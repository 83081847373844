import React, { useEffect, useState } from 'react';
import IncomeExpenseComponent from '../../../../components/_Payroll/reportPayroll/incomeExpense';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  buttonSuccess: {
    backgroundColor: '#2CC63E',
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: '#145e1c',
    },
    width: '100%',
    height: 50,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 18,
  },
}));

const ContribDeducFixed = React.memo(
  ({
    setActiveStep,
    noveltiesData,
    updateIncome,
    addIncome,
    updateExpense,
    addExpense,
    deleteExpense,
    deleteIncome,
    submitEmployee,
  }) => {
    const classes = useStyles();

    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    /**
     * Captura y guarda información de forma de pago empleado
     * @param {*} e Evento
     */
    const onSubmit = (e) => {
      e.preventDefault();
      submitEmployee();
    };

    useEffect(() => {
      const incomesAux = noveltiesData.filter((data) => data.ingreso);
      const expensesAux = noveltiesData.filter((data) => !data.ingreso);
      setIncomes(incomesAux);
      setExpenses(expensesAux);
    }, [noveltiesData]);

    return (
      <>
        <form id={'step-form'} onSubmit={onSubmit}></form>
        <Grid
          container
          direction="column"
          style={{ maxWidth: '70%', margin: '1% auto' }}
        >
          <span
            style={{ color: '#7DD1F8', textAlign: 'center', fontSize: '18px' }}
          >
            Desde aquí podrás ingresar los conceptos fijos que seguramente
            reportas todos los meses para este empleado. Lo que registres desde
            aquí te aparecerá por defecto a la hora de reportar nómina, eso te
            ayudará a ahorrar un montón de tiempo... aprovéchalo!!!
          </span>
        </Grid>
        <Grid item md={12} sm style={{ margin: '1% 0 ' }}>
          <div className="lineDivision"></div>
        </Grid>
        <Grid
          container
          direction="column"
          style={{
            overflow: 'auto',
            maxWidth: '60vw',
            margin: 'auto',
            overflowX: 'clip',
          }}
        >
          <IncomeExpenseComponent
            updateIncome={updateIncome}
            addIncome={addIncome}
            updateExpense={updateExpense}
            addExpense={addExpense}
            deleteExpense={deleteExpense}
            deleteIncome={deleteIncome}
            dataIncome={incomes}
            dataExpense={expenses}
            readOnly={false}
          />
        </Grid>
        <Grid
          container
          spacing={3}
          justify="flex-center"
          alignItems="center"
          style={{ maxWidth: '60vw', margin: 'auto' }}
        >
          <Grid item lg md={6} sm={6} xs={12}>
            <Button
              onClick={() =>
                setActiveStep((prevState) => {
                  return prevState - 1;
                })
              }
              className={classes.buttonSuccess}
              type={'button'}
            >
              Anterior
            </Button>
          </Grid>
          <Grid item lg md={6} sm={6} xs={12}></Grid>
        </Grid>
      </>
    );
  },
);

export default ContribDeducFixed;
