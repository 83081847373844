import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';

import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TooltipVerticalBar } from './custom';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    height: '135px',
    [theme.breakpoints.down('lg')]: {
      height: '130px',
    },
    [theme.breakpoints.down('md')]: {
      height: '135px',
    },
  },
}));

const legendFormatter = (value, entry) => (
  <>
    <span
      style={{
        color: '#FFF',
        marginRight: window.innerWidth <= 1280 ? '0px' : '70px',
      }}
    >
      {value}
    </span>
  </>
);

export const VerticalBar = ({ behaviorMonth: data }) => {
  const classes = useStyles();
  const [activeSection, setActiveSection] = useState(null);

  /**
   *  * Se encarga de mostrar el tooltip al pasar el mouse por encima de la barra seleccionada
   * @param {*} section
   */
  const handleBarMouseEnter = (section) => {
    setActiveSection(section);
  };

  /**
   * * Se encarga de ocultar el tooltip al pasar el mouse por encima de la barra seleccionada
   */
  const handleBarMouseLeave = () => {
    setActiveSection(null);
  };

  return (
    <div className={classes.mainContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={550}
          height={135}
          data={data}
          layout="vertical"
          margin={{
            top: 10,
            right: 45,
            bottom: 0,
          }}
          className="rectangule-hover"
        >
          <XAxis
            type="number"
            tick={{
              fill: '#FFFFFF',
              fontSize: '12px',
            }}
            axisLine={{ stroke: '#FFFFFF', strokeWidth: 1 }}
            tickSize={0}
            dy={10}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={{
              stroke: '#FFFFFF',
              strokeWidth: 1,
            }}
            tickSize={0}
          />
          <Legend
            iconType="circle"
            iconSize={10}
            formatter={legendFormatter}
            align="right"
            wrapperStyle={{
              paddingTop: '10px',
            }}
          />
          <Tooltip
            content={<TooltipVerticalBar activeSection={activeSection} />}
          />
          <Bar
            dataKey="Activos"
            stackId="a"
            fill="#35ADE3"
            onMouseEnter={() => handleBarMouseEnter('Activos')}
            onMouseLeave={handleBarMouseLeave}
          />
          <Bar
            dataKey="Nuevos"
            stackId="a"
            fill="#62ED08"
            onMouseEnter={() => handleBarMouseEnter('Nuevos')}
            onMouseLeave={handleBarMouseLeave}
          />
          <Bar
            dataKey="Retirados"
            stackId="a"
            fill="#E9474B"
            onMouseEnter={() => handleBarMouseEnter('Retirados')}
            onMouseLeave={handleBarMouseLeave}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
