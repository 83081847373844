import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
//SignalR
import * as SignalR from '@microsoft/signalr';

import {
  getStatusTransmissionAction,
  startTransmissionStatusAction,
  updateStatusTransmissionAction,
  updateTransmissionStatusAction,
} from '../../../actions/payrollReportAction';
import { API_ENDPOINT_SIGNALR } from '../../../config/config';

const useStyles = makeStyles({
  container: {
    borderRadius: '5px',
    backgroundColor: '#3B3B3B',
    top: '0',
    zIndex: '9999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    padding: '1vh',
    fontSize: '0.8em',
  },
  item: {
    gridAutoColumns: '1fr',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    padding: '1%',
    cursor: 'pointer',
    alignItems: 'center',
  },
});

const TransmisionBanner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { statusTransmission, startPayrollTransmission } = useSelector(
    (s) => s.payrollReportReducer,
  );

  // React Hooks
  const [hubConnection] = useState(
    new SignalR.HubConnectionBuilder()
      .withUrl(`${API_ENDPOINT_SIGNALR}/CNSignal`)
      .withAutomaticReconnect()
      .configureLogging(SignalR.LogLevel.Information)
      .build(),
  );

  useEffect(() => {
    hubConnection
      .start()
      .then(() => {
        try {
          const company = JSON.parse(localStorage.getItem('dataCompany'));
          hubConnection
            .invoke('JoinGroupApp', company.companyId)
            .catch((err) => {
              console.log('No se puedo realizar la conexión al grupo :', err);
            });

          hubConnection.on('ReceiveMessage', function (pResponse) {
            console.log('Response SignalR:', pResponse);
            dispatch(getStatusTransmissionAction(pResponse));

            if (!pResponse.inicioTransmision) {
              dispatch(updateStatusTransmissionAction(pResponse));
            } else {
              dispatch(
                startTransmissionStatusAction(
                  Number(pResponse.descripcionrespuesta),
                  pResponse.estadonominaid,
                  pResponse.empleadoid,
                  Number(pResponse.detallerespuesta),
                ),
              );
            }
          });

          // Event handler for reconnecting
          hubConnection.onreconnecting((error) => {
            console.log('Reconnecting to SignalR...', error);
          });

          hubConnection.onreconnected((connectionId) => {
            console.log(
              'Reconnected to SignalR with connectionId:',
              connectionId,
            );
          });
        } catch (error) {
          console.log('Error connection', error);
        }
      })
      .catch((err) => console.log('Unable to start Connection :' + err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  useEffect(() => {
    if (statusTransmission && statusTransmission.estadocargueid === 3) {
      setTimeout(function () {
        dispatch(updateTransmissionStatusAction());
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusTransmission]);

  return (
    <>
      {startPayrollTransmission && (
        <Grid
          sx={{ marginBottom: '10px' }}
          container
          className={classes.container}
          style={{ backgroundColor: '#16B1F3', color: '#000' }}
        >
          <Grid item>Enviando soportes de nómina ...</Grid>
        </Grid>
      )}
      {statusTransmission && (
        <Grid
          marginTop={2}
          container
          className={classes.container}
          style={
            statusTransmission.estadocargueid === 3
              ? { backgroundColor: '#A2E3A4', color: '#000' }
              : {}
          }
        >
          {statusTransmission.estadocargueid === 1 && (
            <ClipLoader color={'#ffffff'} size={20} />
          )}
          <Grid item>
            {statusTransmission.estadocargueid === 1
              ? `Envio de soportes de nómina en proceso… ${
                  statusTransmission.transmitidos || 0
                } de ${statusTransmission.cantidad || 0} registros`
              : statusTransmission.estadocargueid === 3
              ? `Se ha finalizado el envio de los soportes de nómina a la DIAN`
              : `Cargue id sin definir :  statusTransmission.estadocargueid ${statusTransmission.estadocargueid} `}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default TransmisionBanner;
