import React from 'react';

export const TooltipHorizontalBar = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="container-custom" style={{ height: '53px' }}>
        <p className="custom-name">{label}</p>
        <span className="custom-description">
          {data.value.toLocaleString('en-US', {
            useGrouping: true,
            groupingSeparator: "'",
            decimalSeparator: '.',
          })}
        </span>
      </div>
    );
  }

  return null;
};
