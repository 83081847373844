import React from 'react';

export const TooltipBiBarChart = ({ active, payload, activeSection }) => {
  if (active && payload && payload.length && activeSection) {
    const paySelect = payload.find(
      (item) => item.dataKey === activeSection.toLowerCase(),
    );
    return (
      <div className="container-custom" style={{ height: '53px' }}>
        <p className="custom-name">{paySelect?.payload.name}</p>
        <span className="custom-description">{paySelect?.value}</span>
      </div>
    );
  }
  return null;
};
