import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import Grid from '@material-ui/core/Grid';
import ButtonPrimary from '../button/buttonPrimary.component';

import CustomAutoComplete from '../autocomplete/autocomplete.component';
import InputForm from '../input/input.component';

import { useFormComplete } from '../../hooks/useFormComplete';

import {
  addExpenseAction,
  updateExpenseAction,
  loadExpenseAction,
  saveConceptDocumentAction,
  updateConceptDocumentAction,
} from '../../actions/conceptAction';

import {
  NumberFormatCustomWithMin,
  percentFormatCustom,
  percentFormatCustomOneHundred,
} from '../../utils/numberFormat';
import isEmpty from '../../utils/isEmpty';
import generateId from '../../utils/generateId';

export const ExpenseForm = ({
  handleClose,
  type = 'default',
  detallenominaid = 0,
}) => {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const [expensesCurrent, setExpensesCurrent] = useState([]);
  const [rule, setRule] = useState({
    id: 0,
    aplicavalor: false,
    aplicaporcentaje: false,
    aplicadescripcion: false,
  });

  const {
    values,
    handleInputChange,
    handleUpdateForm,
    reset,
  } = useFormComplete({
    tipodeduccion: null,
    porcentaje: '',
    valor: '',
    descripcion: '',
  });

  const { tipodeduccion, porcentaje, valor, descripcion } = values;

  /**
   * Store Redux
   */
  const { expensesList } = useSelector((s) => s.configReducer);
  const { expensesPayroll, expenseActive, loadingAdd } = useSelector(
    (s) => s.expensePayrollReducer,
  );

  /**
   * Actualiza listado de ingresos disponibles
   */
  useEffect(() => {
    getDisposableExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expensesList, expensesPayroll]);

  /**
   * Carga ingreso para edición
   */
  useEffect(() => {
    if (!!expenseActive) {
      const tipoDeduccion = expensesList.find(
        (c) => c.id === expenseActive.conceptoid,
      );

      setRule({
        id: tipoDeduccion.id,
        aplicavalor: tipoDeduccion.aplicavalor,
        aplicaporcentaje: tipoDeduccion.aplicaporcentaje,
        aplicadescripcion: tipoDeduccion.aplicadescripcion,
      });

      handleUpdateForm({
        tipodeduccion: tipoDeduccion,
        porcentaje: expenseActive.porcentaje,
        valor: expenseActive.valor,
        descripcion: expenseActive.descripcion,
      });

      setExpensesCurrent([
        {
          ...expenseActive,
          value: tipoDeduccion.id,
          text: tipoDeduccion.descripcion,
        },
        ...expensesCurrent,
      ]);
    } else {
      getDisposableExpenses();
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseActive]);

  /**Actualiza listado ingresos disponibles */
  const getDisposableExpenses = () => {
    let array = expensesList;
    for (let i = 0; i < expensesPayroll.length; i++) {
      const item = expensesPayroll[i];
      array = array.filter((c) => c.id !== item.conceptoid);
    }

    setExpensesCurrent(array);
  };

  /**
   * Actualiza tipo concepto con state,
   * carga reglas definidas para concepto seleccionado
   * @param {*} e
   * @param {*} value
   * @param {*} name
   */
  const handleChangeType = (e, value, name) => {
    //Purgar validador
    validator.purgeFields();
    validator.hideMessages();
    forceUpdate(Math.random());

    handleUpdateForm({
      tipodeduccion: value,
      porcentaje: '',
      cantidad: '',
      valor: '',
      descripcion: '',
    });

    if (!value) {
      setRule({
        id: 0,
        aplicavalor: false,
        aplicaporcentaje: false,
        aplicadescripcion: false,
      });
      return;
    }

    const { id, aplicavalor, aplicaporcentaje, aplicadescripcion } = value;
    setRule({
      id: id,
      aplicavalor: aplicavalor,
      aplicaporcentaje: aplicaporcentaje,
      aplicadescripcion: aplicadescripcion,
    });
  };

  /**
   * Vuelve al valor inicial formulario
   */
  const resetForm = () => {
    reset();
    setRule({
      id: 0,
      aplicavalor: false,
      aplicaporcentaje: false,
      aplicadescripcion: false,
    });

    //Purgar validador
    validator.purgeFields();
    validator.hideMessages();
    forceUpdate(Math.random());
  };

  /**
   * Agrega o Edita deduccion
   * @param {*} e
   */
  const handleAddExpense = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    const expense = {
      identificador: !!expenseActive
        ? expenseActive.identificador
        : generateId(),
      conceptoid: tipodeduccion.id,
      concepto: tipodeduccion.descripcion,
      porcentaje: isEmpty(porcentaje) ? null : parseFloat(porcentaje),
      valor: isEmpty(valor) ? null : parseFloat(valor),
      descripcion: descripcion,
      codigo: tipodeduccion.codigo,
      esingreso: false,
      aplicaValor: tipodeduccion.aplicavalor,
      aplicaPorcentaje: tipodeduccion.aplicaporcentaje,
      aplicaCantidad: tipodeduccion.aplicacantidad,
    };

    if (!!expenseActive) {
      handleTypeUpdateExpense(expense);
    } else {
      handleTypeAddExpense(expense);
    }

    if (type === 'default') completeSaveAndUpdate();
  };

  /**
   * Agrega y/o guarda nuevo ingreso
   * @param {*} expense
   */
  const handleTypeAddExpense = (expense) => {
    if (type === 'document') {
      dispatch(
        saveConceptDocumentAction(
          expense,
          detallenominaid,
          completeSaveAndUpdate,
        ),
      );
    } else {
      dispatch(addExpenseAction(expense));
    }
  };

  /**
   * Edita y/o guarda nuevo ingreso
   * @param {*} expense
   */
  const handleTypeUpdateExpense = (expense) => {
    if (type === 'document') {
      dispatch(
        updateConceptDocumentAction(
          expense,
          detallenominaid,
          completeSaveAndUpdate,
        ),
      );
    } else {
      dispatch(updateExpenseAction(expense));
    }
  };

  const completeSaveAndUpdate = () => {
    handleClose();
    resetForm();
    dispatch(loadExpenseAction(null));
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <CustomAutoComplete
            name="tipodeduccion"
            label="Tipo de deducción*"
            disabled={!!expenseActive}
            value={tipodeduccion}
            options={expensesCurrent}
            textNoOption="No se encontraron resultados."
            onChange={handleChangeType}
            validator={validator}
            validateOptions={'required'}
          />
        </Grid>

        {rule.aplicaporcentaje && (
          <Grid item lg={6} xs={12}>
            <InputForm
              name="porcentaje"
              label={'Porcentaje'}
              value={porcentaje}
              onChange={handleInputChange}
              inputComponent={
                rule.id === 1 ||
                rule.id === 2 ||
                rule.id === 3 ||
                rule.id === 4 ||
                rule.id === 5 ||
                rule.id === 6 ||
                rule.id === 7 ||
                rule.id === 14 ||
                rule.id === 36
                  ? percentFormatCustomOneHundred
                  : percentFormatCustom
              }
              validator={validator}
              validateOptions={rule.aplicaporcentaje ? 'required' : 'void'}
            />
          </Grid>
        )}

        {rule.aplicavalor && (
          <Grid item lg={6} xs={12}>
            <InputForm
              name="valor"
              label={'Valor'}
              value={valor}
              onChange={handleInputChange}
              inputComponent={NumberFormatCustomWithMin}
              validator={validator}
              validateOptions={rule.aplicavalor ? 'required' : 'void'}
            />
          </Grid>
        )}

        {rule.aplicadescripcion && (
          <Grid item lg={12} xs={12}>
            <InputForm
              name="descripcion"
              label={'Descripcion'}
              value={descripcion}
              onChange={handleInputChange}
              validator={validator}
              validateOptions={`min:3|max:100|${
                rule.aplicavalor ? 'required' : 'void'
              }`}
            />
          </Grid>
        )}
      </Grid>
      <Grid container style={{ justifyContent: 'end' }}>
        <Grid item lg={6} xs={12}>
          <ButtonPrimary
            text={!!expenseActive ? 'Editar' : 'Listo'}
            onClick={handleAddExpense}
            loading={loadingAdd}
          />
        </Grid>
      </Grid>
    </>
  );
};
