import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  noSelectValue: {
    color: theme.palette.fourthBackgroundColor + '!important',
  },
}));

const CustomDatePicker = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.Mui-disabled': {
      color: '#FFFF', //gris
    },
    '& label.MuiFormLabel-filled:not(.Mui-focused)': {
      color: 'gray', //gris
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.thirdColor, //azul
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .noSelectValue': {
      color: 'gray',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderColor: '#757575', //gris
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.thirdColor, //azul
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', //gris
      },
    },
  },
}))(DatePicker);

const DatePickerForm = ({ ...props }) => {
  const {
    id,
    onChange,
    value,
    label,
    disabled,
    validator,
    validateOptions,
    hidden,
    format,
    minDate,
    minDateMessage,
    maxDate,
    maxDateMessage,
    disablePast = false,
    disableFuture = false,
  } = props;
  const classes = useStyles();

  let errorValidator = validator
    ? validator.message(label, value, validateOptions)
    : '';

  function handleOnClick(date) {
    onChange(id, date);
  }
  if (!!hidden) return null;

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
        <CustomDatePicker
          autoOk
          fullWidth
          className={classes.margin}
          id={id}
          value={value}
          label={label}
          variant="inline"
          inputVariant="outlined"
          format={!!format ? format : 'dd/MM/yyyy'}
          onChange={handleOnClick}
          error={!!errorValidator ? true : false}
          helperText={errorValidator}
          disabled={disabled === true ? true : false}
          disablePast={disablePast}
          disableFuture={disableFuture}
          InputLabelProps={{
            shrink: true,
            variant: 'outlined',
          }}
          inputProps={value && { className: 'noSelectValue' }}
          minDate={!!minDate ? minDate : undefined}
          minDateMessage={minDateMessage}
          maxDate={!!maxDate ? maxDate : undefined}
          maxDateMessage={maxDateMessage}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePickerForm.propTypes = {};

export default DatePickerForm;
