import { types } from '../actions/types';

const initialState = {};

export const transmissionReducer = (state = initialState, action) => {
  switch (action.payload) {
    case types.UPDATE_DATA_TRANSMISSION:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
