import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Carousel from 'react-material-ui-carousel';
import autoBind from 'auto-bind';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Radio,
  IconButton,
  Box,
} from '@material-ui/core';

import { ShowTemplateAlert } from '../../helpers/alert.helpers';

const CustomCheckBox = withStyles({
  root: {
    '&$checked': {
      color: '#0091BB',
    },
    '&:not($checked)': {
      color: 'white',
    },
  },
  checked: {},
})(Radio);

const useStyles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    background: '#16b1f3',
    borderRadius: 10,
  },
  media: {
    height: '100%',
    width: 'auto',
    backgroundSize: '100% 100%',
  },
  mediaActions: {
    height: '100%',
  },
  icon: {
    backgroundColor: 'white',
  },
  templateName: {
    paddingTop: 18.08,
    fontSize: 18,
    color: 'white',
    lineHeight: '24px',
  },
  watchIcon: {
    weight: 43,
    height: 33,
  },
  cardRoot: {
    borderRadius: 8.1,
  },
});

class TemplateCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      checked: '',
      syncCheckChange: props.syncCheckChange,
      buyTemplate: props.buyTemplate,
      autoPlay: false,
      timer: 500,
      animation: 'slide',
      indicators: false,
      timeout: 300,
      numItemPerPage: 4,
      pages: 0,
      stateModal: false,
      components: [],
    };

    autoBind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (nextProps.templates !== state.templates) {
      update.templates = nextProps.templates;
      update.pages = nextProps.templates / state.numItemPerPage;
    }

    if (nextProps.checked !== state.checked) {
      update.checked = nextProps.checked;
    }

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    ) {
      update.components = nextProps.configReducer.components;
    }

    return Object.keys(update).length ? update : null;
  }

  toggleAutoPlay() {
    this.setState({
      autoPlay: !this.state.autoPlay,
    });
  }

  toggleIndicators() {
    this.setState({
      indicators: !this.state.indicators,
    });
  }

  changeAnimation(event) {
    this.setState({
      animation: event.target.value,
    });
  }

  changeTimeout(event, value) {
    this.setState({
      timeout: value,
    });
  }

  handleTemplate = (nombre, src, e) => {
    e.preventDefault();
    ShowTemplateAlert(nombre, src);
  };

  Item(classes, templates, index) {
    return (
      <Grid container key={index}>
        {templates.map((data, indexTemplate) =>
          this.CardTemplate(classes, data, indexTemplate),
        )}
      </Grid>
    );
  }

  CardTemplate(classes, data, index) {
    return (
      <Grid item lg={3} xs={4} key={index}>
        <Card className={classes.cardRoot}>
          <Box height={315}>
            <CardMedia
              classes={{ root: classes.media }}
              image={data.srcsmall}
              title={data.nombre}
            >
              <Grid
                container
                classes={{ root: classes.mediaActions }}
                direction="column"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <Box zIndex="1010" position="relative">
                    <CustomCheckBox
                      icon={
                        <i>
                          <img
                            src={require('./../../images/unselect-template.png')}
                            alt={'Unselect-template'}
                          />
                        </i>
                      }
                      checkedIcon={
                        <i>
                          <img
                            src={require('./../../images/select-template.png')}
                            alt={'Select-template'}
                          />
                        </i>
                      }
                      checked={data.id === this.state.checked}
                      name="plantillaidnomina"
                      value={data.id}
                      onChange={(event) =>
                        this.state.syncCheckChange(event, data.id)
                      }
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box zIndex="1010" position="relative">
                    <IconButton
                      aria-label="show_template"
                      onClick={(e) =>
                        this.handleTemplate(data.nombre, data.srcbig, e)
                      }
                    >
                      <i>
                        <img
                          className={classes.watchIcon}
                          src={require('./../../images/watch-template.png')}
                          alt={'Watch-template'}
                        />
                      </i>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </CardMedia>
          </Box>
        </Card>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          className={classes.templateName}
        >
          {data.nombre}
        </Typography>
      </Grid>
    );
  }

  render() {
    let list = [];
    let listTemplates = [];
    for (let index = 0; index < this.state.templates.length; index++) {
      if (
        (index + 1) % this.state.numItemPerPage !== 0 &&
        index + 1 !== this.state.templates.length
      ) {
        list.push(this.state.templates[index]);
      } else {
        list.push(this.state.templates[index]);
        listTemplates.push(list);
        list = [];
      }
    }

    const classes = this.props.classes;

    return (
      <Card className={classes.root}>
        <CardContent>
          {!!listTemplates && listTemplates.length && (
            <Carousel
              autoPlay={this.state.autoPlay}
              timer={this.state.timer}
              animation={this.state.animation}
              indicators={this.state.indicators}
              timeout={this.state.timeout}
            >
              {listTemplates.map((templates, index) => {
                return (
                  <Grid container spacing={10} key={index}>
                    {templates.map((data, indexTemplate) =>
                      this.CardTemplate(classes, data, indexTemplate),
                    )}
                  </Grid>
                );
              })}
            </Carousel>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
  configReducer: state.configReducer,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(useStyles)(TemplateCarousel));
