/**
 * * Verifica si el evento contiene un archivo válido para cargar.
 *
 * @param {Event} e - El evento del input de archivo.
 * @returns {boolean} - True si el archivo es válido, false en caso contrario.
 */
export const hasValidFile = (e) => {
  const { target } = e || {};
  const { files } = target || {};

  return !!files && files.length > 0;
};
