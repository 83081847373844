import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
// ctm hook
import { useMyAccount } from '../../hooks/useMyAccount';

import User from './users';
import BasicData from './basicData';
import Branch from './branch';
import Template from './logo';
// store
import { useSelector } from 'react-redux';

import { getBillersAction } from '../../actions/userAction';
import { getCompanyAction } from '../../actions/basicDataActions';
import { getBranchOfficeAction } from '../../actions/branchOfficeActions';
import {
  getComponentsAction,
  ClearGetComponentsAction,
  getAllCitiesAction,
} from '../../actions/configAction';
import { getComponentName } from '../../utils/general.js';
import { getRoleOfLoggedUser } from '../../actions/authActions.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secundaryBackgroundColor,
  },
  activeButton: {
    borderBottom: '1px solid #50C1EE',
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  line: {
    borderColor: '#4F4F4F',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: 'white',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#2CC63E',
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#4F4F4F',
  },
  completed: {
    color: '#4F4F4F',
    zIndex: 1,
    fontSize: 18,
    backgroundColor: '#2CC63E',
    borderRadius: '50%',
    padding: 5,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {' '}
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}{' '}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function getSteps(components) {
  return [
    getComponentName(components, 9, 9, 'Datos básicos'),
    getComponentName(components, 22, 87, 'Sucursales'),
    getComponentName(components, 28, 96, 'Usuarios'), //DEBE SER PLURAL
    getComponentName(components, 38, 119, 'Logo y plantilla'),
  ];
}

const usePreviousValue = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function MyAccount(props) {
  const classes = useStyles();
  let { configReducer } = props;
  const {
    getBillersAction,
    getComponentsAction,
    getCompanyAction,
    getBranchOfficeAction,
    getModalities,
    ClearGetComponentsAction,
    getAllCitiesAction,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [stepsCompleted, setStepCompleted] = useState([]);

  // const { requestCompany } = basicDataReducer;
  let components = configReducer.components;
  let steps = getSteps(components);
  let menu = configReducer.menu;
  let prevMenu = usePreviousValue(menu);

  // custom hook
  const { getBranchesByEmpresaId } = useMyAccount();

  // state redux

  const listBranchStored = useSelector(
    (state) => state.branchOfficeReducer.filteredBranchOfficesList?.data || [],
  );
  const filteredBillersList = useSelector(
    (state) => state.userReducer.filteredBillersList || [],
  );
  const requestCompany = useSelector((state) => state.basicDataReducer || null);

  useEffect(() => {
    if (!!prevMenu && prevMenu !== menu) {
      getComponentsAction(2);
    }
  }, [menu, getComponentsAction, prevMenu]);

  useEffect(() => {
    if (getRoleOfLoggedUser()) {
      props.history.push('/');
    }

    getAllCitiesAction();
    getComponentsAction(2);
    getBillersAction();
    getCompanyAction();
    getBranchOfficeAction();

    return () => {
      ClearGetComponentsAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ClearGetComponentsAction,
    getAllCitiesAction,
    getBillersAction,
    getBranchOfficeAction,
    getCompanyAction,
    getComponentsAction,
    getModalities,
  ]);

  useEffect(() => {
    if (requestCompany && Object.keys(requestCompany).length)
      handleCompleteStep(0);

    if (listBranchStored && listBranchStored.length > 0) handleCompleteStep(1);

    if (
      filteredBillersList &&
      filteredBillersList.length > 0 &&
      filteredBillersList
    )
      handleCompleteStep(2);

    if (
      requestCompany &&
      requestCompany?.plantillaidnomina !== '' &&
      requestCompany?.plantillaidnomina !== null
    )
      handleCompleteStep(3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCompany, listBranchStored, filteredBillersList]);

  useEffect(() => {
    if (!!listBranchStored) {
      getBranchesByEmpresaId('', false);
    }
    // reat hooks
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Listado de componentes para el paso a paso
   * @param {*} step Idetificador paso
   */
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicData />;
      case 1:
        return <Branch />;
      case 2:
        return <User />;
      case 3:
        return <Template />;
      default:
        return 'Unknown step';
    }
  };

  /**
   * Agrega paso completo a listado
   * @param {int} index Identificador paso
   */
  const handleCompleteStep = (index) => {
    let exist = stepsCompleted.find((s) => s === index);
    let newArray = [];
    newArray = stepsCompleted;
    if (exist === null || exist === undefined) {
      newArray.push(index);
      setStepCompleted(newArray);
      setCompleted(new Set(newArray));
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function isStepCompleted(step) {
    return completed.has(step);
  }

  return (
    <div className="container-form" xs={12} lg={12}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.root}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepCompleted(index)}
                className={activeStep === index ? classes.activeButton : ''}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>{getStepContent(activeStep)}</div>
      </div>
    </div>
  );
}

MyAccount.propTypes = {
  getBillersAction: PropTypes.func.isRequired,
  ClearGetComponentsAction: PropTypes.func.isRequired,
  getBranchOfficeAction: PropTypes.func.isRequired,
  getAllCitiesAction: PropTypes.func.isRequired,

  userReducer: PropTypes.object.isRequired,
  basicDataReducer: PropTypes.object.isRequired,
  templateReducer: PropTypes.object.isRequired,
  branchOfficeReducer: PropTypes.object.isRequired,
  configReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  templateReducer: state.templateReducer,
  basicDataReducer: state.basicDataReducer,
  branchOfficeReducer: state.branchOfficeReducer,
  configReducer: state.configReducer,
});

export default connect(mapStateToProps, {
  getBillersAction,
  getCompanyAction,
  getBranchOfficeAction,
  getComponentsAction,
  ClearGetComponentsAction,
  getAllCitiesAction,
})(MyAccount);
