import React, { useEffect, useState } from 'react';

import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

import { enterpriseDocumentNumber } from '../../actions/authActions';
import { getIncomesAndExpensesAction } from '../../actions/configAction';
import {
  cleanPayrollReportAction,
  getTotalDataPayrollReportAction,
  setCheckedPayrollListAction,
  transmitPayrollSupportsAction,
  updateDatePayAction,
  updateFilterDataAction,
} from '../../actions/payrollReportAction';
import { LICENSE_SHOP_URL, URL_STORE } from '../../config/config';
import {
  ConfirmAlert2,
  ConfirmAlert4,
  WarningAlert,
} from '../../helpers/alert.helpers';
import { getDestructuredDate } from '../../helpers/functions.helpers';
import { usePayroll } from '../../hooks/usePayroll';
import calculator from '../../images/icons/calculatorSmall.png';
import {
  validCertificateService,
  validCounterService,
} from '../../services/licenceService';
import ButtonPrimary from '../button/buttonPrimary.component';
import { FormatDecimal } from '../common/formatDecimal';
import ResponseModal from '../modal/responseModal.component';
import CustomProgress from '../Progress/progress.component';
import CustomTooltip from '../tooltip/tooltip.component';
import TooltipMessage from '../tooltip/tootltip-message.component';
import { PayrollEmployeeList } from './payrollEmployeeList';
import { PayrollReportDetail } from './payrollReportDetail';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#242424',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'justify',
    paddingRight: 10,
  },
  actions: {
    backgroundColor: '#393939',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    textAlign: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    height: 80,
    padding: 15,
  },
  title: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 500,
  },
  containerTitle: {
    display: 'grid',
    textAlign: 'center',
    padding: 10,
  },
  line: {
    border: '0.5px solid #50C1EE',
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    borderRadius: '15px !important',
    padding: 0,
  },
  label: {
    flexDirection: 'column',
    fontSize: 12,
    color: 'white',
  },
  labelHorizontal: {
    flexDirection: 'row',
    fontSize: 12,
    color: 'white',
  },
  cardData: {
    height: 'auto',
    padding: 10,
    borderRadius: '5px',
    justifyContent: 'center',
    backgroundColor: '#3B3B3B',
    textAlign: 'center',
    '& p': {
      color: '#2CC63E',
      height: 'fit-content',
      margin: '0 auto',
      fontWeight: 600,
    },
    '& span': {
      color: '#fff',
      height: 'fit-content',
      margin: '0 auto',
      fontSize: 'smaller',
    },
  },
  cardTotal: {
    color: '#F5D13D',
    display: 'flex',
  },
  lineGray: {
    border: '0.5px solid rgba(255, 255, 255, 0.2)',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    display: 'flex',
  },
  value: {
    margin: '0',
    fontSize: '22px',
    lineHeight: 'normal',
    color: '#F5D13D',
  },
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        color: '#FFF',
        '& label ': {
          color: '#FFF',
          textAlign: 'left',
        },
      },
    },
    palette: {
      primary: 'red',
      secondary: 'cyan',
    },
    MuiFormLabel: {
      root: {
        color: '#FFF',
        textAlign: 'center',
        fontSize: '0.9rem',
        width: '100%',
        '&$focused': {
          color: '#16B1F3',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(22, 177, 243)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#16B1F3 !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#FFF',
        borderRadius: '5px',
        '& fieldset': {
          border: '2px solid rgba(255,255,255,0.3) !important',
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#1b5e20',
      },
    },
  },
});

const localeMap = {
  es: esLocale,
};

const initialModal = {
  modalType: '',
  title: '',
  subtitle: '',
  body: '',
  modalImage: '',
  open: false,
  closeElement: '',
};

export const PayrollReportManage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  moment.locale('es');
  const [periodDate, setPeriodDate] = useState(null);
  const [ModalResponse, setModalResponse] = useState(initialModal);
  const [loadingCheckTransmition, setLoadingCheckTransmition] = useState(false);
  const [paymentPeriodDate, setPaymentPeriodDate] = useState(null);
  const [paymentMaxPeriodDate, setPaymentMaxPeriodDate] = useState(null);
  const [payDateValid, setPayDateValid] = useState(true);

  const { Anio, Mes } = useSelector((s) => s.payrollReportReducer.filterData);
  const {
    filterData,
    payrollListChecked,
    loadingPayDate,
    totalconfirmados,
    totalempleados,
    totalnomina,
    totaltransmitidos,
    loadingTotal,
    fechapago,
  } = useSelector((s) => s.payrollReportReducer);
  const { activeSuport, loadingTransmission, startPayrollTransmission } =
    useSelector((s) => s.payrollReportReducer);

  const company = useSelector((s) => s.basicDataReducer.requestCompany || null);
  const { setContentTab } = usePayroll();

  /**
   * Cargue inicial
   */
  useEffect(() => {
    dispatch(getIncomesAndExpensesAction());
  }, [dispatch]);

  /**
   * Desmontado de componente
   */
  useEffect(() => {
    return () => {
      dispatch(cleanPayrollReportAction());
    };
  }, [dispatch]);

  /**
   * Asigna filtro inicial
   */
  useEffect(() => {
    if (!!Anio && !!Mes) {
      const today = new Date();
      const period = new Date(Anio, Mes - 1, 1);

      if (period <= today) {
        setPeriodDate(period);
        setPaymentMaxPeriodDate(period);

        //Consulta totales
        const data = {
          Anio: Anio,
          Mes: Mes,
          EmpresaId: 0,
        };
        dispatch(getTotalDataPayrollReportAction(data));
      }
    }
  }, [Anio, Mes, dispatch]);

  /**
   * Llena fecha de pago
   */
  useEffect(() => {
    if (fechapago) setPaymentPeriodDate(new Date(fechapago));
  }, [fechapago]);

  const handleCloseResponseModal = () => {
    setModalResponse(initialModal);
    setLoadingCheckTransmition(false);
  };

  /**
   * Sincroniza nuevo valor periodo
   * @param {*} e
   */

  const handleChangePeriod = (e) => {
    const { month, year } = getDestructuredDate({
      date: e,
      day: false,
    });
    const period = new Date(year, month - 1, 1);
    dispatch(setCheckedPayrollListAction([]));
    dispatch(
      updateFilterDataAction({
        ...filterData,
        Anio: Number(year),
        Mes: Number(month),
        Offset: 0,
        LoadMore: false,
      }),
    );
    setPeriodDate(period);
    setPaymentPeriodDate(null);
  };

  const handleChangePaymentPeriod = (paydate) => {
    setPaymentPeriodDate(paydate);
    dispatch(updateDatePayAction(paydate, () => setPaymentPeriodDate(null)));
    setPayDateValid(true);
  };

  /**
   * Valida información de soportes a transmitir
   * @returns
   */
  const checkTransmitionSupport = async () => {
    setLoadingCheckTransmition(true);

    if (!paymentPeriodDate) {
      WarningAlert('Upss...!!!', 'Debes seleccionar una fecha de pago');
      setPayDateValid(false);
      setLoadingCheckTransmition(false);
      return;
    }

    if (payrollListChecked.length <= 0) {
      //Lista Seleccionada.
      WarningAlert(
        '',
        'Primero debe confirmar la nomina de cada empleado a transmitir y posteriormente seleccionar los que desea transmitir a la DIAN.',
      );
      setLoadingCheckTransmition(false);
      return;
    }

    //CERTIFICADO
    const certificate = await validCertificateService();
    if (!certificate?.activo) {
      WarningAlert(
        '',
        'Aún no cuentas con certificado Digital para generar la trasmisión a DIAN.',
      );
      setLoadingCheckTransmition(false);
      return;
    }

    //TEST ID
    if (company && !company.testidexitosonomina) {
      WarningAlert(
        ``,
        `Aún no cuentas con el Set de pruebas habilitado para generar la trasmisión a DIAN.`,
      );
      setLoadingCheckTransmition(false);
      return;
    }

    await validLicenceAvailability();
  };

  /**
   * Valida disponibilidad licencia
   * @returns
   */
  const validLicenceAvailability = async () => {
    const total = payrollListChecked.length;
    const documentEnterprise = enterpriseDocumentNumber();
    const selectedEmployees = payrollListChecked.map((item) => ({
      Detalleid: item.detallenominaid,
      Documento: documentEnterprise,
      Month: filterData.Mes,
      Year: filterData.Anio,
      EmpleadoId: item.empleadoid,
    }));

    const supports = {
      Detallesnomina: selectedEmployees,
      ObservacionAjuste: '',
    };

    let license = await validCounterService(supports);
    console.log('Disponibilidad licencia: ', license);
    let licenciaActivaEmp = license.licenciaActivaEmp;
    let licenciaActivaDoc = license.licenciaActivaDoc;

    const expiredLicense =
      !licenciaActivaEmp &&
      !licenciaActivaDoc &&
      license?.fechaExpiracionLicenciaDoc !== null &&
      license?.fechaExpiracionLicenciaEmp !== null
        ? 'Documento y Empleado'
        : !licenciaActivaEmp && license?.fechaExpiracionLicenciaEmp !== null
        ? 'Empleado'
        : !licenciaActivaDoc && license?.fechaExpiracionLicenciaDoc !== null
        ? 'Documento'
        : '';
    const basePlanExpired = {
      modalType: 'warning',
      title: 'Tu plan de Colfactura nómina venció',
      body: (
        <div>
          <p
            style={{
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {`No cuentas con un plan activo(${expiredLicense}), es necesario renovarlo para que puedas seguir emitiendo documentos.`}
          </p>
          <p
            style={{
              marginTop: 10,
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {'Si quieres comprar tu nuevo plan, haz clic en Comprar'}
          </p>
        </div>
      ),
      modalImage: 'warning',
      open: true,
      textButton: 'Comprar',
      closeElement: 'Skip',
      urlButton: `${LICENSE_SHOP_URL}`,
    };

    if (license === null) {
      WarningAlert(
        'Upss...!',
        'No hemos podido verificar cantidad de empleados o documentos disponibles no es posible continuar con el proceso de transmisión, Por favor contacta al administrador',
      );
      setLoadingCheckTransmition(false);
      return;
    }

    // if (
    //   license.cantidadLicenciaEmpleados === 0 &&
    //   license.cantidadLicenciaDocumentos === 0
    // ) {
    //   ConfirmAlert4(
    //     `Quieres transmitir <span style="color:#16B1F3">${total}</span> documentos, pero no cuentas con licencias o documentos  suficientes.`,
    //     `Dispones de <span style="color:#16B1F3">${license.cantidadLicenciaEmpleados}</span> licencia(s) de empleados y <span style="color:#16B1F3">${license.cantidadLicenciaDocumentos}</span> documento(s).`,
    //     `Si quieres comprar más documentos o licencias, haz clic en “Comprar”.`,
    //     `Aceptar`,
    //     () => {},
    //     redirectStore,
    //   );
    //   setLoadingCheckTransmition(false);
    //   return;
    // }
    if (
      !licenciaActivaDoc &&
      !licenciaActivaEmp &&
      !license.licenciaDisponible
    ) {
      setModalResponse(basePlanExpired);
      return;
    }
    if (license.licenciaDisponible === true) {
      if (
        (license.detallesnomina[0].tipoLicencia === 'Documento' &&
          licenciaActivaDoc) ||
        (license.detallesnomina[0].tipoLicencia === 'Empleado' &&
          licenciaActivaEmp)
      ) {
        ConfirmAlert2(
          `Se transmitirán ${total} documento(s).`,
          () => {
            processSupportsValid(supports.Detallesnomina);
          },
          `Transmitir`,
        );
        setLoadingCheckTransmition(false);
      } else {
        setModalResponse(basePlanExpired);
      }
    } else if (
      license.cantidadLicenciaEmpleados === 0 &&
      license.cantidadLicenciaDocumentos === 0
    ) {
      ConfirmAlert4(
        `Quieres transmitir <span style="color:#16B1F3">${total}</span> documentos, pero no cuentas con licencias o documentos  suficientes.`,
        `Dispones de <span style="color:#16B1F3">${license.cantidadLicenciaEmpleados}</span> licencia(s) de empleados y <span style="color:#16B1F3">${license.cantidadLicenciaDocumentos}</span> documento(s).`,
        `Si quieres comprar más documentos o licencias, haz clic en “Comprar”.`,
        `Aceptar`,
        () => {},
        redirectStore,
      );
      setLoadingCheckTransmition(false);
      return;
    } else {
      ConfirmAlert4(
        `Quieres transmitir <span style="color:#16B1F3">${total}</span> documentos, pero no cuentas con licencias o documentos  suficientes.`,
        `Dispones de <span style="color:#16B1F3">${license.cantidadLicenciaEmpleados}</span> licencia(s) de empleados y <span style="color:#16B1F3">${license.cantidadLicenciaDocumentos}</span> documento(s).`,
        `Si quieres comprar más documentos o licencias, haz clic en “Comprar”.`,
        `Transmitir ${
          license.cantidadLicenciaEmpleados + license.cantidadLicenciaDocumentos
        } Doc.`,
        () => {
          processSupportsValid(supports.Detallesnomina);
        },
        redirectStore,
      );

      setLoadingCheckTransmition(false);
    }
  };

  /**
   * Redirecciona a la store
   */
  const redirectStore = () => {
    window.open(URL_STORE, '_blank');
  };

  /**
   * Envia soportes de nomina
   * @param {*} detallesnomina
   */
  const processSupportsValid = (detallesnomina) => {
    detallesnomina = detallesnomina.filter((c) => c.tipoLicencia !== 'NA');
    dispatch(transmitPayrollSupportsAction(detallesnomina));
  };

  /**
   * Vuelve pantalla anterior
   */
  const handleBack = () => {
    setContentTab(1);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Grid container justify="center" justifyContent="center">
              <Grid item lg={4} xs={4}>
                <div className={classes.containerTitle}>
                  <span className={classes.title}>Reportar nómina</span>
                  <span className={classes.line}></span>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={6} style={{ display: 'flex' }}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={localeMap['es']}
                >
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      autoOk
                      disableFuture={true}
                      inputVariant="outlined"
                      className={classes.margin}
                      variant="inline"
                      openTo="month"
                      views={['year', 'month']}
                      label="Mes / Año reportado"
                      value={periodDate}
                      onChange={handleChangePeriod}
                      disabled={loadingPayDate}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <div style={{ padding: '10px 0px' }}>
                  <CustomTooltip
                    message={
                      <TooltipMessage
                        title="¿Qué debo poner aquí?"
                        message="Debes indicar el mes y el año de la nómina que vas a reportar a la DIAN."
                        href={null}
                      />
                    }
                  />
                </div>
              </Grid>
              <Grid item lg={6} xs={6} style={{ display: 'flex' }}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={localeMap['es']}
                >
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      label="Última fecha pago"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      className={classes.margin}
                      value={paymentPeriodDate}
                      onChange={handleChangePaymentPeriod}
                      minDate={paymentMaxPeriodDate}
                      disabled={loadingPayDate}
                      error={!payDateValid}
                      helperText={
                        !payDateValid ? 'Debes diligenciar este campo.' : ''
                      }
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <div style={{ padding: '10px 0px' }}>
                  <Grid container>
                    {loadingPayDate && (
                      <Grid item lg={6} xs={6}>
                        <CustomProgress size={20} />
                      </Grid>
                    )}

                    <Grid item lg={6} xs={6}>
                      <CustomTooltip
                        message={
                          <TooltipMessage
                            title="¿Qué fecha es esta?"
                            message="Indica la última fecha en la que realizaste el pago de la nómina que estás reportando."
                            href={null}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <div className={classes.cardData}>
                  <p>
                    {loadingTotal ? (
                      <CustomProgress size={15} />
                    ) : (
                      <>{`${totalconfirmados} de ${totalempleados}`}</>
                    )}
                  </p>
                  <span>Empleados confirmados</span>
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className={classes.cardData}>
                  <p>
                    {loadingTotal ? (
                      <CustomProgress size={15} />
                    ) : (
                      <>{`${totaltransmitidos} de ${totalempleados}`}</>
                    )}
                  </p>
                  <span>Empleados transmitidos</span>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={12}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              justify="center"
            >
              <Grid item>
                <img src={calculator} alt="Logo" />
              </Grid>
              <Grid item>
                {loadingTotal ? (
                  <CustomProgress size={15} />
                ) : (
                  <h2 style={{ color: '#F5D13D', margin: 0 }}>
                    <FormatDecimal prefix={'$'} value={totalnomina} />
                  </h2>
                )}

                <i style={{ color: '#F5D13D' }}>Valor total de la nómina</i>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Grid container justify="center" justifyContent="center">
              <div style={{ width: '98.99%' }}>
                <span className={classes.lineGray}></span>
              </div>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={5}>
            <PayrollEmployeeList filterData={filterData} />
          </Grid>

          <Grid item lg={7} xs={7}>
            <PayrollReportDetail empleadoid={activeSuport.empleadoid} />
          </Grid>
        </Grid>
        <div className={classes.actions}>
          <Grid container>
            <Grid item lg={8} xs={8} style={{ textAlign: 'left' }}>
              <IconButton
                classes={{
                  root: classes.button,
                  label: classes.labelHorizontal,
                }}
                variant="raised"
                disableRipple={true}
                onClick={handleBack}
                style={{ marginLeft: 10, marginTop: 10 }}
              >
                <ArrowBackIosIcon />
                <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
              </IconButton>
            </Grid>
            <Grid item lg={4} xs={4}>
              <ButtonPrimary
                text={
                  startPayrollTransmission
                    ? 'En proceso de envío de nómina'
                    : 'Transmitir a DIAN'
                }
                onClick={checkTransmitionSupport}
                disabled={startPayrollTransmission}
                loading={
                  loadingCheckTransmition ||
                  loadingTransmission ||
                  startPayrollTransmission
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <ResponseModal
        modalType={ModalResponse.modalType}
        title={ModalResponse.title}
        body={ModalResponse.body}
        modalImage={ModalResponse.modalImage}
        open={ModalResponse.open}
        textButton={ModalResponse?.textButton}
        urlButton={ModalResponse?.urlButton}
        closeElement={ModalResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
      />
    </>
  );
};
