import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Tooltip } from '@material-ui/core';

import InputIcon from '../../components/input/inputIcon.component';
import CustomProgress from '../../components/Progress/progress.component';

//FizedSizeList
import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    border: 'none',
  },
  listStyle: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    color: theme.palette.primaryColor,
  },
  item: {
    marginBottom: 5,
    width: '99%',
    fontWeight: 600,
    '&.MuiListItem-root:hover': {
      borderRadius: 5,
      border: '1px solid #16B1F6',
    },
    '&.Mui-selected': {
      backgroundColor: '#16B1F3',
      borderRadius: 5,
    },
  },
  itemText: {
    fontWeight: 600,
    color: theme.palette.primaryColor,
  },
  container: {
    border: '1px solid red',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: '150vh',
  },
  contenedor: {
    overflow: 'hidden',
    flexGrow: 1,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#2F2E2E',
    fontSize: '14px',
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.5)',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip classes={classes} {...props} />;
}

/**
 * Filtra lista de acuerdo a parametros
 * @param {*} list Listado
 * @param {*} filter cadena para filtro
 */
function handleFilterList(list, filter, top) {
  if (!!filter && filter !== '') {
    list = list.filter(
      (c) =>
        c.text.toLowerCase().match(new RegExp(filter.toLowerCase() + '.*')) ||
        c.documento
          .toLowerCase()
          .match(new RegExp(filter.toLowerCase() + '.*')),
    );
  }

  if (top === 0 || top === null || top === undefined) {
    return list;
  } else {
    return list.slice(0, top);
  }
}

/**
 * Lista con filtro
 * Autor:Fernando Ruiz
 * @method function
 * @param {array}list  Listado original
 * @param {event}onClick Evento click al seleccionar un item de la lista
 * @param {boolean} loading  visualzación de progress
 * @param {int} top  Maximo de item a mostrar
 */

function ListWithFilter({
  list,
  onClick,
  loading,
  top,
  maxHeight,
  selectedId,
  textSearch,
}) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [filter, setFilter] = React.useState('');
  const [filterList, setFilterList] = React.useState([]);

  useEffect(() => {
    if (!!list && list.length) {
      setFilterList(list);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    setSelectedItem(selectedId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const handleFilter = (e) => {
    const value = e.target.value;
    let newList = handleFilterList(list, value, top);
    setFilter(value);
    setFilterList(newList);
  };

  const handleListItemClick = (event, id) => {
    setSelectedItem(id);
  };

  const Row = ({ index, key, style }) => {
    const item = filterList[index];
    return (
      <div>
        <div key={key} style={style} className="post">
          <div>
            <ListItem
              key={index}
              className={classes.item}
              button
              selected={selectedItem === item.id}
              onClick={(event) => {
                handleListItemClick(event, item.id);
                onClick(item);
              }}
            >
              <ListItemText className={classes.itemText} component={'div'}>
                <div className={classes.contenedor}></div>
                <BootstrapTooltip title={`${item.text || ''}`}>
                  <div className={classes.textOverflow}>{item.text}</div>
                </BootstrapTooltip>
              </ListItemText>
            </ListItem>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <InputIcon
        name={'txtFilter'}
        label={
          !!textSearch ? textSearch : 'Buscar empleado por nombre o documento'
        }
        maxLength={28}
        value={filter}
        onChange={(e) => {
          handleFilter(e);
        }}
      />
      {loading === true ? (
        <CustomProgress />
      ) : (
        <Fragment>
          {filterList.length ? (
            <div
              className={classes.root}
              style={{
                maxHeight: !!maxHeight ? maxHeight : 800,
                minHeight: !!maxHeight ? maxHeight : 800,
                backgroundColor: '#232323',
              }}
            >
              <List
                className={classes.listStyle}
                component="nav"
                aria-label="main mailbox folders"
              >
                <FixedSizeList
                  width={1400}
                  height={758}
                  itemCount={filterList.length}
                  itemSize={65}
                  style={{
                    width: '100% !important',
                    position: 'inital',
                    height: '79.3vh',
                    overflowX: 'hidden',
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                  }}
                >
                  {Row}
                </FixedSizeList>
              </List>
            </div>
          ) : (
            <div>No se han encontrado resultados...</div>
          )}
        </Fragment>
      )}
    </div>
  );
}

ListWithFilter.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ListWithFilter;
