import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';

import { DetailIntegrationList } from './detailList';

import dianDocumentDescription from '../../../../utils/dianTypeDocument';
import dianContractDescription from '../../../../utils/dianTypeContract';
import isEmpty from '../../../../utils/isEmpty';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    borderRadius: 5,
    height: '86.8vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  content: {
    width: '100%',
    padding: 15,
    fontSize: 16,
    fontWeight: 300,
    overflow: 'auto',
  },
  name: {
    color: '#F5D13D',
    fontWeight: 'bold',
    fontSize: 20,
  },
  value: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 600,
  },
  flexdisplay: {
    display: 'flex',
  },
  ligthValue: {
    fontWeight: 300,
  },
  icondata: {
    color: '#F5D13D',
    marginRight: 5,
    fontSize: 22,
  },
  verticalLine: {
    border: '0.5px solid grey',
    height: '100%',
    width: 1,
    marginLeft: '50%',
  },
  titleItems: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#2CC63E',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  detailList: {},
  itemLoading: {
    textAlign: 'center',
    width: '100%',
  },
});

export const IntegrationDetail = React.memo(({ employeeIntegration }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {!isEmpty(employeeIntegration) && (
          <>
            <Grid container>
              <Grid item lg={5} xs={5}>
                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.name}>
                      {employeeIntegration.nombrestrabajador ?? ''}
                    </div>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.value}>
                      {`${dianDocumentDescription(
                        employeeIntegration.tipodocumentotrabajador,
                      )} ${employeeIntegration.documentotrabajador ?? ''}`}
                    </div>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.value}>
                      {employeeIntegration.cargotrabajador ?? ''}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} xs={2}>
                <div className={classes.verticalLine}></div>
              </Grid>
              <Grid item lg={5} xs={5}>
                <Grid container>
                  <Grid item lg={12} xs={12} className={classes.flexdisplay}>
                    <DescriptionIcon
                      className={classes.icondata}
                    ></DescriptionIcon>
                    <span className={classes.ligthValue}>
                      {dianContractDescription(
                        employeeIntegration.tipocontrato,
                      )}
                    </span>
                  </Grid>
                  <Grid item lg={12} xs={12} className={classes.flexdisplay}>
                    <PhoneIcon className={classes.icondata}></PhoneIcon>
                    <span className={classes.ligthValue}>
                      {'Salario : ' +
                        '$ ' +
                        new Intl.NumberFormat('es-CO').format(
                          employeeIntegration.salariotrabajador,
                        )}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.titleItems}>{`Soportes de nómina`}</div>
            <div className={classes.detailList}>
              <DetailIntegrationList
                documentotrabajador={employeeIntegration.documentotrabajador}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});
