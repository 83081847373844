import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './autocomplete.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: '5px 0px',
  },
}));

const AutoCompleteSelect = withStyles({
  root: {
    '& .MuiAutocomplete-clearIndicator': {
      color: '#FFFFFF',
    },
    '& .MuiAutocomplete-input': {
      color: '#FAFAFA',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFF',
    },

    '& .MuiAutocomplete-groupUl': {
      backgroundColor: 'blue',
    },
    '& .MuiAutocomplete-popper': {
      color: 'red',
      backgroundColor: 'red',
    },
  },
})(Autocomplete);

const CustomSelect = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.Mui-disabled': {
      color: '#FFFF', //gris
    },
    '& label.MuiFormLabel-filled:not(.Mui-focused)': {
      color: 'gray', //gris
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.thirdColor, //azul
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.thirdColor, //azul
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', //gris
      },
      '& svg': {
        color: theme.palette.fifthBackgroundColor,
      },
    },
  },
}))(TextField);

const CustomAutoComplete = ({
  options,
  label,
  value,
  onChange,
  id,
  name,
  textNoOption,
  validator,
  validateOptions,
  nameValidator,
  disabled = false,
}) => {
  const classes = useStyles();

  let errorValidator = validator
    ? validator.message(
        !!nameValidator ? nameValidator : label,
        value,
        validateOptions,
      )
    : '';
  return (
    <AutoCompleteSelect
      id={id}
      name={name}
      className={classes.margin}
      noOptionsText={textNoOption}
      disabled={disabled}
      options={options}
      value={value}
      getOptionSelected={(options) =>
        !!options?.value ? options.value.toString() : ''
      }
      getOptionLabel={(options) => (!!options?.text ? options.text : '')}
      onChange={(e, value) => onChange(e, value, name)}
      fullWidth
      openOnFocus={true}
      popupIcon={<ExpandMoreIcon />}
      openText={'Abrir'}
      clearText={'Limpiar'}
      closeText={'Cerrar'}
      renderInput={(params) => (
        <div className={classes.root}>
          <CustomSelect
            {...params}
            variant="outlined"
            label={label}
            fullWidth
            error={!!errorValidator ? true : false}
            helperText={errorValidator}
          />
        </div>
      )}
    />
  );
};

export default CustomAutoComplete;
