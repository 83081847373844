import React, { useEffect, useState } from 'react';

import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

import { CustomTooltip } from './custom';
import { useWindowSize } from '../../../../hooks/useResizeWindows';

const colors = [
  '#2D9CDD',
  '#F6C718',
  '#56EF0B',
  '#E12F3B',
  '#9410DB',
  '#D8D8D8',
  '#D8D8D8',
  '#D8D8D8',
  '#D8D8D8',
  '#D8D8D8',
];

export const PieChartComp = ({ concepts: data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [initialValues, setInitialValues] = useState({
    widthInitial: 800,
    cx: 320,
    cy: 150,
    legend: 200,
  });

  const { widthInitial, cx, cy, legend } = initialValues;
  const { width } = useWindowSize();

  useEffect(() => {
    resizeChart(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  /**
   * * Posiciona el gráfico de acuerdo al tamaño de la pantalla
   * @param {*} width
   */
  const resizeChart = (width) => {
    if (width <= 600) {
      setInitialValues({
        widthInitial: 300,
        cx: 150,
        cy: 150,
        legend: 0,
      });
    } else if (width <= 900) {
      setInitialValues({
        widthInitial: 400,
        cx: 150,
        cy: 150,
        legend: 0,
      });
    } else if (width <= 960) {
      setInitialValues({
        widthInitial: 400,
        cx: 200,
        cy: 150,
        legend: 0,
      });
    } else if (width <= 1281) {
      setInitialValues({
        widthInitial: 500,
        cx: 170,
        cy: 150,
        legend: 0,
      });
    } else if (width <= 1500) {
      setInitialValues({
        widthInitial: 500,
        cx: 200,
        cy: 150,
        legend: 0,
      });
    } else if (width < 1920) {
      setInitialValues({
        widthInitial: 800,
        cx: 250,
        cy: 150,
        legend: 50,
      });
    } else {
      setInitialValues({
        widthInitial: 800,
        cx: 320,
        cy: 150,
        legend: 200,
      });
    }
  };

  /**
   *  * Se encarga de mostrar el tooltip al pasar el mouse por encima de la barra seleccionada
   * @param {*} _
   * @param {*} index
   */
  const handlePieEnter = (_, index) => {
    setActiveIndex(index);
  };

  // * Suma de los primeros 5 valores de los conceptos
  const total = data.slice(0, 5).reduce((sum, entry) => sum + entry.value, 0);

  const legendFormatter = (value, entry) => (
    <span style={{ color: '#FFF' }}>{value}</span>
  );

  const truncateText = (text, maxLength) =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  return (
    <>
      {data.length <= 1 && data[0]?.price === 0 ? (
        <div
          style={{
            width: '100%',
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 style={{ color: 'white', margin: 'auto' }}>No hay datos</h1>
        </div>
      ) : (
        <PieChart
          width={widthInitial}
          height={width >= 815 ? 300 : width >= 601 ? 450 : 550}
          style={{
            width: '100%',
          }}
        >
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data.slice(0, 5)}
            cx={cx}
            cy={cy}
            outerRadius={120}
            labelLine={false}
            innerRadius={40}
            stroke="#2F2F2F"
            label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
              const RADIAN = Math.PI / 180;
              const radius = outerRadius + 10;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              return (
                <text
                  x={x}
                  y={y}
                  fill="white"
                  textAnchor={x > cx ? 'start' : 'end'}
                  dominantBaseline="central"
                  fontSize={width <= 1280 ? '14' : '16'}
                >
                  {value > 0.6 ? `${((value / total) * 100).toFixed(2)}%` : ''}
                </text>
              );
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={index}
                fill={colors[index % colors.length]}
                strokeWidth={activeIndex === index ? 0 : 6}
                onMouseEnter={() => handlePieEnter(entry, index)}
                onMouseLeave={() => setActiveIndex(null)}
              />
            ))}
          </Pie>
          <Legend
            payload={data.map((entry, index) => ({
              value: truncateText(entry.name, 20),
              type: 'circle',
              color: colors[index % colors.length],
            }))}
            layout={width >= 815 ? 'vertical' : 'horizontal'}
            verticalAlign={width >= 815 ? 'middle' : 'bottom'}
            align={width >= 815 ? 'right' : 'center'}
            formatter={legendFormatter}
            wrapperStyle={{ paddingRight: `${legend}px` }}
          />
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      )}
    </>
  );
};
