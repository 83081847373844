import { useState, useEffect } from 'react';

/**
 *  * La función useDebounce es un enlace personalizado en JavaScript que retrasa la actualización de un
 *  * valor hasta que haya pasado un retraso específico.
 * @param value - El valor es el valor de entrada que desea eliminar. Podría ser cualquier tipo de
 * valor, como una cadena, un número u un objeto.
 * @param delay - El parámetro de retraso es la cantidad de tiempo en milisegundos que desea esperar
 * antes de actualizar el valor antirrebote.
 * @returns El gancho `useDebounce` devuelve el `debouncedValue`.
 */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Configurar un temporizador para actualizar debouncedValue después del retraso.
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Limpia el temporizador en cada cambio de valor o desmontaje del componente.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
