import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CustomProgress from '../../../../components/Progress/progress.component';

const useStyles = makeStyles((theme) => ({
  root: {},
  colorCard: {
    background: theme.palette.ninethBackgroundColor,
  },
  titleCard: {
    color: theme.palette.primaryColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

export const CardChart = ({ title = '', componentProp, loading = false }) => {
  const classes = useStyles();

  return (
    <Card className={classes.colorCard}>
      <CardHeader title={title} className={classes.titleCard} />
      <CardContent>{loading ? <CustomProgress /> : componentProp}</CardContent>
    </Card>
  );
};
