import ResultTest from '../../../views/employees/bulkLoad/validation/ResultTest';
/**
 * * Verifica las restricciones en los valores de conceptos y devuelve un objeto con las restricciones de error, conceptos sin errores y conceptos con errores.
 *
 * @param {object} ValidatedConcepts - El objeto de conceptos validados.
 * @param {object} restrictions - El objeto de restricciones.
 * @return {object} Un objeto que contiene las restricciones de error, conceptos sin errores y conceptos con errores.
 */

export const CheckConstraintsInConceptValues = ({
  ValidatedConcepts,
  restrictions,
}) => {
  const errorConstraints = [];

  ValidatedConcepts.forEach((ValidatedConcept) => {
    const {
      TipoIngreso,
      TipoDeduccion,
      Cantidad,
      ValorIngreso,
      ValorDeduccion,
      Line,
      PorcentajeDeduccion,
      PorcentajeIngreso,
      DescripcionDeduccion,
      DescripcionIngreso,
    } = ValidatedConcept;
    const constraintKey = TipoIngreso || TipoDeduccion;
    const conceptType = TipoIngreso ? 'ingreso' : 'deducción';

    if (constraintKey) {
      const { cantidad, valor, porcentaje, descripcion } = restrictions[
        constraintKey
      ];

      if (
        cantidad &&
        Cantidad !== undefined &&
        (Cantidad < cantidad.min || Cantidad > cantidad.max)
      ) {
        const error = new ResultTest(
          `La Cantidad(Días u Horas)  debe estar entre ${cantidad.min} y ${cantidad.max}.`,
          Line,
          'Cantidad(Días u Horas)',
        );
        ValidatedConcept.Error = ValidatedConcept.Error || [];
        ValidatedConcept.Error.push(error);
        errorConstraints.push(error);
      }

      if (
        valor &&
        ((TipoIngreso && ValorIngreso < valor.min) ||
          (TipoDeduccion && ValorDeduccion < valor.min) ||
          (TipoIngreso && ValorIngreso > valor.max) ||
          (TipoDeduccion && ValorDeduccion > valor.max))
      ) {
        const error = new ResultTest(
          `El valor debe estar entre ${valor.min.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })} y ${valor.max.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}.`,
          Line,
          `Valor ${conceptType}`,
        );
        ValidatedConcept.Error = ValidatedConcept.Error || [];
        ValidatedConcept.Error.push(error);
        errorConstraints.push(error);
      }

      if (
        porcentaje &&
        ((TipoIngreso && PorcentajeIngreso < porcentaje.min) ||
          (TipoDeduccion && PorcentajeDeduccion < porcentaje.min) ||
          (TipoIngreso && PorcentajeIngreso > porcentaje.max) ||
          (TipoDeduccion && PorcentajeDeduccion > porcentaje.max))
      ) {
        const error = new ResultTest(
          `El porcentaje debe estar entre ${porcentaje.min}% y ${porcentaje.max}%.`,
          Line,
          `Porcentaje ${conceptType}`,
        );
        ValidatedConcept.Error = ValidatedConcept.Error || [];
        ValidatedConcept.Error.push(error);
        errorConstraints.push(error);
      }

      if (
        descripcion &&
        ((TipoIngreso && DescripcionIngreso.length < descripcion.min) ||
          (TipoDeduccion && DescripcionDeduccion.length < descripcion.min) ||
          (TipoIngreso && DescripcionIngreso.length > descripcion.max) ||
          (TipoDeduccion && DescripcionDeduccion.length > descripcion.max))
      ) {
        const error = new ResultTest(
          `La descripción debe tener entre ${descripcion.min} y ${descripcion.max} caracteres.`,
          Line,
          `Descripción ${conceptType}`,
        );
        ValidatedConcept.Error = ValidatedConcept.Error || [];
        ValidatedConcept.Error.push(error);
        errorConstraints.push(error);
      }
    }
  });

  const ConceptsOk = ValidatedConcepts.filter((e) => e.Error === null);
  const ConceptsError = ValidatedConcepts.filter((e) => e.Error !== null);

  return { errorConstraints, ConceptsOk, ConceptsError };
};
