import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import { Grid } from '@material-ui/core';

import RetiredIcon from '../../../../images/icons/retired.png';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.thirdBackgroundColor,
    color: theme.palette.primaryColor,
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
  },
  header: {
    color: theme.palette.primaryColor,
    paddingBottom: 0,
    paddingTop: 0,
  },
  title: {
    color: theme.palette.primaryColor,
    wordBreak: 'break-all',
  },
  icon: {
    color: '#ED8D84',
  },
  iconAction: {
    color: theme.palette.primaryColor,
  },
  content: {
    fontSize: 14,
    color: theme.palette.primaryColor,
  },

  wordBreak: {
    wordBreak: 'break-all',
  },
}));

export const RetireCard = React.memo(
  ({ handleDelete, handleEdit, fechaRetiro, causalretirodescripcion }) => {
    const classes = useStyles();

    return (
      <Card className={`${classes.root}`}>
        <CardHeader
          className={classes.header}
          avatar={<img src={RetiredIcon} alt="icon" />}
          action={
            <>
              <Tooltip title="Editar">
                <IconButton onClick={handleEdit}>
                  <CreateIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                <IconButton onClick={handleDelete}>
                  <DeleteIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>
            </>
          }
          classes={{
            title: classes.title,
          }}
          title={
            <>
              <b>Fecha retiro:</b> {format(new Date(fechaRetiro), 'dd/MM/yyyy')}
            </>
          }
        />
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <span className={classes.wordBreak}>
                Causal de retiro: {causalretirodescripcion}
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  },
);
