import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { calculateTotalPayroll } from '../helpers/calculatePayroll';
import { readDataPayroll } from '../helpers/payroll.helper';

import { showErrorMessage } from '../utils/showErrorService';
import { types } from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

/**
 * Consulta listado nominas asociadas a un empleado
 * @param {*} id
 * @returns
 */
const getPayrollEmployeeById = async (id) => {
  let config = defaultHeaders();
  Object.assign(config.headers, {
    Detallenominaid: Number(id),
  });

  const response = await axiosApiInstance.get(
    `${urlDocument}/Documento/GetPayrollEmployeeDetalleId`,
    config,
  );
  return response;
};

/**
 * Consulta información de nomina asociada a un empleado
 * @param {*} data
 * @returns
 */
export const getPayrollEmployeeAction = (id, continueProcess) => async (
  dispatch,
) => {
  try {
    changeLoadingPayroll(dispatch, true);

    const resp = await getPayrollEmployeeById(id);

    const soporte = readDataPayroll(resp?.data?.result);

    dispatch({
      type: types.LOAD_PAYROLL_ADJUSTMENT,
      payload: soporte,
    });

    dispatch({
      type: types.FILL_INCOMES_PAYROLL,
      payload: soporte.ingresos,
    });

    dispatch({
      type: types.FILL_EXPENSES_PAYROLL,
      payload: soporte.deducciones,
    });

    continueProcess();
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error consultando información de nómina de empleado.',
    );
  } finally {
    changeLoadingPayroll(dispatch, false);
  }
};

const changeLoadingPayroll = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_PAYROLL,
    payload: status,
  });
};

/**
 * Actualiza totales de nomina
 * @returns
 */
export const updateTotalPayrollAction = (incomes, expenses) => async (
  dispatch,
  getState,
) => {
  const { payrollData } = getState().payrollAdjustmentReducer;
  const totals = calculateTotalPayroll(incomes, expenses);

  const soporte = {
    ...payrollData,
    ...totals,
  };

  dispatch({
    type: types.LOAD_PAYROLL_ADJUSTMENT,
    payload: soporte,
  });
};

/**
 * Agrega observaciones a nomina
 * @returns
 */
export const setObservationsAction = (observation) => async (dispatch) => {
  dispatch({
    type: types.SET_OBSERVATIONS_PAYROLL,
    payload: observation,
  });
};

/**
 * *Consultar tipo de licnecia
 * * empleado o documento
 */
export const getTypeLicenseAction = (
  license,
  readResponseLicense,
  sendPayroll,
) => async (dispatch) => {
  try {
    changeLoadingRegisterPayrollAdjustment(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      panio: license.year,
      pmes: license.month,
      pempresaid: license.empresaid,
      pempleadoid: license.empleadoId,
    });
    const { data } = await axiosApiInstance.get(
      `${urlDocument}/Documento/verifyEmployeeLicense`,
      config,
    );

    if (data.result !== null) {
      if (data.result?.licenseActiveBase === false) {
        dispatch({
          type: types.SET_LICENSE_BASE,
          payload: data.result,
        });
        readResponseLicense(data.result);
        return 'no license';
      } else {
        dispatch({
          type: types.SET_TYPE_LICENSE,
          payload: data.result,
        });
        sendPayroll();
        return 'license emp';
      }
    }
    sendPayroll();
    return 'license doc';
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error consultando el tipo de licnecia.',
    );
  } finally {
    changeLoadingRegisterPayrollAdjustment(dispatch, false);
  }
};

/**
 * Registra nota de ajuste
 * @returns
 */
export const registerPayrollAdjustmentAction = (
  data,
  readResponseRegister,
) => async (dispatch, getState) => {
  try {
    changeLoadingRegisterPayrollAdjustment(dispatch, true);

    const { typeLicense } = getState().payrollAdjustmentReducer;
    console.log(
      `Si license retorna un "null" = Documento, si retorna un objeto = Empleado   typeLicense: `,
      typeLicense,
    );

    data = {
      ...data,
      TipoLicencia: typeLicense?.empleadoId || null,
    };

    const resp = await axiosApiInstance.post(
      `${urlDocument}/Documento/RegisterDocumentPayrollAdjust`,
      data,
      defaultHeaders(),
    );

    if (!resp?.data?.result?.detalleNominaId) {
      readResponseRegister(resp?.data);
      return;
    }

    const response = await getPayrollEmployeeById(
      resp.data.result.detalleNominaId,
    );

    updatePayrollEmployee(dispatch, getState, response?.data?.result);
    readResponseRegister(resp?.data);
  } catch (err) {
    readResponseRegister(err.response ?? null);
    showErrorMessage(
      err,
      'Ha ocurrido un error en registro de nominas de ajuste.',
    );
  } finally {
    changeLoadingRegisterPayrollAdjustment(dispatch, false);
  }
};

const changeLoadingRegisterPayrollAdjustment = (dispatch, status) => {
  dispatch({
    type: types.LOADING_ADD_PAYROLL_ADJUSTMENT,
    payload: status,
  });
};

const updatePayrollEmployee = (dispatch, getState, nuevaNomina) => {
  const { payrollListEmployee } = getState().payrollHistoryReducer;
  const payrollItem = payrollListEmployee.find(
    (c) => c.nominaId === nuevaNomina.nominaId,
  );

  if (payrollItem) {
    let historial = payrollItem?.historial ?? [];
    delete payrollItem?.historial;
    historial = [payrollItem, ...historial];

    const data = {
      ...nuevaNomina,
      historial: historial,
    };

    const newPayroll = payrollListEmployee.map((item) =>
      item.nominaId === nuevaNomina.nominaId ? { ...data } : item,
    );
    dispatch({
      type: types.UPDATE_PAYROLL_HISTORY,
      payload: newPayroll,
    });
  }
};

/**
 * Limpia información reducer ajuste nomina
 * @returns
 */
export const cleanPayrollAdjustmentAction = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_PAYROLL_ADJUSTMENT,
  });

  dispatch({
    type: types.CLEAN_INCOMES_REDUCER,
  });

  dispatch({
    type: types.CLEAN_EXPENSE_REDUCER,
  });
};
