import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import StyledCheckbox from '../checkForm/styledCheckbox.component';
import Link from '@material-ui/core/Link';
import { FormControl, Grid, Tooltip } from '@material-ui/core';

import { FixedSizeList } from 'react-window';

import InputIcon from '../input/inputIcon.component';
import SelectFilter from '../select/selectFilter.component';
import PopoverModal from '../../components/modal/popoverModal.component';
import CustomProgress from '../Progress/progress.component';
import { PayrollStatus } from './payrollStatus';

import {
  addCheckedItemAction,
  checkAllPayrollItemsAction,
  getPayrollToReportAction,
  getPayrollToSupportAction,
  updateFilterDataAction,
} from '../../actions/payrollReportAction';

import isEmpty from '../../utils/isEmpty';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '68.5vh',
    minHeight: '68.5vh',
    color: '#FFFF',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
  },
  customItem: {
    '&:hover': {
      border: '1px solid #16B1F3',
      borderRadius: 5,
    },
    borderRadius: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
  },
  customItemSelected: {
    backgroundColor: '#16B1F3 !important',
  },
  itemFilter: {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  itemText: {
    fontSize: 18,
    fontWeight: 700,
    padding: '10px 5px',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'left',
    color: '#FFFFFF',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  actions: {
    width: '100%',
    textAlign: 'end',
  },
  checkItem: {
    width: '100%',
    textAlign: 'left',
  },
  letter: {
    color: '#FFFFFF',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
}));

const sortList = [
  {
    value: 1,
    text: 'A-Z',
  },
  {
    value: 2,
    text: 'Z-A',
  },
  {
    value: 3,
    text: 'Favoritos primero',
  },
];

const animate = 'animate__animated animate__fadeIn animate__faster';

export const PayrollEmployeeList = React.memo(({ filterData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkAllItem, setCheckAllItem] = useState(false);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('1');
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [selected, setSelected] = useState(0);
  const checkboxRef = useRef(null);

  //Redux
  const { payrollListReport, loadingList, activeSuport } = useSelector(
    (s) => s.payrollReportReducer,
  );

  const payrollListMemo = useMemo(() => {
    return payrollListReport;
  }, [payrollListReport]);

  // Infinity Scroll
  //const externalRef = useRef();
  // const { isNearScreen } = useNearScreen({
  //   externalRef: payrollListReport.length === 0 ? null : externalRef,
  //   distance: '150px',
  //   once: false,
  // });

  /**
   * Realiza nueva consulta cuando cambia información de filtro
   */
  useEffect(() => {
    const { Anio, Mes } = filterData;
    if (Anio && Mes) {
      const today = new Date();
      const period = new Date(Anio, Mes - 1, 1);
      if (period <= today) dispatch(getPayrollToReportAction(filterData));
    }
  }, [dispatch, filterData]);

  /**
   * Marca selccion sobre item
   */
  useEffect(() => {
    if (activeSuport) {
      setSelected(activeSuport.empleadoid);
    }
  }, [dispatch, activeSuport]);

  /**
   * * Genera el simulado del click
   */
  useEffect(() => {
    if (
      filterData.CargueMasivo &&
      filterData.CargueMasivoSelected &&
      !loadingList
    ) {
      simulateClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, loadingList]);

  /**
   * * Simula el click en todos los checkboxes cuando es cargue masivo
   */
  const simulateClick = () => {
    const checkboxElement = checkboxRef.current?.querySelector('input');
    if (checkboxElement) {
      checkboxElement.checked = true;
      handleChangeAllItems({ target: checkboxElement });
    }
  };

  /**
   * Consulta información de soporte par empleado seleccionado
   * @param {*} nominaId
   * @param {*} empleadoId
   * @returns
   */
  const handleItemSelection = (nominaId, empleadoId) => {
    setSelected(empleadoId);
    dispatch(getPayrollToSupportAction(nominaId, empleadoId));
  };

  /**
   * Marca seleccion sobre item de nomina
   * @param {*} param0
   */
  const handleChangeCheck = ({ target }) => {
    const { name, checked } = target;
    dispatch(addCheckedItemAction(Number(name), checked));
  };

  /**
   * Selecciona todos los items de la nomina
   * @param {*} param0
   */
  const handleChangeAllItems = ({ target }) => {
    setCheckAllItem(target.checked);
    dispatch(checkAllPayrollItemsAction(target.checked));
  };

  /**
   * Sincroniza nuevo valor filtro
   * @param {*} param0
   */
  const handleChangeFilter = ({ target }) => {
    setFilter(target.value);
    if (isEmpty(target.value)) {
      dispatch(
        updateFilterDataAction({
          ...filterData,
          Filtro: '',
          Offset: 0,
          LoadMore: false,
        }),
      );
    }
  };

  /**
   * Filtra listado por documento y nombre de empleado
   */
  const handleSearchFilter = () => {
    dispatch(
      updateFilterDataAction({
        ...filterData,
        Filtro: filter,
        Offset: 0,
        LoadMore: false,
      }),
    );
  };

  /**
   * Filtra documentos por estado
   * @param {*} param0
   */
  const handleCheckFilter = ({ target }) => {
    const { name, checked } = target;

    let newFilter = { ...filterData };
    if (name === 'confirmado') {
      newFilter = {
        ...newFilter,
        Confirmado: checked,
        Offset: 0,
        LoadMore: false,
      };
    }
    if (name === 'noconfirmado') {
      newFilter = {
        ...newFilter,
        NoConfirmado: checked,
        Offset: 0,
        LoadMore: false,
      };
    }
    if (name === 'transmitido') {
      newFilter = {
        ...newFilter,
        Transmitido: checked,
        Offset: 0,
        LoadMore: false,
      };
    }
    if (name === 'favorito') {
      newFilter = {
        ...newFilter,
        Favorito: checked,
        Offset: 0,
        LoadMore: false,
      };
    }
    if (name === 'retirado') {
      newFilter = {
        ...newFilter,
        Retirado: checked,
        Offset: 0,
        LoadMore: false,
      };
    }
    if (name === 'carguemasivo') {
      newFilter = {
        ...newFilter,
        CargueMasivo: checked,
        Offset: 0,
        LoadMore: false,
      };
    }

    dispatch(updateFilterDataAction(newFilter));
  };

  /**
   * Limpia filtro por estado
   */
  const handleCleanFilter = () => {
    handleChangeAllItems({
      target: {
        checked: false,
      },
    });
    dispatch(
      updateFilterDataAction({
        ...filterData,
        Confirmado: false,
        NoConfirmado: false,
        Transmitido: false,
        Retirado: false,
        Favorito: false,
        Offset: 0,
        LoadMore: false,
        CargueMasivo: false,
        CargueMasivoSelected: false,
      }),
    );
  };

  /**
   * Abre filtro por estado
   * @param {*} event
   */
  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  /**
   * Cierra filtro estado
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Ordena listado
   * @param {*} param0
   */
  const handleChangeSort = ({ target }) => {
    dispatch(
      updateFilterDataAction({
        ...filterData,
        TipoOrdenamiento: Number(target.value),
        Offset: 0,
        LoadMore: false,
      }),
    );
    setSort(target.value);
  };

  /**
   * Filtro por estado
   * @returns
   */
  const modalFilters = () => {
    return (
      <div style={{ paddingLeft: 10, fontSize: 18 }}>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Confirmado}
              onChange={handleCheckFilter}
              name="confirmado"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Confirmados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.NoConfirmado}
              onChange={handleCheckFilter}
              name="noconfirmado"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Sin confirmar
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Transmitido}
              onChange={handleCheckFilter}
              name="transmitido"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Transmitidos
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Retirado}
              onChange={handleCheckFilter}
              name="retirado"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Retirados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.Favorito}
              onChange={handleCheckFilter}
              name="favorito"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Favoritos
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterData.CargueMasivo}
              onChange={handleCheckFilter}
              name="carguemasivo"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Cargue Masivo
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={handleCleanFilter}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Row = ({ index, key, style }) => {
    const item = payrollListMemo[index];
    const labelId = `checkbox-list-label-${item.detallenominaid}`;
    return (
      <div>
        <div key={key} style={style} className="post">
          <div
            className={`${classes.customItem} ${
              Number(selected) === item.empleadoid && classes.customItemSelected
            }`}
          >
            <Grid container>
              <Grid item lg={1} xs={1} className={classes.checkItem}>
                <StyledCheckbox
                  edge="start"
                  checked={Boolean(item.seleccionado)}
                  tabIndex={-1}
                  name={`${item.empleadoid}`}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  onChange={handleChangeCheck}
                  disabled={item.estadonominaid !== 1}
                />
              </Grid>
              <Grid item lg={9} xs={9}>
                <div
                  className={classes.itemText}
                  onClick={() =>
                    handleItemSelection(item.nominaid, item.empleadoid)
                  }
                >
                  {item.nombre}
                </div>
              </Grid>
              <Grid item lg={2} xs={2}>
                <div className={classes.actions}>
                  <PayrollStatus
                    item={item}
                    isSelected={item.empleadoid === selected}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${animate} ${classes.root}`}>
      <div className={classes.itemFilter}>
        <div style={{ alignSelf: 'center' }} ref={checkboxRef}>
          <StyledCheckbox
            name={'checkAllItem'}
            edge="start"
            checked={checkAllItem}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'filter' }}
            onChange={handleChangeAllItems}
            disabled={loadingList}
          />
        </div>
        <div className={classes.itemText}>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item lg={8} xs={8}>
              <InputIcon
                label={'Buscar empleados'}
                name={'filtro'}
                maxLength={200}
                value={filter}
                onChange={handleChangeFilter}
                onSearch={handleSearchFilter}
                disabled={loadingList}
              />
            </Grid>
            <Grid item lg={3} xs={3}>
              <FormControl style={{ width: '100%' }}>
                <SelectFilter
                  name={'ordenar'}
                  value={sort}
                  options={sortList}
                  onChange={handleChangeSort}
                  label={''}
                  disabled={loadingList}
                />
              </FormControl>
            </Grid>
            <Grid item lg={1} xs={1}>
              <Tooltip title="Filtro">
                <IconButton onClick={handleOpenModalChecks} disabled={false}>
                  <img alt={'Filtrar'} width="20px" src={filterImage} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>

      {!loadingList && payrollListMemo.length === 0 && (
        <div className={classes.emptylist}>
          <span>No se han encontrado resultados...</span>
        </div>
      )}

      {!loadingList ? (
        <FixedSizeList
          width={1400}
          height={700}
          itemCount={payrollListMemo.length}
          itemSize={65}
          style={{
            width: '100% !important',
            position: 'inital',
            height: '59vh',
            overflowX: 'hidden',
            backgroundColor: 'transparent',
            borderRadius: 5,
          }}
        >
          {Row}
        </FixedSizeList>
      ) : (
        <div>
          <CustomProgress />
        </div>
      )}

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
    </div>
  );
});
