import {
  GET_MENU_MYACCOUNT,
  GET_MENU_EMPLOYEES,
  GET_MENU_HELP,
  GET_MENU_PAYROLL_BULKLOAD,
} from '../actions/types';

const initialState = {
  menuMyAccount: {
    titlePrefijo: '',
    messagePrefijo: '',
    bottonPrefijo: '',
    urlPrefijo: '',
    titlePopCreateBranch: '',
    messagePopCreateBranch: '',
    messageBodyPopBranch: '',
    messageSkipBranch: '',
    messageProgressBranch: '',
  },
  menuPayrollBulkLoad: {
    titleTooltipRegistrado: '',
    messageTooltiptitleTooltipRegistrado: '',
    statusTooltipRegistrado: '',
    titleTooltipTransmitido: '',
    messageTooltiptitleTooltipTransmitido: '',
    statusTooltipTransmitido: '',
    titleTooltipError: '',
    messageTooltiptitleTooltipError: '',
    statusTooltipError: '',
    titleTooltipMixto: '',
    messageTooltiptitleTooltipMixto: '',
    statusTooltipMixto: '',
  },
  menuEmployees: null,
  menuHelp: {
    titlePrivacyPolicy: '',
    titleProtectionData: '',
    buttonProtectionData: '',
    urlProtectionData: '',
    titleTermAndConditions: '',
    buttonTermAndConditions: '',
    urlTermAndConditions: '',
    urlFrequentQuestions: '',
    buttonFrequentQuestions: '',
    messageReportProblem: '',
    messageContact: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MENU_MYACCOUNT:
      return {
        ...state,
        menuMyAccount: action.payload,
      };
    case GET_MENU_PAYROLL_BULKLOAD:
      return {
        ...state,
        menuPayrollBulkLoad: action.payload,
      };
    case GET_MENU_EMPLOYEES:
      return {
        ...state,
        menuEmployees: action.payload,
      };
    case GET_MENU_HELP:
      return {
        ...state,
        menuHelp: action.payload,
      };
    default:
      return state;
  }
}
