import isEmpty from '../utils/isEmpty';

export const readDataPayroll = (data) => {
  if (!data) return { ingresos: [], deducciones: [] };

  let ingresos = data.ingresos ?? [];
  ingresos = ingresos.map((item) => ({
    identificador: item.id,
    conceptoid: item.conceptoid,
    concepto: item.concepto,
    porcentaje: item.aplicaPorcentaje ? item.porcentaje : null,
    cantidad: item.aplicaCantidad ? item.cantidad : null,
    valor: item.aplicaValor ? item.valor : null,
    descripcion: item.aplicaDescripcion ? item.descripcion : null,
    unidadmedida: item.unidadMedida,
    aplicaCantidad: item.aplicaCantidad,
    aplicaDescripcion: item.aplicaDescripcion,
    aplicaPorcentaje: item.aplicaPorcentaje,
    aplicaValor: item.aplicaValor,
  }));

  let deducciones = data.deducciones ?? [];
  deducciones = deducciones.map((item) => ({
    identificador: item.id,
    conceptoid: item.conceptoid,
    concepto: item.concepto,
    porcentaje: item.aplicaPorcentaje ? item.porcentaje : null,
    cantidad: item.aplicaCantidad ? item.cantidad : null,
    valor: item.aplicaValor ? item.valor : null,
    descripcion: item.aplicaDescripcion ? item.descripcion : null,
    unidadmedida: item.unidadMedida,
    codigo: item.codigo,
    aplicaCantidad: item.aplicaCantidad,
    aplicaDescripcion: item.aplicaDescripcion,
    aplicaPorcentaje: item.aplicaPorcentaje,
    aplicaValor: item.aplicaValor,
  }));

  const soporte = {
    ...data,
    soporteid: data?.detalleNominaId ?? null,
    valornomina: isEmpty(data.valornomina) ? 0 : parseFloat(data.valornomina),
    ingresos: ingresos,
    deducciones: deducciones,
    observacion: data.observacion ?? '',
  };

  return soporte;
};
