import React, { useState, useRef, useEffect } from 'react';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ButtonPrimary from '../../button/buttonPrimary.component';
import SelectForm from '../../select/select.component';

const useStyles = makeStyles((theme) => ({
  contentBody: {
    padding: '0 3em',
  },
}));

export const ChoosingNoteForm = React.memo(
  ({ tipoDocumento, handleConfirmNote, loading }) => {
    const classes = useStyles();
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
      .current;
    const [, forceUpdate] = useState();
    const [noteOption, setNoteOption] = useState('');
    const [options, setOptions] = useState([]);

    /**
     * Define opciones disponibles segun tipo de documento
     */
    useEffect(() => {
      if (tipoDocumento === 102) {
        setOptions([
          { value: '1', text: 'Editar' },
          { value: '2', text: 'Eliminar' },
        ]);
      } else {
        setOptions([{ value: '1', text: 'Editar' }]);
        setNoteOption('1');
      }
    }, [tipoDocumento]);

    /**
     * Sincroniza valor con state
     * @param {*} param0
     */
    const syncChanges = ({ target }) => {
      setNoteOption(target.value);
    };

    /**
     * Confirma formulario
     * @param {} e
     * @returns
     */
    const handleSubmit = (e) => {
      e.preventDefault();

      if (!validator.allValid()) {
        validator.showMessages();
        forceUpdate(Math.random);
        return;
      }
      handleConfirmNote(noteOption);
    };

    return (
      <Grid container>
        <Grid item lg={12} xs={12} className={classes.contentBody}>
          <div className="modalEmail">
            Ten en cuenta las siguientes opciones al editar este desprendible.
          </div>
          <div className="modalEmail">
            <span style={{ color: '#16b1f3' }}>Editar: </span> Podrás realizar
            los cambios que requieras, ten en cuenta que se remplazará el
            desprenderle original con el nuevo documento que generes.
          </div>
          <div className="modalEmail">
            <span style={{ color: '#16b1f3' }}>Eliminar: </span>
            Borrarás completamente el desprendible de nómina generado, este
            desaparecerá del empleado y de su lista de desprendibles, no
            obstante podrás verlo en el tab de editados.
          </div>
          <div className="modalEmail">¿Que deseas hacer?</div>
          <div style={{ margin: '0 auto' }} md={5} sm={5} xs={5}>
            <SelectForm
              label={'Seleccione *'}
              name="noteOption"
              value={noteOption}
              options={options}
              validator={validator}
              validateOptions={'required'}
              onChange={syncChanges}
            />
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm style={{ margin: '0 auto' }}>
          <ButtonPrimary
            text={'Continuar'}
            onClick={handleSubmit}
            type={'button'}
            style={{ color: '#FFF' }}
            loading={loading}
          />
        </Grid>
        <br></br>
      </Grid>
    );
  },
);
