import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { getComponentName } from '../../utils/general.js';
import {
  cleanMenuComponentAction,
  getMenuComponentAction,
} from '../../actions/configAction';
import colnominaLogo from '../../../src/images/logo-small.png';

const styles = () => ({
  descriptionSpan: {
    backgroundColor: 'red',
    width: '835px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
  },
  descriptionName: {
    height: '26px',
    width: '372px',
    color: '#848181',
    fontFamily: 'Muli',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '26px',
  },
  divColor: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
    width: '935px',
  },
  spanVersion: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '20px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '30px',
    textAlign: 'center',
    margin: 'auto',
  },
  spanPowerBy: {
    height: '32px',
    width: '835px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  divPowerBy: {
    marginLeft: 50,
  },
  textPolicy: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
  },
  divText: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
    marign: 'auto',
  },
  divText2: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontStyle: 'italic',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
    marginLeft: 50,
    marginRight: 50,
  },
  mainGrid: {
    margin: 'auto',
  },
});

export class About extends Component {
  constructor() {
    super();
    this.state = {
      components: [],
      versionAplication: '',
    };
  }

  componentDidMount() {
    this.props.getMenuComponentAction(9);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    )
      update.components = nextProps.configReducer.components;

    if (nextProps.versionAplication !== state.versionAplication)
      update.versionAplication = nextProps.versionAplication;

    return Object.keys(update).length ? update : null;
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid container spacing={2}>
        <Grid item md={8} className={classes.mainGrid}>
          <div className={classes.textPolicy}>
            <br />
            <div className={classes.divText}>
              <img src={colnominaLogo} alt="logo" />
            </div>
            <br />
            <div className={classes.divText2}>
              {getComponentName(
                this.state.components,
                76,
                129,
                'Bienvenido a Colfactura nómina, desde esta plataforma se permitirá trasmitir Documento Soportes de Pago de Nómina Electrónica que se envía a la DIAN y da a las empresas la posibilidad de tomar los pagos a sus empleados como un soporte de costos y deducciones a través de un archivo XML. Así mismo, permite generar. Así mismo, permitirá un registro detallado de tus comprobantes de pago de Nómina disponibles trasmitidos y por trasmitir de una mamera fácil y sencilla, al alcance de un clic sin complicaciones de procesos ni configuraciones adicionales.',
              )}
              <br />
              <hr color={'#979797'} size={1} />
              <br />
              <div className={classes.spanVersion}>
                {this.state.versionAplication}
              </div>
            </div>
            <br />
            <br />
            <div className={classes.divPowerBy}>
              <span className={classes.spanPowerBy}>
                {getComponentName(
                  this.state.components,
                  78,
                  131,
                  'Powered By: GSE (Gestión de Seguridad Electrónica S.A)',
                )}
              </span>
              <br />
              <br />
            </div>
          </div>
          <br />
        </Grid>
      </Grid>
    );
  }
}

About.propTypes = {};

const mapStateToProps = (state) => ({
  configReducer: state.configReducer,
  versionAplication: state.menuReducer.menuMyAccount?.versionAplication || '',
});

export default connect(mapStateToProps, {
  getMenuComponentAction,
  cleanMenuComponentAction,
})(withStyles(styles)(About));
