import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import BranchOfficeCard from '../../../components/card/branchOfficeCard.component';
import CollapsePanel from '../../../components/collapse/collapse.component';
import { BranchOfficeForm } from './form';
import InputIcon from '../../../components/input/inputIcon.component';
import { useMyAccount } from '../../../hooks/useMyAccount';
// store
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { filterByPlace } from '../../../actions/configAction';
import NotificationNotCreated from '../../../components/notification/notificationNotCreated.component';

import { SuccessAlert2 } from '../../../helpers/alert.helpers';

/**
 * @description Filtra listado sucursales
 * @param {*} list Listado sucursales
 * @param {*} filter Filtro
 */
function filerList(list, filter) {
  if (filter === '' || filter === null || filter === undefined) return list;

  filter = filter.toLowerCase();
  let newList = list.filter((x) => {
    const nombre = x.nombre.toLowerCase().indexOf(filter);
    const direccion = x.direccion.toLowerCase().indexOf(filter);
    const sucursal = filterByPlace(x.ciudadid, filter);
    if (nombre > -1) return true;
    else if (direccion > -1) return true;
    else if (sucursal > -1) return true;
    return null;
  });
  return newList;
}
// selectors from store, filter and sort integrated
const selectFilteredPayrolls = createSelector(
  (state) => state.branchOfficeReducer.filteredBranchOfficesList?.data || [],
  (_, filter) => filter,
  (todos, filter) => {
    return filerList(todos, filter);
  },
);

const Branch = React.memo(() => {
  const dispatch = useDispatch();
  const [txtFilter, setTxtFilter] = useState('');
  const [expandPanelBranch, setExpandPanelBranch] = useState('');
  // Custom hook
  const { saveLoadBranch, cleanResponses } = useMyAccount();

  // store
  const listBranchStored = useSelector((state) =>
    selectFilteredPayrolls(state, txtFilter),
  );

  const isEditing = useSelector(
    (state) => state.branchOfficeReducer.editBranchStatus || false,
  );
  const updateResponse = useSelector(
    (state) => state.branchOfficeReducer.responseUpdateBranchOffice || null,
  );
  const updateStateResponse = useSelector(
    (state) =>
      state.branchOfficeReducer.responseUpdateStateBranchOffice || null,
  );

  const createResponse = useSelector(
    (state) => state.branchOfficeReducer.responseSaveBranchOffice || null,
  );
  const menuMyAccount = useSelector(
    (state) => state.menuReducer.menuMyAccount || null,
  );

  /**
   * Desmontando componente
   */
  useEffect(() => {
    return () => {
      saveLoadBranch(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      (createResponse && createResponse?.statusCode === '200') ||
      createResponse?.statusCode === '201'
    ) {
      changeCollapsableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResponse]);

  useEffect(
    (prevState) => {
      if (
        updateResponse &&
        (updateResponse.statusCode === '200' ||
          updateResponse.statusCode === '201')
      ) {
        setExpandPanelBranch('');
      }
      cleanResponses();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateResponse],
  );

  useEffect(
    () => {
      if (
        updateStateResponse &&
        (updateStateResponse.statusCode === '200' ||
          updateStateResponse.statusCode === '201')
      ) {
        SuccessAlert2(
          menuMyAccount.titlePopUpdateBranch,
          menuMyAccount.messagePopUpdateBranch.replace(
            '$nombreSucursal',
            updateStateResponse.nombre,
          ),
        );
      }
      cleanResponses();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateStateResponse],
  );

  //NO DEBE IR, Fernando Ruiz
  // useEffect(
  //   (prevState) => {
  //     if (
  //       updatePrincipalBranchResponse &&
  //       (updatePrincipalBranchResponse.statusCode === '200' ||
  //         updatePrincipalBranchResponse.statusCode === '201')
  //     ) {
  //       setExpandPanelBranch('');

  //       SuccessAlert2(
  //         menuMyAccount.titlePopUpdateBranch,
  //         menuMyAccount.messagePopUpdateBranch.replace(
  //           '$nombreSucursal',
  //           updatePrincipalBranchResponse.result,
  //         ),
  //       );
  //     }
  //     cleanResponses();
  //   },
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  //   [updatePrincipalBranchResponse],
  // );

  /**
//* Consulta sucursal principal
//*/
  /**
   * Evento, escucha evento para edición de sucursal,
   * consume función para cargar información en Redux
   * @param {*} e Evento
   * @param {*} data Infromación sucursal
   */
  const editBranchOffice = (e, data) => {
    e.preventDefault();
    saveLoadBranch(data, true);
    setExpandPanelBranch('panel');
    window.scrollTo(0, 0);
  };

  /** 
  //* Evento, Cambia estado de collapse expande o contrae
  //*/
  const changeCollapsableState = () => {
    if (isEditing) {
      setExpandPanelBranch(expandPanelBranch === 'panel' ? '' : 'panel');
    } else {
      setExpandPanelBranch(expandPanelBranch === 'panel' ? '' : 'panel');
    }
  };
  /**
   * Evento, filtra listado se sucursales cuado detecta cambio
   * en filtro.
   * @param {*} e Evento
   */
  const onChangeFilter = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setTxtFilter(value);
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid item xs={12}>
          <CollapsePanel
            component={BranchOfficeForm}
            title={isEditing ? 'Editar sucursal' : 'Crear nueva sucursal'}
            titleOpen={isEditing ? 'Editar sucursal' : 'Nueva sucursal'}
            open={expandPanelBranch}
            onClick={() => changeCollapsableState()}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={12} xs={12}>
          <InputIcon
            name={'txtFilter'}
            label={'Buscar sucursal'}
            value={txtFilter}
            onChange={(e) => {
              onChangeFilter(e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <>
          {listBranchStored && listBranchStored.length > 0 ? (
            <>
              {listBranchStored.map((data, index) => (
                <Grid item lg={4} xs={12} key={index}>
                  <BranchOfficeCard data={data} onClick={editBranchOffice} />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item lg={12} xs={12}>
              <NotificationNotCreated text={'No tienes sucursales creadas'} />
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
});

export default Branch;
