import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { getIncomesExpensesAction } from '../actions/configAction';

import incomesExpensesService from '../services/incomExpService';
import { showErrorMessage } from '../utils/showErrorService';

/**
 * Realiza operaciones asociadas con el componente de ingresos y deducciones
 */
export const useIncomeExpense = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [dataUserSaved] = useState([{ id: null, value: null }]);

  // store data
  const listIncomes = useSelector(
    (state) => state.configReducer.getIncomesExpenses.arrayIncomes,
  );

  const listExpenses = useSelector(
    (state) => state.configReducer.getIncomesExpenses.arrayExpenses,
  );

  const fillIncomesExpenses = useCallback(() => {
    let arrayIncomes = null;
    let arrayExpenses = null;

    incomesExpensesService
      .getIncomesExpensesService()
      .then((results) => {
        if (results.status === 200 && results.data.length > 0) {
          arrayIncomes = results.data.filter(
            (response) => response.ingreso === true,
          );

          arrayExpenses = results.data.filter(
            (response) => response.ingreso === false,
          );

          arrayIncomes.sort((a, b) =>
            a.descripcion.localeCompare(b.descripcion, 'es', {
              sensitivity: 'base',
            }),
          );
          arrayExpenses.sort((a, b) =>
            a.descripcion.localeCompare(b.descripcion, 'es', {
              sensitivity: 'base',
            }),
          );
        }

        dispatch(
          getIncomesExpensesAction({
            arrayIncomes,
            arrayExpenses,
          }),
        );
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando datos para este concepto');
        setLoading(false);
      });
  }, [dispatch]);

  return {
    fillIncomesExpenses,
    loading,
    dataUserSaved,
    // store
    listIncomes,
    listExpenses,
  };
};
