import { types } from '../actions/types';

const initialState = {
  payrollListEmployee: [],
  loading: false,
  payrollFiles: [],
  loadingFiles: false,
  loadingEmail: false,
};

export const payrollHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILL_PAYROLL_HISTORY:
      return {
        ...state,
        payrollListEmployee: action.payload,
      };
    case types.LOADING_PAYROLL_HISTORY:
      return {
        ...state,
        loading: action.payload,
      };
    case types.FILL_PAYROLL_FILES:
      return {
        ...state,
        payrollFiles: action.payload,
      };
    case types.LOADING_PAYROLL_FILES:
      return {
        ...state,
        loadingFiles: action.payload,
      };
    case types.CLEAN_PAYROLL_FILES:
      return {
        ...state,
        payrollFiles: null,
      };
    case types.LOADING_PAYROLL_EMAIL:
      return {
        ...state,
        loadingEmail: action.payload,
      };
    case types.UPDATE_PAYROLL_HISTORY:
      return {
        ...state,
        payrollListEmployee: [...action.payload],
      };
    case types.CLEAN_PAYROLL_HISTORY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
