import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';

import Startup from './Startup';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './utils/muiTheme';

class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Startup />
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
