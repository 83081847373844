/**
 * * Devuelve el título correspondiente según el tipo de resultado.
 *
 * @param {string} item - El tipo de resultado ('Actualizado', 'Error' u otro).
 * @returns {string} El título correspondiente.
 */
export const getTitleResult = (item) => {
  const resultTitles = [
    { type: 'Mixtos', title: 'Conceptos Mixtos' },
    { type: 'Error', title: 'Errores' },
    { type: 'Insertados', title: 'Cargados exitosamente' },
    { type: 'Transmitidos', title: 'Ya estaban trasmitidos a DIAN' },
  ];

  const matchedTitle = resultTitles.find((result) => result.type === item);

  return matchedTitle ? matchedTitle.title : 'Desconocido';
};
