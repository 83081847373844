import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Grid, Grow, makeStyles } from '@material-ui/core';

import BulkResultBg from '../../images/bulk_bulk_result.png';
import { UploadingDialog } from './modalsBulkload';
import { ConfirmAlertBackStep } from '../../helpers/alert.helpers';
import {
  cleanDataBulkLoadPayrollAction,
  transmitBulkLoadPayrollAction,
} from '../../actions/payrollBulkLoadAction';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '18px 0',
    padding: '0',
    backgroundColor: 'blueGrey',
    [theme.breakpoints.up(1281)]: {
      height: '73vh',
    },
  },
  infografia: {
    width: '100%',
    maxWidth: '339.68px',
    margin: 'auto',
    display: 'block',
    marginTop: '40px',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  inputLoad: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 20,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    marginBottom: 45,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
    '& p': {
      margin: '30px 0',
    },
  },
  subTitleValue: {
    color: theme.palette.thirdColor,
  },
  btnContinue: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
  },
  buttonSuccess: {
    height: '54px',
    maxWidth: '18%',
    width: '39%',
    minWidth: '293px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 400,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

const initialModalInfo = {
  openModal: false,
  contentDialog: null,
  title: null,
  subtitle: null,
};

const payrollDataBulkLoadInfo = (numberOfConcepts) => ({
  openModal: true,
  contentDialog: numberOfConcepts,
  title: 'Cargue masivo de información de nómina',
  subtitle: null,
});

export const UploadResultFile = ({ setActiveStep, setCompleted }) => {
  const dispatch = useDispatch();
  const [
    { openModal, contentDialog, title, subtitle },
    setOpenModalIformation,
  ] = useState(initialModalInfo);

  const {
    bulkLoadErrorPayroll,
    bulkLoadPayroll,
    reportDownloaded,
  } = useSelector((s) => s.payrollBulkLoadReducer);

  /**
   * * Carga información de conceptos.
   * * Esta función verifica si el informe ha sido descargado y toma una acción en consecuencia.
   * * Si el informe no ha sido descargado, se muestra una confirmación.
   * * Si el informe ha sido descargado, se inicia la carga de información de conceptos.
   */
  const loadConceptInfo = () => {
    if (!reportDownloaded) {
      ConfirmAlertBackStep(
        `¿Estás seguro que deseas continuar sin descargar el informe de conceptos errados?`,
        payrollBulkUpload,
        'Si',
        'No',
      );
      return;
    }
    payrollBulkUpload(true);
  };

  /**
   * * Inicia una carga masiva de nómina si se ha confirmado.
   *
   * @param {boolean} confirmed - Un valor booleano que indica si la carga ha sido confirmada (true) o no (false).
   */
  const payrollBulkUpload = (confirmed) => {
    if (confirmed) {
      setOpenModalIformation(
        payrollDataBulkLoadInfo(bulkLoadPayroll.nominasMasivas.length),
      );
      dispatch(transmitBulkLoadPayrollAction(bulkLoadPayroll, nextStep));
    }
  };

  const classes = useStyles();

  const nextStep = (error = null) => {
    if (error) {
      setActiveStep(0);
      dispatch(cleanDataBulkLoadPayrollAction());
      return;
    }
    setActiveStep(2);
    setCompleted(new Set([0]));
  };
  return (
    <Grow in={bulkLoadPayroll} timeout={500}>
      <div className={classes.root}>
        <h1 className={classes.title}>Resultado del Cargue</h1>
        <figure container>
          <img
            src={BulkResultBg}
            className={classes.infografia}
            alt="Infografia"
          />
        </figure>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Grid item lg={5} xs={7}>
            <div className={classes.subTitle}>
              <p>
                Se cargaron{' '}
                <span className={classes.subTitleValue}>
                  {' '}
                  {bulkLoadPayroll.nominasMasivas.length}{' '}
                </span>
                conceptos de nómina exitosamente.
              </p>
              {bulkLoadErrorPayroll.length > 0 && (
                <p>
                  No fue posible cargar{' '}
                  <span
                    className={classes.subTitleValue}
                    style={{ color: '#F5D13D' }}
                  >
                    {bulkLoadErrorPayroll.length}
                  </span>{' '}
                  conceptos por errores en el documento.
                  <br />
                  Descarga el informe en la parte inferior, corrígelos y
                  cárgalos de nuevo; también puedes agregarlos manualmente si es
                  necesario.
                </p>
              )}
            </div>
            <div className={classes.btnContinue}>
              <button
                className={classes.buttonSuccess}
                type="submit"
                onClick={loadConceptInfo}
                style={
                  bulkLoadPayroll.nominasMasivas.length === 0
                    ? { display: 'none' }
                    : {}
                }
              >
                Continuar con{' '}
                <strong>{bulkLoadPayroll.nominasMasivas.length}</strong>{' '}
                conceptos
              </button>
            </div>
          </Grid>
        </Grid>
        <UploadingDialog
          openModalIformation={openModal}
          contentDialog={contentDialog}
          title={title}
          subtitle={subtitle}
        />
      </div>
    </Grow>
  );
};
