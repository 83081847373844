export const columnsFileExcel = {
  agrupacionColumn: {
    name: 'Agrupación',
  },
  tipoDocumentoColumn: {
    name: 'Tipo de documento',
  },
  numeroDocumentoColumn: {
    name: 'Número documento',
  },
  tipoIngresoColumn: {
    name: 'Tipo de ingreso',
  },
  valorIngresoColumn: {
    name: 'Valor ingreso',
  },
  cantidadDiasColumn: {
    name: 'Cantidad(Días u Horas)',
  },
  porcentajeIngresoColumn: {
    name: 'Porcentaje ingreso',
  },
  descripcionIngresoColumn: {
    name: 'Descripción ingreso',
  },
  tipoDeduccion: {
    name: 'Tipo de Deducción',
  },
  valorDeduccionColumn: {
    name: 'Valor deducción',
  },
  porcentajeDeduccionColumn: {
    name: 'Porcentaje deducción',
  },
  descripcionDeduccionColumn: {
    name: 'Descripción deducción',
  },
  observacionesColumn: {
    name: 'Observaciones',
  },
};

export const fileHeaderName = () => {
  const columnNames = Object.values(columnsFileExcel).map(
    (column) => column.name,
  );
  return columnNames;
};
