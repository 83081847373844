import ResultTest from '../../../views/employees/bulkLoad/validation/ResultTest';
import { validationConcepts } from '../validationExcel';

/**
 *  * Valida los conceptos de nómina según los datos de entrada y la lista de conceptos.
 *
 * @param {Object[]} data - La lista de datos de nómina a validar.
 * @param {Object[]} concepts - La lista de conceptos a comparar.
 * @returns {Object} - Un objeto que contiene errores y datos válidos.
 */
export const validateConcepts = (data, concepts) => {
  const errors = [];
  const isTipoIngreso = (payroll) => payroll.TipoIngreso;
  const isTipoDeduccion = (payroll) => payroll.TipoDeduccion;

  /**
   * * Valida un campo específico del concepto en el objeto de nómina.
   *
   * @param {Object} payroll - El objeto de nómina.
   * @param {Object} concept - El concepto de nómina a comparar.
   * @param {string} field - El nombre del campo a validar.
   */
  const validateField = (payroll, concept, field) => {
    const { fieldConcept, message } = validationConcepts.find(
      (valida) => valida.field === field,
    );
    const shouldHaveValue = concept[fieldConcept];
    const hasValue = payroll[field] !== null;

    if ((hasValue && !shouldHaveValue) || (!hasValue && shouldHaveValue)) {
      const error = new ResultTest(
        hasValue
          ? `No es necesario proporcionar ${field} cuando ${fieldConcept} está desactivado para el concepto.`
          : message,
        payroll.Line,
        field,
      );
      errors.push(error);
      payroll.Error = payroll.Error ? [...payroll.Error, error] : [error];
    }
  };

  data.forEach((payroll) => {
    if (isTipoIngreso(payroll) && isTipoDeduccion(payroll)) {
      const err = new ResultTest(
        'La nómina no puede tener simultáneamente las categorías de Tipo de ingreso y Tipo de deducción.',
        payroll.Line,
        'Tipo de ingreso',
      );
      errors.push(err);
      payroll.Error = payroll.Error ? [...payroll.Error, err] : [err];
    } else if (!isTipoIngreso(payroll) && !isTipoDeduccion(payroll)) {
      const err = new ResultTest(
        'La nómina debe incluir al menos una de las siguientes categorías: Tipo de ingreso o Tipo de deducción.',
        payroll.Line,
        'Tipo de ingreso',
      );
      errors.push(err);
      payroll.Error = payroll.Error ? [...payroll.Error, err] : [err];
    }

    const conceptFound = concepts.find((concept) =>
      isTipoIngreso(payroll)
        ? concept.id === payroll.TipoIngreso
        : concept.id === payroll.TipoDeduccion,
    );

    if (conceptFound) {
      if (isTipoIngreso(payroll)) {
        validateField(payroll, conceptFound, 'ValorIngreso');
        validateField(payroll, conceptFound, 'PorcentajeIngreso');
        validateField(payroll, conceptFound, 'Cantidad');
        validateField(payroll, conceptFound, 'DescripcionIngreso');
      } else if (isTipoDeduccion(payroll)) {
        validateField(payroll, conceptFound, 'ValorDeduccion');
        validateField(payroll, conceptFound, 'PorcentajeDeduccion');
        validateField(payroll, conceptFound, 'Cantidad');
        validateField(payroll, conceptFound, 'DescripcionDeduccion');
      }
    }
  });

  const dataConceptsWithoutError = data.filter((e) => e.Error === null);
  const dataConceptsError = data.filter((e) => e.Error !== null);

  return { errors, dataConceptsWithoutError, dataConceptsError };
};
