import { useEffect } from 'react';
import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Grid, IconButton, makeStyles } from '@material-ui/core';

import ReactExport from 'react-data-export';

import { usePayroll } from '../../hooks/usePayroll';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import DonwloadIcon from '../../images/icons/donwloadBulkloadError.png';
import { ConfirmAlertBackStep } from '../../helpers/alert.helpers';
import {
  cleanDataBulkLoadPayrollAction,
  reportDownloadedAction,
} from '../../actions/payrollBulkLoadAction';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '15px !important',
    padding: 0,
  },
  clearIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerStep: {
    backgroundColor: '#393939',
    [theme.breakpoints.down(1398)]: {
      marginTop: '3%',
    },
  },
  footerIcon: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
  },
  label: {
    color: 'white',
    flexDirection: 'column',
    fontSize: 12,
  },
  labelHorizontal: {
    color: 'white',
    flexDirection: 'row',
    fontSize: 12,
  },
}));

const Step0 = () => {
  const classes = useStyles();
  const { setContentTab } = usePayroll();
  const dispatch = useDispatch();

  const handleCancelDocument = () => {
    setContentTab(1);
    dispatch(cleanDataBulkLoadPayrollAction());
  };
  return (
    <>
      <Grid container className={classes.footerStep}>
        <Grid item lg={12} xs={12} style={{ margin: '10px', padding: '0px' }}>
          <div className="bottomButtonsSupportRevision">
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.clearIcon}>
                <IconButton
                  classes={{ root: classes.button, label: classes.label }}
                  variant="raised"
                  disableRipple={true}
                  onClick={handleCancelDocument}
                >
                  <ClearIcon />
                  <span style={{ marginTop: 5 }}>Cancelar</span>
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const Step1 = ({ setActiveStep, setCompleted, activeStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const { bulkLoadDataSetPayrol, reportDownloaded } = useSelector(
    (s) => s.payrollBulkLoadReducer,
  );

  const [dataSet, SetDataSet] = useState([]);

  useEffect(() => {
    SetDataSet(bulkLoadDataSetPayrol);
  }, [bulkLoadDataSetPayrol]);

  const handleBackStep = (confirmed) => {
    if (confirmed) {
      setActiveStep(activeStep === 2 ? 0 : activeStep - 1);
      setCompleted([1, 2].includes(activeStep) ? new Set() : new Set([0]));
      dispatch(cleanDataBulkLoadPayrollAction());
    }
  };

  const confirmAlertBack = () => {
    if (!reportDownloaded) {
      ConfirmAlertBackStep(
        `¿Estás seguro que deseas continuar sin descargar el informe de conceptos errados?`,
        handleActionAlert,
        'Si',
        'Cancelar',
      );
      return;
    }
    if ([1, 2].includes(activeStep)) {
      handleActionAlert(true);
      return;
    }
    handleBackStep();
  };

  const handleActionAlert = (confirmed) => {
    if (confirmed) {
      ConfirmAlertBackStep(
        `¿Estás seguro de que deseas realizar esta acción? Si lo haces, perderás la información cargada y deberás empezar de nuevo`,
        handleBackStep,
        'Si',
        'No',
      );
    }
  };

  const handleDownloadSuccess = () => {
    dispatch(reportDownloadedAction(true));
  };
  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ width: '101%' }}
        className={classes.footerStep}
      >
        <Grid item lg={12} xs={12} style={{ height: 66 }}>
          <div className="bottomButtonsSupportRevision">
            <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
              <Grid
                item
                lg={2}
                xs={2}
                style={{ textAlign: 'left', maxWidth: 100 }}
              >
                <IconButton
                  classes={{
                    root: classes.button,
                    label: classes.labelHorizontal,
                  }}
                  variant="raised"
                  disableRipple={true}
                  onClick={() => confirmAlertBack()}
                  style={{ marginLeft: 20, marginTop: 10 }}
                >
                  <ArrowBackIosIcon />
                  <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
                </IconButton>
              </Grid>
              {[1, 2].includes(activeStep) && (
                <Grid item lg={10} xs={10} className={classes.footerIcon}>
                  {bulkLoadDataSetPayrol[0].data.length > 0 && (
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      style={{ marginRight: 10 }}
                    >
                      <ExcelFile
                        filename={`Registro_de_errores_CargueMasivo_Nominas_${new Date().toLocaleDateString(
                          'es-ES',
                          {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          },
                        )}`}
                        element={
                          <div onClick={handleDownloadSuccess}>
                            <figure
                              container
                              style={{
                                marginBlockEnd: '6px',
                                marginBlockStart: '-4px',
                              }}
                            >
                              <img
                                src={DonwloadIcon}
                                className={classes.infografia}
                                alt="Infografia"
                              />
                            </figure>
                            <span style={{ marginTop: 5 }}>
                              Descargar informe de errores
                            </span>
                          </div>
                        }
                      >
                        <ExcelSheet dataSet={dataSet} name="Organization" />
                      </ExcelFile>
                    </IconButton>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export const FooterBulkLoadPayroll = ({
  activeStep,
  setActiveStep,
  setCompleted,
}) => {
  const steps = [
    <Step0 />,
    <Step1
      setActiveStep={setActiveStep}
      setCompleted={setCompleted}
      activeStep={activeStep}
    />,
    <Step1
      setActiveStep={setActiveStep}
      setCompleted={setCompleted}
      activeStep={activeStep}
    />,
    <Step0 />,
  ];

  return steps[activeStep] || 'Unknown step footer';
};
