import React from 'react';
import MonthButton from './MonthButton';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonConfirm: {
    height: '54px',
    width: '100%',
    maxWidth: '28%',
    borderRadius: '5px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    display: 'block',
    margin: '0 auto 1rem auto',
    minWidth: '210px',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      alignItems: 'center',
      backgroundColor: '#272727',
      borderRadius: '5px',
      color: theme.palette.primaryColor,
      height: 'auto',
      maxWidth: '974px',
      width: '99%',
      minHeight: '420px',
    },
  },
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '35px 0 42px 0',
    minHeight: '56px',
    [theme.breakpoints.down(1320)]: {
      margin: '0',
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '340px',
      },
    },
    [theme.breakpoints.down(820)]: {
      height: 'auto',
      minHeight: '56px',
      flexDirection: 'column',
    },
  },
  title: {
    color: '#17DAFA',
    fontSize: 30,
    fontWeight: 800,
    marginTop: 0,
    textAlign: 'center',
  },
  contentText: {
    margin: '12px 0 0 0',
    padding: '0 79px',
    color: '#EEE',
    fontSize: 20,
    fontWeight: 600,
    '& > span': {
      color: '#EEEEEE',
      display: 'inline-block',
      fontSize: 18,
      fontWeight: 100,
      margin: '19px 0 0 0',
    },
    [theme.breakpoints.down(670)]: {
      padding: '0 30px',
      fontSize: 18,
      '& > span': {
        fontSize: 15,
      },
    },
  },
}));

const ModalDialog = ({ open, minDate, date, maxDate, setDate, action }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          <span className={classes.title}>Configura tu licencia</span>
        </DialogTitle>
        <DialogContentText className={classes.contentText}>
          Sabemos que adquiriste una licencia base por Empleados, por favor
          seleccione el mes en el cual va iniciar la vigencia de tu plan.
          <span>
            (Recuerda que la vigencia de este plan es de un año contado a partir
            del mes que especifiques)
          </span>
        </DialogContentText>
        <DialogContent className={classes.dialogContent}>
          {minDate ? (
            <MonthButton date={minDate} setLicenceDate={setDate} />
          ) : null}
          <MonthButton date={date} setLicenceDate={setDate} />
          {maxDate ? (
            <MonthButton date={maxDate} setLicenceDate={setDate} />
          ) : null}
        </DialogContent>
        <DialogActions className="backgroundContent">
          <Grid container>
            <Grid item lg={12} xs={12}>
              <button className={classes.buttonConfirm} onClick={action}>
                Confirmar
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDialog;
