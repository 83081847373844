import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles({
  btonMonth: {
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    borderRadius: '4px',
    border: 'rgba(255, 255, 255, 0.18)',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'block',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Muli',
    height: '40px',
    letterSpacing: '0',
    margin: '0 12px 10px',
    maxWidth: '240px',
    minWidth: '210px',
    textAlign: 'center',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: '#32ba42',
    },
    '&:focus': {
      backgroundColor: '#2CC63E',
    },
  },
});

const MonthButton = ({ date, setLicenceDate }) => {
  moment.locale('es');
  const formatMonth = 'MMMM';
  const classes = useStyles();
  const licenceDate = moment(date, 'DD-MM-YYYY').format(formatMonth);
  const handleChangeDate = () => {
    setLicenceDate(date);
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <button className={classes.btonMonth} onClick={handleChangeDate}>
        {capitalizeFirstLetter(licenceDate.toString()) ?? ''}
      </button>
    </>
  );
};

export default MonthButton;
