import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_CONFIGURATION, defaultHeaders } from '../config/config';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/confignomina/api`;

export default {
  getIncomesExpensesService: async () => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.get(
      `${urlConfiguration}/concepto/Concepto`,
      config,
    );
    return response;
  },
};
