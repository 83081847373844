import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { showErrorMessage } from '../utils/showErrorService';
import {
  FILL_ALL_PAYROLL_EMPLOYEE,
  FILL_ALL_PAYROLL_SUPPORTS,
  FILL_ALL_PAYROLL_EDITED,
  FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR,
  FILL_PAYROLL_DETAIL_EMPLOYEE,
  FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID,
  UPDATE_CONFIRM_PAYROLL,
  GET_ALL_PAYROLL_REPORTED,
  GET_PAYROLL_REPORTED_DETAIL,
  UPDATE_PAYROLL_FAVORITE,
  UPDATE_SUPPORT_PAYROLL_FAVORITE,
  UPDATE_PAYROLL_EMPLOYEE_SELECTED,
  UPDATE_ALL_PAYROLL_EMPLOYEE,
  FILL_DETAIL_PAYROLL,
  FILL_DETAIL_SUPPORTS,
  FILL_DETAIL_EDITED,
  ADD_NEW_INCOME_PAYROLL,
  UPDATE_INCOME_PAYROLL,
  ADD_NEW_EXPENSE_PAYROLL,
  UPDATE_EXPENSE_PAYROLL,
  DELETE_INCOME_PAYROLL,
  DELETE_EXPENSE_PAYROLL,
  SET_NAVIGATION,
  SET_FILES_PREVIEW,
  RESET_PAYROLL_FORM,
  SET_TRANSMISION_PROCESS,
  SET_TRANSMISION_STATE,
  SEND_SUPPORT_PAYROLL,
  LOADING_PAYROLL_REPORTED,
  LOADING_PAYROLL_SUPPORTS,
  RESPONSE_TRANSMISION_STATE,
  LOADING_RETIRE_EMPLOYEE_PAYROLL,
  UPDATE_EMPLOYEE_RETIREMENT,
  UPDATE_STATUS_TRANSMITION_SUPPORT,
} from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

export const fillAllPayrollEmployeeAction = (payload) => {
  return { type: FILL_ALL_PAYROLL_EMPLOYEE, payload: payload };
};

export const fillAllPayrollEmployeeSignalRAction = (payload) => {
  return { type: FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR, payload: payload };
};

export const fillAllPayrollSupportsAction = (payload) => {
  return { type: FILL_ALL_PAYROLL_SUPPORTS, payload: payload };
};

export const loadingPayrollSupportsAction = (payload) => {
  return { type: LOADING_PAYROLL_SUPPORTS, payload: payload };
};

export const fillDetailSupportsAction = (payload) => {
  return { type: FILL_DETAIL_SUPPORTS, payload: payload };
};

export const fillAllPayrollEditedAction = (payload) => {
  return { type: FILL_ALL_PAYROLL_EDITED, payload: payload };
};

export const fillDetailEditedAction = (payload) => {
  return { type: FILL_DETAIL_EDITED, payload: payload };
};

export const getPayrollDetailEmployeeAction = (payload) => {
  return { type: FILL_PAYROLL_DETAIL_EMPLOYEE, payload: payload };
};

export const fillPayrollDetailAction = (payload) => {
  return { type: FILL_DETAIL_PAYROLL, payload: payload };
};

export const fillAllPayrollsReportedAction = (payload) => {
  return { type: GET_ALL_PAYROLL_REPORTED, payload: payload };
};

export const loadingPayrollsReportedAction = (payload) => {
  return { type: LOADING_PAYROLL_REPORTED, payload: payload };
};

export const getPayrollsReportedDetailAction = (payload) => {
  return { type: GET_PAYROLL_REPORTED_DETAIL, payload: payload };
};

export const updatePayrollReportedAction = (payload) => {
  return { type: UPDATE_PAYROLL_FAVORITE, payload: payload };
};

export const updateSupportPayrollFavAction = (payload) => {
  return { type: UPDATE_SUPPORT_PAYROLL_FAVORITE, payload: payload };
};

export const updatePayrollEmployeeAction = (payload) => {
  return { type: UPDATE_PAYROLL_EMPLOYEE_SELECTED, payload: payload };
};

export const updateAllPayrollEmployeeAction = (payload) => {
  return { type: UPDATE_ALL_PAYROLL_EMPLOYEE, payload: payload };
};

export const updatePayrollStateAction = (payload) => {
  return { type: UPDATE_CONFIRM_PAYROLL, payload: payload };
};

export const addIncomeAction = (payload) => {
  return { type: ADD_NEW_INCOME_PAYROLL, payload: payload };
};

export const updateIncomeAction = (payload) => {
  return { type: UPDATE_INCOME_PAYROLL, payload: payload };
};

export const addExpenseAction = (payload) => {
  return { type: ADD_NEW_EXPENSE_PAYROLL, payload: payload };
};

export const updateExpenseAction = (payload) => {
  return { type: UPDATE_EXPENSE_PAYROLL, payload: payload };
};

export const deleteIncomeAction = (payload) => {
  return { type: DELETE_INCOME_PAYROLL, payload: payload };
};

export const deleteExpenseAction = (payload) => {
  return { type: DELETE_EXPENSE_PAYROLL, payload: payload };
};

export const setNavigationAction = (payload) => {
  return { type: SET_NAVIGATION, payload: payload };
};

export const setFilesPreviewAction = (payload) => {
  return { type: SET_FILES_PREVIEW, payload: payload };
};

export const resetpayrollForm = () => {
  return { type: RESET_PAYROLL_FORM };
};

export const fillDetailPayrollIdListPayrollAction = (payload) => {
  return { type: FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID, payload: payload };
};
// Transmision process

export const setTransmisionGeneralStateAction = (supports) => {
  return {
    type: SET_TRANSMISION_PROCESS,
    payload: supports,
  };
};
export const sendSupportPayrollAction = (payload) => {
  return { type: SEND_SUPPORT_PAYROLL, payload: payload };
};
export const setTransmisionStateAction = (payload) => {
  return { type: SET_TRANSMISION_STATE, payload: payload };
};

export const responseTransmisionStateAction = (payload) => {
  return { type: RESPONSE_TRANSMISION_STATE, payload: payload };
};

/**
 * Cambia loading transacción retirar empleado
 * @param {*} status nuevo estado
 * @returns
 */
export const loadingRetireEmployeeAction = (status) => {
  return { type: LOADING_RETIRE_EMPLOYEE_PAYROLL, payload: status };
};

/**
 * Actualiza información de retiro de un empleado
 * @param {*} fecharetiro Nueva fecha de retiro
 * @param {*} causalid Identificador causal
 * @returns
 */
export const updateDataEmployeeRetirement = (
  fecharetiro,
  causalretiroid,
  causalderetiro,
) => {
  return {
    type: UPDATE_EMPLOYEE_RETIREMENT,
    payload: {
      fecharetiro: fecharetiro,
      causalretiroid: causalretiroid,
      causalderetiro: causalderetiro,
    },
  };
};

export const updateStatusPayrollAction = (detailStatus) => async (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_TRANSMITION_SUPPORT,
    payload: detailStatus,
  });
};

/**
 * Cambio estado transacción retirar empleado
 * @param {*} status nuevo estado
 * @returns
 */
export const clearStatusTransmitionAction = () => {
  return { type: SET_TRANSMISION_STATE, payload: null };
};

export const updateStatusLicenceAction = (supports) => {
  return { type: SET_TRANSMISION_PROCESS, payload: supports };
};

/**
 *
 * @returns
 */
export const getEmployeePayrollAction = () => async (dispatch) => {
  try {
    const resp = await axiosApiInstance.get(
      `${urlDocument}/Documento/GetPayrollEmployeeDetalleId`,
      defaultHeaders(),
    );

    console.log('REspuesta--->', resp);
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error consultando historial de nominas de un empleado.',
    );
  } finally {
  }
};
