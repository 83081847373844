import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';

import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { ExpandMore } from '@material-ui/icons';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  add,
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isEqual,
  subDays,
  subMonths,
} from 'date-fns';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';

import ButtonSecundary from '../../../components/button/buttonSecundary.component';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  header: {
    alignItems: 'normal',
    paddingBottom: 0,
  },
  headerContent: {
    textOverflow: 'ellipsis',
    minHeight: 130,
  },
  question: {
    height: '52px',
    width: '418px',
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '26px',
  },
  answer: {
    height: '63px',
    width: '418px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  icon: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  actions: {
    justifyContent: 'flex-end',
    padding: 'unset',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 33,
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
    },
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 17,
  },
  title: {
    color: theme.palette.thirdColor,
  },
  containerActions: {
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        color: '#FFF',
        '& label ': {
          color: '#FFF',
          textAlign: 'left',
        },
      },
    },
    palette: {
      primary: 'red',
      secondary: 'cyan',
    },
    MuiFormLabel: {
      root: {
        color: '#FFF',
        textAlign: 'center',
        fontSize: '0.9rem',
        width: '100%',
        '&$focused': {
          color: '#16B1F3',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(22, 177, 243)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#16B1F3 !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#FFF',
        borderRadius: '5px',
        '& fieldset': {
          border: '2px solid rgba(255,255,255,0.3) !important',
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#1b5e20',
      },
    },
  },
});

const ReportComponent = ({
  title,
  subtitle,
  reportTitle,
  action,
  ExcelComponent,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const excelRef = useRef(null);
  const currentDay = new Date();
  const formatYear = (date) => format(date, 'yyyy-MM-dd');
  const formatDate = (date) => format(date, 'dd-MM-yyyy');

  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateReport, setDateReport] = useState({
    initialDate: subMonths(currentDay, 1),
    finalDate: subDays(currentDay, 1),
  });

  const { initialDate, finalDate } = dateReport;

  /**
   * Abre y Cierra modal para descarga informe
   */
  const handleOpenModal = () => setOpenModal(true);
  const handleClose = () => {
    if (!loading) setOpenModal(false);
  };

  /**
   * Actualiza fecha inicial y fecha final
   * @param {*} date
   * @returns
   */
  const handleChangeInitialDate = (date) =>
    setDateReport({ ...dateReport, initialDate: date });
  const handleChangeFinalDate = (date) =>
    setDateReport({ ...dateReport, finalDate: date });

  /**
   * Actualiza estado loading
   * @param {*} status nueva estado loading
   */
  const changeLoadingStatus = (status) => setLoading(status);

  /**
   * Dispara click, para descarga de excel
   * despues de consultar información de reporte
   * @param {Array<object>} data Informe
   */
  const setReport = (data) => {
    setData(data);
    if (excelRef.current !== undefined) excelRef.current.click();
  };

  /**
   * @param {*} initial: Fecha que llega del initialDate
   * @returns Retorna el valor con el que se modifica el estado del finalDate
   */
  const getFinalDate = (initial) => {
    if (isBefore(initial, currentDay)) {
      const date = addMonths(initial, 1);
      if (isAfter(date, currentDay)) {
        return currentDay;
      } else {
        return subDays(date, 1);
      }
    }
  };

  /**
   * isBefore: Valida si la fecha es antes del rango especificado
   * isAfter: Valida si la fecha es despues del rango especificado
   * isEqual: Valida si los valores son iguales en el rango de fechas
   */
  useEffect(() => {
    if (isAfter(initialDate, subMonths(currentDay, 1)))
      setDateReport({ ...dateReport, finalDate: currentDay });

    if (
      isBefore(initialDate, subMonths(currentDay, 1)) ||
      isEqual(initialDate, subMonths(currentDay, 1))
    )
      setDateReport({ ...dateReport, finalDate: getFinalDate(initialDate) });

    if (isAfter(initialDate, finalDate))
      setDateReport({ ...dateReport, finalDate: getFinalDate(initialDate) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate]);

  /**
   * Al cerrar el modal regresa los datepickers a su estado inicial
   */
  useEffect(() => {
    if (!openModal)
      setDateReport({
        initialDate: subMonths(currentDay, 1),
        finalDate: subDays(currentDay, 1),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  /**
   * Añade un día al initial date cómo fecha mínima
   */
  const getMinDate = () => add(initialDate, { days: 1 });

  /**
   * Valida si el día es el último día del mes actual, si es true coloca el maxDate como un mes posterior restando un día para el informe
   * si es false coloca la fecha actual deshabilitando los demás días del mes.
   */
  const getMaxDate = () => {
    let maxDate;
    endOfMonth(currentDay)
      ? (maxDate = subDays(addMonths(initialDate, 1), 1))
      : (maxDate = currentDay);

    return maxDate;
  };

  /**
   * Envia los datos al dispacth para consultar el informe de nómina
   */
  const getDataReport = async () => {
    dispatch(
      action(
        formatYear(initialDate),
        formatYear(finalDate),
        setReport,
        changeLoadingStatus,
      ),
    );
  };
  /**
     * Envia los datos al dispacth para consultar el informe de nómina agrupado
     */
  const getDataReportGroup = () => {
    dispatch(
      action(
        formatYear(initialDate),
        formatYear(finalDate),
        changeLoadingStatus,
      ),
    );
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.question,
            content: classes.headerContent,
          }}
          title={
            <Fragment>
              <Typography variant="body1">
                <Fragment>
                  <span className={classes.question}>
                    {title}
                    <br />
                    <br />
                  </span>
                </Fragment>
                <Fragment>
                  <span className={classes.answer}>
                    {subtitle}
                    <br />
                  </span>
                </Fragment>
              </Typography>
            </Fragment>
          }
        />
        <CardActions className={classes.actions}>
          <IconButton className={classes.expandIcon} onClick={handleOpenModal}>
            <Typography variant="caption" className={classes.icon}>
              Descargar
            </Typography>
            <ExpandMore className={clsx(classes.expand, {}, classes.icon)} />
          </IconButton>
        </CardActions>
        <div>
          <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">
              <span className={classes.title}>{title}</span>
            </DialogTitle>
            <DialogContent className={classes.backgroundContent}>
              <DialogContentText>
                <span className={classes.subTitle}>{subtitle}</span>
              </DialogContentText>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <Grid container>
                    <Grid item lg={6} xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            label="Desde *"
                            variant="inline"
                            inputVariant="outlined"
                            format="dd MMM yyyy"
                            value={initialDate}
                            onChange={handleChangeInitialDate}
                            maxDate={currentDay}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            label="Hasta *"
                            variant="inline"
                            disableFuture={true}
                            inputVariant="outlined"
                            format="dd MMM yyyy"
                            value={finalDate}
                            onChange={handleChangeFinalDate}
                            minDate={getMinDate}
                            maxDate={getMaxDate}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="backgroundContent">
              <Grid container className={classes.containerActions}>
                <Grid item xs={4} lg={4}>
                  <ButtonSecundary text={'Cancelar'} onClick={handleClose} />
                </Grid>
                <Grid item lg={4} xs={4}>
                  <ButtonPrimary
                    text={'Descargar'}
                    loading={loading}
                    type={'button'}
                    onClick={getDataReportGroup}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </Card>
    </>
  );
};

export default ReportComponent;
