/**
 * Calcula total nomina
 * @param {*} incomes Listado ingresos
 * @param {*} expenses Listado deducciones
 */
export const calculateTotalPayroll = (incomes = [], expenses = []) => {
  let incomeTotal = 0;
  for (let i = 0; i < incomes.length; i++) {
    const income = incomes[i];
    incomeTotal = roundDecimalValue(incomeTotal + income.valor);
  }

  let expenseTotal = 0;
  for (let i = 0; i < expenses.length; i++) {
    const expense = expenses[i];
    expenseTotal = roundDecimalValue(expenseTotal + expense.valor);
  }

  let payrollTotal = roundDecimalValue(incomeTotal - expenseTotal);

  return {
    valornomina: payrollTotal,
    valoringresos: incomeTotal,
    valordeducciones: expenseTotal,
    ingresos: incomes,
    deducciones: expenses,
  };
};

export const roundDecimalValue = (value, precision = 2) => {
  precision = precision === undefined ? 0 : precision;
  value = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  let newValue = parseFloat(value.toFixed(precision));
  return parseFloat(newValue);
};
