import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import StarIcon from '@material-ui/icons/Star';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { CardActions, Collapse, Grid } from '@material-ui/core';

import DocumentLicenseIcon from '../../../images/icons/documentLicenseIcon.png';
import EmployeeLicenseIcon from '../../../images/icons/employeeLicenseIcon.png';

import { getCustomFormatDate } from '../../../helpers/functions.helpers';
import { FormatDecimal } from '../../common/formatDecimal';
import { format } from 'date-fns';
import { PreviusPayrollCard } from './previousPayrollCard';
import { dianTypeDocumentDescription } from '../../../helpers/catalogDian';
import { StatusDianIcon } from './statusDianIcon';
import isEmpty from '../../../utils/isEmpty';
import ResponseModal from '../../modal/responseModal.component';
import { EmailConfirmForm } from '../../email/emailConfirm';
import { CustomModal } from '../../modal/customModal';
import ArchiveModal from '../../modal/archiveModal';
import { ChoosingNoteForm } from './choosingNoteForm';

import {
  cleanPayrollFilesAcion,
  getPayrollFilesAction,
  sendEmailPayrollAction,
} from '../../../actions/payrollEmployeeAction';
import { getPayrollEmployeeAction } from '../../../actions/payrollAdjustmentAction';
import { DeletePayrollForm } from './deletePayrollForm';
import { generateRequestPayrollAdjustment } from '../../../helpers/generateRequest';
import { handleTypeLicense } from '../../../helpers/typeLicense';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    color: theme.palette.primaryColor,
    marginBottom: 8,
    paddingBottom: 10,
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiCardHeader-root': {
      paddingTop: 5,
      paddingBottom: 0,
    },
    '& .MuiCardHeader-avatar': {
      marginRight: 5,
    },
    '& .MuiCardActions-root': {
      padding: 0,
    },
  },
  content: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 'normal',
    display: 'grid',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#FFFFFF',
  },
  value: {
    color: '#2CC63E',
    fontSize: '20px',
    fontWeight: '600',
  },
  iconAction: {
    color: '#FFFFFF',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    color: '#2CC63E',
    fontSize: '16px',
  },
}));

const validarestadonomina = (estadonominaid) => {
  switch (estadonominaid) {
    case 2:
      return 'Aceptado';
    case 3:
      return 'Error en documento';
    case 4:
      return 'Ocurrió error al enviar a la Dian';
    default:
      return '';
  }
};

export const PayrollHistoryCard = React.memo(
  ({
    detallenominaid,
    periodo,
    prefijo,
    consecutivo,
    estadonominaid,
    fechavalidaciondian,
    valorneto,
    historial,
    codigounico,
    favorito,
    diantipodocumento,
    primernombre,
    otronombre,
    primerapellido,
    segundoapellido,
    observacion,
    handleDeletePayroll,
    openModalDelete,
    setOpenModalDelete,
    tipolicencia,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const [openModalMail, setOpenModalMail] = useState(false);
    const [openModalFile, setOpenModalFile] = useState(false);
    const [openModalChoose, setOpenModalChoose] = useState(false);
    const [base64PDF, setBase64PDF] = useState(null);
    const [base64XML, setBase64XML] = useState(null);
    const [modalData, setModalData] = React.useState({
      type: '',
      title: '',
      subtitle: '',
      body: '',
      modalImage: '',
      open: false,
      closeElement: '',
      onCloseElement: 0,
    });

    /**
     * Store Redux
     */
    const { payrollFiles, loadingFiles, loadingEmail } = useSelector(
      (s) => s.payrollHistoryReducer,
    );
    const {
      nominaid,
      soporteid,
      valornomina,
      empresaid,
      empleadoId,
      loading: loadingGetPayroll,
    } = useSelector((s) => s.payrollAdjustmentReducer.payrollData);
    const { loadingRegisterAdjustment } = useSelector(
      (s) => s.payrollAdjustmentReducer,
    );

    const { incomesPayroll } = useSelector((s) => s.incomePayrollReducer);
    const { expensesPayroll } = useSelector((s) => s.expensePayrollReducer);

    /**
     * Escucha cambios en listado archivos asociados a documento
     */
    useEffect(() => {
      if (payrollFiles) {
        let filePDF = payrollFiles.find(
          (c) => c.tipoContenido === 'application/pdf',
        );
        let fileXML = payrollFiles.find(
          (c) => c.tipoContenido === 'application/xml',
        );

        if (!!filePDF) {
          setBase64PDF(filePDF?.archivoBase64);
          setBase64XML(fileXML?.archivoBase64);
        }
      } else {
        setBase64PDF(null);
        setBase64XML(null);
      }
    }, [payrollFiles]);

    /**
     * Expande pane información adicional
     */
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    /**
     * Consulta y previsualiza archivo
     */
    const handlePreview = () => {
      if (isEmpty(codigounico)) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Archivo no disponible',
          body: (
            <div>
              {
                'Mientras, el documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá visualizarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
      } else {
        setOpenModalFile(true);
        dispatch(getPayrollFilesAction(codigounico));
      }
    };

    /**
     * Limpia listado archivos asociados a documento
     */
    const handleCloseModalFile = () => {
      setOpenModalFile(false);
      dispatch(cleanPayrollFilesAcion());
    };

    /**
     * Cierra Modal, Notificación codigo unico no valido
     */
    const handleResponseModal = () => {
      setModalData({
        ...modalData,
        open: false,
      });
    };

    /**
     * Abre modal para envio de correo con documento
     */
    const OpenMailModal = () => {
      if (isEmpty(codigounico)) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Documento no disponible',
          body: (
            <div>
              {
                'El documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá enviarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
      } else {
        setOpenModalMail(true);
      }
    };

    /**
     * Cierra modal envio de correo
     */
    const handleCloseModalMail = () => {
      setOpenModalMail(false);
    };

    /**
     * Envia soporte de nomina a un determinado corrreo electronico
     * @param {*} mailTo Correo electronico
     * @param {*} codigounico Codigo unico del soporte de nómina
     */

    const sendSupportToMail = useCallback(
      (mailTo) => {
        const fullName = getFullName(
          primernombre,
          otronombre,
          primerapellido,
          segundoapellido,
        );
        dispatch(sendEmailPayrollAction(mailTo, fullName, codigounico));
      },
      [
        codigounico,
        dispatch,
        otronombre,
        primerapellido,
        primernombre,
        segundoapellido,
      ],
    );

    /**
     * Concatena nombre completo
     * @param {*} primernombre
     * @param {*} otronombre
     * @param {*} primerapellido
     * @param {*} segundoapellido
     * @returns
     */
    const getFullName = (
      primernombre,
      otronombre,
      primerapellido,
      segundoapellido,
    ) => {
      return `${primernombre} ${otronombre ?? ''} ${primerapellido ?? ''} ${
        segundoapellido ?? ''
      }`;
    };

    /**
     * Cierra modal edicon soporte nomina
     */
    const handleCloseModalChoose = () => {
      setOpenModalChoose(false);
    };

    /**
     * Abre modal edicon soporte nomina
     */
    const handleOpenModalChoose = () => {
      if (isEmpty(codigounico) || estadonominaid !== 2) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Documento no disponible',
          body: (
            <div>
              {
                'El documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá editarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
        return;
      }

      setOpenModalChoose(true);
    };

    /**
     * Confirma edición de soporte de nomina
     * @param {*} option
     */
    const handleConfirmNote = useCallback(
      (option) => {
        if (Number(option) === 1) {
          dispatch(getPayrollEmployeeAction(detallenominaid, continueReplace));
        } else {
          dispatch(getPayrollEmployeeAction(detallenominaid, continueDelete));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [detallenominaid, dispatch],
    );

    /**
     * Redirecciona a modulo para edición de nomina
     */
    const continueReplace = () => {
      handleCloseModalChoose();
      history.push('/payrollAdjustment');
    };

    /**
     * Muestra formulario para continuar porceso de eliminación
     */
    const continueDelete = () => {
      handleCloseModalChoose();
      setOpenModalDelete(true);
    };

    /**
     * Confirma eliminación de soporte de nomina
     * @param {*} observation
     */
    const handleConfirmDelete = useCallback(
      (observation) => {
        const license = handleTypeLicense(periodo, empresaid, empleadoId);

        var data = generateRequestPayrollAdjustment(
          soporteid,
          incomesPayroll,
          expensesPayroll,
          observation,
          valornomina,
          true,
          nominaid,
        );

        handleDeletePayroll(data, license);
      },

      [
        expensesPayroll,
        handleDeletePayroll,
        incomesPayroll,
        nominaid,
        soporteid,
        valornomina,
        empleadoId,
        empresaid,
        periodo,
      ],
    );

    return (
      <Card className={`${classes.root}`}>
        <CardHeader
          classes={{
            title: classes.title,
          }}
          action={
            <>
              {diantipodocumento !== 104 && (
                <Tooltip title="Editar">
                  <IconButton onClick={handleOpenModalChoose}>
                    <CreateIcon className={classes.iconAction} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Visualizar">
                <IconButton onClick={handlePreview}>
                  <VisibilityIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Enviar">
                <IconButton onClick={OpenMailModal}>
                  <EmailIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Favorito">
                <IconButton onClick={() => {}}>
                  {favorito ? (
                    <StarIcon className={classes.iconAction} />
                  ) : (
                    <StarBorderIcon className={classes.iconAction} />
                  )}
                </IconButton>
              </Tooltip>
            </>
          }
          title={
            <>
              {getCustomFormatDate(new Date(periodo), ' ')}
              {tipolicencia?.trim() === 'Empleado' ? (
                <Tooltip title="Licencia Empleado">
                  <IconButton>
                    <img
                      src={EmployeeLicenseIcon}
                      alt="EmployeeLicense"
                      className={classes.iconAction}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Licencia Documento">
                  <IconButton>
                    <img
                      src={DocumentLicenseIcon}
                      alt="DocumentLicense"
                      className={classes.iconAction}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <span
                style={{
                  color: diantipodocumento === 104 ? '#ED8D84' : '#FFFF',
                  fontWeight: 600,
                }}
              >{`Tipo doc: ${dianTypeDocumentDescription(
                diantipodocumento,
              )}`}</span>
            </Grid>
            <Grid item lg={6} xs={12} style={{ textAlign: 'end' }}>
              <span
                className={classes.value}
                style={{
                  color: diantipodocumento === 104 ? '#ED8D84' : '#2CC63E',
                }}
              >
                <FormatDecimal value={valorneto} prefix={'$'} />
              </span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{`Consecutivo: ${prefijo ?? ''}${consecutivo ?? ''}`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{`Validación DIAN: ${validarestadonomina(
                estadonominaid,
              )}`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>
                {fechavalidaciondian && estadonominaid === 2
                  ? `Fecha validación DIAN: ${format(
                      new Date(fechavalidaciondian),
                      'dd/MM/yyyy',
                    )}`
                  : 'Fecha validación DIAN:'}
              </span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span style={{ wordBreak: 'break-all' }}>{`Observaciones: ${
                observacion ?? ''
              }`}</span>
            </Grid>
            <Grid item lg={10} xs={12}>
              <span style={{ wordBreak: 'break-all' }}>{`Código único: ${
                codigounico ?? ''
              }`}</span>
            </Grid>
            <Grid item lg={2} xs={12} style={{ textAlign: 'end' }}>
              <IconButton>
                <StatusDianIcon
                  state={estadonominaid}
                  fechavalidacion={fechavalidaciondian}
                />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <div style={{ textAlign: 'end', width: '100%' }}>
            {historial?.length > 0 && (
              <IconButton
                className={classes.expandButton}
                onClick={handleExpandClick}
              >
                <span>{expanded ? 'Ver menos' : 'Ver mas'}</span>
                <ExpandMoreIcon
                  aria-expanded={expanded}
                  aria-label="show more"
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            )}
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={1}>
              {historial?.map((item) => (
                <>
                  <Grid key={item.detallenominaid} item lg={12} xs={12}>
                    <PreviusPayrollCard {...item} />
                  </Grid>
                </>
              ))}
            </Grid>
          </CardContent>
        </Collapse>

        {/* Modal previsualizar PDF */}
        <ArchiveModal
          loading={loadingFiles}
          open={openModalFile}
          pdfBase64={base64PDF}
          xmlBase64={base64XML}
          handleClose={handleCloseModalFile}
        />
        {/* Modal confirmación email */}
        <CustomModal
          onClose={handleCloseModalMail}
          open={openModalMail}
          component={
            <EmailConfirmForm
              emailUser={''}
              handleConfirmEmail={sendSupportToMail}
              loading={loadingEmail}
            />
          }
          title={'Enviar documento soporte de nómina'}
          width={365}
          showCloseButton={false}
          disableBackdropClick={loadingEmail}
        />

        {/*Modal confirmación */}
        <CustomModal
          onClose={handleCloseModalChoose}
          open={openModalChoose}
          component={
            <ChoosingNoteForm
              tipoDocumento={diantipodocumento}
              handleConfirmNote={handleConfirmNote}
              loading={loadingGetPayroll}
            />
          }
          title={'Editar soporte de nómina'}
          width={'40%'}
          showCloseButton={false}
          disableBackdropClick={loadingGetPayroll}
          styleTitle={{ color: '#16B1F3', fontSize: 26 }}
        />

        {/*Modal eliminación */}
        <CustomModal
          onClose={() => setOpenModalDelete(false)}
          open={openModalDelete}
          component={
            <DeletePayrollForm
              consecutivo={`${prefijo ?? ''}${consecutivo ?? ''}`}
              handleConfirmDelete={handleConfirmDelete}
              loading={loadingRegisterAdjustment}
              periodo={getCustomFormatDate(new Date(periodo), ' ')}
              nombre={getFullName(
                primernombre,
                otronombre,
                primerapellido,
                segundoapellido,
              )}
            />
          }
          title={'Eliminar documento soporte'}
          width={'30%'}
          showCloseButton={false}
          disableBackdropClick={loadingRegisterAdjustment}
          styleTitle={{ color: '#16B1F3', fontSize: 26 }}
        />

        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleResponseModal}
        />
      </Card>
    );
  },
);
