import {
  GET_MENU_MYACCOUNT,
  GET_MENU_EMPLOYEES,
  GET_MENU_HELP,
  GET_MENU_PAYROLL_BULKLOAD,
} from './types';

import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_CONFIGURATION, defaultHeaders } from '../config/config';
import { showErrorMessage } from '../utils/showErrorService';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/confignomina/api`;

/**
 * Consulta listado menus configurables
 */
export const getConfigurableMenuAction = () => async (dispatch) => {
  try {
    const config = defaultHeaders();

    const response = await axiosApiInstance.get(
      `${urlConfiguration}/menu/GetConfigurableMenu`,
      config,
    );
    const menu = response?.data?.result;
    getMenuMyAccount(menu, dispatch);
    getMenuEmployees(menu, dispatch);
    getMenuHelp(menu, dispatch);
    getMenuBulkloadPayroll(menu, dispatch);
  } catch (err) {
    showErrorMessage(err, 'Error consultando muenu configurable');
  }
};

/**
 * Obtiene informacion menu  seccion mi cuenta
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuMyAccount = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titleTestSetId: menu?.find((e) => e.id === 10)?.texto ?? '',
      messageTestSetId: menu?.find((e) => e.id === 11)?.texto ?? '',
      bottonTestSetId: menu?.find((e) => e.id === 12)?.texto ?? '',
      urlTestSetId: menu?.find((e) => e.id === 12)?.url ?? '',

      titleTypePerson: menu?.find((e) => e.id === 16)?.texto ?? '',
      messageTypePerson: menu?.find((e) => e.id === 17)?.texto ?? '',
      bottonTypePerson: menu?.find((e) => e.id === 18)?.texto ?? '',
      urlTypePerson: menu?.find((e) => e.id === 18)?.url ?? '',

      titleTypeRole:
        menu?.find((e) => e.id === 103)?.texto ?? '¿Qué hacen los roles?',
      buttonBasicDataReady: menu?.find((e) => e.id === 19)?.texto ?? 'Listo',

      titlePopCreationBasicData:
        menu?.find((e) => e.id === 20)?.texto ?? 'Genial!!!',
      messagePopCreationBasicData:
        menu?.find((e) => e.id === 21)?.texto ??
        'Configuraste tus datos básicos correctamente.',
      messageBodyPopBasicData:
        menu?.find((e) => e.id === 230)?.texto ??
        'Solo te faltan 3 items para terminar de configurar "MI CUENTA"',
      messageSkipBasicData: menu?.find((e) => e.id === 23)?.texto ?? 'Skip',
      messageProgressBasicData:
        menu?.find((e) => e.id === 231)?.texto ?? 'Progreso 1/4',

      titlePopCreateBranch:
        menu?.find((e) => e.id === 89)?.texto ?? 'Perfecto!!!',
      messagePopCreateBranch:
        menu?.find((e) => e.id === 90)?.texto ??
        'Creaste una nueva sucursal para tu empresa.',
      messageBodyPopBranch:
        menu?.find((e) => e.id === 91)?.texto ??
        'Solo te faltan 2 items para terminar de configurar "MI CUENTA"',
      messageSkipBranch: menu?.find((e) => e.id === 92)?.texto ?? 'Skip',
      messageProgressBranch:
        menu?.find((e) => e.id === 223)?.texto ?? 'Progreso 2/4',
      messageNoFoundBranch:
        menu?.find((e) => e.id === 224)?.texto ??
        'No tienes sucursales creadas',

      titlePopUpdateBranch:
        menu?.find((e) => e.id === 93)?.texto ?? 'Buen trabajo!!!',
      messagePopUpdateBranch:
        menu?.find((e) => e.id === 94)?.texto ??
        'Actualizaste la Sucursal $nombreSucursal de manera exitosa.',

      buttonCreateBranch: menu?.find((e) => e.id === 95)?.texto ?? 'Listo',

      buttonCreateUser:
        menu?.find((e) => e.id === 102)?.texto ?? 'Crear nuevo usuario',

      titleCreateUser: menu?.find((e) => e.id === 98)?.texto ?? 'Huu juu!!!',
      messageCreateUser:
        menu?.find((e) => e.id === 99)?.texto ?? 'Has creado un nuevo usuario',
      messageBodyPopUser:
        menu?.find((e) => e.id === 100)?.texto ??
        'Solo te falta 1 items para terminar de configurar "MI CUENTA"',
      messageSkipUser: menu?.find((e) => e.id === 101)?.texto ?? 'Skip',
      messageProgressUser:
        menu?.find((e) => e.id === 222)?.texto ?? 'Progreso 3/4',

      titleCreateLogo: menu?.find((e) => e.id === 225)?.texto ?? 'Increible!!!',
      messageCreateLogo:
        menu?.find((e) => e.id === 226)?.texto ??
        'Configuraste tu logo y plantilla',
      messageBodyPopLogo:
        menu?.find((e) => e.id === 229)?.texto ?? 'Configuraste "MI CUENTA"',
      messageSkipLogo: menu?.find((e) => e.id === 227)?.texto ?? 'Skip',
      messageProgressLogo:
        menu?.find((e) => e.id === 228)?.texto ?? 'Progreso 4/4',

      messageTypeRole:
        menu?.find((e) => e.id === 104)?.texto ??
        'Administrador: Emitir y editar nóminas, crear usuarios. \n Colaborador: Emitir y editar nóminas soporte de nómina y crear empleados.',
      bottonTypeRole:
        menu?.find((e) => e.id === 105)?.texto ?? 'Más Información',
      urlTypeRole: menu?.find((e) => e.id === 105)?.url ?? '',

      titlePopUpdateUser:
        menu?.find((e) => e.id === 110)?.texto ?? 'Buen trabajo!!!',
      messagePopUpdateUser:
        menu?.find((e) => e.id === 111)?.texto ??
        'Actualizaste el usuario $nombreUsuario de manera exitosa',

      buttonAddUser: menu?.find((e) => e.id === 117)?.texto ?? 'Listo',

      buttonSelectTemplate: menu?.find((e) => e.id === 126)?.texto ?? 'Listo',

      titlePopTestIdInvalid:
        menu?.find((e) => e.id === 141)?.texto ?? 'Upss...!!!',
      messagePopTestIdInvalid:
        menu?.find((e) => e.id === 142)?.texto ?? 'Test set id incorrecto',
      messageBodyPopTestIdInvalid:
        menu?.find((e) => e.id === 143)?.texto ??
        'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y rectifica el identificador del set de pruebas.',

      messageSkip: menu?.find((e) => e.id === 145)?.texto ?? 'Skip',

      titlePopNotRegister:
        menu?.find((e) => e.id === 146)?.texto ?? 'Upss...!!!',
      messagePopNotRegister:
        menu?.find((e) => e.id === 147)?.texto ??
        'Aun no tienes registrado a Colfactura Nómina como tu software de facturación electrónica en Dian',
      messageBodyPopNotRegister:
        menu?.find((e) => e.id === 148)?.texto ??
        'Ingresa por favor al link de <a style="color:#16B1F3;" href="https://catalogo-vpfe-hab.dian.gov.co/User/Login" target="_blank">habilitación</a> de la Dian y realiza el proceso de registro como facturador electrónico seleccionando a Gestión de Seguridad Electrónica S.A (Colfactura Nómina) como tu proveedor tecnológico. ¿Tienes dudas de cómo realizar el proceso? <a style="color:#16B1F3;" href="https://www.youtube.com/watch?v=Om59dTlnD6c" target="_blank">Apréndelo aquí</a>.',
      messageSkipNotRegister: menu?.find((e) => e.id === 149)?.texto ?? 'Skip',

      titlePrefijo: menu?.find((e) => e.id === 213)?.texto ?? '',
      messagePrefijo: menu?.find((e) => e.id === 215)?.texto ?? '',
      bottonPrefijo: menu?.find((e) => e.id === 214)?.texto ?? '',
      urlPrefijo: menu?.find((e) => e.id === 214)?.url ?? '',
      versionAplication:
        menu?.find((e) => e.id === 130)?.texto ?? 'version 1.1.0',
    };

    dispatch({
      type: GET_MENU_MYACCOUNT,
      payload: configurableMenu,
    });
  }
};

/**
 * Obtiene informacion Tooltip Cargue Masivo nomina
 * @param {*} menu Listado Tooltip
 * @param {*} dispatch funcion dispatch
 */
const getMenuBulkloadPayroll = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let tooltipMenuPayrollBulkload = {
      titleTooltipRegistrado: menu?.find((e) => e.id === 362)?.texto ?? '',
      messageTooltiptitleTooltipRegistrado:
        menu?.find((e) => e.id === 363)?.texto ?? '',
      statusTooltipRegistrado: menu?.find((e) => e.id === 364)?.texto ?? '',

      titleTooltipTransmitido: menu?.find((e) => e.id === 365)?.texto ?? '',
      messageTooltiptitleTooltipTransmitido:
        menu?.find((e) => e.id === 366)?.texto ?? '',
      statusTooltipTransmitido: menu?.find((e) => e.id === 367)?.texto ?? '',

      titleTooltipError: menu?.find((e) => e.id === 368)?.texto ?? '',
      messageTooltiptitleTooltipError:
        menu?.find((e) => e.id === 369)?.texto ?? '',
      statusTooltipError: menu?.find((e) => e.id === 370)?.texto ?? '',

      titleTooltipMixto: menu?.find((e) => e.id === 371)?.texto ?? '',
      messageTooltiptitleTooltipMixto:
        menu?.find((e) => e.id === 372)?.texto ?? '',
      statusTooltipMixto: menu?.find((e) => e.id === 373)?.texto ?? '',
    };

    dispatch({
      type: GET_MENU_PAYROLL_BULKLOAD,
      payload: tooltipMenuPayrollBulkload,
    });
  }
};

/**
 * Obtiene informacion menu Empleados
 * @param {*} menu Listado menus
 * @param {*} dispatch funcion dispatch
 */
const getMenuEmployees = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titleStep1BasicData:
        menu?.find((e) => e.id === 217)?.texto ?? 'Datos Básicos',
      titleStep2ContractData:
        menu?.find((e) => e.id === 218)?.texto ?? 'Datos de contratación',
      titleStep3PaymentMethod:
        menu?.find((e) => e.id === 219)?.texto ?? 'Forma de pago',
      titleStep4NoveltyFixed:
        menu?.find((e) => e.id === 220)?.texto ?? 'Conceptos fijos',
    };

    dispatch({
      type: GET_MENU_EMPLOYEES,
      payload: configurableMenu,
    });
  }
};

const getMenuHelp = (menu, dispatch) => {
  if (menu !== undefined && menu !== null) {
    let configurableMenu = {
      titlePrivacyPolicy: menu?.find((e) => e.id === 144)?.texto ?? '',

      titleProtectionData: menu?.find((e) => e.id === 193)?.texto ?? '',
      buttonProtectionData: menu?.find((e) => e.id === 194)?.texto ?? '',
      urlProtectionData: menu?.find((e) => e.id === 194)?.url ?? '',

      titleTermAndConditions: menu?.find((e) => e.id === 195)?.texto ?? '',
      buttonTermAndConditions: menu?.find((e) => e.id === 196)?.texto ?? '',
      urlTermAndConditions: menu?.find((e) => e.id === 196)?.url ?? '',

      urlFrequentQuestions: menu.find((e) => e.id === 197)?.url ?? '',
      buttonFrequentQuestions: menu.find((e) => e.id === 197)?.texto ?? '',

      contactLabel: menu.find((e) => e.id === 137)?.texto ?? '',
      buttonContact: menu.find((e) => e.id === 138)?.texto ?? '',
      messageContact: menu.find((e) => e.id === 140)?.texto ?? '',
      emailContact: menu.find((e) => e.id === 134)?.texto ?? '',
      phoneContact: menu.find((e) => e.id === 135)?.texto ?? '',
      urlPhoneContact: menu.find((e) => e.id === 135)?.url ?? '',
    };

    dispatch({
      type: GET_MENU_HELP,
      payload: configurableMenu,
    });
  }
};
