import './App.css';

import React, { useEffect, useState } from 'react';

import { differenceInCalendarDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {
  getCities,
  getDocumentTypesAction,
  getFrequentQuestions,
  getMenusAction,
  getRegimeTypesAction,
  getRetiresTypesAction,
  getStatesAction,
} from './actions/configAction';
import { AdjustmentManage } from './components/_PayrollAdjustment/adjustmentManage';
import ResponseModal from './components/modal/responseModal.component';
import { CHATBOT_WOLKVOX, LICENSE_SHOP_URL } from './config/config';
import { readError } from './helpers/readError';
// custom hook
import { usePayroll } from './hooks/usePayroll';
import PrivateRoute from './routes/privateRoutes';
import PublicRoute from './routes/publicRoutes';
import { Home } from './views/dashboard/home.view';
import EmployeesView from './views/employees';
import Help from './views/helps/help.view';
import Index from './views/home/index';
import MyAccount from './views/myAccount';
import PayrollView from './views/payroll';
import ReportsView from './views/reports/ReportsView';

let hist = createBrowserHistory();

const initialModal = {
  modalType: '',
  title: '',
  subtitle: '',
  body: '',
  modalImage: '',
  open: false,
  closeElement: '',
};

export const Startup = () => {
  const [ModalResponse, setModalResponse] = useState(initialModal);
  const [ModalResponseDays, setModalResponseDays] = useState(initialModal);
  const dispatch = useDispatch();
  const { currentError } = useSelector((s) => s.generalReducer);
  const { counterData } = useSelector((s) => s.dashboardReducer);
  const fechaExpiracionLicenciaEmp = new Date(
    counterData?.fechaExpiracionLicenciaEmp,
  );
  const fechaExpiracionLicenciaDoc = new Date(
    counterData?.fechaExpiracionLicenciaDoc,
  );
  const licEmp = counterData?.licenciaActivaEmp;
  const LicDoc = counterData?.licenciaActivaDoc;

  const expiredLicense =
    !licEmp &&
    !LicDoc &&
    counterData?.fechaExpiracionLicenciaDoc !== null &&
    counterData?.fechaExpiracionLicenciaEmp !== null
      ? 'Documento y Empleado'
      : !licEmp && counterData?.fechaExpiracionLicenciaEmp !== null
      ? 'Empleado'
      : !LicDoc && counterData?.fechaExpiracionLicenciaDoc !== null
      ? 'Documento'
      : '';

  const basePlanExpired = {
    modalType: 'warning',
    title: 'Tu plan de Colfactura nómina venció',
    body: (
      <div>
        <p
          style={{
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {`No cuentas con un plan activo(${expiredLicense}), es necesario renovarlo para que puedas seguir emitiendo documentos.`}
        </p>
        <p
          style={{
            marginTop: 10,
            fontWeight: 700,
            fontSize: 25,
          }}
        >
          {'Si quieres comprar tu nuevo plan, haz clic en Comprar'}
        </p>
      </div>
    ),
    modalImage: 'warning',
    open: true,
    textButton: 'Comprar',
    closeElement: 'Skip',
    urlButton: `${LICENSE_SHOP_URL}`,
  };

  // custom hook
  const { fillCurrentStateTransmision } = usePayroll();

  /**
   * Cargue Inicial
   */
  useEffect(() => {
    dispatch(getMenusAction());
    dispatch(getStatesAction());
    dispatch(getCities());
    dispatch(getRegimeTypesAction());
    dispatch(getDocumentTypesAction());
    dispatch(getRetiresTypesAction());
    dispatch(getFrequentQuestions());

    //Se inicializa el Chatbot de ayuda
    const script = document.createElement('script');
    script.id = 'prodId';
    script.type = 'text/javascript';
    script.src = CHATBOT_WOLKVOX;
    script.async = '';
    document.body.appendChild(script);

    fillCurrentStateTransmision();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!!currentError?.error) {
      readError(currentError);
    }
  }, [currentError]);

  useEffect(() => {
    const differenceInCalendarDayEmp = differenceInCalendarDays(
      fechaExpiracionLicenciaEmp,
      new Date(),
    );
    const differenceInCalendarDayDoc = differenceInCalendarDays(
      fechaExpiracionLicenciaDoc,
      new Date(),
    );

    if (
      (!licEmp &&
        counterData &&
        counterData?.fechaExpiracionLicenciaEmp !== null) ||
      (!LicDoc &&
        counterData &&
        counterData?.fechaExpiracionLicenciaDoc !== null)
    ) {
      setModalResponse(basePlanExpired);
    }

    if (differenceInCalendarDayDoc <= 3 && differenceInCalendarDayDoc > 0) {
      setModalResponseDays({
        modalType: 'warning',
        title: `Tu plan de Colfactura nómina se vence en ${differenceInCalendarDayDoc} días`,
        body: (
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: 25,
              }}
            >
              {`El plan que adquiriste (Documento) vence el ${format(
                fechaExpiracionLicenciaDoc,
                'dd/MMM/yyyy',
                { locale: es },
              )}`}
            </p>
            <p
              style={{
                marginTop: 10,
                fontWeight: 700,
                fontSize: 25,
              }}
            >
              {
                'Es necesario renovarlo para que puedas seguir emitiendo documentos. Si quieres comprar tu nuevo plan, haz clic en “Comprar”.'
              }
            </p>
          </div>
        ),
        modalImage: 'warning',
        open: true,
        textButton: 'Comprar',
        closeElement: 'Skip',
        urlButton: `${LICENSE_SHOP_URL}`,
      });
    } else if (
      differenceInCalendarDayEmp <= 3 &&
      differenceInCalendarDayEmp > 0
    ) {
      setModalResponseDays({
        modalType: 'warning',
        title: `Tu plan de Colfactura nómina se vence en ${differenceInCalendarDayEmp} días`,
        body: (
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: 25,
              }}
            >
              {`El plan que adquiriste (Empleado) vence el ${format(
                fechaExpiracionLicenciaEmp,
                'dd/MMM/yyyy',
                { locale: es },
              )}`}
            </p>
            <p
              style={{
                marginTop: 10,
                fontWeight: 700,
                fontSize: 25,
              }}
            >
              {
                'Es necesario renovarlo para que puedas seguir emitiendo documentos. Si quieres comprar tu nuevo plan, haz clic en “Comprar”.'
              }
            </p>
          </div>
        ),
        modalImage: 'warning',
        open: true,
        textButton: 'Comprar',
        closeElement: 'Skip',
        urlButton: `${LICENSE_SHOP_URL}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterData]);

  const handleCloseResponseModal = () => {
    setModalResponse(initialModal);
  };
  const handleCloseResponseModalDays = () => {
    setModalResponseDays(initialModal);
  };

  return (
    <>
      <BrowserRouter history={hist}>
        <Switch>
          <PrivateRoute
            exact
            path="/dashboard"
            component={Home}
            history={hist}
            menuid={0}
          />
          <PrivateRoute
            exact
            path="/myAccount"
            component={MyAccount}
            history={hist}
            menuid={1}
          />
          <PrivateRoute
            exact
            path="/payroll"
            component={PayrollView}
            history={hist}
            menuid={2}
          />
          <PrivateRoute
            exact
            path="/employee"
            component={EmployeesView}
            history={hist}
            menuid={3}
          />
          <PrivateRoute
            exact
            path="/informes"
            component={ReportsView}
            history={hist}
            menuid={8}
          />
          <PrivateRoute
            exact
            path="/helps"
            component={Help}
            history={hist}
            menuid={4}
          />
          <PrivateRoute
            exact
            path="/payrollAdjustment"
            component={AdjustmentManage}
            history={hist}
            menuid={10}
          />
          <PublicRoute
            exact
            path="/home/:queryString"
            component={Index}
            history={hist}
          />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </BrowserRouter>
      <ResponseModal
        modalType={ModalResponse.modalType}
        title={ModalResponse.title}
        body={ModalResponse.body}
        modalImage={ModalResponse.modalImage}
        open={ModalResponse.open}
        textButton={ModalResponse?.textButton}
        urlButton={ModalResponse?.urlButton}
        closeElement={ModalResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
      />
      <ResponseModal
        modalType={ModalResponseDays.modalType}
        title={ModalResponseDays.title}
        body={ModalResponseDays.body}
        modalImage={ModalResponseDays.modalImage}
        open={ModalResponseDays.open}
        textButton={ModalResponseDays?.textButton}
        urlButton={ModalResponseDays?.urlButton}
        closeElement={ModalResponseDays.closeElement}
        onCloseElement={handleCloseResponseModalDays}
      />
    </>
  );
};
export default Startup;
