import React from 'react';

import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { TooltipHorizontalBar } from './custom';

export const HorizontalBar = ({ payrollPayment: data }) => {
  /**
   *  * Se encarga de dar formato a los valores del eje Y
   * @param {*} value
   * @returns {String}
   */
  const formatYAxisTick = (value) => {
    return value < 1000000
      ? `${value.toString().slice(0, 3)} Mil`
      : value < 100000000
      ? `${value.toString().slice(0, 2)} Mill`
      : `${value.toString().slice(0, 3)} Mill`;
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      <ComposedChart
        width={500}
        height={274}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis
          dataKey="name"
          tick={{
            fill: '#FFFFFF',
            fontSize: '12px',
          }}
          axisLine={{ stroke: '#FFFFFF', strokeWidth: 1 }}
          tickSize={0}
          dy={10}
        />
        <YAxis
          axisLine={{
            stroke: '#FFFFFF',
            strokeWidth: 1,
          }}
          tick={{
            fill: '#FFFFFF',
            fontSize: '12px',
          }}
          tickSize={0}
          dx={-10}
          tickFormatter={formatYAxisTick}
        />
        <Tooltip content={<TooltipHorizontalBar />} />
        <Bar dataKey="value" barSize={90} fill="#2CC63E" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
