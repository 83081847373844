import React from 'react';

export const reactValidatorOptions = {
  element: (message) => (
    <span className="font-validator-red float-left">{message}</span>
  ),
  validators: {
    password: {
      message:
        ':attribute debe tener al menos 1 mayúscula, minúscula, número, y caracter, no puede contener espacios.',
      rule: (val, params, validator) => {
        //regex params (1 number)(1 a-z)(1 A-Z)(1 special char)(not contain special chars(space))
        return validator.helpers.testRegex(
          val,
          /^((?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[-_@.,;+{}()/*&%$#!¡¿?'|áéíóúÁÉÍÓÚ])(?!.*?[ ])).+$/i,
        );
      },
      required: true, // optional
    },
    documento: {
      message: 'Número de documento no válido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /(^[0-9]{3,12}$)/);
      },
    },
    correo: {
      message: 'El formato del correo es incorrecto',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(?!.*?\.\.)([A-Za-z0-9_ \-.+])+@([A-Za-z0-9_ \-.])+\.([A-Za-z ]{2,})$/,
        );
      },
    },
    isNumber: {
      message: 'Este campo solo admite valores numericos',
      rule: (val) => {
        return parseInt(val).toString() !== 'NaN';
      },
    },
    isAlphanumeric: {
      message: 'Este campo solo admite valores alfabéticos',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z0-9]+$/);
      },
    },
    address: {
      message: 'Este campo solo admite valores alfabéticos',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z0-9-#.\s]+$/);
      },
    },
    noNegative: {
      message: 'Este campo no admite valores negativos',
      rule: (val) => {
        return parseInt(val) >= -1;
      },
    },
    noDecimal: {
      message: 'Este campo solo admite valores enteros',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^\d+$/);
      },
    },
    noZero: {
      message: 'Cantidad superior a 1',
      rule: (val) => {
        return val > 0;
      },
    },
    passport: {
      message: 'El formato del pasaporte es incorrecto',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z][a-zA-Z0-9]*$/);
      },
    },
    phone: {
      message: 'El número de teléfono no posee un formato válido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(\(\+?\d{1,3}\)[\s|-]?(([\d][\s|-]?){6,7})(([\d][\s|-]?){2})?|(\+?[\d][\s|-]?){6,7}(([\d][\s|\\-]?){2}(([\d][\s|-]?){2})?)?)$/,
        );
      },
    },
    uuid: {
      message: 'Campo no válido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
        );
      },
    },
    percentageValue: {
      message: 'El porcentaje no puede ser mayor a 100%.',
      rule: (val) => {
        return parseFloat(val) > -1 && parseFloat(val) <= 100;
      },
    },
    porcentage: {
      message: 'El porcentaje no puede ser mayor a 100%.',
      rule: (val, validator) => {
        return parseFloat(val) <= 100 ? true : false;
      },
    },
    discountvalue: {
      message:
        'El valor del descuento no debe superar valor unitario * cantidad',
      rule: (val, params, validator) => {
        return parseFloat(val) > parseFloat(params[0]) ? false : true;
      },
      messageReplace: (message, params) =>
        message.replace(':values', params[0].toLocaleString('en')),
    },

    dateNotLessThanOrEqual: {
      message: 'La fecha de pago debe ser mayor a la fecha de la factura',
      rule: (val, params, validator) => {
        var dateOne = new Date(val).getTime();
        var dateTwo = new Date(params[0]).getTime();
        return dateOne <= dateTwo ? false : true;
      },
    },

    percentageTwoDecimal: {
      message: 'Campo no válido, solo se aceptan dos decimales',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^\d+\.?\d{0,2}$/);
      },
    },

    blankSpace: {
      message: 'Campo no válido no puede contener espacios',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z\-0-9]+$/);
      },
    },

    consecutiveBetween: {
      message: 'Campo no válido',
      rule: (val, params, validator) => {
        return parseInt(val) >= params[0] && parseInt(val) <= params[1];
      },
    },

    initialConsecutive: {
      message: 'Campo no válido',
      rule: (val, params, validator) => {
        return parseInt(val) < params[0];
      },
    },

    payrollDate: {
      message:
        'Por favor, indica el mes y año de la nómina que vas a cargar (MM/YYYY)',
      rule: (val) => {
        const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;
        return regex.test(val);
      },
    },

    maxPayrollDate: {
      message: 'El año de la nómina no puede ser mayor al año actual',
      rule: (val, params, validator) => {
        const valor = parseInt(val.split('/')[1]) <= new Date().getFullYear();
        return valor;
      },
    },
    maxMonth: {
      message: 'El mes no puede ser mayor al mes actual',
      rule: (val) => {
        const currentDate = new Date();
        const [inputMonth, inputYear] = val.split('/').map(Number);
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        if (inputYear <= 2014) return false;
        if (inputYear < currentYear) return true;

        return inputYear === currentYear && inputMonth <= currentMonth;
      },
    },
  },

  messages: {
    required: 'Campo obligatorio.',
    email: 'Formato incorrecto.',
    min: ':attribute debe tener al menos :min caracteres.',
    max: ':attribute debe tener :max caracteres como máximo.',
    numeric: ':attribute debe ser un número.',
    integer: 'Debe ser un número',
    phone: 'Formato no válido',
    percentageValue: 'El porcentaje no puede ser mayor a 100%.',
    noNegative: 'Este campo no admite valores negativos',
    porcentage: 'El porcentaje no puede ser mayor a 100%.',
    discountvalue: 'El valor del descuento no debe superar  $ :values',
    default: 'Campo no válido!',
    percentageTwoDecimal: 'Campo no válido, solo se aceptan dos decimales',
    blankSpace: 'Campo no válido, no puede contener espacios',
    consecutiveBetween:
      'El campo consecutivo actual debe estar en el intervalo del consecutivo inicial y el consecutivo final',
    initialConsecutive:
      'El campo consecutivo inicial debe ser menor que el consecutivo final',
    dateNotLessThanOrEqual:
      'La fecha de pago debe ser mayor a la fecha de la factura',
    minSalary: 'El nuevo salario no puede ser inferior al actual',
    payrollDate:
      'Por favor, indica el mes y año de la nónima que vas a cargar (MM/YYYY)',
    maxPayrollDate: 'El año de la nómina no puede ser mayor al año actual',
    maxMonth:
      'El mes no puede ser mayor al mes actual o por favor revisa el año ingresado',
  },
};
