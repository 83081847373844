import moment from 'moment';
import { columnsFile } from '../validation/columnsFile';

/**
 * Elimina caracteres que no se desean en determinadas columnas
 *
 * @param {} value
 * @param {*} header
 */
export const removeUnwantedCharacters = (value, header) => {
  if (
    (value && header === columnsFile.salarioColumn.name) ||
    (value && header === columnsFile.numeroDocumentoColumn.name)
  ) {
    value = value.replace('$', '').replace(/,/g, '').replace(' ', '');

    return parseFloat(value);
  }
  return value;
};

export const removeUnwantedCharactersDate = (value, header) => {
  let finalDate = null;

  if (
    (value && header === columnsFile.fechaFinalizacionColumn.name) ||
    (value && header === columnsFile.fechaIngresoColumn.name)
  ) {
    if (value === null || value === undefined) {
      return (value = null);
    }
    const date = value;
    const dateMoment = moment(date, 'DD/MM/YYYY');
    finalDate = dateMoment.format('DD/MM/YYYY');
  }

  return finalDate;
};

/**
 * Modificar el formato de la fecha para enviar al servicio de carga masiva
 * @param {*} value date
 */
export const dateFormat = (value) => {
  const date = value;
  const dateMoment = moment(date, 'DD/MM/YYYY');
  const dateFormat = dateMoment.format('YYYY/MM/DD');
  return dateFormat !== undefined ? dateFormat : null;
};

/**
 * Separa la cadena de texto apra obtener el codigo o descripción del campo
 * @param {*} str
 * @returns
 */
export const getSplitValueString = (str, split) => {
  const stringSplit = str.split(split);
  const result = stringSplit[0].trim();

  return result;
};

/**
 * Agrupa errores por numero de registro
 * @param {lista de errores} results
 */
export const groupByLine = (results) => {
  if (results) {
    return results.reduce((r, a) => {
      r[a.line] = [...(r[a.line] || []), a];
      return r;
    }, {});
  }
  return '';
};

/**
 * * Convierte una cadena base64 en un archivo de Excel e inicia la descarga del archivo.
 * @param data - Es una cadena codificada en base64 que representa los datos del
 * archivo Excel. Este es el contenido del archivo de Excel que desea convertir y descargar.
 * @param [nameFile=Cargue_Masivo_Nomina.xlsm] - El nombre del archivo que se descargará. De forma
 * predeterminada, está configurado en 'Cargue_Masivo_Nomina.xlsm'.
 */
export const convertBase64ToExcel = async (
  data,
  nameFile = 'Cargue_Masivo_Nomina.xlsm',
) => {
  const byteCharacters = atob(data);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: 'application/vnd.ms-excel',
  });

  // Crear una URL de objeto para el blob de Excel
  const url = URL.createObjectURL(blob);

  // Crear un enlace y hacer clic en él para iniciar la descarga
  const link = document.createElement('a');
  link.href = url;
  link.download = nameFile;
  link.click();
};
