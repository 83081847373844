import { types } from '../actions/types';

const initialState = {
  listReported: [],
  loadingReported: false,
  spinnerReported: false,
  spinnerReportedDetail: false,
  filterByDates: {
    Destacado: null,
    empresaId: '87',
    Fechadesde: null,
    Fechahasta: null,
    Limit: 10,
    LoadMore: false,
    Offset: 0,
  },
  detailSelect: {},
  nominaid: null,
};

export const payrollReportedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYROLL_REPORTED:
      return {
        ...state,
        listReported: action.payload,
      };
    case types.LOADING_PAYROLL_REPORTED:
      return {
        ...state,
        loadingReported: action.payload,
      };
    case types.LOADING_SPINNER_PAYROLL_REPORTED:
      return {
        ...state,
        spinnerReported: action.payload,
      };
    case types.LOADING_SPINNER_PAYROLL_REPORTED_DETAIL:
      return {
        ...state,
        spinnerReportedDetail: action.payload,
      };
    case types.FILTER_BY_DATES_REPORTED:
      return {
        ...state,
        filterByDates: action.payload,
      };
    case types.GET_PAYROLL_DETAIL_REPOTED:
      return {
        ...state,
        detailSelect: action.payload,
      };
    case types.SET_PAYROLL_FAVORITE_REPORTED:
      const arr = state.listReported.map((payroll) => {
        if (payroll.id === action.payload.Id) {
          payroll = {
            ...payroll,
            favorito: action.payload.Favorito,
          };
        }
        return payroll;
      });

      return {
        ...state,
        listReported: arr,
        detailSelect: {
          ...state.detailSelect,
          favorito: action.payload.Favorito,
        },
      };

    default:
      return state;
  }
};
