import { types } from '../actions/types';

const initialState = {
  incomesPayroll: [],
  incomeActive: null,
  loadingAdd: false,
  loadingDelete: 0,
};

export const incomePayrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_INCOME_PAYROLL:
      return {
        ...state,
        incomesPayroll: [...state.incomesPayroll, action.payload],
      };
    case types.LOAD_INCOME_PAYROLL:
      return {
        ...state,
        incomeActive: action.payload,
      };
    case types.UPDATE_INCOME_PAYROLL:
      return {
        ...state,
        incomesPayroll: state.incomesPayroll.map((income) =>
          income.identificador === action.payload.identificador
            ? { ...action.payload }
            : { ...income },
        ),
      };
    case types.DELETE_INCOME_PAYROLL:
      return {
        ...state,
        incomesPayroll: state.incomesPayroll.filter(
          (c) => c.identificador !== action.payload,
        ),
      };
    case types.FILL_INCOMES_PAYROLL:
      return {
        ...state,
        incomesPayroll: [...action.payload],
      };
    case types.LOADING_ADD_INCOME:
      return {
        ...state,
        loadingAdd: action.payload,
      };
    case types.LOADING_DELETE_INCOME:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case types.CLEAN_INCOMES_REDUCER:
      return initialState;
    default:
      return state;
  }
};
