import React, { useEffect } from 'react';
import MyAccountManager from '../../components/_myAccount';

const Payroll = () => {
  useEffect(() => {
    // console.log('desviando trafico a hooks ');
  }, []);

  return (
    <>
      {true ? (
        <>
          <MyAccountManager />
        </>
      ) : (
        <p>cargando</p>
      )}
    </>
  );
};

export default Payroll;
