/* eslint-disable array-callback-return */
import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from '../config/config';
import {
  UPDATE_BRANCH_OFFICE,
  LOAD_BRANCH_OFFICE,
  SAVE_BRANCH_OFFICE,
  STATUS_STEP_BRANCH_OFFICE,
  STATUS_EDIT_BRANCH_OFFICE,
  UPDATE_STATE_BRANCH_OFFICE,
  GET_BRANCH_OFFICES,
  UPDATE_PRINCIPAL_BRANCH,
  INFO_SAVE_BRANCH_OFFICES,
  CLEAN_RESPONSES,
  LOADING_SAVE_BRANCH,
} from './types';
import { axiosApiInstance } from '../config/axios-instance';
import { enterpriseIdHeader } from './authActions';
import { showErrorMessage } from '../utils/showErrorService';

const urlBranchOffice = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/SucursalNomina`;

/**
 * Abre el modal que informa que no hay cupo para crear sucursales
 * @param {object} data boolean en true
 */
export const updateOpenModalItem = (data) => (dispatch) => {
  dispatch({
    type: INFO_SAVE_BRANCH_OFFICES,
    payload: data,
  });
};

/**
 * Actualiza estado loading guardado o actualización sucursal
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_BRANCH,
    payload: status,
  });
}

/**
 * Registra información de sucursal en BD (NO LLAMAR DESDE useMyAccount FR)
 * @param {object} body Información sucursal
 */
export const saveBranchOfficeAction = (data, reset) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    const body = {
      ...data,
      empresaid: enterpriseIdHeader(),
    };

    const response = await axiosApiInstance.post(
      `${urlBranchOffice}/CreateOfficeNomina`,
      body,
      defaultHeaders(),
    );

    reset();
    dispatch({
      type: SAVE_BRANCH_OFFICE,
      payload: response.data,
    });
  } catch (err) {
    if (readUnauthorizedError(err)) {
      dispatch({
        type: INFO_SAVE_BRANCH_OFFICES,
        payload: true,
      });
    } else {
      showErrorMessage(err, 'No se ha podido guardar la sucursal.');
    }
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

const readUnauthorizedError = (err) => {
  if (
    err.hasOwnProperty('response') &&
    err?.response?.hasOwnProperty('data') &&
    err?.response?.data?.statusCode === '404' &&
    err?.response?.data?.statusMessage === 'Unauthorized, no quota'
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Actualiza información de sucursal en BD
 * @param {object} body Nueva informacion de sucursal
 */
export const updateBranchOfficeAction = (data, reset) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    data = {
      ...data,
      empresaid: enterpriseIdHeader(),
    };

    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdateOfficeNomina`,
      data,
      config,
    );
    reset();
    dispatch({
      type: UPDATE_BRANCH_OFFICE,
      payload: resp.data,
    });
  } catch (err) {
    showErrorMessage(err, 'No se ha podido guardar la sucursal.');
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

export const changeStepStatusAction = (status) => (dispatch) => {
  dispatch({
    type: STATUS_STEP_BRANCH_OFFICE,
    payload: status,
  });
};

export const changeEditStatusAction = (status) => (dispatch) => {
  dispatch({
    type: STATUS_EDIT_BRANCH_OFFICE,
    payload: status,
  });
};

export const updatePrincipalBranchOfficeAction = ({
  payload,
  idOldPrincipal,
  idNewPrincipal,
}) => {
  return {
    type: UPDATE_PRINCIPAL_BRANCH,
    payload1: payload,
    payload2: idOldPrincipal,
    payload3: idNewPrincipal,
  };
};

export const updateStateBranchOfficeAction = ({
  payload,
  idBranch,
  idState,
}) => {
  return {
    type: UPDATE_STATE_BRANCH_OFFICE,
    payload1: payload,
    payload2: idBranch,
    payload3: idState,
  };
};

export const loadBranchOfficeAction = (payload) => {
  return {
    type: LOAD_BRANCH_OFFICE,
    payload: payload,
  };
};

export const getBranchOfficeAction = (payload) => {
  return { type: GET_BRANCH_OFFICES, payload: payload };
};

export const cleanResponseAction = () => {
  return { type: CLEAN_RESPONSES };
};
