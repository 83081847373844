/**
 * * Devuelve la clase CSS correspondiente según el tipo de resultado.
 *
 * @param {string} item - El tipo de resultado ('Actualizado', 'Error' u otro).
 * @returns {string} La clase CSS correspondiente.
 */
export const textStyle = (item, classes) => {
  const resultStyles = [
    { type: 'Mixtos', className: classes.textUpdate },
    { type: 'Error', className: classes.textError },
    { type: 'Transmitidos', className: classes.textTransmit },
  ];

  const matchedStyle = resultStyles.find((style) => style.type === item);

  return matchedStyle ? matchedStyle.className : classes.textValidate;
};
