import React from 'react';

import {
  TooltipMessageCard,
  getTitleResult,
  iconResult,
  textStyle,
} from './helpers';

export const RenderCardResult = (
  result,
  messageTittle,
  messageText,
  category,
  classes,
) => {
  let arrayData = [];

  return (
    <div className={classes.gridContainerResult}>
      <div className={classes.gridContainerTittle}>
        <span className={classes.gridTittle}>{getTitleResult(category)}</span>
        {TooltipMessageCard(messageTittle, messageText)}
      </div>
      <div>
        <span className={`${textStyle(category, classes)} ${classes.gridText}`}>
          {typeof result === 'number' ? result : arrayData.length}
        </span>
        <img
          src={iconResult(category)}
          alt="Bulkload Icon"
          style={{ marginBottom: '4px' }}
        />
      </div>
    </div>
  );
};
