import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, makeStyles, TextField } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';

import FilterIcon from '../../images/icons/filterIcon.png';
import SearchIcon from '../../images/icons/searchIcon.png';

import { modalFilters } from './modalsBulkload/ModalFilters';
import PopoverModal from '../modal/popoverModal.component';

import { ListInformation } from './';
import { filterData } from './helpers';

import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  divListResult: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '34px',
  },
  divFilter: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  paper: {
    textAlign: 'left',
    color: '#FFFFFF',
    paddingLeft: '10px',
    background: '#363636',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '36px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridTittle: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '33px',
    color: '#2CC63E',
  },
  gridListTittle: {
    background: 'rgba(18, 18, 18, 0.3)',
    borderRadius: '5px',
    width: '90%',
    height: '36px',
    textAlign: 'center',
  },
  gridList: {
    width: '90%',
  },
  cardIcon: {
    paddingRight: '10px',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  inputSearch: {
    color: '#FFFFFF',
    width: '97%',
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #848484',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #16B1F3',
    },
  },
}));

const initialCheckbox = {
  Mixto: false,
  Error: false,
  Transmitido: false,
  Registrado: false,
};

export const ListLoadInformation = () => {
  const classes = useStyles();
  const { bulkLoadPayrollResponse } = useSelector(
    (s) => s.payrollBulkLoadReducer,
  );

  const [stateCheck, setStateCheck] = useState(initialCheckbox);
  const [filterValue, setFilterValue] = useState('');
  const [modalChecks, setModalChecks] = useState(null);
  const [loadinEmployee, setLoadingEmployee] = useState(true);
  const [dataEmployeeList, setDataEmployeeList] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  const valueDebounce = useDebounce(filterValue, 300);

  useEffect(() => {
    setDataEmployeeList(bulkLoadPayrollResponse);
  }, [bulkLoadPayrollResponse]);

  useEffect(() => {
    setLoadingEmployee(false);
    const filteredData = filterData(dataEmployeeList, stateCheck, filterValue, [
      'documentType',
      'concepts',
    ]);

    setDataFilter(filteredData);
    setLoadingEmployee(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDebounce, stateCheck, dataEmployeeList]);

  /**
   *   * Actualiza filtro estado por parametro
   * @param {*} e
   */
  const handleInputSearchChange = (e) => {
    e.preventDefault();
    setFilterValue(e.target.value);
  };

  /**
   * * Abre filtro por estado
   * @param {*} event
   */
  const handleOpenModalChecks = (event) => {
    setModalChecks(event.currentTarget);
  };

  /**
   * * Cierra filtro estado
   */
  const handleCloseModalChecks = () => {
    setModalChecks(null);
  };

  /**
   *  * Marca filtro estado
   * @param {*} itemName
   */
  const handleCheckFilter = (itemName) => {
    setStateCheck({
      ...stateCheck,
      [itemName]: !stateCheck[itemName],
    });
  };

  /**
   * * Limpia filtro estado inicial
   */
  const handleCleanFilter = () => {
    setStateCheck(initialCheckbox);
    setDataFilter(dataEmployeeList);
    setFilterValue('');
  };

  return (
    <div className={classes.root}>
      <div className={classes.divListResult}>
        <Grid item xs={12} className={classes.gridListTittle}>
          <span className={classes.gridTittle}> Detalle del cargue </span>
        </Grid>
      </div>
      <div
        className={classes.divFilter}
        style={{ width: '99%', alignItems: 'flex-end' }}
      >
        <div
          style={{
            paddingLeft: '20px',
            display: 'flex',
            alignItems: 'flex-start',
            width: '87%',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            xs={11}
            className={classes.gridList}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-18px',
            }}
          >
            <TextField
              label=""
              value={filterValue}
              onChange={handleInputSearchChange}
              className={classes.inputSearch}
            />
            <IconButton disabled={false}>
              <img
                alt={'Filtrar'}
                width="20px"
                src={SearchIcon}
                style={{
                  padding: '0',
                  position: 'absolute',
                  left: '-22px',
                  top: '-3px',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs style={{ maxWidth: '20px' }}>
            <IconButton
              onClick={handleOpenModalChecks}
              disabled={false}
              style={{ padding: '0' }}
            >
              <img alt={'Filtrar'} width="20px" src={FilterIcon} />
            </IconButton>
          </Grid>
        </div>
        <PopoverModal
          handleClose={handleCloseModalChecks}
          open={Boolean(modalChecks)}
          component={() =>
            modalFilters(
              classes,
              stateCheck,
              handleCheckFilter,
              handleCleanFilter,
            )
          }
          width={191}
          popoverAnchorEl={modalChecks}
          showCloseButton={false}
        />
      </div>
      <ListInformation
        loadinEmployee={loadinEmployee}
        dataEmployeeList={dataFilter}
      />
    </div>
  );
};
