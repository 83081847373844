import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

const ResumeProcess = ({ setContentTab }) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>Resumen carga masva</Grid>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   configReducer: state.configReducer,
//   menuReducer: state.menuReducer,
//   basicDataReducer: state.basicDataReducer,
//   employeeReducer: state.employeeReducer,
// });

export default ResumeProcess;
