import { types } from '../actions/types';

const initialState = {
  expensesPayroll: [],
  expenseActive: null,
  loadingAdd: false,
  loadingDelete: 0,
};

export const expensePayrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_EXPENSE_PAYROLL:
      return {
        ...state,
        expensesPayroll: [...state.expensesPayroll, action.payload],
      };
    case types.LOAD_EXPENSE_PAYROLL:
      return {
        ...state,
        expenseActive: action.payload,
      };
    case types.UPDATE_EXPENSE_PAYROLL:
      return {
        ...state,
        expensesPayroll: state.expensesPayroll.map((expense) =>
          expense.identificador === action.payload.identificador
            ? { ...action.payload }
            : { ...expense },
        ),
      };
    case types.DELETE_EXPENSE_PAYROLL:
      return {
        ...state,
        expensesPayroll: state.expensesPayroll.filter(
          (c) => c.identificador !== action.payload,
        ),
      };
    case types.FILL_EXPENSES_PAYROLL:
      return {
        ...state,
        expensesPayroll: [...action.payload],
      };
    case types.LOADING_ADD_EXPENSE:
      return {
        ...state,
        loadingAdd: action.payload,
      };
    case types.LOADING_DELETE_EXPENSE:
      return {
        ...state,
        loadingDelete: action.payload,
      };
    case types.CLEAN_EXPENSE_REDUCER:
      return initialState;
    default:
      return state;
  }
};
