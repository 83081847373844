import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  tooltip: {
    color: '#52D769',
  },
  icon: {
    color: 'gray',
  },
}));

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#16B1F3', //verde
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#16B1F3', //verde
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& input.Mui-disabled': {
      color: 'rgba(255,255,255,0.5);',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray', //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#16B1F3', //verde
      },
    },
  },
})(TextField);

const Input = ({ ...props }) => {
  const {
    id,
    onChange,
    value,
    label,
    disabled,
    validator,
    validateOptions,
    hidden,
    name,
    onBlur,
    maxLength,
    nameValidator,
    onSearch,
  } = props;
  const classes = useStyles();

  let errorValidator = validator
    ? validator.message(
        !!nameValidator ? nameValidator : label,
        value,
        validateOptions,
      )
    : '';

  if (!!hidden) return null;

  return (
    <div className={classes.root}>
      <CustomInput
        fullWidth
        disabled={disabled === true ? true : false}
        label={label}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={!!errorValidator ? true : false}
        helperText={errorValidator}
        onBlur={onBlur}
        autoComplete="off"
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Realizar busqueda...">
                <IconButton
                  onClick={onSearch}
                  disabled={disabled === true ? true : false}
                >
                  <SearchIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
};

export default Input;
