import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Grid, IconButton } from '@material-ui/core/';
import CustomProgress from '../../components/Progress/progress.component';

import './modal.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.modalBackgroundColor,
  },
  paper: {
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    color: theme.palette.primaryColor,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
  },
  iconDownload: {
    color: theme.palette.thirdColor,
    textAlign: 'left',
    marginBottom: '2rem',
  },
  divContainerBottom: {
    width: '80%',
    textAlign: 'right',
    marginBottom: '2rem',
  },
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    textAlign: 'center',
  },
  titleModal: {
    fontSize: '30px',
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  subTitleModal: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
  bodyModal: {
    color: theme.palette.primaryColor,
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  successTitle: {
    color: theme.palette.thirdColor,
  },
  warningTitle: {
    color: theme.palette.fourthColor,
  },
  dangerTitle: {
    color: theme.palette.fifthColor,
  },
  showObject: {
    display: 'block',
  },
  hideObject: {
    display: 'none',
  },
}));

function FileModal({ paramFile, closeElement, onCloseElement, open }) {
  const classes = useStyles();
  const [fileobj, setFileobj] = React.useState({ archivoBase64: null });
  const [xmlobj, setXmlobj] = React.useState({ archivoBase64: null });
  const [loading, setLoading] = React.useState(true);
  const windowsHeight = parseInt(window.innerHeight * 0.78, 10);
  const heightDiv = windowsHeight + 'px';

  const functionResp = (response) => {
    if (response && response !== '' && response.length > 0) {
      if (response.length > 0) {
        response.forEach((element) => {
          if (element.extensionArchivo === 'pdf') {
            setFileobj(response[0]);
          } else if (element.extensionArchivo === 'xml') {
            setXmlobj(response[1]);
          }
        });
      } else {
        setFileobj(response);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (paramFile) {
      functionResp(paramFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramFile]);

  /**
   * Cierra modal despes de error
   */
  const closeModal = () => {
    onCloseElement();
    setFileobj({ archivoBase64: null });
    setXmlobj({ archivoBase64: null });
  };

  const contentModal = (
    <div className={classes.paper}>
      <Grid container justify="flex-end" alignItems="center">
        <Grid item lg={2} md={2} sm={12}>
          <IconButton
            className={classes.iconClose}
            onClick={() => closeModal()}
          >
            {closeElement}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={10} md={10} sm={12}>
          <div className={classes.divContainer}>
            <div className={classes.bodyModal}>
              {loading ? (
                <Fragment>
                  Cargando archivo
                  <CustomProgress />
                </Fragment>
              ) : (
                <div
                  className={
                    fileobj === null
                      ? classes.showObject
                      : fileobj.archivoBase64 == null
                      ? classes.showObject
                      : classes.hideObject
                  }
                >
                  No se encontró el archivo
                </div>
              )}
              <div
                className={
                  fileobj === null || loading
                    ? classes.hideObject
                    : fileobj.archivoBase64 == null
                    ? classes.hideObject
                    : classes.showObject
                }
              >
                <iframe
                  src={'data:application/pdf;base64,' + fileobj.archivoBase64}
                  title="File"
                  height={heightDiv}
                  width={'100%'}
                ></iframe>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="center">
        {xmlobj.archivoBase64 !== null && (
          <Grid item lg={3} md={3} sm={12}>
            <div className={classes.divContainerBottom}>
              <IconButton
                download={xmlobj.nombre}
                href={
                  'data:' +
                  xmlobj.tipoContenido +
                  ';base64,' +
                  xmlobj.archivoBase64
                }
                className={classes.iconDownload}
              >
                Descargar XML
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );

  return (
    <div>
      <Modal
        aria-labelledby="response-modal-title"
        aria-describedby="response-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>{contentModal}</Fade>
      </Modal>
    </div>
  );
}

FileModal.propTypes = {
  getNonElectronicDocumentFileAction: PropTypes.func.isRequired,
  closeElement: PropTypes.element.isRequired,
  onCloseElement: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FileModal;
