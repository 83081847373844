import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';

import DonwloadIcon from '../../../../images/icons/donwloadBulkloadError.png';
import ReactExport from 'react-data-export';
import { cleanDataAction } from '../../../../actions/bulkLoadAction';
import { ConfirmAlertBackStep } from '../../../../helpers/alert.helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '15px !important',
    padding: 0,
  },
  clearIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerStep: {
    backgroundColor: '#393939',
    [theme.breakpoints.down(1398)]: {
      marginTop: '3%',
    },
  },
  footerIcon: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '174px',
  },
  label: {
    color: 'white',
    flexDirection: 'column',
    fontSize: 12,
  },
  labelHorizontal: {
    color: 'white',
    flexDirection: 'row',
    fontSize: 12,
  },
}));

const FooterStepper = (
  step,
  setBulkLoad,
  setActiveStep,
  setModalData,
  setUpdateEmployee,
  modalData,
) => {
  const [dataSet, SetDataSet] = useState([]);
  const classes = useStyles();
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const dispatch = useDispatch();

  const { bulkLoadDataSet } = useSelector((s) => s.bulkLoadReducer);

  const handleCancelDocument = () => {
    setBulkLoad(false);
    setUpdateEmployee(true);
    dispatch(cleanDataAction());
  };

  const backStep = (confirmed) => {
    if (confirmed) {
      setActiveStep(step - 1);
      dispatch(cleanDataAction());
    }
  };

  /**
   * @description Función que permite regresar a la vista anterior
   */
  const handleBackStep = () => {
    if (!modalData && step === 1) {
      ConfirmAlertBackStep(
        `¿Estás seguro de que desear realizar esta acción? Si lo haces, dejarás de ver la información cargada?`,
        backStep,
        'Si',
        'No',
      );
    }
    if (step === 1) setModalData(false);
  };

  useEffect(() => {
    bulkLoadDataSet && SetDataSet(bulkLoadDataSet);
  }, [bulkLoadDataSet]);

  return (
    <>
      {step === 0 && (
        <>
          <Grid container className={classes.footerStep}>
            <Grid
              item
              lg={12}
              xs={12}
              style={{ margin: '10px', padding: '0px' }}
            >
              <div className="bottomButtonsSupportRevision">
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.clearIcon}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      onClick={handleCancelDocument}
                    >
                      <ClearIcon />
                      <span style={{ marginTop: 5 }}>Cancelar</span>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {step === 1 && !modalData && (
        <>
          <Grid
            container
            spacing={3}
            style={{ width: '101%' }}
            className={classes.footerStep}
          >
            <Grid item lg={12} xs={12} style={{ height: 66 }}>
              <div className="bottomButtonsSupportRevision">
                <Grid container spacing={1}>
                  <Grid
                    item
                    lg={2}
                    xs={2}
                    style={{ textAlign: 'left', maxWidth: 100 }}
                  >
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.labelHorizontal,
                      }}
                      variant="raised"
                      disableRipple={true}
                      onClick={() => handleBackStep()}
                      style={{ marginLeft: 20, marginTop: 10 }}
                    >
                      <ArrowBackIosIcon />
                      <span style={{ marginLeft: 5, fontSize: 14 }}>
                        Volver
                      </span>
                    </IconButton>
                  </Grid>
                  <Grid item lg={10} xs={10} className={classes.footerIcon}>
                    <IconButton
                      classes={{ root: classes.button, label: classes.label }}
                      variant="raised"
                      disableRipple={true}
                      style={{ marginRight: 10 }}
                    >
                      <ExcelFile
                        filename="Registro_de_errores_COLNOMINA"
                        element={
                          <>
                            <figure
                              container
                              style={{
                                marginBlockEnd: '6px',
                                marginBlockStart: '-4px',
                              }}
                            >
                              <img
                                src={DonwloadIcon}
                                className={classes.infografia}
                                alt="Infografia"
                              />
                            </figure>
                            <span style={{ marginTop: 5 }}>
                              Descargar informe de errores
                            </span>
                          </>
                        }
                      >
                        <ExcelSheet dataSet={dataSet} name="Organization" />
                      </ExcelFile>
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {step === 1 && modalData && (
        <>
          <Grid
            container
            spacing={3}
            style={{ width: '101%' }}
            className={classes.footerStep}
          >
            <Grid item lg={12} xs={12} style={{ height: 66 }}>
              <div className="bottomButtonsSupportRevision">
                <Grid container spacing={1}>
                  <Grid
                    item
                    lg={2}
                    xs={2}
                    style={{ textAlign: 'left', maxWidth: 100 }}
                  >
                    <IconButton
                      classes={{
                        root: classes.button,
                        label: classes.labelHorizontal,
                      }}
                      variant="raised"
                      disableRipple={true}
                      onClick={() => handleBackStep()}
                      style={{ marginLeft: 20, marginTop: 10 }}
                    >
                      <ArrowBackIosIcon />
                      <span style={{ marginLeft: 5, fontSize: 14 }}>
                        Volver
                      </span>
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    xs={10}
                    className={classes.footerIcon}
                  ></Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default FooterStepper;
