export const columnsFile = {
  tipoDocumentoColumn: {
    name: 'Tipo de Documento',
    // msgBackValidations: 'No existe este medio de pago',
  },
  numeroDocumentoColumn: {
    name: 'Numero de documento',
    msgBackValidations: 'No existe este numero de documento',
  },
  primerApellidoColumn: {
    name: 'Primer Apellido',
    msgBackValidations: 'No existe este primer apellido',
  },
  segundoApellidoColumn: {
    name: 'Segundo Apellido',
    msgBackValidations: 'No existe este segundo apellido',
  },
  primerNombreColumn: {
    name: 'Primer Nombre',
    msgBackValidations: 'No existe este primer nombre',
  },
  otrosNombresColumn: {
    name: 'Otros Nombres',
    msgBackValidations: 'No existe este otro nombre',
  },
  correoElectronicoColumn: {
    name: 'Correo Electronico',
    msgBackValidations: 'No existe este correo electrónico',
  },
  telefonoColumn: {
    name: 'Telefono',
  },
  municipioColumn: {
    name: 'Municipio',
  },
  direccionColumn: {
    name: 'Dirección',
  },
  tipoTrabajadorColumn: {
    name: 'Tipo Trabajador',
  },
  subTipoTrabajadorColumn: {
    name: 'SubTipo Trabajador',
  },
  tipoContratoColumn: {
    name: 'Tipo Contrato',
  },
  //no tiene tilde el número de contrato en el excel revisar si le añaden la tilde
  numeroContratoColumn: {
    name: 'Numero de contrato',
  },
  fechaIngresoColumn: {
    name: 'fecha de ingreso',
  },
  fechaFinalizacionColumn: {
    name: 'Fecha de Finalización contrato',
  },
  cargoColumn: {
    name: 'Cargo',
  },
  salarioColumn: {
    name: 'Salario',
  },
  periodoPagoColumn: {
    name: 'Periodo de pago',
  },
  salarioIntegralColumn: {
    name: 'Salario Integral',
  },
  trabajoAltoRiesgoColumn: {
    name: 'trabajo de alto riesgo',
  },
  sucursalColumn: {
    name: 'Prefijo de la Sucursal',
  },
  municipioLugarTrabajoColumn: {
    name: 'Municipio lugar de trabajo',
  },
  direccionLugarTrabajoColumn: {
    name: 'Dirección de trabajo',
  },
  medioPagoColumn: {
    name: 'Medio de pago',
  },
  descripcionMedioPagoColumn: {
    name: 'Descripcion',
  },
  entidadFinancieraColumn: {
    name: 'entidad financiera',
  },
  tipoCuentaColumn: {
    name: 'Tipo de cuenta',
  },
  numeroCuentaColumn: {
    name: 'Numero de cuenta',
  },
};
