import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomProgress from '../../../../../components/Progress/progress.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5C5C5C', //  theme.palette.fourthBackgroundColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  showLayer: {
    display: 'block',
  },
  hideLayer: {
    display: 'none',
  },
  letter: {
    color: '#FFFFFF',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  widthMax: {
    width: '100%',
  },
  paddingIcons: {
    paddingTop: '20%',
  },
  width99: {
    width: '99%',
  },
  width98: {
    width: '98%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width97: {
    width: '97%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width90: {
    width: '90%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  letterTaxes: {
    color: '#2CC63E',
  },
  totalInvoiceDetail: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: '800',
    letterSpacing: '0',
    textAlign: 'left',
  },
  colorIcon: {
    color: '#2CC63E',
  },
  h1Card: {
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
  },
  cover: {
    width: '100%',
    height: '100%',
  },
  containerLoading: {
    paddingTop: 200,
  },
  rootchoose: {
    backgroundColor: '#16B1F3',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  rootsel: {
    backgroundColor: '#3B3B3B',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
}));

const AditionalInformation = ({ data }) => {
  const classes = useStyles();
  // const { data, configReducer } = props;
  const [state, setState] = useState(null);
  const [stateWork, setStateWork] = useState(null);
  //const [listContractType, setlistContractType] = useState();

  // store
  const listStates = useSelector(
    (state) => state.configReducer.getStatesResponse || null,
  );

  useEffect(() => {
    if (listStates && listStates.length > 0 && data) {
      const stateAux =
        listStates.find(
          (element) =>
            Number.parseInt(element.value) === data.ciudad?.departamentoid,
        ) || '';
      const stateWorkAux =
        listStates.find(
          (element) =>
            Number.parseInt(element.value) ===
            data.trabajociudad?.departamentoid,
        ) || '';
      setState(stateAux);
      setStateWork(stateWorkAux);
    } else {
      setStateWork('');
      setState('');
    }
  }, [listStates, data]);

  return (
    <Fragment>
      {!!data ? (
        <div>
          <div>
            {/* Datos Basicos del empleado  */}
            <div className="container-title"> Datos Básicos</div>
            <Card className={classes.root}>
              <CardContent>
                <Grid container className="fieldsReadContainer">
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    className="fieldsReadMini"
                    style={{ width: '96%' }}
                  >
                    Correo electrónico
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    className="fieldsReadBig"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {data.correoelectronico}
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12}>
                      <div className={classes.width97}></div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className="fieldsReadContainer">
                  <Grid item lg={6} sm={12} className="fieldsReadMini">
                    Departamento
                  </Grid>
                  <Grid item lg={6} sm={12} className="fieldsReadMini">
                    Municipio
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={6} sm={12}>
                    {state?.text}
                    <div className={classes.width97}></div>
                  </Grid>
                  <Grid item lg={6} sm={12} className="fieldsReadBig">
                    {data.ciudad?.descripcion}
                    <div className={classes.width97}></div>
                  </Grid>
                </Grid>
                <Grid container className="fieldsReadContainer">
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    className="fieldsReadMini"
                    style={{ width: '96%' }}
                  >
                    Dirección
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    className="fieldsReadBig"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {data.direccion}
                    <div className={classes.width97}></div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
          {/* Datos de contratacion */}
          <div className="spacingInvoice"></div>
          <div className="container-title">Datos de contratación</div>
          <div className="spacingInvoice"></div>
          <Card className={classes.root}>
            <CardContent>
              <Grid container className="fieldsReadContainer">
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Tipo trabajador
                </Grid>
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Sub tipo trabajador
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} md={6} sm={12}>
                  {data.tipotrabajador?.descripcion || ''}
                  <div className={classes.width97}></div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  style={{ alignSelf: 'flex-end' }}
                >
                  {data.subtipotrabajador?.descripcion || ''}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>
              <Grid container className="fieldsReadContainer">
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Trabajo de alto riesgo
                </Grid>
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Salario integral
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} md={6} sm={12}>
                  {!data.altoriesgo ? 'NO' : 'SI'}
                  <div className={classes.width97}></div>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {!data.salariointegral ? 'NO' : 'SI'}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>
              <Grid container className="fieldsReadContainer">
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Tipo contrato
                </Grid>
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Número contrato
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} md={6} sm={12}>
                  {data.tipocontrato?.descripcion}
                  <div className={classes.width97}></div>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {data.numerocontrato || 'N/A'}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>
              <Grid container className="fieldsReadContainer">
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Depar. lugar de trabajo
                </Grid>
                <Grid item lg={6} md={6} sm={12} className="fieldsReadMini">
                  Municipio lugar de trabajo
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} md={6} sm={12}>
                  {stateWork?.text}
                  <div className={classes.width97}></div>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {data.trabajociudad?.descripcion}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>

              <Grid container className="fieldsReadContainer">
                <Grid item lg={12} md={12} sm={12} className="fieldsReadMini">
                  Dirección lugar de trabajo
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12}>
                  {data.trabajodireccion}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>

              <Grid container className="fieldsReadContainer">
                <Grid item lg={12} md={12} sm={12} className="fieldsReadMini">
                  Causal de retiro
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12}>
                  {!!data.causalretiro ? data.causalretiro?.descripcion : 'N/A'}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div className="spacingInvoice"></div>
          <div className="container-title">Forma de pago</div>
          <div className="spacingInvoice"></div>
          <Card className={classes.root}>
            <CardContent>
              <Grid container className="fieldsReadContainer">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className="fieldsReadMini"
                  style={{ width: '96%' }}
                >
                  Medio de pago
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className="fieldsReadBig"
                  style={{ wordBreak: 'break-word' }}
                >
                  {data.dianmediopago?.descripcion}
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12}>
                    <div className={classes.width97}></div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="fieldsReadContainer">
                <Grid item lg={8} md={8} sm={12} className="fieldsReadMini">
                  Entidad financiera
                </Grid>
                <Grid item lg={4} md={4} sm={12} className="fieldsReadMini">
                  Tipo de cuenta
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={8} md={8} sm={12}>
                  {!!data.entidadbancaria
                    ? data.entidadbancaria?.descripcion
                    : '-'}
                  <div className={classes.width97}></div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} className="fieldsReadBig">
                  {!!data.tipocuenta ? data.tipocuenta?.descripcion : '-'}
                  <div className={classes.width97}></div>
                </Grid>
              </Grid>
              <Grid container className="fieldsReadContainer">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className="fieldsReadMini"
                  style={{ width: '96%' }}
                >
                  No. de cuenta
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  className="fieldsReadBig"
                  style={{ wordBreak: 'break-word' }}
                >
                  {data.numerocuenta || 'N/A'}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div style={{ paddingTop: 20 }}>
          <CustomProgress size={20} />
        </div>
      )}
    </Fragment>
  );
};

export default AditionalInformation;
