import React, { useEffect, useState } from 'react';

import { Grow, makeStyles } from '@material-ui/core';

import { CardLoadInformation, ListLoadInformation } from './';

import { InformationDialog } from './modalsBulkload';

const useStyles = makeStyles((theme) => ({
  btnContinue: {
    width: '89%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      width: '92%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  buttonSuccess: {
    height: '54px',
    maxWidth: '18%',
    width: '39%',
    minWidth: '293px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  titleBulkLoadComplete: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 30,
    textAlign: 'center',
    display: 'inline-block',
    marginTop: 30,
    color: '#FFFFFF',
    width: '100%',
  },
}));

export const LoadResultPayroll = ({
  activeStep,
  setCompleted,
  setActiveStep,
}) => {
  const classes = useStyles();
  const [openModalIformation, setOpenModalIformation] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setChecked(true);
  }, []);

  const handleContinueBulkLoad = () => {
    setOpenModalIformation(true);
  };

  return (
    <Grow in={checked}>
      <div style={{ marginBottom: '20px' }}>
        <CardLoadInformation activeStep={activeStep} />
        <ListLoadInformation />
        <div className={classes.btnContinue}>
          <button
            className={classes.buttonSuccess}
            type="submit"
            onClick={handleContinueBulkLoad}
          >
            Continuar
          </button>
        </div>
        <InformationDialog
          openModalIformation={openModalIformation}
          setActiveStep={setActiveStep}
          setCompleted={setCompleted}
        />
      </div>
    </Grow>
  );
};
