import { types } from '../actions/types';

const initialState = {
  payrollData: {
    soporteid: null,
    consecutivo: null,
    prefijo: null,
    primernombre: '',
    otronombre: null,
    primerapellido: '',
    segundoapellido: '',
    tipodocumento: '',
    documento: '',
    telefono: '',
    direccion: '',
    ciudadresidencia: '',
    departamentoresidencia: '',
    cargo: '',
    tipocontrato: '',
    valornomina: 0,
    correoElectronico: '',
    salario: 0,
    periodo: null,
    fechaPago: null,
    ingresos: [],
    deducciones: [],
    observaciones: '',
  },
  loading: false,
  loadingRegisterAdjustment: false,
  typeLicense: null,
  licenseBase: null,
};

export const payrollAdjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING_GET_PAYROLL:
      return {
        ...state,
        loading: action.payload,
      };
    case types.LOAD_PAYROLL_ADJUSTMENT:
      return {
        ...state,
        payrollData: { ...action.payload },
      };
    case types.SET_OBSERVATIONS_PAYROLL:
      return {
        ...state,
        payrollData: {
          ...state.payrollData,
          observacion: action.payload,
        },
      };
    case types.CLEAN_PAYROLL_ADJUSTMENT:
      return {
        ...initialState,
      };
    case types.LOADING_ADD_PAYROLL_ADJUSTMENT:
      return {
        ...state,
        loadingRegisterAdjustment: action.payload,
      };
    case types.SET_TYPE_LICENSE:
      return {
        ...state,
        typeLicense: action.payload,
      };
    case types.SET_LICENSE_BASE:
      return {
        ...state,
        licenseBase: action.payload,
      };
    default:
      return { ...state };
  }
};
