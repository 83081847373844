import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { columnsFile } from '../validation/columnsFile';
import { rules } from '../validation/rules';
import ValidateField from '../validation/ValidateField';
import {
  convertBase64ToExcel,
  dateFormat,
  getSplitValueString,
  groupByLine,
  removeUnwantedCharacters,
  removeUnwantedCharactersDate,
} from '../helpers/utilitiesBulkload';
import { enterpriseIdHeader } from '../../../../actions/authActions';
import {
  getBulkLoadEmployeeAction,
  getTemplateBulkUploadActionTest,
} from '../../../../actions/bulkLoadAction';
import ResponseModal from '../../../../components/modal/responseModal.component';
import LoadingAnimation from '../../../../images/animations/cargando1.flr';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FlareComponent from 'flare-react';
import * as XLSX from 'xlsx/xlsx.mjs';

const useStyles = makeStyles((theme) => ({
  btnEnviar: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  buttonUploadBulk: {
    position: 'relative',
    width: '100%',
    height: '54px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    maxWidth: '81%',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    padding: '1px',
  },
  btnInfografia: {
    width: '100%',
  },
  buttonSuccess: {
    height: '54px',
    width: '100%',
    maxWidth: '81%',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
      height: '464px',
      width: '99%',
      borderRadius: '5px',
      maxWidth: '974px',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '340px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '390px',
      },
    },
  },
  dialogAnimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  subTitleValue: {
    color: theme.palette.thirdColor,
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
  },
}));

const ButtonSteper = ({ activeStep, setActiveStep }) => {
  const [initValidation, setInitValidation] = useState('');
  const [contentDialog, setContentDialog] = useState(false);
  const [openModalIformation, setOpenModalInformation] = useState(false);
  const [modalResponse, setModalResponse] = useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  let fileSize;

  const fileHeader = [
    columnsFile.tipoDocumentoColumn.name,
    columnsFile.numeroDocumentoColumn.name,
    columnsFile.primerApellidoColumn.name,
    columnsFile.segundoApellidoColumn.name,
    columnsFile.primerNombreColumn.name,
    columnsFile.otrosNombresColumn.name,
    columnsFile.correoElectronicoColumn.name,
    columnsFile.telefonoColumn.name,
    columnsFile.municipioColumn.name,
    columnsFile.direccionColumn.name,
    columnsFile.tipoTrabajadorColumn.name,
    columnsFile.subTipoTrabajadorColumn.name,
    columnsFile.tipoContratoColumn.name,
    columnsFile.numeroContratoColumn.name,
    columnsFile.fechaIngresoColumn.name,
    columnsFile.fechaFinalizacionColumn.name,
    columnsFile.cargoColumn.name,
    columnsFile.salarioColumn.name,
    columnsFile.periodoPagoColumn.name,
    columnsFile.salarioIntegralColumn.name,
    columnsFile.trabajoAltoRiesgoColumn.name,
    columnsFile.sucursalColumn.name,
    columnsFile.municipioLugarTrabajoColumn.name,
    columnsFile.direccionLugarTrabajoColumn.name,
    columnsFile.medioPagoColumn.name,
    columnsFile.descripcionMedioPagoColumn.name,
    columnsFile.entidadFinancieraColumn.name,
    columnsFile.tipoCuentaColumn.name,
    columnsFile.numeroCuentaColumn.name,
  ];

  const inputValidations = [
    rules.tipoDocumentoColumn,
    rules.numeroDocumentoColumn,
    rules.primerApellidoColumn,
    rules.segundoApellidoColumn,
    rules.primerNombreColumn,
    rules.otrosNombresColumn,
    rules.correoElectronicoColumn,
    rules.telefonoColumn,
    rules.municipioColumn,
    rules.direccionColumn,
    rules.tipoTrabajadorColumn,
    rules.subTipoTrabajadorColumn,
    rules.tipoContratoColumn,
    rules.numeroContratoColumn,
    rules.fechaIngresoColumn,
    rules.fechaFinalizacionColumn,
    rules.cargoColumn,
    rules.salarioColumn,
    rules.periodoPagoColumn,
    rules.salarioIntegralColumn,
    rules.trabajoAltoRiesgoColumn,
    rules.sucursalColumn,
    rules.municipioLugarTrabajoColumn,
    rules.direccionLugarTrabajoColumn,
    rules.medioPagoColumn,
    rules.descripcionMedioPagoColumn,
    rules.entidadFinancieraColumn,
    rules.tipoCuentaColumn,
    rules.numeroCuentaColumn,
  ];

  //Validaciones
  /**
   * Validaciones pre-cargue y cargue de columnas y validaciones
   * de tipo de datos del excel
   * @param {*} e
   */
  const initialValidations = (e) => {
    setOpenModalInformation(true);
    e.preventDefault();
    if (!!e.target) {
      const file = e.target.files[0];
      //Extensiones admitidas
      const admitedExtentions = ['xls', 'xlsx', 'xlsm', 'csv'];
      //Tamaño Màximo permitido del archivo
      const sizeMax = 500;

      //Validación de formatos
      if (!validateExtention(file, admitedExtentions)) {
        showValidationErrors(
          'Sólo se aceptan los siguientes formatos ' + admitedExtentions,
        );
        //validacion de Peso Màximo
        return setOpenModalInformation(false);
      } else if (!validateSize(file, sizeMax)) {
        showValidationErrors(
          'Recuerda que el tamaño del archivo no puede superar los ' +
            sizeMax +
            ' Kylobytes',
        );
        return setOpenModalInformation(false);
      }

      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, {
          header: 1,
          blankrows: false,
          RS: '#COlNO2023FR\n',
        });

        setInitValidation(data);
      };

      reader.readAsBinaryString(file);
      e.target.value = '';
    }
  };

  /**
   * Validación de la extensión
   *
   * @param {*} file
   * @param {*} admitedExtentions
   */
  function validateExtention(file, admitedExtentions) {
    var ext = file.name.split('.');
    var extention = ext[ext.length - 1];
    return admitedExtentions.includes(extention);
  }

  /**
   * Validación del tamaño del archivo
   *
   * @param {*} file
   * @param {*} sizeMax
   */
  function validateSize(file, sizeMax) {
    fileSize = file.size / 1024; // in KB
    return fileSize <= sizeMax;
  }

  /**
   * Validación  del tamaño y nombre de columnas del archivo
   *
   * @param {*} header
   * @param {*} fileHeader
   */
  function validateHeader(header, fileHeader) {
    if (header.length === fileHeader.length) {
      for (let index = 0; index < header.length; index++) {
        if (header[index] !== fileHeader[index]) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  //Modales
  const handleResponseModal = () => {
    setModalResponse({
      ...modalResponse,
      open: false,
    });
  };

  const showValidationErrors = (body) => {
    setModalResponse({
      type: 'warning',
      title: 'Upss...!!!',
      subtitle: 'Ocurrió algo inesperado',
      body: <div>{body}</div>,
      modalImage: 'warning',
      open: true,
      closeElement: 'Saltar',
      onCloseElement: 1,
    });
  };

  const readLine = (line) => {
    if (line.slice(-1) === ',') {
      return line.slice(0, -1);
    }
    return line;
  };

  /**
   *  process CSV data, Leer información de excel
   * @param {archivo en String} dataString
   * @param {cabecera} fileHeader
   * @param {Validaciones} inputValidations
   */
  const processData = (dataString) => {
    const dataStringLinesTmp = dataString.split(/#COlNO2023FR\n/);
    const dataStringLines = [];

    for (let i = 0; i < dataStringLinesTmp.length; i++) {
      if (
        dataStringLinesTmp[i] !== ',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,' &&
        dataStringLinesTmp[i] !== ''
      ) {
        dataStringLines.push(dataStringLinesTmp[i]);
      }
    }

    const headersTmp = readLine(dataStringLines[0]);

    const headers = headersTmp
      .replace(',,', '')
      .split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    // Se valida que la cabecera tenga las columnas definidas en la plantilla
    if (!validateHeader(readLine(headers), fileHeader)) {
      showValidationErrors(
        'El archivo que estas cargando no corresponde a la plantilla, ColFactura Nomina no puede interpretarlo.',
      );
      return setOpenModalInformation(false);
    }

    const list = [];
    const results = [];
    const validation = new ValidateField(inputValidations);

    if (dataStringLines.length === 1) {
      showValidationErrors('El archivo debe tener por lo menos un registro.');
      setOpenModalInformation(false);
      return;
    }

    const employeeCount = dataStringLines.length - 1;

    setContentDialog(employeeCount);

    for (let i = 1; i < dataStringLines.length; i++) {
      const rowTmp = readLine(dataStringLines[i]).split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );

      if (rowTmp[28] === undefined) {
        rowTmp[28] = '';
      }

      //Obtiene información de fila, igualando con encabezados
      const row = rowTmp.slice(0, headers.length);

      if (headers && row.length === headers.length) {
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }

          d = readLine(d);
          d = removeUnwantedCharacters(d, headers[j]);

          if (headers[j]) {
            obj[headers[j]] = d;
          }

          if (
            headers[j] === 'fecha de ingreso' ||
            headers[j] === 'Fecha de Finalización contrato '
          ) {
            d = removeUnwantedCharactersDate(d, headers[j]);
          }

          const result = validation.validate(d, j, i, headers[j]);

          if (result) {
            results.push(result);
          }
        }

        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    console.log('ListadoEmpleadosExcel ----> ', list);

    let errorsByLine = groupByLine(results);

    const data = (error) => {
      let errorList = [];
      error.forEach((item) => {
        errorList.push(buildData(item));
      });
      return errorList;
    };

    const buildData = (error) => {
      return [
        { value: error.line + 1, style: { font: { sz: '14', bold: true } } },
        { value: error.key, style: { font: { sz: '14' } } },
        { value: error.message, style: { sz: '18', inline: true } },
      ];
    };

    let multiDataSet = [
      {
        columns: [
          { title: 'Fila', width: { wpx: 40 } },
          { title: 'Columna', width: { wpx: 400 } },
          { title: 'Observaciones', width: { wpx: 600 } },
        ],
        data: data(results),
      },
    ];

    const employeeObject = list.map((i, index) => {
      // Recorre list y añade los errores a cada empleado
      const errors = errorsByLine[index + 1];

      if (errors) {
        i.errors = errors;
      }

      //Se genera el objeto para enviar al endpoint
      const objectData = {
        Empresaid: enterpriseIdHeader(),
        Diantipodocumentoidentidadid: getSplitValueString(
          i[`Tipo de Documento`],
          '-',
        ),
        Documento: i[`Numero de documento`],
        Primerapellido: i[`Primer Apellido`].trim(),
        Segundoapellido: i[`Segundo Apellido`].trim(),
        Primernombre: i[`Primer Nombre`].trim(),
        Otronombre: i[`Otros Nombres`].trim(),
        Correoelectronico: i[`Correo Electronico`].trim(),
        Telefono: i[`Telefono`],
        Departamento: i[`Municipio`].substring(0, 2),
        Municipio: i[`Municipio`].substring(2, 5),
        Direccion: i[`Dirección`].trim(),
        Tipotrabajador: getSplitValueString(i[`Tipo Trabajador`], '-'),
        Tipocontratoid: Number(getSplitValueString(i[`Tipo Contrato`], '-')),
        Subtipotrabajador: getSplitValueString(i[`SubTipo Trabajador`], '-'),
        Numerocontrato: i[`Numero de contrato`],
        Fechaingreso:
          i[`fecha de ingreso`].length === 0
            ? null
            : dateFormat(i[`fecha de ingreso`]),
        Fechafinalizacioncontrato:
          i[`Fecha de Finalización contrato`].length === 0
            ? null
            : dateFormat(i[`Fecha de Finalización contrato`]),
        cargo: i[`Cargo`].trim(),
        Salario: parseFloat(i[`Salario`]),
        Dianperiodonominaid: Number(
          getSplitValueString(i[`Periodo de pago`], '-'),
        ),
        Salariointegral: i[`Salario Integral`] === 'SI' ? true : false,
        Altoriesgo: i[`trabajo de alto riesgo`] === 'SI' ? true : false,
        Prefijosucursal: i[`Prefijo de la Sucursal`],
        Trabajodepartamento: getSplitValueString(
          i[`Municipio lugar de trabajo`],
          '-',
        ).substring(0, 2),
        Trabajomunicipio: getSplitValueString(
          i[`Municipio lugar de trabajo`],
          '-',
        ).substring(2, 5),
        Trabajodireccion: i[`Dirección de trabajo`].trim(),
        Dianmediopagoid: getSplitValueString(i[`Medio de pago`], '-'),
        Descripcion: i[`Descripcion`].trim(),
        Entidadbancariaid:
          getSplitValueString(i[`entidad financiera`], '-').length === 0
            ? null
            : Number(getSplitValueString(i[`entidad financiera`], '-')),
        Tipocuentaid:
          getSplitValueString(i[`entidad financiera`], '-').length === 0
            ? null
            : Number(getSplitValueString(i[`Tipo de cuenta`], '-')),
        Numerocuenta: i[`Numero de cuenta`],
        Error: i.errors ? i.errors : null,
      };
      return objectData;
    });

    const dataEmployeeWithoutError = employeeObject.filter(
      (e) => e.Error === null,
    );
    const dataEmployeeError = employeeObject.filter((e) => e.Error !== null);

    dispatch(
      getBulkLoadEmployeeAction(
        dataEmployeeWithoutError,
        employeeObject,
        setActiveStep,
        activeStep,
        dataEmployeeError,
        multiDataSet,
        setOpenModalInformation,
      ),
    );
  };

  useEffect(
    () => {
      if (initValidation.length > 0) {
        processData(initValidation);
        setInitValidation('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initValidation],
  );

  //Dialog de carga masiva de clientes
  const dialogUploadinRegister = () => {
    return (
      <Dialog
        open={openModalIformation}
        onClose={false}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          <span className={classes.title}>Cargue masivo de empleados</span>
        </DialogTitle>
        <DialogContent className={classes.dialogAnimation}>
          <DialogContentText>
            <div className={classes.loading}>
              {
                <FlareComponent
                  width={104}
                  height={104}
                  animationName="music_walk"
                  file={LoadingAnimation}
                />
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="backgroundContent">
          <Grid container>
            <Grid item lg={12} xs={12}>
              <div className={classes.subTitle}>
                Cargando la información de &nbsp;
                <span className={classes.subTitleValue}>{contentDialog}</span>
                &nbsp; empleados, espera un momento por favor.
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * Función que descarga la plantilla de carga masiva
   */
  const handleTemplate = () => {
    dispatch(getTemplateBulkUploadActionTest(convertBase64));
  };

  /**
   * Función que convierte el base 64 en un archivo xlsm
   * @param {*} data
   */
  const convertBase64 = (data) => {
    convertBase64ToExcel(
      data,
      `Cargue_Masivo_Empleados_${new Date().toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}.xlsm`,
    );
  };

  return (
    <>
      <Grid container>
        <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
        <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
          <div className={classes.btnInfografia}>
            <button
              className={classes.buttonSuccess}
              type="submit"
              onClick={handleTemplate}
            >
              Descargar Plantilla
            </button>
          </div>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2} xl={2} />
        <br />
        <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
          <center>
            <button className={classes.buttonUploadBulk}>
              <p id="texto">Cargar Plantilla Masiva</p>
              <input
                type="file"
                className={classes.btnEnviar}
                accept=".csv,.xlsx,.xls,.xlsm"
                onChange={initialValidations}
              />
            </button>
          </center>
        </Grid>

        <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
      </Grid>
      <br></br>
      {dialogUploadinRegister()}
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      ></ResponseModal>
    </>
  );
};

export default ButtonSteper;
