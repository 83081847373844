import { combineReducers } from 'redux';

import generalReducer from './generalReducer';
import userReducer from './userReducer';
import officeReducer from './officeReducer';
import branchOfficeReducer from './branchOfficeReducer';
import templateReducer from './templateReducer';
import basicDataReducer from './basicDataReducer';
import articleReducer from './articleReducer';
import categoryReducer from './categoryReducer';
import configReducer from './configReducer';
import invoiceReducer from './invoiceReducer';
import addressReducer from './addressReducer';
import contactReducer from './contactReducer';
import clientReducer from './clientReducer';
import chargeReducer from './chargeReducer';
import valuesInvoiceReducer from './valuesInvoiceReducer';
import { dashboardReducer } from './dashboardReducer';
import menuReducer from './menuReducer';
import employeeReducer from './employeeReducer';
import payrollReducer from './payrollReducer';
import { integrationReducer } from './integrationReducer';
import { payrollAdjustmentReducer } from './payrollAdjustmentReducer';
import { expensePayrollReducer } from './expensePayrollReducer';
import { incomePayrollReducer } from './incomePayrollReducer';
import { payrollHistoryReducer } from './payrollHistoryReducer';
import { payrollReportReducer } from './payrollReportReducer';
import { transmissionReducer } from './transmissionReducer';
import { payrollReportedReducer } from './payrollReportedReducer';
import { bulkLoadReducer } from './bulkLoadReducer';
import { payrollBulkLoadReducer } from './payrollBulkLoadReducer.';

export default combineReducers({
  generalReducer,
  userReducer,
  branchOfficeReducer,
  templateReducer,
  basicDataReducer,
  officeReducer,
  configReducer,
  articleReducer,
  categoryReducer,
  invoiceReducer,
  addressReducer,
  contactReducer,
  clientReducer,
  chargeReducer,
  valuesInvoiceReducer,
  dashboardReducer,
  menuReducer,
  employeeReducer,
  payrollReducer,
  integrationReducer,
  payrollAdjustmentReducer,
  incomePayrollReducer,
  expensePayrollReducer,
  payrollHistoryReducer,
  payrollReportReducer,
  transmissionReducer,
  payrollReportedReducer,
  bulkLoadReducer,
  payrollBulkLoadReducer,
});
