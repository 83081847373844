import {
  GET_ALL_PAYROLL_REPORTED,
  GET_PAYROLL_REPORTED_DETAIL,
  FILL_ALL_PAYROLL_EMPLOYEE,
  FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR,
  FILL_ALL_PAYROLL_SUPPORTS,
  FILL_ALL_PAYROLL_EDITED,
  FILL_PAYROLL_DETAIL_EMPLOYEE,
  UPDATE_CONFIRM_PAYROLL,
  UPDATE_PAYROLL_FAVORITE,
  UPDATE_PAYROLL_EMPLOYEE_SELECTED,
  UPDATE_ALL_PAYROLL_EMPLOYEE,
  FILL_DETAIL_PAYROLL,
  FILL_DETAIL_SUPPORTS,
  FILL_DETAIL_EDITED,
  ADD_NEW_INCOME_PAYROLL,
  ADD_NEW_EXPENSE_PAYROLL,
  UPDATE_INCOME_PAYROLL,
  UPDATE_EXPENSE_PAYROLL,
  DELETE_INCOME_PAYROLL,
  DELETE_EXPENSE_PAYROLL,
  SET_NAVIGATION,
  SET_FILES_PREVIEW,
  RESET_PAYROLL_FORM,
  RESET_PAYROLL_LIST,
  FILL_PAYROLL_SUPPORTS_EMPLOYEE,
  SET_TRANSMISION_PROCESS,
  SET_TRANSMISION_STATE,
  SEND_SUPPORT_PAYROLL,
  LOADING_PAYROLL_REPORTED,
  LOADING_PAYROLL_SUPPORTS,
  FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID,
  RESPONSE_TRANSMISION_STATE,
  LOADING_RETIRE_EMPLOYEE_PAYROLL,
  UPDATE_EMPLOYEE_RETIREMENT,
  UPDATE_STATUS_TRANSMITION_SUPPORT,
} from '../actions/types';
import isEmpty from '../utils/isEmpty';

const initialState = {
  navigation: {
    contentTab: 1,
    tabSelected: 0,
  },
  payrollListEmployees: { isAnySelected: false, data: [] },
  payrollDetailEmployee: {
    confirmado: false,
  },
  listReported: [], //Debe ser un arreglo
  listSupports: [], //Debe ser un arreglo
  listEdited: null,
  detailReported: null,
  detailSupport: null,
  detailEdited: null,
  payrollDetail: {
    dateReported: null,
    lastDatePayment: null,
    data: {},
  },
  transmisionState: null,
  filesPreview: null,
  listSupportsEmployee: [],
  responseSendSupportPayroll: null,
  loadingReportedList: false,
  loadingSupportsList: false,
  loadingRetireEmployee: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // tab reportados
    case GET_ALL_PAYROLL_REPORTED:
      return {
        ...state,
        listReported: action.payload,
      };
    case LOADING_PAYROLL_REPORTED:
      return {
        ...state,
        loadingReportedList: action.payload,
      };
    case GET_PAYROLL_REPORTED_DETAIL:
      return {
        ...state,
        detailReported: action.payload,
      };
    // reportar nomina
    case FILL_DETAIL_PAYROLL:
      return {
        ...state,
        payrollDetail: {
          ...state.payrollDetail,
          ...action.payload,
        },
      };
    case FILL_ALL_PAYROLL_EMPLOYEE:
      return {
        ...state,
        payrollListEmployees: action.payload,
      };
    case FILL_ALL_PAYROLL_EMPLOYEE_NOMINAID:
      const listAuxEmployee = state.payrollListEmployees.data.map((data) => {
        if (data.id === action.payload.id) {
          data = {
            ...data,
            detallenominaid: action.payload.detallenominaid,
          };
        }
        return data;
      });
      return {
        ...state,
        payrollListEmployees: {
          ...state.payrollListEmployees,
          data: listAuxEmployee,
        },
      };
    case FILL_ALL_PAYROLL_EMPLOYEE_SIGNALR:
      const listAux = state.payrollListEmployees.data.map((payroll) => {
        if (payroll.id === action.payload.data?.empleadoid) {
          payroll = {
            ...payroll,
            selected: false,
            trasmitido: action.payload.data?.transmitido,
            fechatrasmision: action.payload.data?.fechaTransmision,
            fechavalidaciondian: action.payload.data?.fechaValidacion,
            codigorespuesta: action.payload.data?.codigorespuesta,
            detallerespuesta: action.payload.data?.detallerespuesta,
            estadonominaid: action.payload.data?.estadonominaid,
            error: action.payload.data?.error,
            pendiente: action.payload.data?.pendiente,
          };
        }
        return payroll;
      });
      if (
        state.payrollDetailEmployee.data.id === action.payload.data?.empleadoid
      ) {
        return {
          ...state,
          payrollListEmployees: {
            ...state.payrollListEmployees,
            data: listAux,
          },
          payrollDetail: {
            ...state.payrollDetail,
            data: {
              ...state.payrollDetail.data,
              empleadostrasmitidos: action.payload.data?.esTransmitido
                ? action.payload.data?.transmitidos
                : state.payrollDetail.data.empleadostrasmitidos,
            },
          },
          payrollDetailEmployee: {
            ...state.payrollDetailEmployee,
            data: {
              ...state.payrollDetailEmployee.data,
              estadonominaid: action.payload.data?.estadonominaid,
            },
          },
        };
      } else {
        return {
          ...state,
          payrollListEmployees: {
            ...state.payrollListEmployees,
            data: listAux,
          },
          payrollDetail: {
            ...state.payrollDetail,
            data: {
              ...state.payrollDetail.data,
              empleadostrasmitidos: action.payload.data?.esTransmitido
                ? action.payload.data?.transmitidos
                : state.payrollDetail.data.empleadostrasmitidos,
            },
          },
        };
      }

    case FILL_PAYROLL_DETAIL_EMPLOYEE:
      return {
        ...state,
        payrollDetailEmployee: {
          ...state.payrollDetailEmployee,
          ...action.payload,
        },
      };
    // tab soportes nomina
    case FILL_ALL_PAYROLL_SUPPORTS:
      return {
        ...state,
        listSupports: [...action.payload],
      };
    case LOADING_PAYROLL_SUPPORTS:
      return {
        ...state,
        loadingSupportsList: action.payload,
      };
    case FILL_DETAIL_SUPPORTS:
      return {
        ...state,
        detailSupport: action.payload,
      };
    case RESET_PAYROLL_LIST:
      return {
        ...state,
        listSupports: [],
        detailSupport: null,
        loadingSupportsDetail: [],
      };
    case FILL_PAYROLL_SUPPORTS_EMPLOYEE:
      return {
        ...state,
        listSupportsEmployee: action.payload,
      };
    case FILL_ALL_PAYROLL_EDITED:
      return {
        ...state,
        listEdited: action.payload,
      };
    case FILL_DETAIL_EDITED:
      return {
        ...state,
        detailEdited: action.payload,
      };
    case UPDATE_PAYROLL_FAVORITE:
      if (
        state.listReported &&
        action.payload.idPayroll &&
        state.detailReported.id
      ) {
        const array = state.listReported.map((payroll) => {
          if (payroll.id === action.payload.idPayroll) {
            payroll = {
              ...payroll,
              favorito: action.payload.isFavorite,
            };
          }
          return payroll;
        });

        if (state.detailReported.id === action.payload.idPayroll) {
          return {
            ...state,
            listReported: array,
            detailReported: {
              ...state.detailReported,
              favorito: action.payload.isFavorite,
            },
          };
        }
        return {
          ...state,
          listReported: { data: array },
        };
      }
      return {
        ...state,
      };
    case UPDATE_ALL_PAYROLL_EMPLOYEE:
      if (state.payrollListEmployees.data) {
        let isAnySelected = false;
        const array = state.payrollListEmployees.data.map((employee) => {
          if (action.payload.value) {
            if ('confirmado' in employee && employee.confirmado) {
              isAnySelected = true;
              employee = {
                ...employee,
                selected: true,
              };
            } else {
              employee = {
                ...employee,
                selected: false,
              };
            }
          } else {
            employee = {
              ...employee,
              selected: false,
            };
          }
          return employee;
        });
        return {
          ...state,
          payrollListEmployees: { isAnySelected, data: array },
        };
      }
      return {
        ...state,
      };
    case UPDATE_PAYROLL_EMPLOYEE_SELECTED:
      if (state.payrollListEmployees.data && action.payload.idEmployee) {
        let isAnySelected = false;
        const array = state.payrollListEmployees.data.map((employee) => {
          if (employee.id === action.payload.idEmployee) {
            employee = {
              ...employee,
              selected: !action.payload.value,
            };
          }
          if (employee.selected) {
            isAnySelected = true;
          }
          return employee;
        });

        return {
          ...state,
          payrollListEmployees: { isAnySelected, data: array },
        };
      }
      return {
        ...state,
      };
    // Actualiza el estado confirmado para el reporte de nomina de empleado
    case UPDATE_CONFIRM_PAYROLL:
      let revisedEmployees = 0;

      if (!state.payrollDetailEmployee.confirmado) {
        revisedEmployees =
          Number(state.payrollDetail.data.empleadosrevisados) + 1;
      } else {
        revisedEmployees =
          Number(state.payrollDetail.data.empleadosrevisados) - 1;
      }

      if (
        state.payrollListEmployees.data &&
        action.payload.idEmployeePayroll &&
        state.payrollDetailEmployee.data.id
      ) {
        let isAnySelected = false;

        const array = state.payrollListEmployees.data.map((payroll) => {
          if (payroll.id === action.payload.idEmployeePayroll) {
            if ('confirmado' in payroll) {
              payroll = {
                ...payroll,
                confirmado: !payroll.confirmado,
                selected: false,
              };
            } else {
              payroll = {
                ...payroll,
                confirmado: true,
              };
            }
          }
          if (payroll.selected) {
            isAnySelected = true;
          }
          return payroll;
        });

        return {
          ...state,
          payrollListEmployees: { isAnySelected, data: array },
          payrollDetail: {
            ...state.payrollDetail,
            data: {
              ...state.payrollDetail.data,
              valorneto: action.payload.valor,
              empleadosrevisados: revisedEmployees,
            },
          },
          payrollDetailEmployee: {
            ...state.payrollDetailEmployee,
            confirmado: !state.payrollDetailEmployee.confirmado,
          },
        };
      }
      return {
        ...state,
      };
    case ADD_NEW_INCOME_PAYROLL:
      if (state.payrollDetailEmployee.data) {
        let isAnySelected = false;
        let array = state.payrollDetailEmployee.data.ingresos || [];

        const arrayListEmployee = state.payrollListEmployees.data.map(
          (payroll) => {
            if (payroll.selected) {
              isAnySelected = true;
            }

            if (payroll.id === state.payrollDetailEmployee.data.id) {
              return {
                ...payroll,
                confirmado: false,
                noconfirmado: true,
              };
            } else {
              return payroll;
            }
          },
        );

        array.push(action.payload.objPayroll);

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayListEmployee,
          },
          payrollDetailEmployee: {
            confirmado: false,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valorneto,
              ingresos: array,
            },
          },
        };
      }
      return {
        ...state,
      };
    case UPDATE_INCOME_PAYROLL:
      if (state.payrollDetailEmployee.data) {
        let isAnySelected = false;
        const arrayListEmployees = state.payrollListEmployees.data.map(
          (employee) => {
            if (employee.id === action.payload.idEmployee) {
              employee = {
                ...employee,
                selected: !action.payload.value,
              };
            }
            if (employee.selected) {
              isAnySelected = true;
            }
            return employee;
          },
        );

        let array = state.payrollDetailEmployee.data.ingresos || [];

        const filteredArray = array.filter(
          (d) => d.id !== action.payload.objPayroll.id,
        );

        filteredArray.push(action.payload.objPayroll);

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayListEmployees,
          },
          payrollDetailEmployee: {
            confirmado: false,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valorneto,
              ingresos: filteredArray,
            },
          },
        };
      }
      return {
        ...state,
      };
    case UPDATE_EXPENSE_PAYROLL:
      if (state.payrollDetailEmployee.data) {
        let isAnySelected = false;
        const arrayListEmployees = state.payrollListEmployees.data.map(
          (employee) => {
            if (employee.id === action.payload.idEmployee) {
              employee = {
                ...employee,
                selected: !action.payload.value,
              };
            }
            if (employee.selected) {
              isAnySelected = true;
            }
            return employee;
          },
        );

        let array = state.payrollDetailEmployee.data.deducciones || [];

        const filteredArray = array.filter(
          (d) => d.id !== action.payload.objPayroll.id,
        );

        filteredArray.push(action.payload.objPayroll);

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayListEmployees,
          },
          payrollDetailEmployee: {
            confirmado: false,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valorneto,
              deducciones: filteredArray,
            },
          },
        };
      }
      return {
        ...state,
      };
    case ADD_NEW_EXPENSE_PAYROLL:
      if (state.payrollDetailEmployee.data) {
        let isAnySelected = false;
        let array = state.payrollDetailEmployee.data.deducciones || [];

        const arrayListEmployee = state.payrollListEmployees.data.map(
          (payroll) => {
            if (payroll.selected) {
              isAnySelected = true;
            }

            if (payroll.id === state.payrollDetailEmployee.data.id) {
              return {
                ...payroll,
                confirmado: false,
                noconfirmado: true,
              };
            } else {
              return payroll;
            }
          },
        );

        array.push(action.payload.objPayroll);

        // const updatedValue =
        //   state.payrollDetailEmployee.data.valornomina - action.payload.valor;

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayListEmployee,
          },
          payrollDetailEmployee: {
            confirmado: false,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valorneto,
              deducciones: array,
            },
          },
        };
      }
      return {
        ...state,
      };
    case DELETE_INCOME_PAYROLL:
      if (state.payrollDetailEmployee.data.ingresos) {
        let isAnySelected = false;
        let arrayIngresos = state.payrollDetailEmployee.data.ingresos || [];
        let arrayDeducciones =
          state.payrollDetailEmployee.data.deducciones || [];
        let arrayEmployees = state.payrollListEmployees.data;

        const arrayFiltered = arrayIngresos.filter(
          (d) => d.id !== action.payload.idmovimiento,
        );

        const arrayEmployeesFiltered = arrayEmployees.map((data) => {
          if (data.selected) {
            isAnySelected = true;
          }
          if (data.id === state.payrollDetailEmployee.data.id) {
            if (!!arrayIngresos.length && !!arrayDeducciones.length) {
              return {
                ...data,
                noconfirmado: true,
                confirmado: false,
              };
            } else if (arrayIngresos.length || arrayDeducciones.length) {
              return {
                ...data,
                noconfirmado: true,
                confirmado: false,
              };
            } else {
              return {
                ...data,
                noconfirmado: true,
                confirmado: false,
              };
            }
          } else {
            return { ...data };
          }
        });

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayEmployeesFiltered,
          },
          payrollDetailEmployee: {
            confirmado: state.payrollDetailEmployee.confirmado
              ? false
              : state.payrollDetailEmployee.confirmado,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valor || 0,
              ingresos: arrayFiltered,
            },
          },
        };
      }
      return {
        ...state,
      };
    case DELETE_EXPENSE_PAYROLL:
      if (state.payrollDetailEmployee.data.deducciones) {
        let isAnySelected = false;
        let arrayIngresos = state.payrollDetailEmployee.data.deducciones || [];
        let arrayDeducciones =
          state.payrollDetailEmployee.data.deducciones || [];
        let arrayEmployees = state.payrollListEmployees.data;

        const arrayFiltered = arrayDeducciones.filter(
          (d) => d.id !== action.payload.idmovimiento,
        );

        const arrayEmployeesFiltered = arrayEmployees.map((data) => {
          if (data.selected) {
            isAnySelected = true;
          }
          if (data.id === state.payrollDetailEmployee.data.id) {
            if (!!arrayIngresos.length && !!arrayDeducciones.length) {
              return {
                ...data,
                confirmado: false,
              };
            } else {
              return {
                ...data,
                noconfirmado: false,
                confirmado: false,
              };
            }
          } else {
            return { ...data };
          }
        });

        return {
          ...state,
          payrollListEmployees: {
            isAnySelected,
            data: arrayEmployeesFiltered,
          },
          payrollDetailEmployee: {
            confirmado: state.payrollDetailEmployee.confirmado
              ? false
              : state.payrollDetailEmployee.confirmado,
            data: {
              ...state.payrollDetailEmployee.data,
              valornomina: action.payload.valor || 0,
              deducciones: arrayFiltered,
            },
          },
        };
      }
      return {
        ...state,
      };
    case SET_NAVIGATION:
      if (action.payload) {
        return {
          ...state,
          navigation: action.payload,
        };
      }
      return {
        ...state,
      };
    case SET_FILES_PREVIEW:
      return {
        ...state,
        filesPreview: action.payload,
      };
    case RESET_PAYROLL_FORM:
      return {
        ...state,
        payrollDetail: {
          dateReported: null,
          lastDatePayment: null,
        },
        payrollListEmployees: [],
        payrollDetailEmployee: {
          confirmado: false,
          data: null,
        },
      };
    // Transmision
    case SET_TRANSMISION_PROCESS:
      if (state.payrollListEmployees.data) {
        let array = action.payload ?? [];

        return {
          ...state,
          payrollDetailEmployee: {
            ...state.payrollDetailEmployee,
            data: {
              ...state.payrollDetailEmployee.data,
              estadonominaid: 1,
            },
          },
          payrollListEmployees: { ...state.payrollListEmployees, data: array },
        };
      }
      return {
        ...state,
      };
    case SET_TRANSMISION_STATE: {
      return {
        ...state,
        transmisionState: action.payload,
      };
    }
    case SEND_SUPPORT_PAYROLL:
      return {
        ...state,
        responseSendSupportPayroll: action.payload,
      };
    case RESPONSE_TRANSMISION_STATE:
      return {
        ...state,
        responsetransmisionPayroll: action.payload,
      };
    case LOADING_RETIRE_EMPLOYEE_PAYROLL:
      return {
        ...state,
        loadingRetireEmployee: action.payload,
      };
    case UPDATE_EMPLOYEE_RETIREMENT: {
      const id = state.payrollDetailEmployee?.data?.id ?? 0;
      const newList = state?.payrollListEmployees?.data?.map((item) =>
        item.id === id
          ? {
              ...item,
              fecharetiro: action.payload.fecharetiro,
              retirado: action.payload.fecharetiro === null ? false : true,
            }
          : item,
      );
      return {
        ...state,
        payrollDetailEmployee: {
          ...state.payrollDetailEmployee,
          data: {
            ...state.payrollDetailEmployee.data,
            fecharetiro: action.payload.fecharetiro,
            causalretiroid: action.payload.causalretiroid,
            causalderetiro: action.payload.causalderetiro,
            retirado: isEmpty(action.payload.fecharetiro) ? false : true,
          },
        },
        payrollListEmployees: {
          ...state.payrollListEmployees,
          data: newList ?? [],
        },
      };
    }

    case UPDATE_STATUS_TRANSMITION_SUPPORT: {
      const id = action?.payload?.empleadoid ?? 0;
      let newArray = state.payrollListEmployees?.data?.map((item) =>
        item.id === id
          ? {
              ...item,
              selected: false,
              trasmitido: action.payload.transmitido,
              fechatrasmision: action.payload.fechaTransmision,
              fechavalidaciondian: action.payload.fechaValidacion,
              codigorespuesta: action.payload.codigorespuesta,
              detallerespuesta: action.payload.detallerespuesta,
              estadonominaid: action.payload.estadonominaid,
              error: action.payload.error,
              pendiente: action.payload.pendiente,
              confirmado: !action.payload.transmitido,
            }
          : { ...item },
      );
      return {
        ...state,
        payrollListEmployees: { ...state.payrollListEmployees, data: newArray },
      };
    }

    default:
      return state;
  }
}
