import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Material
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IMAGE_BANNER from '../../../../images/icons/carga_masiva_empleado.png';
import '../../../../styles/invoice.css';

const useStyles = makeStyles((theme) => ({
  txtFilter: {
    margin: '10px 0px 10px 0px',
    color: theme.palette.primaryColor,
  },
  textHeader: {
    color: '#17DAFA',
    fontSize: '30px',
    fontFamily: 'Muli',
    fontWeight: '600',
    textAlign: 'center',
    margin: '2%',
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: 'auto',
    height: '50px',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
  },
}));

const LoadProcess = () => {
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <div className={classes.textHeader}>Cargue masivo de empleados</div>
          <img
            src={IMAGE_BANNER}
            alt="Datos no encontrados"
            style={{ margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid container lg={12} xs={12} spacing={2} style={{ marginTop: '2%' }}>
          <Button
            style={{
              color: '#FFF',
              margin: '0 auto',
              cursor: 'pointer',
            }}
            className={classes.buttonContinue}
            // onClick={confirmReportProcess}
            // disabled={!isDiligencied}
          >
            Descargar Plantilla
          </Button>
          <Button
            style={{
              color: '#FFF',
              margin: '0 auto',
              cursor: 'pointer',
            }}
            className={classes.buttonContinue}
            // onClick={confirmReportProcess}
            // disabled={!isDiligencied}
          >
            Cargar Plantilla Masiva
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadProcess;
