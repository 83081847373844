import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from '../config/config';
import { axiosApiInstance } from '../config/axios-instance';
import {
  SAVE_TEMPLATE,
  GET_TEMPLATE,
  STATUS_STEP_TEMPLATE,
  LOADING_SAVE_TEMPLATE,
} from './types';

import smallGrayTemplate from '../images/templates/smallGrayTemplate.png';
import bigGrayTemplate from '../images/templates/bigGrayTemplate.png';
import smallBlueTemplate from '../images/templates/smallBlueTemplate.png';
import bigBlueTemplate from '../images/templates/bigBlueTemplate.png';
import smallGreenTemplate from '../images/templates/smallGreenTemplate.png';
import bigGreenTemplate from '../images/templates/bigGreenTemplate.png';
import smallMagentaTemplate from '../images/templates/smallMagentaTemplate.png';
import bigMagentaTemplate from '../images/templates/bigMagentaTemplate.png';

import { handleResponse, enterpriseIdHeader } from './authActions';
import { showErrorMessage } from '../utils/showErrorService';
const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

const templates = [
  {
    id: 'dace5474-95b0-4253-bcf4-45a3d278848e',
    nombre: 'Plantilla Gris',
    srcsmall: smallGrayTemplate,
    srcbig: bigGrayTemplate,
  },
  {
    id: '1604d9fd-0dff-49a2-8080-729c894f1cab',
    nombre: 'Plantilla Azul',
    srcsmall: smallBlueTemplate,
    srcbig: bigBlueTemplate,
  },
  {
    id: 'ce7a5f5e-377b-4fe7-9b9d-b02218e7110e',
    nombre: 'Plantilla Verde',
    srcsmall: smallGreenTemplate,
    srcbig: bigGreenTemplate,
  },
  {
    id: 'e9f81ffe-6773-4b3f-9a94-c3af574fb313',
    nombre: 'Plantilla Magenta',
    srcsmall: smallMagentaTemplate,
    srcbig: bigMagentaTemplate,
  },
];

/**
 * Obtiene listado plantillas disponibles
 * @returns
 */
export const getTemplatesAction = () => async (dispatch) => {
  dispatch({
    type: GET_TEMPLATE,
    payload: templates,
  });
};

/**
 * Registra o actualiza información de plantilla y logo de empresa
 * @param {object} data Información plantilla
 */
export const saveTemplateDataAction = (data) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    const body = {
      ...data,
      EmpresaId: enterpriseIdHeader(),
    };

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateTemplateDataNomina`,
      body,
      defaultHeaders(),
    );

    dispatch({
      type: SAVE_TEMPLATE,
      payload: response.data,
    });
  } catch (err) {
    handleResponse(err.response);
    showErrorMessage(err, 'No se ha podido actualizar Logo y plantilla.');
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta reistro o actualizaci´n información plantilla
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_TEMPLATE,
    payload: status,
  });
}

export const changeStepStatusAction = (status) => (dispatch) => {
  dispatch({
    type: STATUS_STEP_TEMPLATE,
    payload: status,
  });
};
