export const rulesColumnsExcel = {
  agrupacionColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[A-Za-z0-9-_]+$/,
      message:
        'Este campo solo puede estar compuesto por letras, números y los caracteres: guión (-) y guión bajo (_).',
    },
  },
  tipoDocumentoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\d{1,2}-[A-Za-zÁáÉéÍíÓóÚúÜüÑñ\s]{1,100}\s*$/,
      message:
        'Ingrese un valor válido para el tipo de documento que solo utilice los valores proporcionados en la lista.',
    },
  },
  numeroDocumentoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{4,20}$/,
      message:
        'Número de documento inválido. Debe contener únicamente números enteros y tener al menos 4 caracteres, con un máximo de 20 caracteres.',
    },
  },
  tipoIngresoColumn: {
    pattern: {
      value: /^\d{1,2}-[\wÁáÉéÍíÓóÚúÜüÑñ\s\d%-/]{1,100}$/,
      message:
        'Ingrese un valor válido para el tipo ingreso que solo utilice los valores proporcionados en la lista.',
    },
  },
  valorIngresoColumn: {
    pattern: {
      value: /^\d{1,14}(\.\d{1,2})?$/,
      message:
        'Únicamente se permiten números enteros y hasta 2 decimales, utilizando el punto (.) como separador decimal.',
    },
  },
  cantidadDiasColumn: {
    pattern: {
      value: /^\d{1,6}(\.\d{1,2})?$/,
      message:
        'Únicamente se permiten números hasta seis dígitos en la parte entera y una parte decimal opcional con uno o dos dígitos, utilizando el punto (.) como separador decimal.',
    },
  },
  porcentajeIngresoColumn: {
    pattern: {
      value: /^\d{1,3}(\.\d{1,2})?$/,
      message:
        'Únicamente se permiten números hasta tres dígitos en la parte entera y una parte decimal opcional con uno o dos dígitos, utilizando el punto (.) como separador decimal.',
    },
  },
  descripcionIngresoColumn: {
    pattern: {
      value: /^[A-Za-zÁÉÍÓÚÑáéíóúñ0-9&$#._\-:;,;%&/=?´!¡'¿?{}()+.\s]{0,300}$/,
      message:
        "La descripción de ingreso solo puede contener letras, números y los siguientes caracteres: &$#._&-:,;#$%&/=?´!¡'¿?{}()+. Máximo 300 caracteres.",
    },
  },
  tipoDeduccion: {
    pattern: {
      value: /^\d{1,2}-[A-Za-zÁáÉéÍíÓóÚúÜüÑñ\s\d%]{1,100}\s*$/,
      message:
        'Ingrese un valor válido para el tipo deducción que solo utilice los valores proporcionados en la lista.',
    },
  },
  valorDeduccionColumn: {
    pattern: {
      value: /^\d{1,14}(\.\d{1,2})?$/,
      message:
        'Únicamente se permiten números enteros y hasta 2 decimales, utilizando el punto (.) como separador decimal.',
    },
  },
  porcentajeDeduccionColumn: {
    pattern: {
      value: /^\d{1,3}(\.\d{1,2})?$/,
      message:
        'Únicamente se permiten números hasta tres dígitos en la parte entera y una parte decimal opcional con uno o dos dígitos, utilizando el punto (.) como separador decimal.',
    },
  },
  descripcionDeduccionColumn: {
    pattern: {
      value: /^[A-Za-zÁÉÍÓÚÑáéíóúñ0-9&$#._\-:;,;%&/=?´!¡'¿?{}()+.\s]{0,300}$/,
      message:
        "La descripción de deducción solo puede contener letras, números y los siguientes caracteres: &$#._&-:,;#$%&/=?´!¡'¿?{}()+. Máximo 300 caracteres.",
    },
  },
  observacionesColumn: {
    pattern: {
      value: /^[A-Za-zÁÉÍÓÚÑáéíóúñ0-9&$#._\-:;,;%&/=?´!¡'¿?{}()+.\s]{0,500}$/,
      message:
        "Las observaciones solo pueden contener letras, números y los siguientes caracteres: &$#._&-:,;#$%&/=?´!¡'¿?{}()+. Máximo 500 caracteres.",
    },
  },
  numeroDocumentoPasportColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[A-Za-z0-9]{4,20}$/,
      message:
        'Número de documento inválido. Debe contener únicamente caracteres alfanuméricos. Tener al menos 4 caracteres, con un máximo de 20 caracteres.',
    },
  },
};

export const inputsValidations = () => {
  const validations = Object.values(rulesColumnsExcel).map(
    (validation) => validation,
  );
  return validations;
};
