import {
    GET_INVOICES,
    SUCESS_DOCUMENT_UPDATE,
    GET_DOCUMENT,
    SUCESS_DOCUMENT_CREATE,
    SUCESS_QUOTATION_CREATE,
    RESEND_ELECTRONIC_INVOICE,
    GET_ADITIONAL_DATA_INVOICE,
    SUCESS_SEND_INVOICE,
    GET_TOTAL_DOCUMENTS,
    GET_QUOTATION,
    GET_REASONS_NOTES,
    GET_FILE_INVOICE,
    ACTIVE_STEP_INVOICE,
    INFO_DOCUMENT_CREATE,
    GET_INFORME,
    SHOW_INVOICE_CREATION,
    LOADING_GET_DOCUMENTS,
    GET_LIST_RETEFUENTE,
    GET_LIST_RETEICA,
    GET_LIST_RETEIVA,
    LOADING_GET_DETAIL_DOCUMENT,
    LOADING_UPDATE_STATUS_DOCUMENT,
    LOADING_UPDATE_FAVOURITE_DOCUMENT,
    LOADING_UPDATE_FROM_LIST,
    LOADING_DELETE_DOCUMENT
} from "../actions/types";

const initialState = {
    invoices: [],
    sucess: {},
    document: {},
    create: {},
    resend: {},
    createquotation: {},
    aditionaldataenterprise: {},
    sendmail: {},
    showCatalog: true,
    totaldocs: [],
    quotation: {},
    reasons: [],
    file: {},
    openModalItem: false,
    informe: null,
    showInvoiceCreationStatus: false,
    loadingGetdocuments: false,
    totaldocuments: 0,
    loadingGetDetail: false,
    loadingUpdateStatus: false,
    loadingUpdateFavourite: false,
    loadingFavouriteFromList: 0,
    loadingDeleteDocument: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                totaldocuments: action.payload1
            };

        case SUCESS_DOCUMENT_UPDATE:
            return {
                ...state,
                sucess: action.payload
            };

        case GET_DOCUMENT:
            return {
                ...state,
                document: action.payload
            };
        case SUCESS_DOCUMENT_CREATE:
            return {
                ...state,
                create: action.payload
            };

        case RESEND_ELECTRONIC_INVOICE:
            return {
                ...state,
                resend: action.payload
            };

        case SUCESS_QUOTATION_CREATE:
            return {
                ...state,
                createquotation: action.payload
            };

        case SUCESS_SEND_INVOICE:
            return {
                ...state,
                sendmail: action.payload
            };

        case GET_ADITIONAL_DATA_INVOICE:
            return {
                ...state,
                aditionaldataenterprise: action.payload
            };

        case GET_TOTAL_DOCUMENTS:
            return {
                ...state,
                totaldocs: action.payload
            };
        case GET_QUOTATION:
            return {
                ...state,
                quotation: action.payload
            };
        case GET_REASONS_NOTES:
            return {
                ...state,
                reasons: action.payload
            }
        case GET_FILE_INVOICE:
            return {
                ...state,
                file: action.payload
            };
        case ACTIVE_STEP_INVOICE:
            return {
                ...state,
                activeStepInvoice: action.payload
            }
        case INFO_DOCUMENT_CREATE:
            return {
                ...state,
                openModalItem: action.payload
            }
        case GET_INFORME:
            return {
                ...state,
                informe: action.payload
            };
        case SHOW_INVOICE_CREATION:
            return {
                ...state,
                showInvoiceCreationStatus: action.payload
            }
        case LOADING_GET_DOCUMENTS:
            return {
                ...state,
                loadingGetdocuments: action.payload

            }
        case GET_LIST_RETEFUENTE:
            return {
                ...state,
                listReteFuente: action.payload
            }

        case GET_LIST_RETEICA:
            return {
                ...state,
                listReteICA: action.payload
            }

        case GET_LIST_RETEIVA:
            return {
                ...state,
                listReteIVA: action.payload
            }

        case LOADING_GET_DETAIL_DOCUMENT:
            return {
                ...state,
                loadingGetDetail: action.payload
            }

        case LOADING_UPDATE_STATUS_DOCUMENT:
            return {
                ...state,
                loadingUpdateStatus: action.payload

            }
        case LOADING_UPDATE_FAVOURITE_DOCUMENT:
            return {
                ...state,
                loadingUpdateFavourite: action.payload
            }
        case LOADING_UPDATE_FROM_LIST:
            return {
                ...state,
                loadingFavouriteFromList: action.payload
            }
        case LOADING_DELETE_DOCUMENT:
            return {
                ...state,
                loadingDeleteDocument: action.payload
            }

        default:
            return state
    }
}