import { useState } from 'react';
import {
  regexAlphaNumeric,
  regexOnlyNumbers,
  regexEmail,
  regexPhone,
  regexAddress,
  regexAlphabetic,
} from '../helpers/customRegex.hepers';

export const useFormComplete = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = (e, rule) => {
    let value = e.target.value ?? '';

    if (!!rule) {
      value = validateInput(value, rule);
    }

    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCheckChange = ({ target }) => {
    const value = target.checked ?? false;
    setValues({
      ...values,
      [target.name]: value,
    });
  };

  const handleTooggleChange = (e, value, name) => {
    if (!!value) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleAutocompleteChange = (e, value, name) => {
    setValues({
      ...values,
      [name]: value ?? null,
    });
  };

  const handleUpdateForm = (newData) => {
    setValues({
      ...values,
      ...newData,
    });
  };

  return {
    values,
    handleInputChange,
    reset,
    handleTooggleChange,
    handleUpdateForm,
    handleCheckChange,
    handleAutocompleteChange,
  };
};

const validateInput = (value, rule) => {
  switch (rule) {
    case 'number':
      return value.replace(regexOnlyNumbers, '');
    case 'alphanumeric':
      return value.replace(regexAlphaNumeric, '');
    case 'phone':
      return value.replace(regexPhone, '');
    case 'email':
      return value.replace(regexEmail, '');
    case 'address':
      return value.replace(regexAddress, '');
    case 'alphabetic':
      return value.replace(regexAlphabetic, '');
    default:
      return value;
  }
};
