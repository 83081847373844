/**
 * * Cuenta la cantidad de conceptos en un arreglo que tienen el estado 'Registrado'.
 *
 * @param {Array<Object>} conceptArray - Arreglo de objetos que representan conceptos con un estado.
 * @returns {number} - La cantidad de conceptos con el estado 'Registrado' en el arreglo.
 */
export const countRegisteredConcepts = (conceptArray) => {
  return conceptArray.filter((concept) => concept.status === 'Registrado')
    .length;
};
