import {
    SAVE_CONTACT,
    GET_CONTACTSLIST,
    DELETE_CONTACT,
    LOAD_CONTACT,
    UPDATE_CONTACT
} from '../actions/types'


const initialState = {
    editContactStatus: false
}


export default function (state = initialState, action) {

    switch (action.type) {
        case SAVE_CONTACT:
            return {
                ...state,
                saveContactResponse: action.payload
            }
        case GET_CONTACTSLIST:
            return {
                ...state,
                getContactResponse: action.payload
            }
        case DELETE_CONTACT:
            return {
                ...state,
                deleteContactResponse: action.payload
            }
        case LOAD_CONTACT:
            return {
                ...state,
                loadContact: action.payload,
                editContactStatus: !!action.payload ? true : false
            }
        case UPDATE_CONTACT:
            return {
                ...state,
                updateContactResponse: action.payload,
            }
        default:
            return state
    }
} 