import React, { useState } from 'react';

import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { makeStyles } from '@material-ui/core';

import './charts.css';

import { TooltipBiBarChart } from './custom';

const useStyles = makeStyles((theme) => ({
  legend: {
    color: '#FFF',
    marginRight: '50px',
    [theme.breakpoints.down(1280)]: {
      marginRight: '0px',
    },
  },
}));

const legendPayload = [
  {
    value: 'Documento soporte de nómina electrónica',
    type: 'circle',
    id: 'nomina',
    color: '#16B1F3',
  },
  {
    value: 'Notas de ajuste',
    type: 'circle',
    id: 'ajuste',
    color: '#F8CF1D',
  },
];

export const BiBarChart = ({ documentsTransmitted: data }) => {
  const classes = useStyles();
  const [activeSection, setActiveSection] = useState(null);

  const legendFormatter = (value, entry) => (
    <>
      <span className={classes.legend}>{value}</span>
    </>
  );

  /**
   *  * Se encarga de mostrar el tooltip al pasar el mouse por encima de la barra seleccionada
   * @param {*} section
   */
  const handleBarMouseEnter = (section) => {
    setActiveSection(section);
  };

  /**
   * * Se encarga de ocultar el tooltip al salir el mouse de la barra seleccionada
   * @param {*} section
   */
  const handleBarMouseLeave = () => {
    setActiveSection(null);
  };

  return (
    <ResponsiveContainer width="100%" height={233}>
      <BarChart
        width={500}
        height={249}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        className="rectangule-hover"
      >
        <XAxis
          dataKey="name"
          tick={{
            fill: '#FFFFFF',
            fontSize: '12px',
          }}
          axisLine={{ stroke: '#FFFFFF', strokeWidth: 1 }}
          tickSize={0}
          dy={10}
        />
        <YAxis
          axisLine={{
            stroke: '#FFFFFF',
            strokeWidth: 1,
          }}
          tick={{
            fill: '#FFFFFF',
            fontSize: '12px',
          }}
          tickSize={0}
          dx={-10}
        />
        <Tooltip
          content={<TooltipBiBarChart activeSection={activeSection} />}
        />
        <Legend
          formatter={legendFormatter}
          payload={legendPayload}
          wrapperStyle={{
            paddingTop: '10px',
          }}
          iconSize={10}
          fontSize={12}
        />

        <Bar
          dataKey="nomina"
          fill="#16B1F3"
          barSize={35}
          onMouseEnter={() => handleBarMouseEnter('Nomina')}
          onMouseLeave={handleBarMouseLeave}
        />
        <Bar
          dataKey="ajuste"
          fill="#F8CF1D"
          barSize={35}
          onMouseEnter={() => handleBarMouseEnter('Ajuste')}
          onMouseLeave={handleBarMouseLeave}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
