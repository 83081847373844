import React, { useCallback, useEffect, useState } from 'react';
// store
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SwitchForm from '../switch/switch.component';
import { capitalizeUpperLetters } from '../../utils/general';
import { Store, Create } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CustomProgress from '../../components/Progress/progress.component';
import { useMyAccount } from '../../hooks/useMyAccount';

import { WarningAlert, ConfirmAlert2 } from '../../helpers/alert.helpers';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#4A4A4A',
    color: '#FFFF',
    fontWeight: 300,
    height: '100%',
  },
  title: {
    color: '#FFFF',
    fontWeight: 'bold',
    fontSize: 18,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  content: {
    paddingLeft: 66,
  },
  header: {
    alignItems: 'normal',
  },
  headerContent: {
    overflow: 'hidden',
  },
  typography: {
    fontSize: 16,
  },
  avatar: {
    color: '#2CC63E',
  },
  icon: {
    color: '#2CC63E',
  },
});

const CustomCardBranchOffice = React.memo(({ data, onClick }) => {
  const classes = useStyles();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  //Custom hook
  const { updateStateBranch, loadingBranch } = useMyAccount();

  //Store redux
  const listCities = useSelector(
    (state) => state.configReducer.getAllCitiesResponse || null,
  );

  const listStates = useSelector(
    (state) => state.configReducer.getStatesResponse || null,
  );

  const { data: officesList } = useSelector(
    (s) => s.branchOfficeReducer?.filteredBranchOfficesList || [],
  );

  // Actions
  /**
   * Escucha cambios en switch de estado de susucrsal y
   * sucursal principal
   * @param {*} e Evento
   * @param {*} data Iinformación sucursal
   */
  const onChange = (e, data) => {
    const checked = e.target.checked;
    const idform = data.id ?? 0;
    const actives = officesList.filter(
      (c) => c.idstate === 0 && c.id !== idform,
    );

    if (!checked && actives.length === 0) {
      WarningAlert('Upss...!!!', 'Debe existir al menos una sucursal activa');
      return;
    }

    const changeStatusBranch = (confirmed) => {
      if (confirmed) {
        updateStateBranch(data.id, data.idstate === 0 ? 1 : 0, data.nombre);
      }
    };

    const message = `¿Está seguro que desea ${
      data.idstate === 1 ? 'activar' : 'inactivar'
    } la sucursal ${data.nombre}?`;

    ConfirmAlert2(
      message,
      changeStatusBranch,
      `${data.idstate === 1 ? 'Activar' : 'Inactivar'}`,
    );
  };

  const fillStates = useCallback(() => {
    let cityAux = '';

    if (listCities && listCities.length > 0 && data.ciudadid) {
      cityAux =
        listCities.find((element) => element.id === data.ciudadid) || '';
      setCity(cityAux);
    } else {
      setCity('');
    }
    if (listStates && listStates.length > 0 && cityAux) {
      const stateAux =
        listStates.find(
          (element) =>
            Number.parseInt(element.value) === cityAux.departamentoid,
        ) || '';
      setState(stateAux);
    } else {
      setState('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.ciudadid]);

  // React hooks
  useEffect(() => {
    if (listCities?.length > 0 && listStates.length && data.ciudadid) {
      fillStates();
    }
  }, [data, fillStates, listCities, listStates]);

  return (
    <>
      {data && listCities && listCities.length && listStates ? (
        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            avatar={<Store />}
            action={
              <IconButton
                aria-label="settings"
                onClick={(e) => onClick(e, data)}
              >
                <Create className={classes.icon} />
              </IconButton>
            }
            classes={{
              title: classes.title,
              avatar: classes.avatar,
              content: classes.headerContent,
            }}
            title={data.nombre}
          />
          <CardContent className={classes.content}>
            <Typography
              variant="body1"
              className={classes.typography}
              component={'div'}
            >
              <>
                {!!data.prefijo ? 'Prefijo ' + data.prefijo : ''} <br />
                {data.direccion}
                <br />
                {state ? state.text : 'No se ha podido cargar el departamento'}
                <br />
                {city && city.descripcion
                  ? city.descripcion === 'BOGOTA, D.C.'
                    ? 'BOGOTA, D.C.'
                    : capitalizeUpperLetters(city.descripcion)
                  : 'No se ha podido cargar la ciudad'}
                <br />
              </>
              <SwitchForm
                name="activo"
                checked={!data.idstate}
                value={!data.idstate}
                titleOn={
                  !data.idstate ? 'Desactivar sucursal' : 'Activar sucursal'
                }
                withoutMargin={true}
                onChange={(e) => onChange(e, data)}
                loading={loadingBranch}
              />
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Grid container alignItems="center" justify="center">
          <CustomProgress />
        </Grid>
      )}
    </>
  );
});

export default CustomCardBranchOffice;
