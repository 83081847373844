import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, makeStyles } from '@material-ui/core';
import { LicenseInfoCard } from './licenseInfoCard';

import moment from 'moment';
import ModalEmployeeLicence from './Components/ModalEmployeeLicence';

import {
  getCountersDataAction,
  getInfoChartsAction,
  getLicenseReference,
} from '../../actions/dashboardAction';
import {
  BiBarChart,
  CardChart,
  PieChartComp,
  RadialBarComp,
  VerticalBar,
} from './Components/charts';
import { HorizontalBar } from './Components/charts/HorizontalBar';

const useStyles = makeStyles((theme) => ({
  contRoot: {
    padding: 1,

    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },

    [theme.breakpoints.up('sm')]: {
      width: '68vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '75vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '86vw',
    },
  },
  containerRight: {
    paddingLeft: '15px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '30px !important',
      paddingTop: '15px !important',
    },
  },
  containerLeft: {
    paddingLeft: '30px !important',
    paddingTop: '15px !important',
  },
}));

export const Home = () => {
  moment.locale('es');
  const dispatch = useDispatch();
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [licence, setLicence] = useState({
    billings: 0,
    employees: 0,
    showbillings: true,
    showemployees: true,
  });
  const {
    counterData,
    loadingCounter,
    infoCharts,
    loadingInfoCharts,
  } = useSelector((s) => s.dashboardReducer);

  const {
    behaviorMonth,
    concepts,
    documentsTransmitted,
    employeesConfirmed,
    employeesTransmitted,
    payrollPayment,
  } = infoCharts;

  /**
   * Cargue Inicial
   */
  useEffect(() => {
    dispatch(getCountersDataAction());
    dispatch(getInfoChartsAction());
    dispatch(getLicenseReference());
  }, [dispatch]);

  /**
   * Carga información licencia
   */
  useEffect(() => {
    if (!!counterData) {
      let document = false;
      let employe = false;
      if (
        (counterData.licenciaDocumentosDisponible === 0 &&
          counterData.licenciaEmpleadosDisponible === 0) ||
        counterData.licenciaDocumentosDisponible > 0
      ) {
        document = true;
      }

      if (
        (counterData.licenciaDocumentosDisponible === 0 &&
          counterData.licenciaEmpleadosDisponible === 0) ||
        counterData.licenciaEmpleadosDisponible > 0
      ) {
        employe = true;
      }

      setLicence({
        billings: counterData?.licenciaDocumentosDisponible ?? 0,
        employees: counterData?.licenciaEmpleadosDisponible ?? 0,
        showbillings: document,
        showemployees: employe,
      });
    }
  }, [counterData]);

  const getNameMonth = () => {
    const monthNames = [
      'ENERO',
      'FEBRERO',
      'MARZO',
      'ABRIL',
      'MAYO',
      'JUNIO',
      'JULIO',
      'AGOSTO',
      'SEPTIEMBRE',
      'OCTUBRE',
      'NOVIEMBRE',
      'DICIEMBRE',
    ];

    const d = new Date();
    return monthNames[d.getMonth()];
  };

  // obtener año actual  con date fns
  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const nextStep = () => {
    if (step > 3) {
      setStep(0);
      return;
    }
    setStep(step + 1);
  };

  const backStep = () => {
    setStep(step - 1);
  };

  return (
    <div className={classes.contRoot}>
      <Grid container spacing={1} alignContent="center">
        <Grid item xl={5} lg={5} md={12} xs={12}>
          <Grid container spacing={2} style={{ paddingLeft: 25 }}>
            <Grid item lg={6} xs={6}>
              <LicenseInfoCard
                title={licence.employees}
                subtitle={`Empleados Disponibles ${getNameMonth()}`}
                loading={loadingCounter}
              />
            </Grid>

            {licence.showbillings && (
              <Grid item lg={6} xs={6}>
                <LicenseInfoCard
                  title={licence.billings}
                  subtitle={'Documentos Disponibles'}
                  loading={loadingCounter}
                />
              </Grid>
            )}
            <Grid item lg={12} xs={12}>
              <CardChart
                title={`Comportamiento empleados ${getNameMonth()} ${getYear()}`}
                componentProp={<VerticalBar behaviorMonth={behaviorMonth} />}
                loading={loadingInfoCharts}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={12}
          xs={12}
          className={classes.containerRight}
        >
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <CardChart
                title={`Porcentaje nóminas confirmadas ${getNameMonth()} ${getYear()} (conceptos)`}
                componentProp={<PieChartComp concepts={concepts} />}
                loading={loadingInfoCharts}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} xs={12} className={classes.containerLeft}>
          <CardChart
            title={'Pago de nómina últimos 6 meses '}
            componentProp={<HorizontalBar payrollPayment={payrollPayment} />}
            loading={loadingInfoCharts}
          />
        </Grid>
        <Grid item xl={5} lg={5} xs={12} className={classes.containerLeft}>
          <CardChart
            title={`Empleados confirmados y trasmitidos ${getNameMonth()} ${getYear()}`}
            componentProp={
              <RadialBarComp
                employeesTransmitted={employeesTransmitted}
                employeesConfirmed={employeesConfirmed}
              />
            }
            loading={loadingInfoCharts}
          />
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          xs={12}
          className={classes.containerRight}
          style={{ paddingTop: 15 }}
        >
          <CardChart
            title={'Detalle documentos transmitidos a DIAN últimos 6 meses'}
            componentProp={
              <BiBarChart documentsTransmitted={documentsTransmitted} />
            }
            loading={loadingInfoCharts}
          />
        </Grid>
        <ModalEmployeeLicence
          step={step}
          nextStep={nextStep}
          backStep={backStep}
        />
      </Grid>
    </div>
  );
};
