import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Detail from '../components/detail';
import Buttons from '../components/actionButtons';
// Material
import Grid from '@material-ui/core/Grid';
import ListWithFilter from '../../../../components/list/listWithFilter.component';
import CustomProgress from '../../../../components/Progress/progress.component';
import { TypesTabEmployee } from '../../../../types/TypesTabEmployee.js';
import { useEmployees } from '../../../../hooks/useEmployees';
import DATA_NOT_FOUND_IMAGE from '../../../../images/NotificacionesColfactura.png';

import '../../../../styles/invoice.css';
import { useWindowSize } from '../../../../hooks/useResizeWindows';
import { useRegisterEmployee } from '../../../../hooks/useRegisterEmployee';

const useStyles = makeStyles((theme) => ({
  txtFilter: {
    margin: '10px 0px 10px 0px',
    color: theme.palette.primaryColor,
  },
  datanotfound: {
    display: 'block',
    marginTop: '5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  datanotfoundText: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
}));

const Favorites = ({ setContentTab, setBulkLoad }) => {
  const classes = useStyles();
  const [employeeIdSelected, setEmployeeIdSelected] = useState(null);
  const [heightList, setHeightList] = useState(0);
  const {
    loadingGetEmployees,
    employeeDetail,
    fillEmployeeById,
    listFilterEmployeeFavorites,
    changeStateFavorite,
    changeEditingStatus,
    changeOpenRegisterEmployee,
    retireEmployee,
    loadingDetailEmployee,
  } = useEmployees();

  const { setReintegreStatus } = useRegisterEmployee();

  const { height } = useWindowSize();

  /**
   * Consulta Información de primer empleado en la lista
   */
  useEffect(() => {
    if (listFilterEmployeeFavorites?.length > 0) {
      const id = listFilterEmployeeFavorites[0].id;
      fillEmployeeById(id);
      setEmployeeIdSelected(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilterEmployeeFavorites]);

  //Lee altura de pantalla
  useEffect(() => {
    const windowsHeight = height ?? 0;
    const discount = windowsHeight * 0.19;
    setHeightList(windowsHeight - discount);
  }, [height]);

  const handleEmployeeSelection = (item) => {
    fillEmployeeById(item.id);
    setEmployeeIdSelected(item.id);
  };

  /**
   * Abre formulario para edición de empleado e modo reintegro
   */
  const handleReintegrate = () => {
    setReintegreStatus(true);
    changeOpenRegisterEmployee(true);
    changeEditingStatus(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        {listFilterEmployeeFavorites?.length > 0 ? (
          <>
            <Grid item lg={5} md={6} sm style={{ marginTop: 7 }}>
              {!loadingGetEmployees ? (
                <ListWithFilter
                  list={listFilterEmployeeFavorites}
                  textSearch="Buscar empleado por nombre o documento"
                  onClick={(item) => {
                    handleEmployeeSelection(item);
                  }}
                  selectedId={employeeIdSelected}
                  maxHeight={heightList}
                />
              ) : (
                <div style={{ paddingTop: 20 }}>
                  <CustomProgress />
                </div>
              )}
            </Grid>
            <Grid item lg={7} md={6} sm>
              <Buttons setBulkLoad={setBulkLoad} />
              {!loadingDetailEmployee &&
              employeeIdSelected === employeeDetail?.id ? ( //Debe ser loadingDetail
                <Detail
                  setContentTab={setContentTab}
                  employeeDetail={employeeDetail}
                  type={TypesTabEmployee.Destacados}
                  changeStateFavorite={changeStateFavorite}
                  changeEditingStatus={changeEditingStatus}
                  changeOpenRegisterEmployee={changeOpenRegisterEmployee}
                  retireEmployee={retireEmployee}
                  handleReintegrate={handleReintegrate}
                />
              ) : (
                <div style={{ paddingTop: 20 }}>
                  <CustomProgress />
                </div>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item lg={5} md={6} sm></Grid>
            <Grid item lg={7} md={6} sm>
              <Buttons setBulkLoad={setBulkLoad} />
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12} className={classes.datanotfound}>
                <img src={DATA_NOT_FOUND_IMAGE} alt="Datos no encontrados" />
                <div className={classes.datanotfoundText}>
                  No tienes Empleados Destacados
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Favorites;
