import React from 'react';

import { Grid } from '@material-ui/core';

import ErrorIcon from '../../images/icons/failResult.png';
import TransmitIcon from '../../images/svgIcons/transmitedIcon.svg';
import alertIcon from '../../images/svgIcons/alertIcon.svg';
import ValidateIcon from '../../images/icons/validateIcon.png';

import { AccordionComponent } from '../accordion';

import { ConceptList } from './ConceptList';

import { countRegisteredConcepts, getStatusSummary } from './helpers';

export const RowInformation = ({
  data,
  style,
  setExpanded,
  expanded,
  index,
}) => {
  const { fullName, documentNumber, documentType, concepts } = data;

  const truncateText = (text, maxLength) =>
    text && text.length <= maxLength
      ? text
      : `${text ? text.substring(0, maxLength) + '...' : 'XXXXXXX'}`;

  const iconMap = {
    Registrado: ValidateIcon,
    Transmitido: TransmitIcon,
    Mixto: alertIcon,
  };

  const iconSrc = iconMap[getStatusSummary(concepts)] || ErrorIcon;

  return (
    <div key={`${index}${documentNumber}`} style={style}>
      <Grid container style={{ margin: '5px' }}>
        <Grid item xs={12}>
          <AccordionComponent
            title={`${truncateText(
              fullName,
              40,
            )} - ${documentType} ${documentNumber}`}
            subtitle={`Conceptos cargados ${countRegisteredConcepts(
              concepts,
            )}/${concepts.length}`}
            iconSrc={iconSrc}
            iconAlt={getStatusSummary(concepts)}
            idPanel={`${index}${documentNumber}`}
            setExpanded={setExpanded}
            expanded={expanded}
            detailsContent={<ConceptList conceptList={concepts} />}
          />
        </Grid>
      </Grid>
    </div>
  );
};
