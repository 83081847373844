import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomTabs from '../../components/tabs/tabs.component';
import TabActives from './typesTabs/actives';
import TabRemoves from './typesTabs/removes';
import TabFavorites from './typesTabs/favorites';
import RegisterEmployee from './register';
import MassiveProcess from './massiveLoading';
import { useEmployees } from '../../hooks/useEmployees';
import BulkLoad from './bulkLoad/BulkLoad';

const Employees = () => {
  const [EmployeeId, setEmployeeId] = useState(null);
  const [bulkLoad, setBulkLoad] = useState(false);
  const [updateEmployee, setUpdateEmployee] = useState(false);
  const [, setContentTab] = useState(null);
  const [tabSelected, setTabSelected] = useState(0);
  // Custom hook reactivo al id
  const {
    fillListEmployees,
    getLicenceDataEmployees,
    fillLicenceDataEmployees,
  } = useEmployees(EmployeeId);

  // Store visor
  const openRegisterEmployee = useSelector(
    (state) =>
      state.employeeReducer?.registerEmployeeObject.openRegisterEmployee ||
      false,
  );
  const openMassiveLoading = useSelector(
    (state) =>
      state.employeeReducer?.registerEmployeeObject
        .openMassiveLoadingEmployee || false,
  );

  const tabs = [
    {
      title: 'Activos',
      component: (
        <TabActives
          setContentTab={setContentTab}
          setBulkLoad={setBulkLoad}
          updateEmployee={updateEmployee}
        />
      ),
    },
    {
      title: 'Destacados',
      component: <TabFavorites setBulkLoad={setBulkLoad} />,
    },
    {
      title: 'Retirados',
      component: <TabRemoves setBulkLoad={setBulkLoad} />,
    },
  ];

  /**
   * Cargue inicial, consulta listado empleados
   */
  useEffect(() => {
    fillListEmployees();
    if (getLicenceDataEmployees === null) {
      fillLicenceDataEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {openRegisterEmployee ? (
        <RegisterEmployee
          setEmployeeId={setEmployeeId}
          setTabSelected={setTabSelected}
        />
      ) : openMassiveLoading ? (
        <MassiveProcess />
      ) : bulkLoad ? (
        <BulkLoad
          setBulkLoad={setBulkLoad}
          setUpdateEmployee={setUpdateEmployee}
        />
      ) : (
        <>
          <CustomTabs tabs={tabs} tabSelected={tabSelected} />
        </>
      )}
    </>
  );
};

export default Employees;
