/**
 * * Construye un conjunto de datos múltiples a partir de una lista de errores.
 * @param {object[]} errors - Un array de objetos que representan errores.
 * @returns {Array} - Un array que representa un conjunto de datos múltiples.
 */
export const buildMultiDataSet = (errors) => {
  return [
    {
      columns: [
        {
          title: 'Fila',
          width: { wpx: 40 },
          style: {
            fill: { fgColor: { rgb: 'FF0000' }, patternType: 'solid' },
            font: { color: { rgb: 'FFFFFF' }, bold: true, size: 16 },
          },
        },
        {
          title: 'Columna',
          width: { wpx: 400 },
          style: {
            fill: { fgColor: { rgb: 'FF0000' }, patternType: 'solid' },
            font: { color: { rgb: 'FFFFFF' }, bold: true, size: 16 },
          },
        },
        {
          title: 'Observaciones',
          width: { wpx: 600 },
          style: {
            fill: { fgColor: { rgb: 'FF0000' }, patternType: 'solid' },
            font: { color: { rgb: 'FFFFFF' }, bold: true, size: 16 },
          },
        },
      ],
      data: errors.map(buildData),
    },
  ];
};

/**
 * * Construye un conjunto de datos para representar un error.
 * @param {object} error - El objeto de error que contiene información sobre el error.
 * @returns {Array} - Un array que representa los datos del error.
 */
export const buildData = (error) => {
  return [
    { value: error.line, style: { font: { sz: '14', bold: true } } },
    { value: error.key, style: { font: { sz: '14' } } },
    { value: error.message, style: { sz: '18', inline: true } },
  ];
};
