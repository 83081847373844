/**
 * * Validación del tamaño y nombre de columnas del archivo
 *
 * @param {Array} header - El encabezado del archivo que se debe validar.
 * @param {Array} fileHeader - El encabezado del archivo con el que se debe comparar.
 * @returns {boolean} - True si los encabezados coinciden en tamaño y nombre, de lo contrario, false.
 */
export const validateHeaderExcel = (header, fileHeader) => {
  return (
    header.length === fileHeader.length &&
    header.every((value, index) => value === fileHeader[index])
  );
};
