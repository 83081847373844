import * as XLSX from 'xlsx/xlsx.mjs';

/**
 * * Lee y procesa un archivo Excel (XLSX) y devuelve sus datos en formato CSV.
 *
 * @param {File} file - El archivo Excel (XLSX) a procesar.
 * @returns {Promise<string>} - Una promesa que resuelve en los datos CSV del archivo.
 * @throws {Error} - Se lanza un error si ocurre algún problema durante la lectura.
 */
export const readAndProcessFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      try {
        const binaryData = evt.target.result;
        const workbook = XLSX.read(binaryData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const csvData = XLSX.utils.sheet_to_csv(sheet, {
          header: 1,
          blankrows: false,
          RS: '#COlNO2023FR\n',
        });

        resolve(csvData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};
