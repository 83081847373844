import { API_ENDPOINT_EMPLOYEE, defaultHeaders } from '../config/config';

import { axiosApiInstance } from '../config/axios-instance';

import { enterpriseIdHeader } from '../actions/authActions';

const urlEmployee = `${API_ENDPOINT_EMPLOYEE}/employee/api/empleado`;

export default {
  getAllEmployeesService: async () => {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.get(
      `${urlEmployee}/GetEmployees`,
      config,
    );

    return response;
  },

  getEmployeeByIdService: async (id) => {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, { pId: `${id}` });
    const url = `${urlEmployee}${'/GetEmployeeById'}`;
    var response = await axiosApiInstance.get(url, myConfig);

    return response;
  },
  getEmployeeByDocumentService: async (docType, document) => {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      DocType: `${docType}`,
      Document: `${document}`,
      EmpresaId: `${enterpriseIdHeader()}`,
    });
    const url = `${urlEmployee}${'/GetByDocument'}`;
    var response = await axiosApiInstance.get(url, myConfig);

    return response;
  },
  updateFavoriteEmployeeService: async (id, bFavorite) => {
    const body = JSON.stringify({});
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      employeeId: id,
      isFavorite: bFavorite,
    });
    const url = `${urlEmployee}${'/UpdateFavorite'}`;
    var response = await axiosApiInstance.put(url, body, myConfig);

    return response;
  },

  retireEmployeeService: async (idEmpleado, causalId, fechaRetiro) => {
    const body = {
      IdEmpleado: idEmpleado,
      CausalId: causalId,
      FechaRetiro: fechaRetiro,
    };

    const url = `${urlEmployee}${'/causalRetiro'}`;
    return await axiosApiInstance.post(url, body, defaultHeaders());
  },

  getAditionalInfoEemployeeService: async (idEmployee) => {
    const body = JSON.stringify({});
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      employeeId: idEmployee,
    });
    const url = `${urlEmployee}${'/getInfo'}`;
    var response = await axiosApiInstance.put(url, body, myConfig);

    return response;
  },

  /**
   * Persiste empleado
   * @param {object} dataEmployee Objeto empleado
   */
  createEmployeeService: async (dataEmployee) => {
    let config = defaultHeaders();
    const url = `${urlEmployee}${'/CreateEmployee'}`;
    let response = await axiosApiInstance.post(url, dataEmployee, config);
    return response;
  },

  updateEmployeeService: async (data) => {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers);
    const url = `${urlEmployee}${'/Update'}`;
    var response = await axiosApiInstance.put(url, data, myConfig);

    return response;
  },
};
