import {
  API_ENDPOINT_ENTERPRISE,
  //DEFAULTRESPONSE,
  defaultHeaders,
} from '../config/config';
import {
  SAVE_BASIC_DATA,
  //GET_ERRORS,
  GET_COMPANY,
  LOADING_SAVE_BASIC_DATA,
  LOADING_GET_BASIC_DATA,
  UPDATE_INITIAL_DATA,
  UPDATE_DIAN_DATA,
  LOADING_SAVE_DIAN_DATA,
  LOADING_CHECK_CERTIFICATE,
  types,
} from './types';
import { axiosApiInstance } from '../config/axios-instance';
import {
  handleResponse,
  enterpriseIdHeader,
  authHeader,
  enterpriseDocumentType,
  enterpriseDocumentNumber,
} from './authActions';
import { showErrorMessage } from '../utils/showErrorService';
import { homologateDianDescription } from '../helpers/homologateDianDescripcion';

const urlEnterprise = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;

let branchOffices = [];

/**
 *  Consula información de empresa asociada al usuario logueado.
 *  ajustes
 */
export const getCompanyAction = () => async (dispatch) => {
  try {
    changeGetLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.get(
      `${urlEnterprise}/GetEnterprise`,
      config,
    );
    dispatch({
      type: GET_COMPANY,
      payload: response.data.result,
    });
  } catch (err) {
    handleResponse(err.response);
    showErrorMessage(err, 'No se ha podido obtener información de la empresa');
  } finally {
    changeGetLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta información de empresa
 * @param {*} dispatch
 * @param {boolean} status Estado loading
 */
function changeGetLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_BASIC_DATA,
    payload: status,
  });
}

/**
 *@description
 */
export const getInfoIterprice = () => {
  const config = {
    headers: {
      Authorization: `${authHeader()}`,
      pEnterpriseId: `${enterpriseIdHeader()}`,
    },
  };
  return axiosApiInstance
    .get(`${urlEnterprise}/GetEnterprise`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando información de la empresa.');
    });
};

/**
 * Actualiza información basica de empresa
 */
export const updateBasicDataAction = (body) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);

    body = {
      empresaid: enterpriseIdHeader(),
      ...body,
    };

    const resp = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateDataNomina`,
      body,
      defaultHeaders(),
    );

    dispatch({
      type: SAVE_BASIC_DATA,
      payload: resp.data,
    });
  } catch (err) {
    handleResponse(err.response);
    showErrorMessage(
      err,
      'Error actualizando los datos básicos de la empresa.',
    );
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

export const proofTestIDAction = (document, testSetID) => async (dispatch) => {
  try {
    let config = defaultHeaders();
    Object.assign(config.headers, { pDocumento: document, pTestId: testSetID });
    const resp = await axiosApiInstance.get(
      `${urlEnterprise}/GetDataTestID`,
      config,
    );
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
};

/**
 * Actualiza estado loading Registro a actualización
 * información basica
 * @param {*} dispatch
 * @param {*} status
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_BASIC_DATA,
    payload: status,
  });
}

export const filterByOffice = (sucursalid, filter) => {
  let office = branchOffices.find((office) => office.id === sucursalid);
  return !!office ? office.nombre.toLowerCase().indexOf(filter) : -1;
};

/**
 * Verifica si la empresa del usuario logueado tiene un certificado digital valido
 */
export const checkCertificateCompanyAction =
  (continueReportProcess) => async (dispatch) => {
    try {
      changeLoadingCertificate(dispatch, true);
      let config = defaultHeaders();
      Object.assign(config.headers, {
        pEnterpriseId: `${enterpriseIdHeader()}`,
      });

      const response = await axiosApiInstance.get(
        `${urlEnterprise}/GetCertificateNomina`,
        config,
      );

      continueReportProcess(response?.data?.result);
    } catch (err) {
      continueReportProcess({
        requiere: true,
        activo: false,
      });
      showErrorMessage(err, 'No se ha podido verificar certificado digital.');
    } finally {
      changeLoadingCertificate(dispatch, false);
    }
  };

const changeLoadingCertificate = (dispatch, status) => {
  dispatch({
    type: LOADING_CHECK_CERTIFICATE,
    payload: status,
  });
};

/**
 * Actualiza informacion empresa
 */
export const updateInitialData = (body, completeSave) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateInitialData`,
      body,
      config,
    );
    completeSave();
    dispatch({
      type: UPDATE_INITIAL_DATA,
      payload: response.data,
    });
  } catch (err) {
    handleResponse(err.response);
    showErrorMessage(err, 'Error al actualizar la información de la empresa.');
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza data Dian
 */
export const updateDianData = (data, completeSave) => async (dispatch) => {
  try {
    changeUpdateDianDataLoading(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: `${enterpriseIdHeader()}`,
    });

    //Ajustar data
    data = {
      ...data,
      EmpresaId: enterpriseIdHeader(),
    };

    var response = await axiosApiInstance.put(
      `${urlEnterprise}/UpdateDianData`,
      data,
      config,
    );
    completeSave(response.data);
    dispatch({
      type: UPDATE_DIAN_DATA,
      payload: response.data,
    });
  } catch (err) {
    handleResponse(err.response);
    showErrorMessage(
      err,
      'No se ha podido verificar certificado digital de la empresa.',
    );
  } finally {
    changeUpdateDianDataLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading actualización
 * información DIAN
 * @param {*} dispatch
 * @param {*} status
 */
function changeUpdateDianDataLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_DIAN_DATA,
    payload: status,
  });
}

/**
 * Consulta el estado actual del certificado de la empresa
 */
export const getCertificateNomina = () => async (dispatch, getState) => {
  try {
    const { isCertificate } = getState().basicDataReducer.enterpriseCertificate;

    if (!isCertificate) {
      let config = defaultHeaders();

      Object.assign(config.headers, {
        pTipoDocumento: homologateDianDescription(enterpriseDocumentType()),
        pNumeroDocumento: enterpriseDocumentNumber(),
      });

      const response = await axiosApiInstance.get(
        `${urlEnterprise}/GetCertificateVP`,
        config,
      );

      dispatch({
        type: types.GET_NOMINA_CERTIFICATE,
        payload: response.data?.result,
      });
    }
  } catch (err) {
    showErrorMessage(
      err,
      'No se ha podido validar el certificado digital de la empresa.',
    );
  }
};
