import React, { useEffect, useState } from 'react';

import contractIcon from '../../../../images/icons/contractYellowIcon.png';
import PhoneIcon from '../../../../images/icons/phoneYellowIcon.png';
import CustomProgress from '../../../Progress/progress.component';
import { DetailList } from './detailList';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import '../../../../styles/invoice.css';
import { useWindowSize } from '../../../../hooks/useResizeWindows';

const useStyles = makeStyles({
  container: {
    borderRadius: '5px',
    backgroundColor: '#4B4B4B',
    marginTop: '1%',
    padding: '1%',
    color: '#fff',
    overflowY: 'auto',
    '& i': {
      width: '16px',
      marginLeft: '1%',
      fontSize: '16px',
    },
    '& img': {
      color: '#F5D13D',
      width: '13px',
    },
    '& h2': {
      color: '#F5D13D',
      fontWeight: '600',
      margin: '0 auto',
      fontSize: '20px',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    '& p': {
      color: '#FFF',
      margin: '0 auto',
      fontSize: '1.1em',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  },
  lastSupports: {
    color: '#2CC63E',
    backgroundColor: '#1212124D',
    borderRadius: '5px',
    margin: '1%',
    padding: '1%',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2em',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

const Detail = React.memo(({ detailSupports, loadingSupportsDetail }) => {
  const classes = useStyles();
  const [heightDetail, setHeightDetail] = useState(0);

  const { height } = useWindowSize();

  useEffect(() => {
    const windowsHeight = height ?? 0;
    const discount = windowsHeight * 0.145;
    setHeightDetail(windowsHeight - discount);
  }, [height]);

  return (
    <>
      {!loadingSupportsDetail && !!detailSupports ? (
        <Grid
          container
          className={classes.container}
          style={{ minHeight: heightDetail, maxHeight: heightDetail }}
        >
          <Grid item md={6} style={{ paddingLeft: '1em' }}>
            <h2>
              {`${detailSupports.primernombre} ${detailSupports.primerapellido}`}{' '}
            </h2>
            <p>
              {`${detailSupports.tipodocumento} ${detailSupports.documento}`}
            </p>
            <p>{detailSupports.cargo}</p>
          </Grid>
          <Grid
            item
            md={1}
            style={{
              maxWidth: '20px',
            }}
          >
            <div
              style={{
                background: 'rgba(255,255,255,0.2)',
                width: '2px',
                height: '100%',
              }}
            ></div>
          </Grid>
          <Grid item md={5}>
            <Grid container direction="row">
              <Grid item style={{ marginRight: '2%' }}>
                <img src={contractIcon} alt="Logo" />
              </Grid>
              <Grid
                item
                style={{
                  width: '90%',
                }}
              >
                <span>{detailSupports.tipocontrato}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item style={{ marginRight: '2%' }}>
                <img src={PhoneIcon} alt="Logo" />
              </Grid>
              <Grid
                item
                style={{
                  width: '90%',
                }}
              >
                <span style={{}}>
                  {'Salario : ' +
                    '$ ' +
                    new Intl.NumberFormat('es-CO').format(
                      detailSupports.salario,
                    )}
                </span>
              </Grid>
            </Grid>
          </Grid>
          {/* Lista y filtro */}
          <Grid item lg={12} xs={12}>
            <div className={classes.lastSupports}>Soportes de nómina</div>
            <DetailList empleadoId={detailSupports.id} />
          </Grid>
        </Grid>
      ) : (
        <div style={{ paddingTop: 20 }}>
          <CustomProgress />
        </div>
      )}
    </>
  );
});

export default Detail;
