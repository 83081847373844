import React from 'react';

import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#272727',
      color: theme.palette.primaryColor,
      height: '339px',
      width: '99%',
      borderRadius: '5px',
      maxWidth: '974px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '340px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '390px',
      },
    },
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
  },
  dialogAnimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitle: {
    color: theme.palette.primaryColor,
    marginBottom: 45,
    textAlign: 'justify',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  subTitleValue: {
    color: theme.palette.thirdColor,
  },
  btnContinue: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonSuccess: {
    height: '54px',
    maxWidth: '18%',
    width: '39%',
    minWidth: '293px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
}));

export const InformationDialog = ({
  openModalIformation,
  setCompleted,
  setActiveStep,
}) => {
  const classes = useStyles();

  const handleContinueBulkLoad = () => {
    setCompleted(new Set([0, 1]));
    setActiveStep(3);
  };

  return (
    <Dialog
      open={openModalIformation}
      onClose={false}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
        <span className={classes.title}>
          Cargue masivo información de nómina
        </span>
      </DialogTitle>
      <DialogActions className="backgroundContent">
        <Grid
          container
          style={{
            justifyContent: 'center',
          }}
        >
          <Grid item lg={9} xs={9}>
            <div className={classes.subTitle}>
              La información de nómina fue cargada a la plataforma, recuerda que
              debes revisarla y transmitirla a la DIAN para completar el
              proceso.
            </div>
          </Grid>
        </Grid>
      </DialogActions>
      <div className={classes.btnContinue}>
        <button
          className={classes.buttonSuccess}
          type="submit"
          onClick={handleContinueBulkLoad}
        >
          Continuar
        </button>
      </div>
    </Dialog>
  );
};
