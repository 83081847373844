/**
 * * Formatea el mes y año de la nómina en el formato 'YYYY/MM/01'.
 *
 * @param {string} payrollYearMonth - El mes y año de la nómina en formato 'MM/YYYY'.
 * @returns {string} - El mes y año de la nómina en formato 'YYYY/MM/01'.
 */
export const formatPayrollYearMonth = (payrollYearMonth) => {
  const [month, year] = payrollYearMonth.split('/');
  return `${year}/${month}/01`;
};
