import { enterpriseIdHeader } from '../../../actions/authActions';
import ResultTest from '../../../views/employees/bulkLoad/validation/ResultTest';
import { restrictionsValueConcepts } from '../validationExcel';
import {
  CheckConstraintsInConceptValues,
  buildMultiDataSet,
  formatPayrollYearMonth,
  validateConcepts,
} from './';

/**
 * * Genera un objeto de nómina basado en los datos de entrada.
 *
 * @param {Object[]} list - La lista de datos de nómina.
 * @param {Object} errorsByLine - Datos de errores mapeados por línea.
 * @param {Object} results - Errores adicionales relacionados con la nómina.
 * @param {string} payrollYearMonth - El mes y año de la nómina en formato 'MM/YYYY'.
 * @param {Object[]} incomesList - Lista de ingresos y deducciones.
 * @returns {Object} - El objeto de nómina generado.
 */
export const generatePayroll = (
  list,
  errorsByLine,
  results,
  payrollYearMonth,
  incomesList,
  setContentDialog,
) => {
  /**
   * * Mapea los datos de entrada en un objeto de nómina.
   *
   * @param {Object} i - Datos individuales de nómina.
   * @param {number} index - Índice del elemento en la lista.
   * @returns {Object} - Objeto de nómina generado.
   */
  const payrollObject = list.map((i, index) => {
    const errors = errorsByLine[index + 1];

    return {
      Agrupacion: i['Agrupación'],
      TipoDocumento: Number(i['Tipo de documento'].split('-')[0].trim()),
      NumeroDocumento: i['Número documento'],
      TipoIngreso: Number(i['Tipo de ingreso']?.split('-')[0].trim()) || null,
      ValorIngreso: i['Valor ingreso'] || null,
      Cantidad: Number(i['Cantidad(Días u Horas)']) || null,
      PorcentajeIngreso: Number(i['Porcentaje ingreso']) || null,
      DescripcionIngreso: i['Descripción ingreso'] || null,
      TipoDeduccion:
        Number(i['Tipo de Deducción']?.split('-')[0].trim()) || null,
      ValorDeduccion: i['Valor deducción'] || null,
      PorcentajeDeduccion: Number(i['Porcentaje deducción']) || null,
      DescripcionDeduccion: i['Descripción deducción'] || null,
      Observaciones: i['Observaciones'],
      Line: i['Line'],
      Error: errors ? modifyResults(errors) : null,
    };
  });

  /**
   * * Valida los conceptos en los datos de nómina.
   *
   * @param {Object[]} data - Datos de nómina sin errores.
   * @param {Object[]} incomesList - Lista de ingresos.
   * @returns {Object} - Errores y datos conceptuales validados.
   */
  const {
    errors,
    dataConceptsWithoutError,
    dataConceptsError,
  } = validateConcepts(payrollObject, incomesList);

  const {
    errorConstraints,
    ConceptsOk,
    ConceptsError,
  } = CheckConstraintsInConceptValues({
    ValidatedConcepts: dataConceptsWithoutError,
    restrictions: restrictionsValueConcepts,
  });

  const resultModifyIndex = modifyResults(results);

  const multiDataSet = buildMultiDataSet(
    [...resultModifyIndex, ...errors, ...errorConstraints].sort(
      (a, b) => a.line - b.line,
    ),
  );

  setContentDialog(dataConceptsWithoutError.length);

  /**
   * * Construye el objeto de solicitud de nómina.
   *
   * @returns {Object} - Objeto de solicitud de nómina.
   */
  const payrollRequest = {
    Empresaid: enterpriseIdHeader(),
    periodo: formatPayrollYearMonth(payrollYearMonth),
    nominasMasivas: ConceptsOk,
    errorsXLinea: [...resultModifyIndex, ...errors, ...errorConstraints],
  };

  return {
    payrollRequest,
    payrollError: [...dataConceptsError, ...ConceptsError],
    multiDataSet,
  };
};

const modifyResults = (results) => {
  return results.map((objeto) => {
    return new ResultTest(
      objeto.message,
      objeto.line + 1, // Incrementa la propiedad "line" en 1
      objeto.key,
    );
  });
};
