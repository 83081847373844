import React from 'react';

import { Grid } from '@material-ui/core';

import StyledCheckbox from '../../checkForm/styledCheckbox.component';

export const modalFilters = (
  classes,
  stateCheck,
  handleCheckFilter,
  handleCleanFilter,
) => {
  const filterData = [
    {
      name: 'Mixto',
      label: 'Mixtos',
      checked: stateCheck.Mixto,
    },
    { name: 'Error', label: 'Errores', checked: stateCheck.Error },
    {
      name: 'Transmitido',
      label: 'Transmitidos',
      checked: stateCheck.Transmitido,
    },
    {
      name: 'Registrado',
      label: 'Registrados',
      checked: stateCheck.Registrado,
    },
  ];

  const renderFilterItem = (item) => (
    <>
      <Grid item lg={3} md={3} sm={3}>
        <StyledCheckbox
          className={classes.letter}
          checked={item.checked}
          onChange={() => handleCheckFilter(item.name)}
          name={item.name}
        />
      </Grid>
      <Grid item lg={9} md={9} sm={9}>
        {item.label}
      </Grid>
    </>
  );

  return (
    <div style={{ paddingLeft: 10, fontSize: 18 }}>
      <Grid container justify="flex-start" alignItems="center">
        {filterData.map((item) => renderFilterItem(item))}
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <span
            style={{ paddingLeft: '13px', cursor: 'pointer' }}
            onClick={handleCleanFilter}
          >
            Limpiar Filtro
          </span>
        </Grid>
      </Grid>
    </div>
  );
};
