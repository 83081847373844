import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Grow, makeStyles } from '@material-ui/core';

import { usePayroll } from '../../hooks/usePayroll';

import { CardLoadInformation } from './CardLoadInformation';

import BulkLoadImage from '../../images/bulkLoadComplete.png';
import BulkLoadErrorImage from '../../images/modal/14Ocurriounerror-14.png';

import {
  updateFilterDataAction,
  updatePeriodDateAction,
} from '../../actions/payrollReportAction';
import { cleanDataBulkLoadPayrollAction } from '../../actions/payrollBulkLoadAction';

import { calculateSelectedPeriod, countConcepts } from './helpers';
import Confetti from 'react-confetti';
import { useWindowSize } from '../../hooks/useResizeWindows';

const useStyles = makeStyles((theme) => ({
  btnContinue: {
    width: '89%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      width: '92%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  buttonSuccess: {
    height: '54px',
    maxWidth: '18%',
    width: '39%',
    minWidth: '293px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  titleBulkLoadComplete: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 30,
    textAlign: 'center',
    display: 'inline-block',
    marginTop: 30,
    marginBottom: 113,
    color: '#FFFFFF',
    width: '100%',
  },
  sizeImg: {
    [theme.breakpoints.up('1281')]: {
      height: '35vh',
    },
    height: '100%',
  },
}));

export const FinalResultPayroll = ({ activeStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();

  const [checked, setChecked] = useState(false);

  const { filterData } = useSelector((state) => state.payrollReportedReducer);
  const { bulkLoadPayrollResponse, bulkLoadPayroll } = useSelector(
    (s) => s.payrollBulkLoadReducer,
  );

  const { Registrado } = countConcepts(bulkLoadPayrollResponse);

  const { setContentTab } = usePayroll();

  useEffect(() => {
    window.scrollTo(0, 0);
    setChecked(true);
  }, []);

  /**
   * * La función  actualiza la pestaña de contenido, envía acciones para limpiar
   * * datos y actualizar datos de filtro, y actualiza la fecha del período.
   * @param {number} registrado - La cantidad de registros registrados.
   * @returns {Promise<void>} Una promesa que se resuelve cuando se han completado las operaciones.
   */
  const handleFinalBulkLoad = async (registrado) => {
    if (registrado === 0) {
      setContentTab(1);
      dispatch(cleanDataBulkLoadPayrollAction());
      return;
    }

    const { periodo } = bulkLoadPayroll;
    const { month, year, formattedLastDay } = calculateSelectedPeriod(periodo);

    setContentTab(2);
    await dispatch(
      updateFilterDataAction({
        ...filterData,
        CargueMasivo: true,
        CargueMasivoSelected: true,
      }),
    );

    dispatch(updatePeriodDateAction(month, year, formattedLastDay));
  };

  return (
    <Grow in={checked} timeout={500}>
      <div style={{ marginBottom: '10px' }}>
        <CardLoadInformation activeStep={activeStep} />
        <figure
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '80px 0px 0px',
            position: 'relative',
          }}
        >
          <img
            src={Registrado > 0 ? BulkLoadImage : BulkLoadErrorImage}
            alt="bulkload"
            className={classes.sizeImg}
          />
        </figure>
        {Registrado > 0 && <Confetti width={width} height={height} />}
        <span className={classes.titleBulkLoadComplete}>
          {Registrado > 0
            ? `Maravilloso, tu cargue masivo fue un completo éxito!!!`
            : `Upss!!!, tu cargue no pudo completarse. Por favor, corrígelos y cárgalos nuevamente.`}
        </span>
        <div className={classes.btnContinue}>
          <button
            className={classes.buttonSuccess}
            type="submit"
            onClick={() => handleFinalBulkLoad(Registrado)}
          >
            {Registrado > 0 ? 'Ir a Transmitir' : 'Terminar'}
          </button>
        </div>
      </div>
    </Grow>
  );
};
