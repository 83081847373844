import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import incomeIcon from '../../images/svgIcons/incomeIcon.svg';
import checkIcon from '../../images/svgIcons/checkIcon.svg';
import bookmarkRemove from '../../images/svgIcons/bookmarkRemove.svg';
import failIcon from '../../images/svgIcons/failIcon.svg';

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    textAlign: 'start',
    color: 'white',
    gap: '20px',
  },
  centerColumn: {
    textAlign: 'start',
    color: 'white',
    paddingLeft: '118px',
  },
  endColumn: {
    textAlign: 'end',
    color: '#2CC63E',
    paddingRight: '51px',
  },
}));

export const ConceptList = ({ conceptList }) => {
  const classes = useStyles();

  return conceptList.map(
    (
      { conceptName, quantity, percentage, status, conceptType, conceptValue },
      index,
    ) => (
      <Grid
        key={`${index}${quantity}`}
        container
        spacing={2}
        justify="space-between"
      >
        <Grid item xs={4}>
          <Box className={classes.column}>
            <img
              src={
                ['Error', 'Transmitido'].includes(status) ? failIcon : checkIcon
              }
              alt="Icono Estatus"
            />
            <img
              src={conceptType === 'Ingreso' ? incomeIcon : bookmarkRemove}
              alt="Icono Tipo Concepto"
            />
            {conceptName ?? 'XXXXXX'}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.centerColumn}>
            {quantity ?? ''}
            {percentage ? `${percentage}%` : ''}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className={classes.endColumn}
            style={status === 'Error' ? { color: '#ED8D84' } : { color: '' }}
          >{`$${conceptValue.toLocaleString('en-US', {
            useGrouping: true,
            groupingSeparator: "'",
            decimalSeparator: '.',
          })}`}</Box>
        </Grid>
      </Grid>
    ),
  );
};
