import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'initial',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  paper: {
    maxHeight: 700,
    backgroundColor: '#272727',
    border: 'none',
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: 'rgba(216,216,216,0.5)',
    outline: 0,
  },
  paperGreen: {
    maxHeight: 800,
    backgroundColor: '#393939',
    border: 'none',
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: 'rgba(216,216,216,0.5)',
    outline: 0,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    padding: 0,
  },
  buttonClose: {
    textAlign: 'end',
    padding: 10,
  },
  title: {
    color: '#17DAFA',
    fontSize: '30px',
    fontWeight: '800',
    padding: 10,
    textAlign: 'center',
  },
  titleGreen: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#A2E3A4',
    paddingTop: 10,
    textAlign: 'start',
  },
}));

const SimpleModal = ({ component: Component, ...props }) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    title,
    width,
    showCloseButton,
    disabled,
    noPadding,
    titleGreen,
    height,
    disableBackdropClick,
    otherClass,
  } = props;

  return (
    <Modal
      className={otherClass ? otherClass : classes.modal}
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick ?? false}
    >
      <div
        className={titleGreen === true ? classes.paperGreen : classes.paper}
        style={{
          width: !!width ? width : 600,
          height: !!height ? height : '',
          padding:
            noPadding === true ? '0px 0px 10px 0px' : '5px 20px 10px 20px',
        }}
      >
        <Grid container>
          <Grid
            item
            lg={showCloseButton ? 10 : 12}
            xs={showCloseButton ? 10 : 12}
            className={titleGreen === true ? classes.titleGreen : classes.title}
          >
            {title}
          </Grid>
          {showCloseButton === true && (
            <Grid item lg={2} xs={2} className={classes.buttonClose}>
              <IconButton
                className={classes.iconClose}
                onClick={onClose}
                disabled={disabled}
              >
                <CloseIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Grid>
          )}
          <Grid item lg={12} xs={12}>
            <Grid container>
              <Component />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

SimpleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SimpleModal;
