import React, { useEffect, useState } from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

import { useWindowSize } from '../../../../hooks/useResizeWindows';

import './charts.css';

const CustomLegend = ({ payload, data01, data02 }) => {
  const filteredPayload = payload.filter((entry) => entry.value !== 'Total');
  return (
    <ul className="custom-legend">
      {filteredPayload.map((entry, index) => (
        <li key={index}>
          <span
            className="legend-icon"
            style={{ backgroundColor: entry.color }}
          ></span>
          {entry.value === 'Confirmados' && (
            <>
              <p className="legend-text">
                {entry.value}
                <span>
                  {data01[0].value} de {data01[1].value + data01[0].value}
                </span>
              </p>
            </>
          )}
          {entry.value === 'Transmitidos' && (
            <>
              <p className="legend-text">
                {entry.value}
                <span>
                  {data02[0].value} de {data02[1].value + data02[0].value}
                </span>
              </p>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export const RadialBarComp = ({
  employeesConfirmed: data01,
  employeesTransmitted: data02,
}) => {
  const [initialValues, setInitialValues] = useState({
    widthInitial: 500,
    cx: 200,
    cy: 100,
    legend: '50%',
    widthContainer: 200,
  });

  const { widthInitial, cx, cy, legend, widthContainer } = initialValues;
  const { width } = useWindowSize();

  useEffect(() => {
    resizeChart(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  /**
   *  * Posiciona el gráfico de acuerdo al tamaño de la pantalla
   * @param {*} width
   */
  const resizeChart = (width) => {
    if (width <= 600) {
      // xs
      setInitialValues({
        cx: 100,
        legend: '65%',
        widthContainer: 200,
      });
    } else if (width > 600 && width <= 960) {
      // sm
      setInitialValues({
        cx: 120,
        legend: '70%',
        widthContainer: 200,
      });
    } else if (width > 960 && width < 1280) {
      // sm
      setInitialValues({
        cx: 200,
        legend: '50%',
        widthContainer: 200,
      });
    } else if (width >= 1280 && width <= 1415) {
      // md
      setInitialValues({
        cx: 100,
        legend: '60%',
        widthContainer: 233,
      });
    } else if (width > 1415 && width < 1920) {
      // lg
      setInitialValues({
        cx: 100,
        legend: '50%',
        widthContainer: 200,
      });
    } else if (width >= 1920) {
      // xl
      setInitialValues({
        cx: 200,
        legend: '50%',
        widthContainer: 200,
      });
    }
  };

  // * Colores de las barras
  const colors1 = ['#2D9CDD', '#5E5E5E'];
  const colors2 = ['#2CC63E', '#5E5E5E'];

  return (
    <ResponsiveContainer width="100%" height={widthContainer}>
      <PieChart width={widthInitial} height={274}>
        <Pie
          data={data01}
          dataKey="value"
          cx={cx}
          cy={cy}
          outerRadius={60}
          innerRadius={40}
          fill="#8884d8"
          stroke="none"
          startAngle={-270}
        >
          {data01.map((entry, index) => (
            <Cell key={index} fill={colors1[index % colors1.length]} />
          ))}
        </Pie>
        <Pie
          data={data02}
          dataKey="value"
          cx={cx}
          cy={cy}
          innerRadius={70}
          outerRadius={90}
          fill="#82ca9d"
          stroke="none"
          startAngle={-270}
        >
          {data01.map((entry, index) => (
            <Cell key={index} fill={colors2[index % colors2.length]} />
          ))}
        </Pie>
        <Legend
          content={<CustomLegend data01={data01} data02={data02} />}
          iconType="circle"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{
            position: 'absolute',
            left: `${legend}`,
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
