import React, { useEffect, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, IconButton, Link } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EventIcon from '@material-ui/icons/Event';

import InputIcon from '../../../input/inputIcon.component';
import CustomProgress from '../../../Progress/progress.component';
import StyledCheckbox from '../../../../components/checkForm/styledCheckbox.component';
import RcCalendar from '../../../../components/datepicker/rcdatepicker.component';

import { usePayroll } from '../../../../hooks/usePayroll';
import useNearScreen from '../../../../hooks/useNearScreen';
import PopoverModal from '../../../modal/popoverModal.component';
import { format } from 'date-fns';
import { WarningAlert } from '../../../../helpers/alert.helpers';
import { useWindowSize } from '../../../../hooks/useResizeWindows';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  listStyle: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    color: theme.palette.primaryColor,
    overflow: 'auto',
  },
  item: {
    marginBottom: 5,
    width: '95%',
    fontWeight: 600,
    '&.MuiListItem-root:hover': {
      borderRadius: 5,
      border: '1px solid #16B1F6',
    },
    '&.Mui-selected': {
      backgroundColor: '#16B1F3',
      borderRadius: 5,
    },
  },
  itemText: {
    fontWeight: 600,
    color: theme.palette.primaryColor,
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: '150vh',
  },
  emptylist: {
    color: 'white',
  },
  containerFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#2F2E2E',
    fontSize: '14px',
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.5)',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip classes={classes} {...props} />;
}

export const SupportsList = React.memo(({ handleSelection, selectedId }) => {
  const initialFilter = {
    EmpresaId: JSON.parse(localStorage.getItem('dataCompany')).companyId,
    Offset: 0,
    Limit: 20,
    Fechadesde: null,
    Fechahasta: null,
    Filtro: null,
    Aceptado: null,
    Rechazado: null,
    Retirado: null,
    Editado: null,
    LoadMore: false,
  };

  const classes = useStyles();
  const externalRef = useRef();
  const [filter, setFilter] = useState('');
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [filterData, setFilterData] = useState(initialFilter);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [heightList, setHeightList] = useState(100);

  const [rangeDatesFilter, setRangeDatesFilter] = useState({
    dateFrom: format(new Date(), 'yyyy/MM/dd'),
    dateTo: format(new Date(), 'yyyy/MM/dd'),
  });

  const { isNearScreen } = useNearScreen({
    externalRef: false ? null : externalRef,
    distance: '400px',
    once: false,
  });

  const {
    listSupports,
    loadingSupportsList,
    FillListPayrollsupports,
    isRedirecting,
    detailSupports,
  } = usePayroll();

  const size = useWindowSize();

  useEffect(() => {
    const height = size.height ?? 0;
    const discount = height * 0.1879;
    setHeightList(height - discount);
  }, [size.height]);

  /**
   * Carga identificador seleccionado
   */
  useEffect(() => {
    setSelectedItem(selectedId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  /**
   * Ajusta filtro para consulta, siguiente pagina listado nomina reportada
   */
  useEffect(() => {
    if (isNearScreen && !loadingSupportsList) {
      if (!!listSupports) {
        if (listSupports?.length >= listSupports[0].totalregistros) {
          return;
        }
      }
      setFilterData((prev) => ({
        ...prev,
        Offset: listSupports.length,
        LoadMore: true,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * Consulta listado nomina reportada, cuando cambia filtro
   */
  useEffect(() => {
    FillListPayrollsupports(filterData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  /**
   * Ajusta filtro cuando es redirección, modulo empleados
   */
  useEffect(() => {
    if (isRedirecting) {
      const newFilter = detailSupports?.documento ?? '';
      setFilter(newFilter);

      if (newFilter.length > 0) {
        setFilterData((prev) => ({
          ...prev,
          Offset: 0,
          Filtro: newFilter,
          LoadMore: false,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirecting]);

  /**
   * Sincroniza nuevo valor con state,
   * consulta nuevo listado
   * @param {*} param
   */
  const handleChangeFilter = ({ target }) => {
    setFilter(target.value);
    if (target?.value?.length === 0) {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Filtro: '',
        LoadMore: false,
      }));
    }
  };

  /**
   * Filtra por nombre o Nit de empleado
   */
  const handleSearchByName = () => {
    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Filtro: filter,
      LoadMore: false,
    }));
  };

  /**
   * Abre modal filtro estados
   */
  const handleOpenModalChecks = (e) => {
    setModalChecksAnchorEl(e.currentTarget);
  };

  /**
   * Cierra modal filtro estados
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Lee y ajsuta filtro por estado.
   */
  const handleFilterByStatus = ({ target }) => {
    const name = target.name;
    const checked = target.checked;

    if (name === 'accepted') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Aceptado: checked,
        LoadMore: false,
      }));
    }

    if (name === 'notAccepted') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Rechazado: checked,
        LoadMore: false,
      }));
    }

    if (name === 'retired') {
      setFilterData((prev) => ({
        ...prev,
        Offset: 0,
        Retirado: checked,
        LoadMore: false,
      }));
    }
  };

  /**
   * Limpia filtro por estado
   */
  const cleanFilterByStatus = () => {
    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Aceptado: null,
      Rechazado: null,
      Retirado: null,
      LoadMore: false,
    }));
  };

  /**
   * Cierra modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Abre modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * Valida rango de fechas seleccionado,
   * setea información de filtro para nueva consulta
   */
  const handleFilterByDate = () => {
    const { dateFrom, dateTo } = rangeDatesFilter;

    if (dateFrom === '' || dateTo === '') {
      WarningAlert(
        'Upsss...!!',
        'Debes seleccionar un rango de fechas valido.',
      );
      return;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      WarningAlert(
        'Upsss...!!',
        'La fecha inicial no puede ser mayor a la final',
      );
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Fechadesde: dateFrom,
      Fechahasta: dateTo,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Sincroniza fecha inicial filtro con state
   * @param {*} date Fecha
   */
  const handleChangeInitialDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: date,
    }));
  };

  /**
   * Sincroniza fecha final filtro con state
   * @param {*} date Fecha
   */
  const handleChangeEndDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateTo: date,
    }));
  };

  /**
   * Limpia filtro rango fechas
   */
  const cleanFilterDate = () => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: format(new Date(), 'yyyy/MM/dd'),
      dateTo: format(new Date(), 'yyyy/MM/dd'),
    }));

    setFilterData((prev) => ({
      ...prev,
      Offset: 0,
      Fechadesde: null,
      Fechahasta: null,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  const modalFilters = () => {
    return (
      <Grid container justify="flex-start" alignItems="center">
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={Boolean(filterData.Aceptado)}
            onChange={handleFilterByStatus}
            name="accepted"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Aceptados
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={Boolean(filterData.Rechazado)}
            onChange={handleFilterByStatus}
            name="notAccepted"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Rechazados
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={Boolean(filterData.Retirado)}
            onChange={handleFilterByStatus}
            name="retired"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Retirados
        </Grid>
        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link
            href="#"
            onClick={cleanFilterByStatus}
            variant="inherit"
            underline="none"
            className={classes.link}
          >
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    );
  };

  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision" />
            <div className="spacingInvoiceMini" />
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision" />
            <div className="spacingInvoiceMini" />
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateFrom}
              className={'colorCalendarRC'}
              id={'txtfechainicio'}
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateTo}
              className={'colorCalendarRC'}
              id={'txtfechafin'}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={handleFilterByDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} xs={12}>
          <Grid container>
            <Grid item lg={10} xs={10}>
              <InputIcon
                name={'txtFilter'}
                label={'Buscar empleado por nombre o documento'}
                maxLength={15}
                value={filter}
                onChange={handleChangeFilter}
                onSearch={handleSearchByName}
              />
            </Grid>
            <Grid item lg={2} xs={2}>
              <div className={classes.containerFilter}>
                <IconButton
                  onClick={handleOpenModalDates}
                  style={{ color: 'white' }}
                >
                  <EventIcon />
                </IconButton>
                <IconButton onClick={handleOpenModalChecks}>
                  <img alt={'Filtrar'} src={filterImage} />
                </IconButton>
              </div>
            </Grid>
          </Grid>

          {!loadingSupportsList && listSupports.length === 0 && (
            <div className={classes.emptylist}>
              {'No se han encontrado resultados...'}
            </div>
          )}

          {listSupports.length > 0 && (
            <List
              className={classes.listStyle}
              component="div"
              aria-label="main mailbox folders"
              style={{ maxHeight: heightList, minHeight: heightList }}
            >
              {listSupports.map((employee) => (
                <ListItem
                  key={employee.id}
                  className={classes.item}
                  button
                  selected={selectedItem === employee.id}
                  onClick={() => {
                    handleSelection(employee);
                  }}
                >
                  <ListItemText className={classes.itemText} component={'div'}>
                    <div className={classes.contenedor}></div>
                    <BootstrapTooltip
                      title={`${employee.primernombre || ''} ${
                        employee.otronombre || ''
                      } ${employee.primerapellido || ''} ${
                        employee.segundoapellido || ''
                      }`}
                    >
                      <div className={classes.textOverflow}>{`${
                        employee.primernombre || ''
                      } ${employee.otronombre || ''} ${
                        employee.primerapellido || ''
                      } ${employee.segundoapellido || ''}`}</div>
                    </BootstrapTooltip>
                  </ListItemText>
                </ListItem>
              ))}
              <div ref={externalRef} id="visor"></div>
            </List>
          )}

          {loadingSupportsList && <CustomProgress size={20} />}
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
      <PopoverModal
        handleClose={handleCloseModalDates}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={''}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />
    </>
  );
});
