import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PayrollServices from '../services/payrollServices';
import {
  setNavigationAction,
  // reportPayroll
  fillDetailPayrollIdListPayrollAction,
  fillPayrollDetailAction,
  getPayrollDetailEmployeeAction,
  fillAllPayrollEmployeeAction,
  // tab supports
  fillAllPayrollSupportsAction,
  fillDetailSupportsAction,
  // tab edited
  fillAllPayrollEditedAction,
  fillDetailEditedAction,
  // Payrolls reported
  fillAllPayrollsReportedAction,
  getPayrollsReportedDetailAction,
  updatePayrollReportedAction,
  updatePayrollEmployeeAction,
  updatePayrollStateAction,
  setFilesPreviewAction,
  // incomes expenses
  addIncomeAction,
  updateIncomeAction,
  addExpenseAction,
  updateExpenseAction,
  deleteIncomeAction,
  deleteExpenseAction,
  resetpayrollForm,
  // transmision
  setTransmisionStateAction,
  fillAllPayrollEmployeeSignalRAction,
  responseTransmisionStateAction,
  clearStatusTransmitionAction,
  // Support Payrolls
  updateSupportPayrollFavAction,
  sendSupportPayrollAction,
  loadingPayrollsReportedAction,
  loadingPayrollSupportsAction,
  loadingRetireEmployeeAction,
  updateStatusLicenceAction,
} from '../actions/payrollAction';
import { getCompanyAction } from '../actions/basicDataActions';
import {
  FILL_PAYROLL_SUPPORTS_EMPLOYEE,
  RESET_PAYROLL_LIST,
} from '../actions/types';

import { SuccessAlert2 } from '../helpers/alert.helpers';
import { showErrorMessage } from '../utils/showErrorService';

export const usePayroll = () => {
  // state for detail employee payroll
  const [loadingReportedDetail, setLoadingReportedDetail] = useState(false);
  const [loadingSupportsDetail] = useState(false);
  const [loadMoreReported, setLoadMoreReported] = useState(true);
  const [loadingSupportsEmployee, setLoadingSupportsEmployee] = useState(false);
  const [loadingConfirmPayroll, setLoadingConfirmPayroll] = useState(false);
  const [loadingTransmisionPayroll, setLoadingTransmisionPayroll] = useState(
    false,
  );
  const [loadingSendSupportPayroll] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState(false);

  // request company
  const requestCompany = useSelector(
    (state) => state.basicDataReducer.requestCompany || null,
  );

  //Detalle nomina
  const responseSendSupportPayroll = useSelector(
    (state) => state.payrollReducer.responseSendSupportPayroll || null,
  );
  //Fin dellate nomina

  // Navegacion
  const tabSelected = useSelector(
    (state) => state.payrollReducer.navigation.tabSelected,
  );
  const contentTab = useSelector(
    (state) => state.payrollReducer.navigation.contentTab,
  );
  const isRedirecting = useSelector(
    (state) => state.payrollReducer.navigation?.isRedirecting || false,
  );

  const { payrollListEmployees, payrollDetailEmployee } = useSelector(
    (s) => s.payrollReducer,
  );
  // Fin Navegacion

  // Reportar nomina
  const payrollDetail = useSelector(
    (state) => state.payrollReducer.payrollDetail,
  );
  const payrollList = useSelector(
    (state) => state.payrollReducer.payrollListEmployees,
  );
  const payrollEmployeeDetail = useSelector(
    (state) => state.payrollReducer.payrollDetailEmployee || null,
  );

  const { loadingReportedList, loadingSupportsList } = useSelector(
    (s) => s.payrollReducer,
  );
  // fin reportar nomina

  // tab reportados
  const detailReported = useSelector(
    (state) => state.payrollReducer.detailReported,
  );
  const { listReported } = useSelector((s) => s.payrollReducer);
  // fin tab reportados

  // tab soportes de nomina
  const { listSupports, listSupportsEmployee } = useSelector(
    (s) => s.payrollReducer,
  );

  const detailSupports = useSelector(
    (state) => state.payrollReducer.detailSupport,
  );
  // fin tab soportes de nomina

  // tab editados
  const listEdited = useSelector((state) => state.payrollReducer.listEdited);
  const detailEdited = useSelector(
    (state) => state.payrollReducer.detailEdited,
  );
  // fin tab editados

  // Estado Transmision
  const transmisionState = useSelector(
    (state) => state.payrollReducer.transmisionState,

    // fin estado transmision
  );

  const filesPreview = useSelector(
    (state) => state.payrollReducer.filesPreview,
  );

  const dispatch = useDispatch();

  // PRIVATE
  /**
   * Retorna un detalle de nomina segun parametros pedidos, si no se envia alguno de ellos
   * el metodo no cargara nada a la store, de lo contrario cargara el detalle de nomina para
   * un empleado seleccionado
   * @param {array} employeeId Id dek empleado
   * @param {array} payrollId Id de la nomina
   */
  const fillDetailPayrollEmployee = useCallback(
    (employeeId, payrollId, confirmado) => {
      if (employeeId && payrollId) {
        const data = {
          empleadoId: employeeId,
          nominaId: payrollId,
        };
        PayrollServices.GetEmployeePayrollDetail(data)
          .then((result) => {
            // save in store detail employee

            const data = result.data.result || null;
            fillDetailNominaIdInList(data.detallenominaid, data.id);
            dispatch(getPayrollDetailEmployeeAction({ confirmado, data }));
            // local State for employee detail
          })
          .catch((error) => {
            showErrorMessage(
              error,
              'Error consultando detalle de nómina del empleado.',
            );
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  /**
   * Consulta listado nomina reportados
   */
  const getListPayrollReported = useCallback(
    (limitPass, offsetPass, dateFrom, dateTo, favorite, loadMore = false) => {
      if (loadMore !== true) dispatch(fillAllPayrollsReportedAction([]));
      dispatch(loadingPayrollsReportedAction(true));
      PayrollServices.GetAllPayrollReported(
        limitPass,
        offsetPass,
        dateFrom,
        dateTo,
        favorite,
      )
        .then((resp) => {
          let newList = [];

          if (loadMore) {
            const nextList = resp?.data?.result ?? [];
            newList = [...listReported, ...nextList];
          } else {
            newList = resp?.data?.result ?? [];

            //Detalle
            if (newList.length > 0) {
              fillDetailPayrollReported(newList[0]);
            }
          }

          dispatch(fillAllPayrollsReportedAction(newList));
        })
        .catch((err) => {
          showErrorMessage(err, 'Error consultando listado nómina reportado.');
        })
        .finally(() => {
          dispatch(loadingPayrollsReportedAction(false));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, listReported],
  );

  /**
   * Llena listado de la nomina tab reportadas
   */
  const fillListPayrollReported = useCallback(
    (limitPass, offsetPass, dateFrom, dateTo, favorite) => {
      if (loadMoreReported) {
        dispatch(loadingPayrollsReportedAction(true));
        !listReported && fillAllPayrollsReportedAction(null);
        PayrollServices.GetAllPayrollReported(
          limitPass,
          offsetPass,
          dateFrom,
          dateTo,
          favorite,
        )
          .then((result) => {
            const data = result.data.result || null;
            if (data) {
              if (listReported && listReported && listReported.length > 0) {
                const arrAux = [...listReported, ...data];
                dispatch(fillAllPayrollsReportedAction(arrAux));
              } else {
                dispatch(fillAllPayrollsReportedAction(data));
              }

              if (data.length > 0 && !detailReported) {
                fillDetailPayrollReported(data[0]);
              }

              if (data.length === 0) setLoadMoreReported(false);

              dispatch(loadingPayrollsReportedAction(false));
            }
          })
          .catch((error) => {
            showErrorMessage(
              error,
              'Error consultando información de nómina reportada.',
            );
            dispatch(loadingPayrollsReportedAction(false));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listReported, detailReported, loadMoreReported],
  );

  const FillListPayrollEmployee = useCallback(
    (month, year) => {
      const payroll = {
        Mes: month,
        Anio: year,
        Empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      };

      PayrollServices.GetAllEmployeePayroll(payroll)
        .then((result) => {
          const dataResponse = result.data.result || null;

          if (dataResponse) {
            const { empleados, ...data } = dataResponse;

            const lastDatePayment = data.fechapago || null;

            dispatch(fillAllPayrollEmployeeAction({ data: empleados }));

            dispatch(fillPayrollDetailAction({ data, lastDatePayment }));

            if (empleados || empleados.length > 0) {
              fillDetailPayrollEmployee(
                dataResponse.empleados[0].id,
                data.nominaid,
                dataResponse.empleados[0].confirmado,
              );
            }
          }
        })
        .catch((error) => {
          showErrorMessage(error, 'Error consultando información de nómina.');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const fillDetailNominaIdInList = (detallenominaid, id) => {
    dispatch(fillDetailPayrollIdListPayrollAction({ detallenominaid, id }));
  };

  const resetFormReportPayroll = () => {
    dispatch(resetpayrollForm());
  };

  const fillListPayrollEmployeeSignalR = (employeeData) => {
    dispatch(fillAllPayrollEmployeeSignalRAction({ data: employeeData }));
  };

  const fillTransmisionStateSignalR = (objtransmision) => {
    dispatch(setTransmisionStateAction(objtransmision));
  };

  /**
   * Carga un detalle de nomina mensual segun el id de nomina enviado, si no se envia
   * el metodo no cargara nada a la store, de lo contrario cargara el detalle de nomina para
   * la nomina seleccionada del listado (VISTA NOMINAS REPORTADAS)
   * @param {object} dataPayroll Objeto payroll
   */
  const fillDetailPayrollReported = useCallback(
    (dataPayroll) => {
      setLoadingReportedDetail(true);
      dispatch(getPayrollsReportedDetailAction(null));

      if (dataPayroll.id) {
        const data = {
          nominaid: dataPayroll.id,
        };

        PayrollServices.GetPayrollDetailReported(data)
          .then((result) => {
            const data = result.data.result || null;

            dispatch(getPayrollsReportedDetailAction({ data, ...dataPayroll }));
            setLoadingReportedDetail(false);
          })
          .catch((error) => {
            setLoadingReportedDetail(false);
            showErrorMessage(
              error,
              'Error consultando la información de nómina de empleados.',
            );
          });
      }
    },
    [dispatch],
  );

  const setContentTab = (number) => {
    dispatch(setNavigationAction({ contentTab: number, tabSelected }));
  };

  const setTabSelected = (number) => {
    dispatch(setNavigationAction({ contentTab, tabSelected: number }));
  };
  const setIsRedirecting = (isRedirectingPass) => {
    dispatch(
      setNavigationAction({
        contentTab,
        tabSelected,
        isRedirecting: isRedirectingPass,
      }),
    );
  };

  /**
   * Carga en detalles payroll de la store el valor que se le pase como un object
   * @param {object} obj Objeto payroll detail
   */
  const fillPayrollDetail = useCallback(
    (obj) => {
      if (obj) {
        dispatch(fillPayrollDetailAction(obj));
      }
    },
    [dispatch],
  );

  /**
   * Llena listado de soportes de nomina
   */
  const FillListPayrollsupports = useCallback(
    (filter) => {
      dispatch(loadingPayrollSupportsAction(true));
      const { LoadMore } = filter;
      if (!LoadMore) dispatch(fillAllPayrollSupportsAction([]));
      PayrollServices.GetAllPayrollSupports(filter)
        .then((resp) => {
          let newList = [];
          if (LoadMore) {
            const nextList = resp?.data?.result ?? [];
            newList = [...listSupports, ...nextList];
          } else {
            newList = resp?.data?.result ?? [];

            if (newList.length > 0) {
              fillDetailPayrollSupport(newList[0]);
              dispatch({
                type: FILL_PAYROLL_SUPPORTS_EMPLOYEE,
                payload: [],
              });
            }
          }

          dispatch(fillAllPayrollSupportsAction(newList));
        })
        .catch((err) => {
          showErrorMessage(
            err,
            'Error consultando listado soportes de nomina.',
          );
        })
        .finally(() => {
          dispatch(loadingPayrollSupportsAction(false));
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, listSupports],
  );

  /**
   * Carga un detalle de soporte de nomina con un id determinado para una nomina
   * @param {object} dataSupport Objeto payroll
   */
  const fillDetailPayrollSupport = useCallback(
    (dataSupport) => {
      dispatch(fillDetailSupportsAction({ ...dataSupport }));
      dispatch({
        type: FILL_PAYROLL_SUPPORTS_EMPLOYEE,
        payload: [],
      });
    },
    [dispatch],
  );

  /**
   * Consulta listado soportes de nomina por empleado
   */
  const FillPayrollSupportByEmployee = (filter) => {
    if (!filter.LoadMore) {
      dispatch({
        type: FILL_PAYROLL_SUPPORTS_EMPLOYEE,
        payload: [],
      });
    }

    setLoadingSupportsEmployee(true);
    PayrollServices.GetPayrollSupport(filter)
      .then((result) => {
        let newList = result?.data?.result ?? [];
        if (filter.LoadMore) {
          newList = [...listSupportsEmployee, ...newList];
        }

        dispatch({
          type: FILL_PAYROLL_SUPPORTS_EMPLOYEE,
          payload: newList,
        });
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando soportes por empleado.');
      })
      .finally(() => {
        setLoadingSupportsEmployee(false);
      });
  };

  /**
   * Limpia listado y detalle nomina reportada.
   */
  const cleanPayrollSupport = () => {
    dispatch({
      type: RESET_PAYROLL_LIST,
    });
  };

  /** Tab Editados*/
  /**
   * Llena listado de la nomina tab reportadas
   */
  const FillListPayrollEdited = useCallback(() => {
    PayrollServices.GetAllPayrollSupports({
      EmpresaId: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      Offset: 0,
      Limit: 9,
      Fechadesde: '2021-02-01',
      Fechahasta: null,
      Filtro: null,
      Aceptado: null,
      Rechazado: null,
      Retirado: null,
      Editado: true,
    })
      .then((result) => {
        const data = result.data.result || null;

        if (data) {
          dispatch(fillAllPayrollEditedAction(data));
        }
      })
      .catch((error) => {
        showErrorMessage(error, 'Error generando listado de nomina.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Carga un detalle de soporte de nomina con historial con un id determinado para una nomina
   * @param {object} dataSupport Objeto payroll
   */
  const fillDetailPayrollEdited = useCallback(
    (dataSupport) => {
      setLoadingReportedDetail(true);
      dispatch(fillDetailEditedAction(null));

      const dataPayload = {
        empleadoid: dataSupport.id || null,
        Offset: 0,
        Limit: 4,
        filtro: null,
        Fechadesde: null,
        Fechahasta: null,
        Aceptado: false,
        Rechazado: false,
        Destacado: false,
        Historial: true,
      };

      if (dataSupport) {
        PayrollServices.GetPayrollSupport(dataPayload)
          .then((result) => {
            const data = result.data.result || null;

            dispatch(fillDetailEditedAction({ data, ...dataSupport }));

            setLoadingReportedDetail(false);
          })
          .catch((error) => {
            showErrorMessage(
              error,
              'Error consultando detalle de soporte de nomina.',
            );
            setLoadingReportedDetail(false);
          });
      }
    },
    [dispatch],
  );

  const UpdatePayrollEmployee = useCallback(
    (idEmployee, value) => {
      dispatch(
        updatePayrollEmployeeAction({ idEmployee: idEmployee, value: value }),
      );
    },
    [dispatch],
  );

  /**
   * Actualiza el estado confirmado de una determinada nomina
   * @param {int} Detallenominaid Id de nomina a confirmar
   * @param {float} CurrentValuePayroll Valor actual de una determinada nomina
   * @param {Boolean} Confirmado Estado de la nomina
   */
  const UpdatePayrollState = (
    Detallenominaid,
    CurrentValuePayroll,
    Confirmado,
  ) => {
    setLoadingConfirmPayroll(true);
    PayrollServices.updateStatePayroll(
      Detallenominaid,
      Confirmado,
      payrollDetail?.data?.nominaid,
      CurrentValuePayroll,
    )
      .then((result) => {
        const valor = result.data.result.valorneto || null;
        const idEmployeePayroll = payrollEmployeeDetail?.data?.id || null;
        if (
          result.data.statusCode === '200' ||
          result.data.statusCode === '201'
        ) {
          dispatch(
            updatePayrollStateAction({
              valor,
              idEmployeePayroll,
            }),
          );
        }
        setLoadingConfirmPayroll(false);
      })
      .catch((err) => {
        showErrorMessage(err, 'Error al confirmar nómina de empleado.');
        setLoadingConfirmPayroll(false);
      });
  };

  // OPERACIONES DB

  /**
   * Actualiza en DB el favorito segun donde provenga
   * @param {object} idToUpdate Id registro a acturalizar
   * @param {object} isFavorite Estado favorito
   */
  const UpdatePayrollReportedFav = useCallback(
    (idPayroll, isfavorite) => {
      PayrollServices.UpdateFavoriteRegister(idPayroll, isfavorite)
        .then((result) => {
          const data = result.data.httpCode || null;

          if (data === 200) {
            dispatch(
              updatePayrollReportedAction({
                idPayroll: idPayroll,
                isFavorite: isfavorite,
              }),
            );
          }
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error al consultar información de destacados.',
          );
        });
    },
    [dispatch],
  );

  /**
   * Actualiza en DB el estado de favorito de un soporte de nomina
   * @param {object} idDetailPayroll Id registro a acturalizar
   * @param {object} isFavorite Estado favorito
   */
  const updateSupportPayrollFav = useCallback(
    (idSupportPayroll, isFavorite) => {
      PayrollServices.UpdateSupportFavoriteRegister(
        idSupportPayroll,
        isFavorite,
      )
        .then((result) => {
          const data = result.data.httpCode || null;
          if (data === 201) {
            dispatch(
              updateSupportPayrollFavAction({
                idSupportPayroll: idSupportPayroll,
                isFavorite: isFavorite,
              }),
            );
          }
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error al añadir soporte de nómina a destacados.',
          );
        });
    },
    [dispatch],
  );

  /**
   * consulta y llena en REDUX el estado acutla de transmision, general por id empresa
   */
  const fillCurrentStateTransmision = () => {
    PayrollServices.getCurrentStateTransmision()
      .then((result) => {
        const data = result.data.result || null;
        dispatch(setTransmisionStateAction(data));

        if (result.data.statusCode === '200') {
          dispatch(setTransmisionStateAction(data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Prepara y envia obj para el proceso de transmision a DIAN
   */
  const prepareTransmisionDIAN = useCallback(
    (supports) => {
      setLoadingTransmisionPayroll(true);
      PayrollServices.prepareTransmisionDIAN({ Detallesnomina: supports })
        .then((result) => {
          const data = result.data || null;
          dispatch(responseTransmisionStateAction(data));
          if (
            result.data.statusCode === '201' &&
            result.data.responseCode === 'A13'
          ) {
            const updateSupports = adjustSuportsStatus(supports);
            dispatch(updateStatusLicenceAction(updateSupports));
          }
          // local State for employee detail
          setLoadingTransmisionPayroll(false);
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error al consultar el estado de tranmisión a la DIAN del empleado.',
          );
          setLoadingTransmisionPayroll(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, payrollEmployeeDetail, payrollList],
  );

  const adjustSuportsStatus = (supports) => {
    let array = payrollListEmployees.data ?? [];
    for (let i = 0; i < supports.length; i++) {
      const item = supports[i];
      array = array.map((empleado) =>
        empleado.detallenominaid === item.detalleid
          ? empleado.id === payrollDetailEmployee.data.id
            ? { ...empleado, estadonominaid: 5, estadonominaidAux: 5 }
            : { ...empleado, estadonominaid: 5 }
          : { ...empleado },
      );
    }

    return array;
  };

  // INCOMES EXPENSES ACTIONS

  /**
   * guarda en el estado income
   * @param {obj} objPayroll payroll income
   */
  const addIncome = (objPayroll) => {
    const payroll = fillIncomeExpenseObjectPayload(
      objPayroll,
      payrollEmployeeDetail,
      true,
    );
    if (payrollEmployeeDetail.confirmado) {
      PayrollServices.updateStatePayroll(
        payrollEmployeeDetail?.data?.detallenominaid,
        false,
        payrollDetail?.data?.nominaid,
        payrollEmployeeDetail.data.valornomina,
      ).then((resultUpd) => {
        if (
          resultUpd.data.statusCode === '200' ||
          resultUpd.data.statusCode === '201'
        ) {
          dispatch(
            updatePayrollStateAction({
              valor: payrollEmployeeDetail.data.valornomina,
              idEmployeePayroll: payrollEmployeeDetail.data.id,
            }),
          );
          PayrollServices.UpdateConcept(payroll)
            .then((result) => {
              // save in store detail employee
              const data = result.data.result || null;

              if (
                result.data.statusCode === '200' ||
                result.data.statusCode === '201'
              ) {
                objPayroll.id = data.idmovimiento;

                const objUpdate = {
                  valorneto: data.valorneto,
                  objPayroll: objPayroll,
                };
                dispatch(addIncomeAction(objUpdate));
              }
            })
            .catch((error) => {
              showErrorMessage(error, 'Error al actualizar el concepto');
            });
        }
      });
    } else {
      PayrollServices.UpdateConcept(payroll)
        .then((result) => {
          // save in store detail employee
          const data = result.data.result || null;

          if (
            result.data.statusCode === '200' ||
            result.data.statusCode === '201'
          ) {
            objPayroll.id = data.idmovimiento;

            const objUpdate = {
              valorneto: data.valorneto,
              objPayroll: objPayroll,
            };
            dispatch(addIncomeAction(objUpdate));
          }
        })
        .catch((error) => {
          showErrorMessage(error, 'Error al actualizar el concepto.');
        });
    }
  };

  /**
   * actualiza en el estado income
   * @param {obj} objPayroll payroll income
   */
  const updateIncome = useCallback(
    (objPayroll) => {
      const payroll = fillIncomeExpenseObjectPayload(
        objPayroll,
        payrollEmployeeDetail,
        true,
      );
      PayrollServices.UpdateConcept(payroll)
        .then((result) => {
          // save in store detail employee
          const data = result.data.result || null;
          if (
            result.data.statusCode === '201' ||
            result.data.statusCode === '200'
          ) {
            const objUpdate = {
              valorneto: data.valorneto,
              objPayroll: objPayroll,
            };
            dispatch(
              updatePayrollStateAction({
                valor: result.data.result.valornomina || 0,
                idEmployeePayroll: payrollEmployeeDetail.data.id,
              }),
            );
            dispatch(updateIncomeAction(objUpdate));
          }
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error al consultar la información de los ingresos.',
          );
        });
    },
    [dispatch, payrollEmployeeDetail],
  );

  /**
   * guarda en el estado expense
   * @param {obj} objPayroll payroll expense
   */
  const addExpense = (objPayroll) => {
    const payroll = fillIncomeExpenseObjectPayload(
      objPayroll,
      payrollEmployeeDetail,
      false,
    );
    if (payrollEmployeeDetail.confirmado) {
      PayrollServices.updateStatePayroll(
        payrollEmployeeDetail?.data?.detallenominaid,
        false,
        payrollDetail?.data?.nominaid,
        payrollEmployeeDetail.data.valornomina,
      ).then((resultUpd) => {
        if (
          resultUpd.data.statusCode === '200' ||
          resultUpd.data.statusCode === '201'
        ) {
          dispatch(
            updatePayrollStateAction({
              valor: payrollEmployeeDetail.data.valornomina,
              idEmployeePayroll: payrollEmployeeDetail.data.id,
            }),
          );
          PayrollServices.UpdateConcept(payroll)
            .then((result) => {
              // save in store detail employee

              const data = result.data.result || null;

              if (
                result.data.statusCode === '200' ||
                result.data.statusCode === '201'
              ) {
                objPayroll.id = data.idmovimiento || null;

                const objUpdate = {
                  valorneto: data.valorneto,
                  objPayroll: objPayroll,
                };
                dispatch(addExpenseAction(objUpdate));
              }
            })
            .catch((error) => {
              showErrorMessage(error, 'Error al actualizar las deducciones.');
            });
        }
      });
    } else {
      PayrollServices.UpdateConcept(payroll)
        .then((result) => {
          // save in store detail employee

          const data = result.data.result || null;

          if (
            result.data.statusCode === '200' ||
            result.data.statusCode === '201'
          ) {
            objPayroll.id = data.idmovimiento || null;

            const objUpdate = {
              valorneto: data.valorneto,
              objPayroll: objPayroll,
            };
            dispatch(addExpenseAction(objUpdate));
          }
        })
        .catch((error) => {
          showErrorMessage(error, 'Error al actualizar las deducciones.');
        });
    }
  };

  /**
   * actualiza en el estado deducciones
   * @param {obj} objPayroll payroll expense
   */
  const updateExpense = useCallback(
    (objPayroll) => {
      const payroll = fillIncomeExpenseObjectPayload(
        objPayroll,
        payrollEmployeeDetail,
        false,
      );
      PayrollServices.UpdateConcept(payroll)
        .then((result) => {
          // save in store detail employee

          const data = result.data.result || null;

          if (
            result.data.statusCode === '201' ||
            result.data.statusCode === '200'
          ) {
            const objUpdate = {
              valorneto: data.valorneto,
              objPayroll: objPayroll,
            };
            dispatch(
              updatePayrollStateAction({
                valor: result.data.result.valornomina || 0,
                idEmployeePayroll: payrollEmployeeDetail.data.id,
              }),
            );
            dispatch(updateExpenseAction(objUpdate));
          }
          // local State for employee detail
        })
        .catch((error) => {
          showErrorMessage(error, 'Error al actualizar las deducciones.');
        });
    },
    [dispatch, payrollEmployeeDetail],
  );

  /**
   * retorna objeto payroll para guardar o editar un concepto en DB
   * @param {obj} objPayroll payroll expense
   * @param {obj} payrollEmployeeDetail payroll expense
   */
  const fillIncomeExpenseObjectPayload = (
    objPayroll,
    payrollEmployeeDetail,
    isIncome,
  ) => {
    const payroll = {
      movimientoempleadoid:
        typeof objPayroll.id === 'string' ? 0 : objPayroll.id,
      Detallenominaid: payrollEmployeeDetail.data.detallenominaid,
      Conceptoid: objPayroll.conceptoid,
      Valor: objPayroll.valor,
      Cantidad: objPayroll.cantidad,
      Porcentaje: objPayroll.porcentaje,
      Descripcion: objPayroll.descripcion,
      esingreso: isIncome,
    };
    return payroll;
  };

  /**
   * elimina un ingreso de el store y DB
   * @param {obj} item Objeto income a borrar
   */
  const deleteIncome = useCallback(
    (item) => {
      const payload = {
        movimientoempleadoid: item.id,
        Detallenominaid: payrollEmployeeDetail?.data?.detallenominaid,
        Valor: Number(item.valor),
        esingreso: true,
      };
      PayrollServices.deleteConceptPayroll(payload)
        .then((result) => {
          const valor = result.data.result.valorneto || null;
          const idmovimiento = result.data.result.idmovimiento || null;
          if (
            result.data.statusCode === '200' ||
            result.data.statusCode === '201'
          ) {
            dispatch(
              updatePayrollStateAction({
                valor: result.data.result.valornomina || 0,
                idEmployeePayroll: payrollEmployeeDetail.data.id,
              }),
            );
            dispatch(deleteIncomeAction({ valor, idmovimiento }));
          }
        })
        .catch((err) => {
          showErrorMessage(err, 'Error al eliminar el ingreso.');
        });
    },
    [dispatch, payrollEmployeeDetail],
  );

  /**
   * elimina una deduccion de el store y DB
   * @param {obj} item Objeto expense a borrar
   */
  const deleteExpense = (item) => {
    const payload = {
      movimientoempleadoid: item.id,
      Detallenominaid: payrollEmployeeDetail?.data?.detallenominaid,
      Valor: Number(item.valor),
      esingreso: false,
    };
    PayrollServices.deleteConceptPayroll(payload)
      .then((result) => {
        const valor = result.data.result.valorneto || null;
        const idmovimiento = result.data.result.idmovimiento || null;

        if (
          result.data.statusCode === '200' ||
          result.data.statusCode === '201'
        ) {
          dispatch(
            updatePayrollStateAction({
              valor: result.data.result.valornomina || 0,
              idEmployeePayroll: payrollEmployeeDetail.data.id,
            }),
          );
          dispatch(deleteExpenseAction({ valor, idmovimiento }));
        }
      })
      .catch((error) => {
        showErrorMessage(error, 'Error al eliminar la deducción.');
      });
  };

  /**
   * llama al servicio y llena en Store los archivos devueltos para la previsualizacion
   * @param {object} data data registro nomina (TipoDocumento, Documento, CodigoUnico)
   */
  const fillPreviewFiles = useCallback(
    (codigounico) => {
      !!codigounico &&
        PayrollServices.GetDocumentFiles(codigounico)
          .then((result) => {
            const data = result.data.result || null;
            dispatch(setFilesPreviewAction(data));
          })
          .catch((error) => {
            dispatch(
              showErrorMessage(
                error,
                'Error al cargar el documento de soporte de pago.',
              ),
            );
          });
      dispatch(setFilesPreviewAction([]));
    },
    [dispatch],
  );

  /**
   * Envia soporte de nomina a un determinado corrreo electronico
   * @param {*} mailTo Correo electronico
   * @param {*} razonSocialEmpleado Nombre completo del empleado
   * @param {*} codigoUnico Codigo unico del soporte de nomina
   */
  const sendSupportToMail = (
    mailTo,
    razonSocialEmpleado,
    codigoUnico,
    inactiveLoadingSendSupportPayroll,
  ) => {
    PayrollServices.sendPayrollSupport(mailTo, razonSocialEmpleado, codigoUnico)
      .then((result) => {
        const data = result.data || null;

        if (data.httpCode === 200) {
          SuccessAlert2(
            'Buen trabajo!!!',
            'Se realizó el reenvío del soporte de nómina exitosamente.',
          );
        }

        dispatch(sendSupportPayrollAction(data));
      })
      .catch((error) => {
        showErrorMessage(
          error,
          'Error al enviar soporte de nómina por correo electrónico.',
        );
      })
      .finally(() => {
        if (
          inactiveLoadingSendSupportPayroll !== null &&
          inactiveLoadingSendSupportPayroll !== undefined
        ) {
          inactiveLoadingSendSupportPayroll();
        }
      });
  };

  /**
   * guarda en store tabSeleccionada y el tab del contenido
   * @param {obj} obj objeto tipo : {contentTab: 1, tabSelected: 1}
   */
  const saveNavigation = useCallback(
    (obj) => {
      dispatch(setNavigationAction(obj));
    },
    [dispatch],
  );

  /**
   * Limpia Store respuesta de trasnmisión  de documentos
   */
  const getCompanyInformationRequest = () => {
    !requestCompany && dispatch(getCompanyAction());
  };

  /**
   * Elimina soporte de nomina,
   * @param {int}  Detallenominaid detalle del id nominaId
   * @param {string}  ObservacionAjuste Observacion para eliminacion documento
   */
  const deleteSupportDocument = (detallenominaid, observacion) => {
    dispatch(loadingRetireEmployeeAction(true));
    PayrollServices.deleteSupportingDocument(detallenominaid, observacion)
      .then((result) => {
        const statusCode = result.data.statusCode;
        if (statusCode === '200' || statusCode === '201') {
          setDeleteResponse(result.data);
        }
      })
      .catch((error) => {
        showErrorMessage(
          error,
          'Ha ocurrido un error al eliminar el documento.',
        );
      })
      .finally(() => {
        dispatch(loadingRetireEmployeeAction(false));
      });
  };

  /**
   * Limpia informacion del Estado transmision
   */
  const clearStatusTransmition = () => {
    dispatch(clearStatusTransmitionAction());
  };

  return {
    saveNavigation,
    setContentTab,
    contentTab,
    tabSelected,
    setTabSelected,
    responseSendSupportPayroll,
    isRedirecting,
    setIsRedirecting,
    requestCompany,
    loadingSendSupportPayroll,
    loadingReportedDetail,
    loadingReportedList,
    loadingConfirmPayroll,
    loadingTransmisionPayroll,
    loadingSupportsDetail,
    loadingSupportsList,
    loadingSupportsEmployee,
    sendSupportToMail,
    fillListPayrollReported,
    FillListPayrollsupports,
    FillListPayrollEdited,
    fillDetailPayrollEdited,
    FillPayrollSupportByEmployee,
    getCompanyInformationRequest,
    getListPayrollReported,
    listReported,
    listSupports,
    listEdited,
    listSupportsEmployee,
    detailReported,
    detailSupports,
    detailEdited,
    FillListPayrollEmployee,
    fillDetailPayrollEmployee,
    fillPayrollDetail,
    fillPreviewFiles,
    payrollEmployeeDetail,
    fillDetailPayrollReported,
    fillDetailPayrollSupport,
    UpdatePayrollEmployee,
    UpdatePayrollState,
    filesPreview,
    resetFormReportPayroll,
    cleanPayrollSupport,
    deleteResponse,
    // Transmision
    prepareTransmisionDIAN,
    fillCurrentStateTransmision,
    fillTransmisionStateSignalR,
    fillListPayrollEmployeeSignalR,
    transmisionState,
    // DB operations
    updateSupportPayrollFav,
    UpdatePayrollReportedFav,
    deleteSupportDocument,
    payrollDetail,
    payrollList,
    // INCOMES EXPENSES
    addIncome,
    addExpense,
    updateIncome,
    updateExpense,
    deleteIncome,
    deleteExpense,
    clearStatusTransmition,
  };
};
