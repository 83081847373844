import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  progress: {
    color: '#3fc7fa',
  },
}));

export default function CustomProgress(props) {
  const classes = useStyles();
  const { size, aling, color } = props;

  return (
    <div
      className={classes.root}
      style={{ textAlign: !!aling ? aling : 'center' }}
    >
      <CircularProgress
        className={classes.progress}
        style={{
          width: !!size ? size : 30,
          height: !!size ? size : 30,
          color: !!color ? '#3fc7fa' : color,
        }}
      />
    </div>
  );
}
