import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import myAccountServices from '../services/myAccountServices';

import {
  loadBranchOfficeAction,
  getBranchOfficeAction,
  updateStateBranchOfficeAction,
  updatePrincipalBranchOfficeAction,
  updateBranchOfficeAction,
  saveBranchOfficeAction,
  cleanResponseAction,
} from '../actions/branchOfficeActions';
import { showErrorMessage } from '../utils/showErrorService';

export const useMyAccount = () => {
  const dispatch = useDispatch();

  const [loadingBranch, setLoadingBranch] = useState(false);
  const [loadingBranchPrincipal, setLoadingBranchPrincipal] = useState(false);
  // PRIVATE ACTIONS

  // PUBLIC ACTIONS
  /**
   * Guarda el estado de edicion de sucursal
   * @param {object} data data
   * @param {bool} editBranchStatus estado edicion default null -> false
   */
  const saveLoadBranch = useCallback(
    (data, editBranchStatus = false) => {
      dispatch(loadBranchOfficeAction({ data, editBranchStatus }));
    },
    [dispatch],
  );

  /**
   * Actualiza sucursal principal  de una empresa,
   * marca como principal la nueva sucursal y desmarca como princial
   * la anterior
   * @param {*} idOldPrincipal Identificador actual sucursal principal
   * @param {*} idNewPrincipal  Identificador nueva sucursal principal
   */
  const updateMainBranch = useCallback(
    (idOldPrincipal, idNewPrincipal) => {
      setLoadingBranchPrincipal(true);
      myAccountServices
        .UpdateMainBranch(idOldPrincipal, idNewPrincipal)
        .then((result) => {
          let response = {
            ...result.data,
            idOldPrincipal: idOldPrincipal,
            idNewPrincipal: idNewPrincipal,
          };
          dispatch(
            updatePrincipalBranchOfficeAction({
              payload: response,
              idOldPrincipal: idOldPrincipal,
              idNewPrincipal: idNewPrincipal,
            }),
          );
          setLoadingBranchPrincipal(false);
        })
        .catch((error) => {
          showErrorMessage(error, 'Error actualizando la sucursal principal');
        });
    },
    [dispatch],
  );

  /**
   * Actualiza data de una sucursal
   * @param {obj} data data a actualizar de la sucursal (ACTUALIZA DOBLE VEZ, CORREGIR)
   */
  const updateDataBranch = useCallback(
    (data) => {
      myAccountServices
        .UpdateDataBranch(data)
        .then((result) => {
          dispatch(updateBranchOfficeAction(result.data));
        })
        .catch((error) => {
          showErrorMessage(error, 'Error actualizando los datos de sucursal');
        });
    },
    [dispatch],
  );

  /**
   * Guarda data de una nueva sucursal (NO FUNCIONA GUARDA DOS VECES)
   * @param {obj} data data sucursal
   */
  const saveDataBranch = useCallback(
    (data) => {
      myAccountServices
        .SaveDataBranch(data)
        .then((result) => {
          dispatch(saveBranchOfficeAction({ result }));
        })
        .catch((error) => {
          showErrorMessage(error, 'Error guardando los datos de sucursal');
        });
    },
    [dispatch],
  );

  /**
   * Actualiza el estado de una sucursal
   * @param {*} id Identificador actual sucursal principal
   * @param {*} idState  Identificador nueva sucursal principal
   */
  const updateStateBranch = useCallback(
    (id, idState, nombre) => {
      setLoadingBranch(true);
      myAccountServices
        .UpdateStateBranch(id, idState)
        .then((result) => {
          let response = {
            ...result.data,
            id: id,
            idstate: idState,
            nombre: nombre,
          };
          dispatch(
            updateStateBranchOfficeAction({
              payload: response,
              idBranch: id,
              idState: idState,
            }),
          );
          setLoadingBranch(false);
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error actualizando el estado de la sucursal',
          );
        });
    },
    [dispatch],
  );

  /**
   * Set lista de sucursales, filtrada y no filtrada
   * @param {text} filter Filtro text
   * @param {*} refresh  Identificador nueva sucursal principal
   */
  const getBranchesByEmpresaId = useCallback(() => {
    myAccountServices
      .GetByEmpresaId()
      .then((result) => {
        const data = result?.data?.result || [];
        dispatch(getBranchOfficeAction({ data }));
      })
      .catch((error) => {
        showErrorMessage(error, 'Error consultando listado sucursales');
      });
  }, [dispatch]);

  /**
   * Limpia de la store las respuestas guardadas de actualizacion de sucursal
   * principal y actualizacion
   */
  const cleanResponses = useCallback(() => {
    dispatch(cleanResponseAction());
  }, [dispatch]);

  return {
    saveLoadBranch,
    updateMainBranch,
    updateStateBranch,
    updateDataBranch,
    saveDataBranch,
    loadingBranch,
    loadingBranchPrincipal,
    getBranchesByEmpresaId,
    cleanResponses,
  };
};
