import React from 'react';
import ExportExcel from 'react-export-excel';

const ExcelPayrollReport = ({ element, dataSet, fileName }) => {
  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelFile.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelFile.ExcelColumn;

  return (
    <ExcelFile element={element} filename={fileName}>
      <ExcelSheet data={dataSet} name="Reporte Nómina">
        <ExcelColumn label="Tipo Documento" value="tipodocumento" />
        <ExcelColumn label="Número Documento" value="numerodocumento" />
        <ExcelColumn label="Nombre" value="nombrecompleto" />
        <ExcelColumn label="Teléfono" value="telefono" />
        <ExcelColumn label="Correo" value="correo" />
        <ExcelColumn label="Fecha ingreso" value="fechaingreso" />
        <ExcelColumn label="Salario" value="salario" />
        <ExcelColumn label="Mes" value="mes" />
        <ExcelColumn label="Fecha Pago" value="fechapago" />
        <ExcelColumn label="Ingresos" value="ingresos" />
        <ExcelColumn label="Deducciones" value="deducciones" />
        <ExcelColumn label="Valor Total" value="valortotal" />
        <ExcelColumn label="Tipo Documento" value="diantipodocumento" />
        <ExcelColumn label="Prefijo Consecutivo" value="prefijoconsecutivo" />
        <ExcelColumn label="Fecha Trasmision" value="fechatrasmision" />
        <ExcelColumn label="CUNE" value="cune" />
        <ExcelColumn label="Observaciones" value="observaciones" />
        <ExcelColumn label="Fecha acuse Dian" value="fechaacusedian" />
        <ExcelColumn label="Estado dian" value="estadodian" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExcelPayrollReport;
