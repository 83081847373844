import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ModalDialog from './ModalDialog';
import moment from 'moment';
import { SuccessAlert2 } from '../../../helpers/alert.helpers';
import {
  clearLicenseReference,
  setLicenseValidity,
  updateLicenceReference,
} from '../../../actions/dashboardAction';
import ModalConfirm from './ModalConfirm';
import CustomProgress from '../../../components/Progress/progress.component';

const infoAlert = {
  title: 'Buen trabajo!!!',
  text: 'Ya puedes seguir utilizando Colfactura Nómina ',
};

const ModalEmployeeLicence = ({ step, nextStep, backStep }) => {
  moment.locale('es');
  const formatDate = 'DD-MM-YYYY';
  const dispatch = useDispatch();

  const [paymentDate, setPaymentDate] = useState(null);
  const [isPaymentDate, setIsPaymentDate] = useState(false);
  const [licenceDate, setLicenceDate] = useState(null);
  const [paymentLicenceMaxDate, setPaymentLicenceMaxDate] = useState(null);
  const [paymentLicenceMinDate, setPaymentLicenceMinDate] = useState(null);
  const [loadingStep, setLoadingStep] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const {
    counterData,
    licenseReference,
    licenseValidity,
    loadingLicenseValidity,
    updateLicense,
  } = useSelector((s) => s.dashboardReducer);

  /**
   * Asigna fecha por defecto al cargar el componente
   */
  useEffect(() => {
    if (licenseReference === null) return;
    const paymentLicence = licenseReference.paymentDate ?? null;
    const paymentFormat = moment(paymentLicence, moment.ISO_8601).format(
      formatDate,
    );

    setPaymentDate(paymentFormat);
    setIsPaymentDate(true);
    setLoadingStep(true);
  }, [licenseReference]);

  /**
   * Valida si hay una licencia por asignar
   */
  useEffect(() => {
    if (counterData === null || licenseReference === null) return;
    if (licenseReference?.asignoFechaLicEmp) return;
    if (counterData?.fechaInicioLicenciaEmp !== licenseReference?.paymentDate) {
      nextStep();
      setOpenDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterData]);

  /**
   * Asigna fechas de inicio y finalización de la licencia
   */
  useEffect(() => {
    if (!isPaymentDate) return;
    setLoadingStep(false);
    const pastTwoMonths = moment(paymentDate, formatDate).add(2, 'months');
    const pastOneMonth = moment(paymentDate, formatDate).add(1, 'months');
    const currentDate = moment();
    const currentDateFormat = moment(currentDate, formatDate);

    /**
     * Primera validación: La licencia ha excedido los 2 meses de uso
     * Segunda Validación: La licencia no ha excedido los 2 meses de uso y el mes actual ya finalizó
     * Tercera opción, si la licencia no ha excedido los 2 meses de uso y el mes actual no ha finalizado aún
     */
    if (currentDateFormat.isAfter(moment(pastTwoMonths))) {
      const newPaymentDate = formatMonthDate(paymentDate, formatDate, 1, 'add');
      setPaymentDate(newPaymentDate);
    } else if (currentDateFormat.isAfter(moment(pastOneMonth))) {
      const maxPaymentDate = formatMonthDate(paymentDate, formatDate, 1, 'add');
      const minPaymentDate = formatMonthDate(paymentDate, formatDate, 1, 'sub');
      const formatPaymentDate = formatMonthDate(paymentDate, formatDate, 0, '');

      setPaymentDate(formatPaymentDate);
      setPaymentLicenceMaxDate(maxPaymentDate);
      setPaymentLicenceMinDate(minPaymentDate);
    } else {
      const minPaymentDate = formatMonthDate(paymentDate, formatDate, 1, 'sub');
      const formatPaymentDate = formatMonthDate(paymentDate, formatDate, 0, '');

      setPaymentDate(formatPaymentDate);
      setPaymentLicenceMinDate(minPaymentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentDate]);

  useEffect(() => {
    if (licenseValidity === null) return;
    if (licenseValidity.newEffectiveLicenseDate !== null && !updateLicense) {
      setOpenConfirm(false);
      dispatch(updateLicenceReference());
      dispatch(clearLicenseReference());
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseValidity]);

  const handleConfirmDate = () => {
    if (licenceDate === null) return;
    nextStep();
    setOpenConfirm(true);
  };

  const handleSetDate = () => {
    const month = handleDestructureDate(licenceDate, 'Mes');
    const year = handleDestructureDate(licenceDate, 'Año');
    dispatch(setLicenseValidity(month, year));
    setOpenDialog(false);
  };

  const handleDestructureDate = (date, formatDate) => {
    date = moment(date, 'DD-MM-YYYY');
    switch (formatDate) {
      case 'Mes':
        return date.format('MM');
      case 'Año':
        return date.format('YYYY');
      default:
        return date.format('DD');
    }
  };

  const formatMonthDate = (date, format, month, operation) => {
    let monthDate;
    switch (operation) {
      case 'add':
        monthDate = moment(date, format)
          .add(month, 'months')
          .startOf('months')
          .format(format);
        break;
      case 'sub':
        monthDate = moment(date, format)
          .subtract(month, 'months')
          .startOf('months')
          .format(format);
        break;
      default:
        monthDate = moment(date, format).startOf('months').format(format);
        break;
    }
    return monthDate;
  };

  const modalEmployee = (stepModal) => {
    switch (stepModal) {
      case 1:
        return (
          <>
            {loadingStep ? (
              <CustomProgress />
            ) : (
              <ModalDialog
                open={openDialog}
                minDate={paymentLicenceMinDate}
                date={paymentDate}
                maxDate={paymentLicenceMaxDate}
                setDate={setLicenceDate}
                action={handleConfirmDate}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
            <ModalConfirm
              open={openConfirm}
              textContent={`Estás seguro que deseas establecer el siguiente mes, ${licenceDate}, para el inicio de la vigencia?`}
              actionConfirm={handleSetDate}
              actionCancel={backStep}
              loading={loadingLicenseValidity}
            />
          </>
        );
      case 3:
        return (
          <>{updateLicense && SuccessAlert2(infoAlert.title, infoAlert.text)}</>
        );
      default:
        return null;
    }
  };

  return modalEmployee(step);
};

export default ModalEmployeeLicence;
