import { enterpriseIdHeader } from '../actions/authActions';
import { axiosApiInstance } from '../config/axios-instance';
import {
  API_ENDPOINT_PAYROLL,
  API_ENDPOINT_ENTERPRISE,
  defaultHeaders,
} from '../config/config';

/**
 * Valida licencias disponibles
 * @param {*} supports
 * @returns
 */
export const validCounterService = async (supports) => {
  try {
    const url = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/ValidCounter`;
    const resp = await axiosApiInstance.post(url, supports, defaultHeaders());
    return resp?.data ?? null;
  } catch (err) {
    console.log('Error verificando licencias disponibles', err);
    return null;
  }
};

/**
 * Valida certificado vigente empresa
 */
export const validCertificateService = async () => {
  try {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: `${enterpriseIdHeader()}`,
    });

    const resp = await axiosApiInstance.get(
      `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa/GetCertificateNomina`,
      config,
    );
    return resp?.data?.result ?? null;
  } catch (err) {
    console.log('Error verificando certificado de empresa', err);
    return {
      requiere: true,
      activo: false,
    };
  }
};
