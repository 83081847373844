import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Grid, IconButton } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

import CustomProgress from '../../components/Progress/progress.component';

import './modal.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.modalBackgroundColor,
  },
  paper: {
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    color: theme.palette.primaryColor,
  },
  iconClose: {
    color: theme.palette.thirdColor,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
  },
  iconDownload: {
    color: theme.palette.thirdColor,
    textAlign: 'left',
    marginBottom: '2rem',
  },
  divContainerBottom: {
    width: '80%',
    textAlign: 'right',
    marginBottom: '2rem',
  },
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'flex-end',
    textAlign: 'center',
  },
  titleModal: {
    fontSize: '30px',
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  subTitleModal: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
  bodyModal: {
    color: theme.palette.primaryColor,
    fontWeight: '800',
    fontFamily: 'Muli',
  },
  successTitle: {
    color: theme.palette.thirdColor,
  },
  warningTitle: {
    color: theme.palette.fourthColor,
  },
  dangerTitle: {
    color: theme.palette.fifthColor,
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
}));

const ArchiveModal = ({ loading, pdfBase64, open, handleClose, xmlBase64 }) => {
  const classes = useStyles();
  const windowsHeight = parseInt(window.innerHeight * 0.7, 10);

  return (
    <Modal
      aria-labelledby="response-modal-title"
      aria-describedby="response-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item lg={2} xs={12}>
              <IconButton
                className={classes.iconClose}
                onClick={handleClose}
                disabled={loading}
              >
                <CloseIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container justify="center" alignItems="center">
            <Grid item lg={10} xs={12}>
              <div className={classes.divContainer}>
                <div className={classes.bodyModal}>
                  {loading && (
                    <>
                      Cargando archivo
                      <CustomProgress />
                    </>
                  )}
                  <div
                    className={pdfBase64 == null ? classes.show : classes.hide}
                  >
                    {loading ? '' : 'No se encontró el archivo '}
                  </div>
                  <div
                    className={pdfBase64 == null ? classes.hide : classes.show}
                  >
                    <iframe
                      src={'data:application/pdf;base64,' + pdfBase64}
                      title="File"
                      height={windowsHeight}
                      width={'100%'}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container justify="flex-end" alignItems="center">
            {!!xmlBase64 && (
              <Grid item lg={3} xs={12}>
                <div className={classes.divContainerBottom}>
                  <IconButton
                    download={'document.xml'}
                    href={'data:application/xml;base64,' + xmlBase64}
                    className={classes.iconDownload}
                  >
                    Descargar XML
                  </IconButton>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ArchiveModal;
