import { fileHeaderName } from '../validationExcel/columnsFileExcel';
import { groupByLine } from '../../../views/employees/bulkLoad/helpers/utilitiesBulkload';
import { inputsValidations } from '../validationExcel';
import { findAndReportErrors, removeUnwantedCharacters } from './';
import { validateHeaderExcel } from './validateHeaderExcel';
import ValidateField from '../../../views/employees/bulkLoad/validation/ValidateField';

/**
 *  * process CSV data, Leer información de excel
 * @param {archivo en String} dataString
 * @param {cabecera} fileHeader
 * @param {Validaciones} inputValidations
 */
export const processData = async (
  dataString,
  showValidationErrors,
  setOpenModalInformation,
  btnBulk,
) => {
  try {
    const dataStringLines = splitDataString(dataString);

    const headers = parseHeaders(readLine(dataStringLines[0]));
    if (!validateHeaderExcel(headers, fileHeaderName())) {
      showValidationErrors(
        'El archivo que estas cargando no corresponde a la plantilla, ColFactura Nomina no puede interpretarlo.',
      );
      btnBulk.current.value = null;
      setOpenModalInformation(false);
      return;
    }

    if (dataStringLines.length === 1) {
      showValidationErrors('El archivo debe tener por lo menos un registro.');
      btnBulk.current.value = null;
      setOpenModalInformation(false);
      return;
    }

    const { list, results } = processRows(dataStringLines, headers);

    const errors = findAndReportErrors(list);
    if (errors.length > 0) {
      showValidationErrors(
        `Agrupaciones ${errors} son diferentes en  Tipo de documento o Número documento `,
      );
      btnBulk.current.value = null;
      setOpenModalInformation(false);
      return;
    }

    let errorsByLine = groupByLine(results);

    return {
      list,
      errorsByLine,
      results,
    };
  } catch (error) {
    console.error('Error processing data:', error);
    showValidationErrors('Error processing data');
    btnBulk.current.value = null;
  }
};

/**
 * * Limpia una línea eliminando una coma final, si existe.
 * @param {string} line - La línea a limpiar.
 * @returns {string} - La línea limpia.
 */
const readLine = (line) => {
  if (line.slice(-1) === ',') {
    return line.slice(0, -1);
  }
  return line;
};

/**
 * * Parsea la línea de encabezados de un archivo CSV.
 * @param {string} headerLine - La línea de encabezados del archivo CSV.
 * @returns {string[]} - Un array de encabezados limpios.
 */
const parseHeaders = (headerLine) => {
  return headerLine
    .replace(',,', '')
    .split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
    .map((header) => header.trim());
};

/**
 * * Divide una cadena de datos en líneas y elimina líneas vacías y líneas que contengan una cadena específica.
 * @param {string} dataString - La cadena de datos a dividir.
 * @returns {string[]} - Un array de líneas de datos limpias.
 */
const splitDataString = (dataString) => {
  const dataStringLinesTmp = dataString.split(/#COlNO2023FR\n/);
  return dataStringLinesTmp.filter(
    (line) => line !== ',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,' && line !== '',
  );
};

/**
 * * Procesa las filas de datos de un archivo CSV y realiza validaciones en los campos.
 * @param {string[]} dataStringLines - Un array de líneas de datos CSV.
 * @param {string[]} headers - Un array de encabezados.
 * @returns {Object} - Un objeto que contiene una lista de datos procesados y una lista de resultados de validación.
 */
const processRows = (dataStringLines, headers) => {
  const list = [];
  const results = [];
  const validation = new ValidateField(inputsValidations());

  for (let i = 1; i < dataStringLines.length; i++) {
    const rowTmp = readLine(dataStringLines[i]).split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    // * Obtiene información de fila, igualando con encabezados
    const row = rowTmp.slice(0, headers.length);

    if (headers && row.length === headers.length) {
      const obj = {};

      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
        }
        d = readLine(d).trim();
        d = removeUnwantedCharacters(d, headers[j]);

        if (headers[j]) {
          obj[headers[j]] = d;
        }

        const result =
          headers[j] === 'Número documento' && row[j - 1] === '7- Pasaporte'
            ? validation.validate(d, 13, i, headers[j])
            : validation.validate(d, j, i, headers[j]);

        if (result) {
          results.push(result);
        }
      }

      obj['Line'] = i + 1;

      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }
  return { list, results };
};
