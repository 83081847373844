import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = (theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  header: {
    alignItems: 'normal',
    paddingBottom: 0,
  },
  headerContent: {
    textOverflow: 'ellipsis',
    minHeight: 130,
  },
  title: {
    color: theme.palette.primaryColor,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  question: {
    height: '52px',
    width: '418px',
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '26px',
  },
  answer: {
    height: '63px',
    width: '418px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  iconModality: {
    width: 'auto',
  },
  icon: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  content: {
    paddingTop: 0,
    paddingLeft: 58,
  },
  content2: {
    paddingTop: 0,
    paddingLeft: 62,
  },
  content3: {
    paddingTop: 0,
    paddingLeft: 54,
  },
  actions: {
    justifyContent: 'flex-end',
    padding: 'unset',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  colorDescription: {
    color: 'red',
    fontSize: 12.32,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 33,
    },
  },
});

export class CustomCardNumeration extends Component {
  state = {
    active: false,
    expanded: false,
  };

  handleExpandClick = () => {
    !this.state.expanded
      ? this.setState({ expanded: true })
      : this.setState({ expanded: false });
  };

  render() {
    const classes = this.props.classes;

    return (
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.title,
            content: classes.headerContent,
          }}
          title={
            <Fragment>
              <Typography variant="body1">
                <Fragment>
                  <span className={classes.question}>
                    {this.props.question}
                    <br />
                    <br />
                  </span>
                  <Fragment>
                    {this.state.expanded === false ? (
                      <span className={classes.answer}>
                        {this.props.description} ....
                        <br />
                      </span>
                    ) : (
                      <span className={classes.answer}>
                        {this.props.description2}
                      </span>
                    )}
                  </Fragment>
                </Fragment>
              </Typography>
            </Fragment>
          }
        />
        <CardActions className={classes.actions}>
          <IconButton
            className={classes.expandIcon}
            onClick={() => this.handleExpandClick()}
            aria-expanded={this.state.expanded}
          >
            <Typography variant="caption" className={classes.icon}>
              {this.state.expanded ? 'Ver menos' : 'Ver más'}
            </Typography>
            <ExpandMore
              className={clsx(
                classes.expand,
                {
                  [classes.expandOpen]: this.state.expanded,
                },
                classes.icon,
              )}
            />
          </IconButton>
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  branchOfficeReducer: state.branchOfficeReducer,
  basicDataReducer: state.basicDataReducer,
});

export default connect(
  mapStateToProps,
  {},
)(withStyles(useStyles)(CustomCardNumeration));
