import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import Grid from '@material-ui/core/Grid';
import ButtonPrimary from '../button/buttonPrimary.component';

import CustomAutoComplete from '../autocomplete/autocomplete.component';
import InputForm from '../input/input.component';

import { useFormComplete } from '../../hooks/useFormComplete';

import {
  addIncomeAction,
  updateIncomeAction,
  loadIncomeAction,
  saveConceptDocumentAction,
  updateConceptDocumentAction,
} from '../../actions/conceptAction';

import {
  NumberFormatCustom,
  NumberFormatCustomBasic,
  NumberFormatCustomWithMin,
  percentFormatCustom,
  percentFormatCustomOneHundred,
  quantityFormatCustomMax,
  quantityFormatCustomOneHundred,
  quantityFormatCustomPrima,
} from '../../utils/numberFormat';
import isEmpty from '../../utils/isEmpty';
import generateId from '../../utils/generateId';

export const IncomeForm = ({
  handleClose,
  type = 'default',
  detallenominaid = 0,
}) => {
  const dispatch = useDispatch();
  const validator = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  ).current;
  const [, forceUpdate] = useState();
  const [incomesCurrent, setIncomesCurrent] = useState([]);
  const [rule, setRule] = useState({
    id: 0,
    aplicavalor: false,
    aplicaporcentaje: false,
    aplicacantidad: false,
    aplicadescripcion: false,
  });

  const { values, handleInputChange, handleUpdateForm, reset } =
    useFormComplete({
      tipoingreso: null,
      porcentaje: '',
      cantidad: '',
      valor: '',
      descripcion: '',
    });

  const { tipoingreso, porcentaje, cantidad, valor, descripcion } = values;

  const quantityRef = useRef(null);

  /**
   * Store Redux
   */
  const { incomesList } = useSelector((s) => s.configReducer);
  const { incomesPayroll, incomeActive, loadingAdd } = useSelector(
    (s) => s.incomePayrollReducer,
  );

  /**
   * Actualiza listado de ingresos disponibles
   */
  useEffect(() => {
    getDisposableIncomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomesList, incomesPayroll]);

  /**
   * Carga ingreso para edición
   */
  useEffect(() => {
    if (!!incomeActive) {
      const tipoIngreso = incomesList.find(
        (c) => c.id === incomeActive.conceptoid,
      );

      if (!tipoIngreso) return;

      setRule({
        id: tipoIngreso.id,
        aplicavalor: tipoIngreso.aplicavalor,
        aplicaporcentaje: tipoIngreso.aplicaporcentaje,
        aplicacantidad: tipoIngreso.aplicacantidad,
        aplicadescripcion: tipoIngreso.aplicadescripcion,
      });

      handleUpdateForm({
        tipoingreso: tipoIngreso,
        porcentaje: incomeActive.porcentaje,
        cantidad: incomeActive.cantidad,
        valor: incomeActive.valor,
        descripcion: incomeActive.descripcion,
      });

      setIncomesCurrent([
        {
          ...incomeActive,
          value: tipoIngreso.id,
          text: tipoIngreso.descripcion,
        },
        ...incomesCurrent,
      ]);
    } else {
      getDisposableIncomes();
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeActive]);

  /**Actualiza listado ingresos disponibles */
  const getDisposableIncomes = () => {
    let array = incomesList;
    for (let i = 0; i < incomesPayroll.length; i++) {
      const item = incomesPayroll[i];
      array = array.filter((c) => c.id !== item.conceptoid);
    }

    setIncomesCurrent(array);
  };

  /**
   * Actualiza tipo concepto con state,
   * carga reglas definidas para concepto seleccionado
   * @param {*} e
   * @param {*} value
   * @param {*} name
   */
  const handleChangeType = (e, value, name) => {
    //Purgar validador
    validator.purgeFields();
    validator.hideMessages();
    forceUpdate(Math.random());

    handleUpdateForm({
      tipoingreso: value,
      porcentaje: '',
      cantidad: '',
      valor: '',
      descripcion: '',
    });

    if (!value) {
      setRule({
        id: 0,
        aplicavalor: false,
        aplicaporcentaje: false,
        aplicacantidad: false,
        aplicadescripcion: false,
      });
      return;
    }

    const {
      id,
      aplicavalor,
      aplicaporcentaje,
      aplicacantidad,
      aplicadescripcion,
    } = value;

    setRule({
      id: id,
      aplicavalor: aplicavalor,
      aplicaporcentaje: aplicaporcentaje,
      aplicacantidad: aplicacantidad,
      aplicadescripcion: aplicadescripcion,
    });
  };

  /**
   * Vuelve al valor inicial formulario
   */
  const resetForm = () => {
    reset();
    setRule({
      id: 0,
      aplicavalor: false,
      aplicaporcentaje: false,
      aplicacantidad: false,
      aplicadescripcion: false,
    });

    //Purgar validador
    validator.purgeFields();
    validator.hideMessages();
    forceUpdate(Math.random());
  };

  /**
   * Agrega o Edita nuevo ingreso
   * @param {*} e
   */
  const handleAddIncome = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    const income = {
      identificador: !!incomeActive ? incomeActive.identificador : generateId(),
      conceptoid: tipoingreso.id,
      concepto: tipoingreso.descripcion,
      porcentaje: isEmpty(porcentaje) ? null : parseFloat(porcentaje),
      cantidad: isEmpty(cantidad) ? null : parseFloat(cantidad),
      valor: isEmpty(valor) ? null : parseFloat(valor),
      descripcion: descripcion,
      unidadmedida: tipoingreso.unidadmedida,
      esingreso: true,
      aplicaValor: tipoingreso.aplicavalor,
      aplicaPorcentaje: tipoingreso.aplicaporcentaje,
      aplicaCantidad: tipoingreso.aplicacantidad,
      aplicaDescripcion: tipoingreso.aplicadescripcion,
    };

    if (!!incomeActive) {
      handleTypeUpdateIncome(income);
    } else {
      handleTypeAddIncome(income);
    }

    if (type === 'default') completeSaveAndUpdate();
  };

  const completeSaveAndUpdate = () => {
    handleClose();
    resetForm();
    dispatch(loadIncomeAction(null));
  };

  /**
   * Agrega y/o guarda nuevo ingreso
   * @param {*} income
   */
  const handleTypeAddIncome = (income) => {
    if (type === 'document') {
      dispatch(
        saveConceptDocumentAction(
          income,
          detallenominaid,
          completeSaveAndUpdate,
        ),
      );
    } else {
      dispatch(addIncomeAction(income));
    }
  };

  /**
   * Edita y/o guarda nuevo ingreso
   * @param {*} income
   */
  const handleTypeUpdateIncome = (income) => {
    if (type === 'document') {
      dispatch(
        updateConceptDocumentAction(
          income,
          detallenominaid,
          completeSaveAndUpdate,
        ),
      );
    } else {
      dispatch(updateIncomeAction(income));
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <CustomAutoComplete
            name="tipoingreso"
            label="Tipo de ingreso *"
            disabled={!!incomeActive}
            value={tipoingreso}
            options={incomesCurrent}
            textNoOption="No se encontraron resultados."
            onChange={handleChangeType}
            validator={validator}
            validateOptions={'required'}
          />
        </Grid>

        {rule.aplicaporcentaje && (
          <Grid item lg={6} xs={12}>
            <InputForm
              name="porcentaje"
              label={'Porcentaje'}
              value={porcentaje}
              onChange={handleInputChange}
              inputComponent={
                rule.id === 36 || rule.id === 37
                  ? percentFormatCustomOneHundred
                  : percentFormatCustom
              }
              validator={validator}
              validateOptions={rule.aplicaporcentaje ? 'required' : 'void'}
            />
          </Grid>
        )}

        {rule.aplicacantidad && (
          <Grid item lg={6} xs={12}>
            <InputForm
              name="cantidad"
              label={`Cantidad ${tipoingreso?.unidadmedida ?? ''}`}
              value={cantidad}
              onChange={handleInputChange}
              inputComponent={
                rule.id === 34
                  ? quantityFormatCustomPrima
                  : rule.id === 21 ||
                    rule.id === 32 ||
                    rule.id === 33 ||
                    rule.id === 38 ||
                    rule.id === 39 ||
                    rule.id === 40 ||
                    rule.id === 41 ||
                    rule.id === 46 ||
                    rule.id === 64 ||
                    rule.id === 65
                  ? quantityFormatCustomOneHundred
                  : quantityFormatCustomMax
              }
              validator={validator}
              validateOptions={rule.aplicacantidad ? 'required' : 'void'}
              ref={quantityRef}
            />
          </Grid>
        )}

        {rule.aplicavalor && (
          <Grid item lg={6} xs={12}>
            <InputForm
              name="valor"
              label={'Valor'}
              value={valor}
              onChange={handleInputChange}
              inputComponent={
                rule.id === 21
                  ? NumberFormatCustomBasic
                  : rule.id === 22 ||
                    rule.id === 42 ||
                    rule.id === 44 ||
                    rule.id === 45 ||
                    rule.id === 55 ||
                    rule.id === 56 ||
                    rule.id === 57
                  ? NumberFormatCustomWithMin
                  : NumberFormatCustom
              }
              validator={validator}
              validateOptions={rule.aplicavalor ? 'required' : 'void'}
            />
          </Grid>
        )}

        {rule.aplicadescripcion && (
          <Grid item lg={12} xs={12}>
            <InputForm
              name="descripcion"
              label={'Descripcion'}
              value={descripcion}
              onChange={handleInputChange}
              validator={validator}
              validateOptions={`min:3|max:100|${
                rule.aplicavalor ? 'required' : 'void'
              }`}
            />
          </Grid>
        )}
      </Grid>
      <Grid container style={{ justifyContent: 'end' }}>
        <Grid item lg={6} xs={12}>
          <ButtonPrimary
            text={!!incomeActive ? 'Editar' : 'Listo'}
            onClick={handleAddIncome}
            loading={loadingAdd}
          />
        </Grid>
      </Grid>
    </>
  );
};
