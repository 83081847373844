import React, { useEffect } from 'react';
// Components
import { BulkLoadPayroll } from '../_PayrollBulkLoad';
import CustomTabs from '../../components/tabs/tabs.component';
import TabEdited from './edited';
import TabSupports from './removable';
import { TabIntegration } from './integration';
// custom hooks
import { usePayroll } from '../../hooks/usePayroll';
import { isIntegrationEnterprise } from '../../actions/authActions';
import HomePayroll from '../_PayrollGeneral/HomePayroll';
import { PayrollReportManage } from '../_PayrollReport/payrollReportManage';

const PayrollManager = (props) => {
  // custom hook
  const {
    contentTab,
    setContentTab,
    tabSelected,
    resetFormReportPayroll,
    requestCompany,
    getCompanyInformationRequest,
  } = usePayroll();

  //Componentes principales
  const getTabs = () => {
    if (isIntegrationEnterprise()) {
      return [
        {
          title: 'Reportadas',
          component: <HomePayroll />,
        },
        {
          title: 'Soportes de nómina',
          component: <TabSupports setContentTab={setContentTab} />,
        },
        {
          title: 'Editados',
          component: <TabEdited />,
        },
        {
          title: 'Integraciones',
          component: <TabIntegration />,
        },
      ];
    } else {
      return [
        {
          title: 'Reportadas',
          component: <HomePayroll />,
        },
        {
          title: 'Soportes de nómina',
          component: <TabSupports setContentTab={setContentTab} />,
        },
        {
          title: 'Editados',
          component: <TabEdited />,
        },
      ];
    }
  };

  useEffect(() => {
    if (!requestCompany) getCompanyInformationRequest();
    if (contentTab === 1) resetFormReportPayroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTab, tabSelected]);

  useEffect(() => {
    return () => {
      setContentTab(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = () => {
    switch (Number(contentTab)) {
      case 1:
        return <CustomTabs tabs={getTabs()} tabSelected={tabSelected} />;
      case 2:
        return <PayrollReportManage />;
      case 3:
        return <BulkLoadPayroll />;
      default:
        return <CustomTabs tabs={getTabs()} />;
    }
  };

  return <>{contentTab ? <>{getContent(contentTab)}</> : <p>cargando</p>}</>;
};

export default PayrollManager;
