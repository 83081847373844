import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { capitalizeProperty } from '../helpers/functions.helpers';
import { showErrorMessage } from '../utils/showErrorService';
import { types } from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;
const urlConfiguration = `${API_ENDPOINT_PAYROLL}/confignomina/api`;

/**
 * Action para enviar los empleados de cargue masivo
 * @param {*} value,
 * @returns
 */
export const getBulkLoadEmployeeAction = (
  employeeWithoutError,
  employeelist,
  setActiveStep,
  activeStep,
  errorEmployee,
  dataset,
  setOpenModalInformation,
) => async (dispatch) => {
  try {
    const urlBulkLoadEmployee = `${urlDocument}/Documento/ChargeMasiveEmployee`;

    let dataBulkLoad = [];

    dispatch({
      type: types.GET_BULKLOAD_ERROR_EMPLOYEE,
      payload: errorEmployee,
    });

    dispatch({
      type: types.GET_BULKLOAD_DATASET,
      payload: dataset,
    });

    if (employeeWithoutError.length > 0) {
      dataBulkLoad = await axiosApiInstance.post(
        urlBulkLoadEmployee,
        employeeWithoutError,
        defaultHeaders(),
      );
    }

    if (dataBulkLoad.status === 200 && dataBulkLoad.data.result !== null) {
      //Se cambia la primera letra de cada propiedad a mayuscula para que coincida al filtrar con el arreglo de errores
      const dataEmployeeResponse = capitalizeProperty(
        dataBulkLoad.data?.result[0],
      );

      const errorResponse = dataEmployeeResponse.filter(
        (e) => e.Resultado === 'Error',
      );

      errorResponse.length > 0 &&
        errorResponse.forEach((e) => {
          const indexLine = employeelist.findIndex(
            (i) => i.Documento === Number(e.Documento),
          );

          const sucursalFilter = employeelist.filter(
            (s) => s.Documento === Number(e.Documento),
          );

          e.Error = [
            {
              message: `Sucursal no válida, verifique la información ingresada en [Sucursal: ${sucursalFilter[0].Prefijosucursal}].`,
              line: indexLine + 2,
              key: 'Prefijo de la Sucursal',
            },
          ];

          dataset[0].data.push([
            {
              value: indexLine + 2,
              style: {
                font: {
                  sz: '14',
                  bold: true,
                },
              },
            },
            {
              value: 'Prefijo de la Sucursal',
              style: {
                font: {
                  sz: '14',
                },
              },
            },
            {
              value: `Sucursal no válida, verifique la información ingresada en [Sucursal: ${sucursalFilter[0].Prefijosucursal}].`,
              style: {
                sz: '18',
                inline: true,
              },
            },
          ]);

          dataset[0].data.sort((a, b) => a[0].value - b[0].value);
        });

      const dataResponse = dataEmployeeResponse.filter(
        (e) => e.Resultado !== 'Error',
      );

      if (errorResponse.length > 0) {
        dispatch({
          type: types.GET_BULKLOAD_ERROR_EMPLOYEE,
          payload: errorEmployee.concat(errorResponse),
        });
      }

      if (dataResponse.length > 0) {
        dispatch({
          type: types.GET_BULKLOAD_EMPLOYEE,
          payload: dataResponse,
        });
      }
    }

    setActiveStep(activeStep + 1);
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error realizando el cargue masivo de empleados.',
    );
    setOpenModalInformation(false);
  } finally {
    setActiveStep(activeStep + 1);
  }
};

/**
 * Action para traer la plantilla de los empleados de cargue masivo y convertirla a base64 con el callback de la función
 * @param {} convertBase64
 * @returns
 */
export const getTemplateBulkUploadActionTest = (convertBase64) => async () => {
  try {
    const urlTemplate = `${urlConfiguration}/Menu/ObtenerPlantillaEmpleados`;

    const response = await axiosApiInstance.get(urlTemplate, defaultHeaders());

    if (response.status === 200) {
      convertBase64(response.data.result.downloadRoute);
    }
  } catch (err) {
    showErrorMessage(err, 'Error consultando la plantilla de cargue masivo.');
  }
};

/**
 *  Action para limpiar los datos retornando el estado inicial
 * @returns
 */
export const cleanDataAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_BULKLOAD_EMPLOYEE,
  });
};
