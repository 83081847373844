import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import Detail from '../components/detail';
import Buttons from '../components/actionButtons';
import ListWithFilter from '../../../../components/list/listWithFilter.component';
import CustomProgress from '../../../../components/Progress/progress.component';
import ResponseModal from '../../../../components/modal/responseModal.component';
// Material
import Grid from '@material-ui/core/Grid';
import '../../../../styles/invoice.css';
import { TypesTabEmployee } from '../../../../types/TypesTabEmployee.js';
import DATA_NOT_FOUND_IMAGE from '../../../../images/NotificacionesColfactura.png';
// custom hooks
import { useEmployees } from '../../../../hooks/useEmployees';
import { useRegisterEmployee } from '../../../../hooks/useRegisterEmployee';
import { useWindowSize } from '../../../../hooks/useResizeWindows';

const useStyles = makeStyles((theme) => ({
  txtFilter: {
    margin: '10px 0px 10px 0px',
    color: theme.palette.primaryColor,
  },
  datanotfound: {
    display: 'block',
    marginTop: '5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  datanotfoundText: {
    color: theme.palette.primaryColor,
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Muli',
  },
}));

const Actives = ({ setContentTab, setBulkLoad, updateEmployee }) => {
  const classes = useStyles();
  const [employeeIdSelected, setEmployeeIdSelected] = useState(null);
  const [heightList, setHeightList] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const {
    employeeDetail,
    fillEmployeeById,
    listFilterEmployeeActives,
    fillListEmployees,
    changeStateFavorite,
    changeEditingStatus,
    changeOpenRegisterEmployee,
    retireEmployee,
    loadingGetEmployees,
    loadingDetailEmployee,
  } = useEmployees();

  const { height } = useWindowSize();
  const {
    responseCreateProcess,
    saveResponseCreate,
    setReintegreStatus,
  } = useRegisterEmployee();

  useEffect(() => {
    if (
      responseCreateProcess &&
      responseCreateProcess.statusCode &&
      (responseCreateProcess.statusCode === '200' ||
        responseCreateProcess.statusCode === '201')
    ) {
      setOpenCreateModal(true);
    } else {
      setOpenCreateModal(false);
    }
  }, [responseCreateProcess]);

  /**
   * Consulta Información de primer empleado en la lista
   */
  useEffect(() => {
    if (listFilterEmployeeActives?.length > 0) {
      const id = listFilterEmployeeActives[0].id;
      fillEmployeeById(id);
      setEmployeeIdSelected(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFilterEmployeeActives]);

  /**
   * Consulta lista de empleados al completar cargue masivo cambiando el estate de updateEmployee
   */
  useEffect(() => {
    if (updateEmployee) {
      fillListEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEmployee]);

  /**
   * Lee altura de pantalla
   */
  useEffect(() => {
    const windowsHeight = height ?? 0;
    const discount = windowsHeight * 0.19;
    setHeightList(windowsHeight - discount);
  }, [height]);

  /**
   * Escucha evento selección de empleado
   * @param {*} item
   */
  const handleEmployeeSelection = (item) => {
    fillEmployeeById(item.id);
    setEmployeeIdSelected(item.id);
  };

  /**
   * ??
   */
  const closeSuccessModal = () => {
    saveResponseCreate(null);
  };

  /**
   * Abre formulario para edición de empleado e modo reintegro
   */
  const handleReintegrate = () => {
    setReintegreStatus(true);
    changeOpenRegisterEmployee();
    changeEditingStatus(true);
  };

  return (
    <>
      {loadingGetEmployees ? (
        <div style={{ paddingTop: 40 }}>
          <CustomProgress />
        </div>
      ) : (
        <Grid container>
          {listFilterEmployeeActives?.length > 0 ? (
            <>
              <Grid item lg={5} md={6} sm style={{ marginTop: 7 }}>
                {!loadingGetEmployees ? (
                  <ListWithFilter
                    list={listFilterEmployeeActives}
                    textSearch="Buscar empleado por nombre o documento"
                    onClick={(item) => {
                      handleEmployeeSelection(item);
                    }}
                    selectedId={employeeIdSelected}
                    maxHeight={heightList}
                  />
                ) : (
                  <div style={{ paddingTop: 20 }}>
                    <CustomProgress />
                  </div>
                )}
              </Grid>
              <Grid item lg={7} md={6} sm style={{ paddingLeft: '1%' }}>
                <Buttons setBulkLoad={setBulkLoad} />
                {!loadingDetailEmployee &&
                employeeIdSelected === employeeDetail?.id ? ( //Debe ser loadingDetail
                  <>
                    <Detail
                      setContentTab={setContentTab}
                      employeeDetail={employeeDetail}
                      type={TypesTabEmployee.Activos}
                      changeStateFavorite={changeStateFavorite}
                      loadingSpinner={loadingGetEmployees}
                      changeEditingStatus={changeEditingStatus}
                      changeOpenRegisterEmployee={changeOpenRegisterEmployee}
                      retireEmployee={retireEmployee}
                      handleReintegrate={handleReintegrate}
                    />
                  </>
                ) : (
                  <div style={{ paddingTop: 20 }}>
                    <CustomProgress />
                  </div>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={5} md={6} sm></Grid>
              <Grid item lg={7} md={6} sm>
                <Buttons setBulkLoad={setBulkLoad} />
              </Grid>
              <Grid container>
                <Grid item lg={12} xs={12} className={classes.datanotfound}>
                  <img src={DATA_NOT_FOUND_IMAGE} alt="Datos no encontrados" />
                  <div className={classes.datanotfoundText}>
                    No tienes Empleados creados
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}

      <ResponseModal
        modalType="success"
        title="Que buena onda!!!"
        subtitle="Creaste un nuevo empleado"
        body="¡Es bueno tener talento nuevo, sigue así!"
        modalImage="successEmployee"
        open={openCreateModal}
        closeElement="Skip"
        onCloseElement={() => closeSuccessModal()}
      />
    </>
  );
};

export default Actives;
