import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#a2e3a4',
    color: 'black',
    textAlign: 'center',
  },
  action: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 800,
    fontSize: 14,
  },
  message: {
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  },
}));

const CustomContent = withStyles(() => ({
  root: { padding: '10px 16px' },
}))(CardContent);

const CustomTypography = withStyles(() => ({
  root: { paddingBottom: '10px' },
}))(Typography);

const CustomButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 500,
    padding: '2px 20px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#2CC63E',
    borderColor: '#2CC63E',
    borderRadius: '35px',
    color: theme.palette.primaryColor,
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2CC63E',
      borderColor: '#2CC63E',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#2CC63E',
      borderColor: '#2CC63E',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#2CC63E',
      borderColor: '#2CC63E',
    },
  },
}))(Button);

const TooltipMessage = ({ ...props }) => {
  const {
    message,
    title,
    hidden,
    botton,
    href,
    bgColor,
    isPayrool,
    description,
  } = props;
  const classes = useStyles();

  if (!!hidden) return null;

  return (
    <Card
      className={classes.root}
      style={!!bgColor ? { backgroundColor: bgColor } : null}
    >
      <CustomContent>
        <CustomTypography variant="body1">
          <span className={classes.title}>{title}</span>
        </CustomTypography>
        {isPayrool ? (
          <CustomTypography variant="body2" inline="true">
            <span className={classes.message}>
              {message}
              {description}
            </span>
          </CustomTypography>
        ) : (
          <CustomTypography variant="body2" inline="true">
            <span className={classes.message}>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </span>
          </CustomTypography>
        )}
      </CustomContent>
      {props.href === null ||
      props.href === '' ||
      props.href === undefined ? null : (
        <CardActions className={classes.action}>
          <CustomButton size="small" onClick={() => window.open(href)}>
            {!!botton ? botton : 'Mas información'}
          </CustomButton>
        </CardActions>
      )}
    </Card>
  );
};

TooltipMessage.propTypes = {
  labelProps: PropTypes.object,
  id: PropTypes.string,
};

export default TooltipMessage;
