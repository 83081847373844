import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  deleteConceptDocumentAction,
  loadExpenseAction,
  loadIncomeAction,
} from '../../actions/conceptAction';
import {
  clearPayrollPreview,
  getPayrollPreview,
  UpdateObservationPayrollAction,
  updatePayrollStatusAction,
} from '../../actions/payrollReportAction';
import InputForm from '../../components/input/input.component';
import {
  ConfirmAlert,
  WarningAlertPopUp,
} from '../../helpers/alert.helpers';
import { errorToast } from '../../helpers/toast.helper';
import { CollapseFormPanel } from '../collapse/collapseFormPanel';
import { FormatDecimal } from '../common/formatDecimal';
import { ExpenseCard } from '../concepts/expenseCard';
import { ExpenseForm } from '../concepts/expenseForm';
import { IncomeCard } from '../concepts/incomeCard';
import { IncomeForm } from '../concepts/incomeForm';
import ArchiveModal from '../modal/archiveModal';
import CustomProgress from '../Progress/progress.component';
import CustomTooltip from '../tooltip/tooltip.component';
import TooltipMessage from '../tooltip/tootltip-message.component';

const useStyles = makeStyles({
  root: {
    width: '98%',
    height: '67.4vh',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: 15,
    overflow: 'auto',
  },
  name: {
    color: '#F5D13D',
    fontWeight: 700,
    fontSize: 20,
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  value: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 600,
  },
  verticalLine: {
    border: '0.5px solid grey',
    height: '100%',
    width: 1,
    marginLeft: '50%',
  },
  ligthValue: {
    fontWeight: 300,
  },
  flexdisplay: {
    display: 'flex',
  },
  flexCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: '55%',
    flexBasis: '55%',
  },
  icondata: {
    color: '#F5D13D',
    marginRight: 5,
    fontSize: 22,
  },
  iconAction: {
    color: '#FFFFFF',
  },
  iconPreview: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    cursor: 'pointer',
  },
  textIconPreview: {
    color: '#FFFFFF',
    height: '18px',
    lineHeight: '0px',
    letterSpacing: '.5px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    textAlign: 'center',
  },
  span: {
    color: '#FFFFFF',
  },
  italic: {
    fontStyle: 'italic',
    fontSize: 16,
    fontWeight: 400,
    color: '#FFFF',
  },
  titleItems: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#2CC63E',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  titleItemsSecond: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#ED8D84;',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  titleItemsThird: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#F5D13D',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  cardTotal: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 5,
    display: 'grid',
    textAlign: 'center',
    padding: 5,
    marginTop: 10,
  },
  cardConfirm: {
    width: '74%',
    backgroundColor: 'transparent',
    borderRadius: 5,
    display: 'flex',
    textAlign: 'center',
    padding: 5,
    marginTop: 10,
    border: '1px solid gray',
    justifyContent: 'space-between',
  },
  valueTotal: {
    color: '#2CC63E',
    fontSize: 26,
    fontWeight: 700,
  },
  redTotal: {
    color: '#ED8D84',
  },
  legendTotal: {
    color: '#FFFFFF',
    fontWeight: 300,
    fontSize: 12,
    fontStyle: 'italic',
  },
  messageStatus: {
    color: ' #FFFF',
    fontSize: 18,
    width: '100%',
    padding: 13,
  },
  containerProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 200,
  },
});

const CustomFormControlLabel = withStyles({
  disabled: {
    color: '#FFF !important',
  },
  'MuiFormControlLabel-root': {
    color: '#FFF !important',
  },
})(FormControlLabel);

const CustomSwitch = withStyles({
  disabled: {
    color: '#FFF',
  },
  switchBase: {
    color: '#848484',
    '&$checked': {
      color: '#2cc63e',
    },
    '&$checked + $track': {
      backgroundColor: '#848484',
    },
  },
  checked: {},
  track: {},
})(Switch);

const animate = 'animate__animated animate__fadeIn animate__faster';

export const PayrollReportDetail = React.memo(({ empleadoid }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandIncome, setExpandIncome] = useState(false);
  const [expandExpense, setExpandExpense] = useState(false);
  const [observation, setObservation] = useState(null);
  const [payrollError, setPayrollError] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64PDF, setBase64PDF] = useState(null);

  /**
   * Redux
   */
  const {
    incomesPayroll,
    incomeActive,
    loadingDelete: loadingDeleteIncome,
  } = useSelector((s) => s.incomePayrollReducer);

  const {
    expensesPayroll,
    expenseActive,
    loadingDelete: loadingDeleteExpense,
  } = useSelector((s) => s.expensePayrollReducer);

  const {
    loadingConfirm,
    loadingDetail,
    activeSuport,
    loadingObservation,
    payrollPreview,
    loadingPayrollPreview,
  } = useSelector((s) => s.payrollReportReducer);

  /**
   * Memo
   */
  const supportMemo = useMemo(() => {
    return activeSuport;
  }, [activeSuport]);

  const {
    detallenominaid,
    nombrecompleto,
    tipodocumento,
    documento,
    cargo,
    tipocontrato,
    telefono,
    direccion,
    ciudad,
    departamento,
    valornomina,
    estadonominaid,
    observacion,
  } = supportMemo;

  /**
   * Carga información incial de la Bd del atributo observacion y lo setea en observation
   */
  useEffect(() => {
    setPayrollError(false);
    setObservation(observacion);
  }, [observacion, estadonominaid]);

  /**
   * Carga información del Base64 para previsualizar.
   */
  useEffect(() => {
    setBase64PDF(payrollPreview);
  }, [payrollPreview]);

  /**
   * Carga información de ingreso para edición
   * @param {*} income
   */
  const handleUpdateIncome = useCallback(
    (income) => {
      if (estadonominaid === 4) {
        return errorToast(
          'Error al editar ingreso.',
          'No se puede editar un ingreso de una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
        );
      }

      estadonominaid === 3 && setPayrollError(true);
      setExpandIncome(true);
      dispatch(loadIncomeAction(income));
    },

    [estadonominaid, dispatch],
  );

  /**
   * Elimina Ingreso asociado a soporte nomina
   * @param {*} income
   */
  const handleDeleteIncome = useCallback(
    (income) => {
      if (estadonominaid === 4) {
        return errorToast(
          'Error al eliminar ingreso.',
          'No se puede eliminar un ingreso de una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
        );
      }

      const deleteData = (confirmed) => {
        estadonominaid === 3 && setPayrollError(true);
        if (confirmed) {
          dispatch(
            deleteConceptDocumentAction(
              income.identificador,
              detallenominaid,
              true,
            ),
          );
          dispatch(loadIncomeAction(null));
        }
      };
      ConfirmAlert(`¿Estas seguro de eliminar este ingreso?`, deleteData);
    },
    [detallenominaid, dispatch, estadonominaid],
  );

  /**
   * Carga información de deduccion para edición
   * @param {*} expense
   */
  const handleUpdateExpense = (expense) => {
    if (estadonominaid === 4) {
      return errorToast(
        'Error al editar deducciones.',
        'No se puede editar una deducción de una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
      );
    }
    estadonominaid === 3 && setPayrollError(true);
    setExpandExpense(true);
    dispatch(loadExpenseAction(expense));
  };

  /**
   * Elimina Ingreso asociado a soporte nomina
   * @param {*} income
   */
  const handleDeleteExpense = (expense) => {
    if (estadonominaid === 4) {
      return errorToast(
        'Error al eliminar deducciones.',
        'No se puede eliminar una deducción de una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
      );
    }

    const deleteData = (confirmed) => {
      estadonominaid === 3 && setPayrollError(true);
      if (confirmed) {
        dispatch(
          deleteConceptDocumentAction(
            expense.identificador,
            detallenominaid,
            false,
          ),
        );
        dispatch(loadExpenseAction(null));
      }
    };
    ConfirmAlert(`¿Estas seguro de eliminar esta deducción?`, deleteData);
  };

  /**
   * Expande o contrae formulario ingresos, limpia formulario
   */
  const handleExpandIncomePanel = () => {
    if (estadonominaid === 4) {
      return errorToast(
        'Error al añadir ingresos.',
        'No se puede añadir un ingreso en una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
      );
    }

    if (expandIncome === true) {
      dispatch(loadIncomeAction(null));
    }
    setExpandIncome(!expandIncome);
    estadonominaid === 3 && setPayrollError(!payrollError);
  };

  /**
   * Expande o contrae formulario deducciones, limpia formulario
   */
  const handleExpandExpensePanel = () => {
    if (estadonominaid === 4) {
      return errorToast(
        'Error al añadir deducciones.',
        'No se puede añadir una deducción en una nomina pendiente de sincronizar con la DIAN, por favor consulte con soporte.',
      );
    }

    if (expandExpense === true) {
      dispatch(loadExpenseAction(null));
    }

    setExpandExpense(!expandExpense);
    estadonominaid === 3 && setPayrollError(!payrollError);
  };

  /**
   * Cierra formulario deducciones
   */
  const handleCloseIncomePanel = () => {
    setExpandIncome(false);
  };

  /**
   * Cierra formulario deducciones
   */
  const handleCloseExpensePanel = () => {
    setExpandExpense(false);
  };

  /**
   * Confirma soporte de nomina
   * @param {*} param0
   */
  const handleConfirmSupport = ({ target }) => {
    const checked = target.checked;
    dispatch(updatePayrollStatusAction(detallenominaid, checked));
  };

  /**
   * Captura la información del input de observaciones y lo guarda en el estado observation
   * @param {*} target, para obtener el value del input
   */
  const handleChangeObservation = ({ target }) => {
    setObservation(target.value);
  };

  /**
   * Actualiza la bd al perder el foco del input de observaciones,
   * al digitar una cadena de texto diferente a la contenida en la bd
   */
  const handleUpdateObservation = (e) => {
    if (
      observation.trim() === e.target.value.trim() &&
      observation.trim() === observacion.trim()
    )
      return;
    dispatch(UpdateObservationPayrollAction(observation));
  };

  /**
   * Pevisualiza la nómina a transmitir
   */
  const handlePreview = () => {
    if (estadonominaid === 0 || estadonominaid === null) {
      return WarningAlertPopUp(
        'Upss...!',
        'Debes confirmar la nómina para poder previsualizarla.',
      );
    }

    if (expensesPayroll.length === 0 && incomesPayroll.length === 0) {
      return WarningAlertPopUp(
        'Upss...!',
        'El valor total a pagar a este empleado debe ser superior a 0.',
      );
    }
    setOpenModalFile(true);
    dispatch(getPayrollPreview(detallenominaid));
  };

  /**
   * Cierra el modal del preview de nómina
   */
  const handleCloseModalFile = () => {
    setOpenModalFile(false);
    dispatch(clearPayrollPreview());
  };

  return (
    <>
      {loadingDetail ? (
        <div className={classes.containerProgress}>
          <div style={{ display: 'grid' }}>
            <CustomProgress />
            <p style={{ color: '#FFFF' }}>{' Consultando... '}</p>
          </div>
        </div>
      ) : (
        <>
          {detallenominaid && (
            <div className={`${classes.root} ${animate}`}>
              <Grid container>
                <Grid item lg={5} xs={5}>
                  <Grid container>
                    <Grid item lg={12} xs={12}>
                      <div className={classes.name}> {nombrecompleto}</div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <div
                        className={classes.value}
                      >{`${tipodocumento} ${documento}`}</div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <div className={classes.value}>{cargo}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} xs={2}>
                  <div className={classes.verticalLine}></div>
                </Grid>
                <Grid item lg={5} xs={5}>
                  <Grid container>
                    <Grid item lg={12} xs={12} className={classes.flexdisplay}>
                      <DescriptionIcon
                        className={classes.icondata}
                      ></DescriptionIcon>
                      <span className={classes.italic}>{tipocontrato}</span>
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.flexdisplay}>
                      <PhoneIcon className={classes.icondata} />
                      <span className={classes.italic}>{telefono}</span>
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.flexdisplay}>
                      <LocationOnIcon className={classes.icondata} />
                      <span className={classes.italic}>
                        {`${direccion} ${ciudad} - ${departamento}`}
                      </span>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      className={classes.flexdisplay}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className={classes.titleItems}>{`Ingresos`}</div>
              <Grid container spacing={2}>
                {estadonominaid !== 2 && estadonominaid !== 6 && (
                  <Grid item lg={12} xs={12}>
                    <CollapseFormPanel
                      component={
                        <IncomeForm
                          handleClose={handleCloseIncomePanel}
                          type={'document'}
                          detallenominaid={detallenominaid}
                        />
                      }
                      title={'Añadir ingresos'}
                      titleOpen={
                        !!incomeActive ? 'Editar ingreso' : 'Nuevo ingreso'
                      }
                      open={expandIncome}
                      onClick={() => handleExpandIncomePanel()}
                    />
                  </Grid>
                )}

                {incomesPayroll.map((income) => (
                  <Grid key={income.identificador} item lg={6} xs={12}>
                    <IncomeCard
                      {...income}
                      handleUpdate={() => handleUpdateIncome(income)}
                      handleDelete={() => handleDeleteIncome(income)}
                      disabled={estadonominaid === 2 || estadonominaid === 6}
                      loading={loadingDeleteIncome}
                    />
                  </Grid>
                ))}
              </Grid>

              <div className={classes.titleItemsSecond}>{`Deducciones`}</div>
              <Grid container spacing={2}>
                {estadonominaid !== 2 && estadonominaid !== 6 && (
                  <Grid item lg={12} xs={12}>
                    <CollapseFormPanel
                      component={
                        <ExpenseForm
                          handleClose={handleCloseExpensePanel}
                          type={'document'}
                          detallenominaid={detallenominaid}
                        />
                      }
                      title={'Añadir Deducciones'}
                      titleOpen={
                        !!expenseActive ? 'Editar Deduccion' : 'Nueva Deduccion'
                      }
                      open={expandExpense}
                      onClick={() => handleExpandExpensePanel()}
                    />
                  </Grid>
                )}

                {expensesPayroll.map((expense) => (
                  <Grid item lg={8} xs={12} key={expense.identificador}>
                    <ExpenseCard
                      data={expense}
                      handleUpdate={() => handleUpdateExpense(expense)}
                      handleDelete={() => handleDeleteExpense(expense)}
                      disabled={estadonominaid === 2 || estadonominaid === 6}
                      loading={loadingDeleteExpense}
                    />
                  </Grid>
                ))}
              </Grid>

              <div className={classes.titleItemsThird}>Observaciones</div>
              {loadingObservation ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item lg={11} xs={11}>
                    <InputForm
                      name="observation"
                      maxLength={500}
                      label={'Observaciones'}
                      multiline={true}
                      disabled={true}
                      value={observation}
                      onChange={handleChangeObservation}
                      onBlur={handleUpdateObservation}
                    />
                  </Grid>
                  <Grid item lg={1} xs={1}>
                    <CustomProgress />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <InputForm
                      name="observation"
                      maxLength={500}
                      label={'Observaciones'}
                      multiline={true}
                      disabled={
                        estadonominaid === 0 ||
                        estadonominaid === 1 ||
                        payrollError === true ||
                        estadonominaid === null
                          ? false
                          : true
                      }
                      value={observation}
                      onChange={handleChangeObservation}
                      onBlur={handleUpdateObservation}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={1}
                style={{ marginTop: 20, justifyContent: 'space-around' }}
              >
                <Grid item lg={6} xs={6} className={classes.flexCard}>
                  {(!estadonominaid ||
                    estadonominaid === 0 ||
                    payrollError === true ||
                    estadonominaid === 1) && (
                    <section
                      className={classes.iconPreview}
                      onClick={handlePreview}
                    >
                      <Tooltip title="Visualizar">
                        <IconButton>
                          <VisibilityIcon className={classes.iconAction} />
                        </IconButton>
                      </Tooltip>
                      <span className={classes.textIconPreview}>
                        Previsualizar
                      </span>
                    </section>
                  )}
                  <div className={`${classes.cardConfirm} ${animate}`}>
                    {(!estadonominaid ||
                      estadonominaid === 0 ||
                      payrollError === true ||
                      estadonominaid === 1) && (
                      <>
                        <CustomFormControlLabel
                          style={{ color: 'white' }}
                          control={
                            loadingConfirm ? (
                              <div style={{ padding: 5 }}>
                                <CustomProgress size={15} />
                              </div>
                            ) : (
                              <CustomSwitch
                                checked={estadonominaid === 1}
                                disabled={
                                  (estadonominaid !== 0 &&
                                    estadonominaid !== 1 &&
                                    payrollError !== true &&
                                    !estadonominaid !== true) ||
                                  valornomina <= 0
                                }
                                onChange={handleConfirmSupport}
                                name="checkedA"
                              />
                            )
                          }
                          label="Nómina confirmada"
                          labelPlacement="start"
                        />
                        <div style={{ padding: '10px 0px' }}>
                          <CustomTooltip
                            message={
                              <TooltipMessage
                                title="¿Qué es esto?"
                                message="Una vez termines de diligenciar los Ingresos y Deducciones activa la opción, esto indicará que se encuentra lista para transmitir."
                                href={null}
                              />
                            }
                          />
                        </div>
                      </>
                    )}
                    {estadonominaid === 2 && (
                      <span className={`${classes.messageStatus} ${animate}`}>
                        Transmitido a DIAN
                      </span>
                    )}
                    {estadonominaid === 3 && payrollError === false && (
                      <span className={`${classes.messageStatus} ${animate}`}>
                        Error en documento
                      </span>
                    )}
                    {(estadonominaid === 4 || estadonominaid === 6) && (
                      <span className={`${classes.messageStatus} ${animate}`}>
                        Sincronizando con DIAN
                      </span>
                    )}
                    {estadonominaid === 5 && (
                      <span className={`${classes.messageStatus} ${animate}`}>
                        En proceso de transmisión
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  style={{ flexBasis: '44%', maxWidth: '44%' }}
                >
                  <div className={`${classes.cardTotal} ${animate}`}>
                    <span
                      className={`${classes.valueTotal} ${
                        valornomina < 0 ? classes.redTotal : ''
                      }`}
                    >
                      <FormatDecimal prefix={'$'} value={valornomina} />
                    </span>
                    <span className={classes.legendTotal}>
                      Valor total a pagar a este empleado
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          {/* Modal previsualizar PDF */}
          <ArchiveModal
            loading={loadingPayrollPreview}
            open={openModalFile}
            pdfBase64={base64PDF}
            handleClose={handleCloseModalFile}
          />
        </>
      )}
    </>
  );
});
