import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { getIncomesAndExpensesAction } from '../../actions/configAction';
import {
  cleanPayrollAdjustmentAction,
  getTypeLicenseAction,
  registerPayrollAdjustmentAction,
  updateTotalPayrollAction,
} from '../../actions/payrollAdjustmentAction';
import { LICENSE_SHOP_URL } from '../../config/config';
import { ConfirmAlert, WarningAlert } from '../../helpers/alert.helpers';
import { getCustomFormatDate } from '../../helpers/functions.helpers';
import { generateRequestPayrollAdjustment } from '../../helpers/generateRequest';
import ButtonPrimary from '../button/buttonPrimary.component';
import InputForm from '../input/input.component';
import ResponseModal from '../modal/responseModal.component';
import CustomProgress from '../Progress/progress.component';
import { AdjustmentDetail } from './adjustmentDetail';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#242424',
    borderRadius: 5,
    height: '93.4vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'justify',
  },
  actions: {
    backgroundColor: '#393939',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    textAlign: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    height: 80,
    padding: 15,
  },
  button: {
    borderRadius: '15px !important',
    padding: 0,
    cursor: 'pointer',
  },
  label: {
    flexDirection: 'column',
    fontSize: 12,
    color: 'white',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 500,
  },
  containerTitle: {
    display: 'grid',
    textAlign: 'center',
    padding: 10,
  },
  line: {
    border: '0.5px solid #50C1EE',
    marginTop: 10,
    marginBottom: 10,
  },
  lineGray: {
    border: '0.5px solid rgba(255, 255, 255, 0.2)',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    display: 'flex',
  },
  containerList: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 5,
    height: '50%',
    padding: 5,
  },
  containerItem: {
    color: '#FFFFFF',
    width: '100%',
    backgroundColor: '#16B1F3',
    borderRadius: 5,
    padding: 15,
    fontSize: 18,
    fontWeight: 700,
  },
});

export const AdjustmentManage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [modalData, setModalData] = useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
  });

  const {
    nominaid,
    soporteid,
    consecutivo,
    prefijo,
    primernombre,
    otronombre,
    primerapellido,
    segundoapellido,
    periodo,
    valornomina,
    observacion,
    empresaid,
    empleadoId,
  } = useSelector((s) => s.payrollAdjustmentReducer.payrollData);
  const { loadingRegisterAdjustment } = useSelector(
    (s) => s.payrollAdjustmentReducer,
  );
  const { incomesPayroll } = useSelector((s) => s.incomePayrollReducer);
  const { expensesPayroll } = useSelector((s) => s.expensePayrollReducer);

  /*Inicia componente */
  useEffect(() => {
    dispatch(getIncomesAndExpensesAction());

    return () => {
      dispatch(cleanPayrollAdjustmentAction());
    };
  }, [dispatch]);

  /**
   * Redirecciona si no existe información de nomina
   */
  useEffect(() => {
    if (!soporteid) {
      history.push('/payroll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soporteid]);

  /**
   * Recalcula valor de nomina
   */
  useEffect(() => {
    dispatch(updateTotalPayrollAction(incomesPayroll, expensesPayroll));
  }, [incomesPayroll, expensesPayroll, dispatch]);

  useEffect(() => {
    if (loadingRegisterAdjustment) {
      setModalData({
        type: 'success',
        title: 'Colnomina',
        subtitle: 'Generando documento, por favor espere...',
        body: (
          <>
            {' '}
            <CustomProgress />
            {'Procesando'}{' '}
          </>
        ),
        modalImage: 'noimagen',
        open: true,
        closeElement: '',
        onCloseElement: 0,
        closeFromModalBody: false,
      });
    }
  }, [loadingRegisterAdjustment]);

  /**
   * Cancela edición
   */
  const handleCancel = () => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(cleanPayrollAdjustmentAction());
      }
    };
    ConfirmAlert(
      `¿Estás seguro de que deseas cancelar la edición? Si lo haces perderás la información modificada.`,
      deleteData,
    );
  };

  const handleResponseModal = () => {
    setModalData({
      type: '',
      title: '',
      subtitle: '',
      body: '',
      modalImage: '',
      open: false,
      closeElement: '',
      onCloseElement: 0,
      closeFromModalBody: false,
    });
    dispatch(cleanPayrollAdjustmentAction());
    history.push('/payroll');
  };

  /**
   * * Consultar tipo de licencia
   */

  const handleTypeLicense = () => {
    const license = {
      year: Number(moment(periodo).format('YYYY')),
      month: Number(moment(periodo).format('MM')),
      empresaid,
      empleadoId,
    };
    dispatch(getTypeLicenseAction(license, readResponseLicense, sendPayroll));
  };

  const readResponseLicense = (response) => {
    setModalData({
      modalType: 'warning',
      title: 'Tu plan de Colfactura nómina venció',
      body: (
        <div>
          <p
            style={{
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {`Licencia Base de ${response?.claim} vencida.. La nomina Original fue transmitida por licencia de ${response?.claim}, es necesario renovarla para que puedas seguir emitiendo documentos.`}
          </p>
          <p
            style={{
              marginTop: 10,
              fontWeight: 700,
              fontSize: 25,
            }}
          >
            {'Si quieres comprar tu nuevo plan, haz clic en Comprar'}
          </p>
        </div>
      ),
      modalImage: 'warning',
      open: true,
      textButton: 'Comprar',
      closeElement: 'Skip',
      urlButton: `${LICENSE_SHOP_URL}`,
    });
  };

  /**
   * *Envia información de nomina para reporte
   */
  const handleSavePayroll = async (e) => {
    e.preventDefault();
    if (!observacion) {
      WarningAlert('Upsss...!!!', 'Debes diligenciar el campo observaciones.');
      return;
    }

    handleTypeLicense();

    if (valornomina <= 0) {
      WarningAlert(
        'Upsss...!!!',
        'No es posible transmitir una nota de ajuste con valor igual o menor a cero.',
      );
      return;
    }
  };

  const sendPayroll = async () => {
    setModalData({
      ...modalData,
      open: false,
    });
    const confirmData = (confirmed) => {
      if (confirmed) {
        var data = generateRequestPayrollAdjustment(
          soporteid,
          incomesPayroll,
          expensesPayroll,
          observacion,
          valornomina,
          nominaid,
        );

        dispatch(registerPayrollAdjustmentAction(data, readResponseRegister));
      }
    };
    ConfirmAlert(
      `¿Estás seguro que deseas modificar el documento soporte No.
      ${prefijo ?? ''}${
        consecutivo ?? ''
      } ? Este remplazará el documento original, no obstante podrás encontrarlo en el tab de "Editados" `,
      confirmData,
    );
  };

  const readResponseRegister = (data) => {
    if (
      (data?.httpCode === 200 || data?.httpCode === 201) &&
      data?.result?.codigoRespuesta === 'A10'
    ) {
      setModalData({
        type: 'success',
        title: 'Fenomenal!!!',
        subtitle: 'Has generado un nuevo documento',
        body: (
          <div>
            <div>{'Proceso Exitoso'}</div>
            <div className="fenomenalWhiteLittle">
              {data?.statusMessage}{' '}
              <span className="fenomenalBlueLittle">
                No. {data?.result?.numero ?? ''}
              </span>
            </div>
          </div>
        ),
        modalImage: 'success',
        open: true,
        closeElement: 'Saltar',
        onCloseElement: 1,
        closeFromModalBody: true,
      });

      return;
    }

    //Leer Error
    let errorMessage = data?.result?.descripcionRespuesta ?? null;
    errorMessage = errorMessage ?? data?.data?.result?.descripcionRespuesta;

    setModalData({
      type: 'warning',
      title: 'Upss...!!!',
      subtitle: 'Ocurrió algo inesperado',
      body: (
        <div>
          {errorMessage ??
            'Error no identificado, por favor contacta al administrador.'}
        </div>
      ),
      modalImage: 'warning',
      open: true,
      closeElement: 'Cerrar',
      onCloseElement: 1,
      closeFromModalBody: false,
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Grid container justify="center" justifyContent="center">
              <Grid item lg={4} xs={4}>
                <div className={classes.containerTitle}>
                  <span className={classes.title}>Nota de ajuste</span>
                  <span className={classes.line}></span>
                  <span className={classes.title}>
                    {`Consecutivo: ${prefijo ?? ''}${consecutivo ?? ''}`}
                  </span>
                </div>
              </Grid>
              <Grid item lg={10} xs={10}>
                <span className={classes.lineGray}></span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} xs={5}>
            <InputForm
              name="mes"
              maxLength={500}
              label={'Mes/Año'}
              value={` ${
                !!periodo ? getCustomFormatDate(new Date(periodo)) : ''
              }`}
              disabled={false}
              onChange={() => {}}
            />
            <div className={classes.containerList}>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <div className={classes.containerItem}>
                    <span>
                      {`${primernombre ?? ''}
                     ${otronombre ?? ''} ${primerapellido ?? ''} ${
                        segundoapellido ?? ''
                      }`}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={7} xs={7}>
            <AdjustmentDetail />
          </Grid>
        </Grid>
        <div className={classes.actions}>
          <Grid container>
            <Grid item lg={4} xs={4}></Grid>
            <Grid item lg={4} xs={4}>
              <IconButton
                classes={{ root: classes.button, label: classes.label }}
                variant="raised"
                disableRipple={true}
                onClick={handleCancel}
              >
                <ClearIcon />
                <span style={{ marginTop: 5 }}>Cancelar</span>
              </IconButton>
            </Grid>
            <Grid item lg={4} xs={4}>
              <ButtonPrimary
                text={'Guardar y Transmitir a DIAN'}
                onClick={handleSavePayroll}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={() => handleResponseModal()}
        urlButton={modalData?.urlButton}
        textButton={modalData?.textButton}
      />
    </>
  );
};
