import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import InputIcon from '../../../input/inputIcon.component';
import CustomProgress from '../../../Progress/progress.component';
import PopoverModal from '../../../../components/modal/popoverModal.component';
import RcCalendar from '../../../../components/datepicker/rcdatepicker.component';
import StyledCheckbox from '../../../../components/checkForm/styledCheckbox.component';

import {
  getEmployeesIntegrationAction,
  setSelectedEmployee,
} from '../../../../actions/integrationAction';

import useNearScreen from '../../../../hooks/useNearScreen';
import { format } from 'date-fns';
import { WarningAlert } from '../../../../helpers/alert.helpers';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    color: 'white',
  },
  containerList: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '86.8vh',
    minHeight: '86.8vh',
    backgroundColor: theme.palette.secundaryBackgroundColor,
    color: theme.palette.primaryColor,
  },
  alignCenter: {
    textAlign: 'center',
  },
  letter: {
    color: '#FFFFFF',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  item: {
    marginBottom: 5,
    fontWeight: 'bold',
    '&.MuiListItem-root:hover': {
      borderRadius: 5,
      border: '1px solid #16B1F6',
    },
    '&.Mui-selected': {
      backgroundColor: '#16B1F3',
      borderRadius: 5,
    },
  },
  itemText: {
    fontWeight: 600,
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: '150vh',
  },
  emptylist: {
    color: 'white',
  },
  itemLoading: {
    textAlign: 'center',
    width: '100%',
  },
}));

const initialFilter = {
  Skip: 0,
  Limit: 30,
  IdEmpresa: 3,
  Fechadesde: null,
  FechaHasta: null,
  Filtro: null,
  Aprobado: null,
  Rechazado: null,
  Retirado: null,
  LoadMore: false,
};

export const IntegrationList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialFilter);
  const [filterText, setFilterText] = useState('');
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [rangeDatesFilter, setRangeDatesFilter] = useState({
    dateFrom: format(new Date(), 'yyyy/MM/dd'),
    dateTo: format(new Date(), 'yyyy/MM/dd'),
  });
  const { employeesIntegration, loadingEmployeesIntegration } = useSelector(
    (s) => s.integrationReducer,
  );
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedTypeDocument, setSelectedTypeDocument] = useState(null);

  // Infinity Scroll
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: employeesIntegration.length === 0 ? null : externalRef,
    distance: '150px',
    once: false,
  });

  /**
   * Escucha cambio en filtros, cosulta resumen
   */
  useEffect(() => {
    dispatch(
      getEmployeesIntegrationAction(
        filter,
        setSelectedDocument,
        setSelectedTypeDocument,
      ),
    );
  }, [filter, dispatch]);

  /**
   * Paginado, Consulta nuevo lote  empleados
   */
  useEffect(() => {
    if (isNearScreen) {
      //No realiza mas consultas si no hay mas registros en BD
      if (
        !!employeesIntegration &&
        employeesIntegration?.length >= employeesIntegration[0].totalregistros
      )
        return;

      setFilter((prev) => ({
        ...prev,
        Skip: employeesIntegration.length,
        LoadMore: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * Sincroniza valor de filtro por texto con state
   * @param {*} param0
   */
  const handleChangeFilterText = ({ target }) => {
    setFilterText(target.value);

    if (target.value.length === 0) {
      setFilter((prev) => ({
        ...prev,
        Filtro: null,
        Skip: 0,
        LoadMore: false,
      }));
    }
  };

  /**
   * Filtra listado empleados por texto
   */
  const handleFilterByText = () => {
    setFilter((prev) => ({
      ...prev,
      Filtro: filterText,
      Skip: 0,
      LoadMore: false,
    }));
  };

  /**
   * Cierra modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Abre modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * Valida rango de fechas seleccionado,
   * setea información de filtro para nueva consulta
   */
  const handleFilterByDate = () => {
    const { dateFrom, dateTo } = rangeDatesFilter;

    if (dateFrom === '' || dateTo === '') {
      WarningAlert(
        'Upsss...!!',
        'Debes seleccionar un rango de fechas valido.',
      );
      return;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      WarningAlert(
        'Upsss...!!',
        'La fecha inicial no puede ser mayor a la final',
      );
      return;
    }

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: dateFrom,
      FechaHasta: dateTo,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Sincroniza fecha inicial filtro con state
   * @param {*} date Fecha
   */
  const handleChangeInitialDate = (date) => {
    console.log(date);
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: date,
    }));
  };

  /**
   * Sincroniza fecha final filtro con state
   * @param {*} date Fecha
   */
  const handleChangeEndDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateTo: date,
    }));
  };

  /**
   * Limpia filtro rango fechas
   */
  const cleanFilterDate = () => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: format(new Date(), 'yyyy/MM/dd'),
      dateTo: format(new Date(), 'yyyy/MM/dd'),
    }));

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: null,
      FechaHasta: null,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Limpia filtro por estado
   */
  const cleanFilterStatus = () => {
    setFilter((prevState) => ({
      ...prevState,
      Skip: 0,
      Aprobado: null,
      Rechazado: false,
      Retirado: false,
      LoadMore: false,
    }));
    handleCloseModalChecks();
  };

  /**
   * Cierra modal filtro estado
   * @param {*} event Evento
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Abre modal filtro estado
   * @param {*} event Evento
   */
  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  /**
   * Filtra listado reportados por estado
   * @param {*} event
   */
  const handleCheckFilters = ({ target }) => {
    const name = target.name;
    const checked = target.checked;

    if (name === 'accepted') {
      setFilter((prev) => ({
        ...prev,
        Aprobado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }

    if (name === 'rejected') {
      setFilter((prev) => ({
        ...prev,
        Rechazado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }

    if (name === 'retired') {
      setFilter((prev) => ({
        ...prev,
        Retirado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }
  };

  /**
   * Consulta detalle de documento
   * @param {*} id Identificador documento
   */
  const handleChangeSelection = useCallback(
    (item) => {
      dispatch(setSelectedEmployee(item));
      setSelectedDocument(item.documentotrabajador);
      setSelectedTypeDocument(item.tipodocumentotrabajador);
    },
    [dispatch],
  );

  /**
   * Panel selección filtro rango fechas
   */
  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateFrom}
              className={'colorCalendarRC'}
              id={'txtfechainicio'}
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateTo}
              className={'colorCalendarRC'}
              id={'txtfechafin'}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={handleFilterByDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  // Modal search filters
  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Aprobado}
              onChange={handleCheckFilters}
              name="accepted"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Aceptados
          </Grid>

          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Rechazado}
              onChange={handleCheckFilters}
              name="rejected"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Rechazados
          </Grid>

          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Retirado}
              onChange={handleCheckFilters}
              name="retired"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Retirados
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilterStatus}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <List
            className={classes.containerList}
            component="div"
            aria-label="main mailbox folders"
          >
            <ListItem>
              <Grid container spacing={1}>
                <Grid item lg={12} xs={12} style={{ display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <InputIcon
                      name={'txtFilter'}
                      label={'Buscar'}
                      value={filterText}
                      onChange={handleChangeFilterText}
                      onSearch={handleFilterByText}
                      maxLength={100}
                      disabled={false}
                    />
                  </div>
                  <Tooltip title="Filtrar por fechas">
                    <IconButton onClick={handleOpenModalDates} disabled={false}>
                      <EventIcon className={classes.buttonIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Filtrar por estado">
                    <IconButton
                      className={classes.buttonFilterStatus}
                      onClick={handleOpenModalChecks}
                      disabled={false}
                    >
                      <img alt={'Filtrar'} src={filterImage}></img>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ListItem>

            {!loadingEmployeesIntegration && employeesIntegration.length === 0 && (
              <ListItem className={classes.emptylist}>
                <span>No se han encontrado resultados...</span>
              </ListItem>
            )}

            {employeesIntegration.map((item) => (
              <ListItem
                key={item.documentotrabajador}
                className={classes.item}
                button
                selected={
                  selectedDocument === item.documentotrabajador &&
                  selectedTypeDocument === item.tipodocumentotrabajador
                }
                onClick={() => handleChangeSelection(item)}
              >
                <ListItemText className={classes.itemText} component={'div'}>
                  <div className={classes.textOverflow}>
                    {item.nombrestrabajador}
                  </div>
                </ListItemText>
              </ListItem>
            ))}
            <div ref={externalRef} id="visor" />

            {loadingEmployeesIntegration && (
              <div className={classes.itemLoading}>
                <CustomProgress />
              </div>
            )}
          </List>
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={() => setModalDatesAnchorEl(null)}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={''}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
    </>
  );
};
