import React, { useState, useEffect } from 'react';

import contractIcon from '../../../../images/icons/contractYellowIcon.png';
import PhoneIcon from '../../../../images/icons/phoneYellowIcon.png';
import CustomProgress from '../../../Progress/progress.component';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import '../../../../styles/invoice.css';
import { DetailList } from '../../removable/detail/detailList';
import { useWindowSize } from '../../../../hooks/useResizeWindows';

const useStyles = makeStyles({
  container: {
    borderRadius: '5px',
    backgroundColor: '#4B4B4B',
    marginTop: '1%',
    padding: '1%',
    margin: '1% 0 0 0',
    color: '#fff',
    '& i': {
      width: '16px',
      marginLeft: '1%',
      fontSize: '16px',
    },
    '& img': {
      color: '#F5D13D',
      width: '13px',
    },
    '& h2': {
      color: '#F5D13D',
      fontWeight: '600',
      margin: '0 auto',
      fontSize: '20px',
    },
    '& p': {
      color: '#FFF',
      margin: '0 auto',
      fontSize: '1.1em',
    },
  },
  headerInfo: {
    padding: '1%',
    alignContent: 'center',
    overflow: 'auto',
    display: 'block',
  },
  bottom: {
    position: 'relative',
    width: '50%',
    textAlign: 'center',
    margin: '2% auto',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: '40%',
    height: '50px',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
    margin: '0 auto',
  },
  titleDetail: {
    fontSize: '20px',
    '& p ': {
      margin: '0 1%',
    },
  },
  valCanceledDetail: {
    color: '#2CC63E',
    fontSize: '20px',
  },
  listDetails: {
    '& p': {
      margin: '1% 0 ',
    },
  },
  yellowColor: {
    color: '#F6D746',
  },
  greenColor: {
    color: '#2CC63E',
  },
  textButtons: {
    color: '#ffffff',
    fontFamily: 'Muli',
    fontSize: '10px',
    letterSpacing: '0',
    textAlign: 'center',
  },
  detailHeader: {
    color: '#fff',
    '& i': {
      width: '16px',
      marginLeft: '1%',
      fontSize: '16px',
    },
    '& img': {
      color: '#F5D13D',
      width: '13px',
    },
    '& h2': {
      color: '#F5D13D',
      fontWeight: '600',
      margin: '0 auto',
      fontSize: '20px',
    },
    '& p': {
      color: '#FFF',
      margin: '0 auto',
      fontSize: '1.1em',
    },
  },
  incomeTitle: {
    borderRadius: '5px',
    backgroundColor: 'rgba(18,18,18,0.3)',
    marginBottom: '0.5em',
    textAlign: 'center',
    margin: '2%',
    '& h2': {
      color: '#2CC63E',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
  },
  letterSearch: {
    color: '#848484',
  },
  paddingIcons: {
    paddingTop: '20%',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  lastSupports: {
    color: '#2CC63E',
    backgroundColor: '#1212124D',
    borderRadius: '5px',
    margin: '1%',
    padding: '1%',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2em',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

const Detail = React.memo(({ detailSupports, loadingSupportsDetail }) => {
  const classes = useStyles();
  const [heightDetail, setHeightDetail] = useState(0);

  const { height } = useWindowSize();

  useEffect(() => {
    const windowsHeight = height ?? 0;
    const discount = windowsHeight * 0.141;
    setHeightDetail(windowsHeight - discount);
  }, [height]);

  return (
    <>
      {!loadingSupportsDetail && !!detailSupports ? (
        <Grid
          container
          className={classes.container}
          style={{ minHeight: heightDetail, maxHeight: heightDetail }}
        >
          <Grid item md={6} style={{ paddingLeft: '1em' }}>
            <h2 style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
              {`${detailSupports.primernombre} ${detailSupports.primerapellido}`}
            </h2>
            <p>{`${detailSupports.tipodocumento} ${detailSupports.documento}`}</p>
            <p>{detailSupports.cargo} </p>
          </Grid>
          <Grid
            item
            md={1}
            style={{
              maxWidth: '20px',
            }}
          >
            <div
              style={{
                background: 'rgba(255,255,255,0.2)',
                width: '2px',
                height: '100%',
              }}
            ></div>
          </Grid>
          <Grid item md={5}>
            <Grid xs={12} container direction="row">
              <Grid item style={{ marginRight: '2%' }}>
                <img src={contractIcon} alt="Logo" />
              </Grid>
              <Grid
                item
                style={{
                  width: '90%',
                }}
              >
                <span>{detailSupports.tipocontrato}</span>
              </Grid>
            </Grid>
            <Grid xs={12} container direction="row">
              <Grid item style={{ marginRight: '2%' }}>
                <img src={PhoneIcon} alt="Logo" />
              </Grid>
              <Grid
                item
                style={{
                  width: '90%',
                }}
              >
                <span style={{}}>
                  {'Salario : ' +
                    '$ ' +
                    new Intl.NumberFormat('es-CO').format(
                      detailSupports.salario,
                    )}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12}>
            <div className={classes.lastSupports}>Soportes de nómina</div>
            <DetailList empleadoId={detailSupports.id} editados={true} />
          </Grid>
        </Grid>
      ) : (
        <div style={{ paddingTop: 20 }}>
          <CustomProgress />
        </div>
      )}
    </>
  );
});

export default Detail;
