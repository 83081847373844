/* eslint-disable array-callback-return */
import axios from 'axios';
import {
  GET_STATES,
  GET_CITIES,
  GET_CITIES_STATE,
  GET_ALL_CITIES,
  GET_DOCUMENT_TYPES,
  GET_REGIME_TYPES,
  GET_MENU,
  GET_COMPONENTS,
  GET_PAYMENT_METHODS,
  GET_CITIES_COUNTRY,
  GET_MENU_COMPONENT,
  GET_FREQUENT_QUESTIONS,
  GET_REPORT_PROBLEM,
  GET_SECURITY_POLICY,
  SEND_MAIL,
  GET_QUESTION_FILTER,
  GET_REASON,
  SEND_MAIL_CONTACT,
  GET_WORKER_TYPES,
  GET_CONTRACT_TYPES,
  GET_PAYMENT_PERIOD,
  GET_INCOMES_EXPENSES,
  GET_BANKS,
  GET_RETIRE_CAUSES,
  types,
} from './types';
import { API_ENDPOINT_CONFIGURATION, defaultHeaders } from '../config/config';
import { capitalizeUpperLetters } from '../utils/general';
import {
  fullNameUser,
  emailUser,
  idUser,
  businessName,
  nitCompany,
} from './authActions';
import { axiosApiInstance } from '../config/axios-instance';
import { showErrorMessage } from '../utils/showErrorService';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/confignomina/api`;

var states = [];
var cities = [];
var documentTypes = [];
var regimeTypes = [];
var menus = [];
var paymentMethods = [];
var allCitiesCountry = [];
var menuComponent = [];
var allFrequentQuestions = [];
var dataIssue = [];
var dataReason = [];
var contractTypes = [];
var paymentPeriods = [];
var banks = [];

/**
 * Obtiene listado de departamentos por identificador de pais,
 * valida si ya se ha consultado información de api, evitando rescosultas inecesarias
 */
export const getStatesAction = () => async (dispatch) => {
  if (states.length > 0) {
    dispatch({
      type: GET_STATES,
      payload: states,
    });
  } else {
    try {
      const url = `${urlConfiguration}${'/DaneDepartamento/GetDepartamentoByPaisId'}`;
      let config = defaultHeaders();
      Object.assign(config.headers, {
        paisid: 1,
      });

      var res = await axios.get(url, config);

      states = res.data.map((item) => {
        return {
          ...item, // Debe ir todo el objeto
          value: item.id.toString(),
          text: item.descripcion.toUpperCase(),
        };
      });

      dispatch({
        type: GET_STATES,
        payload: states,
      });
    } catch (err) {
      showErrorMessage(err, 'Error consultando listado de departamentos');
    }
  }
};

export const getCitiesAction = (departamentoid) => async (dispatch) => {
  if (cities.length > 0) {
    dispatch({
      type: GET_CITIES,
      payload: cities.filter(
        (c) => c.departamentoid === parseInt(departamentoid, 10),
      ),
    });
  } else {
    const url = `${urlConfiguration}${'/DaneCiudad/DaneCiudad'}`;
    await axios
      .get(url, defaultHeaders())
      .then((res) => {
        cities = res.data.map((item) => {
          return {
            ...item,
            value: item.id.toString(),
            text: capitalizeUpperLetters(item.descripcion),
            departamentoid: item.departamentoid,
          };
        });
        dispatch({
          type: GET_CITIES,
          payload: cities.filter(
            (c) => c.departamentoid === parseInt(departamentoid, 10),
          ),
        });
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando listado de ciudades');
      });
  }
};

/**
 * Obtiene listado de ciudades de un pais
 */
export const getAllCitiesAction = () => async (dispatch) => {
  if (allCitiesCountry.length > 0) {
    dispatch({
      type: GET_CITIES_COUNTRY,
      payload: allCitiesCountry,
    });
  } else {
    try {
      const url = `${urlConfiguration}${'/DaneCiudad/DaneCiudad'}`;
      var res = await axios.get(url, defaultHeaders());

      allCitiesCountry = res.data.map((item) => {
        return {
          ...item,
          value: item.id.toString(),
          text: item.descripcion,
          departamentoid: item.departamentoid,
        };
      });

      dispatch({
        type: GET_CITIES_COUNTRY,
        payload: allCitiesCountry,
      });
    } catch (err) {
      showErrorMessage(err, 'Error consultando listado de ciudades');
    }
  }
};

export const getCities = (departamentoid) => async (dispatch) => {
  if (cities.length > 0) {
    const allCities = cities.filter(
      (c) => c.departamentoid === Number.parseInt(departamentoid),
    );
    dispatch({
      type: GET_CITIES_STATE,
      payload: allCities.map((city) => {
        return {
          ...city,
          value: city.id.toString(),
          text: capitalizeUpperLetters(city.descripcion),
          departamentoid: city.departamentoid,
        };
      }),
    });
  } else {
    const url = `${urlConfiguration}${'/DaneCiudad/DaneCiudad'}`;
    await axios
      .get(url)
      .then((res) => {
        cities = res.data;
        dispatch({
          type: GET_ALL_CITIES,
          payload: res.data,
        });
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando listado de ciudades');
      });
  }
};

/**
 * Consulta listado tipos de identificación disponibles,
 * sobrecarga consulta API
 */
export const getDocumentTypesAction = () => async (dispatch) => {
  if (documentTypes.length > 0) {
    dispatch({
      type: GET_DOCUMENT_TYPES,
      payload: documentTypes,
    });
  } else {
    const url = `${urlConfiguration}${'/DianTipDocIdentidad/documentType'}`;
    await axios
      .get(url, defaultHeaders())
      .then((res) => {
        documentTypes = res.data.map((item) => {
          return {
            value: item.id.toString(),
            text: item.descripcion,
          };
        });
        documentTypes = documentTypes.filter((d) => d.value !== '9');

        dispatch({
          type: GET_DOCUMENT_TYPES,
          payload: documentTypes,
        });
      })
      .catch((err) => {
        showErrorMessage(
          err,
          'Error consultando listado tipos de identificación',
        );
      });
  }
};

export const getRegimeTypesAction = () => async (dispatch) => {
  if (documentTypes.length > 0) {
    dispatch({
      type: GET_REGIME_TYPES,
      payload: regimeTypes,
    });
  } else {
    const url = `${urlConfiguration}${'/DianTipoRegimen/DianTipoRegimen'}`;
    await axios
      .get(url, defaultHeaders())
      .then((res) => {
        regimeTypes = res.data.map((item) => {
          return {
            ...item,
            value: item.id.toString(),
            text: item.descripcion,
          };
        });

        dispatch({
          type: GET_REGIME_TYPES,
          payload: regimeTypes,
        });
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando listado tipos de regimen');
      });
  }
};

export const getMenusAction = () => (dispatch) => {
  let config = defaultHeaders();

  axios
    .get(`${urlConfiguration}${'/menu/Menu'}`, config)
    .then((response) => {
      menus = response.data;
      dispatch({
        type: GET_MENU,
        payload: response.data,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado menus configurables');
    });
};

export const getComponentsAction = (id) => (dispatch) => {
  const components = menus.find((element) => element.id === id);
  if (!!components) {
    dispatch({
      type: GET_COMPONENTS,
      payload: components.adcomponente,
    });
  }
};

/**
 * Consulta información de menus configurables y filtra por componente
 * @param {*} id Identificador de componente
 */
export const getMenuComponentAction = (id) => async (dispatch) => {
  try {
    if (menuComponent.length > 0) {
      let component = menuComponent.find((e) => e.id === id)?.adcomponente;
      dispatch({
        type: GET_MENU_COMPONENT,
        payload: component,
      });
    } else {
      var response = await axios.get(`${urlConfiguration}${'/menu/Menu'}`);
      menuComponent = response.data;
      let component = menuComponent.find((e) => e.id === id)?.adcomponente;

      dispatch({
        type: GET_MENU_COMPONENT,
        payload: component,
      });
    }
  } catch (err) {
    showErrorMessage(
      err,
      'Error consultando listado menus configurables, por componente',
    );
  }
};

export const cleanMenuComponentAction = () => async (dispatch) => {
  dispatch({
    type: GET_MENU_COMPONENT,
    payload: [],
  });
};

export const ClearGetComponentsAction = () => (dispatch) => {
  dispatch({
    type: GET_COMPONENTS,
    payload: [],
  });
};

export const getPaymentMethodsAction = () => async (dispatch) => {
  if (paymentMethods.length > 0) {
    dispatch({
      type: GET_PAYMENT_METHODS,
      payload: paymentMethods,
    });
  } else {
    const url = `${urlConfiguration}${'/DianMedioPago/DianMedioPago'}`;
    await axios
      .get(url)
      .then((res) => {
        paymentMethods = res.data.map((item) => {
          return {
            value: item.id.toString(),
            text: item.descripcion,
            defecto: item.defecto,
            tipoentidadbancaria: item.tipoentidadbancariaid,
          };
        });
        dispatch({
          type: GET_PAYMENT_METHODS,
          payload: paymentMethods,
        });
      })
      .catch((err) => {
        showErrorMessage(err, 'Error consultando listado medios de pago');
      });
  }
};

export const filterByPlace = (ciudadid, filter) => {
  let city = cities.find((city) => city.id === ciudadid);
  let state = !!city
    ? states.find(
        (state) => Number.parseInt(state.value) === city.departamentoid,
      )
    : null;
  let result = -1;
  if (!!city && result < 0)
    result = city.descripcion.toLowerCase().indexOf(filter);
  if (!!state && result < 0) result = state.text.toLowerCase().indexOf(filter);
  return result;
};

export const getFrequentQuestions = () => (dispatch) => {
  axios
    .get(`${urlConfiguration}${'/menu/faq'}`)
    .then((response) => {
      allFrequentQuestions = response.data.result;
      dispatch({
        type: GET_FREQUENT_QUESTIONS,
        payload: response.data.result,
      });
    })
    .catch((err) => {
      showErrorMessage(
        err,
        'Error consultando listado de preguntas frecuentes',
      );
    });
};

export const getReportProblem = () => (dispatch) => {
  let data = [];
  dataIssue.forEach((item) => {
    let items = {
      value: item.id,
      text: item.descripcion,
    };
    data.push(items);
  });

  dispatch({
    type: GET_REPORT_PROBLEM,
    payload: data,
    payload1: dataIssue,
    payload2: fullNameUser(),
    payload3: emailUser(),
    payload4: idUser(),
    payload5: businessName(),
    payload6: nitCompany(),
  });
};

export const getreason = () => async (dispatch) => {
  let reasonData = [];

  dataReason.forEach((item) => {
    let items = {
      value: item.id,
      text: item.descripcion,
    };
    reasonData.push(items);
  });

  dispatch({
    type: GET_REASON,
    payload: reasonData,
    payload1: dataReason,
    payload2: emailUser(),
  });
};

export const getSecurityPolicy = () => (dispatch) => {
  axios
    .get(`${urlConfiguration}${'/menu/securityPoli'}`)
    .then((response) => {
      let policy = '';

      response.data.result.map((item) => {
        policy = item.description;
      });

      dispatch({
        type: GET_SECURITY_POLICY,
        payload: policy,
      });
    })
    .catch((err) => {
      showErrorMessage(
        err,
        'Error consultando listado de preguntas frecuentes',
      );
    });
};

export const sendMailReport = (body, value) => (dispatch) => {
  axios
    .post(`${urlConfiguration}${'/menu/send'}`, body)
    .then((response) => {
      dispatch({
        type: SEND_MAIL,
        payload: response.data,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error al enviar correo electrónico.');
    });
};

export const sendMailContact = (body) => (dispatch) => {
  axios
    .post(`${urlConfiguration}${'/menu/send'}`, body)
    .then((response) => {
      dispatch({
        type: SEND_MAIL_CONTACT,
        payload: response.data,
      });
    })
    .catch((err) => {
      showErrorMessage(
        err,
        'Error al enviar correo electrónico sección de contactenos.',
      );
    });
};

export const getFrequentQuestionsFilter = (filter = '', refresh = true) => (
  dispatch,
) => {
  if (allFrequentQuestions.length > 0 && !refresh) {
    dispatch({
      type: GET_QUESTION_FILTER,
      payload: filerList(allFrequentQuestions, filter),
    });
  }
};

function filerList(list, filter) {
  try {
    if (!list) return list;

    const data = list.filter((x) => {
      const question = x.question.toLowerCase();
      const answer = x.answer.toLowerCase();
      const query = `${question} ${answer}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });
    return data;
  } catch (err) {
    showErrorMessage(
      err,
      'Error filtrando el listado de preguntas frecuentes.',
    );
  }
}

export const getIssue = () => (dispatch) => {
  axios
    .get(`${urlConfiguration}${'/menu/issue'}`, defaultHeaders())
    .then((response) => {
      response.data.result.forEach((item) => {
        item = {
          ...item,
          value: item.id,
          text: item.descripcion,
        };
        if (item.tipo === 1) {
          dataIssue.push(item);
        } else {
          dataReason.push(item);
        }
      });

      dispatch({
        type: GET_REASON,
        payload: dataReason,
        payload1: dataReason,
        payload2: emailUser(),
      });

      dispatch({
        type: GET_REPORT_PROBLEM,
        payload: dataIssue,
        payload1: dataIssue,
        payload2: fullNameUser(),
        payload3: emailUser(),
        payload4: idUser(),
        payload5: businessName(),
        payload6: nitCompany(),
      });
    })
    .catch((err) => {
      showErrorMessage(
        err,
        'Error filtrando el listado de preguntas frecuentes.',
      );
    });
};

export const getWorkerTypesAction = () => async (dispatch) => {
  const url = `${urlConfiguration}${'/DianTipoTrabajador/DianTipoTrabajor'}`;
  await axios
    .get(url)
    .then((res) => {
      const dataDestructured = res.data.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion,
          nivel: item.nivel,
        };
      });

      const workerTypes =
        dataDestructured.filter((data) => data.nivel === 1) || [];
      const workerSubTypes =
        dataDestructured.filter((data) => data.nivel === 2) || [];

      dispatch({
        type: GET_WORKER_TYPES,
        payload1: workerTypes,
        payload2: workerSubTypes,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado tipos de trabajador');
    });
};

export const getContractTypesAction = () => async (dispatch) => {
  const url = `${urlConfiguration}${'/DianTipoContrato/DianTipoContrato'}`;
  await axios
    .get(url)
    .then((res) => {
      contractTypes = res.data.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion,
        };
      });
      dispatch({
        type: GET_CONTRACT_TYPES,
        payload: contractTypes,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado tipos de trabajador');
    });
};

export const getPaymentPeriodAction = () => async (dispatch) => {
  const url = `${urlConfiguration}${'/DianPeriodoNomina/DianPeriodoNomina'}`;
  await axios
    .get(url)
    .then((res) => {
      paymentPeriods = res.data.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion,
          orden: item.orden,
        };
      });

      dispatch({
        type: GET_PAYMENT_PERIOD,
        payload: paymentPeriods,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado periodicidad de pago');
    });
};

export const getBanksAction = () => async (dispatch) => {
  const url = `${urlConfiguration}${'/EntidadBancaria/EntidadBancaria'}`;
  await axios
    .get(url)
    .then((res) => {
      banks = res.data.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion,
          tipoentidadbancaria: item.tipoentidadbancariaid,
        };
      });

      dispatch({
        type: GET_BANKS,
        payload: banks,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado de entidades bancarias');
    });
};

export const getRetiresTypesAction = () => async (dispatch) => {
  const url = `${urlConfiguration}${'/causalRetiro/Causal'}`;
  await axios
    .get(url, defaultHeaders())
    .then((res) => {
      let causesRetire = res.data.result.map((item) => {
        return {
          ...item, //Debe ir FR
          value: item.id.toString(),
          text: item.descripcion,
        };
      });
      dispatch({
        type: GET_RETIRE_CAUSES,
        payload: causesRetire,
      });
    })
    .catch((err) => {
      showErrorMessage(err, 'Error consultando listado de causales de retiro');
    });
};

export const getIncomesExpensesAction = (payload) => {
  return { type: GET_INCOMES_EXPENSES, payload: payload };
};

/**
 * Consulta listado de ingresoso y deducciones
 * @returns
 */
export const getIncomesAndExpensesAction = () => async (dispatch, getState) => {
  try {
    const { incomesList, expensesList } = getState().configReducer;

    if (incomesList.length > 0 || expensesList.length > 0) return;

    const resp = await axiosApiInstance.get(
      `${urlConfiguration}/concepto/Concepto`,
      defaultHeaders(),
    );

    let data = resp.data ?? [];
    data = data.map((item) => {
      return {
        ...item,
        value: item.id,
        text: item.descripcion,
        unidadmedida: item.unidadMedida,
      };
    });
    let incomes = data.filter((item) => item.ingreso);
    incomes = incomes.sort((a, b) =>
      a.descripcion.localeCompare(b.descripcion, 'es', {
        sensitivity: 'base',
      }),
    );

    let expenses = data.filter((item) => !item.ingreso);
    expenses = expenses.sort((a, b) =>
      a.descripcion.localeCompare(b.descripcion, 'es', {
        sensitivity: 'base',
      }),
    );

    dispatch({
      type: types.FILL_INCOMES_LIST,
      payload: incomes,
    });

    dispatch({
      type: types.FILL_EXPENSES_LIST,
      payload: expenses,
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando listado ingresos y deducciones');
  }
};
