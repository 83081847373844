import React from 'react';
import { DIGITS_AFTER_DECIMAL } from '../../config/config';

export const FormatDecimal = ({ value, prefix = '', suffix = '', size }) => {
  let beforeDecimal = '0';
  let afterDecimal = '';
  const separatorDecimal = '.';

  if (value === 'N/A') {
    return value;
  }

  if (value !== undefined && value !== null && value !== '') {
    if (typeof value === 'string') value = parseFloat(value);
    if (Number.isNaN(value)) value = 0;

    value = value.toLocaleString('en', {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL,
    });
    const point =
      value.indexOf(separatorDecimal) !== -1
        ? value.indexOf(separatorDecimal)
        : value.length;
    beforeDecimal = value.substring(0, point);
    afterDecimal = value.substring(point + 1, value.length);
  } else {
    return <span>{'0'}</span>;
  }

  return (
    <span>
      {prefix}
      <span>{beforeDecimal}</span>
      {afterDecimal.length > 0 ? (
        <>
          {separatorDecimal}
          <small style={{ fontSize: !!size ? size : 'x-small' }}>
            {afterDecimal}
          </small>
        </>
      ) : (
        <small></small>
      )}
      {suffix}
      &nbsp;
    </span>
  );
};
