import moment from 'moment';

/**
 * * Consultar tipo de licencia
 */

export const handleTypeLicense = (periodo, empresaid, empleadoId) => {
  const license = {
    year: Number(moment(periodo).format('YYYY')),
    month: Number(moment(periodo).format('MM')),
    empresaid,
    empleadoId,
  };

  return license;
};
