import React, { useEffect, useState } from 'react';

import { RowInformation } from './';

import { Skeleton } from '@material-ui/lab';
import { Slide } from '@material-ui/core';

export const ListInformation = ({ loadinEmployee, dataEmployeeList }) => {
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
    }, 100);
  }, [dataEmployeeList]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '50px',
      }}
    >
      {loadinEmployee ? (
        <div style={{ width: '68vw', height: 425, overflowX: 'hidden' }}>
          <Slide direction="down" in={checked} mountOnEnter unmountOnExit>
            <section>
              {dataEmployeeList.length === 0 ? (
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '2em',
                    color: 'white',
                    height: '30vh',
                  }}
                >
                  No hay datos disponibles
                </p>
              ) : (
                dataEmployeeList.map((data, index) => (
                  <RowInformation
                    key={index}
                    data={data}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    index={index}
                  />
                ))
              )}
            </section>
          </Slide>
        </div>
      ) : (
        <div style={{ width: '68vw', height: 425, overflowX: 'hidden' }}>
          {[...Array(6)].map((_, index) => (
            <Skeleton
              key={index}
              variant="text"
              width="100%"
              height={49}
              style={{
                margin: '5px',
              }}
              animation="pulse"
            />
          ))}
        </div>
      )}
    </div>
  );
};
