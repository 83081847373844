export const rules = {
  tipoDocumentoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones Validas [CC|NIT|PA]',
    },
  },
  numeroDocumentoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\d{4,20}$/,
      message: 'Únicamente es permitido números',
    },
  },
  primerApellidoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{0,100}$/,
      // value: /^\s*(?:\S\s*){0,100}$/,
      message:
        'Únicamente es permitido letras y tildes, Máximo 100 caracteres.',
    },
  },
  segundoApellidoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      // value: /^\s*(?:\S\s*){0,100}$/,
      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{0,100}$/,
      message:
        'Únicamente es permitido letras y tildes, Máximo 100 caracteres.',
    },
  },
  primerNombreColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{0,100}$/,
      // value: /^\s*(?:\S\s*){0,100}$/,
      message:
        'Únicamente es permitido letras y tildes, Máximo 100 caracteres.',
    },
  },
  otrosNombresColumn: {
    pattern: {
      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{0,100}$/,
      // value: /^\s*(?:\S\s*){3,200}$/,
      message: 'Únicamente es permitido letras',
    },
  },
  correoElectronicoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+]{3,})+@([A-Za-z0-9_\-.]{3,})+\.([A-Za-z]{2,})$/,
      message:
        'Debe ingresar una dirección de correo válida, mínimo 10 caracteres',
    },
  },
  telefonoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9-+() ]{7,15}$/,
      message:
        'Solo se permite números y -+(), mínimo 7 y máximo 15 caracteres',
    },
  },
  municipioColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas [11001|05001]',
    },
  },
  direccionColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.;:_/)()]*$/,
      message:
        'Únicamente es permitido letras, tildes, números y algunos caracteres especiales',
    },
  },
  tipoTrabajadorColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message:
        'Opciones válidas [00 - No aplica |01 - Dependiente pensionado por vejez activo]',
    },
  },
  subTipoTrabajadorColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas [11001|05001]',
    },
  },
  tipoContratoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message:
        'Opciones válidas [00 - No aplica |01 - Dependiente pensionado por vejez activo]',
    },
  },
  numeroContratoColumn: {
    pattern: {
      value: /^[\w\s\S]*$/,
      // value: /^[0-9a-zA-Z\-_]*$/,
      message:
        'Solo se permite caracteres alfanuméricos, espacios y caracteres especiales',
    },
  },
  fechaIngresoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      // value: /^((0[1-9]|[1-9][0-9]{0,3})-(0[1-9]|1[0-2])-([0-9]{4}))$/,
      // value: /^(?:3[01]|[12][0-9]|0?[1-9])([\-/.])(0?[1-9]|1[1-2])\1\d{4}$/,
      value: /^(?:3[01]|[12][0-9]|0?[1-9])(\/|-)(0?[0-9]|1[0-2])\1\d{2,4}$/,
      message: 'Formato fecha de ingreso inválido (DD-MM-YYYY)',
    },
  },
  fechaFinalizacionColumn: {
    pattern: {
      // eslint-disable-next-line no-useless-escape
      // value: /^(?:3[01]|[12][0-9]|0?[1-9])([\-/.])(0?[1-9]|1[1-2])\1\d{4}$/,
      // value: /^((0[1-9]|[1-9][0-9]{0,3})-(0[1-9]|1[0-2])-([0-9]{4}))$/,
      value: /^(?:3[01]|[12][0-9]|0?[1-9])(\/|-)(0?[0-9]|1[0-2])\1\d{2,4}$/,
      message: 'Formato fecha de finalización inválido (DD-MM-YYYY)',
    },
  },
  cargoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.;:_/)()]*$/,
      message:
        'Únicamente es permitido letras, tildes, números y algunos caracteres especiales',
    },
  },
  salarioColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\d{1,13}$/,
      message: 'Únicamente es permitido números',
    },
  },
  periodoPagoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message:
        'Opciones válidas [1 - Semanal| 2 - Decenal |3 - Catorcenal|4 - Quincenal | 5 - Mensual]',
    },
  },
  salarioIntegralColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(SI|NO|Si|No|sI|nO|si|no)$/,
      message: 'Opciones Validas [SI|NO|SI|NO|Si|No|sI|nO|si|no]',
    },
  },
  trabajoAltoRiesgoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(SI|NO|Si|No|sI|nO|si|no)$/,
      message: 'Opciones Validas [SI|NO|SI|NO|Si|No|sI|nO|si|no]',
    },
  },
  sucursalColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-ZáéíóúÁÉÍÓÚ]{1,100}$/,
      valueResponse: 'Error',
      message: 'Sucursal no válida, verifique la información ingresada',
    },
  },
  municipioLugarTrabajoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas [11001|05001]',
    },
  },
  direccionLugarTrabajoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.;:_/)()]*$/,
      message:
        'Únicamente es permitido letras, tildes, números y algunos caracteres especiales',
    },
  },
  medioPagoColumn: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas ZZZ - Acuerdo mutuo| 71 - Bonos]',
    },
  },
  descripcionMedioPagoColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Descrcipción medio de pago',
    },
  },
  entidadFinancieraColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas [5 - Banco BCSC | 6 - Banco Citibank ]',
    },
  },
  tipoCuentaColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Opciones válidas [5 - Banco BCSC | 6 - Banco Citibank ]',
    },
  },
  numeroCuentaColumn: {
    pattern: {
      value: /^\s*(?:\S\s*){0,500}$/,
      message: 'Numero de cuenta',
    },
  },
};
