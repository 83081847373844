import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { SuccessAlert2 } from '../helpers/alert.helpers';
import { enterpriseDocumentNumber } from './authActions';
import { showErrorMessage } from '../utils/showErrorService';
import { types } from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

/**
 * Consulta listado de documentos generados por empleado
 * @param {*} data
 * @returns
 */
export const getHistoryPayrollByEmployeeAction = (data) => async (
  dispatch,
  getState,
) => {
  try {
    changeLoadingStatus(dispatch, true);
    const { payrollListEmployee } = getState().payrollHistoryReducer;

    if (!data.LoadMore) {
      dispatch({
        type: types.FILL_PAYROLL_HISTORY,
        payload: [],
      });
    }

    const resp = await axiosApiInstance.post(
      `${urlDocument}/Documento/GetHistoryPayroll`,
      data,
      defaultHeaders(),
    );

    const nuevoListado = resp?.data?.result ?? [];
    const nominasEmpleado = data.LoadMore
      ? [...payrollListEmployee, ...nuevoListado]
      : [...nuevoListado];

    dispatch({
      type: types.FILL_PAYROLL_HISTORY,
      payload: nominasEmpleado,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error consultando listado nominas por empleado.',
    );
  } finally {
    changeLoadingStatus(dispatch, false);
  }
};

const changeLoadingStatus = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAYROLL_HISTORY,
    payload: status,
  });
};

/**
 * Consulta archivos asociados a un soporte de nomina
 * @param {*} codigounico Identificador unico soporte
 */
export const getPayrollFilesAction = (codigounico) => async (dispatch) => {
  try {
    changeLoadingFiles(dispatch, true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: 31,
      pDocumento: enterpriseDocumentNumber(),
      pCodigoUnico: codigounico,
    });

    const response = await axiosApiInstance.get(
      `${urlDocument}/Documento/GetDocumentFiles`,
      config,
    );

    dispatch({
      type: types.FILL_PAYROLL_FILES,
      payload: response?.data?.result ?? null,
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando archivos asociados a documento.');
  } finally {
    changeLoadingFiles(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta archivos asociados a un soporte de nomina
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingFiles = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAYROLL_FILES,
    payload: status,
  });
};

/**
 * Borra información de archivos consultados por soporte de nomina
 * @returns
 */
export const cleanPayrollFilesAcion = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_PAYROLL_FILES,
    payload: null,
  });
};

/**
 * Envia documento soporte nomina a correo electronico
 * @param {*} codigoUnico
 * @param {*} razonSocialEmpleado
 * @param {*} mailTo
 * @returns
 */
export const sendEmailPayrollAction = (
  mailTo,
  razonSocialEmpleado,
  codigoUnico,
) => async (dispatch) => {
  try {
    changeLoadingSend(dispatch, true);
    const data = {
      TipoDocumento: '31',
      Documento: enterpriseDocumentNumber(),
      CodigoUnico: codigoUnico,
      RazonSocialCliente: razonSocialEmpleado,
      Correos: mailTo,
    };

    const resp = await axiosApiInstance.post(
      `${urlDocument}${'/Documento/SendDocument'}`,
      JSON.stringify(data),
      defaultHeaders(),
    );

    console.log('ENVIO', resp);

    SuccessAlert2(
      'Buen trabajo!!!',
      'Se realizó el reenvío del soporte de nómina exitosamente.',
    );
  } catch (err) {
    showErrorMessage(err, 'Error en envio de correo electronico.');
  } finally {
    changeLoadingSend(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta archivos asociados a un soporte de nomina
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingSend = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAYROLL_EMAIL,
    payload: status,
  });
};

/**
 * Reinicia reducer historial nominas empleados
 * @returns
 */
export const cleanPayrollEmployeeHistoryAction = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_PAYROLL_HISTORY,
  });
};
