import React from 'react';

import BulkLoadBg from '../../../../images/bulk_load.png';

import { makeStyles } from '@material-ui/core';
import ButtonSteper from './ButtonSteper';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0',
    padding: '0',
    backgroundColor: 'blueGrey',
  },
  infografia: {
    width: '100%',
    maxWidth: '1070px',
    margin: 'auto',
    display: 'block',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
  },
}));

const LoadInformation = ({ activeStep, setActiveStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Cargue masivo de empleados</h1>
      <figure container>
        <img src={BulkLoadBg} className={classes.infografia} alt="Infografia" />
      </figure>
      <ButtonSteper activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  );
};

export default LoadInformation;
