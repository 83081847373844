export const findAndReportErrors = (data) => {
  const groupedData = data.reduce((groups, item, index) => {
    const groupKey = item['Agrupación'];
    groups[groupKey] = groups[groupKey] || { referenceItem: item, items: [] };
    groups[groupKey].items.push({ ...item, index });
    return groups;
  }, {});

  const errors = Object.keys(groupedData).reduce((allErrors, groupKey) => {
    const group = groupedData[groupKey];
    const { referenceItem, items } = group;
    const errorMessages = [];

    items.forEach((item) => {
      if (
        item['Tipo de documento'] !== referenceItem['Tipo de documento'] ||
        item['Número documento'] !== referenceItem['Número documento']
      ) {
        errorMessages.push(groupKey);
      }
    });

    return { ...allErrors, [groupKey]: errorMessages };
  }, {});

  const allErrorMessages = Object.values(errors)
    .map((groupErrors) => groupErrors.join(', '))
    .filter((errorString) => errorString !== '');

  return allErrorMessages.join(', ');
};
