import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { getStringFromDate } from '../../helpers/functions.helpers';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
  cardEmployee: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255,255,255,0.11)',
    marginBottom: '12px',
    cursor: 'pointer',
    padding: '2%',
    margin: '0 1%',
    width: '100%',
    '&:hover': {
      boxShadow: '0px 0px 3px 2px rgba(22,177,243,0.7)',
    },
    '&:selected': {
      border: '1px solid #16b1f3',
      backgroundColor: '#16b1f3',
    },
  },
  valueList: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2CC63E',
  },
  incomplete: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
}));

const CardEmployee = ({ data, onClick, selected }) => {
  const selectedStyle = {
    backgroundColor: '#16b1f3',
    color: '#FFF',
  };
  const classes = useStyles();

  const handleDetailPayroll = () => {
    onClick();
  };

  return (
    <div
      className={classes.cardEmployee}
      style={selected ? selectedStyle : {}}
      onClick={handleDetailPayroll}
    >
      <Grid container className={'panelInvisibleInvoice'}>
        <Grid item xs={7}>
          <div className="dataNumberInvoice">
            <span style={{ fontSize: '20px', fontWeight: '600' }}>
              {getStringFromDate(new Date(data.periodo), true)}
            </span>
          </div>
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            className={classes.incomplete}
            direction="row"
            alignItems="center"
          >
            <Grid
              item
              style={
                selected === data.id
                  ? {
                      backgroundColor: '#16b1f3',
                      color: '#FFF',
                    }
                  : data.cantidadocumentos !== data.cantidadtrasmitidos
                  ? { color: '#F5D13D' }
                  : {}
              }
            >
              {data.cantidadocumentos === data.cantidadtrasmitidos
                ? ''
                : 'Incompleta'}
            </Grid>
            <Grid item>
              {data.favorito ? (
                <StarIcon style={{ color: '#FFF' }} />
              ) : (
                <StarBorderIcon style={{ color: '#FFF' }} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={7} lg={5} className="dataNumberInvoice">
          <span style={{ fontSize: '12px' }}>
            {data.cantidadocumentos === data.cantidadtrasmitidos
              ? `Cantidad de empleados transmitidos : ${data.cantidadtrasmitidos}`
              : `Cantidad de empleados transmitidos : ${data.cantidadtrasmitidos} de ${data.cantidadocumentos}`}
          </span>
        </Grid>
        <Grid
          item
          xs={5}
          lg={7}
          container
          alignItems="center"
          justify="flex-end"
          direction="row"
        >
          <span
            className={classes.valueList}
            style={
              selected === data.id
                ? selectedStyle
                : data.cantidadempleados !== data.cantidadtrasmitidos
                ? { color: '#F5D13D' }
                : {}
            }
          >
            {'$' + new Intl.NumberFormat('es-CO').format(data.valornomina || 0)}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default CardEmployee;
