import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Grid } from '@material-ui/core';

import CustomTooltip from '../tooltip/tooltip.component';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.primaryColor,
    fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    backgroundColor: 'hsla(0,0%,100%,.2)',
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.fourthBackgroundColor,
      borderRadius: 5,
      border: 'none',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.5)',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.secundaryColor,
      borderRadius: 5,
      border: 'none',
    },
    '& .Mui-selected:hover': {
      backgroundColor: theme.palette.secundaryColor,
      borderRadius: 5,
      border: 'none',
    },
  },
  group: {
    width: '100%',
    border: 'none',
  },
  styleText: {
    color: theme.palette.primaryColor,
    textTransform: 'capitalize',
  },
  styleToogle: {
    height: 10,
    width: '100%',
    border: 'none',
    fontWeight: 600,
  },
  root: {
    color: theme.palette.primaryColor,
    '& .MuiFormLabel-root.Mui-focused ': {
      color: theme.palette.primaryColor,
    },
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'initial',
  },
  title: {
    color: 'gray',
    fontFamily: theme.typography.fontFamily,
    fontSize: 11,
    boder: 'none',
    lineHeight: '15px',
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ToggleButtons({
  label,
  onChange,
  value = '1',
  titleOne,
  titleTwo,
  txtTooltip,
  name,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}> {label}</span>
      <Grid container spacing={1}>
        {txtTooltip ? (
          <Fragment>
            <Grid item xs={11} className={classes.container}>
              <ToggleButtonGroup
                value={value}
                exclusive
                onChange={onChange}
                aria-label="text alignment"
                size={'large'}
                className={classes.group}
                name={name}
              >
                <ToggleButton
                  disabled={disabled}
                  value="1"
                  aria-label="left aligned"
                  className={classes.styleToogle}
                >
                  <span className={classes.styleText}>{titleOne}</span>
                </ToggleButton>
                <ToggleButton
                  disabled={disabled}
                  value="2"
                  aria-label="right aligned"
                  className={classes.styleToogle}
                >
                  <span className={classes.styleText}>{titleTwo}</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={1} className={classes.wrapper}>
              <CustomTooltip message={txtTooltip} />
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs={12} className={classes.container}>
            <ToggleButtonGroup
              value={value}
              exclusive
              onChange={onChange}
              aria-label="text alignment"
              size={'large'}
              className={classes.group}
              name={name}
            >
              <ToggleButton
                disabled={disabled}
                value="1"
                aria-label="left aligned"
                className={classes.styleToogle}
              >
                <span className={classes.styleText}>{titleOne}</span>
              </ToggleButton>
              <ToggleButton
                disabled={disabled}
                value="2"
                aria-label="right aligned"
                className={classes.styleToogle}
              >
                <span className={classes.styleText}>{titleTwo}</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
