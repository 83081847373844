import React from 'react';

import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';

import DetailPayroll from './DetailPayroll';
import ListPayrollReportedGeneral from './ListPayrollReportedGeneral';

const HomePayroll = () => {
  const { loadingReported } = useSelector((s) => s.payrollReportedReducer);

  return (
    <Grid container>
      <Grid item xs={12} lg={5}>
        <ListPayrollReportedGeneral loadingReported={loadingReported} />
      </Grid>
      <Grid item xs={12} lg={7} style={{ paddingLeft: 5 }}>
        <DetailPayroll loadingReported={loadingReported} />
      </Grid>
    </Grid>
  );
};

export default HomePayroll;
