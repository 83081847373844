import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import { Grid } from '@material-ui/core';

import { format } from 'date-fns';
import { dianTypeDocumentDescription } from '../../../helpers/catalogDian';
import ArchiveModal from '../../modal/archiveModal';
import isEmpty from '../../../utils/isEmpty';
import {
  cleanPayrollFilesAcion,
  getPayrollFilesAction,
  sendEmailPayrollAction,
} from '../../../actions/payrollEmployeeAction';
import ResponseModal from '../../modal/responseModal.component';
import { EmailConfirmForm } from '../../email/emailConfirm';
import { CustomModal } from '../../modal/customModal';
import { FormatDecimal } from '../../common/formatDecimal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#6C6C6C;',
    color: theme.palette.primaryColor,
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: '10px !important',
      paddingLeft: 47,
    },
    '& .MuiCardHeader-root': {
      paddingTop: 5,
      paddingBottom: 0,
    },
    '& .MuiCardHeader-avatar': {
      marginRight: 5,
    },
    '& .MuiCardActions-root': {
      padding: 0,
    },
  },
  content: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 'normal',
    display: 'grid',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: '#FFFFFF',
  },
  avatar: {
    color: '#2CC63E',
    padding: '2px 1px',
  },
  value: {
    color: '#2CC63E',
    fontSize: '14px',
    fontWeight: '600',
  },
  iconAction: {
    color: '#FFFFFF',
  },
}));

const validarestadonomina = (estadonominaid) => {
  switch (estadonominaid) {
    case 2:
      return 'Aceptado';
    case 3:
      return 'Error en documento';
    case 4:
    case 6:
      return 'Ocurrió error al enviar a la Dian';
    default:
      return '';
  }
};

export const PreviusPayrollCard = React.memo(
  ({
    consecutivo,
    estadonominaid,
    fechavalidaciondian,
    prefijo,
    codigounico,
    diantipodocumento,
    primernombre,
    otronombre,
    primerapellido,
    segundoapellido,
    valorneto,
    observacion,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openModalMail, setOpenModalMail] = useState(false);
    const [openModalFile, setOpenModalFile] = useState(false);
    const [base64PDF, setBase64PDF] = useState(null);
    const [base64XML, setBase64XML] = useState(null);
    const [modalData, setModalData] = useState({
      type: '',
      title: '',
      subtitle: '',
      body: '',
      modalImage: '',
      open: false,
      closeElement: '',
      onCloseElement: 0,
    });

    /**
     * Store Redux
     */
    const { payrollFiles, loadingFiles, loadingEmail } = useSelector(
      (s) => s.payrollHistoryReducer,
    );

    /**
     * Escucha cambios en listado archivos asociados a documento
     */
    useEffect(() => {
      if (payrollFiles) {
        let filePDF = payrollFiles.find(
          (c) => c.tipoContenido === 'application/pdf',
        );
        let fileXML = payrollFiles.find(
          (c) => c.tipoContenido === 'application/xml',
        );

        if (!!filePDF) {
          setBase64PDF(filePDF?.archivoBase64);
          setBase64XML(fileXML?.archivoBase64);
        }
      } else {
        setBase64PDF(null);
        setBase64XML(null);
      }
    }, [payrollFiles]);

    /**
     * Consulta y previsualiza archivo
     */
    const handlePreview = () => {
      if (isEmpty(codigounico)) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Archivo no disponible',
          body: (
            <div>
              {
                'Mientras, el documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá visualizarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
      } else {
        setOpenModalFile(true);
        dispatch(getPayrollFilesAction(codigounico));
      }
    };

    /**
     * Limpia listado archivos asociados a documento
     */
    const handleCloseModalFile = () => {
      setOpenModalFile(false);
      dispatch(cleanPayrollFilesAcion());
    };

    /**
     * Cierra Modal, Notificación codigo unico no valido
     */
    const handleResponseModal = () => {
      setModalData({
        ...modalData,
        open: false,
      });
    };

    /**
     * Cierra modal envio de correo
     */
    const handleCloseModalMail = () => {
      setOpenModalMail(false);
    };

    /**
     * Abre modal para envio de correo con documento
     */
    const openMailModal = () => {
      if (isEmpty(codigounico)) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Documento no disponible',
          body: (
            <div>
              {
                'El documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá enviarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
      } else {
        setOpenModalMail(true);
      }
    };

    /**
     * Envia soporte de nomina a un determinado corrreo electronico
     * @param {*} mailTo Correo electronico
     * @param {*} codigounico Codigo unico del soporte de nómina
     */
    const sendSupportToMail = (mailTo) => {
      const fullName = getFullName(
        primernombre,
        otronombre,
        primerapellido,
        segundoapellido,
      );
      dispatch(sendEmailPayrollAction(mailTo, fullName, codigounico));
    };

    /**
     * Concatena nombre completo
     * @param {*} primernombre
     * @param {*} otronombre
     * @param {*} primerapellido
     * @param {*} segundoapellido
     * @returns
     */
    const getFullName = (
      primernombre,
      otronombre,
      primerapellido,
      segundoapellido,
    ) => {
      return `${primernombre} ${otronombre ?? ''} ${primerapellido ?? ''} ${
        segundoapellido ?? ''
      }`;
    };

    return (
      <Card className={`${classes.root}`}>
        <CardHeader
          classes={{
            title: classes.title,
          }}
          avatar={<CreateIcon className={classes.avatar} />}
          action={
            <>
              <Tooltip title="Visualizar">
                <IconButton onClick={handlePreview}>
                  <VisibilityIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Enviar">
                <IconButton onClick={openMailModal}>
                  <EmailIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>
            </>
          }
          title={`Tipo doc: ${dianTypeDocumentDescription(diantipodocumento)}`}
        />
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <span>{`Consecutivo: ${prefijo}${consecutivo}`}</span>
            </Grid>
            <Grid item lg={6} xs={12} style={{ textAlign: 'end' }}>
              <span
                className={classes.value}
                style={{
                  color: diantipodocumento === 104 ? '#ED8D84' : '#2CC63E',
                }}
              >
                <FormatDecimal value={valorneto} prefix={'$'} />
              </span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>{`Validación DIAN: ${validarestadonomina(
                estadonominaid,
              )}`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span>
                {fechavalidaciondian && estadonominaid === 2
                  ? `Fecha validación DIAN: ${format(
                      new Date(fechavalidaciondian),
                      'dd/MM/yyyy',
                    )}`
                  : 'Fecha validación DIAN:'}
              </span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span style={{ wordBreak: 'break-all' }}>{`Observaciones: ${
                observacion ?? ''
              }`}</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span style={{ wordBreak: 'break-all' }}>{`Codigo unico: ${
                codigounico ?? ''
              }`}</span>
            </Grid>
          </Grid>
        </CardContent>

        {/* Modal previsualizar PDF */}
        <ArchiveModal
          loading={loadingFiles}
          open={openModalFile}
          pdfBase64={base64PDF}
          xmlBase64={base64XML}
          handleClose={handleCloseModalFile}
        />

        {/* Modal confirmación email */}
        <CustomModal
          onClose={handleCloseModalMail}
          open={openModalMail}
          component={
            <EmailConfirmForm
              emailUser={''}
              handleConfirmEmail={sendSupportToMail}
              loading={loadingEmail}
            />
          }
          title={'Enviar documento soporte de nómina'}
          width={365}
          showCloseButton={false}
          disableBackdropClick={loadingEmail}
        />
        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleResponseModal}
        />
      </Card>
    );
  },
);
