import React from 'react';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ExpenseIcon from '../../../../../images/icons/expenses.png';

import '../../../../../styles/invoice.css';

const useStyles = makeStyles({
  container: {
    marginBottom: '1%',
    backgroundColor: 'rgba(255,255,255,0.11)',
    borderRadius: '5px',
    padding: '2%',
    maxWidth: '49%',
    color: '#FFF',
    fontWeight: 'bold',
  },
  item: {
    paddingLeft: '5%',
    '& p': {
      margin: '0',
      textAlign: 'left',
      color: '#FFF',
    },
  },
});

const ItemList = ({
  dataExpense,
  setSelectedExpense,
  setDefaultValues,
  deleteExpense,
  readOnly,
}) => {
  // actions

  // react hooks

  // style classes
  const classes = useStyles();
  // actions
  const handleDelete = (item) => {
    deleteExpense(item);
  };
  const handleEdit = (item) => {
    setSelectedExpense({ text: item.concepto, value: item.conceptoid });
    setDefaultValues(item);
  };

  return (
    <>
      {dataExpense && dataExpense.length > 0
        ? dataExpense.map((item, key) => {
            return item.idstate !== 1 ? (
              <Grid item xs={6} className={classes.container} key={key}>
                <Grid container>
                  <Grid item>
                    <img src={ExpenseIcon} alt="Logo" />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      className={classes.item}
                    >
                      <p>
                        <strong> {item.concepto}</strong>
                      </p>
                      <p>
                        {`${
                          Number(item.cantidad) ? Number(item.cantidad) : ''
                        } ${item.textocantidad ? item.textocantidad : ''} ${
                          Number(item.cantidad) && Number(item.porcentaje)
                            ? '-'
                            : ''
                        } ${
                          Number(item.porcentaje) ? Number(item.porcentaje) : ''
                        } ${Number(item.porcentaje) ? '%' : ''}`}
                      </p>
                      {item.descripcion && (
                        <span
                          title={item.descripcion}
                          style={{
                            margin: '0.5em 0px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflowX: 'clip',
                            maxWidth: '20vw',
                          }}
                        >{`${item.descripcion}`}</span>
                      )}
                      <p style={{ color: '#ED8D84' }}>
                        {'$' +
                          new Intl.NumberFormat('es-CO').format(item.valor)}
                      </p>
                    </Grid>
                    {!readOnly && (
                      <Grid item>
                        <CreateIcon
                          style={{ color: '#D8D8D8', cursor: 'pointer' }}
                          onClick={() => handleEdit(item)}
                        />
                        <DeleteIcon
                          style={{ color: '#D8D8D8', cursor: 'pointer' }}
                          onClick={() => handleDelete(item)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            );
          })
        : ''}
    </>
  );
};

export default ItemList;
