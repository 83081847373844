/**
 * * Elimina caracteres que no se desean en determinadas columnas
 *
 * @param {} value
 * @param {*} header
 */
export const removeUnwantedCharacters = (value, header) => {
  if (
    (value && header === 'Valor ingreso') ||
    (value && header === 'Valor deducción')
  ) {
    value = value.replace('$', '').replace(/,/g, '').replace(' ', '');

    return parseFloat(value);
  }
  return value;
};
