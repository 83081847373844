import {
  SAVE_BILLER,
  INFO_BILLER,
  GET_BILLERS,
  UPDATE_BILLER,
  LOAD_BILLER,
  UPDATE_STATUS_BILLER,
  LOADING_SAVE_BILLER,
  LOADING_GET_BILLERS,
  DISABLED_ACTIONS_USER,
  INFO_LICENSE_BILLER,
} from '../actions/types';

import isEmpty from '../utils/isEmpty';

const initialState = {
  loadingSaveBiller: false,
  loadingSaveSeller: false,
  loadingGetBillers: false,
  editBillerStatus: false,
  editSellerStatus: false,
  openModalItem: false,
  filteredBillersList: [],
  billerList: [],
  disabledActions: false,
  licenseBiller: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_BILLER:
      const statusCode = action.payload.statusCode;
      if (statusCode === '200' || statusCode === '201') {
        const newUser = action.payload.result;
        return {
          ...state,
          billerList: [newUser, state.billerList],
          filteredBillersList: [newUser, ...state.filteredBillersList],
          saveBillerResponse: action.payload,
        };
      } else {
        return {
          ...state,
          saveBillerResponse: action.payload,
        };
      }
    case GET_BILLERS:
      return {
        ...state,
        billerList: action.payload,
        filteredBillersList: action.payload1,
      };
    case UPDATE_BILLER:
      const id = action.payload.result?.id ?? 0;
      const updateUser = action.payload.result;
      return {
        ...state,
        billerList: state.billerList.map((user) =>
          user.id === id ? { ...updateUser } : user,
        ),
        filteredBillersList: state.filteredBillersList.map((user) =>
          user.id === id ? { ...updateUser } : user,
        ),
        updateBillerResponse: action.payload,
      };

    case LOAD_BILLER:
      return {
        ...state,
        loadedBillerObject: action.payload,
        editBillerStatus: !isEmpty(action.payload),
      };
    case UPDATE_STATUS_BILLER:
      const iduser = action?.payload?.id ?? 0;
      const status = action?.payload?.status ?? 1;
      return {
        ...state,
        billerList: state.billerList.map((user) =>
          user.id === iduser ? { ...user, idstate: status } : user,
        ),
        filteredBillersList: state.filteredBillersList.map((user) =>
          user.id === iduser ? { ...user, idstate: status } : user,
        ),
        updateBillerStatusResponse: action.payload,
      };
    case LOADING_SAVE_BILLER:
      return {
        ...state,
        loadingSaveBiller: action.payload,
      };
    case LOADING_GET_BILLERS:
      return {
        ...state,
        loadingGetBillers: action.payload,
      };
    case INFO_BILLER:
      return {
        ...state,
        openModalItem: action.payload,
        updateBillerStatusResponse: action.payload1,
      };
    case DISABLED_ACTIONS_USER:
      return {
        ...state,
        disabledActions: action.payload,
      };
    case INFO_LICENSE_BILLER:
      return {
        ...state,
        licenseBiller: action.payload,
      };
    default:
      return state;
  }
}
