import {
  buildMultiDataSet,
  getStatusSummary,
} from '../components/_PayrollBulkLoad/helpers';
import { axiosApiInstance } from '../config/axios-instance';

import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';

import { showErrorMessage } from '../utils/showErrorService';

import { types } from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;
const urlConfiguration = `${API_ENDPOINT_PAYROLL}/confignomina/api`;

/**
 * * Action para enviar los nominas de cargue masivo
 * @param {*} value,
 * @returns
 */
export const getBulkLoadPayrollAction = (
  payrollRequest,
  payrollError,
  multiDataSet,
  nextStep,
) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BULKLOAD_PAYROLL,
      payload: payrollRequest,
    });

    dispatch({
      type: types.GET_BULKLOAD_ERROR_PAYROLL,
      payload: payrollError,
    });

    dispatch({
      type: types.GET_BULKLOAD_DATASET_PAYROLL,
      payload: multiDataSet,
    });

    if (payrollError.length === 0) {
      dispatch({
        type: types.GET_BULKLOAD_REPORTDOWNLOADED_PAYROLL,
        payload: true,
      });
    }

    nextStep();
  } catch (err) {
    showErrorMessage(err, 'Ha ocurrido un error.');
  }
};

/**
 * * Descarga la plantilla de Nómina para cargue masivo y la convierte a base64.
 *
 * @param {function(string): void} convertBase64 - Función que convierte el resultado a base64.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la descarga y conversión son exitosas.
 */
export const downloadBulkPayrollTemplateAction = (
  convertBase64,
) => async () => {
  try {
    const urlTemplate = `${urlConfiguration}/Menu/ObtenerPlantillaNomina`;

    const response = await axiosApiInstance.get(urlTemplate, defaultHeaders());

    if (response.status === 200) {
      convertBase64(response.data.result.downloadRoute);
    }
  } catch (err) {
    showErrorMessage(err, 'Error consultando la plantilla de cargue masivo.');
  }
};

/**
 * * Acción para informar si un informe de carga masiva de nómina ha sido descargado.
 * * Esta acción actualiza el estado de la aplicación para indicar si el informe ha sido descargado con éxito.
 *
 * @param {boolean} isReported - Un valor booleano que indica si el informe ha sido descargado (true) o no (false).
 * @returns {Promise<void>} - Una función que dispara la acción de informar sobre la descarga del informe.
 */
export const reportDownloadedAction = (isReported) => async (dispatch) => {
  dispatch({
    type: types.GET_BULKLOAD_REPORTDOWNLOADED_PAYROLL,
    payload: isReported,
  });
};

/**
 * * Acción para limpiar los datos relacionados con la carga masiva de nómina.
 * * Esta acción reinicia o limpia el estado(Redux) de la carga masiva de nómina en la aplicación.
 *
 * @returns {Function} - Una función que dispara la acción de limpiar la carga masiva de nómina.
 */
export const cleanDataBulkLoadPayrollAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_BULKLOAD_PAYROLL,
  });
};

/**
 * Realiza una carga masiva de nóminas y notifica el resultado a través de Redux.
 *
 * @param {Object} bulkLoadPayroll - Los datos de la carga masiva de nóminas.
 * @param {Function} nextStep - La función a ejecutar después de completar la carga.
 * @param {Function} dispatch - La función de Redux para despachar acciones.
 */
export const transmitBulkLoadPayrollAction = (
  bulkLoadPayroll,
  nextStep,
) => async (dispatch) => {
  try {
    const urlBulkLoadPayroll = `${urlDocument}/CargueMasivo/CargueMasivoNominas`;
    const {
      data: { result },
    } = await axiosApiInstance.post(
      urlBulkLoadPayroll,
      bulkLoadPayroll,
      defaultHeaders(),
    );

    if (result.movimientoEmpleadoLists.length > 0) {
      const payrollResultCreated = createBulkUploadResponseObjects(
        result.movimientoEmpleadoLists,
      );

      await dispatch({
        type: types.GET_BULKLOAD_PAYROLL_RESPONSE,
        payload: payrollResultCreated,
      });

      if (result.errorsXLinea.length > 0) {
        const infoErrors = buildMultiDataSet(
          result.errorsXLinea.sort((a, b) => a.line - b.line),
        );

        await dispatch({
          type: types.GET_BULKLOAD_DATASET_PAYROLL,
          payload: infoErrors,
        });

        dispatch({
          type: types.GET_BULKLOAD_ERROR_PAYROLL,
          payload: result.errorsXLinea,
        });
      }
      nextStep();
    } else {
      throw new Error('No se encontraron resultados.');
    }
  } catch (error) {
    showErrorMessage(error, 'Error Intentando Cargar Plantilla Cargue Masivo.');
    nextStep(error);
  }
};

/**
 * * Función que crea un arreglo de objetos de respuesta para una carga masiva.Crea objetos de respuesta para una carga masiva a partir de un conjunto de datos y poder mostrarlos en el front.
 *
 * @param {Array<Object>} data - Arreglo de objetos con datos para la carga masiva.
 * @returns {Array<Object>} - Arreglo de objetos de respuesta agrupados por la combinación de 'NumeroDocumento' y 'TipoDocumento'.
 */
const createBulkUploadResponseObjects = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const key = `${item.numeroDocumento}-${item.tipoDocumento}`;

    if (!groupedData[key]) {
      groupedData[key] = {
        fullName: item.nombreCompleto,
        documentNumber: item.numeroDocumento,
        documentType: item.tipoDocumento,
        concepts: [],
      };
    }

    const concept = {
      conceptName: item.nombreConcepto,
      quantity: item.cantidadConcepto,
      percentage: item.porcentajeConcepto,
      status: item.estado,
      conceptType: item.tipoConcepto,
      conceptValue: item.valorConcepto,
    };

    groupedData[key].concepts.push(concept);
  });

  const result = Object.values(groupedData).map((groupedItem) => {
    const statusSummary = getStatusSummary(groupedItem.concepts);
    return { ...groupedItem, result: statusSummary };
  });

  return result;
};
