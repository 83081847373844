import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';

import { Grid } from '@material-ui/core';

import InputForm from '../../../input/input.component';
import SwitchForm from '../../../switch/switch.component.js';
import AutoCompleteForm from '../../../autocomplete/autocomplete.component';
import ButtonPrimary from '../../../button/buttonPrimary.component';
import TooltipMessage from '../../../tooltip/tootltip-message.component';
import ResponseModal from '../../../modal/responseModal.component';

import {
  saveBranchOfficeAction,
  updateBranchOfficeAction,
} from '../../../../actions/branchOfficeActions';

import { useFormComplete } from '../../../../hooks/useFormComplete';
import { useMyAccount } from '../../../../hooks/useMyAccount';
import { SuccessAlert2, WarningAlert } from '../../../../helpers/alert.helpers';

export const BranchOfficeForm = () => {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const [, forceUpdate] = useState();
  const [citiestoState, setCitiesToState] = useState([]);
  const [dataModal, setdataModal] = useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
    showProgress: 0,
    textProgress: '',
    valueProgressBar: 0,
  });

  const {
    values,
    handleInputChange,
    handleAutocompleteChange,
    handleUpdateForm,
    reset,
  } = useFormComplete({
    nombre: '',
    prefijo: '',
    departamentoid: null,
    ciudadid: null,
    direccion: '',
    idstate: true,
  });
  const { saveLoadBranch } = useMyAccount();

  const {
    nombre,
    prefijo,
    departamentoid,
    ciudadid,
    direccion,
    idstate,
  } = values;

  const { getStatesResponse: statesList, allCitiesCountry } = useSelector(
    (s) => s.configReducer,
  );
  const {
    titlePrefijo,
    messagePrefijo,
    bottonPrefijo,
    urlPrefijo,
    titlePopCreateBranch,
    messagePopCreateBranch,
    messageBodyPopBranch,
    messageSkipBranch,
    messageProgressBranch,
    titlePopUpdateBranch,
    messagePopUpdateBranch,
  } = useSelector((s) => s.menuReducer.menuMyAccount);

  const {
    loadBranchOffice,
    editBranchStatus,
    loadingSaveBranch,
    responseSaveBranchOffice: responseSave,
    responseUpdateBranchOffice: responseUpdate,
  } = useSelector((s) => s.branchOfficeReducer);

  const { data: officesList } = useSelector(
    (s) => s.branchOfficeReducer?.filteredBranchOfficesList || [],
  );

  /**
   * Escucha cambios en sucursal activa
   */
  useEffect(() => {
    if (!!loadBranchOffice) {
      loadDataInForm(loadBranchOffice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCitiesCountry, loadBranchOffice]);

  /**
   * Lee respuesta guardado sucursal
   */
  useEffect(() => {
    const status = responseSave?.statusCode;
    if (status === '200' || status === '201') {
      setdataModal({
        type: 'success',
        title: titlePopCreateBranch,
        subtitle: messagePopCreateBranch,
        body: (
          <div
            dangerouslySetInnerHTML={{
              __html: messageBodyPopBranch,
            }}
          ></div>
        ),
        modalImage: 'successBranch',
        open: true,
        closeElement: messageSkipBranch,
        onCloseElement: 1,
        showProgress: 1,
        textProgress: messageProgressBranch,
        valueProgressBar: 50,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseSave]);

  /**
   * Lee repuesta actualización sucursal
   */
  useEffect(() => {
    const status = responseUpdate?.statusCode;
    const name = responseUpdate?.result?.nombre ?? '';
    if (status === '200' || status === '201') {
      const message = messagePopUpdateBranch.replace('$nombreSucursal', name);
      SuccessAlert2(titlePopUpdateBranch, message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdate]);

  /**
   * Carga información de sucursal en formulario
   * @param {*} office
   */
  const loadDataInForm = (office) => {
    let officeCity = allCitiesCountry.find((c) => c.id === office.ciudadid);
    let stateid = officeCity?.departamentoid ?? 0;
    let officeState = statesList.find((s) => s.id === stateid);
    let cities = allCitiesCountry.filter((c) => c.departamentoid === stateid);

    setCitiesToState(cities);

    handleUpdateForm({
      nombre: office.nombre,
      principal: office.principal,
      prefijo: office.prefijo,
      departamentoid: officeState ?? null,
      ciudadid: officeCity ?? null,
      direccion: office.direccion,
      idstate: office.idstate === 0,
    });
  };

  /**
   * Filtra listado ciudades por departamento, sincroniza con state
   * @param {*} e
   * @param {*} value
   * @param {*} name
   */
  const handleChangeState = (e, value, name) => {
    let newCities = [];
    if (!!value)
      newCities = allCitiesCountry.filter(
        (c) => c.departamentoid === parseInt(value.value),
      );

    handleUpdateForm({
      ciudadid: null,
      departamentoid: value,
    });
    setCitiesToState(newCities);
  };

  /**
   * Valida y actualiza estado (activo/Inactivo) de sucursal
   * @param {*} param0
   */
  const handleChangeStatus = ({ target }) => {
    const checked = target.checked;
    const idform = loadBranchOffice?.id ?? 0;
    const actives = officesList.filter(
      (c) => c.idstate === 0 && c.id !== idform,
    );

    if (!checked && actives.length === 0) {
      WarningAlert('Upss...!!!', 'Debe existir al menos una sucursal activa');
      return;
    }

    handleUpdateForm({
      idstate: checked,
    });
  };

  /**
   * Limpia y cierra modal notificación
   */
  const handleResponseModal = () => {
    setdataModal({
      type: '',
      title: '',
      subtitle: '',
      body: '',
      modalImage: '',
      open: false,
      closeElement: '',
      onCloseElement: 0,
      showProgress: 0,
      textProgress: '',
      valueProgressBar: 0,
    });
  };

  /**
   * Evento, Guarda información de sucursal.
   * @param {*} e Evento
   */
  const onSubmitBranchOffice = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random());
      return;
    }

    const newData = {
      id: loadBranchOffice?.id ?? 0,
      nombre: nombre,
      prefijo: prefijo,
      direccion: direccion,
      ciudadid: parseInt(ciudadid.value),
      idstate: idstate ? 0 : 1,
    };

    if (!editBranchStatus) {
      dispatch(saveBranchOfficeAction(newData, resetForm));
    } else {
      dispatch(updateBranchOfficeAction(newData, resetForm));
    }
  };

  /**
   * Resetea campos de formulario
   */
  const resetForm = () => {
    reset();
    validator.hideMessages();
    forceUpdate(Math.random());
    if (!!loadBranchOffice) {
      saveLoadBranch(null, false);
    }
  };

  return (
    <>
      <form onSubmit={onSubmitBranchOffice} noValidate autoComplete="off">
        <Grid container alignItems="center" spacing={3}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={'nombre'}
              label={'Nombre *'}
              maxLength={300}
              value={nombre}
              onChange={handleInputChange}
              validator={validator}
              validateOptions={'required|max:300|min:5'}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={'prefijo'}
              label={'Prefijo liquidación'}
              maxLength={5}
              value={prefijo}
              onChange={(e) => handleInputChange(e, 'alphabetic')}
              tooltip={
                <TooltipMessage
                  title={titlePrefijo}
                  message={messagePrefijo}
                  botton={bottonPrefijo}
                  href={urlPrefijo}
                />
              }
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AutoCompleteForm
              label={'Departamento *'} //En singular
              name="departamentoid"
              textNoOption={'No hay registros'}
              value={departamentoid}
              options={statesList}
              validator={validator}
              validateOptions={'required'}
              onChange={handleChangeState}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <AutoCompleteForm
              label={'Municipio *'} // En singular
              name="ciudadid"
              textNoOption={'No hay registros'}
              value={ciudadid}
              options={citiestoState}
              validator={validator}
              validateOptions={'required'}
              onChange={handleAutocompleteChange}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <InputForm
              name={'direccion'}
              label={'Dirección *'}
              maxLength={100}
              value={direccion}
              onChange={handleInputChange}
              validator={validator}
              validateOptions={'required|min:3|max:100'}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SwitchForm
              name="idstate"
              checked={idstate}
              titleOn="Activar/Desactivar Sucursal"
              onChange={handleChangeStatus}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="flex-end">
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ButtonPrimary
              text={'Listo'}
              loading={loadingSaveBranch}
              type={'submit'}
            />
          </Grid>
        </Grid>
      </form>
      <ResponseModal
        modalType={dataModal.modalType}
        title={dataModal.title}
        subtitle={dataModal.subtitle}
        body={dataModal.body}
        modalImage={dataModal.modalImage}
        open={dataModal.open}
        closeElement={dataModal.closeElement}
        onCloseElement={handleResponseModal}
        showProgress={dataModal.showProgress}
        textProgress={dataModal.textProgress}
        valueProgressBar={dataModal.valueProgressBar}
      />
    </>
  );
};
