import { types } from '../actions/types';

const initialState = {
  employeesIntegration: [],
  loadingEmployeesIntegration: false,
  employeeIntegration: {},
  documentsIntegration: [],
  loadingDocumentsIntegration: false,
  loadingGetFilesIntegration: false,
  filesIntegrationList: null,
  loadingSendDocumentIntegration: false,
};

export const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILL_INTEGRATION_EMPLOYEES:
      return {
        ...state,
        employeesIntegration: action.payload,
      };
    case types.LOADING_INTEGRATION_EMPLOYEES:
      return {
        ...state,
        loadingEmployeesIntegration: action.payload,
      };
    case types.SET_EMPLOYEE_INTEGRATION:
      return {
        ...state,
        employeeIntegration: action.payload,
      };
    case types.FILL_INTEGRATION_DOCUMENTS:
      return {
        ...state,
        documentsIntegration: action.payload,
      };
    case types.LOADING_INTEGRATION_DOCUMENTS:
      return {
        ...state,
        loadingDocumentsIntegration: action.payload,
      };
    case types.LOADING_FILES_INTEGRATION_DOCUMENTS:
      return {
        ...state,
        loadingGetFilesIntegration: action.payload,
      };
    case types.FILL_INTEGRATION_FILES:
      return {
        ...state,
        filesIntegrationList: action.payload,
      };

    case types.LOADING_SEND_INTEGRATION_DOCUMENTS:
      return {
        ...state,
        loadingSendDocumentIntegration: action.payload,
      };
    case types.CLEAN_INTEGRATION_REDUCER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
