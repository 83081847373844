export const formatCurrency = (locales, currency, fractionDigits, number) => {
  const formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits,
  }).format(number);
  return formatted;
};

const exp = /(\d)(?=(\d{3})+(?!\d))/g;
const rep = '$1,';
export const FormatType = {
  currency: 'currency',
  percentage: 'percentage',
};

export const numericValueFormat = (
  value,
  digits = 2,
  formatType = FormatType.currency,
) => {
  return roundNumber(value, digits);
};

const roundNumber = (number, digits) => {
  let integer = Math.round(toExp(Math.abs(number), digits));
  return Math.sign(number) * toExp(integer, -digits);
};

const toExp = (number, digits) => {
  let array = number.toString().split('e');
  let mantissa = array[0];
  let exponent = digits;

  if (array[1]) {
    exponent = Number(array[1]) + digits;
  }

  return Number(mantissa + 'e' + exponent.toString());
};

export const formatCurrencyColombia = (number) => {
  let array = number.toString().split('.');
  array[0] = array[0].replace(exp, rep);
  return array[1] ? array.join('.') : array[0];
};
