import ErrorIcon from '../../../images/icons/failResult.png';
import TransmitIcon from '../../../images/icons/transmitIcon.png';
import AlertIcon from '../../../images/svgIcons/alertIcon.svg';
import ValidateIcon from '../../../images/icons/validateIcon.png';

/**
 * * Devuelve el ícono correspondiente según el tipo de resultado.
 *
 * @param {string} item - El tipo de resultado ('Actualizado', 'Error' u otro).
 * @returns {string} El ícono correspondiente.
 */
export const iconResult = (item) => {
  const resultIcons = [
    { type: 'Mixtos', icon: AlertIcon },
    { type: 'Error', icon: ErrorIcon },
    { type: 'Transmitidos', icon: TransmitIcon },
  ];

  const matchedIcon = resultIcons.find((icon) => icon.type === item);

  return matchedIcon ? matchedIcon.icon : ValidateIcon;
};
