import {
  GET_MENU,
  LOADING,
  SET_INDEX_SELECTED,
  GET_ERRORS,
  SHOW_ERROR,
} from '../actions/types';

const initialState = {
  listMenu: [],
  loading: false,
  errorResponse: null,
  currentError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MENU:
      return {
        ...state,
        listMenu: action.payload,
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ERRORS: {
      return {
        ...state,
        errorResponse: {
          error: action.payload,
          message: action.payload1,
        },
      };
    }
    case SET_INDEX_SELECTED:
      return {
        ...state,
        indexSelected: action.payload,
      };
    case SHOW_ERROR:
      return {
        ...state,
        currentError: { ...action.payload },
      };
    default:
      return state;
  }
}
