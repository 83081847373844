import React, { useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
// Material
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
// hooks
import { useIncomeExpense } from '../../../../hooks/useIncomeExpense';

// utils
import {
  makeRandomId,
  unformatterValue,
} from '../../../../helpers/functions.helpers';
// components
import CustomAutoComplete from '../../../../components/autocomplete/autocomplete.component';
import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import CustomProgress from '../../../Progress/progress.component';

import ItemIncome from './itemIncome';
import ItemExpense from './itemExpense';
import { useForm } from 'react-hook-form';
import '../../../../styles/invoice.css';
// helpers
import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';

import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
  container: {
    gap: '2%',
    display: 'grid',
    gridTemplateColumns: '4fr 1fr 1fr 2fr',
    color: '#fff',
  },
  incomeTitle: {
    borderRadius: '5px',
    backgroundColor: 'rgba(18,18,18,0.3)',
    marginBottom: '0.5em',
    textAlign: 'center',
    '& h2': {
      color: '#2CC63E',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
  },
  expenseTitle: {
    borderRadius: '5px',
    marginBottom: '0.5em',
    backgroundColor: 'rgba(18,18,18,0.3)',
    '& h2': {
      color: '#ED8D84',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
  },
  incomeAdd: {
    borderRadius: '5px',
    backgroundColor: 'rgba(255,255,255,0.11)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5em',
    '& h2': {
      color: '#FFF',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0.1em',
      color: '#FFF',
    },
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: '100%',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
  },
  customGrid: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    display: 'grid',
    gap: '2%',
    width: '100%',
  },
});

const CustomInput = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'green',
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'blue',
    },
    '& input.Mui-disabled': {
      color: 'rgba(255,255,255,0.5);',
    },

    '& .MuiInputBase-input': {
      color: '#FFFF',
      border: '2px solid rgba(255,255,255,0.3)',
      borderRadius: '4px',
    },
  },
})(TextField);

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        color: '#FFF',
        '& label ': {
          color: '#FFF',
          textAlign: 'left',
        },
      },
    },
    palette: {
      primary: 'red',
      secondary: 'cyan',
    },
    MuiFormLabel: {
      root: {
        color: '#FFF',
        textAlign: 'center',
        width: '100%',
        '&$focused': {
          color: '#16B1F3',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(22, 177, 243)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#16B1F3 !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#FFF',
        borderRadius: '5px',
        '& fieldset': {
          border: '2px solid rgba(255,255,255,0.3) !important',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#4A4A4A !important',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#232323',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#FFF',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#1b5e20',
      },
    },
  },
});

const IncomeExpense = ({
  readOnly = false,
  dataIncome,
  dataExpense,
  updateIncome,
  addIncome,
  updateExpense,
  addExpense,
  deleteExpense,
  deleteIncome,
}) => {
  // local state
  const [selectorIncomes, setSelectorIncomes] = useState([]);
  const [selectorExpenses, setSelectorExpenses] = useState([]);
  const [term, setTerm] = useState(null);
  const [isIncome, setIsIncome] = useState(null);
  const [selectedIncome, setSelectedIncome] = useState('');
  const [defaultValues, setDefaultValues] = useState(null);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  // simple validator
  const simpleValidator = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  );
  // references
  const valueRef = useRef(null);
  const percentRef = useRef(null);
  const quantityRef = useRef(null);
  const fixedRef = useRef(null);
  const totalRef = useRef(null);
  const descriptionRef = useRef(null);
  const dateRef = useRef(null);
  // forms react use form
  const { handleSubmit } = useForm();
  // hook manage incomes expenses actions,

  // custom hook
  const { fillIncomesExpenses, listIncomes, listExpenses } = useIncomeExpense();
  // style classes
  const classes = useStyles();
  // methods
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 100000000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        isAllowed={withValueCap}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        decimalSeparator="."
        isNumericString
        prefix="$"
        allowNegative={false}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }
  function NumberFormatCustomWithMin(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 100000000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL && value > 0.0) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        isAllowed={withValueCap}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        decimalSeparator="."
        isNumericString
        prefix="$"
        allowNegative={false}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }
  function NumberFormatCustomBasic(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 10000000000000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        isAllowed={withValueCap}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        decimalSeparator="."
        isNumericString
        prefix="$"
        allowNegative={false}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }
  function percentFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 10000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        isAllowed={withValueCap}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        allowNegative={false}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }
  function percentFormatCustomOneHundred(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 100;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        isAllowed={withValueCap}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        decimalScale={2}
        allowNegative={false}
      />
    );
  }
  function quantityFormatCustomMax(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 730000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        isAllowed={withValueCap}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        allowNegative={false}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }

  function quantityFormatCustomOneHundred(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 99;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        isAllowed={withValueCap}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fixedDecimalScale={true}
        decimalScale={0}
        allowNegative={false}
      />
    );
  }

  function quantityFormatCustomPrima(props) {
    const { inputRef, onChange, ...other } = props;
    const MAX_VAL = 25000;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        isAllowed={withValueCap}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        allowNegative={false}
        fixedDecimalScale={false}
        decimalScale={0}
      />
    );
  }

  const handleIncome = (event, values) => {
    event.preventDefault();
    if (values && values.value) {
      setSelectedIncome(values);
    } else {
      setSelectedIncome(null);
      setTerm(null);
    }
  };

  const handleExpense = (event, values) => {
    event.preventDefault();
    if (values && values.value) {
      setSelectedExpense(values);
    } else {
      setSelectedExpense(null);
      setTerm(null);
    }
  };
  // submit form
  const onSubmit = () => {
    const referencesObj = {
      valueRef,
      percentRef,
      quantityRef,
      fixedRef,
      descriptionRef,
      totalRef,
    };

    let myObj = {};

    if (simpleValidator.current.allValid()) {
      simpleValidator.current.hideMessages();

      const aux = Object.values(referencesObj);
      // map object reerences
      aux.forEach((refs) => {
        if (refs.current && refs.current.name) {
          if (refs.current.name === 'valor') {
            myObj[refs.current.name] = unformatterValue(refs.current.value);
          } else {
            myObj[refs.current.name] = refs.current.value;
          }
        }
      });

      if (!myObj.hasOwnProperty('valor')) {
        myObj.valor = 0;
      }

      if (isUpdating) {
        // is editing or is a new register
        myObj.id = defaultValues.id;
        myObj.conceptoid = term.id;
        myObj.concepto = term.descripcion;
        myObj.idstate = term.idstate || 0;
        myObj.textocantidad = term.textocantidad;
        updateIncome(myObj);
      } else {
        myObj.id = makeRandomId(4);
        myObj.conceptoid = term.id;
        myObj.concepto = term.descripcion;
        myObj.idstate = term.idstate || 0;
        myObj.textocantidad = term.textocantidad;
        addIncome(myObj);
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setIsUpdating(false);
    setDefaultValues(null);
    setSelectedIncome(null);
    setTerm(null);
  };

  const onSubmitExpense = () => {
    const referencesObj = {
      valueRef,
      percentRef,
      quantityRef,
      fixedRef,
      descriptionRef,
      totalRef,
    };

    let myObj = {};
    if (simpleValidator.current.allValid()) {
      simpleValidator.current.hideMessages();
      const aux = Object.values(referencesObj);

      aux.forEach((refs) => {
        if (refs.current && refs.current.name) {
          if (refs.current.name === 'valor') {
            myObj[refs.current.name] = unformatterValue(refs.current.value);
          } else {
            myObj[refs.current.name] = refs.current.value;
          }
        }
      });

      if (isUpdating) {
        myObj.id = defaultValues.id;
        myObj.conceptoid = term.id;
        myObj.concepto = term.descripcion;
        myObj.idstate = term.idstate || 0;
        updateExpense(myObj);
      } else {
        myObj.id = makeRandomId(4);
        myObj.conceptoid = term.id;
        myObj.concepto = term.descripcion;
        myObj.idstate = term.idstate || 0;
        addExpense(myObj);
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setIsUpdating(false);
    setDefaultValues(null);
    setSelectedExpense(null);
    setTerm(null);
  };

  const handleOpen = (stateBool) => {
    setIsIncome(stateBool);

    if (isUpdating) {
      setIsUpdating(false);
      setDefaultValues(null);
      setTerm(null);
      setSelectedExpense(null);
      setSelectedIncome(null);
    }
  };

  const deleteTermIncomes = () => {
    let arrAux = [...(listIncomes || [])];
    const isSelected =
      selectedIncome &&
      dataIncome &&
      dataIncome.find(
        (d) => d.conceptoid === selectedIncome.value && d.idstate === 1,
      );
    if (!!isSelected) {
      setIsUpdating(false);
      setDefaultValues(null);
      setSelectedIncome(null);
      setTerm(null);
    }
    dataIncome &&
      dataIncome.forEach((data) => {
        if (data.idstate === 0)
          arrAux = arrAux.filter((el) => el.id !== data.conceptoid);
      });
    setSelectorIncomes(
      dataIncome && dataIncome.length > 0 ? arrAux : listIncomes,
    );
  };

  const deleteTermExpense = () => {
    let arrAux = [...(listExpenses || [])];

    const isSelected =
      selectedExpense &&
      dataExpense &&
      dataExpense.find(
        (d) => d.conceptoid === selectedExpense.value && d.idstate === 1,
      );
    if (!!isSelected) {
      setIsUpdating(false);
      setDefaultValues(null);
      setSelectedExpense(null);
      setTerm(null);
    }
    dataExpense &&
      dataExpense.forEach((data) => {
        if (data.idstate === 0)
          arrAux = arrAux.filter((el) => el.id !== data.conceptoid);
      });
    setSelectorExpenses(
      dataExpense && dataExpense.length > 0 ? arrAux : listExpenses,
    );
  };

  // react hooks
  useEffect(() => {
    if (!listIncomes || !listExpenses) {
      fillIncomesExpenses();
    } else {
      deleteTermIncomes();
      deleteTermExpense();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIncomes, listExpenses, dataIncome, dataExpense]);

  useEffect(() => {
    if (selectedIncome) {
      setIsIncome(true);
      let arrAux = [...listIncomes];
      if (
        listIncomes &&
        listIncomes.length > 0 &&
        selectedIncome &&
        (!dataIncome || !dataIncome.length)
      ) {
        const termFinded = listIncomes.find(
          (response) => response.id === Number(selectedIncome.value),
        );
        const listWithoutTerm = listIncomes.filter(
          (response) => response.id !== Number(selectedIncome.value),
        );
        setSelectorIncomes(listWithoutTerm);
        setTerm(termFinded);
      } else if (dataIncome && dataIncome.length > 0) {
        dataIncome &&
          dataIncome.forEach((data) => {
            if (data.idstate === 0)
              arrAux = arrAux.filter((el) => el.id !== data.conceptoid);
          });
        const termFinded = listIncomes.find(
          (response) => response.id === Number(selectedIncome.value),
        );
        const listWithoutTerm = arrAux.filter(
          (response) => response.id !== Number(selectedIncome.value),
        );
        setSelectorIncomes(listWithoutTerm);
        setTerm(termFinded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncome]);

  useEffect(() => {
    if (selectedExpense) {
      let arrAux = [...listExpenses];
      setIsIncome(false);
      if (
        listExpenses &&
        listExpenses.length > 0 &&
        selectedExpense &&
        (!dataExpense || !dataExpense.length)
      ) {
        const termFinded = listExpenses.find(
          (response) => response.id === Number(selectedExpense.value),
        );

        const listWithoutTerm = listExpenses.filter(
          (response) => response.id !== Number(selectedExpense.value),
        );

        setSelectorExpenses(listWithoutTerm);
        setTerm(termFinded);
      } else if (dataExpense && dataExpense.length > 0) {
        dataExpense &&
          dataExpense.forEach((data) => {
            if (data.idstate === 0)
              arrAux = arrAux.filter((el) => el.id !== data.conceptoid);
          });
        const termFinded = listExpenses.find(
          (response) => response.id === Number(selectedExpense.value),
        );
        const listWithoutTerm = arrAux.filter(
          (response) => response.id !== Number(selectedExpense.value),
        );
        setSelectorExpenses(listWithoutTerm);
        setTerm(termFinded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExpense]);

  useEffect(() => {
    if (defaultValues) {
      setIsUpdating(true);
    }
  }, [defaultValues, isIncome]);

  useEffect(() => {
    if (!isUpdating) {
      setTerm(null);
      setSelectedIncome(null);
      setSelectedExpense(null);
    }
  }, [isUpdating]);

  // components
  const InputGenerator = () => {
    if (term) {
      // la idea es poner un valir por defecto en el componente que se renderiza para que se pueda pasar como un estado a la lista,
      // defaultValues;

      const {
        aplicavalor,
        aplicaporcentaje,
        aplicacantidad,
        aplicadescripcion,
        aplicafija,
        aplicaFecha,
        textodescripcion,
        unidadMedida,
      } = term;

      return (
        <>
          <ThemeProvider theme={defaultMaterialTheme}>
            {aplicaporcentaje && (
              <CustomInput
                label={'Porcentaje'}
                name="porcentaje"
                inputRef={percentRef}
                defaultValue={
                  defaultValues && defaultValues.porcentaje
                    ? defaultValues.porcentaje
                    : ''
                }
                style={{ marginTop: '2%' }}
                variant="outlined"
                autoComplete="off"
                title="Este campo debe ser numerico, positivo y maximo 3 caracteres."
                InputProps={
                  term.id === 14 ||
                  term.id === 36 ||
                  term.id === 37 ||
                  term.id === 2 ||
                  term.id === 3 ||
                  term.id === 4 ||
                  term.id === 1 ||
                  term.id === 6 ||
                  term.id === 7 ||
                  term.id === 5
                    ? {
                        inputComponent: percentFormatCustomOneHundred,
                      }
                    : {
                        inputComponent: percentFormatCustom,
                      }
                }
                inputProps={{ min: 0, max: 6 }}
                required={true}
                maxLength={3}
              />
            )}
            {aplicacantidad && (
              <CustomInput
                style={{ marginTop: '2%' }}
                label={`Cantidad en ${unidadMedida}`}
                name="cantidad"
                defaultValue={
                  defaultValues && defaultValues.cantidad
                    ? defaultValues.cantidad
                    : ''
                }
                variant="outlined"
                InputProps={
                  term.id === 34
                    ? { inputComponent: quantityFormatCustomPrima }
                    : term.id === 46 ||
                      term.id === 41 ||
                      term.id === 21 ||
                      term.id === 40 ||
                      term.id === 39 ||
                      term.id === 32 ||
                      term.id === 33 ||
                      term.id === 64 ||
                      term.id === 65 ||
                      term.id === 38
                    ? { inputComponent: quantityFormatCustomOneHundred }
                    : {
                        inputComponent: quantityFormatCustomMax,
                      }
                }
                inputRef={quantityRef}
                autoComplete="off"
                required={true}
              />
            )}
            {aplicafija && (
              <CustomInput
                style={{ marginTop: '2%' }}
                label={'Fija'}
                name="fija"
                defaultValue={
                  defaultValues && defaultValues.fixed
                    ? defaultValues.fixed
                    : ''
                }
                variant="outlined"
                inputRef={fixedRef}
                autoComplete="off"
                required={true}
              />
            )}
            {aplicavalor && (
              <CustomInput
                style={{ marginTop: '2%' }}
                label={'Valor'}
                name="valor"
                defaultValue={
                  defaultValues && defaultValues.valor
                    ? defaultValues.valor
                    : ''
                }
                variant="outlined"
                inputRef={valueRef}
                autoComplete="off"
                required={true}
                InputProps={
                  term.id === 21
                    ? {
                        inputComponent: NumberFormatCustomBasic,
                      }
                    : term.id === 45 ||
                      term.id === 44 ||
                      term.id === 22 ||
                      term.id === 42 ||
                      term.id === 55 ||
                      term.id === 57 ||
                      term.id === 10 ||
                      term.id === 56 ||
                      term.id === 9
                    ? {
                        inputComponent: NumberFormatCustomWithMin,
                      }
                    : {
                        inputComponent: NumberFormatCustom,
                      }
                }
              />
            )}
            {aplicaFecha && (
              <DatePicker
                autoOk
                inputRef={dateRef}
                disableFuture={true}
                inputVariant="outlined"
                className={classes.margin}
                name="valor"
                variant="inline"
                openTo="month"
                views={['year', 'month']}
                label="Mes / Año reportado"
              />
            )}
            {aplicadescripcion && (
              <CustomInput
                style={{
                  marginTop: '2%',
                  gridColumnStart: 1,
                  gridColumnEnd: 3,
                }}
                label={`Descripción ${textodescripcion}`}
                name="descripcion"
                defaultValue={
                  defaultValues && defaultValues.descripcion
                    ? defaultValues.descripcion
                    : ''
                }
                inputProps={{ maxLength: 300 }}
                variant="outlined"
                inputRef={descriptionRef}
                autoComplete="off"
                required={true}
              />
            )}
          </ThemeProvider>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {selectorIncomes && selectorExpenses ? (
        <div className="spacingInvoice">
          <Grid container direction="column">
            <Grid item className={classes.incomeTitle}>
              <h2>Ingresos</h2>
            </Grid>
            {!readOnly && (
              <Grid item className={classes.incomeAdd}>
                <Grid container direction="row">
                  <Grid
                    item
                    style={{
                      width: 'fit-content',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      !isIncome ? handleOpen(true) : handleOpen(null)
                    }
                  >
                    Añadir ingresos
                  </Grid>

                  <Grid item>
                    {!isIncome ? (
                      <AddCircleRoundedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpen(true)}
                      ></AddCircleRoundedIcon>
                    ) : (
                      <RemoveCircleIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpen(null)}
                      ></RemoveCircleIcon>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isIncome && !readOnly && (
              <Grid
                container
                style={{
                  borderRadius: '5px',
                  marginBottom: '0.5em',
                  backgroundColor: 'rgba(255,255,255,0.11)',
                  padding: '1%',
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    color: '#16C2F6',
                    margin: 'auto',
                    fontSize: '16px',
                  }}
                >
                  {isUpdating ? 'Editar ' : 'Nuevo '}Ingreso
                </p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    width: '100%',
                  }}
                >
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '100%',
                    }}
                  >
                    <div className={classes.customGrid}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ marginTop: '2%' }}
                      >
                        <CustomAutoComplete
                          name="TipoIngreso"
                          label="Tipo de ingreso *"
                          disabled={isUpdating ? true : false}
                          value={selectedIncome}
                          options={selectorIncomes.map(
                            ({ id, descripcion }) => {
                              return { text: descripcion, value: id };
                            },
                          )}
                          textNoOption="No se encontraron resultados."
                          onChange={(event, values) => {
                            handleIncome(event, values);
                          }}
                          nameValidator={'TipoIngreso'}
                          validator={simpleValidator.current}
                          validateOptions={'required'}
                        />
                        {/* 
                          <InputLabel id="TipoIngreso">
                            Tipo de ingreso
                          </InputLabel>
                          <Select
                            labelId="TipoIngreso"
                            id="demo-simple-select-outlined"
                            value={selectedIncome}
                            onChange={handleIncome}
                            label="TipoIngreso"
                            style={{ color: '#FFF' }}
                          >
                            <MenuItem value="" selected>
                              <em>Seleccione</em>
                            </MenuItem>
                            {selectorIncomes &&
                              selectorIncomes.map(({ id, descripcion }) => (
                                <MenuItem key={id} value={id}>
                                  {descripcion}
                                </MenuItem>
                              ))}
                          </Select> */}
                      </FormControl>
                      {term && <InputGenerator />}
                    </div>
                    <div className={classes.customGrid}>
                      <div className=""></div>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          width: '100%',
                        }}
                      >
                        <ButtonPrimary
                          text="Listo"
                          type="submit"
                          // onClick={this.onSubmitClient}
                          className={classes.buttonContinue}
                        />
                      </Grid>
                    </div>
                  </FormControl>
                </form>
              </Grid>
            )}
            <Grid
              container
              style={{ justifyContent: 'space-between', marginBottom: '0.5em' }}
            >
              <ItemIncome
                setSelectedIncome={setSelectedIncome}
                setDefaultValues={setDefaultValues}
                dataIncome={dataIncome}
                deleteIncome={deleteIncome}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item className={classes.expenseTitle}>
              <h2>Deducciones</h2>
            </Grid>
            {!readOnly && (
              <Grid item className={classes.incomeAdd}>
                <Grid container direction="row">
                  <Grid
                    item
                    style={{
                      width: 'fit-content',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      isIncome === null || isIncome === true
                        ? handleOpen(false)
                        : handleOpen(null)
                    }
                  >
                    Añadir deducciones
                  </Grid>
                  <Grid item>
                    {isIncome === null || isIncome === true ? (
                      <AddCircleRoundedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpen(false)}
                      ></AddCircleRoundedIcon>
                    ) : isIncome === false ? (
                      <RemoveCircleIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpen(null)}
                      ></RemoveCircleIcon>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isIncome === false && !readOnly ? (
              <Grid
                container
                style={{
                  borderRadius: '5px',
                  marginBottom: '0.5em',
                  backgroundColor: 'rgba(255,255,255,0.11)',
                  padding: '1%',
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    color: '#16C2F6',
                    margin: 'auto',
                    fontSize: '16px',
                  }}
                >
                  {isUpdating ? 'Editar ' : 'Nueva '}Deduccion
                </p>
                <form
                  onSubmit={handleSubmit(onSubmitExpense)}
                  style={{
                    width: '100%',
                  }}
                >
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '100%',
                    }}
                  >
                    <div className={classes.customGrid}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ marginTop: '2%' }}
                      >
                        <CustomAutoComplete
                          name="TipoIngreso"
                          label="Tipo de deducción *"
                          value={selectedExpense}
                          disabled={isUpdating ? true : false}
                          options={selectorExpenses.map(
                            ({ id, descripcion }) => {
                              return { text: descripcion, value: id };
                            },
                          )}
                          textNoOption="No se encontraron resultados."
                          onChange={(event, values) => {
                            handleExpense(event, values);
                          }}
                          nameValidator={'TipoIngreso'}
                          validator={simpleValidator.current}
                          validateOptions={'required'}
                        />
                        {/* <InputLabel id="demo-simple-select-outlined-label">
                          Tipo de deducción
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedExpense}
                          onChange={handleExpense}
                          label="Age"
                          style={{ color: '#FFF' }}
                        >
                          <MenuItem value="" selected>
                            <em>Seleccione</em>
                          </MenuItem>
                          {selectorExpenses &&
                            selectorExpenses.map(({ id, descripcion }) => (
                              <MenuItem key={id} value={id}>
                                {descripcion}
                              </MenuItem>
                            ))}
                        </Select> */}
                      </FormControl>
                      {term && <InputGenerator />}
                    </div>
                    <div className={classes.customGrid}>
                      <div className=""></div>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          width: '100%',
                        }}
                      >
                        <ButtonPrimary
                          text="Listo"
                          type="submit"
                          // onClick={this.onSubmitClient}
                          className={classes.buttonContinue}
                        />
                      </Grid>
                    </div>
                  </FormControl>
                </form>
              </Grid>
            ) : (
              ''
            )}
            <Grid
              container
              style={{ justifyContent: 'space-between', marginBottom: '0.5em' }}
            >
              <ItemExpense
                readOnly={readOnly}
                dataExpense={dataExpense}
                setSelectedExpense={setSelectedExpense}
                setDefaultValues={setDefaultValues}
                deleteExpense={deleteExpense}
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <CustomProgress />
      )}
    </>
  );
};

export default IncomeExpense;
