import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import {
  enterpriseDocumentNumber,
  enterpriseIdHeader,
} from '../actions/authActions';

const urlConfiguration = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

export default {
  GetAllEmployeePayroll: async (payroll) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/GetPayrollEmployeeList`,
      payroll,
      config,
    );
    return response;
  },

  /**
   * Obtiene listado de nominas para la tab soportes de nomina
   */
  GetAllPayrollSupports: async (objPayload) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/GetRemovablePayroll`,
      objPayload,
      config,
    );
    return response;
  },

  /**
   * Obtiene detalle de soportes de nomina para un empleado
   */
  GetPayrollSupport: async (data) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/PayrollSuport`,
      data,
      config,
    );
    return response;
  },
  /**
   *
   * @param {*} payload
   * @returns
   */
  GetEmployeePayrollDetail: async (payload) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/GetPayrollEmployee`,
      payload,
      config,
    );
    return response;
  },
  GetAllPayrollReported: async (
    limitPass,
    offsetPass,
    dateFrom,
    dateTo,
    favorite,
  ) => {
    const payload = {
      empresaId: `${enterpriseIdHeader()}`,
      Offset: offsetPass,
      Limit: limitPass,
      Fechadesde: dateFrom || null,
      Fechahasta: dateTo || null,
      Destacado: favorite,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/GetPayrollReportedList`,
      payload,
      config,
    );
    return response;
  },
  GetPayrollDetailReported: async (payload) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/GetPayrollReportedDetail`,
      payload,
      config,
    );

    return response;
  },
  /**
   * Actualiza el favorito de dos tablas distintas si es un detalle
   * de nomina o no
   * @param {object} idToUpdate Id registro a acturalizar
   * @param {object} isFavorite Estado favorito
   */
  UpdateFavoriteRegister: async (idToUpdate, isFavorite) => {
    const payload = {
      Id: idToUpdate,
      Favorito: isFavorite,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateFavoriteStatus`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Actualiza el favorito de un soporte de nomina
   * @param {object} idToUpdate Id registro a acturalizar
   * @param {object} isFavorite Estado favorito
   */
  UpdateSupportFavoriteRegister: async (idToUpdate, isFavorite) => {
    const payload = {
      Id: idToUpdate,
      Favorito: isFavorite,
    };

    let config = defaultHeaders();

    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateFavoriteStatusDetailPayroll`,
      payload,
      config,
    );

    return response;
  },
  /**
   * Actualiza el estado confirmado para una nomina
   * de nomina o no
   * @param {object} Detallenominaid Id nomina
   * @param {object} Confirmado Estado confirmado
   */
  UpdateConfirmedStatus: async (Detallenominaid, Confirmado) => {
    const payload = {
      Detallenominaid: Detallenominaid,
      Confirmado: Confirmado,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateConfirmedStatus`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Elimina de DB un concepto PENDIENTE
   * @param {object} Detallenominaid Id nomina
   */
  DeleteConcept: async (Detallenominaid, Confirmado) => {
    const payload = {
      Detallenominaid: Detallenominaid,
      Confirmado: Confirmado,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateConfirmedStatus`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Elimina una nomina
   * de nomina o no
   * @param {object} Detallenominaid Id nomina
   * @param {object} Confirmado Estado confirmado
   */
  DeletePayroll: async (Detallenominaid, Confirmado) => {
    const payload = {
      Detallenominaid: Detallenominaid,
      Confirmado: Confirmado,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateConfirmedStatus`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Actualiza concepto de una determinada nomina para un empleado
   * @param {int} Detallenominaid Id registro a acturalizar
   * @param {int} Empleadoid Estado favorito
   * @param {int} Nominaid Id nomina
   * @param {int} Conceptoid id concepto
   * @param {float} Valor valor concepto
   * @param {int} Cantidad cantidad (si aplica null:default)
   * @param {int} Porcentaje Porcentaje (si aplica null:default)
   * @param {string} Descripcion Descripcion (si aplica null:default)
   */

  UpdateConcept: async (payload) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateConcept`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Persiste la ultima fecha de pago para la nomina
   * @param {object} idPayroll Id registro de nomina a actualizar
   * @param {object} lastDayPayment Estado favorito
   */
  SaveLasDatePayment: async (idPayroll, lastDayPayment) => {
    const payload = {
      Nominaid: idPayroll,
      Fechapago: lastDayPayment,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/SetDateLastPaid`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Obtiene PDF y XML de una determinada nomina
   * @param {object} data data registro nomina (TipoDocumento, Documento, CodigoUnico)
   */
  GetDocumentFiles: async (codigounico) => {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: 31,
      pDocumento: enterpriseDocumentNumber(),
      pCodigoUnico: codigounico,
    });

    const response = await axiosApiInstance.get(
      `${urlConfiguration}/Documento/GetDocumentFiles`,
      config,
    );
    return response;
  },
  /**
   * Envia soporte de nomina a un determinado corrreo electronico
   * @param {*} mailTo Correo electronico
   * @param {*} razonSocialEmpleado Nombre completo del empleado
   * @param {*} codigoUnico Codigo unico del soporte de nomina
   */
  sendPayrollSupport: async (mailTo, razonSocialEmpleado, codigoUnico) => {
    const payload = {
      TipoDocumento: 31,
      Documento: enterpriseDocumentNumber(),
      CodigoUnico: codigoUnico,
      RazonSocialCliente: razonSocialEmpleado,
      Correos: [mailTo],
    };

    const body = JSON.stringify(payload);
    let myConfig = defaultHeaders();
    const url = `${urlConfiguration}${'/Documento/SendDocument'}`;
    var response = await axiosApiInstance.post(url, body, myConfig);

    return response;
  },
  /**
   * Actualiza el estado confirmado de una determinada nomina
   * @param {int} Detallenominaid Id de nomina a confirmar
   * @param {Boolean} Confirmado Estado de la nomina
   * @param {Boolean} NominaId Id de la nomina
   * @param {Boolean} ValorNomina Valor de la detalle nomina actual
   */
  updateStatePayroll: async (
    Detallenominaid,
    Confirmado,
    NominaId,
    ValorNomina,
  ) => {
    const payload = {
      Detallenominaid: Detallenominaid,
      Confirmado: Confirmado,
      Nominaid: NominaId,
      Valor: ValorNomina,
    };
    let config = defaultHeaders();
    let response = await axiosApiInstance.put(
      `${urlConfiguration}/Documento/UpdateConfirmedStatus`,
      payload,
      config,
    );
    return response;
  },
  /**
   * Transmicion nomina a la DIAN
   * @param {obj} objPayroll Objeto con arreglo ids nomina
   */
  prepareTransmisionDIAN: async (objPayroll) => {
    let config = defaultHeaders();
    let response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/PayrollDetailTransmission`,
      objPayroll,
      config,
    );
    return response;
  },
  /**
   * Elimina de DB determinado concepto para una nomina
   * @param {obj}  payload Objeto payroll nomina
   */
  deleteConceptPayroll: async (payload) => {
    let response = await axiosApiInstance.delete(
      `${urlConfiguration}/Documento/DeleteConcept`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        data: payload,
      },
    );
    return response;
  },
  /**
   * Obtiene estado actual de la transmision
   */
  getCurrentStateTransmision: async () => {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaid: `${enterpriseIdHeader()}`,
    });
    const response = await axiosApiInstance.get(
      `${urlConfiguration}/Documento/GetConsultDetailTransmission`,
      config,
    );
    return response;
  },
  /**
   * Eliminar un documento de nomina de la DB
   * @param {int}  Detallenominaid detalle del id nominaId
   * @param {string}  ObservacionAjuste Observacion para eliminacion documento
   */
  deleteSupportingDocument: async (Detallenominaid, ObservacionAjuste) => {
    const config = defaultHeaders();
    const payload = {
      Detallesnomina: [{ Detalleid: Detallenominaid }],
      ObservacionAjuste: ObservacionAjuste,
    };
    Object.assign(config.headers, {
      pEmpresaid: `${enterpriseIdHeader()}`,
    });

    const response = await axiosApiInstance.post(
      `${urlConfiguration}/Documento/PayrollAdjustDetailTransmission`,
      payload,
      config,
    );
    return response;
  },
};
