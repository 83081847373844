import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  makeStyles,
} from '@material-ui/core';
import CustomProgress from '../../../components/Progress/progress.component';

const useStyles = makeStyles(() => ({
  buttonConfirm: {
    height: '28px',
    width: '100%',
    maxWidth: '28%',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#7DD1F8',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
    minWidth: '148px',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      alignItems: 'center',
      backgroundColor: '#272727',
      borderRadius: '5px',
      color: '#FFFF',
      height: '412px',
      width: '99%',
      maxWidth: '374px',
      maxHeight: '148px',
    },
  },
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    maxHeight: 56,
    margin: '35px 0 42px 0',
  },
  title: {
    color: '#17DAFA',
    fontSize: 30,
    fontWeight: 800,
    marginTop: 0,
    textAlign: 'center',
  },
  contentText: {
    marginBottom: 10,
    padding: '19px 19px 0 19px;',
    fontSize: 16,
    '& > span': {
      color: '#D8D8D8',
      display: 'inline-block',
      textAlign: 'center',
    },
  },
}));

const ModalConfirm = ({
  title,
  textContent,
  actionConfirm,
  actionCancel,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        {title ? (
          <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
            <span className={classes.title}>{title}</span>
          </DialogTitle>
        ) : null}
        <DialogContentText className={classes.contentText}>
          <span>{textContent}</span>
        </DialogContentText>
        {loading ? (
          <CustomProgress />
        ) : (
          <DialogActions className="backgroundContent">
            <Grid container>
              <Grid item lg={6} xs={6}>
                <button
                  className={classes.buttonConfirm}
                  onClick={actionCancel}
                >
                  Cancelar
                </button>
              </Grid>
              <Grid item lg={6} xs={6}>
                <button
                  className={classes.buttonConfirm}
                  onClick={actionConfirm}
                >
                  Si
                </button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default ModalConfirm;
