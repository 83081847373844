import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { URL_SECURITY } from '../config/config';

import MasterPage from '../components/masterPage/masterPage.component.js';
import { validateTokenExpirationDate } from '../actions/authActions';

import { setIndexSelectedAction } from '../actions/generalAction';

const PrivateRoute = ({ component: Component, ...rest }) => {
  /**
   * Marca opción del menu seleccionada
   */
  useEffect(() => {
    if (rest.menuid !== null && rest.menuid !== undefined) {
      rest.setIndexSelectedAction(rest.menuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.menuid]);

  validateTokenExpirationDate();
  return (
    <Route
      {...rest}
      render={(props) => (
        <div>
          {localStorage.getItem('isAuthenticated') ? (
            <MasterPage component={Component} {...props} />
          ) : (
            (window.location.href = URL_SECURITY)
          )}
        </div>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  generalReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  generalReducer: state.generalReducer,
});

export default connect(mapStateToProps, { setIndexSelectedAction })(
  PrivateRoute,
);
