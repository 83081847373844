import { types } from '../actions/types';

const initialState = {
  bulkLoadErrorEmployee: [],
  bulkLoadErrorEmployeeResponse: [],
  bulkLoadEmployee: [],
  bulkLoadDataSet: [],
};

export const bulkLoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BULKLOAD_EMPLOYEE:
      return {
        ...state,
        bulkLoadEmployee: action.payload,
      };
    case types.GET_BULKLOAD_ERROR_EMPLOYEE:
      return {
        ...state,
        bulkLoadErrorEmployee: action.payload,
      };
    case types.GET_BULKLOAD_DATASET:
      return {
        ...state,
        bulkLoadDataSet: action.payload,
      };
    case types.CLEAN_BULKLOAD_EMPLOYEE:
      return initialState;
    default:
      return state;
  }
};
