import isEmpty from './isEmpty';

/**
 * @function DEVUELVE DESCRIPCIÓN DE TIPO CONTRATO
 * @param {*} value Identificador tipo contrato
 */
const dianContractDescription = (value) => {
  if (value === null || value === undefined || isEmpty(value)) return 'NA';

  switch (value) {
    case 1:
      return 'Término Fijo';
    case 2:
      return 'Término Indefinido';
    case 3:
      return 'Obra o Labor';
    case 4:
      return 'Aprendizaje';
    case 5:
      return 'Prácticas o Pasantías';
    default:
      return '';
  }
};

export default dianContractDescription;
