import { API_ENDPOINT_EMPLOYEE, defaultHeaders } from '../config/config';
import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_ADITIONAL_INFO,
  LOADING_SAVE_BASIC_DATA,
  LOAD_EMPLOYEE,
  LOADING_GET_EMPLOYEES,
  UPDATE_CHANGE_STATUS,
  UPDATE_FAVORITE_EMPLOYEE,
  OPEN_REGISTER_EMPLOYEE,
  OPEN_MASSIVE_LOADING_EMPLOYEE,
  SELECTED_ID_EMPLOYEE,
  CHANGE_EDITION_EMPLOYEE_STATE,
  SAVE_BASIC_DATA_EMPLOYEE,
  SAVE_CONTRACT_DATA_EMPLOYEE,
  SAVE_PAYMENT_DATA_EMPLOYEE,
  SAVE_NOVELTIES_DATA_EMPLOYEE,
  RESET_REGISTER_FORM,
  ADD_NEW_INCOME_EMPLOYEE,
  UPDATE_INCOME_EMPLOYEE,
  ADD_NEW_EXPENSE_EMPLOYEE,
  UPDATE_EXPENSE_EMPLOYEE,
  DELETE_INCOME_EMPLOYEE,
  DELETE_EXPENSE_EMPLOYEE,
  FILL_SUPPORT_EMPLOYEE_DETAIL,
  SAVE_RESPONSE_CREATE_PROCESS,
  RETIRE_EMPLOYEE,
  LOADING_RETIRE_EMPLOYEE,
  LOADING_DETAIL_EMPLOYEE,
  SET_REINTEGRE_EMPLOYEE,
  types,
} from './types';
import { axiosApiInstance } from '../config/axios-instance';

import { handleResponse, enterpriseIdHeader } from './authActions';

import { SuccessAlert } from '../helpers/alert.helpers';
import { showErrorMessage } from '../utils/showErrorService';

const urlEmployee = `${API_ENDPOINT_EMPLOYEE}/employee/api/Empleado`;

/**
 *  Consulta información de empleados asociados a la empresa.
 *  @method GET
 */
export const getResumeEmployeesAction = (payload) => {
  return { type: GET_EMPLOYEES, payload: payload };
};

export const getAditionalDataEmployeeAction = (payload) => {
  return { type: GET_EMPLOYEES_ADITIONAL_INFO, payload: payload };
};

/**
 * Consulta  información de empleado para edicion
 */
export const getExistingEmployeeAction = (payload) => {
  return { type: LOAD_EMPLOYEE, payload: payload };
};

/**
 * Carga ultimos dos soportes de nomina si aplica
 */
export const fillSupportEmployeeAction = (payload) => {
  return { type: FILL_SUPPORT_EMPLOYEE_DETAIL, payload: payload };
};

/**
 * Limpia información de em,pleado seleccionado
 */
export const cleanSelectedClient = () => async (dispatch) => {
  dispatch({
    type: SELECTED_ID_EMPLOYEE,
    payload: 0,
  });
};

/**
 * Actualiza estado loading Registro a actualización
 * información basica
 * @param {*} dispatch
 * @param {*} status
 */
function changeSaveLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_BASIC_DATA,
    payload: status,
  });
}

/**
 * Actualiza estado de empleado como reintegro o retiro
 * @method PUT
 * @param {*} id Identificador empleado
 */
export const changeStateEmployeeAction = (
  id,
  causalRetiroId,
  isRetirement,
  fullName,
) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    const body = JSON.stringify({});
    let config = defaultHeaders();
    Object.assign(config.headers, {
      employeeId: id,
      causalRetiroId: causalRetiroId,
      isRetirement: isRetirement,
    });
    var response = await axiosApiInstance.put(
      `${urlEmployee}/ChangeStateEmployee`,
      body,
      config,
    );

    if (response.status === 201) {
      SuccessAlert(
        `${
          isRetirement ? 'Retiraste' : 'Reintegraste'
        } al empleado ${fullName} de manera exitosa.`,
      );
    }

    dispatch({
      type: UPDATE_CHANGE_STATUS,
      payload: response.data,
    });
  } catch (err) {
    showErrorMessage(err, 'Error actualizando empleado,');
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza  datos de contratacion del empleado
 */
export const updateContractDataAction = (body) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    body = {
      ...body,
      empresaid: `${enterpriseIdHeader()}`,
    };
    var response = await axiosApiInstance.put(
      `${urlEmployee}${'/UpdateContractData'}`,
      body,
      defaultHeaders(),
    );

    dispatch({
      type: SAVE_CONTRACT_DATA_EMPLOYEE,
      payload: response.data,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Error actualizando la información de contratacion para el empleado.',
    );
    handleResponse(err.response);
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

/**
 * Actualiza datos de forma de pago del empleado
 * @param {*} body
 * @returns
 */
export const updatePaymentMethodDataAction = (body) => async (dispatch) => {
  try {
    changeSaveLoading(dispatch, true);
    body = {
      ...body,
      empresaid: `${enterpriseIdHeader()}`,
    };
    var response = await axiosApiInstance.put(
      `${urlEmployee}${'/UpdatePaymentMethodData'}`,
      body,
      defaultHeaders(),
    );

    dispatch({
      type: SAVE_CONTRACT_DATA_EMPLOYEE,
      payload: response.data,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Error actualizando la información de forma de pago para el empleado.',
    );
    handleResponse(err.response);
  } finally {
    changeSaveLoading(dispatch, false);
  }
};

export const changeOpenRegisterEmployeeAction = (payload) => {
  return {
    type: OPEN_REGISTER_EMPLOYEE,
    payload: payload,
  };
};

export const changeOpenMassiveLoadEmployeeAction = (payload) => {
  return {
    type: OPEN_MASSIVE_LOADING_EMPLOYEE,
    payload: payload,
  };
};

export const updatePayrollStateAction = (payload) => {
  return { type: UPDATE_FAVORITE_EMPLOYEE, payload: payload };
};
// actions for register
export const changeEditingStatusAction = (payload) => {
  return { type: CHANGE_EDITION_EMPLOYEE_STATE, payload: payload };
};

export const saveBasicDataAction = (payload) => {
  return { type: SAVE_BASIC_DATA_EMPLOYEE, payload: payload };
};

export const saveContractedDataAction = (payload) => {
  return { type: SAVE_CONTRACT_DATA_EMPLOYEE, payload: payload };
};

export const savePaymentDataAction = (payload) => {
  return { type: SAVE_PAYMENT_DATA_EMPLOYEE, payload: payload };
};

export const saveNoveltiesDataAction = (payload) => {
  return { type: SAVE_NOVELTIES_DATA_EMPLOYEE, payload: payload };
};

export const resetFormEmployee = () => {
  return { type: RESET_REGISTER_FORM };
};

export const saveResponseSaveProcess = (payload) => {
  return { type: SAVE_RESPONSE_CREATE_PROCESS, payload: payload };
};

export const addIncomeAction = (payload) => {
  return { type: ADD_NEW_INCOME_EMPLOYEE, payload: payload };
};

export const updateIncomeAction = (payload) => {
  return { type: UPDATE_INCOME_EMPLOYEE, payload: payload };
};

export const addExpenseAction = (payload) => {
  return { type: ADD_NEW_EXPENSE_EMPLOYEE, payload: payload };
};

export const updateExpenseAction = (payload) => {
  return { type: UPDATE_EXPENSE_EMPLOYEE, payload: payload };
};

export const deleteIncomeAction = (payload) => {
  return { type: DELETE_INCOME_EMPLOYEE, payload: payload };
};
export const deleteExpenseAction = (payload) => {
  return { type: DELETE_EXPENSE_EMPLOYEE, payload: payload };
};

/**
 * Marca como retirado a un empleado
 * @param {*} payload
 * @returns
 */
export const retireEmployeeAction = (payload) => {
  return { type: RETIRE_EMPLOYEE, payload: payload };
};

/**
 * Actuliza estado loading en transacción  retirar empleado
 * @param {*} status
 * @returns
 */
export const loadingRetireEmployeeAction = (status) => {
  return {
    type: LOADING_RETIRE_EMPLOYEE,
    payload: status,
  };
};

/**
 * Actuliza estado loading en transacción  consultar empleados
 * @param {*} status
 * @returns
 */
export const loadingGetEmployeesAction = (status) => {
  return {
    type: LOADING_GET_EMPLOYEES,
    payload: status,
  };
};

/**
 * Actuliza estado loading en consulta detalle empleado
 * @param {*} status
 * @returns
 */
export const loadingDetailEmployeAction = (status) => {
  return {
    type: LOADING_DETAIL_EMPLOYEE,
    payload: status,
  };
};

export const setReintegreStatusAction = (status) => {
  return {
    type: SET_REINTEGRE_EMPLOYEE,
    payload: status,
  };
};

/**
 * Actualiza estado loading Creando un nuevo empleado
 * @param {*} dispatch
 * @param {*} status
 */
export const loadingCreateEmployeAction = (status) => {
  return {
    type: types.LOADING_CREATE_EMPLOYEE,
    payload: status,
  };
};
