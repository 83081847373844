import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';

import InputForm from '../../../../components/input/input.component';
import DatePickerForm from '../../../../components/datepicker/datepicker.component';
import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import { NumberFormatCustomMax9 } from '../../../../utils/numberFormat';

const useStyles = makeStyles((theme) => ({
  modal: {
    color: theme.palette.primaryColor,
  },
  modalInfo: {
    margin: '0 auto',
    padding: '3%',
    textAlign: 'center',
  },
  modalInfoText: {
    color: '#7DD1F8',
    fontSize: '18px',
    textAlign: 'center',
  },
  modalText: {
    padding: '3%',
    fontSize: '17px',
  },
  widthMax: {
    width: '100%',
  },
  button: {
    margin: '0 auto',
  },
}));

const ModalSalary = React.memo(({ aumento, salario, updateSalary }) => {
  const classes = useStyles();

  const simpleValidator = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  );

  const [, forceUpdate] = useState();

  const [salary, setSalary] = useState(salario);
  const [date, setDate] = useState();

  /**
   * Limpia caracteres invalidos para valores numericos
   * con 16 enteros y 6 decimales
   * @param {*} e Evento
   */
  const syncChangesValue = ({ target }) => {
    setSalary(target.value);
  };

  /**
   * Sincroniza nuevo valor datePicker
   * @param {*} id Identificador
   * @param {*} date Nueva Fecha
   * @param {*} name Nombre datePicker
   */
  const handleDateFormPicket = (id, date, name) => {
    setDate(new Date(date).toISOString());
  };

  const onSubmitModalSalary = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      if (aumento) {
        updateSalary(salary, date);
      } else {
        updateSalary(salary);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(Math.random());
    }
  };

  return (
    <form onSubmit={(e) => onSubmitModalSalary(e)} className={classes.widthMax}>
      <div className={classes.modal}>
        <Grid container>
          {aumento ? (
            <>
              <Grid item className={classes.modalText} lg={12} md={12} sm>
                Por favor ingresa la información del aumento
              </Grid>
              <Grid item lg={6} md={6} sm>
                <InputForm
                  id={'salary'}
                  label={'Nuevo salario *'}
                  value={salary}
                  onChange={syncChangesValue}
                  nameValidator={'Valor'}
                  validator={simpleValidator.current}
                  validateOptions={`required|minSalary:${salario}`}
                  inputComponent={NumberFormatCustomMax9}
                />
              </Grid>
              <Grid item lg={6} md={6} sm>
                <DatePickerForm
                  id={'date'}
                  label={'Fecha a partir de la cual aplica'}
                  value={date}
                  onChange={handleDateFormPicket}
                  autoOk
                  validator={simpleValidator.current}
                  validateOptions={'required'}
                />
              </Grid>
              {/* <Grid item className={classes.modalInfo} lg={12} md={12} sm>
                <span className={classes.modalInfoText}>
                  Cuando reportes la nómina deberás generar un segundo documento
                  debido a que hiciste un cambio salarial del empleado en una
                  fecha diferente al 1 del mes.
                </span>
              </Grid> */}
            </>
          ) : (
            <>
              <Grid item className={classes.modalText} lg={12} md={12} sm>
                Por favor ingresa la información del salario
              </Grid>
              <Grid item lg={12} md={12} sm>
                <InputForm
                  id={'salary'}
                  label={'Salario *'}
                  value={salary}
                  onChange={syncChangesValue}
                  nameValidator={'Valor'}
                  validator={simpleValidator.current}
                  validateOptions={'required'}
                  inputComponent={NumberFormatCustomMax9}
                />
              </Grid>
            </>
          )}
          <Grid item className={classes.button} lg={4} md={4} sm>
            <ButtonPrimary text={'Continuar'} type={'submit'} />
          </Grid>
        </Grid>
      </div>
    </form>
  );
});

export default ModalSalary;
