import {
    SAVE_CLIENT,
    UPDATE_CLIENT,
    GET_CLIENTS_LIST,
    LOAD_CLIENT,
    LOADING_SAVE_CLIENT,
    LOADING_LIST_CLIENT,
    LOAD_CLIENT_DETAIL,
    DELETE_CLIENT,
    GET_FILTERLIST_CLIENT,
    LOADING_FILTERLIST_CLIENT,
    LOADING_DETAIL_CLIENT,
    LOADING_GET_CLIENT,
    GET_CLIENTS_RESUME_LIST,
    LOADING_STANDOUT_CLIENT,
    LOADING_DELETE_CLIENT,
    SELECTED_ID_CLIENT,
    LOADING_GET_EXISTING_CLIENT
} from '../actions/types'

import isEmpty from '../utils/isEmpty'

const initialState = {
    loadingSave: false,
    loadingDetail: false,
    selectedId: 0,
    loadingGetClient: false,
    clientIdSelected: null,
    loadingGetExistingClient: false,
}


export default function (state = initialState, action) {

    switch (action.type) {
        case SAVE_CLIENT:
            return {
                ...state,
                saveClientResponse: action.payload
            }
        case GET_CLIENTS_LIST:
            return {
                ...state,
                getClientListResponse: action.payload
            }
        case LOAD_CLIENT:
            return {
                ...state,
                loadClientObject: action.payload,
                editClientStatus: !isEmpty(action.payload) ? true : false
            }
        case UPDATE_CLIENT:
            return {
                ...state,
                updateClientResponse: action.payload,
            }

        case LOAD_CLIENT_DETAIL:
            return {
                ...state,
                loadClientDetail: action.payload,
            }

        case LOADING_SAVE_CLIENT:
            return {
                ...state,
                loadingSave: action.payload,
            }
        case LOADING_LIST_CLIENT:
            return {
                ...state,
                loadingListClients: action.payload,
            }
        case DELETE_CLIENT:
            return {
                ...state,
                deleteClientResponse: action.payload,
            }

        case GET_FILTERLIST_CLIENT:
            return {
                ...state,
                getFilterListClient: action.payload,
            }

        case LOADING_FILTERLIST_CLIENT:
            return {
                ...state,
                loadingFilterList: action.payload,
            }
        case LOADING_DETAIL_CLIENT:
            return {
                ...state,
                loadingDetail: action.payload
            } 
        case LOADING_GET_CLIENT:
            return{
                ...state,
                loadingGetClient: action.payload
            }
        case GET_CLIENTS_RESUME_LIST:
            return {
                ...state,
                clientsResumeList: action.payload
            }
        case LOADING_STANDOUT_CLIENT:
            return {
                ...state,
                loadingStandOutClient: action.payload
            }
        case LOADING_DELETE_CLIENT:
            return {
                ...state,
                loadingDeleteClient: action.payload
            }

        case SELECTED_ID_CLIENT:
            return{
                ...state,
                clientIdSelected: action.payload
            }
        case LOADING_GET_EXISTING_CLIENT:
            return {
                ...state,
                loadingGetExistingClient: action.payload
            }
        default:
            return state
    }
} 