/**
 * Homologa codigo tipo identificación DIAN a prefijo
 * @param {*} type
 * @returns
 */
export const homologateDescripcionId = (type) => {
  switch (type.replaceAll(' ', '')) {
    case 'RC':
      return '11';
    case 'TI':
      return '12';
    case 'CC':
      return '13';
    case 'TE':
      return '21';
    case 'CE':
      return '22';
    case 'NIT':
      return '31';
    case 'PA':
      return '41';
    case 'DIE':
      return '42';
    case 'PEP':
      return '47';
    default:
      return '';
  }
};

export const dianTypeDocumentDescription = (code) => {
  switch (code) {
    case 102:
      return 'Documento soporte de nómina';
    case 103:
      return 'Nota de ajuste (Remplazo)';
    case 104:
      return 'Nota de ajuste (Eliminación)';
    default:
      return '';
  }
};
