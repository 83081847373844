import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import EmployeesService from '../services/employeesServices';
import PayrollServices from '../services/payrollServices';

import {
  getResumeEmployeesAction,
  getExistingEmployeeAction,
  updatePayrollStateAction,
  changeEditingStatusAction,
  changeOpenRegisterEmployeeAction,
  changeOpenMassiveLoadEmployeeAction,
  fillSupportEmployeeAction,
  retireEmployeeAction,
  loadingRetireEmployeeAction,
  loadingGetEmployeesAction,
  loadingDetailEmployeAction,
} from '../actions/employeeActions';
import { getLicenceDataAction } from '../actions/dashboardAction';
import { getFullName } from '../helpers/functions.helpers';
import {
  ConfirmAlert2,
  SuccessAlert2,
  WarningAlertemployee,
} from '../helpers/alert.helpers';
import { formatDate } from '../utils/general';
import { showErrorMessage } from '../utils/showErrorService';

export const useEmployees = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // Observables store (redux)
  const {
    employeesResumeList: listResponse,
    loademployeeObject: employeeDetail,
    loadingGetEmployees,
    loadingDetailEmployee,
  } = useSelector((s) => s.employeeReducer, shallowEqual);

  const isEditing = useSelector(
    (state) => state.employeeReducer.registerEmployeeObject.editEmployeeStatus,
  );

  const getLicenceDataEmployees = useSelector(
    (state) => state.dashboardReducer.licenceData?.employees || null,
  );

  //PRIVATE METHODS PRIVATE

  /**
   * Filtra los empleados activos
   * @returns {array} Retorna listado empleados
   */
  const listFilterEmployeeActives = useMemo(() => {
    const actives = listResponse.filter((item) => item.retirado === 0);
    return actives;
  }, [listResponse]);

  /**
   * Filtra los empleados retirados
   * @returns {array} Retorna listado empleados
   */
  const listFilterEmployeeRemoves = useMemo(() => {
    const actives = listResponse.filter((item) => item.retirado === 1);
    return actives;
  }, [listResponse]);

  /**
   * Filtra los empleados con marca de favoritos
   * @returns {array} Retorna listado empleados
   */
  const listFilterEmployeeFavorites = useMemo(() => {
    const actives = listResponse.filter((item) => item.favorito === true);
    return actives;
  }, [listResponse]);

  /**
   * Arma nombre completo para listado empleados
   * @param {*} arrayDestructure
   * @returns
   */
  const destructureArrayFilter = (arrayDestructure) => {
    const array = arrayDestructure.map((item) => ({
      ...item,
      text: getFullName(
        item.primerApellido,
        item.segundoApellido,
        item.primerNombre,
        item.otroNombre,
      ),
    }));
    return array;
  };

  /**
   * Consulta total listado empleados
   */
  const fillListEmployees = useCallback(() => {
    dispatch(loadingGetEmployeesAction(true));

    EmployeesService.getAllEmployeesService()
      .then((resp) => {
        const employees = destructureArrayFilter(resp?.data?.result ?? []);
        dispatch(getResumeEmployeesAction(employees));
      })
      .catch((error) => {
        showErrorMessage(error, 'Error consultando listado empleados');
      })
      .finally(() => {
        dispatch(loadingGetEmployeesAction(false));
      });
  }, [dispatch]);

  /**
   * Fill employee by id REDUX : loadEmployeeDetail
   * @param {*} id
   */
  const fillEmployeeById = (id) => {
    dispatch(loadingDetailEmployeAction(true)); //Empieza loading
    dispatch(getExistingEmployeeAction(null));
    EmployeesService.getEmployeeByIdService(id)
      .then((result) => {
        if (result.status === 200) {
          const employee = result?.data?.result || null;
          employee && dispatch(getExistingEmployeeAction(employee));
          fillDetailPayrollSupport(id);
        }
      })
      .catch((error) => {
        showErrorMessage(
          error,
          'Error consultando información de empleados por identificador',
        );
      })
      .finally(() => {
        dispatch(loadingDetailEmployeAction(false));
      });
  };

  /**
   * Cambia estado favorito en DB
   * @param int Id empleado
   * @param boolean estado favorito
   */
  const changeStateFavorite = (employeeId, isFavorite) => {
    setLoading(true);
    EmployeesService.updateFavoriteEmployeeService(employeeId, isFavorite)
      .then((result) => {
        if (result.status === 200) {
          dispatch(
            updatePayrollStateAction({
              idEmployee: employeeId,
              isFavorite: isFavorite,
            }),
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorMessage(error, 'Error al añadir destacado');
        setLoading(false);
      });
  };

  /**
   * Cambia estado retirado en DB
   * @param idEmpleado Id empleado
   * @param causalId Id causal de retiro
   * @param fechaRetiro Fecha Retiro
   */
  const retireEmployee = useCallback(
    (
      idEmpleado,
      causalId,
      fechaRetiro,
      primernombre = '',
      primerapellido = '',
    ) => {
      dispatch(loadingRetireEmployeeAction(true));

      EmployeesService.retireEmployeeService(idEmpleado, causalId, fechaRetiro)
        .then((result) => {
          const statusCode = result.data.statusCode;
          if (statusCode === '200' || statusCode === '201') {
            dispatch(
              retireEmployeeAction({
                id: idEmpleado,
                causalid: causalId,
                fecharetiro: fechaRetiro,
              }),
            );

            const message = `Retiraste el Empleado ${primernombre ?? ''} ${
              primerapellido ?? ''
            } de manera exitosa.`;
            SuccessAlert2('Listo!!!', message);
          }
        })
        .catch((error) => {
          showErrorMessage(error, 'Error al retirar empleado.');
        })
        .finally(() => {
          dispatch(loadingRetireEmployeeAction(false));
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  /**
   * Carga empleado por documento
   * @param typeDocument Tipo de documento
   * @param document Documento
   */
  const setEmployeeByDocument = useCallback(
    (typeDocument, document) => {
      EmployeesService.getEmployeeByDocumentService(typeDocument, document)
        .then((employee) => {
          if (employee?.data?.id === 0) {
            changeEditingStatus(false);
            dispatch(getExistingEmployeeAction(null));
          } else {
            changeEditingStatus(true);
            dispatch(getExistingEmployeeAction(employee));
          }
        })
        .catch((error) => {
          showErrorMessage(error, 'Error consultando información del empleado');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  /**
   * Carga un detalle de soporte de nomina con un id determinado para una nomina
   * @param {object} employeeId Objeto payroll
   */
  const fillDetailPayrollSupport = useCallback(
    (employeeId) => {
      const dataPayload = {
        empleadoid: employeeId || null,
        Offset: 0,
        Limit: 2,
        filtro: null,
        Fechadesde: null,
        Fechahasta: null,
        Aceptado: false,
        Rechazado: false,
        Destacado: false,
        Historial: false,
      };

      if (employeeId) {
        PayrollServices.GetPayrollSupport(dataPayload)
          .then((result) => {
            const data = result.data.result || null;

            !!data && dispatch(fillSupportEmployeeAction(data));
          })
          .catch((error) => {
            showErrorMessage(
              error,
              'Error consultando detalle de soportes de nomina',
            );
          });
      }
    },
    [dispatch],
  );

  /**
   * Verifica si un documento existe en DB
   * @param typeDocument Tipo de documento
   * @param document Documento
   */
  const existAnyEmployeeByDocument = useCallback(
    (typeDocument, document, cleanDocument, redirectToRetiredEmployees) => {
      if (typeDocument && document) {
        EmployeesService.getEmployeeByDocumentService(typeDocument, document)
          .then((employee) => {
            if (
              employee.data.result &&
              employee.data.result.documento === document &&
              employee.data.result.id !== 0
            ) {
              if (!employee.data.result.retirado) {
                WarningAlertemployee(
                  '',
                  `El número de documento que estás ingresando no puede ser utilizado por que ya pertenece al empleado ${employee.data.result?.primernombre} ${employee.data.result?.primerapellido}`,
                  cleanDocument,
                );
              } else {
                ConfirmAlert2(
                  `El empleado ${employee.data.result?.primernombre} ${
                    employee.data.result?.primerapellido
                  } que estas intentado crear fue retirado el ${formatDate(
                    employee.data.result?.fecharetiro,
                    'dd/MM/yyyy',
                  )}.</br>
                  Si deseas reintegrarlo debes dirigirte a la pestaña Retirados y dale clic a la opcion “Reintegrar”.`,
                  redirectToRetiredEmployees,
                  'Ir a retirados',
                  cleanDocument,
                );
              }
            }
          })
          .catch((error) => {
            showErrorMessage(
              error,
              'Error al consultar el número de documento del empleado.',
            );
          });
      }
    },

    [],
  );

  /**
   * guarda en el estado registro formulario
   * @param {obj} isRegister Estado open del formulario
   */
  const changeOpenRegisterEmployee = useCallback(
    (isRegister) => {
      dispatch(changeOpenRegisterEmployeeAction(isRegister));
    },
    [dispatch],
  );

  /**
   * guarda en el estado en proceso carga masiva
   * @param {obj} isRegister Estado open del formulario
   */
  const changeOpenLoadMassiveEmployee = useCallback(
    (isRegister) => {
      dispatch(changeOpenMassiveLoadEmployeeAction(isRegister));
    },
    [dispatch],
  );

  /**
   * cambia Estado edicion o creacion para nuevo empleado
   * @param {obj} isEditing Estado edicion o creacion para nuevo empleado
   */
  const changeEditingStatus = useCallback(
    (isEditing) => {
      dispatch(changeEditingStatusAction(isEditing));
    },
    [dispatch],
  );

  /**
   * llena empleados de licenciamiento
   */
  const fillLicenceDataEmployees = useCallback(() => {
    dispatch(getLicenceDataAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLicenceDataAction]);

  return {
    loading,
    listResponse,
    loadingDetailEmployee,
    employeeDetail,
    fillListEmployees,
    fillEmployeeById,
    loadingGetEmployees,
    listFilterEmployeeRemoves,
    listFilterEmployeeActives,
    listFilterEmployeeFavorites,
    changeStateFavorite,
    retireEmployee,
    changeOpenRegisterEmployee,
    changeOpenLoadMassiveEmployee,
    changeEditingStatus,
    isEditing,
    setEmployeeByDocument,
    existAnyEmployeeByDocument,
    fillDetailPayrollSupport,
    getLicenceDataEmployees,
    fillLicenceDataEmployees,
  };
};
