import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, makeStyles } from '@material-ui/core';

import { CardTypeInformation } from './';
import { countConcepts } from './helpers';

const useStyles = makeStyles((theme) => ({
  gridContainerList: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: 'auto',
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '10px',
    },
  },
  gridResult: {
    color: '#FFFF',
    fontWeight: '300',
    backgroundColor: '#3B3B3B',
    borderRadius: '4px',
    height: '80px',
    maxWidth: '344px',
    margin: '0 5px',
    [theme.breakpoints.down(980)]: {
      maxWidth: '100%',
    },
  },
  gridContainerTittle: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  gridContainerResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridTittle: {
    width: '97%',
    textAlign: 'center',
    paddingLeft: '25px',
    paddingTop: '10px',
    fontFamily: 'Muli',
    fontStyle: 'italic',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  gridText: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '40px',
    lineHeight: '50px',
    textAlign: 'center',
    marginRight: '5px',
  },
  textUpdate: {
    color: '#F6F6F6',
  },
  textError: {
    color: '#ED8D84',
  },
  textValidate: {
    color: '#2CC63E',
  },
  textTransmit: {
    color: '#16B1F3',
  },
}));

export const CardLoadInformation = ({ activeStep }) => {
  const classes = useStyles();
  const { bulkLoadPayrollResponse } = useSelector(
    (s) => s.payrollBulkLoadReducer,
  );
  const { menuPayrollBulkLoad } = useSelector((state) => state.menuReducer);
  const {
    titleTooltipRegistrado,
    messageTooltiptitleTooltipRegistrado,
    titleTooltipTransmitido,
    messageTooltiptitleTooltipTransmitido,
    titleTooltipError,
    messageTooltiptitleTooltipError,
    titleTooltipMixto,
    messageTooltiptitleTooltipMixto,
  } = menuPayrollBulkLoad;

  const [dataMap, setDataMap] = useState([]);

  const { Transmitido, Registrado, Mixto, Error } = countConcepts(
    bulkLoadPayrollResponse,
  );

  const resultData = [
    {
      data: Registrado,
      title: titleTooltipRegistrado,
      description: messageTooltiptitleTooltipRegistrado,
      status: 'Insertados',
    },
    {
      data: Transmitido,
      title: titleTooltipTransmitido,
      description: messageTooltiptitleTooltipTransmitido,
      status: 'Transmitidos',
    },
    {
      data: Error,
      title: titleTooltipError,
      description: messageTooltiptitleTooltipError,
      status: 'Error',
    },
    {
      data: Mixto,
      title: titleTooltipMixto,
      description: messageTooltiptitleTooltipMixto,
      status: 'Mixtos',
    },
  ];

  useEffect(() => {
    setDataMap(filterData({ activeStep, Registrado, resultData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  /**
   * * Filtra los datos según el valor de activeStep y Registrado.
   *
   * @param {Object} options - Un objeto con las siguientes propiedades:
   * @param {number} options.activeStep - El paso activo.
   * @param {number} options.Registrado - El valor de Registrado.
   * @param {Array} options.resultData - Los datos a filtrar.
   *
   * @returns {Array} - Los datos filtrados según las condiciones.
   */
  const filterData = ({ activeStep, Registrado, resultData }) => {
    if (activeStep === 2) return resultData;
    if (activeStep === 3) {
      return Registrado === 0
        ? [resultData[2]]
        : Registrado > 0
        ? [resultData[0]]
        : [];
    }
    return [];
  };

  return (
    <Grid container className={classes.gridContainerList}>
      {dataMap.map((result, index) => (
        <CardTypeInformation key={index} {...result} classes={classes} />
      ))}
    </Grid>
  );
};
