import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { FormatDecimal } from '../common/formatDecimal';

import expenseIcon from '../../images/icons/expenses.png';
import CustomProgress from '../Progress/progress.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5E5E5E',
    color: theme.palette.primaryColor,
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginLeft: 27,
    },
    '& .MuiCardHeader-root': {
      paddingTop: 5,
      paddingBottom: 0,
    },
    '& .MuiCardHeader-avatar': {
      marginRight: 5,
    },
  },
  content: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: 'normal',
    display: 'grid',
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
  avatar: {
    color: '#2CC63E',
    padding: '5px 3px',
  },
  value: {
    color: '#ED8D84',
  },
  iconAction: {
    color: '#FFFFFF',
  },
}));
const animate = 'animate__animated animate__fadeIn animate__faster';
export const ExpenseCard = ({
  data,
  handleUpdate,
  handleDelete,
  disabled,
  loading,
}) => {
  const classes = useStyles();

  const {
    identificador,
    concepto,
    valor,
    porcentaje,
    descripcion,
    aplicaPorcentaje,
    aplicaValor,
  } = data;

  return (
    <Card className={`${classes.root} ${animate}`}>
      <CardHeader
        avatar={
          <img src={expenseIcon} className={classes.avatar} alt={'income'} />
        }
        classes={{
          title: classes.title,
        }}
        action={
          !disabled && (
            <>
              <Tooltip title="Editar">
                <IconButton onClick={handleUpdate}>
                  <CreateIcon className={classes.iconAction} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                {loading === identificador ? (
                  <IconButton>
                    <CustomProgress size={15} />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon className={classes.iconAction} />
                  </IconButton>
                )}
              </Tooltip>
            </>
          )
        }
        title={` ${concepto} ${descripcion ?? ''}`}
      />
      <CardContent className={classes.content}>
        {aplicaPorcentaje && <FormatDecimal suffix={'%'} value={porcentaje} />}
        {aplicaValor && (
          <span className={classes.value}>
            <FormatDecimal prefix={'$'} value={valor} />
          </span>
        )}
      </CardContent>
    </Card>
  );
};
