import React, { useEffect, useState } from 'react';
// Components
import Detail from './detail';
import { SupportsList } from '../removable/list';
// Material
import Grid from '@material-ui/core/Grid';
// hooks
import { usePayroll } from '../../../hooks/usePayroll';
import NotificationNotCreated from '../../notification/notificationNotCreated.component';

const Edited = ({ setContentTab }) => {
  const [employeeIdSelected, setEmployeeIdSelected] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const {
    fillDetailPayrollSupport,
    detailSupports,
    cleanPayrollSupport,
    loadingSupportsDetail,
    listSupports,
    loadingSupportsList,
  } = usePayroll();

  /**
   * Desmontado componente
   */
  useEffect(() => {
    return () => {
      cleanPayrollSupport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Carga identificador de empleado para lista
   */
  useEffect(() => {
    setEmployeeIdSelected(detailSupports?.id ?? 0);
  }, [detailSupports]);

  /**
   * Mostrar notificación
   */
  useEffect(() => {
    setShowNotification(
      !detailSupports && listSupports.length === 0 && !loadingSupportsList
        ? true
        : false,
    );
  }, [detailSupports, listSupports, loadingSupportsList]);

  /**
   * Evento, consulta detalle de empleado seleccionado en lista
   * @param {*} item
   */
  const handleEmployeeSelection = (item) => {
    setEmployeeIdSelected(item.id);
    fillDetailPayrollSupport(item);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ display: showNotification ? 'flex' : 'none' }}
      >
        <Grid item lg={12} md={12} sm>
          <NotificationNotCreated
            text={'No tienes soportes de nómina editados'}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ display: !showNotification ? 'flex' : 'none' }}
      >
        <Grid item lg={5} md={6} sm>
          <SupportsList
            handleSelection={(item) => {
              handleEmployeeSelection(item);
            }}
            selectedId={employeeIdSelected}
          />
        </Grid>
        <Grid item lg={7} md={6} sm>
          <Detail
            detailSupports={detailSupports}
            loadingSupportsDetail={loadingSupportsDetail}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Edited;
