/**
 * * Valida si el tamaño del archivo es menor o igual al tamaño máximo especificado.
 *
 * @param {File} file - El objeto de archivo a validar.
 * @param {number} maxSizeKB - El tamaño máximo permitido en kilobytes (KB).
 * @returns {boolean} - True si el tamaño es válido, false en caso contrario.
 */
export const validateFileSize = (file, maxSizeKB) => {
  const fileSizeKB = file.size / 1024; // Tamaño del archivo en KB

  return fileSizeKB <= maxSizeKB;
};
