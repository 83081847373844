import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
// react-router
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
// custom hook
import { usePayroll } from '../../../../../hooks/usePayroll';
// components
import SelectForm from '../../../../../components/select/select.component';
import SimpleModal from '../../../../../components/modal/simpleModal.component';
import ButtonPrimary from '../../../../../components/button/buttonPrimary.component';
import FileModal from '../../../../../components/modal/fileModal.component';
import CustomTooltip from '../../../../../components/tooltip/tooltip.component';
import TooltipMessage from '../../../../../components/tooltip/tootltip-message.component';
import ResponseModal from '../../../../../components/modal/responseModal.component';

// personalized icons
import ErrorReportingIcon from '../../../../../images/icons/errorReporting.png';
import TransferedBlankIcon from '../../../../../images/icons/transferedBlank.png';
import PendingTransferIcon from '../../../../../images/icons/cloud.png';
// helpers
import { reactValidatorOptions } from '../../../../../helpers/simpleReactValidator';
import { SuccessAlert2 } from '../../../../../helpers/alert.helpers';
import { getStringFromDate } from '../../../../../helpers/functions.helpers';
import isEmpty from '../../../../../utils/isEmpty';
// personalized form
import ModalMail from './modailMail';
import ModalDeleteSupport from './modalDeleteSupport';
import { getPayrollEmployeeAction } from '../../../../../actions/payrollAdjustmentAction';
import { showErrorAction } from '../../../../../actions/generalAction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5C5C5C', //  theme.palette.fourthBackgroundColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
    margin: '1% 0',
  },
  header: {
    padding: '1% 2%',
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.6em',
    color: '#FFFFFF',
  },
  content: {
    fontSize: 14,
    fontWeight: 300,
    display: 'flex',
  },
  showLayer: {
    display: 'block',
  },
  hideLayer: {
    display: 'none',
  },
  letter: {
    color: '#FFFFFF',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  widthMax: {
    width: '100%',
  },
  paddingIcons: {
    paddingTop: '20%',
  },
  width99: {
    width: '99%',
  },
  width98: {
    width: '98%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width97: {
    width: '97%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  width90: {
    width: '90%',
    boxSizing: 'border-box',
    height: '1px',
    border: '1px solid rgba(255,255,255,0.2)',
  },
  letterTaxes: {
    color: '#2CC63E',
  },
  totalInvoiceDetail: {
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: '800',
    letterSpacing: '0',
    textAlign: 'left',
  },
  colorIcon: {
    color: '#2CC63E',
  },
  h1Card: {
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
  },
  cover: {
    width: '100%',
    height: '100%',
  },
  containerLoading: {
    paddingTop: 200,
  },
  rootchoose: {
    backgroundColor: '#16B1F3',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  rootsel: {
    backgroundColor: '#3B3B3B',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  customProgress: {
    display: 'inline-grid',
    verticalAlign: 'middle',
  },
  iconToAssign: {
    color: '#FFF',
  },
  buttonToAssign: {
    color: theme.palette.secundaryColor,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cardContent: {
    padding: '1% 2%',
    color: '#FFF',
  },
  carContentMore: {
    display: 'grid',
    borderRadius: '5px',
    marginBottom: '2%',
    gridAutoFlow: 'column',
    backgroundColor: '#6C6C6C',
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
  cardContentInfo: {
    gridColumn: '2/12',
    '& p ': {
      fontSize: '14px',
    },
  },
  showMoreText: {
    color: '#2CC63E',
    fontSize: '14px',
    '& * ': {
      color: '#2CC63E',
    },
  },
  showMoreActions: {
    display: 'grid',
    gridTemplateColumns: 'inherit',
    '& * ': {
      color: '#FFF',
    },
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: 'max-content',
    height: '50px',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
    padding: '2%',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
  contentBody: {
    padding: '0 3em',
  },
  wordBreakGreen: {
    wordBreak: 'break-all',
    color: theme.palette.secundaryColor,
    fontFamily: 'Muli',
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));

const GetIconState = ({ state, fechavalidacion }) => {
  return (
    <>
      {state === 2 ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Transmisión a DIAN"
              message={
                <span>
                  Validación DIAN:
                  <span style={{ fontWeight: 'bold' }}> Aceptada </span>
                </span>
              }
              description={
                <div>
                  Fecha validacíon:
                  <span style={{ fontWeight: 'bold' }}>
                    {format(new Date(fechavalidacion), 'yyyy/MM/dd')}
                  </span>
                </div>
              }
              // botton="Prueba"
              isPayrool={true}
              href={null}
              bgColor="#7DD1F8"
            />
          }
          iconEnvolved={
            <img
              src={TransferedBlankIcon}
              alt="Transmitido"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : state === 3 ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Error DIAN - Documento con errores"
              message="El documento presenta una inconsistencia ante la DIAN, has clic en el siguiente botón para reportarlo a nuestro equipo de soporte y poder ayudarte."
              // botton="Prueba"
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={ErrorReportingIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : state === 4 ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Pendiente de sincronización con DIAN."
              message="Este Ítem esta pendiente de sincronización con DIAN."
              // botton="Prueba"
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={PendingTransferIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : state === 6 ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Error DIAN - Documento de Respuesta de DIAN con Error"
              message="El documento de respuesta de DIAN contiene errores, por favor repórtalo a nuestro equipo de soporte para poder ayudarte."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={PendingTransferIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

// components
const PaymentsDetail = React.memo(
  ({ support, sendSupportToMail, empleadoid }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const [openMore, setOpenMore] = useState(false);
    const [observationDelete, setObservationDelete] = useState('');
    const [userAction, setUserAction] = useState(null);
    const [openModalMail, setOpenModalMail] = useState(false);
    const [openModalChoose, setOpenModalChoose] = useState(false);
    const [openModalDeleting, setOpenModalDeleting] = useState(false);
    const [, forceUpdate] = useState();
    const [openModalFile, setOpenModalFile] = useState(false);
    const [modalData, setModalData] = React.useState({
      type: '',
      title: '',
      subtitle: '',
      body: '',
      modalImage: '',
      open: false,
      closeElement: '',
      onCloseElement: 0,
    });

    const { loading: loadingPayrollAdjustment, payrollData } = useSelector(
      (s) => s.payrollAdjustmentReducer,
    );

    // custom hooks
    const {
      //saveNavigation,
      fillPreviewFiles,
      filesPreview,
      updateSupportPayrollFav,
      deleteSupportDocument,
      deleteResponse,
    } = usePayroll();

    useEffect(() => {
      if (deleteResponse) {
        if (deleteResponse && deleteResponse.responseCode === 'R19') {
          setModalData({
            ...modalData,
            type: 'warning',
            title: 'Upss...!!!',
            subtitle: '¡¡¡Ha ocurrido un error!!!',
            body: <div>{deleteResponse.statusMessage}</div>,
            modalImage: 'warning',
            open: true,
            closeElement: 'Cerrar',
            onCloseElement: 0,
          });
        }
        if (deleteResponse && deleteResponse.responseCode === 'A13') {
          SuccessAlert2(
            'Listo!!!',
            'Eliminaste el documento soporte de nomina de manera exitosa',
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteResponse]);

    /**
     * Redirecciona modulo generar nota ajuste
     */
    useEffect(() => {
      if (!!payrollData.soporteid) {
        handleCloseModalChoose();
        history.push('/payrollAdjustment');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payrollData.soporteid]);

    // React validator
    const simpleValidator = useRef(
      new SimpleReactValidator(reactValidatorOptions),
    );

    const handleCloseModalChoose = () => {
      setUserAction(null);
      setOpenModalChoose(false);
    };

    const handleCloseModalFile = () => {
      fillPreviewFiles(null);
      setOpenModalFile(false);
    };

    const handleCloseModalDeleting = () => {
      setObservationDelete('');
      setOpenModalDeleting(false);
    };

    const validarestadonomina = (estadoid) => {
      switch (estadoid) {
        case 2:
          return 'Aceptado';
        case 3:
          return 'Error en documento';
        case 4:
        case 6:
          return 'Ocurrió error al enviar a la Dian';
        default:
          return '';
      }
    };

    const OpenMailModal = () => {
      if (isEmpty(support.codigounico) || support?.estadoid !== 2) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Oops!!!',
          subtitle: 'Documento no disponible',
          body: (
            <div>
              {
                'Nómina en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá enviarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
      } else {
        setOpenModalMail(true);
      }
    };

    const handleCloseModalMail = () => {
      setOpenModalMail(false);
    };

    /**
     * Confirma operación generación nomina de ajuste
     * @param {*} e
     */
    const onSubmitModal = (e) => {
      e.preventDefault();

      //Validación formulario
      if (!simpleValidator.current.allValid()) {
        simpleValidator.current.showMessages();
        forceUpdate(Math.random());
        return;
      }

      if (userAction === '1') {
        dispatch(getPayrollEmployeeAction(support.id));
      } else {
        handleCloseModalChoose();
        setOpenModalDeleting(true);
      }
    };

    const onSubmitModalDeleting = (e) => {
      e.preventDefault();
      if (simpleValidator.current.allValid()) {
        handleCloseModalDeleting();
        callServiceDelete();
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(Math.random());
      }
    };

    const callServiceDelete = () => {
      deleteSupportDocument(support.id, observationDelete);
    };
    /**
     * Consulta documento en base64 para visualización
     */
    const openFileModal = () => {
      if (isEmpty(support.codigounico) || support?.estadoid !== 2) {
        setModalData({
          ...modalData,
          type: 'warning',
          title: 'Upss...!!!',
          subtitle: 'Archivo no disponible',
          body: (
            <div>
              {
                'Nómina en proceso de envío a DIAN. Mientras, el documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá visualizarlo.'
              }
            </div>
          ),
          modalImage: 'warning',
          open: true,
          closeElement: 'Cerrar',
          onCloseElement: 0,
        });
        dispatch(
          showErrorAction('Error al cargar el documento de soporte de pago.'),
        );
      } else {
        fillPreviewFiles(support.codigounico);
        setOpenModalFile(true);
      }
    };

    /**
     * Cierra Modal, Notificación codigo unico no valido
     */
    const handleResponseModal = () => {
      setModalData({
        ...modalData,
        open: false,
      });
    };

    /**
     * Sincroniza nuevo valor de Input con state
     * @param {*} e Evento
     */
    const syncChanges = (e) => {
      const value = e.target.value;
      const name = e.target.name;
      console.log('SYNC VALUES ', value, name, name === 'observaciones');
      if (name === 'userAction') setUserAction(value);
      if (name === 'observaciones') setObservationDelete(value);
    };

    /**
     * Modal confirmación generación nomina de ajuste
     * @returns
     */
    const modalChoosing = () => {
      return (
        <form>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.contentBody}
            >
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                Ten en cuenta las siguientes opciones al editar este
                desprendible.
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                <span style={{ color: '#16b1f3' }}>Editar: </span> Podrás
                realizar los cambios que requieras, ten en cuenta que se
                remplazará el desprenderle original con el nuevo documento que
                generes.
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                <span style={{ color: '#16b1f3' }}>Eliminar: </span>
                Borrarás completamente el desprendible de nómina generado, este
                desaparecerá del empleado y de su lista de desprendibles, no
                obstante podrás verlo en el tab de editados.
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                ¿Que deseas hacer?
              </Grid>
              <Grid style={{ margin: '0 auto' }} md={5} sm={5} xs={5}>
                <SelectForm
                  label={'Seleccione *'}
                  name="userAction"
                  value={userAction}
                  options={[
                    { value: '1', text: 'Editar' },
                    { value: '2', text: 'Eliminar' },
                  ]}
                  validator={simpleValidator.current}
                  validateOptions={'required'}
                  onChange={(e) => syncChanges(e)}
                />
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm style={{ margin: '0 auto' }}>
              <ButtonPrimary
                text={'Continuar'}
                onClick={onSubmitModal}
                type={'button'}
                style={{ color: '#FFF' }}
                loading={loadingPayrollAdjustment}
              />
            </Grid>
            <br></br>
          </Grid>
        </form>
      );
    };

    const modalMails = () => {
      return (
        <ModalMail
          mailTo={support?.correoelectronico}
          codigounico={support?.codigounico}
          sendSupportToMail={sendSupportToMail}
        />
      );
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.title,
          }}
          title={getStringFromDate(new Date(support?.periodo), true)}
          subheader=""
          action={
            <>
              {!support?.eliminado ? (
                <>
                  {/* <IconButton aria-label="edit">
                    <EditIcon
                      className={classes.iconToAssign}
                      onClick={() => setOpenModalChoose(true)}
                    />
                  </IconButton> */}
                  <IconButton aria-label="view">
                    <VisibilityIcon
                      className={classes.iconToAssign}
                      onClick={() => openFileModal()}
                    />
                  </IconButton>
                  <IconButton aria-label="email">
                    <EmailIcon
                      className={classes.iconToAssign}
                      onClick={() => OpenMailModal()}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="favorite"
                    onClick={() =>
                      updateSupportPayrollFav(support.id, !support.favorito)
                    }
                  >
                    {support.favorito ? (
                      <StarIcon className={classes.iconToAssign} />
                    ) : (
                      <StarBorderIcon className={classes.iconToAssign} />
                    )}
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton aria-label="view">
                    <VisibilityIcon
                      className={classes.iconToAssign}
                      onClick={() => openFileModal()}
                    />
                  </IconButton>
                  <IconButton aria-label="email">
                    <EmailIcon
                      className={classes.iconToAssign}
                      onClick={() => OpenMailModal()}
                    />
                  </IconButton>
                </>
              )}
            </>
          }
        />
        <div className={classes.cardContent}>
          <span
            style={{
              color: !support?.eliminado ? '#FFFFFF' : '#ED8D84 ',
            }}
          >
            {Number(support?.tipodocumento) === 2
              ? 'Tipo doc: Nota de ajuste'
              : support?.eliminado
                ? 'Tipo doc: Documento Eliminado'
                : ''}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Consecutivo: {support?.consecutivo}</span>
            <span
              style={{
                color: !support?.eliminado ? '#2CC63E' : '#ED8D84 ',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              {'$' + new Intl.NumberFormat('es-CO').format(support?.valorneto)}
            </span>
          </div>
          <span>Validación DIAN: {validarestadonomina(support?.estadoid)}</span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Fecha validación DIAN:
              {support?.fechavalidaciondian && support?.estadoid === 2
                ? ` ${format(
                  new Date(support?.fechavalidaciondian),
                  'dd/MM/yyyy',
                )}`
                : ''}
            </span>
            {support?.historial ? (
              openMore ? (
                <span className={classes.showMoreText}>
                  Ver menos
                  <IconButton aria-label="Ver menos">
                    <ExpandLessIcon
                      className={classes.iconToAssign}
                      onClick={() => setOpenMore(!openMore)}
                    />
                  </IconButton>
                </span>
              ) : (
                <span className={classes.showMoreText}>
                  Ver más
                  <IconButton aria-label="Ver mas">
                    <ExpandMoreIcon
                      className={classes.iconToAssign}
                      onClick={() => setOpenMore(!openMore)}
                    />
                  </IconButton>
                </span>
              )
            ) : (
              <IconButton aria-label="favourite">
                <GetIconState
                  state={support?.estadoid}
                  className={classes.iconToAssign}
                  fechavalidacion={support?.fechavalidaciondian}
                />
              </IconButton>
            )}
          </div>
          {/* card -  view more  */}
          {openMore ? (
            <>
              {/* insertar ciclo para recorrer las listas de informacion adicional  */}
              {support.historial &&
                support.historial.map((hist) => (
                  <div className={classes.carContentMore}>
                    <IconButton aria-label="Editado">
                      <EditIcon
                        style={{
                          color: '#2CC63E',
                        }}
                      />
                    </IconButton>
                    <div className={classes.cardContentInfo}>
                      <p
                        style={{
                          fontWeight: '600',
                          fontSize: '16px',
                        }}
                      >
                        Tipo Doc:{' '}
                        {Number(hist.tipoajuste) === 1
                          ? 'Documento soporte de nómina'
                          : Number(hist.tipoajuste) === 2
                            ? 'Nota de ajuste'
                            : support?.eliminado
                              ? 'Documento Eliminado'
                              : ''}
                      </p>
                      <p>Motivo reemplazo: {hist.observacion}</p>
                      <p>
                        Fecha reemplazo:{' '}
                        {format(new Date(hist.fecharemplazo), 'MM/dd/yyyy')}
                      </p>
                      <p>
                        Fecha validación DIAN:{' '}
                        {hist.fechavalidacion &&
                          format(new Date(hist.fechavalidacion), 'MM/dd/yyyy')}
                      </p>
                      <p style={{ wordBreak: 'break-all' }}>
                        Codigo unico: {hist.codigounico}
                      </p>
                    </div>
                    {/* <div className={classes.showMoreActions}>
                    <IconButton aria-label="Ver">
                      <VisibilityIcon className={classes.iconToAssign} />
                    </IconButton>
                    <IconButton aria-label="Enviar">
                      <EmailIcon className={classes.iconToAssign} />
                    </IconButton>
                  </div> */}
                  </div>
                ))}
            </>
          ) : null}
        </div>

        <FileModal
          open={openModalFile}
          onCloseElement={handleCloseModalFile}
          closeElement={'Cerrar'}
          paramFile={filesPreview}
        ></FileModal>
        <SimpleModal
          onClose={handleCloseModalChoose}
          open={openModalChoose}
          component={modalChoosing}
          title={'Editar soporte de nómina'}
        />
        <SimpleModal
          onClose={handleCloseModalDeleting}
          open={openModalDeleting}
          component={() => (
            <ModalDeleteSupport
              consecutivo={support.consecutivo}
              onSubmitModalDeleting={onSubmitModalDeleting}
            />
          )}
          title={'Eliminar documento soporte'}
        // width={365}
        // showCloseButton={false}
        />
        <SimpleModal
          onClose={handleCloseModalMail}
          open={openModalMail}
          component={modalMails}
          title={'Enviar documento soporte de nómina'}
          titleGreen={true}
          width={365}
          showCloseButton={false}
        />
        <ResponseModal
          modalType={modalData.modalType}
          title={modalData.title}
          subtitle={modalData.subtitle}
          body={modalData.body}
          modalImage={modalData.modalImage}
          open={modalData.open}
          closeElement={modalData.closeElement}
          onCloseElement={handleResponseModal}
        />
      </Card>
    );
  },
);

export default PaymentsDetail;
