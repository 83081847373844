import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { CollapseFormPanel } from '../collapse/collapseFormPanel';
import InputForm from '../../components/input/input.component';
import { IncomeForm } from '../concepts/incomeForm';
import { IncomeCard } from '../concepts/incomeCard';
import { ExpenseForm } from '../concepts/expenseForm';
import { ExpenseCard } from '../concepts/expenseCard';
import { ConfirmAlert } from '../../helpers/alert.helpers';

import {
  loadIncomeAction,
  deleteIncomeAction,
  deleteExpenseAction,
  loadExpenseAction,
} from '../../actions/conceptAction';

import { formatDate } from '../../utils/general';
import { FormatDecimal } from '../common/formatDecimal';
import { setObservationsAction } from '../../actions/payrollAdjustmentAction';

const useStyles = makeStyles({
  root: {
    width: '98%',
    height: '74.4vh',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: 15,
    overflow: 'auto',
  },
  name: {
    color: '#F5D13D',
    fontWeight: 700,
    fontSize: 20,
  },
  value: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 600,
  },
  verticalLine: {
    border: '0.5px solid grey',
    height: '100%',
    width: 1,
    marginLeft: '50%',
  },
  ligthValue: {
    fontWeight: 300,
  },
  flexdisplay: {
    display: 'flex',
  },
  icondata: {
    color: '#F5D13D',
    marginRight: 5,
    fontSize: 22,
  },
  italic: {
    fontStyle: 'italic',
    fontSize: 16,
    fontWeight: 400,
    color: '#FFFF',
  },
  titleItems: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#2CC63E',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  titleItemsSecond: {
    width: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.3)',
    borderRadius: 5,
    color: '#ED8D84;',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px auto',
    padding: 5,
  },
  cardTotal: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 5,
    display: 'grid',
    textAlign: 'center',
    padding: 5,
    marginTop: 10,
  },
  valueTotal: {
    color: '#2CC63E',
    fontSize: 26,
    fontWeight: 700,
  },
  legendTotal: {
    color: '#FFFFFF',
    fontWeight: 300,
    fontSize: 12,
    fontStyle: 'italic',
  },
});
export const AdjustmentDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandIncome, setExpandIncome] = useState(false);
  const [expandExpense, setExpandExpense] = useState(false);

  const { incomesPayroll, incomeActive } = useSelector(
    (s) => s.incomePayrollReducer,
  );
  const { expensesPayroll, expenseActive } = useSelector(
    (s) => s.expensePayrollReducer,
  );

  const {
    primernombre,
    otronombre,
    primerapellido,
    segundoapellido,
    tipodocumento,
    documento,
    telefono,
    direccion,
    ciudadresidencia,
    departamentoresidencia,
    cargo,
    tipocontrato,
    fechaPago,
    valornomina,
    observacion,
  } = useSelector((s) => s.payrollAdjustmentReducer.payrollData);

  /**
   * Carga información de ingreso para edición
   * @param {*} income
   */
  const handleUpdateIncome = useCallback(
    (income) => {
      setExpandIncome(true);
      dispatch(loadIncomeAction(income));
    },

    [dispatch],
  );

  /**
   * Elimina Ingreso asociado a soporte nomina
   * @param {*} income
   */
  const handleDeleteIncome = useCallback(
    (income) => {
      const deleteData = (confirmed) => {
        if (confirmed) {
          dispatch(deleteIncomeAction(income.identificador));
          dispatch(loadIncomeAction(null));
        }
      };
      ConfirmAlert(`¿Estas seguro de eliminar este ingreso?`, deleteData);
    },
    [dispatch],
  );

  /**
   * Carga información de deduccion para edición
   * @param {*} expense
   */
  const handleUpdateExpense = (expense) => {
    setExpandExpense(true);
    dispatch(loadExpenseAction(expense));
  };

  /**
   * Elimina Ingreso asociado a soporte nomina
   * @param {*} income
   */
  const handleDeleteExpense = (expense) => {
    const deleteData = (confirmed) => {
      if (confirmed) {
        dispatch(deleteExpenseAction(expense.identificador));
        dispatch(loadExpenseAction(null));
      }
    };
    ConfirmAlert(`¿Estas seguro de eliminar esta deducción?`, deleteData);
  };

  /**
   * Expande o contrae formulario ingresos, limpia formulario
   */
  const handleExpandIncomePanel = () => {
    if (expandIncome === true) {
      dispatch(loadIncomeAction(null));
    }

    setExpandIncome(!expandIncome);
  };

  /**
   * Expande o contrae formulario deducciones, limpia formulario
   */
  const handleExpandExpensePanel = () => {
    if (expandExpense === true) {
      dispatch(loadExpenseAction(null));
    }

    setExpandExpense(!expandExpense);
  };

  /**
   * Cierra formulario deducciones
   */
  const handleCloseIncomePanel = () => {
    setExpandIncome(false);
  };

  /**
   * Cierra formulario deducciones
   */
  const handleCloseExpensePanel = () => {
    setExpandExpense(false);
  };

  /**
   * Sincorniza observaciones con state
   * @param {*} param0
   */
  const handleChangeObservaciones = ({ target }) => {
    dispatch(setObservationsAction(target.value));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={5} xs={5}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <div className={classes.name}>
                {' '}
                {`${primernombre ?? ''}
                     ${otronombre ?? ''} ${primerapellido ?? ''} ${
                  segundoapellido ?? ''
                }`}
              </div>
            </Grid>
            <Grid item lg={12} xs={12}>
              <div
                className={classes.value}
              >{`${tipodocumento} ${documento}`}</div>
            </Grid>
            <Grid item lg={12} xs={12}>
              <div className={classes.value}>{cargo}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={2}>
          <div className={classes.verticalLine}></div>
        </Grid>
        <Grid item lg={5} xs={5}>
          <Grid container>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <DescriptionIcon className={classes.icondata}></DescriptionIcon>
              <span className={classes.italic}>{tipocontrato}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <PhoneIcon className={classes.icondata} />
              <span className={classes.italic}>{telefono}</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <LocationOnIcon className={classes.icondata} />
              <span className={classes.italic}>
                {`${direccion} ${ciudadresidencia} - ${departamentoresidencia}`}
              </span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.flexdisplay}>
              <CalendarTodayIcon className={classes.icondata} />
              <span
                className={classes.italic}
              >{`Última fecha de pago: ${formatDate(
                new Date(fechaPago),
                'dd/MM/yyyy',
              )}`}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.titleItems}>{`Ingresos`}</div>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <CollapseFormPanel
            component={<IncomeForm handleClose={handleCloseIncomePanel} />}
            title={'Añadir ingresos'}
            titleOpen={!!incomeActive ? 'Editar ingreso' : 'Nuevo ingreso'}
            open={expandIncome}
            onClick={() => handleExpandIncomePanel()}
          />
        </Grid>
        {incomesPayroll.map((income) => (
          <Grid key={income.identificador} item lg={6} xs={12}>
            <IncomeCard
              {...income}
              handleUpdate={() => handleUpdateIncome(income)}
              handleDelete={() => handleDeleteIncome(income)}
            />
          </Grid>
        ))}
      </Grid>

      <div className={classes.titleItemsSecond}>{`Deducciones`}</div>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <CollapseFormPanel
            component={<ExpenseForm handleClose={handleCloseExpensePanel} />}
            title={'Añadir Deducciones'}
            titleOpen={!!expenseActive ? 'Editar Deduccion' : 'Nueva Deduccion'}
            open={expandExpense}
            onClick={() => handleExpandExpensePanel()}
          />
        </Grid>

        {expensesPayroll.map((expense) => (
          <Grid item lg={6} xs={12}>
            <ExpenseCard
              data={expense}
              handleUpdate={() => handleUpdateExpense(expense)}
              handleDelete={() => handleDeleteExpense(expense)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container style={{ marginTop: 10 }}>
        <Grid item lg={12} xs={12}>
          <InputForm
            name="observaciones"
            maxLength={500}
            label={'Observaciones*'}
            multiline={true}
            value={observacion}
            onChange={handleChangeObservaciones}
          />
        </Grid>
      </Grid>

      <Grid container style={{ justifyContent: 'end' }}>
        <Grid item lg={6} xs={6}>
          <div className={classes.cardTotal}>
            <span className={classes.valueTotal}>
              <FormatDecimal prefix={'$'} value={valornomina} />
            </span>
            <span className={classes.legendTotal}>
              Valor total a pagar a este empleado
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
