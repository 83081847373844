import {
  SAVE_CHARGE,
  UPDATE_CHARGE,
  DELETE_CHARGE,
  GET_CHARGELIST,
  GET_CHARGE,
  GET_FILTER_CHARGES,
  GET_FILTER_CHARGE,
  LOADING_SAVE_CHARGE,
  CHANGE_EDITING_CHARGE_STATUS,
  LOADING_GET_CHARGES
} from "../actions/types";

const initialState = {
  loadingSave: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHARGE:
      return {
        ...state,
        getCharge: action.payload,
      };

    case GET_CHARGELIST:
      return {
        ...state,
        getChargesEnterprise: action.payload,
      };

    case SAVE_CHARGE:
      return {
        ...state,
        createCharge: action.payload,
      };

    case UPDATE_CHARGE:
      return {
        ...state,
        updateCharge: action.payload,
      };

    case DELETE_CHARGE:
      return {
        ...state,
        deleteCharge: action.payload,
      };

    case GET_FILTER_CHARGES:
      return {
        ...state,
        getFilterCharges: action.payload,
      };

    case GET_FILTER_CHARGE:
      return {
        ...state,
        currentCharge: action.payload,
        editingChargeData: !!action.payload ? true : false,
      };

    case CHANGE_EDITING_CHARGE_STATUS:
      return {
        ...state,
        editingChargeData: action.payload,
      };

    case LOADING_SAVE_CHARGE:
      return {
        ...state,
        loadingSave: action.payload,
      };

    case LOADING_GET_CHARGES:
      return {
        ...state,
        loadingGetChargesList: action.payload,
      };

    default:
      return state;
  }
}
