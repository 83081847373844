import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import {
  downloadBulkPayrollTemplateAction,
  getBulkLoadPayrollAction,
} from '../../actions/payrollBulkLoadAction';

import { UploadingDialog } from './modalsBulkload';
import ResponseModal from '../modal/responseModal.component';

import { convertBase64ToExcel } from '../../views/employees/bulkLoad/helpers/utilitiesBulkload';
import { generatePayroll, processData } from './helpers';
import { hasValidFile, readAndProcessFile } from '../../helpers/excelFile';
import { validateExtension } from '../../helpers/validateExtension';
import { validateFileSize } from '../../helpers/validateSizeFile';
import { getIncomesAndExpensesAction } from '../../actions/configAction';

const useStyles = makeStyles((theme) => ({
  btnEnviar: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  btnEnviarDisabled: {
    cursor: 'not-allowed',
  },
  buttonUploadBulk: {
    position: 'relative',
    width: '100%',
    height: '54px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    maxWidth: '81%',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
    padding: '1px',
  },
  buttonUploadBulkDisabled: {
    backgroundColor: '#CCCCCC',
    cursor: 'not-allowed',
    color: '#888888',
    opacity: 0.5,
    '&:hover': {
      backgroundColor: '#CCCCCC',
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  btnInfografia: {
    width: '100%',
  },
  buttonSuccess: {
    height: '54px',
    width: '100%',
    maxWidth: '81%',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
      height: '464px',
      width: '99%',
      borderRadius: '5px',
      maxWidth: '974px',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '340px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '390px',
      },
    },
  },
  dialogAnimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  subTitleValue: {
    color: theme.palette.thirdColor,
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
  },
}));

const initialModal = {
  type: '',
  title: '',
  subtitle: '',
  body: '',
  modalImage: '',
  open: false,
  closeElement: '',
  onCloseElement: 0,
};

export const ButtonsLoad = ({
  validator,
  payrollYearMonth,
  setActiveStep,
  setCompleted,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const btnBulk = useRef(null);

  const [openModalIformation, setOpenModalInformation] = useState(false);
  const [contentDialog, setContentDialog] = useState(null);
  const [modalResponse, setModalResponse] = useState(initialModal);
  const [{ dialogTitle, dialogSubtitle }, setInfoDialog] = useState({
    dialogTitle: null,
    dialogSubtitle: null,
  });

  const { incomesList, expensesList } = useSelector((s) => s.configReducer);

  useEffect(() => {
    dispatch(getIncomesAndExpensesAction());
  }, [dispatch]);

  /**
   * * Realiza las validaciones iniciales y procesa un archivo seleccionado por el usuario.
   * @param {Event} e - El evento del formulario que desencadenó la función.
   */
  const initialValidations = async (e) => {
    try {
      e.preventDefault();

      if (!hasValidFile(e)) {
        e.target.value = '';
        return;
      }

      const file = e.target.files[0];
      const allowedExtensions = ['xls', 'xlsx', 'xlsm', 'csv'];
      const maxSizeKB = 500;

      if (!validateExtensionAndSize(file, allowedExtensions, maxSizeKB)) {
        e.target.value = '';
        return;
      }

      fillDialogFields({
        title: 'Subiendo archivo',
        subtitle: 'Estamos revisando tu archivo cargado. Por favor, espera.',
        openModal: true,
      });

      const data = await readAndProcessFile(file);
      const { list, errorsByLine, results } = await processData(
        data,
        showValidationErrors,
        setOpenModalInformation,
        btnBulk,
      );

      const { payrollRequest, payrollError, multiDataSet } = generatePayroll(
        list,
        errorsByLine,
        results,
        payrollYearMonth,
        [...incomesList, ...expensesList],
        setContentDialog,
      );

      dispatch(
        getBulkLoadPayrollAction(
          payrollRequest,
          payrollError,
          multiDataSet,
          nextStep,
        ),
      );
    } catch (error) {
      console.error('Error al procesar el archivo:', error);
      btnBulk.current.value = null;
    }
  };

  const nextStep = () => {
    fillDialogFields({
      title: null,
      subtitle: null,
      openModal: false,
    });
    setActiveStep(1);
  };

  const allowFileInput = validator.allValid();

  /**
   * * Realiza las validaciones de extensión y tamaño del archivo.
   *
   * @param {File} file - El archivo a validar.
   * @param {string[]} allowedExtensions - Las extensiones permitidas.
   * @param {number} maxSizeKB - El tamaño máximo permitido en kilobytes (KB).
   * @returns {boolean} - True si las validaciones son exitosas, false en caso contrario.
   */
  const validateExtensionAndSize = (file, allowedExtensions, maxSizeKB) => {
    const isExtensionValid = validateExtension(file, allowedExtensions);
    const isSizeValid = validateFileSize(file, maxSizeKB);

    if (!isExtensionValid) {
      showValidationErrors(
        `Sólo se aceptan los siguientes formatos: ${allowedExtensions.join(
          ', ',
        )}`,
      );
      return false;
    } else if (!isSizeValid) {
      showValidationErrors(
        `El tamaño del archivo no puede superar los ${maxSizeKB} Kilobytes`,
      );
      return false;
    }

    return true;
  };

  /**
   * * Restablece el estado del modal a su valor inicial.
   */
  const handleResponseModal = () => setModalResponse(initialModal);

  /**
   * * Muestra un modal de advertencia con mensajes de validación.
   *
   * @param {string|ReactNode} body - Contenido del mensaje de validación.
   */
  const showValidationErrors = (body) => {
    setModalResponse({
      type: 'warning',
      title: 'Upss...!!!',
      subtitle: 'Ocurrió algo inesperado',
      body: <div>{body}</div>,
      modalImage: 'warning',
      open: true,
      closeElement: 'Saltar',
      onCloseElement: 1,
    });
  };

  /**
   * * Función que descarga la plantilla de carga masiva
   */
  const handleTemplate = () => {
    dispatch(downloadBulkPayrollTemplateAction(convertBase64));
  };

  /**
   * * Función que convierte el base 64 en un archivo xlsm
   * @param {*} data
   */
  const convertBase64 = (data) => {
    convertBase64ToExcel(
      data,
      `Cargue_Masivo_Nominas_${new Date().toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}.xlsm`,
    );
  };

  const fillDialogFields = ({ content, title, subtitle, openModal }) => {
    setContentDialog(content);
    setInfoDialog({ dialogTitle: title, dialogSubtitle: subtitle });
    setOpenModalInformation(openModal);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
        <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
          <div className={classes.btnInfografia}>
            <button
              className={classes.buttonSuccess}
              type="submit"
              onClick={handleTemplate}
            >
              Descargar Plantilla
            </button>
          </div>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2} xl={2} />
        <br />
        <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
          <center>
            <button
              className={`${classes.buttonUploadBulk} ${
                !allowFileInput ? classes.buttonUploadBulkDisabled : ''
              }`}
            >
              <p id="texto">Cargar Plantilla Masiva</p>
              <input
                ref={btnBulk}
                type="file"
                className={`${classes.btnEnviar} ${
                  !allowFileInput ? classes.btnEnviarDisabled : ''
                }`}
                accept=".csv,.xlsx,.xls,.xlsm"
                onChange={initialValidations}
                disabled={!allowFileInput}
              />
            </button>
          </center>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
      </Grid>
      <br />
      <UploadingDialog
        openModalIformation={openModalIformation}
        contentDialog={contentDialog}
        title={dialogTitle}
        subtitle={dialogSubtitle}
      />
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      />
    </>
  );
};
