import React, { useEffect, useState } from 'react';

import { Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import UpdateIcon from '../../../../images/icons/copyResult.png';
import ErrorIcon from '../../../../images/icons/failResult.png';
import ValidateIcon from '../../../../images/icons/validateIcon.png';
import FilterIcon from '../../../../images/icons/filterIcon.png';
import SearchIcon from '../../../../images/icons/searchIcon.png';

import CustomProgress from '../../../../components/Progress/progress.component';
import StyledCheckbox from '../../../../components/checkForm/styledCheckbox.component';
import PopoverModal from '../../../../components/modal/popoverModal.component';

import { FixedSizeList } from 'react-window';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  divListResult: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '34px',
  },
  divFilter: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  paper: {
    textAlign: 'left',
    color: '#FFFFFF',
    paddingLeft: '10px',
    background: '#363636',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '36px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridTittle: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '33px',
    color: '#2CC63E',
  },
  gridListTittle: {
    background: 'rgba(18, 18, 18, 0.3)',
    borderRadius: '5px',
    width: '90%',
    height: '36px',
    textAlign: 'center',
  },
  gridList: {
    width: '90%',
  },
  cardIcon: {
    paddingRight: '10px',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  inputSearch: {
    color: '#FFFFFF',
    width: '97%',
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #848484',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #16B1F3',
    },
  },
}));

const ListLoadResult = ({ bulkLoadErrorEmployee, bulkLoadEmployee }) => {
  const [dataEmployeeList, setDataEmployeeList] = useState([]);
  const [employeeListResult, setEmployeeList] = useState([]);
  const [errorEmployeeList, setErrorEmployeeList] = useState([]);
  const [initalValue, setInitalValue] = useState([]);
  const [loadinEmployee, setLoadingEmployee] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [modalChecks, setModalChecks] = useState(null);
  const [stateCheck, setStateCheck] = useState({
    Actualizado: false,
    Error: false,
  });

  const classes = useStyles();

  useEffect(() => {
    bulkLoadEmployee && setEmployeeList(bulkLoadEmployee);
    setLoadingEmployee(true);
  }, [bulkLoadEmployee]);

  useEffect(() => {
    bulkLoadErrorEmployee && setErrorEmployeeList(bulkLoadErrorEmployee);
  }, [bulkLoadErrorEmployee]);

  useEffect(() => {
    if (employeeListResult.length > 0 || errorEmployeeList.length > 0) {
      const employeelist = employeeListResult.concat(errorEmployeeList);
      setDataEmployeeList(employeelist);
      setInitalValue(employeelist);
    }
    // if (employeeListResult.length > 0) {
    //   setDataEmployeeList(employeeListResult);
    //   setInitalValue(employeeListResult);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeListResult, errorEmployeeList]);

  useEffect(() => {
    if (
      filterValue !== '' &&
      filterValue !== 'Actualizado' &&
      filterValue !== 'Error'
    ) {
      setDataEmployeeList(handleFilter(filterValue));
    } else if (stateCheck.Actualizado) {
      setDataEmployeeList(
        initalValue.filter((item) => item.Resultado === 'Actualizado'),
      );
    } else if (stateCheck.Error) {
      setDataEmployeeList(initalValue.filter((item) => item.Error?.length > 0));
    } else {
      setDataEmployeeList(initalValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const Row = ({ index, key, style }) => {
    const {
      Primernombre,
      Primerapellido,
      Diantipodocumentoidentidadid,
      Documento,
      Resultado,
    } = dataEmployeeList[index];

    return (
      <div key={key} style={style}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {Resultado === 'Actualizado' ? (
                <img className={classes.cardIcon} src={UpdateIcon} alt="Upd" />
              ) : Resultado === 'Insertado' ? (
                <img
                  className={classes.cardIcon}
                  src={ValidateIcon}
                  alt="validate"
                />
              ) : (
                <img className={classes.cardIcon} src={ErrorIcon} alt="error" />
              )}
              {Primernombre} {Primerapellido} - &nbsp;
              {Diantipodocumentoidentidadid} {Documento}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  const handleInputSearchChange = (e) => {
    e.preventDefault();
    setFilterValue(e.target.value);
  };

  const handleFilter = (data, checked) => {
    let filter = [];
    if (data !== '' && checked === undefined) {
      filter = initalValue.filter((objeto) =>
        Object.keys(objeto)
          .filter(
            (clave) =>
              clave !== 'Resultado' && clave !== 'Cargo' && clave !== 'Error',
          )
          .some(
            (clave) =>
              (typeof objeto[clave] === 'string' ||
                typeof objeto[clave] === 'number') &&
              String(objeto[clave]).toLowerCase().includes(data.toLowerCase()),
          ),
      );
      return filter;
    }
    if (checked && data === 'Actualizado') {
      filter = dataEmployeeList.filter(
        (item) => item.Resultado === 'Actualizado',
      );
      return setDataEmployeeList(filter);
    }
    if (!checked && data === 'Actualizado') {
      return setDataEmployeeList(initalValue);
    }
    if (checked && data === 'Error') {
      filter = dataEmployeeList.filter((item) => item.Error?.length > 0);
      return setDataEmployeeList(filter);
    }
    if (!checked && data === 'Error') {
      return setDataEmployeeList(initalValue);
    }
  };

  /**
   * Abre filtro por estado
   * @param {*} event
   */
  const handleOpenModalChecks = (event) => {
    setModalChecks(event.currentTarget);
  };

  /**
   * Cierra filtro estado
   */
  const handleCloseModalChecks = () => {
    setModalChecks(null);
  };

  /**
   * Filtro por estado
   * @returns
   */
  const modalFilters = () => {
    return (
      <div style={{ paddingLeft: 10, fontSize: 18 }}>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={stateCheck.Actualizado}
              onChange={handleCheckFilter}
              name="Actualizado"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Reemplazados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={stateCheck.Error}
              onChange={handleCheckFilter}
              name="Error"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Errores
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <span
              style={{ paddingLeft: '13px', cursor: 'pointer' }}
              onClick={handleCleanFilter}
            >
              Limpiar Filtro
            </span>
          </Grid>
        </Grid>
      </div>
    );
  };

  /**
   * Filtra documentos por estado
   * @param {*} param0
   */
  const handleCheckFilter = ({ target }) => {
    const { name, checked } = target;

    setStateCheck({ ...stateCheck, [name]: checked });

    if (stateCheck.Actualizado && stateCheck.Error) {
      let checkedFiltered = [];

      if (name === 'Actualizado' && checked === false) {
        checkedFiltered = setDataEmployeeList(
          initalValue.filter((item) => item.Error?.length > 0),
        );
      } else if (name === 'Error' && checked === false) {
        checkedFiltered = setDataEmployeeList(
          initalValue.filter((item) => item.Resultado === 'Actualizado'),
        );
      }
      return checkedFiltered;
    }

    handleFilter(name, checked);
  };

  /**
   * Limpia filtro por estado
   */
  const handleCleanFilter = () => {
    setStateCheck({
      Actualizado: false,
      Error: false,
    });
    setDataEmployeeList(initalValue);
    handleCloseModalChecks();
  };

  return (
    <div className={classes.root}>
      <div className={classes.divListResult}>
        <Grid item xs={12} className={classes.gridListTittle}>
          <span className={classes.gridTittle}> Detalle del cargue </span>
        </Grid>
      </div>
      <div
        className={classes.divFilter}
        style={{ width: '99%', alignItems: 'flex-end' }}
      >
        <div
          style={{
            paddingLeft: '20px',
            display: 'flex',
            alignItems: 'flex-start',
            width: '87%',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            xs={11}
            className={classes.gridList}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-18px',
            }}
          >
            <TextField
              label=""
              value={filterValue}
              onChange={handleInputSearchChange}
              className={classes.inputSearch}
            />
            <IconButton disabled={false}>
              <img
                alt={'Filtrar'}
                width="20px"
                src={SearchIcon}
                style={{
                  padding: '0',
                  position: 'absolute',
                  left: '-22px',
                  top: '-3px',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs style={{ maxWidth: '20px' }}>
            <IconButton
              onClick={handleOpenModalChecks}
              disabled={false}
              style={{ padding: '0' }}
            >
              <img alt={'Filtrar'} width="20px" src={FilterIcon} />
            </IconButton>
          </Grid>
        </div>
        <PopoverModal
          handleClose={handleCloseModalChecks}
          open={Boolean(modalChecks)}
          component={modalFilters}
          width={191}
          popoverAnchorEl={modalChecks}
          showCloseButton={false}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
        }}
      >
        {loadinEmployee ? (
          <FixedSizeList
            width={'68vw'}
            height={360}
            itemCount={dataEmployeeList.length}
            itemSize={50}
            overflowX={'hidden'}
          >
            {Row}
          </FixedSizeList>
        ) : (
          <div>
            <CustomProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListLoadResult;
