import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { showErrorMessage } from '../utils/showErrorService';
import { updateIncomeAndExpenseDocument } from './payrollReportAction';
import { types } from './types';

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

/**
 * Agrega nuevo ingreso
 * @param {*} income
 * @returns
 */
export const addIncomeAction = (income) => async (dispatch) => {
  dispatch({
    type: types.ADD_INCOME_PAYROLL,
    payload: income,
  });
};

/**
 * Carga ingreso para edición
 * @param {*} income
 * @returns
 */
export const loadIncomeAction = (income) => async (dispatch) => {
  dispatch({
    type: types.LOAD_INCOME_PAYROLL,
    payload: income,
  });
};

/**
 * Actualiza información ingreso
 * @param {*} income
 * @returns
 */
export const updateIncomeAction = (income) => async (dispatch) => {
  dispatch({
    type: types.UPDATE_INCOME_PAYROLL,
    payload: income,
  });
};

/**
 * Elimina ingreso
 * @param {*} income
 * @returns
 */
export const deleteIncomeAction = (id) => async (dispatch) => {
  dispatch({
    type: types.DELETE_INCOME_PAYROLL,
    payload: id,
  });
};

/**
 * Agrega nueva deduccion
 * @param {*} expense
 * @returns
 */
export const addExpenseAction = (expense) => async (dispatch) => {
  dispatch({
    type: types.ADD_EXPENSE_PAYROLL,
    payload: expense,
  });
};

/**
 * Carga deduccion para edición
 * @param {*} expense
 * @returns
 */
export const loadExpenseAction = (expense) => async (dispatch) => {
  dispatch({
    type: types.LOAD_EXPENSE_PAYROLL,
    payload: expense,
  });
};

/**
 * Actualiza información deduccion
 * @param {*} expense
 * @returns
 */
export const updateExpenseAction = (expense) => async (dispatch) => {
  dispatch({
    type: types.UPDATE_EXPENSE_PAYROLL,
    payload: expense,
  });
};

/**
 * Elimina deduccion
 * @param {*} expense
 * @returns
 */
export const deleteExpenseAction = (id) => async (dispatch) => {
  dispatch({
    type: types.DELETE_EXPENSE_PAYROLL,
    payload: id,
  });
};

/**
 * Guarda ingreso en BD y actualiza totales
 * @param {*} concept
 * @param {*} detallenominaid
 * @returns
 */
export const saveConceptDocumentAction = (
  concept,
  detallenominaid,
  completeSaveAndUpdate,
) => async (dispatch, getState) => {
  try {
    changeLoadingAdd(dispatch, true, concept.esingreso);
    const data = {
      Id: 0,
      Detallenominaid: detallenominaid,
      Conceptoid: concept.conceptoid,
      Valor: concept.valor,
      Cantidad: concept.cantidad,
      Porcentaje: concept.porcentaje,
      Descripcion: concept.descripcion,
    };

    const resp = await axiosApiInstance.post(
      `${urlDocument}/Documento/RegisterEmployeeConcept`,
      data,
      defaultHeaders(),
    );

    if (resp)
      await updateIncomeAndExpenseDocument(dispatch, getState, detallenominaid);
    completeSaveAndUpdate();
  } catch (err) {
    showErrorMessage(err, 'Ha ocurrido un error agregando nuevo ingreso.');
  } finally {
    changeLoadingAdd(dispatch, false, concept.esingreso);
  }
};

/**
 * Actualiza información de ingreso en BD
 * @param {*} concept
 * @param {*} detallenominaid
 * @returns
 */
export const updateConceptDocumentAction = (
  concept,
  detallenominaid,
  completeSaveAndUpdate,
) => async (dispatch, getState) => {
  try {
    changeLoadingAdd(dispatch, true, concept.esingreso);
    const data = {
      Id: concept.identificador,
      Detallenominaid: detallenominaid,
      Conceptoid: concept.conceptoid,
      Valor: concept.valor,
      Cantidad: concept.cantidad,
      Porcentaje: concept.porcentaje,
      Descripcion: concept.descripcion,
    };

    const resp = await axiosApiInstance.post(
      `${urlDocument}/Documento/RegisterEmployeeConcept`,
      data,
      defaultHeaders(),
    );
    if (resp) {
      await updateIncomeAndExpenseDocument(dispatch, getState, detallenominaid);
    }
    completeSaveAndUpdate();
  } catch (err) {
    showErrorMessage(err, 'Ha ocurrido un error actualizando ingreso.');
  } finally {
    changeLoadingAdd(dispatch, false, concept.esingreso);
  }
};

const changeLoadingAdd = (dispatch, status, esingreso) => {
  dispatch({
    type: esingreso ? types.LOADING_ADD_INCOME : types.LOADING_ADD_EXPENSE,
    payload: status,
  });
};

/**
 * Elimina ingreso
 * @param {*} income
 * @returns
 */
export const deleteConceptDocumentAction = (
  id,
  detallenominaid,
  esingreso,
) => async (dispatch, getState) => {
  try {
    changeLoadingDelete(dispatch, id, esingreso);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pMovimientoId: Number(id),
    });

    const resp = await axiosApiInstance.delete(
      `${urlDocument}/Documento/DeleteEmployeeConcept`,
      config,
    );
    if (resp) {
      await updateIncomeAndExpenseDocument(dispatch, getState, detallenominaid);
    }
  } catch (err) {
    showErrorMessage(err, 'Ha ocurrido un error eliminando ingreso.');
  } finally {
    changeLoadingDelete(dispatch, 0, esingreso);
  }
};

const changeLoadingDelete = (dispatch, status, esingreso) => {
  dispatch({
    type: esingreso
      ? types.LOADING_DELETE_INCOME
      : types.LOADING_DELETE_EXPENSE,
    payload: status,
  });
};
