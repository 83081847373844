import {
  SAVE_BILLER,
  INFO_BILLER,
  GET_BILLERS,
  UPDATE_BILLER,
  LOAD_BILLER,
  UPDATE_STATUS_BILLER,
  LOADING_SAVE_BILLER,
  LOADING_GET_BILLERS,
  DISABLED_ACTIONS_USER,
  INFO_LICENSE_BILLER,
} from './types';
import {
  //API_ENDPOINT_USER,
  defaultHeaders,
  API_ENDPOINT_ENTERPRISE,
} from '../config/config';
import { axiosApiInstance } from '../config/axios-instance';
import { handleResponse, enterpriseIdHeader } from './authActions';
import { filterByOffice } from './basicDataActions';
import { showErrorMessage } from '../utils/showErrorService';

const urlUser = `${API_ENDPOINT_ENTERPRISE}/enterprise/api`;

/** BEGIN USER BILLERS */
/**
 * Consulta listado de usuarios con rol facturador y aplica filtro
 * @param {*} filter Palabra para filtro
 * @param {*} refresh Estado, define nueva consulta a API
 */
export const getBillersAction = (filter = '', refresh = true) => async (
  dispatch,
  getState,
) => {
  const { billerList } = getState().userReducer;
  if (!refresh) {
    const filterUsers = filterListUsers(billerList, filter);
    dispatch({
      type: GET_BILLERS,
      payload: billerList,
      payload1: filterUsers,
    });
  } else {
    try {
      changGetBillersLoading(dispatch, true);
      let config = defaultHeaders();
      Object.assign(config.headers, {
        empresaId: `${enterpriseIdHeader()}`,
      });

      const resp = await axiosApiInstance.get(
        `${urlUser}${'/user/GetUser'}`,
        config,
      );

      const allUsers = resp.data.result.map((item) => {
        return {
          ...item,
          nombreCompleto: `${item.nombres} ${item.apellidos}`,
        };
      });

      const filterUsers = filterListUsers(allUsers, filter);

      dispatch({
        type: GET_BILLERS,
        payload: allUsers,
        payload1: filterUsers,
      });
    } catch (err) {
      handleResponse(err.response);
      showErrorMessage(err, 'Error al obtener los usuarios de la empresa');
    } finally {
      changGetBillersLoading(dispatch, false);
    }
  }
};

/**
 * Actualiza estado loading conulta listado facturadores
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changGetBillersLoading(dispatch, status) {
  dispatch({
    type: LOADING_GET_BILLERS,
    payload: status,
  });
}

/**
 * Registra información de usuario facturador en BD
 * @param {object} body Información usuario
 */
export const saveBillerAction = (data) => async (dispatch) => {
  try {
    changeSaveBillerLoading(dispatch, true);
    changeStatusDisabledActions(dispatch, true);
    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const response = await axiosApiInstance.post(
      `${urlUser}${'/user'}`,
      data,
      defaultHeaders(),
    );

    dispatch({
      type: SAVE_BILLER,
      payload: response.data,
    });
  } catch (err) {
    if (
      err.hasOwnProperty('response') &&
      err.response.hasOwnProperty('data') &&
      err.response.data.statusCode === '404' &&
      err.response.data.statusMessage === 'Unauthorized, no quota'
    ) {
      dispatch({
        type: INFO_LICENSE_BILLER,
        payload: err.response.data,
      });
      if (
        err.response.data.result.licenseActiveBase &&
        !err.response.data.result.exist
      ) {
        dispatch({
          type: INFO_BILLER,
          payload: true,
        });
      }
    } else {
      handleResponse(err.response);
      showErrorMessage(err, 'Error al crear facturador');
    }
  } finally {
    changeSaveBillerLoading(dispatch, false);
    changeStatusDisabledActions(dispatch, false);
  }
};

/**
 * Elimina la licencia del usuario consultada
 *
 */

export const cleanLicenseResponseUser = () => (dispatch) => {
  dispatch({
    type: INFO_LICENSE_BILLER,
    payload: null,
  });
};

/**
 * Abre el modal que informa que no hay cupo para crear usuarios
 * @param {object} data boolean en true
 */
export const updateOpenModalItem = (data) => (dispatch) => {
  dispatch({
    type: INFO_BILLER,
    payload: data,
  });
};

/**
 * Actualiza información de usurio facturador
 * @param {*} data nueva información usurio
 */
export const updateBillerAction = (data) => async (dispatch) => {
  try {
    changeStatusDisabledActions(dispatch, true);
    changeSaveBillerLoading(dispatch, true);
    data = {
      ...data,
      empresaid: `${enterpriseIdHeader()}`,
    };

    const resp = await axiosApiInstance.put(
      `${urlUser}${'/user'}`,
      data,
      defaultHeaders(),
    );

    dispatch({
      type: UPDATE_BILLER,
      payload: resp.data,
    });

    clearLoadBiller(dispatch);
  } catch (err) {
    if (
      err.hasOwnProperty('response') &&
      err.response.hasOwnProperty('data') &&
      err.response.data.statusCode === '404' &&
      err.response.data.statusMessage === 'Unauthorized, no quota'
    ) {
      let responseError = {
        ...err.response.data,
        id: data?.id ?? 0,
        status: data?.idstate ?? 1,
      };
      dispatch({
        type: INFO_BILLER,
        payload: true,
        payload1: responseError,
      });
    } else {
      showErrorMessage(err, 'Error al actualizar el usuario');
    }
  } finally {
    changeStatusDisabledActions(dispatch, false);
    changeSaveBillerLoading(dispatch, false);
  }
};

/**
 * Actualiza estado loading guardado o actualización usuario facturador
 * @param {*} dispatch
 * @param {boolean} status Nuevo estado loaging
 */
function changeSaveBillerLoading(dispatch, status) {
  dispatch({
    type: LOADING_SAVE_BILLER,
    payload: status,
  });
}

export const loadBillerAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_BILLER,
    payload: data,
  });
};

function clearLoadBiller(dispatch) {
  dispatch({
    type: LOAD_BILLER,
    payload: null,
  });
}

/**
 * Actualiza estado de usuario facturador
 * @param {*} id Identificador usuario
 * @param {*} status Identificador nuevo estado
 */
export const updateBillerStatusAction = (id, status) => async (
  dispatch,
  getState,
) => {
  try {
    changeUpdateStateBillerLoading(dispatch, getState, true, id);
    changeStatusDisabledActions(dispatch, true);
    let config = defaultHeaders();
    Object.assign(config.headers, {
      userId: id,
      stateId: status,
      aplication: 'COLNOMINA',
    });

    const result = await axiosApiInstance.put(
      `${urlUser}${'/user/UpdateState'}`,
      null,
      config,
    );
    let response = {
      ...result.data,
      id: id,
      status: status,
    };

    dispatch({
      type: UPDATE_STATUS_BILLER,
      payload: response,
    });
  } catch (err) {
    if (
      err.hasOwnProperty('response') &&
      err.response.hasOwnProperty('data') &&
      err.response.data.statusCode === '404' &&
      err.response.data.statusMessage === 'Unauthorized, no quota'
    ) {
      let responseError = {
        ...err.response.data,
        id: id,
        status: status,
      };
      dispatch({
        type: INFO_BILLER,
        payload: true,
        payload1: responseError,
      });
    } else {
      showErrorMessage(err, 'Error al actualizar el usuario');
    }
  } finally {
    changeUpdateStateBillerLoading(dispatch, getState, false, id);
    changeStatusDisabledActions(dispatch, false);
  }
};

/**
 * Cambia estado loading para actualización de estado dentro del listado de facturadores
 * @param {object} dispatch
 * @param {boolean} status  Estado loading
 */
function changeUpdateStateBillerLoading(dispatch, getState, status, id) {
  let { filteredBillersList, billerList } = getState().userReducer;

  let newData = filteredBillersList.find((n) => n.id === id);

  if (!!newData) {
    const index = filteredBillersList.findIndex((e) => e.id === id);
    let newArray = [...filteredBillersList];
    newArray[index] = {
      ...newData,
      loading: status,
    };
    filteredBillersList = newArray;
  }

  dispatch({
    type: GET_BILLERS,
    payload: billerList,
    payload1: filteredBillersList,
  });
}

/**
 * Actualiza estado, deshabilitar aciones en modulo usuarios
 * @param {*} dispatch
 * @param {*} status
 */
const changeStatusDisabledActions = (dispatch, status) => {
  dispatch({
    type: DISABLED_ACTIONS_USER,
    payload: status,
  });
};

/** END USER BILLERS */
/**
 * Filtra listado de ususarios por filtro
 * @param {*} list Listado usurios
 * @param {*} filter Filtro
 */
function filterListUsers(list, filter) {
  if (filter === '' || filter === null || filter === undefined) return list;

  // eslint-disable-next-line array-callback-return
  const data = list.filter((x) => {
    filter = filter.toLowerCase().trim();
    const nombreCompleto = x.nombreCompleto?.toLowerCase().indexOf(filter);
    const nombres = x.nombres.toLowerCase().indexOf(filter);
    const apellidos = x.apellidos.toLowerCase().indexOf(filter);
    const correo = !!x.email ? x.email.toLowerCase().indexOf(filter) : -1;
    const telefono = !!x.telefono
      ? x.telefono.toLowerCase().indexOf(filter)
      : -1;
    const rol = !!x.rolid
      ? x.rolid === 4 || x.rolid === 5
        ? 'Administrador'.toLowerCase().indexOf(filter)
        : 'Colaborador'.toLowerCase().indexOf(filter)
      : -1;
    const office = !!x.sucursalid ? filterByOffice(x.sucursalid, filter) : -1;

    if (nombreCompleto > -1) return true;
    else if (nombres > -1) return true;
    else if (apellidos > -1) return true;
    else if (correo > -1) return true;
    else if (telefono > -1) return true;
    else if (rol > -1) return true;
    else if (office > -1) return true;
  });

  return data;
}
