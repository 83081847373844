import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  regexAlphaNumeric,
  regexOnlyNumbers,
} from '../../../../helpers/customRegex.hepers.js';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import CustomProgress from '../../../../components/Progress/progress.component';
import CustomAutoComplete from '../../../../components/autocomplete/autocomplete.component';
import InputForm from '../../../../components/input/input.component';
import TooltipMessage from '../../../../components/tooltip/tootltip-message.component';
import ToggleButtons from '../../../../components/toggle/toggle.component';

const useStyles = makeStyles((theme) => ({
  paddingIcons: {
    paddingTop: '5px',
  },
  letter: {
    color: '#FFFFFF',
  },

  letterInformation: {
    color: '#FFFFFF',
    fontFamily: theme.palette.fontFamily,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'justify',
  },

  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogConfirm: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
    },
  },
  btnBlue: {
    width: '25%',
    float: 'right',
  },
  divRegister: {
    background: theme.palette.seventhColor /* Can be in percentage also. */,
    height: 'auto',
    margin: '0 auto',
    padding: '10px',
    position: 'relative',
    textAlign: 'center',
    color: '#272727',
    fontFamily: theme.palette.fontFamily,
    fontWeight: 'bold',
    borderRadius: '5px',
  },
  styleText: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 14,
  },
  styleIcon: {
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  datanotfound: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#272727',
      color: theme.palette.primaryColor,
      height: 480,
      width: 1000,
      borderRadius: '5px',
    },
  },
  titleInformation: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textBarBotton: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: '#2CC63E',
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: '#145e1c',
    },
    width: '100%',
    height: 50,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 18,
  },
}));

const PaymentMethod = React.memo(
  ({
    paymentData,
    setPaymentData,
    setFormCompleted,
    savePaymentData,
    setActiveStep,
    submitEmployee,
    simpleValidator,
    validateAllForms,
  }) => {
    const classes = useStyles();
    //const { getPaymentMethodsResponse, getBanksResponse } = configReducer;
    // const { loademployeeObject, employeesResumeList } = employeeReducer;
    const [banks, SetBanks] = useState([]);
    const [, forceUpdate] = useState();

    // store data
    const getPaymentMethodsResponse = useSelector(
      (state) => state.configReducer.getPaymentMethodsResponse || [],
    );

    const getBanksResponse = useSelector(
      (state) => state.configReducer.getBanksResponse || [],
    );

    // react hooks

    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      let filterBanks = getBanksResponse.filter(
        (bank) =>
          bank.tipoentidadbancaria ===
          paymentData.medioPago?.tipoentidadbancaria,
      );
      SetBanks(filterBanks);
    }, [paymentData.medioPago, getBanksResponse]);

    /**
     * Limpia caracteres invalidos, solo letras y numeros
     * @param {*} e Evento
     */
    const validateLettersAndNumbers = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexAlphaNumeric, '');
      setPaymentData({
        ...paymentData,
        [id]: value,
      });
    };

    /**
     * Sincroniza nuevo valor de Input con state
     * @param {*} e Evento
     */
    const syncChanges = (e) => {
      const value = e.target.value;
      const name = e.target.name;
      setPaymentData({
        ...paymentData,
        [name]: value,
      });
    };

    /**
     * Sincroniza nuevo valor autocomplete con el estado del Hook
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     * @param {*} id Identificador elemnto
     */
    const syncAutoCompleteChanges = (event, values, id) => {
      event.preventDefault();
      setPaymentData({
        ...paymentData,
        [id]: values,
      });
    };

    /**
     * Sincroniza nuevo valor autocomplete con el estado del Hook
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     * @param {*} id Identificador elemnto
     */
    const syncAutoCompletePaymentMethodChanges = (event, values, id) => {
      event.preventDefault();

      if (
        paymentData.medioPago?.tipoentidadbancaria !==
        values?.tipoentidadbancaria
      ) {
        setPaymentData({
          [id]: values,
          entidadFinanciera: '',
          toogleTipoCuenta: '1',
          numeroCuenta: '',
          descripcion: '',
        });
      } else {
        setPaymentData({
          ...paymentData,
          [id]: values,
        });
      }
    };

    const syncChangeAccountType = (event, newAlignment) => {
      setPaymentData({
        ...paymentData,
        toogleTipoCuenta: !!newAlignment ? newAlignment : '1',
      });
    };

    /**
     * Limpia caracteres invalidos para valores numericos
     * @param {*} e Evento
     */
    const validateOnlyNumbers = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexOnlyNumbers, '');
      setPaymentData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Captura y guarda información de forma de pago empleado
     * @param {*} e Evento
     */
    const onSubmit = (e) => {
      e.preventDefault();

      if (!simpleValidator.allValid()) {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }

      submitEmployee(e);
    };

    /**
     * Captura y guarda información de forma de pago empleado
     * @param {*} e Evento
     */
    const nextStep = (e) => {
      e.preventDefault();

      validateAllForms();
      if (simpleValidator.allValid()) {
        savePaymentData(paymentData);
        setFormCompleted((prevState) => {
          return {
            ...prevState,
            paymentData: true,
          };
        });
        setActiveStep((prevState) => {
          return prevState + 1;
        });
      } else {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }
    };

    /**
     * Valida formularios, muestra paso anterior
     * @param {*} e
     */
    const backStep = (e) => {
      validateAllForms();
      setActiveStep((prev) => {
        return prev - 1;
      });
    };

    return (
      <>
        <br></br>
        {getPaymentMethodsResponse === undefined ||
        getBanksResponse === undefined ? (
          <Grid container alignItems="center" justify="center">
            <CustomProgress />
          </Grid>
        ) : (
          <form id={'step-form'} onSubmit={onSubmit}>
            <Grid style={{ maxWidth: '60%', margin: '0 auto' }}>
              {/* Esto es para que se limpien las validaciones y se actualicen*/}
              {simpleValidator.purgeFields()}
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <CustomAutoComplete
                    label={'Medio de pago *'}
                    name="medioPago"
                    value={paymentData.medioPago}
                    options={getPaymentMethodsResponse}
                    textNoOption="No se encontraron resultados."
                    onChange={(event, values) => {
                      syncAutoCompletePaymentMethodChanges(
                        event,
                        values,
                        'medioPago',
                      );
                    }}
                    nameValidator={'medioPago'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} sm style={{ margin: '1% auto' }}>
                <div className="lineDivision"></div>
              </Grid>
              {paymentData?.medioPago?.tipoentidadbancaria !== null &&
                paymentData?.medioPago?.tipoentidadbancaria !== 3 && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item lg md={10} sm={12} xs={12}>
                        <CustomAutoComplete
                          name="entidadFinanciera"
                          label="Entidad financiera *"
                          value={paymentData.entidadFinanciera}
                          options={banks}
                          disabled={
                            paymentData?.medioPago === null ||
                            paymentData?.medioPago?.tipoentidadbancaria ===
                              null ||
                            paymentData?.medioPago?.tipoentidadbancaria === 3
                          }
                          textNoOption="No se encontraron resultados."
                          onChange={(event, values) => {
                            syncAutoCompleteChanges(
                              event,
                              values,
                              'entidadFinanciera',
                            );
                          }}
                          nameValidator={'entidad'}
                          validator={simpleValidator}
                          validateOptions={
                            paymentData?.medioPago?.tipoentidadbancaria !==
                              null &&
                            paymentData?.medioPago?.tipoentidadbancaria !== 3
                              ? 'required'
                              : 'void'
                          }
                        />
                      </Grid>
                      <Grid item lg md={10} sm={12} xs={12}>
                        <ToggleButtons
                          label={'Tipo de cuenta'}
                          value={paymentData.toogleTipoCuenta}
                          onChange={syncChangeAccountType}
                          titleOne={'Ahorros'}
                          titleTwo={'Corriente'}
                          txtTooltip={
                            <TooltipMessage
                              title={'¿Qué es esto?'}
                              message={
                                'Las cuentas de Ahorro y Corrientes generalmente son para hacer transacciones, como depósitos y retiros frecuentes de dinero.'
                              }
                            />
                          }
                          disabled={
                            paymentData?.medioPago === null ||
                            paymentData?.medioPago?.tipoentidadbancaria ===
                              null ||
                            paymentData?.medioPago?.tipoentidadbancaria === 3
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg md={10} sm={12} xs={12}>
                        <InputForm
                          noMargin={true}
                          name={'numeroCuenta'}
                          label={'N° de cuenta *'}
                          maxLength={30}
                          disabled={
                            paymentData?.medioPago === null ||
                            paymentData?.medioPago?.tipoentidadbancaria ===
                              null ||
                            paymentData?.medioPago?.tipoentidadbancaria === 3
                          }
                          onChange={(event) => {
                            syncChanges(event);
                            validateOnlyNumbers(event);
                          }}
                          value={paymentData.numeroCuenta}
                          validator={simpleValidator}
                          validateOptions={
                            paymentData?.medioPago?.tipoentidadbancaria !==
                              null &&
                            paymentData?.medioPago?.tipoentidadbancaria !== 3
                              ? 'required|min:5|max:30'
                              : 'void'
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              {paymentData?.medioPago?.tipoentidadbancaria === 3 && (
                <Grid container spacing={2}>
                  <Grid item lg md={10} sm={12} xs={12}>
                    <InputForm
                      noMargin={true}
                      name={'descripcion'}
                      label={'Descripción *'}
                      maxLength={100}
                      onChange={(event) => {
                        validateLettersAndNumbers(event);
                      }}
                      value={paymentData.descripcion}
                      validator={simpleValidator}
                      validateOptions={
                        paymentData?.medioPago?.tipoentidadbancaria === 3
                          ? 'required|max:100'
                          : 'void'
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3} alignItems="center">
                <Grid item lg md={10} sm={12} xs={12}>
                  <Button
                    onClick={backStep}
                    className={classes.buttonSuccess}
                    type={'button'}
                  >
                    Anterior
                  </Button>
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <Button
                    className={classes.buttonSuccess}
                    onClick={(e) => nextStep(e)}
                    type={'button'}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </>
    );
  },
);

export default PaymentMethod;
