import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color: '#FFFFFF',
    fontSize: '20px',
    flexBasis: '83%',
    flexShrink: 0,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  secondaryHeading: {
    fontSize: '14px',
    color: '#FFF',
    fontFamily: 'Muli',
    fontStyle: 'italic',
    fontWeight: '300',
    lineHeight: 'normal',
    margin: '10px 0  0',
  },
  paper: {
    background: '#363636',
    borderRadius: '4px !important',
  },
  cardIcon: {
    width: '19px',
    height: '19px',
    margin: '8px 13px 0 0',
  },
  summary: {
    '& .MuiAccordionSummary-content': {
      margin: '0px !important',
    },
  },
  detailsContent: {
    borderRadius: '5px',
    background: '#232323',
  },
  expandMoreIcon: {
    color: '#C9C9C9',
  },
}));

/**
 * * AccordionComponent es un componente personalizado de React para representar un panel de acordeón.
 *
 * @param {string} title - El título del panel de acordeón.
 * @param {string} subtitle - El subtítulo del panel de acordeón.
 * @param {string} iconSrc - La URL de origen para la imagen del icono.
 * @param {string} iconAlt - El texto alternativo para la imagen del icono.
 * @param {string} idPanel - El identificador único del panel de acordeón.
 * @param {function} setExpanded - Una función de devolución de llamada para manejar la expansión del panel.
 * @param {boolean} expanded - Un booleano que indica si el panel está expandido.
 * @param {ReactNode} detailsContent - El contenido que se mostrará cuando el panel esté expandido.
 * @param {Object} classesCustom - Clases CSS personalizadas para estilizar el componente (opcional).
 * @param {string} customClass - Clase personalizada adicional que se aplicará al componente.
 *
 * @returns {JSX.Element} Elemento JSX de React que representa el AccordionComponent.
 */
export const AccordionComponent = ({
  title,
  subtitle,
  iconSrc,
  iconAlt,
  idPanel,
  setExpanded,
  expanded,
  detailsContent,
  classesCustom,
  customClass,
}) => {
  const classes = classesCustom ?? useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={`${classes.root} ${customClass}`}>
      <Accordion
        expanded={expanded === idPanel}
        onChange={handleChange(idPanel)}
        className={classes.paper}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.paper} />}
          id="panel1bh-header"
          className={classes.summary}
        >
          <img className={classes.cardIcon} src={iconSrc} alt={iconAlt} />
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.secondaryHeading}>
            {subtitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsContent}>
          <Grid container style={{ margin: '5px' }}>
            <Grid item xs={12}>
              {detailsContent}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
