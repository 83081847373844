import React, { useEffect } from 'react';
import TabLoadProcess from './loadProcess';
import TabResumeProcess from './resumeProcess';
import FooterActions from './footerActions';
import CustomTabs from '../../../components/tabs/tabs.component';

const MassiveLoading = (props) => {
  const tabs = [
    {
      title: 'Carga de información',
      component: <TabLoadProcess />,
    },
    {
      title: 'Resutltado del cargue',
      component: <TabResumeProcess />,
    },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <div className="container-form">
        <CustomTabs tabs={tabs} />
      </div>
      <FooterActions />
    </>
  );
};

export default MassiveLoading;
