import { types } from '../actions/types';

const initialState = {
  bulkLoadErrorPayroll: [],
  bulkLoadPayroll: [],
  bulkLoadPayrollResponse: [],
  bulkLoadDataSetPayrol: [],
  reportDownloaded: false,
};

export const payrollBulkLoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BULKLOAD_PAYROLL:
      return {
        ...state,
        bulkLoadPayroll: action.payload,
      };
    case types.GET_BULKLOAD_ERROR_PAYROLL:
      return {
        ...state,
        bulkLoadErrorPayroll: action.payload,
      };
    case types.GET_BULKLOAD_DATASET_PAYROLL:
      return {
        ...state,
        bulkLoadDataSetPayrol: action.payload,
      };
    case types.GET_BULKLOAD_REPORTDOWNLOADED_PAYROLL:
      return {
        ...state,
        reportDownloaded: action.payload,
      };
    case types.GET_BULKLOAD_PAYROLL_RESPONSE:
      return {
        ...state,
        bulkLoadPayrollResponse: action.payload,
      };
    case types.CLEAN_BULKLOAD_PAYROLL:
      return initialState;
    default:
      return state;
  }
};
