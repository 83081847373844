import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { showErrorMessage } from '../utils/showErrorService';
import { enterpriseIdHeader } from './authActions';
import { types } from './types';

const urlConsultReported = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/GetPayrollReportedList `;
const urlConsultDetailReportd = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/GetPayrollReportedDetail `;
const urlFavoriteReportedUpdate = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/UpdateFavoriteStatus
`;

export const getPayrollReportedAction = (data) => async (
  dispatch,
  getState,
) => {
  try {
    data = {
      ...data,
      empresaId: enterpriseIdHeader(),
    };

    if (!data.LoadMore) {
      dispatch({
        type: types.GET_PAYROLL_REPORTED,
        payload: [],
      });
    }
    const { listReported } = getState().payrollReportedReducer;
    changeLoadingPayrollReported(dispatch, true);
    const resp = await axiosApiInstance.post(
      urlConsultReported,
      data,
      defaultHeaders(),
    );

    const newList = data.LoadMore
      ? [...listReported, ...(resp?.data?.result ?? [])]
      : resp?.data?.result ?? [];
    dispatch({
      type: types.GET_PAYROLL_REPORTED,
      payload: newList,
    });

    if (!data.LoadMore && resp && resp?.data?.result?.length > 0) {
      await dispatch(getPayrollReportedDetailAction(resp.data.result[0].id));
    }
  } catch (error) {
    showErrorMessage(error, 'Error consultando listado empleados Reportados');
  } finally {
    changeLoadingPayrollReported(dispatch, false);
  }
};

/**
 *  * Cambia el estado del Loader
 */

const changeLoadingPayrollReported = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAYROLL_REPORTED,
    payload: status,
  });
};
const changeSpinnerPayrollReported = (dispatch, status) => {
  dispatch({
    type: types.LOADING_SPINNER_PAYROLL_REPORTED,
    payload: status,
  });
};
const changeSpinnerPayrollReportedDetail = (dispatch, status) => {
  dispatch({
    type: types.LOADING_SPINNER_PAYROLL_REPORTED_DETAIL,
    payload: status,
  });
};

/**
 * * Filtra por Fechas
 */

export const filterByDatesReported = (filter) => {
  return {
    type: types.FILTER_BY_DATES_REPORTED,
    payload: filter,
  };
};

/**
 * * Detalle Reportada
 */

export const detailReportedID = async (dispatch, detailID) => {
  dispatch({
    type: types.SET_PAYROLL_ID,
    payload: detailID,
  });
};

export const getPayrollReportedDetailAction = (nominaID) => async (
  dispatch,
  getState,
) => {
  try {
    const { listReported } = getState().payrollReportedReducer;
    const payroll = listReported.find((c) => c.id === nominaID);

    const body = {
      nominaid: nominaID,
    };
    changeSpinnerPayrollReportedDetail(dispatch, true);
    const { data } = await axiosApiInstance.post(
      urlConsultDetailReportd,
      body,
      defaultHeaders(),
    );
    dispatch({
      type: types.GET_PAYROLL_DETAIL_REPOTED,
      payload: {
        ...payroll,
        ...data.result,
      },
    });
  } catch (error) {
    showErrorMessage(error, 'Error consultando Detalle Reportados');
  } finally {
    changeSpinnerPayrollReportedDetail(dispatch, false);
  }
};

export const setPayrollReportedFavoriteAction = (favorite) => async (
  dispatch,
) => {
  try {
    changeSpinnerPayrollReported(dispatch, true);
    const { data } = await axiosApiInstance.put(
      urlFavoriteReportedUpdate,
      favorite,
      defaultHeaders(),
    );
    if (data.httpCode === 200) {
      dispatch({
        type: types.SET_PAYROLL_FAVORITE_REPORTED,
        payload: favorite,
      });
    }
  } catch (error) {
    showErrorMessage(error, 'Error ');
  } finally {
    changeSpinnerPayrollReported(dispatch, false);
  }
};
