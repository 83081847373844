import { API_ENDPOINT_IBUHOO, URL_SECURITY } from '../config/config';
const axios = require('axios');

/**
 * Instancia Axios
 */
export const axiosApiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

/**
 * Response interceptor for API calls
 */
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 || error.response.status === 403) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        let dataToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${dataToken.token}`;
        setAuthTokenAxios(dataToken);
        return axiosApiInstance(originalRequest);
      } else {
        logOutApp();
      }
    }
    return Promise.reject(error);
  },
);

/**
 * Obtiene nuevo token con refresh token
 */
const refreshAccessToken = async () => {
  try {
    const refreshTokenData = {
      RefreshToken: localStorage.getItem('refreshToken'),
      UserName: localStorage.getItem('user'),
      Appname: 'colnomina',
    };

    var response = await axios.post(
      `${API_ENDPOINT_IBUHOO}/authentication/api/Login/RefreshToken`,
      refreshTokenData,
    );

    if (response?.data?.result !== undefined) {
      let result = {
        ...response.data.result,
        user: localStorage.getItem('user'),
      };
      return result;
    } else {
      logOutApp();
    }
  } catch (err) {
    console.log(
      'Error obteniendo refresh Token',
      !!err.response ? err.response : err,
    );
    logOutApp();
  }
};

/**
 * LogOut aplicacion, Redirecciona Login
 */
const logOutApp = () => {
  window.location.href = URL_SECURITY;
  setAuthTokenAxios();
};

/**
 * Borra información de Logueo
 * @param {*} data Informacion Token
 */
export const setAuthTokenAxios = (data) => {
  if (data) {
    axiosApiInstance.defaults.headers.common['Authorization'] =
      'Bearer ' + data.token;
    localStorage.setItem('jwtToken', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('user', data.user);
  } else {
    delete axiosApiInstance.defaults.headers.common['Authorization'];
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.clear();
  }
};
