import {
  GET_UNIT_MEASURE,
  GET_CATEGORY,
  SAVE_ARTICLE,
  GET_ARTICLE,
  LOAD_ARTICLE,
  UPDATE_ARTICLE,
  SUCCES_DELETE_ARTICLE,
  SEND_INDEX_TAB,
  UPDATE_FAVORITE_ARTICLE,
  GET_FILTER_ARTICLE,
  CLEAN_REQUEST,
  LOADING_GET_ARTICLES,
  GET_ARTICLE_LIST,
  GET_ARTICLE_CHECKED,
  LOADING_UPDATE_ARTICLE,
  LOADING_SAVE_ARTICLE
} from "../actions/types";
import isEmpty from "../utils/isEmpty";

const initialState = {
  requestUnitMeasure: "",
  requestCategory: "",

  requestGetArticle: {},

  requestDeleteArticle: {},
  closeCollapseArticle: "",

  loadingGetArticles: false,
  loadingUpdateArticle: false,
  loadingSaveArticle: false,
  editArticleStatus: false
};

export default function (state = initialState, action) {

  switch (action.type) {
    case GET_UNIT_MEASURE:
      return {
        ...state,
        requestUnitMeasure: action.payload,
      };

    case GET_CATEGORY:
      return {
        ...state,
        requestCategory: action.payload,
      };

    case SAVE_ARTICLE:
      return {
        ...state,
        saveArticleResponse: action.payload,
      };

    case GET_ARTICLE:
      return {
        ...state,
        getArticlesResponse: action.payload,
      };

    case LOAD_ARTICLE:
      return {
        ...state,
        loadArticleObject: action.payload,
        editArticleStatus: !isEmpty(action.payload),
      };

    case SUCCES_DELETE_ARTICLE:
      return {
        ...state,
        deleteArticleResponse: action.payload,
      };

    case SEND_INDEX_TAB:
      return {
        ...state,
        requestTabIndex: action.payload,
      };

    case UPDATE_FAVORITE_ARTICLE:
      return {
        ...state,
        updateFavoriteResponse: action.payload,
      };

    case UPDATE_ARTICLE:
      return {
        ...state,
        updateArticleResponse: action.payload,
      };

    case GET_FILTER_ARTICLE:
      return {
        ...state,
        getfilterArticle: action.payload,
        resultArticle: true,
      };
    case CLEAN_REQUEST:
      return {
        ...state,
        cleanRequest: true
      }

    case LOADING_GET_ARTICLES:
      return {
        ...state,
        loadingGetArticles: action.payload,
      }

    case GET_ARTICLE_LIST:
      return {
        ...state,
        getArticleListResponse: action.payload,
        totalArticles: action.payload1
      }

    case GET_ARTICLE_CHECKED:
      return {
        ...state,
        checkedArticles: action.payload,
      }

    case LOADING_UPDATE_ARTICLE:
      return {
        ...state,
        loadingUpdateArticle: action.payload,
      }

    case LOADING_SAVE_ARTICLE:
      return {
        ...state,
        loadingSaveArticle: action.payload,
      }

    default:
      return state;
  }
}
