import React from 'react';
import { useDispatch } from 'react-redux';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import UploadIcon from '../../images/icons/Upload.png';

import { makeStyles } from '@material-ui/core';
import { usePayroll } from '../../hooks/usePayroll';
import { getDestructuredDate } from '../../helpers/functions.helpers';
import { updatePeriodDateAction } from '../../actions/payrollReportAction';

const useStyles = makeStyles({
  container: {
    gap: '2%',
    display: 'grid',
    // gridTemplateColumns: 'repeat(1,1fr)',
    gridTemplateColumns: 'repeat(2,1fr)', //*! NO BORRAR ACTIVAR PARA EL BOTON DE CARGUE MASIVO
    color: '#fff',
  },
  item: {
    cursor: 'pointer',
    gridAutoColumns: '1fr',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    display: 'flex',
    justifyContent: 'center',
    padding: '1%',
    alignItems: 'center',
  },
});

const ReportNewPayrollButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setContentTab } = usePayroll();

  /**
   * Abre modulo para reporte de nomina
   */
  const handleNewPayroll = () => {
    const { month, year } = getDestructuredDate({
      date: new Date(),
      day: false,
    });
    setContentTab(2);
    dispatch(updatePeriodDateAction(month, year));
  };

  /**
   *  Abre modulo para carga masiva de nomina
   */
  const handleNewPayrollLoad = () => {
    setContentTab(3);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.item} onClick={handleNewPayroll}>
          Reportar nueva nómina &nbsp;&nbsp;&nbsp;
          <AddCircleRoundedIcon style={{ color: '#D8D8D8' }} />
        </div>
        <div className={classes.item} onClick={handleNewPayrollLoad}>
          Carga masiva de nómina &nbsp;&nbsp;&nbsp;
          <img src={UploadIcon} alt="Upload" />
        </div>
      </div>
    </>
  );
};

export default ReportNewPayrollButton;
