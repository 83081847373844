import React, { useState } from 'react';

import {
  makeStyles,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

import clsx from 'clsx';

import LoadInformation from './componentSteper/LoadInformation';
import LoadResult from './componentSteper/LoadResult';
import FooterStepper from './componentSteper/FooterStepper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secundaryBackgroundColor,
    paddingBottom: '18px',
    paddingTop: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  bulkLoad: {
    minHeight: '93vh',
    padding: '26px 8px 0 0',
    backgroundColor: '#232323',
    borderRadius: '8px',
    [theme.breakpoints.down(1398)]: {
      height: '93vh',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
  },
  activeButton: {
    borderBottom: '1px solid #50C1EE',
    paddingBottom: '10px',
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#FFFFFF',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#2CC63E',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#4F4F4F',
  },
  completed: {
    color: '#4F4F4F',
    zIndex: 1,
    fontSize: 18,
    backgroundColor: '#2CC63E',
    borderRadius: '50%',
    padding: 5,
  },
});

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  line: {
    borderColor: '#4F4F4F',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return ['Carga de información', 'Resultado del Cargue'];
}

const BulkLoad = ({ setBulkLoad, setUpdateEmployee }) => {
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [completed, setCompleted] = useState(new Set());
  const [modalData, setModalData] = useState(false);

  const classes = useStyles();
  const steps = getSteps();

  /**
   * *Marca paso a completo
   * *@param {*} step
   * *@returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {' '}
        {completed ? (
          <Check className={classes.completed} />
        ) : (
          <div className={classes.circle} />
        )}{' '}
      </div>
    );
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <LoadInformation
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </>
        );

      case 1:
        return (
          <>
            <LoadResult
              activeStep={activeStep}
              modalData={modalData}
              setModalData={setModalData}
              setBulkLoad={setBulkLoad}
              setUpdateEmployee={setUpdateEmployee}
            />
          </>
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <div className={classes.bulkLoad}>
      <Stepper
        alternativeLabel
        nonLinear
        connector={<QontoConnector />}
        activeStep={activeStep}
        className={classes.root}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ''}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className="stepper-title">{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <>{getStepContent(activeStep)}</>
      <>
        {FooterStepper(
          activeStep,
          setBulkLoad,
          setActiveStep,
          setModalData,
          setUpdateEmployee,
          modalData,
        )}
      </>
    </div>
  );
};

export default BulkLoad;
