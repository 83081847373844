import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import '../../../styles/employees.css';

// custom hooks
import { useRegisterEmployee } from '../../../hooks/useRegisterEmployee';
import BasicDataEmployee from '../register/basicData/basicDataEmployee.view';
import ContractData from '../register/contractData/contractData.view';
import PaymentMethod from '../register/paymentMethod/paymentMethod.view';
import ContribDeducFixed from '../register/contributionsDeductionsFixed/contribDeducFixed.view';
import { getBranchOfficeAction } from '../../../actions/branchOfficeActions';
import FooterNav from './footerNav';
import { useEmployees } from '../../../hooks/useEmployees';
import { Grid } from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secundaryBackgroundColor,
  },
  activeButton: {
    borderBottom: '1px solid #50C1EE',
  },
}));

const QontoConnector = withStyles({
  root: {},
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#4F4F4F',
    },
  },
  line: {
    borderColor: '#4F4F4F',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: 'white',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#2CC63E',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#4F4F4F',
  },
  completed: {
    color: '#4F4F4F',
    zIndex: 1,
    fontSize: 18,
    backgroundColor: '#2CC63E',
    borderRadius: '50%',
    padding: 5,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function getSteps(components) {
  return [
    components.titleStep1BasicData,
    components.titleStep2ContractData,
    components.titleStep3PaymentMethod,
    components.titleStep4NoveltyFixed,
  ];
}

const Register = ({ setEmployeeId, menuReducer, setTabSelected }) => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(new Set());
  const [stepsCompleted, setStepCompleted] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { menuEmployees } = menuReducer;
  const [steps, setSteps] = useState(null);
  const [message, setMessage] = useState('');

  const simpleValidatorBasic = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  ).current;

  const simpleValidatorContract = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  ).current;

  const simpleValidatorPayment = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  ).current;

  const { reintegreStatus } = useSelector((s) => s.employeeReducer);

  // state redux
  const {
    basicData,
    contractData,
    paymentData,
    noveltiesData,
    lastSupports,
    setBasicData,
    setNoveltiesData,
    setPaymentData,
    setContractData,
    formCompleted,
    setFormCompleted,
    saveBasicData,
    savePaymentData,
    saveContractData,
    getWorkerTypes,
    getWorkerSubTypes,
    getContractTypes,
    getPaymentPeriod,
    resetFormRedux,
    isEditing,
    employeeDetail,
    getListBranch,
    updateEmployeeProcess,
    createEmployeeProcess,
    registerReduxForm,
    updateExpense,
    updateIncome,
    addIncome,
    addExpense,
    deleteExpense,
    deleteIncome,
    getRetireCauses,
    setReintegreStatus,
  } = useRegisterEmployee();

  const { changeEditingStatus, changeOpenRegisterEmployee } = useEmployees();

  useEffect(() => {
    if (registerReduxForm.editEmployeeStatus) {
      handleCompleteStep(0);
      handleCompleteStep(1);
      handleCompleteStep(2);
      handleCompleteStep(3);
    } else {
      if (registerReduxForm.isCompleteBasicData) {
        handleCompleteStep(0);
      }
      if (registerReduxForm.isCompleteContractData) {
        handleCompleteStep(1);
      }
      if (registerReduxForm.isCompletePaymentData) {
        handleCompleteStep(2);
      }
      if (registerReduxForm.isCompleteNoviceData) {
        handleCompleteStep(3);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerReduxForm, formCompleted]);

  useEffect(() => {
    menuEmployees && setSteps(getSteps(menuEmployees));
  }, [menuEmployees]);

  /**
   * Captura y guarda información de empleado
   * @param {*} e Evento
   */
  const submitEmployee = (e) => {
    e.preventDefault();

    const isValid = validateAllForms();

    if (
      registerReduxForm.isCompleteBasicData &&
      registerReduxForm.isCompleteContractData &&
      registerReduxForm.isCompletePaymentData &&
      isValid
    ) {
      if (isEditing) {
        updateEmployeeProcess(reintegreStatus, completeProcess);
      } else {
        createEmployeeProcess(completeProcess);
      }
    }
  };

  /**
   * Completa proceso actualización y creación de empleado
   */
  const completeProcess = (isEditing) => {
    if (isEditing) {
      changeEditingStatus(false);
      changeOpenRegisterEmployee(false);
      resetFormRedux();
      setReintegreStatus(false);
    } else {
      resetFormRedux();
      changeOpenRegisterEmployee(false);
      setReintegreStatus(false);
    }
  };

  /**
   * Valida todos los formularios de la seccion empleados
   */
  const validateAllForms = () => {
    if (!isEditing) return true;

    const messages = [];
    let valid = true;
    if (!simpleValidatorBasic.allValid()) {
      messages.push('Datos básicos');
      simpleValidatorBasic.showMessages();
      valid = valid === true ? false : valid;
    }

    if (!simpleValidatorContract.allValid()) {
      messages.push('Datos de contratación');
      simpleValidatorContract.showMessages();
      valid = valid === true ? false : valid;
    }

    if (!simpleValidatorPayment.allValid()) {
      messages.push('Forma de Pago');
      simpleValidatorPayment.showMessages();
      valid = valid === true ? false : valid;
    }

    if (messages.length > 0) {
      let notValidMessage = messages.join(', ');
      notValidMessage = `Tienes campos pendientes por completar en la(s) seción(es) ${notValidMessage}.`;
      setMessage(notValidMessage);
    } else {
      setMessage('');
    }

    return valid;
  };

  /**
   * Listado de componentes para el paso a paso
   * @param {*} step Identificador paso
   */
  const getStepContent = (step) => {
    return (
      <>
        <div style={{ display: step === 0 ? '' : 'none' }}>
          <BasicDataEmployee
            basicData={basicData}
            setBasicData={setBasicData}
            setFormCompleted={setFormCompleted}
            saveBasicData={saveBasicData}
            setEmployeeId={setEmployeeId}
            isEditing={isEditing}
            setActiveStep={setActiveStep}
            haveAnySupport={lastSupports}
            resetFormRedux={resetFormRedux}
            setTabSelected={setTabSelected}
            submitEmployee={submitEmployee}
            simpleValidator={simpleValidatorBasic}
            validateAllForms={validateAllForms}
          />
        </div>
        <div style={{ display: step === 1 ? '' : 'none' }}>
          <ContractData
            getListBranch={getListBranch}
            contractData={contractData}
            setContractData={setContractData}
            saveContractData={saveContractData}
            getWorkerTypes={getWorkerTypes}
            getWorkerSubTypes={getWorkerSubTypes}
            getContractTypes={getContractTypes}
            getPaymentPeriod={getPaymentPeriod}
            setActiveStep={setActiveStep}
            setFormCompleted={setFormCompleted}
            isEditing={isEditing}
            submitEmployee={submitEmployee}
            getRetireCauses={getRetireCauses}
            simpleValidator={simpleValidatorContract}
            validateAllForms={validateAllForms}
          />
        </div>
        <div style={{ display: step === 2 ? '' : 'none' }}>
          <PaymentMethod
            paymentData={paymentData}
            setPaymentData={setPaymentData}
            savePaymentData={savePaymentData}
            setFormCompleted={setFormCompleted}
            setActiveStep={setActiveStep}
            submitEmployee={submitEmployee}
            simpleValidator={simpleValidatorPayment}
            validateAllForms={validateAllForms}
          />
        </div>
        <div style={{ display: step === 3 ? '' : 'none' }}>
          <ContribDeducFixed
            updateIncome={updateIncome}
            addIncome={addIncome}
            updateExpense={updateExpense}
            addExpense={addExpense}
            deleteExpense={deleteExpense}
            deleteIncome={deleteIncome}
            noveltiesData={noveltiesData}
            dataIncome={employeeDetail?.ingresos}
            dataExpense={employeeDetail?.deducciones}
            setNoveltiesData={setNoveltiesData}
            setActiveStep={setActiveStep}
            submitEmployee={submitEmployee}
            validateAllForms={validateAllForms}
          />
        </div>
      </>
    );
  };

  /**
   * Agrega paso completo a listado
   * @param {int} index Identificador paso
   */
  const handleCompleteStep = (index) => {
    let exist = stepsCompleted.find((s) => s === index);
    let newArray = [];
    newArray = stepsCompleted;
    if (exist === null || exist === undefined) {
      newArray.push(index);
      setStepCompleted(newArray);
      setCompleted(new Set(newArray));
    }
    if (!index) {
      setStepCompleted([]);
    }
  };

  const handleStep = (step) => () => {
    if (registerReduxForm.editEmployeeStatus) {
      setActiveStep(step);
    } else {
      if (isStepCompleted(step)) {
        setActiveStep(step);
      }
    }
  };

  function isStepCompleted(step) {
    return completed.has(step);
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <div className="container-form">
          <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            connector={<QontoConnector />}
            className={classes.root}
          >
            {steps != null &&
              steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepButton
                      onClick={handleStep(index)}
                      completed={isStepCompleted(index)}
                      className={
                        activeStep === index ? classes.activeButton : ''
                      }
                    >
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <label className="stepper-title">{label}</label>
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
          </Stepper>
          <>{getStepContent(activeStep)}</>
          {message.length > 0 && (
            <div
              style={{
                width: '100%',
                color: '#f44336',
                textAlign: 'center',
                padding: 20,
              }}
            >
              {message}
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} lg={12}>
        <FooterNav
          employeeDetail={employeeDetail}
          setActiveStep={setActiveStep}
          resetFormRedux={resetFormRedux}
          basicData={basicData}
          activeStep={activeStep}
          isEditing={isEditing}
          updateEmployeeProcess={updateEmployeeProcess}
          createEmployeeProcess={createEmployeeProcess}
          setBasicData={setBasicData}
          formCompleted={
            registerReduxForm.isCompleteBasicData &&
            registerReduxForm.isCompleteContractData &&
            registerReduxForm.isCompletePaymentData
          }
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, { getBranchOfficeAction })(Register);
