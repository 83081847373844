import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { SuccessAlert2, ConfirmAlert2 } from '../../../helpers/alert.helpers';

import Biller from './biller';

import UserCard from '../../../components/card/userCard.component';
import InputIcon from '../../../components/input/inputIcon.component';
import CollapsePanel from '../../../components/collapse/collapse.component';
import NotificationNotCreated from '../../../components/notification/notificationNotCreated.component';
import CustomProgress from '../../../components/Progress/progress.component';

import {
  loadBillerAction,
  updateBillerStatusAction,
  getBillersAction,
} from '../../../actions/userAction';
import isEmpty from '../../../utils/isEmpty';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBillersList: [],
      txtFilterBiller: '',
      filterBillers: [],
      resultBiller: false,
      updateBillerStatusResponse: {},
      expandPanelBiller: '',
      editBillerStatus: false,
      saveBillerResponse: {},
      updateBillerResponse: {},
      checkedState: false,
      companyId: 0,
      loadingGetBillers: false,
      menuMyAccount: {},
    };
  }

  componentDidMount() {
    this.props.getBillersAction('', false);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      nextProps.userReducer.filteredBillersList !== state.filteredBillersList
    ) {
      update.filteredBillersList = nextProps.userReducer.filteredBillersList;
    }

    if (
      !!nextProps.userReducer.updateBillerStatusResponse &&
      nextProps.userReducer.updateBillerStatusResponse !==
        state.updateBillerStatusResponse
    )
      update.updateBillerStatusResponse =
        nextProps.userReducer.updateBillerStatusResponse;

    if (nextProps.userReducer.editBillerStatus !== state.editBillerStatus)
      update.editBillerStatus = nextProps.userReducer.editBillerStatus;

    if (
      !!nextProps.userReducer.saveBillerResponse &&
      nextProps.userReducer.saveBillerResponse !== state.saveBillerResponse
    )
      update.saveBillerResponse = nextProps.userReducer.saveBillerResponse;

    if (
      !!nextProps.userReducer.updateBillerResponse &&
      nextProps.userReducer.updateBillerResponse !== state.updateBillerResponse
    ) {
      update.updateBillerResponse = nextProps.userReducer.updateBillerResponse;
    }

    if (
      !isEmpty(nextProps.userReducer.loadingGetBillers) &&
      nextProps.userReducer.loadingGetBillers !== state.loadingGetBillers
    )
      update.loadingGetBillers = nextProps.userReducer.loadingGetBillers;

    if (
      !!nextProps.menuReducer.menuMyAccount &&
      nextProps.menuReducer.menuMyAccount !== state.menuMyAccount
    )
      update.menuMyAccount = nextProps.menuReducer.menuMyAccount;

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.updateBillerStatusResponse !==
      this.state.updateBillerStatusResponse
    ) {
      if (this.state.updateBillerStatusResponse.statusCode === '201') {
        SuccessAlert2(
          this.state.menuMyAccount.titlePopUpdateUser,
          this.state.menuMyAccount.messagePopUpdateUser.replace(
            '$nombreUsuario',
            this.state.updateBillerStatusResponse.result.nombres +
              ' ' +
              this.state.updateBillerStatusResponse.result.apellidos,
          ),
        );
      }
    }

    if (prevState.saveBillerResponse !== this.state.saveBillerResponse) {
      if (
        this.state.saveBillerResponse.statusCode === '201' ||
        this.state.saveBillerResponse.statusCode === '400'
      ) {
        this.setState({
          expandPanelBiller: '',
        });
      }
    }

    if (prevState.updateBillerResponse !== this.state.updateBillerResponse) {
      if (
        this.state.updateBillerResponse.statusCode === '200' ||
        this.state.updateBillerResponse.statusCode === '201'
      ) {
        this.setState({
          expandPanelBiller: '',
        });
      }
    }
  }

  componentWillUnmount() {}

  /**
   * Evento, Cambio de estado facturador desde listado
   * @param {*} e Evento
   * @param {int} id  Identificador Facturador
   * @param {int} data  Información del usuario a modificar
   */
  onChangeStatusBiller = (e, id, data) => {
    e.preventDefault();
    const checked = e.target.checked;

    const changeStatusBiller = (confirmed) => {
      if (confirmed) {
        this.props.updateBillerStatusAction(id, checked ? 0 : 1);
      }
    };
    ConfirmAlert2(
      `¿Esta seguro que desea ${checked ? 'activar' : 'inactivar'} el usuario ${
        data.nombres
      } ${data.apellidos}?`,
      changeStatusBiller,
      `${checked ? 'Activar' : 'Inactivar'}`,
    );
  };

  /**
   * Evento,  escucha cambios en filtro facturadores,
   * invoca función para filtrado
   * @param {*} e Evento
   */
  onChangeFilterBiller(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      txtFilterBiller: value,
    });
    this.props.getBillersAction(value, false);
  }

  /**
   * Evento, Exapande o collapsa 'Collapse' formulario facturador
   */
  onClickCollapseBillerChange = () => {
    this.setState({
      expandPanelBiller:
        this.state.expandPanelBiller === 'panel' ? '' : 'panel',
    });
  };

  /**
   * Evento, Carga información de facturador en formulario
   * para edición
   * @param {*} e Evento
   * @param {object} data Información facturador
   */
  onLoadBiller = (e, data) => {
    e.preventDefault();
    this.setState({
      expandPanelBiller: 'panel',
    });
    window.scrollTo(0, 0);
    this.props.loadBillerAction(data);
  };

  render() {
    return (
      <Fragment>
        <div className="comunContainer">
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
              <CollapsePanel
                component={Biller}
                title={
                  !this.state.editBillerStatus
                    ? this.state.menuMyAccount.buttonCreateUser
                    : 'Editar Usuario'
                }
                titleOpen={
                  !this.state.editBillerStatus
                    ? 'Nuevo Usuario'
                    : 'Editar Usuario'
                }
                titleEdit={'Editar Usuario'}
                open={this.state.expandPanelBiller}
                onClick={() => this.onClickCollapseBillerChange()}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <InputIcon
              id={'txtFilterBiller'}
              label={'Buscar Usuario'}
              value={this.state.txtFilterBiller}
              onChange={(e) => {
                this.onChangeFilterBiller(e);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {this.state.loadingGetBillers === true ? (
            <Grid item lg={12} xs={12}>
              <Grid container alignItems="center" justify="center">
                <CustomProgress />
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {this.state.filteredBillersList.length > 0 ? (
                <Fragment>
                  {this.state.filteredBillersList.map((data, index) => (
                    <Grid item key={index} lg={4} xs={12}>
                      <UserCard
                        type={'Biller'}
                        data={data}
                        onClick={(e) => this.onLoadBiller(e, data)}
                        onChange={(e) =>
                          this.onChangeStatusBiller(e, data.id, data)
                        }
                        disabled={this.props.disabledActions}
                      />
                    </Grid>
                  ))}
                </Fragment>
              ) : (
                <Grid item lg={12} xs={12}>
                  <NotificationNotCreated
                    text={'No se han encontrado usuarios...'}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      </Fragment>
    );
  }
}

User.propTypes = {
  loadBillerAction: PropTypes.func.isRequired,
  updateBillerStatusAction: PropTypes.func.isRequired,
  getBillersAction: PropTypes.func.isRequired,
  userReducer: PropTypes.object.isRequired,
  refrehAfterUpdateBillerStatusAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  authReducer: state.authReducer,
  menuReducer: state.menuReducer,
  disabledActions: state.userReducer.disabledActions,
});

export default connect(mapStateToProps, {
  loadBillerAction,
  updateBillerStatusAction,
  getBillersAction,
})(User);
