/**
 * * Obtiene un resumen del estado de los conceptos a partir de un arreglo de conceptos.
 *
 * @param {Array<Object>} conceptArray - Arreglo de objetos que representan conceptos con un estado.
 * @returns {string} - Un resumen del estado de los conceptos, que puede ser 'Transmitido', 'Registrado', 'Mixto' o una cadena vacía.
 */
export const getStatusSummary = (conceptArray) => {
  const registeredConceptsCount = conceptArray.filter(
    (concept) => concept.status === 'Registrado',
  ).length;
  const hasTransmittedConcept = conceptArray.some(
    (concept) => concept.status === 'Transmitido',
  );

  return hasTransmittedConcept
    ? 'Transmitido'
    : registeredConceptsCount === conceptArray.length
    ? 'Registrado'
    : registeredConceptsCount < conceptArray.length &&
      registeredConceptsCount > 0
    ? 'Mixto'
    : 'Error';
};
