import {
  GET_LICENCE_DATA,
  LOADING_LICENCE_DATA,
  GET_COUNTER_DATA,
  LOADING_COUNTER_DATA,
  GET_INFO_CHARTS,
  LOADING_INFOCHARTS_DATA,
  types,
} from '../actions/types';

const initialState = {
  licenceData: null,
  loading: false,
  counterData: null,
  licenseReference: null,
  licenseValidity: null,
  updateLicense: false,
  loadingCounter: false,
  loadingInfoCharts: false,

  infoCharts: {
    concepts: [],
    behaviorMonth: [],
    payrollPayment: [],
    employeesConfirmed: [],
    employeesTransmitted: [],
    documentsTransmitted: [],
  },
  loadingLicenseReference: false,
  loadingLicenseUpdate: false,
  loadingLicenseValidity: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LICENCE_DATA:
      return {
        ...state,
        licenceData: action.payload,
      };
    case LOADING_LICENCE_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_COUNTER_DATA:
      return {
        ...state,
        counterData: action.payload,
      };
    case LOADING_COUNTER_DATA:
      return {
        ...state,
        loadingCounter: action.payload,
      };
    case GET_INFO_CHARTS:
      return {
        ...state,
        infoCharts: action.payload,
      };
    case LOADING_INFOCHARTS_DATA:
      return {
        ...state,
        loadingInfoCharts: action.payload,
      };
    case types.GET_LICENSE_REFERENCE:
      return {
        ...state,
        licenseReference: action.payload,
      };
    case types.SET_LICENSE_VALIDATION:
      return {
        ...state,
        licenseValidity: action.payload,
      };
    case types.UPDATE_LICENSE_EMPLOYEE:
      return {
        ...state,
        updateLicense: action.payload,
      };
    case types.LOADING_LICENSE_REFERENCE:
      return {
        ...state,
        loadingLicenceReference: action.payload,
      };
    case types.LOADING_LICENSE_UPDATE:
      return {
        ...state,
        loadingLicenseUpdate: action.payload,
      };
    case types.LOADING_LICENSE_VALIDITY:
      return {
        ...state,
        loadingLicenseValidity: action.payload,
      };
    case types.CLEAR_LICENSE_REFERENCE:
      return {
        ...state,
        licenseReference: null,
      };
    default:
      return state;
  }
};
