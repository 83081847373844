import React from 'react';

import { Grid } from '@material-ui/core';

import { RenderCardResult } from './';

export const CardTypeInformation = ({
  data,
  title,
  description,
  status,
  classes,
}) => {
  return (
    <Grid className={classes.gridResult} item lg={4} xs={12}>
      {RenderCardResult(data, title, description, status, classes)}
    </Grid>
  );
};
