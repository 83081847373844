import React from 'react';
import './select.css';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: '5px 0px',
  },
}));

const CustomSelect = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.Mui-disabled': {
      color: '#FFFF', //gris
    },
    '& div.Mui-disabled': {
      color: 'rgba(255,255,255,0.5);',
    },
    '& label.MuiFormLabel-filled:not(.Mui-focused)': {
      color: 'gray', //gris
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.thirdColor, //azul
    },
    '& .MuiIconButton-label': {
      color: '#FFFF',
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInput-underline:focus': {
      borderBottomColor: 'gray', //gris
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid',
        borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.thirdColor, //azul
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', //gris
      },
      '& svg': {
        color: theme.palette.fifthBackgroundColor,
      },
      '& .MuiSelect-root': {
        textAlign: 'left',
      },
    },
  },
}))(TextField);

const SelectForm = ({ ...props }) => {
  const classes = useStyles();
  const {
    id,
    onChange,
    values,
    options,
    label,
    value,
    hidden,
    validator,
    validateOptions,
    name,
    disabled,
    nameValidator,
  } = props;
  const optionsSelect =
    !!options && options.length > 0
      ? options.map((option, index) =>
          option !== undefined && option !== null
            ? {
                key: index,
                value: option.value,
                text: option.text,
              }
            : '',
        )
      : !!values && values.length > 0
      ? values.map((item, index) => ({
          key: index,
          text: item,
          value: item,
        }))
      : [];

  let errorValidator = validator
    ? validator.message(
        !!nameValidator ? nameValidator : label,
        value,
        validateOptions,
      )
    : '';

  if (hidden) return null;

  return (
    <div className={classes.root}>
      <CustomSelect
        id={id}
        value={value}
        select
        className={classes.margin}
        name={name}
        label={label}
        fullWidth
        onChange={onChange}
        disabled={!!disabled && disabled === true ? true : false}
        variant="outlined"
        error={!!errorValidator ? true : false}
        helperText={errorValidator}
        SelectProps={{
          IconComponent: (props) => <ExpandMoreIcon {...props} />,
        }}
      >
        {optionsSelect.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </CustomSelect>
    </div>
  );
};

SelectForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default SelectForm;
