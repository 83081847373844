import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { format } from 'date-fns';

//Actions
import {
  updateDocumentFavoriteStatusAction,
  getDocumentFilesAction,
  cleanFileIntegrationAction,
  sendDocumentIntegrationAction,
} from '../../../../actions/integrationAction';

// components
import CustomTooltip from '../../../tooltip/tooltip.component';
import TooltipMessage from '../../../tooltip/tootltip-message.component';
import ResponseModal from '../../../modal/responseModal.component';

// personalized icons
import ErrorReportingIcon from '../../../../images/icons/errorReporting.png';
import TransferedBlankIcon from '../../../../images/icons/transferedBlank.png';
import PendingTransferIcon from '../../../../images/icons/cloud.png';
// helpers
import { getStringFromDate } from '../../../../helpers/functions.helpers';
import isEmpty from '../../../../utils/isEmpty';

// personalized form
import ArchiveModal from '../../../modal/archiveModal';
import { CustomModal } from '../../../modal/customModal';
import { EmailConfirmForm } from '../../../email/emailConfirm';

import integrationIcon from '../../../../images/icons/integration.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#5C5C5C', //  theme.palette.fourthBackgroundColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
    margin: 0,
    borderRadius: 4,
    boxShadow: '0px 0px',
    fontSize: 14,
    '& .MuiCardHeader-root': {
      paddingTop: 5,
      paddingBottom: 0,
    },
  },
  header: {
    padding: '1% 2%',
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 800,
    fontSize: 20,
    color: '#FFFFFF',
  },
  iconToAssign: {
    color: '#FFF',
  },
  cardContent: {
    padding: '0px 2%',
    color: '#FFF',
  },
  cardContentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalToPaid: {
    color: '#2CC63E',
    fontSize: '20px',
    fontWeight: '600',
  },
  dateDian: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const GetIconState = ({ state, fechavalidacion }) => {
  return (
    <>
      {state === '00' ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Transmisión a DIAN"
              message={
                <span>
                  Validación DIAN:
                  <span style={{ fontWeight: 'bold' }}> Aceptada </span>
                </span>
              }
              description={
                <div>
                  Fecha validacíon:
                  <span style={{ fontWeight: 'bold' }}>
                    {format(new Date(fechavalidacion), 'yyyy/MM/dd')}
                  </span>
                </div>
              }
              isPayrool={true}
              href={null}
              bgColor="#7DD1F8"
            />
          }
          iconEnvolved={
            <img
              src={TransferedBlankIcon}
              alt="Transmitido"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : state === '99' || state === '66' ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Error DIAN - Documento con errores"
              message="El documento presenta una inconsistencia ante la DIAN."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={ErrorReportingIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : state === '500' ? (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Pendiente de sincronización con DIAN."
              message="Este Ítem esta pendiente de sincronización con DIAN."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={PendingTransferIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%' }}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

//Componente
const IntegrationCard = ({ support }) => {
  const classes = useStyles();
  const [openModalMail, setOpenModalMail] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [base64PDF, setBase64PDF] = useState(null);
  const [base64XML, setBase64XML] = useState(null);
  const [modalData, setModalData] = React.useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
  });

  const dispatch = useDispatch();

  const {
    filesIntegrationList,
    loadingGetFilesIntegration,
    loadingSendDocumentIntegration,
  } = useSelector((state) => state.integrationReducer);

  /**
   * Escucha cambios en listado archivos asociados a documento
   */
  useEffect(() => {
    if (!!filesIntegrationList) {
      let filePDF = filesIntegrationList.find(
        (c) => c.tipoContenido === 'application/pdf',
      );
      let fileXML = filesIntegrationList.find(
        (c) => c.tipoContenido === 'application/xml',
      );

      if (!!filePDF) {
        setBase64PDF(filePDF?.archivoBase64);
        setBase64XML(fileXML?.archivoBase64);
      }
    } else {
      setBase64PDF(null);
      setBase64XML(null);
    }
  }, [filesIntegrationList]);

  /**
   * Limpia listado archivos asociados a documento
   */
  const handleCloseModalFile = () => {
    setOpenModalFile(false);
    dispatch(cleanFileIntegrationAction());
  };

  const validarestadonomina = (codigoestadodian) => {
    switch (codigoestadodian) {
      case '00':
        return 'Aceptado';
      case '99':
      case '66':
        return 'Error en documento';
      case '500':
        return 'Ocurrió error al enviar a la Dian';
      default:
        return '';
    }
  };

  const OpenMailModal = () => {
    if (isEmpty(support?.codigounico) || support?.codigoestadodian !== '00') {
      setModalData({
        ...modalData,
        type: 'warning',
        title: 'Upss...!!!',
        subtitle: 'Documento no disponible',
        body: (
          <div>
            {
              'El documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá enviarlo.'
            }
          </div>
        ),
        modalImage: 'warning',
        open: true,
        closeElement: 'Cerrar',
        onCloseElement: 0,
      });
    } else {
      setOpenModalMail(true);
    }
  };

  const handleCloseModalMail = () => {
    setOpenModalMail(false);
  };

  /**
   * Consulta y previsualiza archivo
   */
  const previewDocumentFile = () => {
    if (isEmpty(support?.codigounico) || support?.codigoestadodian !== '00') {
      setModalData({
        ...modalData,
        type: 'warning',
        title: 'Upss...!!!',
        subtitle: 'Archivo no disponible',
        body: (
          <div>
            {
              'Mientras, el documento no se encuentre aprobado por DIAN, Colfactura nómina no te permitirá visualizarlo.'
            }
          </div>
        ),
        modalImage: 'warning',
        open: true,
        closeElement: 'Cerrar',
        onCloseElement: 0,
      });
    } else {
      setOpenModalFile(true);
      dispatch(getDocumentFilesAction(support?.codigounico));
    }
  };

  /**
   * Cierra Modal, Notificación codigo unico no valido
   */
  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  const updateDocumentFavoriteStatus = useCallback(
    (id, favorite) => {
      dispatch(updateDocumentFavoriteStatusAction(id, favorite));
    },
    [dispatch],
  );

  /**
   * Envia soporte de nomina a un determinado corrreo electronico
   * @param {*} mailTo Correo electronico
   * @param {*} codigounico Codigo unico del soporte de nómina
   */
  const SendSupportToMail = (mailTo) => {
    dispatch(
      sendDocumentIntegrationAction(
        mailTo,
        support.nombrestrabajador,
        support?.codigounico,
      ),
    );
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        classes={{
          title: classes.title,
        }}
        title={getStringFromDate(new Date(support?.periodo), true)}
        subheader=""
        action={
          <>
            <IconButton aria-label="view" onClick={previewDocumentFile}>
              <VisibilityIcon className={classes.iconToAssign} />
            </IconButton>
            <IconButton aria-label="email">
              <EmailIcon
                className={classes.iconToAssign}
                onClick={() => OpenMailModal()}
              />
            </IconButton>
            <IconButton
              aria-label="favorite"
              onClick={() =>
                updateDocumentFavoriteStatus(support.id, !support.favorito)
              }
            >
              {support.favorito ? (
                <StarIcon className={classes.iconToAssign} />
              ) : (
                <StarBorderIcon className={classes.iconToAssign} />
              )}
            </IconButton>
          </>
        }
      />
      <div className={classes.cardContent}>
        <div className={classes.cardContentHeader}>
          <span>Consecutivo: {support?.numero}</span>
          <span className={classes.totalToPaid}>
            {'$' + new Intl.NumberFormat('es-CO').format(support?.valorapagar)}
          </span>
        </div>
        <span>
          Validación DIAN: {validarestadonomina(support?.codigoestadodian)}
        </span>
        <div className={classes.dateDian}>
          <span>
            Fecha validación DIAN:
            {support?.fechavalidaciondian
              ? ` ${format(
                  new Date(support?.fechavalidaciondian),
                  'dd/MM/yyyy',
                )}`
              : ''}
          </span>
          <div>
            <CustomTooltip
              message={
                <TooltipMessage
                  title=""
                  message="Este soporte de nómina  fue creado en un Software diferente y cargado por integración."
                  href={null}
                  bgColor="#FFF"
                />
              }
              iconEnvolved={
                <IconButton aria-label="status">
                  <img src={integrationIcon} alt={'inputButton'} />
                </IconButton>
              }
            />
            <IconButton aria-label="status">
              <GetIconState
                state={support?.codigoestadodian}
                className={classes.iconToAssign}
                fechavalidacion={support?.fechavalidaciondian}
              />
            </IconButton>
          </div>
        </div>
      </div>

      {/* Modal previsualizar PDF */}
      <ArchiveModal
        loading={loadingGetFilesIntegration}
        open={openModalFile}
        pdfBase64={base64PDF}
        xmlBase64={base64XML}
        handleClose={handleCloseModalFile}
      />

      {/* Modal confirmación email */}
      <CustomModal
        onClose={handleCloseModalMail}
        open={openModalMail}
        component={
          <EmailConfirmForm
            emailUser={support?.emailUser}
            handleConfirmEmail={SendSupportToMail}
            loading={loadingSendDocumentIntegration}
          />
        }
        title={'Enviar documento soporte de nómina'}
        width={365}
        showCloseButton={false}
        disableBackdropClick={loadingSendDocumentIntegration}
      />

      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
    </Card>
  );
};

export default IntegrationCard;
