import {
  UPDATE_BRANCH_OFFICE,
  SAVE_BRANCH_OFFICE,
  LOAD_BRANCH_OFFICE,
  UPDATE_STATE_BRANCH_OFFICE,
  GET_BRANCH_OFFICES,
  GET_FILTER_BRANCH_OFFICES,
  LOADING_SAVE_BRANCH,
  LOADING_GET_BRANCH_OFFICES,
  INFO_SAVE_BRANCH_OFFICES,
  CLEAN_RESPONSES,
} from '../actions/types';

const initialState = {
  responseSaveBranchOffice: {},
  responseUpdateBranchOffice: {},
  updatePrincipalBranchResponse: {},
  editBranchStatus: false,
  stepBranchOfficeComplete: false,
  loadingSaveBranch: false,
  loadingGetBranchOffices: false,
  openModalItem: false,
  loadBranchOffice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BRANCH_OFFICES:
      return {
        ...state,
        filteredBranchOfficesList: action.payload,
        branchOfficesList: action.payload1,
      };
    case SAVE_BRANCH_OFFICE: {
      const newItem = action?.payload?.result ?? null;
      return {
        ...state,
        filteredBranchOfficesList: {
          data: [newItem, ...(state?.filteredBranchOfficesList?.data ?? [])],
        },
        responseSaveBranchOffice: action.payload,
      };
    }
    case UPDATE_BRANCH_OFFICE: {
      const updatedItem = action?.payload?.result ?? null;
      const newlist =
        state?.filteredBranchOfficesList?.data?.map((item) =>
          item.id === updatedItem?.id ? updatedItem : item,
        ) ?? [];
      return {
        ...state,
        filteredBranchOfficesList: { data: [...newlist] },
        responseUpdateBranchOffice: action.payload,
      };
    }
    case UPDATE_STATE_BRANCH_OFFICE: {
      if (state.filteredBranchOfficesList.data) {
        const id = action?.payload2 ?? 0;
        const idstate = action?.payload3 ?? 1;
        const newList = state.filteredBranchOfficesList.data.map((item) =>
          item.id === id ? { ...item, idstate: idstate } : item,
        );
        return {
          ...state,
          filteredBranchOfficesList: { data: [...newList] },
          responseUpdateStateBranchOffice: action.payload1,
        };
      }
      return {
        ...state,
      };
    }
    case LOAD_BRANCH_OFFICE:
      return {
        ...state,
        responseSaveBranchOffice: null,
        loadBranchOffice: action.payload?.data || null,
        editBranchStatus: action.payload?.data ? true : false,
      };

    case GET_FILTER_BRANCH_OFFICES:
      return {
        ...state,
        getFilterBranchOffices: action.payload,
      };
    case CLEAN_RESPONSES:
      return {
        ...state,
        updatePrincipalBranchResponse: null,
        responseUpdateStateBranchOffice: null,
        responseUpdateBranchOffice: null,
        responseSaveBranchOffice: null,
      };
    case LOADING_SAVE_BRANCH:
      return {
        ...state,
        loadingSaveBranch: action.payload,
      };
    case LOADING_GET_BRANCH_OFFICES:
      return {
        ...state,
        loadingGetBranchOffices: action.payload,
      };
    case INFO_SAVE_BRANCH_OFFICES:
      return {
        ...state,
        openModalItem: action.payload,
      };
    default:
      return state;
  }
}
