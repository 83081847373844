import React from 'react';

import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FlareComponent from 'flare-react';

import LoadingAnimation from '../../../images/animations/cargando1.flr';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
      height: '464px',
      width: '99%',
      borderRadius: '5px',
      maxWidth: '974px',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '340px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        maxWidth: '680px',
        height: '390px',
      },
    },
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  dialogAnimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 20,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  subTitleValue: {
    color: theme.palette.thirdColor,
  },
}));

export const UploadingDialog = ({
  contentDialog,
  openModalIformation,
  title = '',
  subtitle,
}) => {
  const classes = useStyles();

  const renderSubtitle = () => {
    if (subtitle) {
      return <div className={classes.subTitle}>{subtitle}</div>;
    } else {
      return (
        <div className={classes.subTitle}>
          Cargando la información de &nbsp;
          <span className={classes.subTitleValue}>{contentDialog}</span>
          &nbsp; conceptos, espera un momento por favor.
        </div>
      );
    }
  };

  return (
    <Dialog
      open={openModalIformation}
      onClose={false}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
        <span className={classes.title}>{title}</span>
      </DialogTitle>
      <DialogContent className={classes.dialogAnimation}>
        <DialogContentText>
          <div className={classes.loading}>
            {
              <FlareComponent
                width={104}
                height={104}
                animationName="music_walk"
                file={LoadingAnimation}
              />
            }
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="backgroundContent">
        <Grid container>
          <Grid item lg={12} xs={12}>
            {renderSubtitle()}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
