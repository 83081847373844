/* eslint-disable no-control-regex */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import aesjs from 'aes-js';
import moment from 'moment';
import 'moment/locale/es';
import {
  URL_SECURITY,
  API_ENDPOINT_ENTERPRISE,
  ROL_CNCOLAB,
  API_ENDPOINT_IBUHOO,
} from '../config/config';
import {
  SET_CURRENT_USER,
  GET_COMPANY,
  GET_BILLERS,
  GET_BRANCH_OFFICES,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { encryptionKey, encryptionIv } from './../config/configKeys';
import { ConfirmAlert } from '../helpers/alert.helpers';
import { TIME_REFRESH_TOKEN } from './../config/config';
import { showErrorMessage } from '../utils/showErrorService';

const urlEnterpriseEndPoint = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/empresa`;
const urlAuthentication = `${API_ENDPOINT_IBUHOO}/authentication`;

/**
 * AuthAction loginUser (set user credentials to server for login attempt )
 * @param {string} token
 */
const loginUser = async (token) => {
  try {
    const url = `${urlEnterpriseEndPoint}/TokenValidate`;
    const config = {
      headers: {
        Authorization: `${authHeader(token)}`,
      },
    };

    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    redirectToLogin();
  }
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @method GET
 * @param {history} history
 */
export const logoutUserAction = () => {
  ConfirmAlert('¿Está seguro de cerrar sesión?', logoutData);
};

/**
 * Si se confirma se redirige al login de seguridad de la aplicacion
 * @param {*} confirmed Estado de confirmacion
 */
const logoutData = (confirmed) => {
  if (confirmed) {
    redirectToLogin();
  }
};

/**
 * Lee token y lo valida
 * @param {*} queryString Información token
 */
export const checkTokenValidAction = (queryString, completeValid) => async (
  dispatch,
) => {
  try {
    const decodedToken = await decodeTokenData(queryString);
    const data = await loginUser(decodedToken);

    dispatchData(data, dispatch);
    setAuthToken(decodedToken);

    //Devuelve información de empresa
    completeValid(data.result.enterprise);
  } catch (err) {
    redirectToLogin();
  }
};

/**
 * Validar token
 *
 * @method
 * @param {queryString} queryString
 */
export const validateTokenAction = (queryString) => async (dispatch) => {
  try {
    // Lanza loading
    setAuthToken(false);
    const decodedToken = await decodeTokenData(queryString);
    const data = await loginUser(decodedToken);

    setAuthToken(decodedToken);
    return await dispatchData(data, dispatch);
  } catch (err) {
    redirectToLogin();
  } finally {
    // detener loading
  }
};

/**
 * Validar token
 *
 * @method
 * @param {queryString} queryString
 */
export const validateInfo = (queryString, history) => async (dispatch) => {
  try {
    // Lanza loading
    setAuthToken(false);
    const decodedToken = await decodeTokenData(queryString);
    const data = await loginUser(decodedToken);

    setAuthToken(decodedToken);
    return await data;
  } catch (err) {
    redirectToLogin();
  } finally {
    // detener loading
  }
};

/**
 * Carga datos de autenticacion en redux
 * @param {data} data
 */
const dispatchData = async (data, dispatch) => {
  try {
    const response = {
      companyId: data.result.enterprise.id,
      companyName: data.result.enterprise.razonsocial,
      userName: data.result.userLogged.nombres,
      userId: data.result.userLogged.iduser,
      userEmail: data.result.userLogged.email,
    };

    localStorage.setItem('dataCompany', JSON.stringify(response));

    dispatch({
      type: GET_COMPANY,
      payload: data.result.enterprise,
    });

    dispatch({
      type: GET_BRANCH_OFFICES,
      payload: data.result.enterprise.sucursales,
    });

    dispatch({
      type: GET_BILLERS,
      payload: data.result.enterprise.adusuarios,
      payload1: data.result.enterprise.adusuarios,
    });

    return true;
  } catch (err) {
    redirectToLogin();
  }
};

/**
 * decodificar token dentro de un querystring
 *
 * @method
 * @param {queryString} queryString
 */
async function decodeTokenData(queryString) {
  try {
    const paramsChar = decodeURIComponent(queryString);
    const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || '', 'base64').toString('hex'),
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    const cleanParamsObj = clearQueryVariable(decryptedText);
    const paramsObj = JSON.parse(cleanParamsObj);
    if (paramsObj.token !== undefined && paramsObj.token !== null) {
      return paramsObj.token;
    } else {
      redirectToLogin();
    }
  } catch (err) {
    redirectToLogin();
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    paramsChar = paramsChar
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsChar = paramsChar.replace(/[\u0000-\u0019]+/g, '');
    return paramsChar;
  } catch (err) {
    showErrorMessage(err, 'Error limpiando parametros:');
  }
}

/**
 * validar vigencia del token
 *
 * @method
 * @param {querytokenString} token
 */
export const validateTokenExpirationDate = async () => {
  try {
    const jwtToken = localStorage.getItem('jwtToken');
    const decodedToken = jwt_decode(jwtToken);
    const currentDate = moment().utc();
    const dateExpiresToken = moment.unix(decodedToken.exp).utc();

    if (moment(dateExpiresToken).isAfter(currentDate) === false) {
      redirectToLogin();
    } else if (
      moment(dateExpiresToken).diff(currentDate, 'seconds') < TIME_REFRESH_TOKEN
    ) {
      //Refresh Token
      let userName = decodedToken.unique_name;
      let refreshData = {
        Token: jwtToken,
        UserName: userName,
        Appname: 'colnomina',
      };

      try {
        await axios
          .post(urlAuthentication + '/api/Login/RefreshToken', refreshData)
          .then((response) => {
            setAuthToken(response.data.result.token);
          });
      } catch (err) {
        redirectToLogin();
        showErrorMessage(err, 'Error refrescando Token:');
      }
    }
  } catch (err) {
    redirectToLogin();
    showErrorMessage(
      err,
      'Error al validar la fecha de vencimiento del token:',
    );
  }
};

/**
 * Encriptar mensaje
 *
 * @method
 * @param {requestParameters} requestParameters
 */
export function encryptParameters(requestParameters) {
  try {
    const requestParametersJson = JSON.stringify(requestParameters);
    const requestTextBytesUtf8 = aesjs.utils.utf8.toBytes(
      requestParametersJson,
    );
    const requestPaddedData = aesjs.padding.pkcs7.pad(requestTextBytesUtf8);
    const aesCtr = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    const encryptedBytes = aesCtr.encrypt(requestPaddedData);
    const encryptedTextBase64 = Buffer.from(encryptedBytes).toString('base64');
    return encodeURIComponent(encryptedTextBase64);
  } catch (err) {
    showErrorMessage(err, 'Error encriptando parametros:');
  }
}

/**
 * Obtener el userName a partir del token
 * @param {token} token
 */
export function userName(token) {
  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    return `${decodedToken.unique_name}`;
  } else {
    return {};
  }
}

/**
 * Redireccionar a login
 *
 * @method
 * @param
 */
function redirectToLogin() {
  setAuthToken(false);
  window.location.href = URL_SECURITY;
}

/**
 * Procesar respuesta 401 o 403
 *
 * @method
 * @param {response} response
 */
export function handleResponse(response) {
  if (!!response) {
    if (response.status !== null && response.status !== undefined) {
      if ([401, 403].indexOf(response.status) !== -1) {
        redirectToLogin();
      }
    }
  }
}

/**
 * Obtener token en el header
 *
 * @method
 * @param {token} token
 */
export function authHeader(token) {
  const currentToken =
    token === null || token === undefined
      ? localStorage.getItem('jwtToken')
      : token;
  if (currentToken !== null || currentToken !== undefined) {
    return `Bearer ${currentToken}`;
  } else {
    return {};
  }
}

/**
 * Obtener id empresa en el header
 *
 * @method
 * @param {}
 */
export function enterpriseIdHeader() {
  const company = JSON.parse(localStorage.getItem('dataCompany'));
  const enterpriseId =
    company !== null || company !== undefined ? company.companyId : null;
  if (enterpriseId !== null || enterpriseId !== undefined) {
    return Number(enterpriseId);
  } else {
    return 0;
  }
}

/**
 * Obtener tipo de documento de la empresa.
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentType() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return null;

  const decodedToken = jwt_decode(token);
  return decodedToken ? `${decodedToken.customer_type}` : null;
}

/**
 * Obtener numero de documento de la empresa
 *
 * @method
 * @param {}
 */
export function enterpriseDocumentNumber() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return null;

  const decodedToken = jwt_decode(token);
  return decodedToken ? `${decodedToken.customer_value}` : null;
}

/**
 * Obtener rol del usuario logueado
 *
 * @method
 * @param {}
 */
export function getRoleOfLoggedUser() {
  const decodedToken = jwt_decode(localStorage.getItem('jwtToken'));
  if (!!decodedToken.COLNOMINA_ROLE && decodedToken.COLNOMINA_ROLE !== '') {
    return decodedToken.COLNOMINA_ROLE === ROL_CNCOLAB;
  } else {
    return true;
  }
}

/**
 * Obtener nombre de usuario que logueado
 *
 * @method
 * @param {}
 */
export function fullNameUser() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return '';

  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    let fullName = decodedToken.given_name.concat(
      ' ' + decodedToken.family_name,
    );
    return `${fullName}`;
  } else {
    return '';
  }
}

/**
 * Obtener el correo electronico de usuario logueado
 *
 * @method
 * @param {}
 */
export function emailUser() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return '';

  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    return `${decodedToken.email}`;
  } else {
    return '';
  }
}

/**
 * Obtener el el iduser del usuario logueado, para poder obtener el rol del usuario.
 *
 * @method
 * @param {}
 */
export function idUser() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return '';

  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    return `${decodedToken.COLNOMINA_ROLE}`;
  } else {
    return '';
  }
}

/**
 * Obtener la razonsocial de la empresa , seleccionada.
 *
 * @method
 * @param {}
 */
export function businessName() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return '';

  const decodedToken = jwt_decode(localStorage.getItem('jwtToken'));
  if (decodedToken) {
    return `${decodedToken.customer_name}`;
  } else {
    return '';
  }
}

/**
 * Obtener el nit d ela empresa seleccionada.
 *
 * @method
 * @param {}
 */
export function nitCompany() {
  const token = localStorage.getItem('jwtToken') || null;
  if (token === null) return '';

  const decodedToken = jwt_decode(token);
  if (decodedToken) {
    return `${decodedToken.customer_value}`;
  } else {
    return '';
  }
}

/**
 * Verifica si tiene privilegios de integracion
 * @returns
 */
export function isIntegrationEnterprise() {
  const decodedToken = jwt_decode(localStorage.getItem('jwtToken'));
  if (!!decodedToken) {
    const privileges = decodedToken?.COLNOMINA_PRIVILEGE ?? [];

    const isIntegration = privileges.find(
      (c) => c === 'COLNOMINA_INTEGRATION_VIEW',
    );
    if (!!isIntegration) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
