import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';

import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import SwitchForm from '../../../components/switch/switch.component.js';
import SelectForm from '../../../components/select/select.component';
import InputForm from '../../../components/input/input.component';
import TooltipMessage from '../../../components/tooltip/tootltip-message.component';
import ResponseModal from '../../../components/modal/responseModal.component';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import ToggleButtons from '../../../components/toggle/toggle.component';
import CustomProgress from '../../../components/Progress/progress.component';

import {
  updateBasicDataAction,
  proofTestIDAction,
  getCompanyAction,
} from '../../../actions/basicDataActions';

import {
  regexEmail,
  regexText,
  regexAlphaNumeric,
  regexPhone,
  regexOnlyNumbers,
  regexLettersAndNumbers,
} from '../../../helpers/customRegex.hepers';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';
import isEmpty from '../../../utils/isEmpty';

const styles = (theme) => ({
  buttonTestId: {
    background: '#7DD1F8',
    color: '#FFFF',
    fontSize: 20,
    fontWeight: 500,
    textTransform: 'none',
    width: '100%',
    marginTop: 15,
  },
  italicTxt: {
    marginTop: 15,
    marginBottom: 15,
    textAling: 'center',
  },
});

export class BasicData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      form: {
        razonsocial: '',
        diantipodocumentoidentidadid: '',
        documento: '',
        documentodv: '',
        diantipopersonaid: '1',
        enviarnomina: false,
        testidnomina: '',
        testidexitosonomina: true,
      },
      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: 0,
        showProgress: 0,
        textProgress: '',
        valueProgressBar: 0,
      },
      saveBasicDataResponse: {},
      requestCompany: {},
      options: [],
      optionsRegimen: [],
      loadingSaveBasicData: false,
      loadingGetBasicData: false,
      testidexitoso: false,
      menuMyAccount: {},
    };
    this.validator = new SimpleReactValidator(reactValidatorOptions);
  }

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};

    if (
      !!nextProps.configReducer.components &&
      nextProps.configReducer.components !== state.components
    )
      update.components = nextProps.configReducer.components;

    if (
      !!nextProps.configReducer.getDocumentTypesResponse &&
      nextProps.configReducer.getDocumentTypesResponse !== state.options
    )
      update.options = nextProps.configReducer.getDocumentTypesResponse;

    if (
      !!nextProps.configReducer.getRegimeTypesResponse &&
      nextProps.configReducer.getRegimeTypesResponse !== state.optionsRegimen
    )
      update.optionsRegimen = nextProps.configReducer.getRegimeTypesResponse;

    if (
      !!nextProps.basicDataReducer.requestSaveBasicData &&
      nextProps.basicDataReducer.requestSaveBasicData !==
        state.saveBasicDataResponse
    )
      update.saveBasicDataResponse =
        nextProps.basicDataReducer.requestSaveBasicData;

    if (
      !!nextProps.basicDataReducer.requestCompany &&
      nextProps.basicDataReducer.requestCompany !== state.requestCompany
    ) {
      if (Object.keys(nextProps.basicDataReducer.requestCompany).length) {
        update.requestCompany = nextProps.basicDataReducer.requestCompany;
        let data = nextProps.basicDataReducer.requestCompany;
        update.form = {
          diantipodocumentoidentidadid: data.diantipodocumentoidentidadid.toString(),
          documento: data.documento,
          documentodv: data.documentodv,
          razonsocial: data.razonsocial,
          diantipopersonaid: data.diantipopersonaid === 1 ? '1' : '2',
          enviarnomina: data.enviarnomina || false,
          testidnomina: data.testidnomina || '',
          testidexitosonomina: data.testidexitosonomina,
        };
      }
    }

    if (
      !isEmpty(nextProps.basicDataReducer.loadingSaveBasicData) &&
      nextProps.basicDataReducer.loadingSaveBasicData !==
        state.loadingSaveBasicData
    )
      update.loadingSaveBasicData =
        nextProps.basicDataReducer.loadingSaveBasicData;

    if (
      !isEmpty(nextProps.basicDataReducer.loadingGetBasicData) &&
      nextProps.basicDataReducer.loadingGetBasicData !==
        state.loadingGetBasicData
    )
      update.loadingGetBasicData =
        nextProps.basicDataReducer.loadingGetBasicData;

    if (
      nextProps?.basicDataReducer?.requestCompany?.testidexitoso !==
      state.requestCompany.testidexitoso
    ) {
      update.testidexitoso =
        nextProps?.basicDataReducer?.requestCompany?.testidexitoso;
    }

    if (
      !!nextProps.menuReducer.menuMyAccount &&
      nextProps.menuReducer.menuMyAccount !== state.menuMyAccount
    ) {
      update.menuMyAccount = nextProps.menuReducer.menuMyAccount;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.saveBasicDataResponse !== this.state.saveBasicDataResponse) {
      if (
        this.state.saveBasicDataResponse.statusCode === '250' ||
        this.state.saveBasicDataResponse.statusCode === '201'
      ) {
        this.setState({
          responseModal: {
            type: 'success',
            title: this.state.menuMyAccount.titlePopCreationBasicData,
            subtitle: this.state.menuMyAccount.messagePopCreationBasicData,
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.menuMyAccount.messageBodyPopBasicData,
                }}
              ></div>
            ),
            modalImage: 'successBasicData',
            open: true,
            closeElement: this.state.menuMyAccount.messageSkipBasicData,
            onCloseElement: 1,
            showProgress: 1,
            textProgress: this.state.menuMyAccount.messageProgressBasicData,
            valueProgressBar: 25,
          },
        });
        this.props.getCompanyAction();
      } else if (this.state.saveBasicDataResponse.statusCode === '252') {
        this.setState({
          responseModal: {
            type: 'warning',
            title: this.state.menuMyAccount.titlePopTestIdInvalid,
            subtitle: this.state.menuMyAccount.messagePopTestIdInvalid,
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.menuMyAccount.messageBodyPopTestIdInvalid,
                }}
              ></div>
            ),
            modalImage: 'warning',
            open: true,
            closeElement: this.state.menuMyAccount.messageSkip,
            onCloseElement: 0,
          },
        });
        this.props.getCompanyAction();
      } else if (this.state.saveBasicDataResponse.statusCode === '253') {
        this.setState({
          responseModal: {
            type: 'warning',
            title: this.state.menuMyAccount.titlePopNotRegister,
            subtitle: this.state.menuMyAccount.messagePopNotRegister,
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.menuMyAccount.messageBodyPopNotRegister,
                }}
              ></div>
            ),
            modalImage: 'warning',
            open: true,
            closeElement: this.state.menuMyAccount.messageSkipNotRegister,
            onCloseElement: 0,
          },
        });
        this.props.getCompanyAction();
      } else if (this.state.saveBasicDataResponse.statusCode === '251') {
        this.setState({
          responseModal: {
            type: 'warning',
            title: 'Upsss...!!',
            subtitle: this.state.menuMyAccount.messagePopNotRegister,
            body: (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.saveBasicDataResponse?.statusMessage ?? '',
                }}
              ></div>
            ),
            modalImage: 'warning',
            open: true,
            closeElement: this.state.menuMyAccount.messageSkipNotRegister,
            onCloseElement: 0,
          },
        });
        this.props.getCompanyAction();
      }

      if (
        prevState.form.diantipopersonaid === '1' &&
        this.state.form.diantipopersonaid === '2'
      ) {
        this.setState({
          form: {
            ...this.state.form,
            razonsocial: this.state.form.razonsocial.replace(regexText, ''),
          },
        });
      }
    }
  }

  /**
   * Sincroniza nuevo valor de input con state
   * @param {*} e Evento
   */
  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  };

  /**
   * Sincroniza nuevo valor de checkbox con state
   * @param {*} e
   */
  syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: checked,
      },
    }));
  };

  /**
   * Verifica si caracteres invalidos y da formato al campo TestID
   * @param {object} e Evento
   */
  syncChangesTestId(e) {
    e.preventDefault();
    const name = e.target.name;
    let value = e.target.value;

    let guid = '';
    let str = value;
    const regex = /[^a-f0-9\s]+/g;
    str = str.replace(regex, '');
    str = str.trim();

    let one = str.slice(0, 8);
    let two = str.slice(8, 12);
    let three = str.slice(12, 16);
    let four = str.slice(16, 20);
    let five = str.slice(20, 32);

    two = two.length > 0 ? '-' + two : '';
    three = three.length > 0 ? '-' + three : '';
    four = four.length > 0 ? '-' + four : '';
    five = five.length > 0 ? '-' + five : '';
    guid = one + two + three + four + five;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: guid,
      },
    }));
  }

  /**
   * Sincroniza nuevo valor toogle con state
   * @param {*} event Evento
   * @param {*} newAlignment Nuevo valor
   */
  onChangeTooggle = (event, newAlignment) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        diantipopersonaid: !!newAlignment ? newAlignment : '1',
      },
    }));
  };

  /**
   * Limpia caracteres invalidos para correo electronico
   * @param {*} e Evento
   */
  validateEmail(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexEmail, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para telefono
   * @param {*} e Evento
   */
  validatePhone(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexPhone, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores solo texto
   * @param {*} e Evento
   */
  validateText(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexText, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores alfanumericos
   * @param {*} e Evento
   */
  validateAlphaNumeric(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexAlphaNumeric, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos para valores numericos
   * @param {*} e Evento
   */
  validateOnlyNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexOnlyNumbers, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  /**
   * Limpia caracteres invalidos, solo letras y numeros
   * @param {*} e Evento
   */
  validateLettersAndNumbers(e) {
    const id = !!e.target.id ? e.target.id : e.target.name;
    let value = e.target.value;
    value = value.replace(regexLettersAndNumbers, '');
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  }

  handleResponseModal = () => {
    this.setState({
      responseModal: {
        type: '',
        title: '',
        subtitle: '',
        body: '',
        modalImage: '',
        open: false,
        closeElement: '',
        onCloseElement: '',
      },
    });
  };

  /**
   * Captura y actualiza información de empresa.
   */
  saveBasicData = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      const {
        razonsocial,
        diantipodocumentoidentidadid,
        documento,
        documentodv,
        diantipopersonaid,
        testidnomina,
        enviarnomina,
      } = this.state.form;

      let data = {
        razonsocial: razonsocial,
        diantipodocumentoidentidadid: diantipodocumentoidentidadid,
        documento: documento,
        documentodv: documentodv,
        diantipopersonaid: diantipopersonaid === '1' ? 1 : 2,
        testidnomina: testidnomina,
        enviarnomina: enviarnomina,
      };
      this.props.updateBasicDataAction(data);
      if (!this.state.form.testidexitosonomina) {
        this.props.proofTestIDAction(data.documento, data.testidnomina);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const classes = this.props.classes;

    return (
      <Fragment>
        <br></br>
        {this.state.loadingGetBasicData === true ? (
          <Grid container alignItems="center" justify="center">
            <CustomProgress />
          </Grid>
        ) : (
          <form
            id="basicDataForm"
            onSubmit={(e) => this.saveBasicData(e)}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <SelectForm
                  label={'Tipo de documento *'}
                  name="diantipodocumentoidentidadid"
                  value={this.state.form.diantipodocumentoidentidadid}
                  options={this.state.options}
                  validator={this.validator}
                  validateOptions={'required'}
                  onChange={(e) => this.syncChanges(e)}
                  disabled={true}
                />
              </Grid>

              {/* Numero de documento */}
              <Grid item lg={2} xs={12}>
                <InputForm
                  name="documento"
                  label={'Número de documento *'}
                  maxLength={20}
                  value={this.state.form.documento}
                  onChange={(e) => {
                    this.syncChanges(e);
                    this.state.form.diantipodocumentoidentidadid === '7'
                      ? this.validateLettersAndNumbers(e)
                      : this.validateOnlyNumbers(e);
                  }}
                  validator={this.validator}
                  validateOptions={'required|documento'}
                  disabled={true}
                />
              </Grid>

              {/* Numero de verificacion */}
              {this.state.form.diantipodocumentoidentidadid === '6' ? (
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <InputForm
                    name="documentodv"
                    label={'Número de verificación'}
                    maxLength={20}
                    value={this.state.form.documentodv}
                    disabled
                    onChange={(e) => {
                      this.syncChanges(e);
                      this.validateOnlyNumbers(e);
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={2}>
              {this.state.form.diantipodocumentoidentidadid === '6' ? (
                <Grid item lg={4} xs={12}>
                  <ToggleButtons
                    name={'diantipopersonaid'}
                    label={'Tipo de persona'}
                    value={this.state.form.diantipopersonaid}
                    onChange={this.onChangeTooggle}
                    titleOne={'Jurídica '}
                    titleTwo={'Natural'}
                    txtTooltip={
                      <TooltipMessage
                        title={this.state.menuMyAccount.titleTypePerson}
                        message={this.state.menuMyAccount.messageTypePerson}
                        botton={this.state.menuMyAccount.bottonTypePerson}
                        href={this.state.menuMyAccount.urlTypePerson}
                      />
                    }
                  />
                </Grid>
              ) : (
                ''
              )}

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InputForm
                  name={'razonsocial'}
                  label={
                    this.state.form.diantipopersonaid === '1'
                      ? 'Razón Social *'
                      : 'Nombre(s) y Apellido(s) *'
                  }
                  maxLength={400} //Debe ser 400
                  value={this.state.form.razonsocial}
                  onChange={
                    this.state.form.diantipopersonaid === '1'
                      ? (e) => {
                          this.syncChanges(e);
                        }
                      : (e) => {
                          this.syncChanges(e);
                          this.validateText(e);
                        }
                  }
                  nameValidator={'nombreEmpresa'}
                  validator={this.validator}
                  validateOptions={'required|min:3|max:400'}
                />
              </Grid>
            </Grid>

            <div style={{ display: 'block' }}>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                  <InputForm
                    name="testidnomina"
                    label={'Test set ID'}
                    maxLength={36}
                    value={this.state.form.testidnomina}
                    validator={this.validator}
                    validateOptions="required|uuid"
                    onChange={(e) => this.syncChangesTestId(e)}
                    placeholder={'00000000-0000-0000-0000-000000000000'}
                    disabled={this.state.form.testidexitosonomina}
                    tooltip={
                      <TooltipMessage
                        title={this.state.menuMyAccount.titleTestSetId}
                        message={this.state.menuMyAccount.messageTestSetId}
                        botton={this.state.menuMyAccount.bottonTestSetId}
                        href={this.state.menuMyAccount.urlTestSetId}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <SwitchForm
                      name="enviarnomina"
                      txtTooltip={
                        <TooltipMessage
                          title={'¿Que es lo que se va a enviar?'}
                          message={
                            'Seleccione para enviar una copia de nómina a cada empleado'
                          }
                          botton={null}
                          href={null}
                        />
                      }
                      checked={this.state.form.enviarnomina}
                      titleOn="Quiero enviar el soporte de nómina a los empleados"
                      withoutMargin={true}
                      onChange={this.syncCheckChange}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <Button
                      className={classes.buttonTestId}
                      variant="contained"
                      href="https://catalogo-vpfe-hab.dian.gov.co/User/Login"
                      target="_blank"
                    >
                      {'Obtener mi Test Set ID'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={4} xs={12}>
                  <Typography
                    className={classes.italicTxt}
                    style={{ color: '#FFFFFF', textAlign: 'center' }}
                  >
                    ¿Tienes dudas de cómo realizar el proceso?
                    <br></br>
                    Apréndelo en el siguiente video
                  </Typography>

                  <div className={classes.containerVideo}>
                    <iframe
                      src="https://www.youtube.com/embed/_7q8762SdXU"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      title="video"
                      style={{
                        width: '100%',
                        height: 200,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <br />
                <Grid item xs={8} lg={8}>
                  <Divider light />
                  <hr></hr>
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={5} justify="center" alignItems="center">
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ButtonPrimary
                  text={this.state.menuMyAccount.buttonBasicDataReady}
                  loading={this.state.loadingSaveBasicData}
                  type={'submit'}
                />
              </Grid>
            </Grid>
          </form>
        )}

        <ResponseModal
          modalType={this.state.responseModal.modalType}
          title={this.state.responseModal.title}
          subtitle={this.state.responseModal.subtitle}
          body={this.state.responseModal.body}
          modalImage={this.state.responseModal.modalImage}
          open={this.state.responseModal.open}
          closeElement={this.state.responseModal.closeElement}
          onCloseElement={() => this.handleResponseModal()}
          showProgress={this.state.responseModal.showProgress}
          textProgress={this.state.responseModal.textProgress}
          valueProgressBar={this.state.responseModal.valueProgressBar}
        />
      </Fragment>
    );
  }
}

BasicData.propTypes = {
  getCompanyAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
  generalReducer: state.generalReducer,
  configReducer: state.configReducer,
  menuReducer: state.menuReducer,
});

export default connect(mapStateToProps, {
  updateBasicDataAction,
  proofTestIDAction,
  getCompanyAction,
})(withStyles(styles)(BasicData));
