import React from 'react';

export const TooltipVerticalBar = ({ active, payload, activeSection }) => {
  if (active && payload && payload.length && activeSection) {
    const valueEmpleados = payload.find(
      (item) => item.dataKey === activeSection,
    );
    return (
      <div className="container-custom" style={{ height: '85px' }}>
        <p className="custom-name">
          {activeSection}: {valueEmpleados?.value}{' '}
        </p>
        {payload
          .filter((item) => item.dataKey !== activeSection)
          .map((item) => (
            <p key={item.dataKey} className="custom-name-sub">
              {item.dataKey}: {item.value}{' '}
            </p>
          ))}
      </div>
    );
  }
  return null;
};
