import { axiosApiInstance } from '../config/axios-instance';
import {
  GET_COUNTER_DATA,
  GET_INFO_CHARTS,
  GET_LICENCE_DATA,
  LOADING_COUNTER_DATA,
  LOADING_INFOCHARTS_DATA,
  LOADING_LICENCE_DATA,
  types,
} from './types';
import {
  API_ENDPOINT_DISCHARGE,
  API_ENDPOINT_ENTERPRISE,
  API_ENDPOINT_LICENCE,
  API_ENDPOINT_PAYROLL,
  defaultHeaders,
} from '../config/config';
import {
  enterpriseDocumentNumber,
  enterpriseDocumentType,
  enterpriseIdHeader,
} from './authActions';
import { showErrorMessage } from '../utils/showErrorService';
import { generateModelDashboard } from '../helpers/generateModelDashboard';

const urlConfigurationDischarge = `${API_ENDPOINT_DISCHARGE}/license/api`;
const urlReferenciaLicencia = `${API_ENDPOINT_ENTERPRISE}/enterprise/api`;
const urlLicence = `${API_ENDPOINT_LICENCE}/license/api`;

/**
 * Consulta información de licencia para usuario logueado
 * @returns
 */
export const getLicenceDataAction = () => async (dispatch) => {
  try {
    changeStatusLoading(dispatch, true);
    const url = `${urlConfigurationDischarge}${'/license/nominaCustomers'}`;
    let data = {
      documentType: `${enterpriseDocumentType()}`,
      documentNumber: `${enterpriseDocumentNumber()}`,
    };

    const resp = await axiosApiInstance.post(url, data, defaultHeaders());

    let licence = null;
    if (resp?.data?.result?.records?.length > 0) {
      licence = resp?.data?.result?.records[0];
    }

    dispatch({
      type: GET_LICENCE_DATA,
      payload: {
        ...licence,
      },
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando información de licencia.');
  } finally {
    changeStatusLoading(dispatch, false);
  }
};

const changeStatusLoading = (dispatch, status) => {
  dispatch({
    type: LOADING_LICENCE_DATA,
    payload: status,
  });
};

/**
 * Consulta información de counters
 * @returns
 */
export const getCountersDataAction = () => async (dispatch) => {
  try {
    changeLoadingCounter(dispatch, true);
    const url = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/GetCounter`;
    const data = {
      Documento: `${enterpriseDocumentNumber()}`,
    };
    const resp = await axiosApiInstance.post(url, data, defaultHeaders());

    dispatch({
      type: GET_COUNTER_DATA,
      payload: resp?.data ?? null,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Error consultando información de counters licencia.',
    );
  } finally {
    changeLoadingCounter(dispatch, false);
  }
};

/**
 * Consulta información de Gráficas Dashboard
 * @returns
 */
export const getInfoChartsAction = () => async (dispatch) => {
  try {
    changeLoadingInfoCharts(dispatch, true);
    const date = new Date();
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEmpresaId: `${enterpriseIdHeader()}`,
      pMes: `${date.getMonth() + 1}`,
      pAnio: `${date.getFullYear()}`,
    });

    const url = `${API_ENDPOINT_PAYROLL}/documentnomina/api/DashBoard/ConsultarInformacionDashBoard`;

    const { data } = await axiosApiInstance.get(url, config);

    const dataModel = generateModelDashboard(data.result);

    dispatch({
      type: GET_INFO_CHARTS,
      payload: dataModel,
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando información Gráficas Dashboard.');
  } finally {
    changeLoadingInfoCharts(dispatch, false);
  }
};

/**
 * Consulta información de la referencia de licencia de empleado
 * @returns
 */
export const getLicenseReference = () => async (dispatch) => {
  try {
    changeLoadingLicenseReference(dispatch, true);
    const url = `${urlReferenciaLicencia}${'/Empresa/GetReferenciaLicencia'}`;
    const enterprise = enterpriseIdHeader();
    const config = defaultHeaders();

    Object.assign(config.headers, {
      pEnterpriseId: enterprise,
    });

    const response = await axiosApiInstance.get(url, config);

    dispatch({
      type: types.GET_LICENSE_REFERENCE,
      payload: response.data.result ?? null,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Error consultando información de referencia licencia.',
    );
  } finally {
    changeLoadingLicenseReference(dispatch, false);
  }
};

/**
 *
 * Setea la fecha de la vigencia de la licencia de empleados
 * @param {*} month
 * @param {*} year
 * @returns
 */
export const setLicenseValidity = (month, year) => async (dispatch) => {
  try {
    changeLoadingLicenseValidity(dispatch, true);
    const url = `${urlLicence}${'/license/set-license_validity'}`;
    const config = defaultHeaders();

    const data = {
      DocumentType: enterpriseDocumentType(),
      DocumentNumber: enterpriseDocumentNumber(),
      Year: Number(year),
      Month: Number(month),
    };

    const response = await axiosApiInstance.post(url, data, config);

    dispatch({
      type: types.SET_LICENSE_VALIDATION,
      payload: response.data.result ?? null,
    });
  } catch (err) {
    if (err.response.data.statusCode === 422) {
      changeLoadingLicenseValidity(dispatch, true);
      dispatch({
        type: types.SET_LICENSE_VALIDATION,
        payload: err.response.data.result ?? null,
      });
      showErrorMessage(
        err,
        'Acción no permitida para actualización de vigencia, la licencia ya fue configurada anteriormente.',
      );
    } else {
      showErrorMessage(
        err,
        'Error realizando la actualización de la vigencia de licencia.',
      );
    }
  } finally {
    changeLoadingLicenseValidity(dispatch, false);
  }
};

/**
 * Actualiza la nueva fecha y si se asigno la licencia de empleados a una referencia
 */
export const updateLicenceReference = () => async (dispatch, getState) => {
  try {
    changeLoadingUpdateLicense(dispatch, true);
    const url = `${urlReferenciaLicencia}${'/Empresa/UpdateReferenciaLicencia'}`;
    const { licenseReference, licenseValidity } = getState().dashboardReducer;

    const body = {
      Reference: licenseReference.reference,
      FechaLicEmp: licenseValidity?.newEffectiveLicenseDate,
      AsignoFechaLicEmp: !licenseReference.asignoFechaLicEmp,
    };

    const { data } = await axiosApiInstance.put(url, body, defaultHeaders());

    dispatch({
      type: types.UPDATE_LICENSE_EMPLOYEE,
      payload: data.result ? true : false,
    });
  } catch (err) {
    showErrorMessage(err, 'Error actualizando la licencia de empleados.');
  } finally {
    changeLoadingUpdateLicense(dispatch, false);
  }
};

export const clearLicenseReference = () => async (dispatch) => {
  dispatch({
    type: types.CLEAR_LICENSE_REFERENCE,
    payload: null,
  });
};

const changeLoadingCounter = (dispatch, status) => {
  dispatch({
    type: LOADING_COUNTER_DATA,
    payload: status,
  });
};
const changeLoadingInfoCharts = (dispatch, status) => {
  dispatch({
    type: LOADING_INFOCHARTS_DATA,
    payload: status,
  });
};

const changeLoadingLicenseReference = (dispatch, status) => {
  dispatch({
    type: types.LOADING_LICENSE_REFERENCE,
    payload: status,
  });
};

const changeLoadingLicenseValidity = (dispatch, status) => {
  dispatch({
    type: types.LOADING_LICENSE_VALIDITY,
    payload: status,
  });
};

const changeLoadingUpdateLicense = (dispatch, status) => {
  dispatch({
    type: types.LOADING_LICENSE_UPDATE,
    payload: status,
  });
};
