import {
  SAVE_BASIC_DATA,
  GET_TYPE_DOCUMENT,
  GET_REGIMEN,
  GET_COMPANY,
  STATUS_STEP_BASIC_DATA,
  LOADING_SAVE_BASIC_DATA,
  LOADING_GET_BASIC_DATA,
  LOADING_SAVE_TEMPLATE,
  UPDATE_INITIAL_DATA,
  UPDATE_DIAN_DATA,
  LOADING_SAVE_DIAN_DATA,
  LOADING_CHECK_CERTIFICATE,
  types,
} from '../actions/types';

const initialState = {
  stepBasicDataComplete: false,
  loadingSaveBasicData: false,
  loadingGetBasicData: false,
  loadingSaveTemplate: false,
  updateInitial: false,
  updateDian: false,
  loadingSaveDianData: false,
  requestSaveBasicData: null,
  loadingCheckCertificate: false,
  enterpriseCertificate: {
    isCertificate: false,
    data: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_BASIC_DATA:
      return {
        ...state,
        requestSaveBasicData: action.payload,
      };
    case GET_TYPE_DOCUMENT:
      return {
        ...state,
        requestTypeDocument: action.payload,
      };
    case GET_REGIMEN:
      return {
        ...state,
        requestRegimen: action.payload,
      };
    case GET_COMPANY:
      return {
        ...state,
        requestCompany: action.payload,
      };
    case STATUS_STEP_BASIC_DATA:
      return {
        ...state,
        stepBasicDataComplete: action.payload,
      };
    case LOADING_SAVE_BASIC_DATA:
      return {
        ...state,
        loadingSaveBasicData: action.payload,
      };
    case LOADING_GET_BASIC_DATA:
      return {
        ...state,
        loadingGetBasicData: action.payload,
      };
    case LOADING_SAVE_TEMPLATE:
      return {
        ...state,
        loadingSaveTemplate: action.payload,
      };
    case UPDATE_INITIAL_DATA:
      return {
        ...state,
        updateInitial: action.payload,
      };
    case UPDATE_DIAN_DATA:
      return {
        ...state,
        updateDian: action.payload,
      };
    case LOADING_SAVE_DIAN_DATA:
      return {
        ...state,
        loadingSaveDianData: action.payload,
      };
    case LOADING_CHECK_CERTIFICATE:
      return {
        ...state,
        loadingCheckCertificate: action.payload,
      };
    case types.GET_NOMINA_CERTIFICATE:
      return {
        ...state,
        enterpriseCertificate: {
          isCertificate: !!action.payload,
          data: action.payload,
        },
      };
    default:
      return state;
  }
}
