import React, { useEffect } from 'react';
import { useEmployees } from '../../../../hooks/useEmployees';

// Material
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import '../../../../styles/invoice.css';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#393939',
    padding: '1%',
    position: 'sticky',
    bottom: '0',
    boxShadow: '0px 4px 7px 2px rgb(0 0 0 / 94%)',
    zIndex: '2',
  },
  inputButton: {
    width: '100%',
    border: '2px solid rgba(255,255,255,0.3)',
    borderRadius: '5px',
    minmax: '50px',
  },
  textButtons: {
    color: '#ffffff',
    fontFamily: 'Muli',
    fontSize: '10px',
    letterSpacing: '0',
    textAlign: 'center',
  },
  totalPayroll: {
    color: '#F5D13D',
    '& img': {
      width: '25px',
    },
    '& h2': {
      fontWeight: '600',
      fontSize: '26px',
      margin: '0 auto',
    },
    '& i': {
      fontSize: '12px',
      margin: '0 auto',
    },
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: 'auto',
    height: '50px',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
  },
  return: {
    color: '#FFF',
    fontSize: '14px',
    '& * ': {
      color: '#FFF',
    },
  },
});

const ActionsBottom = () => {
  // style classes
  const classes = useStyles();

  // custom hook
  const { changeOpenLoadMassiveEmployee } = useEmployees();

  useEffect(() => {
    console.log('ActionsBottom ');
  });

  return (
    <>
      <div className={classes.container}>
        <Grid container direction="row">
          <Grid item lg={2} md sm>
            <span
              className={classes.return}
              onClick={() => changeOpenLoadMassiveEmployee(false)}
            >
              <IconButton aria-label="Volver">
                <ArrowBackIosIcon />
              </IconButton>
              Volver
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ActionsBottom;
