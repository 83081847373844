import { endOfMonth, format, parse } from 'date-fns';

/**
 * * Calcula el último día del mes y formatea la fecha en el formato deseado.
 *
 * @param {string} period - El período en formato 'yyyy/MM'.
 * @returns {Object} Un objeto con la información del mes, año y el último día del mes en el formato especificado.
 */
export const calculateSelectedPeriod = (period) => {
  const [year, month] = period.split('/').map(Number);
  const lastDayOfMonth = endOfMonth(
    parse(`${year}-${month}`, 'yyyy-MM', new Date()),
  );
  const formattedLastDay = format(lastDayOfMonth, "yyyy-MM-dd'T'00:00:00");
  return { month, year, formattedLastDay };
};
