import React from 'react';
import '../styles/pai-chart-custom.css';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { payload: data, value, name } = payload[0].payload;
    return (
      <div className="container-custom">
        <p className="custom-name">{name}</p>
        <span className="custom-description">{`${value}%`}</span>
        <span className="custom-description">
          {data.price.toLocaleString('en-US', {
            useGrouping: true,
            groupingSeparator: "'",
            decimalSeparator: '.',
          })}
        </span>
      </div>
    );
  }

  return null;
};
