/*
 * El objeto `commonRestrictions` define un conjunto de restricciones para diferentes conceptos. Cada concepto está representado por una clave  y tiene su propio conjunto de restricciones definidas como pares clave-valor.
 */
const commonRestrictions = {
  one: {
    cantidad: { min: 0.1, max: 730000.0 },
    valor: { min: 1, max: 99999999.99 },
  },
  two: {
    cantidad: { min: 1, max: 25000 },
    valor: { min: 1, max: 99999999.99 },
  },
  three: { cantidad: { min: 1, max: 99 } },
  four: {
    cantidad: { min: 1, max: 99 },
    valor: { min: 1, max: 9999999999999.99 },
  },
  five: { cantidad: { min: 1, max: 99 }, valor: { min: 1, max: 99999999.99 } },
  six: {
    valor: { min: 1, max: 99999999.99 },
    descripcion: { min: 1, max: 300 },
  },
  seven: {
    valor: { min: 1, max: 99999999.99 },
    porcentaje: { min: 0.1, max: 100.0 },
  },
  eight: { valor: { min: 1, max: 99999999.99 } },
};

/*
 * El objeto `ranges` define un conjunto de rangos para diferentes conceptos. Cada concepto está representado por una clave y tiene su propia matriz de valores como rango. Estos rangos se utilizan para asignar los valores a sus restricciones correspondientes en el objeto "commonRestrictions".
 */
const ranges = {
  one: [25, 26, 27, 28, 29, 30, 31],
  two: [34],
  three: [41, 46],
  four: [21],
  five: [32, 33, 38, 39, 40, 64, 65],
  six: [8, 47],
  seven: [1, 2, 3, 4, 5, 6, 7, 37],
  eight: [
    9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 35, 36, 42, 43,
    44, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
  ],
};

/*
 * El código crea un nuevo objeto llamado `restrictionsValueConcepts` y lo completa con pares clave-valor.
 */
const restrictionsValueConcepts = {};

Object.keys(ranges).forEach((restriction) => {
  ranges[restriction].forEach((value) => {
    restrictionsValueConcepts[value] = commonRestrictions[restriction];
  });
});

export { restrictionsValueConcepts };
