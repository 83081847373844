/**
 * Genera request registro nota de ajuste
 * @param {*} incomes
 * @param {*} expenses
 */
export const generateRequestPayrollAdjustment = (
  soporteid,
  incomes = [],
  expenses = [],
  observations,
  valornomina,
  eliminar = false,
  nominaid,
  typeLicense,
) => {
  var ingresos = incomes.map((item) => ({
    codigo: item.conceptoid,
    valor: item.valor,
    porcentaje: item.porcentaje,
    descripcion: item.concepto,
    Cantidad: item.cantidad,
    Ingreso: true,
  }));

  var deducciones = expenses.map((item) => ({
    codigo: item.conceptoid,
    valor: item.valor,
    porcentaje: item.porcentaje,
    descripcion: item.concepto,
    Cantidad: item.cantidad,
    Ingreso: false,
  }));

  const data = {
    NominaId: nominaid,
    DetalleNominaId: soporteid,
    TipoNota: !eliminar ? 103 : 104,
    ObservacionAjuste: observations,
    Conceptos: [...ingresos, ...deducciones],
    ValorNomina: valornomina,
  };

  return data;
};
