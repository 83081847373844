import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_ENTERPRISE, defaultHeaders } from '../config/config';

const urlBranchOffice = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/sucursal`;
const urlOfficeNomina = `${API_ENDPOINT_ENTERPRISE}/enterprise/api/SucursalNomina`;

export default {
  /**
   * Actualiza sucursal principal  de una empresa,
   * marca como principal la nueva sucursal y desmarca como princial
   * la anterior
   * @param {obj} idOldPrincipal Identificador actual sucursal principal
   * @param {obj} idNewPrincipal  Identificador nueva sucursal principal
   */
  UpdateMainBranch: async (idOldPrincipal, idNewPrincipal) => {
    let config = defaultHeaders();

    Object.assign(config.headers, {
      pIdOldPrincipal: idOldPrincipal,
      pIdNewPrincipal: idNewPrincipal,
    });

    const response = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdatePrincipal`,
      null,
      config,
    );
    return response;
  },
  /**
   * Actualiza el estado de la sucursal
   * @param {obj} id Identificador sucursal
   * @param {obj} idState  Estado
   */
  UpdateStateBranch: async (id, idState) => {
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pId: id,
      pIdState: idState,
    });
    var response = await axiosApiInstance.put(
      `${urlOfficeNomina}/UpdateState`,
      null,
      config,
    );
    return response;
  },
  /**
   * Actualiza la data de la sucursal
   * @param {obj} data data sucursal a actualizar
   */
  UpdateDataBranch: async ({ data }) => {
    const company = JSON.parse(localStorage.getItem('dataCompany'));
    const enterpriseId =
      company !== null || company !== undefined ? company.companyId : null;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: enterpriseId,
    });

    var response = await axiosApiInstance.put(
      `${urlBranchOffice}/UpdateOfficesNomina`,
      data,
      config,
    );
    return response;
  },

  /**
   * Actualiza la data de la sucursal
   * @param {obj} data data sucursal a actualizar
   */
  SaveDataBranch: async ({ data }) => {
    const company = JSON.parse(localStorage.getItem('dataCompany'));
    const enterpriseId = company?.companyId ?? null;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      empresaId: enterpriseId,
    });

    const response = await axiosApiInstance.put(
      `${urlBranchOffice}/CreateOfficeNomina`,
      data,
      config,
    );
    return response;
  },

  /**
   * Consulta listado sucursales asociadas a empresa
   */
  GetByEmpresaId: async () => {
    const company = JSON.parse(localStorage.getItem('dataCompany'));
    const enterpriseId =
      company !== null || company !== undefined ? company.companyId : null;

    let config = defaultHeaders();
    Object.assign(config.headers, {
      pEnterpriseId: enterpriseId,
    });

    let response = await axiosApiInstance.get(
      `${urlOfficeNomina}/GetByEmpresaId`,
      config,
    );
    return response;
  },
};
