import React from 'react';

import CustomTooltip from '../../tooltip/tooltip.component';
import TooltipMessage from '../../tooltip/tootltip-message.component';

export const TooltipMessageCard = (title, message) => {
  return (
    <CustomTooltip
      message={<TooltipMessage title={title} message={message} href={null} />}
    />
  );
};
