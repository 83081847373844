import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';
import { IntegrationDetail } from './detail';
import { IntegrationList } from './list';

import { cleanReducerIntegrationAction } from '../../../actions/integrationAction';

export const TabIntegration = () => {
  const dispatch = useDispatch();
  const { employeeIntegration } = useSelector((s) => s.integrationReducer);

  /**
   * Limpiar Reducer Integraciones
   */
  useEffect(() => {
    return () => {
      dispatch(cleanReducerIntegrationAction());
    };
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item lg={6} xs={6}>
        <IntegrationList />
      </Grid>
      <Grid item lg={6} xs={6}>
        <IntegrationDetail employeeIntegration={employeeIntegration} />
      </Grid>
    </Grid>
  );
};
