import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, makeStyles, Tooltip, Link } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import InputIcon from '../../../input/inputIcon.component';
import StyledCheckbox from '../../../checkForm/styledCheckbox.component';
import RcCalendar from '../../../../components/datepicker/rcdatepicker.component';

import { format } from 'date-fns';
import { WarningAlert } from '../../../../helpers/alert.helpers';
import PopoverModal from '../../../modal/popoverModal.component';

import CustomProgress from '../../../Progress/progress.component';
import { getDocumentsIntegrationAction } from '../../../../actions/integrationAction';
import IntegrationCard from './integrationCard';
import useNearScreen from '../../../../hooks/useNearScreen';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonIcon: {
    color: 'white',
  },
  containerList: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '61.5vh',
  },
  alignCenter: {
    textAlign: 'center',
  },
  letter: {
    color: '#FFFFFF',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  itemLoading: {
    textAlign: 'center',
    width: '100%',
  },
}));

export const DetailIntegrationList = ({ documentotrabajador }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [rangeDatesFilter, setRangeDatesFilter] = useState({
    dateFrom: format(new Date(), 'yyyy/MM/dd'),
    dateTo: format(new Date(), 'yyyy/MM/dd'),
  });

  const { documentsIntegration, loadingDocumentsIntegration } = useSelector(
    (s) => s.integrationReducer,
  );

  // Infinity Scroll
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: false ? null : externalRef,
    distance: '150px',
    once: false,
  });

  /**
   * Actualiza filtro cuando cambia documento de empleado
   */
  useEffect(() => {
    console.log('Cambio Documento trabajador', documentotrabajador);
    if (!!documentotrabajador) {
      setFilter({
        Skip: 0,
        Limit: 5,
        Fechadesde: null,
        FechaHasta: null,
        Filtro: null,
        Aprobado: null,
        Rechazado: null,
        LoadMore: false,
        DocumentoEmpleado: documentotrabajador,
      });
    }
  }, [documentotrabajador]);

  /**
   * Escucha cambio en filtros, cosulta resumen
   */
  useEffect(() => {
    if (!!filter && !!documentotrabajador) {
      dispatch(getDocumentsIntegrationAction(filter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dispatch]);

  useEffect(() => {
    if (
      isNearScreen &&
      !!documentsIntegration &&
      !loadingDocumentsIntegration
    ) {
      //No realiza mas consultas si no hay mas registros en BD
      if (
        documentsIntegration?.length >=
          documentsIntegration[0]?.totalregistros ||
        documentsIntegration?.length === 0
      )
        return;

      setFilter((prev) => ({
        ...prev,
        Skip: documentsIntegration.length,
        LoadMore: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * Sincroniza valor de filtro con state
   * @param {*} param0
   */
  const handleChangeFilter = ({ target }) => {
    setFilterText(target.value);
    if (target.value.length === 0) {
      setFilter((prev) => ({
        ...prev,
        Filtro: '',
        Skip: 0,
        LoadMore: false,
      }));
    }
  };

  /**
   * Filtra Listado por texto
   */
  const handleSearchByText = () => {
    setFilter((prev) => ({
      ...prev,
      Filtro: filterText,
      Skip: 0,
      LoadMore: false,
    }));
  };

  /**
   * Sincroniza fecha inicial filtro con state
   * @param {*} date Fecha
   */
  const handleChangeInitialDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: date,
    }));
  };

  /**
   * Sincroniza fecha final filtro con state
   * @param {*} date Fecha
   */
  const handleChangeEndDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateTo: date,
    }));
  };

  /**
   * Limpia filtro rango fechas
   */
  const cleanFilterDate = () => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: format(new Date(), 'yyyy/MM/dd'),
      dateTo: format(new Date(), 'yyyy/MM/dd'),
    }));

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: null,
      FechaHasta: null,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Limpia filtro por estado
   */
  const cleanFilterStatus = () => {
    setFilter((prevState) => ({
      ...prevState,
      Skip: 0,
      Aprobado: null,
      Rechazado: false,
      Destacado: false,
      LoadMore: false,
    }));
    handleCloseModalChecks();
  };

  /**
   * Abre modal filtro estado
   * @param {*} event Evento
   */
  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  /**
   * Cierra modal filtro estado
   * @param {*} event Evento
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * Abre modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * Cierra modal filtro rango fechas
   * @param {*} event Evento
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * Filtra listado reportados por estado
   * @param {*} event
   */
  const handleCheckFilters = ({ target }) => {
    const name = target.name;
    const checked = target.checked;

    if (name === 'accepted') {
      setFilter((prev) => ({
        ...prev,
        Aprobado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }

    if (name === 'rejected') {
      setFilter((prev) => ({
        ...prev,
        Rechazado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }

    if (name === 'favorite') {
      setFilter((prev) => ({
        ...prev,
        Destacado: checked,
        Skip: 0,
        LoadMore: false,
      }));
    }
  };

  /**
   * Valida rango de fechas seleccionado,
   * setea información de filtro para nueva consulta
   */
  const handleFilterByDate = () => {
    const { dateFrom, dateTo } = rangeDatesFilter;

    if (dateFrom === '' || dateTo === '') {
      WarningAlert(
        'Upsss...!!',
        'Debes seleccionar un rango de fechas valido.',
      );
      return;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      WarningAlert(
        'Upsss...!!',
        'La fecha inicial no puede ser mayor a la final',
      );
      return;
    }

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: dateFrom,
      FechaHasta: dateTo,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   * Panel selección filtro rango fechas
   */
  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateFrom}
              className={'colorCalendarRC'}
              id={'txtfechainicio'}
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateTo}
              className={'colorCalendarRC'}
              id={'txtfechafin'}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={handleFilterByDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };

  // Modal search filters
  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Aprobado}
              onChange={handleCheckFilters}
              name="accepted"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Aceptados
          </Grid>

          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Rechazado}
              onChange={handleCheckFilters}
              name="rejected"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Rechazados
          </Grid>

          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filter.Destacado}
              onChange={handleCheckFilters}
              name="favorite"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Destacados
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilterStatus}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12} style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <InputIcon
              name={'txtFilter'}
              label={'Buscar'}
              value={filterText}
              onChange={handleChangeFilter}
              onSearch={handleSearchByText}
              maxLength={100}
              disabled={false}
            />
          </div>
          <Tooltip title="Filtrar por fechas">
            <IconButton onClick={handleOpenModalDates} disabled={false}>
              <EventIcon className={classes.buttonIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtrar por estado">
            <IconButton
              className={classes.buttonFilterStatus}
              onClick={handleOpenModalChecks}
              disabled={false}
            >
              <img alt={'Filtrar'} src={filterImage}></img>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item lg={12} xs={12}>
          <div className={classes.containerList}>
            <Grid container spacing={1}>
              {documentsIntegration.length === 0 &&
                loadingDocumentsIntegration && (
                  <Grid item lg={12} xs={12} style={{ textAling: 'center' }}>
                    <CustomProgress />
                  </Grid>
                )}

              {documentsIntegration.map((item) => (
                <Grid key={item.id} item lg={12} xs={12}>
                  <IntegrationCard support={item} />
                </Grid>
              ))}

              {documentsIntegration.length === 0 &&
                !loadingDocumentsIntegration && (
                  <Grid item lg={12} xs={12} style={{ textAling: 'center' }}>
                    <span>No se han encontrado resultados...</span>
                  </Grid>
                )}
            </Grid>
            <div ref={externalRef} id="visor" />
            {loadingDocumentsIntegration && documentsIntegration.length > 0 && (
              <div className={classes.itemLoading}>
                <CustomProgress />
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={() => setModalDatesAnchorEl(null)}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={''}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
    </div>
  );
};
