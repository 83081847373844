import React, { useEffect, useRef, useState } from 'react';

import SimpleReactValidator from 'simple-react-validator';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';

import { Grid, Grow, makeStyles } from '@material-ui/core';

import { ButtonsLoad } from './';

import InputForm from '../../components/input/input.component';

import BulkLoadBg from '../../images/bulk_load_payroll.png';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0',
    padding: '0',
    backgroundColor: 'blueGrey',
  },
  infografia: {
    width: '100%',
    maxWidth: '1015px',
    margin: 'auto',
    display: 'block',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 0,
  },
  inputLoad: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export const LoadInformationPayroll = ({ setActiveStep, setCompleted }) => {
  const classes = useStyles();
  const [, forceUpdate] = useState();
  const [payrollYearMonth, setPayrollYearMonth] = useState(null);
  const [checked, setChecked] = useState(false);

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;

  useEffect(() => {
    window.scrollTo(0, 0);
    setChecked(true);
  }, []);

  /**
   * * Realiza una validación y muestra mensajes de error si es necesario.
   * @returns {boolean} Devuelve `true` si la validación es exitosa, de lo contrario `false`.
   */
  const onValidateInput = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      forceUpdate(Math.random);
      return false;
    }
    return true;
  };

  return (
    <Grow in={checked} timeout={500}>
      <div className={classes.root}>
        <h1 className={classes.title}>
          Cargue masivo de información de nómina
        </h1>
        <figure container>
          <img
            src={BulkLoadBg}
            className={classes.infografia}
            alt="Infografia"
          />
        </figure>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.inputLoad}
        >
          <Grid item lg={6} xs={8}>
            <InputForm
              name={'payrollYearMonth'}
              label={'Mes / Año'}
              maxLength={100}
              value={payrollYearMonth}
              onChange={(e) => setPayrollYearMonth(e.target.value)}
              validator={validator}
              validateOptions={[
                'required',
                {
                  payrollDate: payrollYearMonth,
                },
                {
                  maxPayrollDate: payrollYearMonth,
                },
                {
                  maxMonth: payrollYearMonth,
                },
              ]}
              onBlur={onValidateInput}
            />
          </Grid>
        </Grid>
        <ButtonsLoad
          payrollYearMonth={payrollYearMonth}
          validator={validator}
          setActiveStep={setActiveStep}
          setCompleted={setCompleted}
        />
      </div>
    </Grow>
  );
};
