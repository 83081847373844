/**
 * Homologa el código tipo identificación DIAN a prefijo
 *
 * @param {string} data - Tipo de documento de identificación para homologar
 * @return {string} Valor de homologación en la DIAN
 */
export const homologateDianDescription = (data) => {
  const typeMap = new Map([
    ['RC', '11'],
    ['TI', '12'],
    ['CC', '13'],
    ['TE', '21'],
    ['CE', '22'],
    ['NIT', '31'],
    ['PA', '41'],
    ['DIE', '42'],
    ['PEP', '47'],
  ]);

  return typeMap.get(data.replaceAll(' ', '')) || '';
};
