import React from 'react';
import NumberFormat from 'react-number-format';

export const percentFormatCustomOneHundred = ({
  inputRef,
  onChange,
  name,
  ...other
}) => {
  const withValueCap = (input) => {
    return input.value <= 100 ? true : false;
  };

  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalScale={2}
      allowNegative={false}
      suffix={'%'}
    />
  );
};

export function percentFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 10000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
      suffix={'%'}
    />
  );
}

export function quantityFormatCustomPrima(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 25000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueCap}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      fixedDecimalScale={false}
      decimalScale={0}
    />
  );
}

export function quantityFormatCustomOneHundred(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 99;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) {
      return true;
    }
    return false;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueCap}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      fixedDecimalScale={true}
      decimalScale={0}
      allowNegative={false}
    />
  );
}

export function quantityFormatCustomMax(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 730000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueCap}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

export function amountPercentageFormat({ inputRef, onChange, name, ...other }) {
  const MAX_VAL = 100;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueCap}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isNumericString
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

export function NumberFormatCustomBasic(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 10000000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator="."
      isNumericString
      prefix="$"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

export function NumberFormatCustomWithMin(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 100000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL && value > 0.0) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator="."
      isNumericString
      prefix="$"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 100000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator="."
      isNumericString
      prefix="$"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

export function NumberFormatCustomMax9(props) {
  const { inputRef, onChange, ...other } = props;
  const MAX_VAL = 1000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  return (
    <NumberFormat
      {...other}
      isAllowed={withValueCap}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      decimalSeparator="."
      isNumericString
      prefix="$"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}
