import { DEFAULTRESPONSE } from '../config/config';
import { GET_ERRORS, SET_INDEX_SELECTED } from './types';

/**
 * get list of access Menu from Database
 *
 * @method GET
 * @param {*}
 */

export const getMenuAction = () => async (dispatch) => {};

/**
 * @method
 * @description Limpia error generado por consumo de apis
 */
export const cleanErrorAction = () => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};

export const setIndexSelectedAction = (index) => (dispatch) => {
  dispatch({
    type: SET_INDEX_SELECTED,
    payload: index,
  });
};

export const setErrorAction = (payload) => {
  return { type: GET_ERRORS, payload: payload };
};

/**
 * Lee errores en repuestas de APIs
 * @param {*} error
 * @param {*} message
 * @returns
 */
export const showErrorAction = (error, message) => {
  console.log(message, !!error.response ? error.response : error);

  const response = !!error.response
    ? error.response
    : DEFAULTRESPONSE.noResponseFromApi;

  return {
    type: GET_ERRORS,
    payload: response,
    payload1: message,
  };
};
