import { format } from 'date-fns';
import React from 'react';
import CustomTooltip from '../../tooltip/tooltip.component';
import TooltipMessage from '../../tooltip/tootltip-message.component';

import TransferedBlankIcon from '../../../images/icons/transferedBlank.png';
import ErrorReportingIcon from '../../../images/icons/errorReporting.png';
import PendingTransferIcon from '../../../images/icons/cloud.png';

export const StatusDianIcon = React.memo(({ state, fechavalidacion }) => {
  switch (state) {
    case 2:
      return (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Transmisión a DIAN"
              message={
                <span>
                  Validación DIAN:
                  <span style={{ fontWeight: 'bold' }}> Aceptada </span>
                </span>
              }
              description={
                <div>
                  Fecha validacíon:
                  <span style={{ fontWeight: 'bold' }}>
                    {format(new Date(fechavalidacion), 'yyyy/MM/dd')}
                  </span>
                </div>
              }
              isPayrool={true}
              href={null}
              bgColor="#7DD1F8"
            />
          }
          iconEnvolved={
            <img
              src={TransferedBlankIcon}
              alt="Transmitido"
              style={{ marginLeft: '5%', width: 20 }}
            />
          }
        />
      );
    case 3:
      return (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Error DIAN - Documento con errores"
              message="El documento presenta una inconsistencia ante la DIAN, has clic en el siguiente botón para reportarlo a nuestro equipo de soporte y poder ayudarte."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={ErrorReportingIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%', width: 20 }}
            />
          }
        />
      );
    case 4:
      return (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Pendiente de sincronización con DIAN."
              message="Este Ítem esta pendiente de sincronización con DIAN."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={PendingTransferIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%', width: 20 }}
            />
          }
        />
      );
    case 6:
      return (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Error DIAN - Documento de Respuesta de DIAN con Error"
              message="El documento de respuesta de DIAN contiene errores, por favor repórtalo a nuestro equipo de soporte para poder ayudarte."
              href={null}
              bgColor="#FFF"
            />
          }
          iconEnvolved={
            <img
              src={PendingTransferIcon}
              alt="Alternativo"
              style={{ marginLeft: '5%', width: 20 }}
            />
          }
        />
      );

    default:
      return <></>;
  }
});
