import React from 'react';
import {
  FinalResultPayroll,
  LoadInformationPayroll,
  UploadResultFile,
} from './';
import { LoadResultPayroll } from './';

export const GetStepContent = ({ activeStep, setActiveStep, setCompleted }) => {
  const steps = [
    <LoadInformationPayroll
      setActiveStep={setActiveStep}
      setCompleted={setCompleted}
    />,
    <UploadResultFile
      setActiveStep={setActiveStep}
      setCompleted={setCompleted}
    />,
    <LoadResultPayroll
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      setCompleted={setCompleted}
    />,
    <FinalResultPayroll activeStep={activeStep} />,
  ];
  return steps[activeStep] || 'Unknown stepIndex';
};
