import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import { useEmployees } from '../../../../hooks/useEmployees';
import { ConfirmAlertAbortProcess } from '../../../../helpers/alert.helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#393939',
    position: 'sticky',
    bottom: '0',
    boxShadow: '0px 4px 7px 2px rgb(0 0 0 / 94%)',
    zIndex: '2',
    alignItems: 'center',
    textAlign: 'center',
  },
  paddingIcons: {
    paddingTop: '5px',
  },
  letter: {
    color: '#FFFFFF',
  },
  buttons: {
    '& *': {
      color: '#FFFFFF',
      display: 'block !important',
      textAlign: 'center',
      margin: '0 auto',
    },
  },
  letterInformation: {
    color: '#FFFFFF',
    fontFamily: theme.palette.fontFamily,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'justify',
  },

  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogConfirm: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
    },
  },
  btnBlue: {
    width: '25%',
    float: 'right',
  },
  divRegister: {
    background: theme.palette.seventhColor /* Can be in percentage also. */,
    height: 'auto',
    margin: '0 auto',
    padding: '10px',
    position: 'relative',
    textAlign: 'center',
    color: '#272727',
    fontFamily: theme.palette.fontFamily,
    fontWeight: 'bold',
    borderRadius: '5px',
  },
  styleText: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 14,
  },
  styleIcon: {
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  datanotfound: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#272727',
      color: theme.palette.primaryColor,
      height: 480,
      width: 1000,
      borderRadius: '5px',
    },
  },
  titleInformation: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textBarBotton: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
  },
}));

const FooterNav = ({
  setActiveStep,
  activeStep,
  resetFormRedux,
  updateEmployeeProcess,
  createEmployeeProcess,
  formCompleted = false,
  setBasicData,
  basicData,
}) => {
  const classes = useStyles();

  const { loadingCreateEmployee } = useSelector((s) => s.employeeReducer);

  const {
    changeEditingStatus,
    changeOpenRegisterEmployee,
    isEditing,
  } = useEmployees();

  const cancelOperation = () => {
    ConfirmAlertAbortProcess(
      `¿Estás seguro de que deseas cancelar? Si lo haces, perderás toda la información del empleado.`,
      cancelProcess,
      `Aceptar`,
    );
  };

  const cancelProcess = () => {
    changeEditingStatus(false);
    resetFormRedux();
    changeOpenRegisterEmployee(false);
  };

  /**
   * Sincroniza nuevo valor de checbox con form
   * @param {*} e Evento
   */
  const syncCheckChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    setBasicData({
      ...basicData,
      [name]: checked,
    });
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item lg={4} md={4} sm={4}>
          <div
            style={{
              display: true ? 'block' : 'none',
            }}
          >
            <FormControlLabel
              style={{ margin: 0, height: '75%' }}
              control={
                <Checkbox
                  name="favorito"
                  checked={basicData.favorito}
                  onChange={(e) => syncCheckChange(e)}
                  className={classes.formCheck}
                  // disabled={disabled === true ? true : false}
                  icon={
                    <IconButton
                      classes={{
                        root: classes.buttons,
                        label: classes.label,
                      }}
                      style={{ display: 'block' }}
                      variant="raised"
                      disableRipple={true}
                    >
                      <StarBorderIcon className={classes.iconAction} />
                      <div className={classes.textBarBotton}>Destacar</div>
                    </IconButton>
                  }
                  checkedIcon={
                    <IconButton
                      classes={{
                        root: classes.buttons,
                        label: classes.label,
                      }}
                      variant="raised"
                      disableRipple={true}
                    >
                      <StarIcon className={classes.iconAction} />
                      <div className={classes.textBarBotton}>Destacado</div>
                    </IconButton>
                  }
                />
              }
            />
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4}>
          <ClearIcon
            className="buttonEmployeeBehavior"
            onClick={cancelOperation}
          ></ClearIcon>
          <br></br>
          <div className={classes.textBarBotton}>Cancelar</div>
        </Grid>
        <Grid item lg={4} md={4} sm={4}>
          {/* Botón Actualizar */}
          <ButtonPrimary
            text={isEditing ? 'Actualizar' : 'Guardar'}
            disabled={!formCompleted}
            loading={loadingCreateEmployee}
            type={'submit'}
            form={'step-form'}
            style={
              !formCompleted
                ? { color: '#FFF' }
                : {
                    color: '#888888',
                    backgroundColor: '#2b6984',
                    cursor: 'not-allowed ',
                  }
            }
            className={classes.buttonContinue}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FooterNav;
