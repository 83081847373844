import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const SuccessAlert = (tittle, message, time) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: `<font size="4" color="#F5D13D">${
      tittle === null ? 'Buen trabajo!!!' : tittle
    }</font>`,
    html: `<p><font color="white">${message} </font></p>`,
    width: 400,
    heightAuto: true,
    timer: time,
    showConfirmButton: false,
    allowOutsideClick: true,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const SuccessAlert2 = (title, message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: (
      <p
        style={{
          color: '#F5D13D',
          fontFamily: 'Muli',
          fontWeight: '600',
          fontSize: '18px',
          marginTop: 'auto',
        }}
      >
        {title}
      </p>
    ),
    showConfirmButton: false,
    html: `<div style="margin-bottom: 2em; font-family: 'Muli'; font-size: 16px; line-height: 24px; letter-spacing: normal; text-align: center">${message}</div>`,
    background: 'rgba(255,255,255,0.16) !important',
  });
};

export const WarningAlert = (title, message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    confirmButtonText: (
      <div
        style={{
          color: '#7dd1f8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'right',
        }}
      >
        Entendido
      </div>
    ),
    cancelButtonColor: '#d33',
    // icon: 'warning',
    // title: <p>{title}</p>,
    text: message,
    background: '#000000',
  });
};

export const WarningAlertemployee = (title, message, callback) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    confirmButtonText: (
      <div
        style={{
          color: '#7dd1f8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'right',
        }}
      >
        Entendido
      </div>
    ),
    cancelButtonColor: '#d33',
    // icon: 'warning',
    // title: <p>{title}</p>,
    text: message,
    background: '#000000',
  }).then(function (confirmed) {
    callback();
  });
};

export const ErrorAlert = (title, message) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: 'error',
    title: <p>{title}</p>,
    text: message,
    width: 600,
  });
};

export const WarningAlertPopUp = (title = '', message = '', time = 3500) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: `<font size="4" color="#F5D13D">${title}</font>`,
    html: `<p><font color="white">${message} </font></p>`,
    width: 400,
    heightAuto: true,
    timer: time,
    showConfirmButton: false,
    allowOutsideClick: true,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

//Sin titulo FR
export const ConfirmAlert = (message, callback, txtConfirm = 'Confirmar') => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<p align="left"><font color="white">${message} </font></p>`,
    width: 430,
    heightAuto: true,
    allowOutsideClick: false,
    backdrop: `
          rgba(18,18,18,0.8)
          left top
          no-repeat
        `,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: txtConfirm,
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
  }).then(function (confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

// backStep Footer Pop Up bulkload
export const ConfirmAlertBackStep = (
  message,
  callback,
  txtConfirm,
  textCancel,
) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: (
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          color: '#D8D8D8',
        }}
      >
        {message}
      </span>
    ),
    width: 350,
    heightAuto: true,
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonText: (
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          color: '#7DD1F8',
        }}
      >
        {txtConfirm}
      </span>
    ),
    cancelButtonText: (
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          color: '#7DD1F8',
        }}
      >
        {textCancel}
      </span>
    ),
    customClass: {
      content: 'content-bulkLoad',
    },
    reverseButtons: false,
  }).then(function (confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

export const ConfirmAlertDelete = (title, message, callback) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    // title: <p>{title}</p>,
    text: message,
    // icon: 'warning',
    showCancelButton: true,
    cancelButtonText: (
      <div
        style={{
          color: '#7dd1f8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'right',
        }}
      >
        Cancelar
      </div>
    ),
    confirmButtonText: (
      <div
        style={{
          color: '#7dd1f8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'right',
        }}
      >
        Eliminar
      </div>
    ),
    background: 'rgba(255,255,255,0.16)',
  }).then(function (confirmed) {
    callback(confirmed && confirmed.value === true);
  });
};

export const ConfirmAlert2 = (
  message,
  callback,
  action,
  callbackDissmised = null,
) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<div style="display: block; font-family: 'Muli'; font-size: 16px; line-height: 24px; letter-spacing: normal; text-align: center">${message}</div>`,
    showCancelButton: true,
    // confirmButtonColor: '#121212',
    // cancelButtonColor: '#121212',
    confirmButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        {action}
      </div>
    ),
    cancelButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        Cancelar
      </div>
    ),
    background: 'rgba(255,255,255,0.16)',
  }).then(function (confirmed) {
    if (confirmed.isConfirmed) {
      callback(confirmed && confirmed.value === true);
    } else if (confirmed.isDismissed) {
      if (callbackDissmised !== null && callbackDissmised !== undefined) {
        callbackDissmised();
      }
    }
  });
};
export const ConfirmAlert3 = (message, typeDoc, document, callback, action) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<div style="display: block; font-family: 'Muli'; font-size: 16px; line-height: 24px; letter-spacing: normal; text-align: center">${message}</div>`,
    showCancelButton: true,
    // confirmButtonColor: '#121212',
    // cancelButtonColor: '#121212',
    confirmButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        {action}
      </div>
    ),
    cancelButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        Cancelar
      </div>
    ),
    background: 'rgba(255,255,255,0.16)',
  }).then(function (confirmed) {
    if (confirmed.isConfirmed) {
      callback(typeDoc, document);
    } else if (confirmed.isDismissed) {
    }
  });
};

export const ConfirmAlert4 = (
  message,
  secondMessage,
  thirdMessage,
  action,
  callback,
  callbackcancel,
) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `
    <div style="text-aling: justify">
          <div style="display: block; text-align: justify; font-family: 'Muli';">
              <p style="font-weight: bold; font-size: 18px; line-height: 23px;">${message}</p>
              <p style="left: 37.15%; right: 37.08%; top: 31.05%; bottom: 62.21%; background: rgba(255, 255, 255, 0.05); border-radius: 8px; margin-bottom: 0px; padding-bottom: 10px;">
                <span style="font-size: 12px; line-height: 15px;">${secondMessage}</span>
                <br />
                <span style="font-weight: bold; font-size: 12px; line-height: 15px;">
                  ${thirdMessage}
                </span>
              </p>
            </div>
      </div>`,
    showCancelButton: true,
    confirmButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '20px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        {action}
      </div>
    ),
    cancelButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '20px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        Comprar
      </div>
    ),
    background: 'rgba(255,255,255,0.16)',
  }).then(function (confirmed) {
    if (confirmed.isConfirmed) {
      callback();
    } else if (confirmed.isDismissed && confirmed.dismiss === 'cancel') {
      callbackcancel();
    }
  });
};
export const ConfirmAlertAbortProcess = (message, callback, action) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html: `<div style="display: block; font-family: 'Muli'; font-size: 16px; line-height: 24px; letter-spacing: normal; text-align: center">${message}</div>`,
    showCancelButton: true,
    // confirmButtonColor: '#121212',
    // cancelButtonColor: '#121212',
    confirmButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        {action}
      </div>
    ),
    cancelButtonText: (
      <div
        style={{
          color: '#7DD1F8',
          fontFamily: 'Muli',
          fontSize: '16px',
          letterSpacing: 'normal',
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        Cancelar
      </div>
    ),
    background: 'rgba(255,255,255,0.16)',
  }).then(function (confirmed) {
    if (confirmed.isConfirmed) {
      callback();
    } else if (confirmed.isDismissed) {
    }
  });
};

export const LoadMassive = (title, message) => {
  var el = document.createElement('span'),
    t = document.createTextNode('Custom HTML Message!!');
  el.style.cssText = 'color:#F6BB42';
  el.appendChild(t);
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html:
      '<font size="5" color="#00BFFF">Cargue masivo de artículos</font>' +
      '<p align="left"><font color="white"> Bienvenido al cargue masivo, aquí podras cargar toda la información  de varios artículos al mismo tiempo, solo debes segir estas instrucciones:</font></p>, ' +
      '<p align="left"><font color="white" ><font color="#00BFFF" >1.</font> Descarga la plantilla de cargue, deberas completarla con la información solicitada de cada artículo que desea subir;por favor, no la modifiques ni cambies el nombre del archivo.</font></p>' +
      '<p align="left"><font  color="white"><font color="#00BFFF" >2.</font> Sube la plantilla con la información diligenciada mediante el botón "Cargar Plantilla Masiva", nosotros haremos el resto...tu,tranquilo.</font></p>' +
      '<p align="left"><font color="white"><font color="#00BFFF" >3.</font> Listo!!!, La plataforma te mostrará el resultado del cargue, por favor verifica que todo quede en orden. </font></p>',
    showCancelButton: true,
    confirmButtonColor: '#00BFFF',
    cancelButtonColor: '#00BFFF',
    confirmButtonText: 'Descargar Plantilla',
    cancelButtonText: 'Cargar Plantilla Masiva',
    padding: '3em',
    background: '#2E2E2E',
    showCloseButton: true,
    position: 'center-right',
    backdrop: `
        rgba(0,0,0,0.4)
        left top
        no-repeat`,
    width: '80%',
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const ShowTemplateAlert = (title, src) => {
  var el = document.createElement('span'),
    t = document.createTextNode('Custom HTML Message!!');
  el.style.cssText = 'color:#F6BB42';
  el.appendChild(t);
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    html:
      '<font size="5" color="#00BFFF">' +
      title +
      '</font>' +
      '<p><img src="' +
      src +
      '" width="83%" height="83%"/></p>',
    confirmButtonColor: '#00BFFF',
    confirmButtonText: 'Cerrar',
    padding: '0em',
    background: '#2E2E2E',
    showCloseButton: true,
    position: 'center',
    backdrop: `

        rgba(0,0,0,0.4)
        left top
        no-repeat`,
    width: '30%',
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};

export const showTemplateChooseOneAlert = () => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: 'Editar soporte de nómina',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    html:
      '<p>Ten en cuenta las siguientes opciones al editar este desprendible.</p>' +
      '<p>Editar: Podrás realizar los cambios que requieras, ten en cuenta que se reemplazará el desprendible original con el nuevo documento que generes.</p>, ' +
      '<p>Eliminar: Borrarás completamente el desprendible de nómina generado, este desaparecerá del empleado y de su lista de desprendibles, no obstante podrás verlo en el tab de editados.</p>' +
      '<p>¿Que deseas hacer?</p>',
    input: 'select',
    inputOptions: {
      edit: 'Editar',
      delete: 'Eliminar',
    },
    inputPlaceholder: 'Seleccione',
    showCancelButton: false,
    confirmButtonText: `Continuar`,
    // inputValidator: (value) => {
    //   return new Promise((resolve) => {
    //     if (value) {
    //       return resolve();
    //     } else {
    //       return resolve('Debe seleccionar una opción');
    //     }
    //   });
    // },
  }).then((result) => {
    if (result.value) {
      return true;
    }
  });
};
