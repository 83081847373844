import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    margin: '0 1%',
  },
  title: {
    display: 'inline-flex',
    verticalAlign: 'top',
    fontSize: '1.2em',
    fontfamily: 'Muli',
    fontWeight: 'bold',
    margin: '0 auto',
  },
  titleOpen: {
    display: 'inline-flex',
    verticalAlign: 'top',
    fontSize: '1.2em',
    fontfamily: 'Muli',
    fontWeight: 'bold',
    margin: '0 auto',
  },
  body: {
    width: '100%',
  },
  margin: {
    borderRadius: '5px',
    // flexDirection: "column",
    // justifyContent: "center"
  },
  icon: {
    marginLeft: 10,
  },
}));
const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    minHeight: '',
    // maxHeight: '70vh',
    // overflowY: 'auto',
  },
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    // minHeight: 56,
    // '&$expanded': {
    //   minHeight: 56,
    // },
  },
  // content: {
  //   '&$expanded': {
  //     margin: '12px 0',
  //   },
  // },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: '1%',
  },
  expanded: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
}))(AccordionDetails);

const CollapsePanelStyles = ({ component: Component, ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('');
  const {
    open,
    edit,
    cleanData,
    idItemToEdit,
    backgroundColorSummary,
    backgroundColorDetail,
    type,
    data,
  } = props;

  //console.log('PRUEBA DATA',data);

  useEffect(() => {
    if (open !== '') {
      setExpanded('panel');
    }

    if (open !== 'panel') {
      setExpanded('');
    }
  }, [open, props.open]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
      className={classes.root}
      expanded={expanded === 'panel'}
      onChange={handleChange('panel')}
    >
      <ExpansionPanelSummary
        aria-controls="paneld-content"
        id="paneld-header"
        className={classes.margin}
        onClick={props.onClick}
        expandIcon={<ExpandMoreIcon />}
        style={backgroundColorSummary}
      >
        <span
          className={expanded === 'panel' ? classes.titleOpen : classes.title}
        >
          {!!edit
            ? props.titleEdit
            : expanded === 'panel'
            ? props.titleOpen
            : props.title}
          {type === 'createclient' ? (
            expanded === 'panel' ? (
              ''
            ) : (
              <AddCircleRoundedIcon
                style={{ color: '#FFFFFF' }}
                className={classes.icon}
              />
            )
          ) : null}
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={backgroundColorDetail}>
        <span className={classes.body}>
          <Component
            edit={edit}
            cleanData={cleanData}
            idItemToEdit={idItemToEdit}
            data={data}
          />
        </span>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CollapsePanelStyles.propTypes = {
  component: PropTypes.func.isRequired,
};

export default CollapsePanelStyles;
