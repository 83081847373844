import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import CardLoadResult from './CardLoadResult';
import ListLoadResult from './ListLoadResult';
import BulkLoadImage from '../../../../images/bulkLoadComplete.png';

const useStyles = makeStyles({
  btnContinue: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSuccess: {
    height: '54px',
    maxWidth: '18%',
    width: '39%',
    minWidth: '293px',
    borderRadius: '4px',
    backgroundColor: '#16B1F3',
    border: '#16B1F3',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0',
    textAlign: 'center',
    fontFamily: 'Muli',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },
  titleBulkLoadComplete: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 30,
    textAlign: 'center',
    display: 'inline-block',
    marginTop: 30,
    color: '#FFFFFF',
    width: '100%',
  },
});

const LoadResult = ({
  setModalData,
  modalData,
  setBulkLoad,
  setUpdateEmployee,
}) => {
  const classes = useStyles();

  const { bulkLoadErrorEmployee, bulkLoadEmployee } = useSelector(
    (s) => s.bulkLoadReducer,
  );

  const handleContinueBulkLoad = () => {
    setModalData(true);
  };

  const handleCompleteBulkLoad = () => {
    setBulkLoad(false);
    setUpdateEmployee(true);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {modalData ? (
        <>
          <CardLoadResult
            bulkLoadErrorEmployee={bulkLoadErrorEmployee}
            bulkLoadEmployee={bulkLoadEmployee}
            modalData={modalData}
          />
          <figure
            container
            style={{ display: 'flex', justifyContent: 'center', marginTop: 51 }}
          >
            <img src={BulkLoadImage} alt="bulkload" />
          </figure>
          <span className={classes.titleBulkLoadComplete}>
            {bulkLoadEmployee.length > 0
              ? `Maravilloso, tu cargue masivo fue un completo éxito!!!`
              : `No fue posible cargar ${bulkLoadErrorEmployee?.length} registro(s), por favor corrígelo(s) y súbelo(s) nuevamente..`}
          </span>
          <div className={classes.btnContinue} style={{ marginTop: 51 }}>
            <button
              className={classes.buttonSuccess}
              type="submit"
              onClick={handleCompleteBulkLoad}
            >
              Continuar
            </button>
          </div>
        </>
      ) : (
        <>
          <CardLoadResult
            bulkLoadErrorEmployee={bulkLoadErrorEmployee}
            bulkLoadEmployee={bulkLoadEmployee}
          />
          <ListLoadResult
            bulkLoadErrorEmployee={bulkLoadErrorEmployee}
            bulkLoadEmployee={bulkLoadEmployee}
          />
          <div className={classes.btnContinue}>
            <button
              className={classes.buttonSuccess}
              type="submit"
              onClick={handleContinueBulkLoad}
            >
              Continuar
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LoadResult;
