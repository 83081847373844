import React, { useState, useRef } from 'react';

import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';
import SimpleReactValidator from 'simple-react-validator';

import { Grid } from '@material-ui/core';
import ButtonPrimary from '../../button/buttonPrimary.component';
import InputForm from '../../input/input.component';

export const DeletePayrollForm = React.memo(
  ({ consecutivo, handleConfirmDelete, loading, periodo, nombre }) => {
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
      .current;
    const [, forceUpdate] = useState();
    const [observation, setObservation] = useState('');

    const handleChange = ({ target }) => {
      setObservation(target.value);
    };

    /**
     * Confirma formulario
     * @param {*} e
     * @returns
     */
    const onSubmit = (e) => {
      e.preventDefault();
      if (!validator.allValid()) {
        validator.showMessages();
        forceUpdate(Math.random);
        return;
      }

      handleConfirmDelete(observation);
    };

    return (
      <Grid container>
        <Grid item lg={12} xs={12}>
          <div className="modalEmail">
            {/* <b>
              {`Una vez elimines este documento soporte, no podrás volver a
              reportar nomina para ${nombre} en ${periodo}, "Deberás contactar a la mesa de soporte para habilitar de nuevo la transmisión".`}
            </b> */}
            <br />
            <br />
            ¿Estas seguro que deseas eliminar el documento soporte de nómina No.{' '}
            {consecutivo}?
          </div>
          <div className="modalEmail">
            Por favor indique las observaciones correspondientes para
            eliminarlo.
          </div>
          <div style={{ margin: '0 auto' }}>
            <InputForm
              name={'observaciones'}
              label={'Observaciones *'}
              maxLength={500}
              onChange={handleChange}
              value={observation}
              validator={validator}
              validateOptions={'required'}
            />
          </div>
        </Grid>
        <Grid item lg={4} md={4} style={{ margin: '0 auto' }}>
          <ButtonPrimary
            text={'Continuar'}
            onClick={onSubmit}
            type={'button'}
            style={{ color: '#FFF' }}
            loading={loading}
          />
        </Grid>
      </Grid>
    );
  },
);
