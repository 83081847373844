import React from 'react';
import cogoToast from 'cogo-toast';

export const errorToast = (title, message, position = 'top-right') => {
  const { hide } = cogoToast.error(
    <div>
      <b>{title}</b>
      <div>{message}</div>
    </div>,
    {
      onClick: () => {
        hide();
      },
      position: position,
      hideAfter: 10,
    },
  );
};

export const warningToast = (title, message, position = 'top-right') => {
  const { hide } = cogoToast.warn(
    <div>
      <b>{title}</b>
      <div>{message}</div>
    </div>,
    {
      onClick: () => {
        hide();
      },
      position: position,
      hideAfter: 10,
    },
  );
};

export const successToast = (title, message, position = 'top-right') => {
  const { hide } = cogoToast.success(
    <div>
      <b>{title}</b>
      <div>{message}</div>
    </div>,
    {
      onClick: () => {
        hide();
      },
      position: position,
      hideAfter: 10,
    },
  );
};

export const infoToast = (title, message, position = 'top-right') => {
  const { hide } = cogoToast.info(
    <div>
      <b>{title}</b>
      <div>{message}</div>
    </div>,
    {
      onClick: () => {
        hide();
      },
      position: position,
      hideAfter: 10,
    },
  );
};
