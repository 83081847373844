export const validationConcepts = [
  {
    conceptType: 'TipoIngreso',
    field: 'ValorIngreso',
    fieldConcept: 'aplicavalor',
    message: 'Debe proporcionar Valor ingreso para el concepto de ingreso.',
  },
  {
    conceptType: 'TipoIngreso',
    field: 'PorcentajeIngreso',
    fieldConcept: 'aplicaporcentaje',
    message:
      'Debe proporcionar Porcentaje ingreso para el concepto de ingreso.',
  },
  {
    conceptType: 'TipoIngreso',
    field: 'Cantidad',
    fieldConcept: 'aplicacantidad',
    message:
      'Debe proporcionar Cantidad(Días u Horas) para el concepto de ingreso.',
  },
  {
    conceptType: 'TipoIngreso',
    field: 'DescripcionIngreso',
    fieldConcept: 'aplicadescripcion',
    message:
      'Debe proporcionar Descripción ingreso para el concepto de ingreso.',
  },
  {
    conceptType: 'TipoDeduccion',
    field: 'ValorDeduccion',
    fieldConcept: 'aplicavalor',
    message: 'Debe proporcionar Valor deducción para el concepto de deducción.',
  },
  {
    conceptType: 'TipoDeduccion',
    field: 'PorcentajeDeduccion',
    fieldConcept: 'aplicaporcentaje',
    message:
      'Debe proporcionar Porcentaje deducción para el concepto de deducción.',
  },
  {
    conceptType: 'TipoDeduccion',
    field: 'Cantidad',
    fieldConcept: 'aplicacantidad',
    message: 'Debe proporcionar Cantidad para el concepto de deducción.',
  },
  {
    conceptType: 'TipoDeduccion',
    field: 'DescripcionDeduccion',
    fieldConcept: 'aplicadescripcion',
    message:
      'Debe proporcionar Descripción deducción para el concepto de deducción.',
  },
];
