import { axiosApiInstance } from '../config/axios-instance';
import {
  API_ENDPOINT_CONSULT_DOCUMENT,
  API_ENDPOINT_PAYROLL,
  defaultHeaders,
} from '../config/config';

import { SuccessAlert2 } from '../helpers/alert.helpers';
import { homologateDescripcionId } from '../helpers/catalogDian';
import { showErrorMessage } from '../utils/showErrorService';
import {
  enterpriseDocumentNumber,
  enterpriseDocumentType,
} from './authActions';

import { types } from './types';

const urlConsultDocument = `${API_ENDPOINT_CONSULT_DOCUMENT}/consultpaymentreceipt/api/DocumentoIntegracion`;
const urlConfiguration = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;

/**
 * Consulta listado empleados con soportes de integración asociados
 */
export const getEmployeesIntegrationAction = (
  filterData,
  setSelectedDocument,
  setSelectedTypeDocument,
) => async (dispatch, getState) => {
  try {
    //Limpia listado cuando cambia filtro
    if (!filterData.LoadMore) {
      dispatch({
        type: types.FILL_INTEGRATION_EMPLOYEES,
        payload: [],
      });
    }

    const { employeesIntegration } = getState().integrationReducer;

    //Inicia consulta
    changeLoadingeEmployee(dispatch, true);
    filterData = {
      ...filterData,
      TipoDocumentoEmpresa: homologateDescripcionId(enterpriseDocumentType()),
      DocumentoEmpresa: enterpriseDocumentNumber(),
    };

    const url = `${urlConsultDocument}${'/GetEmpleadosIntegracion'}`;
    const resp = await axiosApiInstance.post(url, filterData, defaultHeaders());
    const newList = filterData.LoadMore
      ? [...employeesIntegration, ...(resp?.data?.result ?? [])]
      : resp?.data?.result ?? [];

    dispatch({
      type: types.FILL_INTEGRATION_EMPLOYEES,
      payload: newList,
    });

    if (!filterData.LoadMore) {
      dispatch({
        type: types.SET_EMPLOYEE_INTEGRATION,
        payload: !!newList ? newList[0] : null,
      });
    }

    setSelectedDocument(newList[0]?.documentotrabajador);
    setSelectedTypeDocument(newList[0]?.tipodocumentotrabajador);
  } catch (err) {
    showErrorMessage(
      err,
      'Error consultando listado empleados por integración',
    );
  } finally {
    changeLoadingeEmployee(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta empleados por integracion
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingeEmployee = (dispatch, status) => {
  dispatch({
    type: types.LOADING_INTEGRATION_EMPLOYEES,
    payload: status,
  });
};

/**
 * Actualiza el empleado seleccionado en integraciones
 * @param {*} dispatch
 * @param {*} employee
 */
export const setSelectedEmployee = (employee) => (dispatch) => {
  dispatch({
    type: types.SET_EMPLOYEE_INTEGRATION,
    payload: employee,
  });
};

/**
 * Consulta listado soportes de integración de empleado
 */
export const getDocumentsIntegrationAction = (filterData) => async (
  dispatch,
  getState,
) => {
  try {
    //Limpia listado cuando cambia filtro
    if (!filterData.LoadMore) {
      dispatch({
        type: types.FILL_INTEGRATION_DOCUMENTS,
        payload: [],
      });
    }

    const { documentsIntegration } = getState().integrationReducer;

    //Inicia consulta
    changeLoadingDocument(dispatch, true);
    filterData = {
      ...filterData,
      TipoDocumentoEmpresa: homologateDescripcionId(enterpriseDocumentType()),
      DocumentoEmpresa: enterpriseDocumentNumber(),
    };

    const url = `${urlConsultDocument}${'/GetResumenDocumentos'}`;
    const resp = await axiosApiInstance.post(url, filterData, defaultHeaders());

    const newList = filterData.LoadMore
      ? [...documentsIntegration, ...(resp?.data?.result ?? [])]
      : resp?.data?.result ?? [];

    dispatch({
      type: types.FILL_INTEGRATION_DOCUMENTS,
      payload: newList,
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Error consultando listado de documentos de un empleado por integración',
    );
  } finally {
    changeLoadingDocument(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta listado soportes de integración de empleado
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingDocument = (dispatch, status) => {
  dispatch({
    type: types.LOADING_INTEGRATION_DOCUMENTS,
    payload: status,
  });
};

/**
 * Actualiza el estado favorito de un documento integrado
 * @param {*} documentid
 * @param {*} favorite
 * @returns
 */
export const updateDocumentFavoriteStatusAction = (
  documentid,
  favorite,
) => async (dispatch, getState) => {
  try {
    //Inicia actualizacion
    let config = defaultHeaders();
    Object.assign(config.headers, {
      pDocumentoId: documentid,
      pDestacado: favorite,
    });

    const url = `${urlConsultDocument}${'/DestacarDocumento'}`;
    const resp = await axiosApiInstance.put(url, null, config);

    const { documentsIntegration } = getState().integrationReducer;

    //Si la respuesta es 200 se actualiza el registro
    if (resp?.data?.httpCode === 200) {
      const newList = documentsIntegration.map((document) => {
        if (document.id === documentid) {
          document = {
            ...document,
            favorito: favorite,
          };
        }
        return document;
      });

      dispatch({
        type: types.FILL_INTEGRATION_DOCUMENTS,
        payload: newList,
      });
    }
  } catch (err) {
    showErrorMessage(
      err,
      'Error actualizando el estado de favorito de un documento por integración.',
    );
  }
};

/**
 * Consulta archivos asociados a un soporte de nomina
 * @param {*} codigounico Identificador unico soporte
 */
export const getDocumentFilesAction = (codigounico) => async (dispatch) => {
  try {
    changeLoadingFiles(dispatch, true);
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pTipoDocumento: 31,
      pDocumento: enterpriseDocumentNumber(),
      pCodigoUnico: codigounico,
    });

    const response = await axiosApiInstance.get(
      `${urlConfiguration}/Documento/GetDocumentFiles`,
      config,
    );

    dispatch({
      type: types.FILL_INTEGRATION_FILES,
      payload: response?.data?.result ?? null,
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando archivos asociados a documento.');
  } finally {
    changeLoadingFiles(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta archivos asociados a un soporte de nomina
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingFiles = (dispatch, status) => {
  dispatch({
    type: types.LOADING_FILES_INTEGRATION_DOCUMENTS,
    payload: status,
  });
};

/**
 * Limpia información de archivos
 * @returns
 */
export const cleanFileIntegrationAction = () => async (dispatch) => {
  dispatch({
    type: types.FILL_INTEGRATION_FILES,
    payload: null,
  });
};

/**
 * Envia documento soporte nomina a correo electronico
 * @param {*} codigoUnico
 * @param {*} razonSocialEmpleado
 * @param {*} mailTo
 * @returns
 */
export const sendDocumentIntegrationAction = (
  mailTo,
  razonSocialEmpleado,
  codigoUnico,
) => async (dispatch) => {
  try {
    changeLoadingSend(dispatch, true);
    const data = {
      TipoDocumento: '31',
      Documento: enterpriseDocumentNumber(),
      CodigoUnico: codigoUnico,
      RazonSocialCliente: razonSocialEmpleado,
      Correos: mailTo,
    };

    await axiosApiInstance.post(
      `${urlConfiguration}${'/Documento/SendDocument'}`,
      JSON.stringify(data),
      defaultHeaders(),
    );

    SuccessAlert2(
      'Buen trabajo!!!',
      'Se realizó el reenvío del soporte de nómina exitosamente.',
    );
  } catch (err) {
    showErrorMessage(err, 'Error en envio de correo electronico.');
  } finally {
    changeLoadingSend(dispatch, false);
  }
};

/**
 * Actualiza estado loading consulta archivos asociados a un soporte de nomina
 * @param {*} dispatch
 * @param {*} status
 */
const changeLoadingSend = (dispatch, status) => {
  dispatch({
    type: types.LOADING_SEND_INTEGRATION_DOCUMENTS,
    payload: status,
  });
};

/**
 *  Limpia información de reducer
 * @returns
 */
export const cleanReducerIntegrationAction = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_INTEGRATION_REDUCER,
  });
};
