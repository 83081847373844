import React from 'react';

import UpdateIcon from '../../../../images/icons/copyResult.png';
import ErrorIcon from '../../../../images/icons/failResult.png';
import ValidateIcon from '../../../../images/icons/validateIcon.png';
import TooltipMessage from '../../../../components/tooltip/tootltip-message.component';
import CustomTooltip from '../../../../components/tooltip/tooltip.component';

import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  gridContainerList: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: 'auto',
    marginBottom: '15px',
  },
  gridResult: {
    color: '#FFFF',
    fontWeight: '300',
    backgroundColor: '#3B3B3B',
    borderRadius: '4px',
    height: '80px',
    maxWidth: '344px',
    margin: '0 5px',
  },
  gridContainerTittle: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  gridContainerResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridTittle: {
    width: '97%',
    textAlign: 'center',
    paddingLeft: '25px',
    paddingTop: '10px',
    fontFamily: 'Muli',
    fontStyle: 'italic',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  gridText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '40px',
    lineHeight: '50px',
    textAlign: 'center',
    marginRight: '5px',
  },
  textUpdate: {
    color: '#F5D13D',
  },
  textError: {
    color: '#ED8D84',
  },
  textValidate: {
    color: '#2CC63E',
  },
});

const CardLoadResult = ({
  bulkLoadErrorEmployee,
  bulkLoadEmployee,
  modalData,
}) => {
  const classes = useStyles();
  const updateIcon = UpdateIcon;
  const errorIcon = ErrorIcon;
  const validateIcon = ValidateIcon;
  const totalEmployee = bulkLoadEmployee.length;

  const errorData = bulkLoadErrorEmployee || [];

  /**
   * Renderiza el grid con los datos de cargue masivo según el tipo de resultado
   * @param {*} result Arreglo de objetos con los resultados de cargue masivo
   * @param {*} messageTittle Título del mensaje de tooltip
   * @param {*} messageText Cuerpo del mensaje de tooltip
   * @returns
   */
  const renderResult = (result, messageTittle, messageText, category) => {
    let arrayData = [];

    //Añade la cantidad de empleados con errores
    if (result[0]?.Error?.length > 0) {
      arrayData = result;
    }
    //Añade la cantidad de empleados actualizados
    if (result[0]?.Resultado === 'Actualizado') {
      arrayData = result.filter((item) => item.Resultado === 'Actualizado');
    }

    return (
      <div className={classes.gridContainerResult}>
        <div className={classes.gridContainerTittle}>
          <span className={classes.gridTittle}>{tittleResult(category)}</span>
          {tooltipMessage(messageTittle, messageText)}
        </div>
        <div>
          <span className={`${textStyle(category)} ${classes.gridText}`}>
            {typeof result === 'number' ? result : arrayData.length}
          </span>
          <img
            src={iconResult(category)}
            alt="Bulkload Icon"
            style={{ marginBottom: '4px' }}
          />
        </div>
      </div>
    );
  };

  const textStyle = (item) => {
    // if (item[0]?.Resultado === 'Actualizado') return classes.textUpdate;
    // if (item[0]?.Error.length > 0) return classes.textError;
    // return classes.textValidate;
    switch (item) {
      case 'Actualizado':
        return classes.textUpdate;
      case 'Error':
        return classes.textError;
      default:
        return classes.textValidate;
    }
  };

  const tittleResult = (item) => {
    // if (item[0]?.Resultado === 'Actualizado') return ;
    // if (item[0]?.Error.length > 0) return 'Errores';
    // return 'Cargados exitosamente';
    switch (item) {
      case 'Actualizado':
        return 'Remplazados';
      case 'Error':
        return 'Errores';
      default:
        return 'Cargados exitosamente';
    }
  };

  const iconResult = (item) => {
    // if (item[0]?.Resultado === 'Actualizado') return updateIcon;
    // if (item[0]?.Error.length > 0) return errorIcon;
    // return validateIcon;
    switch (item) {
      case 'Actualizado':
        return updateIcon;
      case 'Error':
        return errorIcon;
      default:
        return validateIcon;
    }
  };

  const tooltipMessage = (tittleData, messageData) => {
    return (
      <CustomTooltip
        message={
          <TooltipMessage
            title={tittleData}
            message={messageData}
            href={null}
          />
        }
      />
    );
  };

  return (
    <div>
      {modalData ? (
        <Grid container className={classes.gridContainerList}>
          <Grid className={classes.gridResult} item lg={4} xs={12}>
            {renderResult(
              totalEmployee,
              'Cargados exitosamente',
              'La información de estos empleados fue cargada sin ningún inconveniente.',
              'Insertados',
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.gridContainerList}>
          <Grid className={classes.gridResult} item lg={4} xs={12}>
            {renderResult(
              totalEmployee,
              'Cargados exitosamente',
              'La información de estos empleados fue cargada sin ningún inconveniente.',
              'Insertados',
            )}
          </Grid>
          <Grid className={classes.gridResult} item lg={4} xs={12}>
            {renderResult(
              bulkLoadEmployee,
              'Información remplazada',
              'Ya existía información registrada en la nómina para estos empleados, esta información fue remplazada con la que suministraste en el cargue.',
              'Actualizado',
            )}
          </Grid>
          <Grid className={classes.gridResult} item lg={4} xs={12}>
            {renderResult(
              errorData,
              'Información cargada con errores',
              'La información de estos empleados contiene errores, descarga el informe de errores en la opción ubicada en la parte inferior de la pantalla.',
              'Error',
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CardLoadResult;
