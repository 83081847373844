import isEmpty from '../utils/isEmpty';

/**
 * @function DEVUELVE DESCRIPCIÓN DE TIPO DOCUMENTO IDENTIFICACIÓN
 * @param {*} value Identificador tipo documento identidad
 */
const dianDocumentDescription = (value) => {
  if (value === null || value === undefined || isEmpty(value)) return '';

  switch (value) {
    case '11':
      return 'RC.';
    case '12':
      return 'TI.';
    case '13':
      return 'CC.';
    case '21':
      return 'TE.';
    case '22':
      return 'CE.';
    case '31':
      return 'N.I.T';
    case '41':
      return 'PA.';
    case '42':
      return 'DE.';
    case '47':
      return 'PEP.';
    case '50':
      return 'N.I.T.';
    case '91':
      return 'NUIP.';
    default:
      return '';
  }
};

export default dianDocumentDescription;
