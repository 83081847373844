import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ImageTooltip from '../../images/AbrirTooltip.png';

const CustomStyledTooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.secundaryColor,
    padding: 'unset',
  },
}))(Tooltip);

const CustomTooltip = ({ ...props }) => {
  const { message, iconEnvolved } = props;

  return (
    <CustomStyledTooltip
      title={message}
      placement="bottom-end"
      interactive
      enterTouchDelay={1000}
      component={'div'}
    >
      {iconEnvolved ? (
        iconEnvolved
      ) : (
        <img
          src={ImageTooltip}
          alt={'imageTooltip'}
          width="20px"
          style={{ margin: '0.2em' }}
        ></img>
      )}
    </CustomStyledTooltip>
  );
};

CustomTooltip.propTypes = {
  message: PropTypes.object,
};

export default CustomTooltip;
