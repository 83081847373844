/**
 *  * Genera el modelo de datos para poder renderizar el dashboard
 * @param {Response Data Dashboard} data
 * @returns {Object}
 */

export const generateModelDashboard = (data) => {
  const {
    documentosTransmitidos,
    empleadosConfirmadosyTrasmitidos,
    ultimasNominas,
    comportamientoEmpleados,
    topConceptos,
  } = data;

  const totalSum = topConceptos
    .slice(0, 5)
    .reduce((sum, entry) => sum + entry.total, 0);

  const concepts = topConceptos?.map((concept, index) => ({
    name: concept.concepto,
    price: concept.total,
    value:
      index < 5 ? Number(((concept.total / totalSum) * 100).toFixed(2)) : 0,
  }));

  const behaviorMonth = comportamientoEmpleados?.map((concept) => ({
    name: '',
    Activos: concept.activos,
    Nuevos: concept.nuevos,
    Retirados: concept.retirados,
  }));

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const payrollPayment = ultimasNominas?.map((payroll) => {
    const date = new Date(payroll.periodo);
    const month = date.toLocaleString('default', { month: 'long' });
    const capitalizedMonth = capitalizeFirstLetter(month);
    const year = date.getFullYear();

    return {
      name: `${capitalizedMonth} ${year}`,
      value: payroll.valorTotal,
    };
  });

  const employeesTransmitted = [
    {
      name: 'Transmitidos',
      value: empleadosConfirmadosyTrasmitidos[0]?.trasmitidos ?? 0,
    },
    {
      name: 'Total',
      value:
        empleadosConfirmadosyTrasmitidos[0]?.total -
          empleadosConfirmadosyTrasmitidos[0].trasmitidos ?? 0,
    },
  ];

  const employeesConfirmed = [
    {
      name: 'Confirmados',
      value: empleadosConfirmadosyTrasmitidos[0]?.confirmados ?? 0,
    },
    {
      name: 'Total',
      value:
        empleadosConfirmadosyTrasmitidos[0]?.total -
          empleadosConfirmadosyTrasmitidos[0].confirmados ?? 0,
    },
  ];

  const documentsTransmitted = documentosTransmitidos?.map((document) => {
    const date = new Date(document.periodo);
    const month = date.toLocaleString('default', { month: 'short' });
    const capitalizedMonth = capitalizeFirstLetter(month);
    const year = date.getFullYear();

    return {
      name: `${capitalizedMonth} ${year}`,
      nomina: document.documentos,
      ajuste: document.notas,
    };
  });

  return {
    concepts,
    behaviorMonth,
    payrollPayment,
    documentsTransmitted,
    employeesTransmitted,
    employeesConfirmed,
  };
};
