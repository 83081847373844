import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CustomTooltip from '../tooltip/tooltip.component';
import { FormHelperText } from '@material-ui/core';
import CustomProgress from '../Progress/progress.component';
import './switch.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 17,
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50.4,
    height: 25,
    padding: 0,
    display: 'flex',
    borderRadius: 17.5,
    borderWidth: '0.01px !important',
    overflow: 'hidden',
  },
  switchBase: {
    padding: 2,
    color: 'grey',
    '&$checked': {
      transform: 'translateX(22.4px)',
      color: theme.palette.secundaryColor,
      '& + $track': {
        opacity: 1,
        backgroundColor: 'transparent', //gris
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
    margin: 2,
  },
  track: {
    border: `1.4px solid ${theme.palette.fifthBackgroundColor}`,
    borderRadius: 17,
    opacity: 1,
    backgroundColor: 'transparent', // Gris Claro
  },
  checked: {},
}))(Switch);

export default function SwitchForm({
  titleOff,
  titleOn,
  checked: externalChecked,
  onChange,
  name,
  txtTooltip,
  text,
  value,
  validator,
  validateOptions,
  withoutMargin,
  disabled,
  loading,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(
    externalChecked === true ? true : false,
  );

  useEffect(() => {
    setChecked(externalChecked);
  }, [externalChecked]);

  let errorValidator = validator
    ? validator.message(titleOn, value, validateOptions)
    : '';

  return (
    <FormGroup className={classes.root}>
      <div className={withoutMargin ? '' : classes.margin}>
        <Grid
          container
          component={'div'}
          style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}
          justify={!!txtTooltip ? 'flex-start' : 'space-between'}
          alignItems={!!txtTooltip ? 'center' : 'flex-start'}
        >
          <Grid item className={classes.title}>
            {!!titleOn ? titleOn : ''}{' '}
            {text !== undefined ? `${text}${'%'}` : ''}
          </Grid>
          {!!txtTooltip && (
            <Grid item style={{ marginLeft: 5 }}>
              <CustomTooltip message={txtTooltip} />
            </Grid>
          )}
          <Grid item style={{ marginLeft: 5 }}>
            {loading === true ? (
              <CustomProgress size={15} />
            ) : (
              <AntSwitch
                error={!!errorValidator ? 'error' : ''}
                name={name}
                checked={checked}
                onChange={onChange}
                value={value}
                disabled={disabled}
              />
            )}
          </Grid>
          {!!titleOff && (
            <Grid item className={classes.title}>
              {titleOff}
            </Grid>
          )}
          {!!errorValidator && (
            <FormHelperText>{errorValidator}</FormHelperText>
          )}
        </Grid>
      </div>
    </FormGroup>
  );
}
