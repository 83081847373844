import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, makeStyles } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import ReportNewPayrollButton from './ReportNewPayrollButton';
import {
  getDestructuredDate,
  getStringFromDate,
} from '../../helpers/functions.helpers';
import { format } from 'date-fns';
import CustomProgress from '../Progress/progress.component';
import ButtonPrimary from '../button/buttonPrimary.component';
import { setPayrollReportedFavoriteAction } from '../../actions/payrollReportedAction';
import { usePayroll } from '../../hooks/usePayroll';
import { updatePeriodDateAction } from '../../actions/payrollReportAction';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#4B4B4B',
    marginTop: '1.5vh',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  bottom: {
    boxShadow: '0 0 4px 0 rgb(0 0 0 / 50%)',
    bottom: '0%',
    width: '100%',
    backgroundColor: '#393939',
    textAlign: 'center',
    padding: '2%',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    position: 'relative',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  incomeTitle: {
    borderRadius: '5px',
    backgroundColor: 'rgba(18,18,18,0.3)',
    margin: '20px 0px',
    textAlign: 'center',
    '& h2': {
      color: '#2CC63E',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
  },
  expenseTitle: {
    borderRadius: '5px',
    margin: '20px 0px',
    backgroundColor: 'rgba(18,18,18,0.3)',
    '& h2': {
      color: '#ED8D84',
      textAlign: 'center',
      margin: '0.1em',
      fontSize: '18px',
    },
  },
  buttonContinue: {
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFF',
    width: '80%',
    height: '50px',
    fontSize: '18px',
    backgroundColor: '#16B1F3',
    borderRadius: '5px',
  },
  valCanceledDetail: {
    color: '#2CC63E',
    fontSize: '20px',
  },
  listDetails: {
    '& p': {
      margin: '1% 0 ',
    },
  },
  yellowColor: {
    color: '#F6D746',
    fontSize: 20,
  },
  greenColor: {
    color: '#2CC63E',
  },
  redColor: {
    color: '#ED8D84',
  },
  textButtons: {
    color: '#ffffff',
    fontFamily: 'Muli',
    fontSize: '10px',
    letterSpacing: '0',
    textAlign: 'center',
  },
  itemLoading: {
    textAlign: 'center',
    width: '100%',
  },
  view: {
    height: '100%',
  },
  mesagge: {
    fontSize: '15px',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Muli',
  },
});

const DetailPayroll = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { detailSelect, spinnerReportedDetail, spinnerReported } = useSelector(
    (state) => state.payrollReportedReducer,
  );

  const {
    ingresos,
    totalingresos,
    deducciones,
    totaldeducciones,
  } = detailSelect;

  const { setContentTab } = usePayroll();

  const UpdatePayrollReportedFav = (detailID, detailFavorite) => {
    dispatch(
      setPayrollReportedFavoriteAction({
        Id: detailID,
        Favorito: detailFavorite,
      }),
    );
  };

  /**
   * Abre modulo para reporte de nomina
   */
  const handleContinuePayroll = () => {
    if (!!detailSelect.periodo) {
      const { month, year } = getDestructuredDate({
        date: new Date(detailSelect.periodo),
        day: false,
      });
      setContentTab(2);
      dispatch(updatePeriodDateAction(month, year, detailSelect.fechapago));
    }
  };

  return (
    <div className={classes.view}>
      <ReportNewPayrollButton />
      {!spinnerReportedDetail && detailSelect?.ingresos ? (
        <div
          className={classes.container}
          style={{ minHeight: '80.7vh', maxHeight: '80.7vh' }}
        >
          <Grid container direction="column" justifycontent="space-between">
            <Grid item xs={12}>
              <Grid container style={{ padding: 20 }}>
                {/* Titulo */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item lg={11} sm={10} style={{ display: 'grid' }}>
                      <span className={classes.yellowColor}>
                        <strong>
                          {getStringFromDate(
                            new Date(detailSelect?.periodo),
                            true,
                          )}
                        </strong>
                      </span>
                      <span className={classes.valCanceledDetail}>
                        <strong>
                          {'$' +
                            new Intl.NumberFormat('es-CO').format(
                              detailSelect?.valornomina || 0,
                            )}
                        </strong>
                      </span>
                    </Grid>
                    <Grid item lg={1} sm={2}>
                      {detailSelect?.favorito ? (
                        <StarIcon />
                      ) : (
                        <StarBorderIcon />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <span>
                        {detailSelect?.periodoinicial &&
                          `Periodo reportado del ${format(
                            new Date(detailSelect?.periodoinicial),
                            'dd-MM-yyyy',
                          )} al ${format(
                            new Date(detailSelect?.periodofinal),
                            'dd-MM-yyyy',
                          )}`}
                      </span>
                    </Grid>
                    <div className="lineDivision"></div>
                    <Grid item xs={12}>
                      <span>
                        Cantidad empleados transmitidos:{' '}
                        {detailSelect.cantidadocumentos ===
                        detailSelect.cantidadtrasmitidos
                          ? `${detailSelect.cantidadtrasmitidos}`
                          : `${detailSelect.cantidadtrasmitidos} de ${detailSelect.cantidadocumentos}`}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Ingresos */}
                <Grid item xs={12}>
                  <div className={classes.incomeTitle}>
                    <h2>Ingresos</h2>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className={classes.listDetails} spacing={1}>
                    {ingresos?.map((concepts) => (
                      <Fragment key={concepts.id}>
                        <Grid item lg={5} xs={4} style={{ textAlign: 'end' }}>
                          <span>{concepts.descripcion}:</span>
                        </Grid>
                        <Grid item lg={4} xs={4} style={{ textAlign: 'end' }}>
                          <span className={classes.greenColor}>
                            {` $ ${new Intl.NumberFormat('es-CO').format(
                              concepts.total,
                            )}`}
                          </span>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          xs={1}
                          style={{ textAlign: 'end' }}
                        ></Grid>
                      </Fragment>
                    ))}
                    {!ingresos?.length && (
                      <Grid item lg={12} xs={12}>
                        {'Sin registros'}
                      </Grid>
                    )}
                    <div className="lineDivision" />
                    <Grid item lg={5} xs={8} style={{ textAlign: 'end' }}>
                      <b>Total Ingresos:</b>
                    </Grid>
                    <Grid item lg={4} xs={4} style={{ textAlign: 'end' }}>
                      <b className={classes.greenColor}>
                        {` $ ${new Intl.NumberFormat('es-CO').format(
                          totalingresos || 0,
                        )}`}
                      </b>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      xs={1}
                      style={{ textAlign: 'end' }}
                    ></Grid>
                  </Grid>
                </Grid>
                {/* Deducciones */}
                <Grid item xs={12}>
                  <div className={classes.expenseTitle}>
                    <h2>Deducciones</h2>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className={classes.listDetails} spacing={1}>
                    {deducciones?.map((item) => (
                      <Fragment key={item.id}>
                        <Grid item lg={5} xs={5} style={{ textAlign: 'end' }}>
                          <span>{item.descripcion}:</span>
                        </Grid>
                        <Grid item lg={4} xs={4} style={{ textAlign: 'end' }}>
                          <span
                            className={classes.redColor}
                            style={{ textAlign: 'end' }}
                          >
                            {` $ ${new Intl.NumberFormat('es-CO').format(
                              item.total,
                            )}`}
                          </span>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          xs={1}
                          style={{ textAlign: 'end' }}
                        ></Grid>
                      </Fragment>
                    ))}
                    {!deducciones?.length && (
                      <Grid item lg={12} xs={12}>
                        {'Sin registros'}
                      </Grid>
                    )}

                    <div className="lineDivision"></div>

                    <Grid item lg={5} xs={5} style={{ textAlign: 'end' }}>
                      <b>Total Deducciones:</b>
                    </Grid>
                    <Grid item lg={4} xs={4} style={{ textAlign: 'end' }}>
                      <b className={classes.redColor}>
                        {` $ ${new Intl.NumberFormat('es-CO').format(
                          totaldeducciones || 0,
                        )}`}
                      </b>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      xs={1}
                      style={{ textAlign: 'end' }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Botones y Favorito */}
            <Grid item xs={12}>
              <Grid container className={classes.bottom}>
                <Grid item xs={6} className={classes.buttonContainer}>
                  <ButtonPrimary
                    text={
                      detailSelect.cantidadempleados ===
                      detailSelect.cantidadtrasmitidos
                        ? 'Consultar nómina'
                        : 'Continuar nómina'
                    }
                    style={{ color: '#FFF' }}
                    onClick={handleContinuePayroll}
                    className={classes.buttonContinue}
                    lessMargin={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  {!spinnerReported ? (
                    <div>
                      {detailSelect.favorito ? (
                        <StarIcon
                          style={{ color: '#FFF', cursor: 'pointer' }}
                          onClick={() =>
                            UpdatePayrollReportedFav(
                              detailSelect.id,
                              !detailSelect.favorito,
                            )
                          }
                        />
                      ) : (
                        <StarBorderIcon
                          style={{ color: '#FFF', cursor: 'pointer' }}
                          onClick={() =>
                            UpdatePayrollReportedFav(
                              detailSelect.id,
                              !detailSelect.favorito,
                            )
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <div className={classes.itemLoading}>
                      <CustomProgress />
                    </div>
                  )}
                  <div className={classes.textButtons}>Destacar</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div style={{ paddingTop: 20 }}>
          {!spinnerReportedDetail && detailSelect?.ingresos === null ? (
            <h1 className={classes.mesagge}>
              No se ha confirmado o transmitido ninguna nómina para el mes
              seleccionado.
            </h1>
          ) : (
            <CustomProgress />
          )}
        </div>
      )}
    </div>
  );
};

export default DetailPayroll;
