import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import CustomTooltip from '../tooltip/tooltip.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: '5px 0px',
  },
  endAdornment: {
    color: theme.palette.primaryColor,
  },
  noMargin: {
    margin: 0,
  },
}));

const CustomInput = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.MuiFormLabel-filled:hover': {
      color: theme.palette.thirdColor, //azul
    },
    '& label.Mui-disabled': {
      color: 'gray', //gris
    },
    '& input.Mui-disabled': {
      color: 'rgba(255,255,255,0.5);',
    },
    '& label.MuiFormLabel-filled:not(.Mui-focused):not(.Mui-error)': {
      color: 'gray', //gris
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#16B1F3', //verde
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiInput-underline:focus': {
      borderBottomColor: 'gray', //gris
    },
    '& .MuiOutlinedInput-root': {
      '&.MuiInputLabel-outlined': {
        color: 'white',
      },
      '& fieldset': {
        border: '2px solid',
        borderColor: ' rgba(255,255,255,0.3)', //borde de la caja
      },
      '&:hover fieldset': {
        borderColor: 'gray',
        color: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.thirdColor, //azul
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray', //gris
      },
    },
  },
}))(TextField);

const Input = ({ ...props }) => {
  const {
    tooltip,
    id,
    onChange,
    value,
    label,
    disabled,
    validator,
    validateOptions,
    hidden,
    onKeyPress,
    onBlur,
    maxLength,
    nameValidator,
    variant,
    multiline,
    name,
    size,
    max,
    rowsLine,
    placeholder,
    noMargin,
    ref,
    inputComponent,
  } = props;
  const classes = useStyles();

  let errorValidator = validator
    ? validator.message(
        !!nameValidator ? nameValidator : label,
        value,
        validateOptions,
        // nameValidator
      )
    : '';

  if (!!hidden) return null;

  return (
    <div className={classes.root}>
      <CustomInput
        fullWidth
        className={noMargin ? classes.noMargin : classes.margin}
        rowsMax={rowsLine === true ? 5 : 'Infinity'}
        rows={rowsLine === true ? 5 : ''}
        disabled={disabled === true ? true : false}
        multiline={multiline ? true : false}
        label={label}
        variant={!!variant ? variant : 'outlined'}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={!!errorValidator ? true : false}
        helperText={errorValidator}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        size={size}
        inputProps={{ maxLength: maxLength, max: max }}
        autoComplete="off"
        placeholder={placeholder}
        ref={ref}
        InputProps={{
          inputComponent: inputComponent ?? 'input',
          endAdornment: !!tooltip && (
            <InputAdornment position="end">
              <CustomTooltip message={tooltip} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
};

export default Input;
