import {
  GET_TEMPLATE,
  SAVE_TEMPLATE,
  STATUS_STEP_TEMPLATE,
} from '../actions/types';

const initialState = {
  requestPostTemplate: {},
  stepTemplateComplete: false,
  templates: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATE:
      return {
        ...state,
        templates: action.payload,
      };
    case SAVE_TEMPLATE:
      return {
        ...state,
        requestPostTemplate: action.payload,
      };
    case STATUS_STEP_TEMPLATE:
      return {
        ...state,
        stepTemplateComplete: action.payload,
      };

    default:
      return state;
  }
}
