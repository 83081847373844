import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { AddCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    border: 'none',
  },
  title: {
    display: 'inline-flex',
    verticalAlign: 'top',
    fontSize: 16,
    fontWeight: 600,
  },
  titleOpen: {
    color: '#16B1F3',
    fontSize: 16,
    fontWeight: 600,
  },
  body: {
    width: '100%',
  },
  margin: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    marginLeft: 10,
  },
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

export const CollapseFormPanel = React.memo(
  ({
    component,
    open = false,
    onClick,
    disabled,
    titleOpen,
    title,
    backgroundColor,
  }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(open);

    useEffect(() => {
      setExpanded(open);
    }, [open]);

    return (
      <ExpansionPanel
        style={{
          backgroundColor: !!backgroundColor ? backgroundColor : "#4A4A4A'",
        }}
        className={classes.root}
        square
        expanded={expanded}
        disabled={disabled}
      >
        <ExpansionPanelSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.margin}
          onClick={onClick}
        >
          <Typography
            component={'span'}
            className={expanded ? classes.titleOpen : classes.title}
          >
            {expanded ? titleOpen : title}
            {!expanded && <AddCircle className={classes.icon} />}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component={'div'} className={classes.body}>
            {component}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  },
);

CollapseFormPanel.propTypes = {
  component: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
