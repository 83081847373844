import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import {
  validateTokenAction,
  checkTokenValidAction,
} from './../../actions/authActions';
import { getCompanyAction } from './../../actions/basicDataActions';

import { URL_SECURITY } from '../../config/config';
import isEmpty from '../../utils/isEmpty';

const useStyles = (theme) => ({
  divContainer: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  gridAling: {
    margin: '20px',
    padding: '20px',
  },
});

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validComplete: false,
      wizard: null,
    };
  }

  componentDidMount() {
    const queryString = this.props.match.params.queryString;
    this.props.checkTokenValidAction(queryString, this.completeValid);
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    return Object.keys(update).length ? update : null;
  }

  /**
   * Completa autenticación usuario
   * @param {*} data Información empresa
   */
  completeValid = (data) => {
    if (isEmpty(data)) {
      window.location.href = URL_SECURITY;
      return;
    }

    this.setState({
      wizard: data.wizard,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item lg={4} md={4} sm={12}></Grid>
          <Grid item lg={4} md={4} sm={12} className={classes.gridAling}>
            <div className={classes.divContainer}>
              <CircularProgress size={60} thickness={7} />
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12}></Grid>
        </Grid>
        {this.state.wizard === 2 || this.state.wizard === 3 ? (
          <Redirect to="/dashboard"></Redirect>
        ) : this.state.wizard === 1 ? (
          <Redirect to="/steps"></Redirect>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Index.propTypes = {
  prop: PropTypes,
  getCompanyAction: PropTypes.func.isRequired,
  checkTokenValidAction: PropTypes.func.isRequired,
  basicDataReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  basicDataReducer: state.basicDataReducer,
});

export default connect(mapStateToProps, {
  validateTokenAction,
  getCompanyAction,
  checkTokenValidAction,
})(withStyles(useStyles)(Index));
