import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import InputForm from '../../../../../components/input/input.component';
import ButtonPrimary from '../../../../../components/button/buttonPrimary.component';

import { reactValidatorOptions } from '../../../../../helpers/simpleReactValidator';
import SimpleReactValidator from 'simple-react-validator';

const useStyles = makeStyles(() => ({}));

const ModalDeleteSupport = React.memo(
  ({ consecutivo, onSubmitModalDeleting }) => {
    const classes = useStyles();
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
      .current;
    const [, forceUpdate] = useState();
    const [observation, setObservation] = useState('');

    /**
     * Sincroniza valor de input con state
     * @param {*} param0
     */
    const syncChanges = ({ target }) => {
      setObservation(target.value);
    };

    /**
     * Confirma formulario
     * @param {*} e
     * @returns
     */
    const onSubmit = (e) => {
      if (!validator.allValid()) {
        validator.showMessages();
        forceUpdate(Math.random);
        return;
      }
      onSubmitModalDeleting(e);
    };
    return (
      <Grid container>
        <Grid item lg={12} xs={12} className={classes.contentBody}>
          <div className="modalEmail">
            ¿Estas seguro que deseas eliminar el documento soporte de nómina No.{' '}
            {consecutivo}? esto lo borrará de la lista de desprendibles.
          </div>
          <div className="modalEmail">
            Por favor indique las observaciones correspondientes para
            eliminarlo.
          </div>
          <div style={{ margin: '0 auto' }}>
            <InputForm
              name={'observaciones'}
              label={'Observaciones *'}
              maxLength={300}
              onChange={syncChanges}
              value={observation}
              validator={validator}
              validateOptions={'required'}
            />
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm style={{ margin: '0 auto' }}>
          <ButtonPrimary
            text={'Continuar'}
            onClick={onSubmit}
            type={'button'}
            style={{ color: '#FFF' }}
          />
        </Grid>
      </Grid>
    );
  },
);
export default ModalDeleteSupport;
