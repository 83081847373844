import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPayrollReportedAction,
  getPayrollReportedDetailAction,
} from '../../actions/payrollReportedAction';

import useNearScreen from '../../hooks/useNearScreen';

import RcCalendar from '../datepicker/rcdatepicker.component';

import {
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import PopoverModal from '../modal/popoverModal.component';
import CustomProgress from '../Progress/progress.component';

import CardEmployee from './CardEmployee';
import { format } from 'date-fns';
import { WarningAlert } from '../../helpers/alert.helpers';
import StyledCheckbox from '../checkForm/styledCheckbox.component';

//** Imagen Filtro */
const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    color: 'white',
  },
  containerList: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '86.8vh',
    minHeight: '86.8vh',
    backgroundColor: theme.palette.secundaryBackgroundColor,
    color: theme.palette.primaryColor,
  },
  alignCenter: {
    textAlign: 'center',
  },
  letter: {
    color: '#FFFFFF',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  item: {
    marginBottom: 5,
    fontWeight: 'bold',
    '&.MuiListItem-root:hover': {
      borderRadius: 5,
      border: '1px solid #16B1F6',
    },
    '&.Mui-selected': {
      backgroundColor: '#16B1F3',
      borderRadius: 5,
    },
  },
  itemText: {
    fontWeight: 600,
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: '150vh',
  },
  emptylist: {
    color: 'white',
  },
  itemLoading: {
    textAlign: 'center',
    width: '100%',
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  view: {
    height: '100%',
  },
}));

const initialFilterReported = {
  Destacado: null,
  empresaId: 0,
  Fechadesde: null,
  Fechahasta: null,
  Limit: 10,
  LoadMore: false,
  Offset: 0,
};

const ListPayrollReportedGeneral = ({ loadingReported, setShowDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { listReported, detailSelect } = useSelector(
    (state) => state.payrollReportedReducer,
  );

  const [reporteds, setReporteds] = useState([]);
  const [filter, setFilter] = useState(initialFilterReported);
  const [modalDatesAnchorEl, setModalDatesAnchorEl] = useState(null);
  const [modalChecksAnchorEl, setModalChecksAnchorEl] = useState(null);
  const [rangeDatesFilter, setRangeDatesFilter] = useState({
    dateFrom: format(new Date(), 'yyyy/MM/dd'),
    dateTo: format(new Date(), 'yyyy/MM/dd'),
  });
  const [filterStatus, setFilterStatus] = useState({
    standOut: false,
    notStandOut: false,
  });
  const [selected, setSelected] = useState(0);

  // *  Infinity Scroll
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: reporteds.length === 0 ? null : externalRef,
    distance: '150px',
    once: false,
  });

  /**
   * * Consulta lista de reportados y filtra.
   * * setea lisReported en el estado de reporteds.
   */
  useEffect(() => {
    dispatch(getPayrollReportedAction(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    setReporteds(listReported);
  }, [listReported, reporteds]);

  useEffect(() => {
    if (detailSelect) {
      setSelected(detailSelect.id);
    }
  }, [detailSelect]);

  /**
   * * Paginado, Consulta nuevo lote  empleados
   */
  useEffect(() => {
    if (isNearScreen) {
      //No realiza mas consultas si no hay mas registros en BD
      if (!!reporteds && reporteds?.length >= reporteds[0]?.totalregistros)
        return;

      setFilter((prev) => ({
        ...prev,
        Offset: reporteds.length,
        LoadMore: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearScreen]);

  /**
   * * Abre modal filtro estado
   * * @param {*} event Evento
   */
  const handleOpenModalChecks = (event) => {
    setModalChecksAnchorEl(event.currentTarget);
  };

  /**
   * * Cierra modal filtro estado
   *  * @param {*} event Evento
   */
  const handleCloseModalChecks = () => {
    setModalChecksAnchorEl(null);
  };

  /**
   * * Abre modal filtro rango fechas
   * * @param {*} event Evento
   */
  const handleOpenModalDates = (event) => {
    setModalDatesAnchorEl(event.currentTarget);
  };

  /**
   * *  Cierra modal filtro estado
   * * @param {*} event Evento
   */
  const handleCloseModalDates = () => {
    setModalDatesAnchorEl(null);
  };

  /**
   * * Sincroniza fecha inicial filtro con state
   *  * @param {*} date Fecha
   */
  const handleChangeInitialDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: date,
    }));
  };

  /**
   * * Sincroniza fecha final filtro con state
   * * @param {*} date Fecha
   */
  const handleChangeEndDate = (date) => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateTo: date,
    }));
  };

  /**
   * * Limpia filtro rango fechas
   */
  const cleanFilterDate = () => {
    setRangeDatesFilter((prevState) => ({
      ...prevState,
      dateFrom: format(new Date(), 'yyyy/MM/dd'),
      dateTo: format(new Date(), 'yyyy/MM/dd'),
    }));

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: null,
      FechaHasta: null,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   *  * Valida rango de fechas seleccionado,
   *  * setea información de filtro para nueva consulta
   */
  const handleFilterByDate = () => {
    const { dateFrom, dateTo } = rangeDatesFilter;

    if (dateFrom === '' || dateTo === '') {
      WarningAlert(
        'Upsss...!!',
        'Debes seleccionar un rango de fechas valido.',
      );
      return;
    }

    if (new Date(dateFrom) > new Date(dateTo)) {
      WarningAlert(
        'Upsss...!!',
        'La fecha inicial no puede ser mayor a la final',
      );
      return;
    }

    setFilter((prev) => ({
      ...prev,
      Skip: 0,
      Fechadesde: dateFrom,
      FechaHasta: dateTo,
      LoadMore: false,
    }));

    handleCloseModalDates();
  };

  /**
   *  * Filtra listado reportados por estado
   * * @param {*} event
   */
  const handleCheckFilters = ({ target }) => {
    const name = target.name;
    const checked = target.checked;

    let statusFavorite = null;
    if (name === 'favorite') {
      setFilterStatus((prev) => ({
        ...prev,
        standOut: checked,
      }));

      if (Boolean(checked) !== Boolean(filterStatus.notStandOut)) {
        statusFavorite = checked;
      }
    }

    if (name === 'notFavorite') {
      setFilterStatus((prev) => ({
        ...prev,
        notStandOut: checked,
      }));

      if (Boolean(checked) !== Boolean(filterStatus.standOut)) {
        statusFavorite = !checked;
      }
    }

    setFilter((prev) => ({
      ...prev,
      offsetPass: 0,
      Destacado: statusFavorite,
      loadMore: false,
    }));
  };

  /**
   *  * Limpia filtro por estado
   */
  const cleanFilterStatus = () => {
    setFilterStatus({
      standOut: false,
      notStandOut: false,
    });
    setFilter((prevState) => ({
      ...prevState,
      offsetPass: 0,
      Destacado: null,
      loadMore: false,
    }));
    handleCloseModalChecks();
  };

  /**
   * Marca item seleccionado
   */
  const handleSelectedItem = (id) => {
    setSelected(id);
    dispatch(getPayrollReportedDetailAction(id));
  };

  /**
   *  * Modal selección filtro rango fechas
   */
  const modalDates = () => {
    return (
      <>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5} className={classes.alignCenter}>
            Desde
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            Hasta
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <div className="lineDivision"></div>
            <div className="spacingInvoiceMini"></div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item lg={5} sm={5}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateFrom}
              className={'colorCalendarRC'}
              id={'txtfechainicio'}
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item lg={1} sm={1} className="verticalLineInvoiceOther" />
          <Grid item lg={6} sm={6}>
            <RcCalendar
              disablePast={false}
              disableFuture={true}
              value={rangeDatesFilter.dateTo}
              className={'colorCalendarRC'}
              id={'txtfechafin'}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>

        <div className="spacingInvoice"></div>
        <div className="lineDivision"></div>
        <div className="spacingInvoice"></div>
        <Grid container>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterGray" onClick={cleanFilterDate}>
              Limpiar Filtro
            </button>
          </Grid>
          <Grid item lg={6} sm={6} className={classes.alignCenter}>
            <button className="btnFilterBlue" onClick={handleFilterByDate}>
              Filtrar
            </button>
          </Grid>
        </Grid>
      </>
    );
  };
  // ! ----- Final Modal Rango Fechas ----

  /**
   * * Modal Search Filters
   */

  const modalFilters = () => {
    return (
      <div>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterStatus.standOut}
              onChange={handleCheckFilters}
              name="favorite"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            Destacados
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <StyledCheckbox
              className={classes.letter}
              checked={filterStatus.notStandOut}
              onChange={handleCheckFilters}
              name="notFavorite"
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            No Destacados
          </Grid>
          <Grid item lg={12} md={12} sm={12} className={classes.link}>
            <Link
              href="#"
              onClick={cleanFilterStatus}
              variant="inherit"
              underline="none"
              className={classes.link}
            >
              Limpiar Filtro
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  };

  // ! ----- Final Modal SearchFilters ----

  /**
   *  * Componente  List Payroll
   */

  return (
    <div className={classes.view}>
      <Grid container>
        <Grid item xs={12}>
          <List
            className={classes.containerList}
            component="div"
            aria-label="main mailbox folders"
          >
            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.filters}>
                    <Tooltip title="Filtrar por fechas">
                      <IconButton
                        onClick={handleOpenModalDates}
                        disabled={false}
                      >
                        <EventIcon className={classes.buttonIcon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Filtrar por estado">
                      <IconButton
                        className={classes.buttonFilterStatus}
                        onClick={handleOpenModalChecks}
                        disabled={false}
                      >
                        <img alt={'Filtrar'} src={filterImage}></img>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </ListItem>

            {!loadingReported && listReported.length === 0 && (
              <ListItem className={classes.emptylist}>
                <span>No se han encontrado resultados...</span>
              </ListItem>
            )}

            <ListItem>
              <Grid container>
                <Grid item xs={12}>
                  {listReported.length > 0 &&
                    listReported.map((data) => (
                      <CardEmployee
                        key={data?.id}
                        data={data}
                        setShowDetail={setShowDetail}
                        onClick={() => handleSelectedItem(data.id)}
                        selected={data?.id === selected}
                      />
                    ))}
                </Grid>
              </Grid>
            </ListItem>

            <div ref={externalRef} id="visor" />
            {loadingReported && (
              <div className={classes.itemLoading}>
                <CustomProgress />
              </div>
            )}
          </List>
        </Grid>
      </Grid>

      <PopoverModal
        handleClose={() => setModalDatesAnchorEl(null)}
        open={Boolean(modalDatesAnchorEl)}
        component={modalDates}
        title={''}
        width={530}
        popoverAnchorEl={modalDatesAnchorEl}
        showCloseButton={false}
      />

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalChecksAnchorEl)}
        component={modalFilters}
        width={191}
        popoverAnchorEl={modalChecksAnchorEl}
        showCloseButton={false}
      />
    </div>
  );
};

export default ListPayrollReportedGeneral;
