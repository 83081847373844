import React from 'react';
import PayRollManager from '../../components/_Payroll';

const Payroll = () => {
  return (
    <>
      <PayRollManager />
    </>
  );
};

export default Payroll;
