import { Grid } from '@material-ui/core';
import React from 'react';
import ReportComponent from './component/ReportComponent';
import { downloadPayrollGroupedReport } from '../../actions/payrollReportAction';

import ExcelPayrollReport from './excelData/ExcelPayrollReport';

const ReportsView = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={12} xs={12} style={{ paddingTop: '1rem' }}>
              <ReportComponent
                title={'Informe Nómina Electrónica'}
                subtitle={
                  'En este informe tendrá los datos específicos sobre las nóminas exitosas enviadas a la DIAN que se generaron en su empresa.'
                }
                ExcelComponent={ExcelPayrollReport}
                action={downloadPayrollGroupedReport}
                reportTitle={'informeNomina'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsView;
