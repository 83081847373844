/**
 * * Filtra un arreglo de objetos de datos basado en varios criterios.
 *
 * @param {Array<Object>} data - El arreglo de objetos de datos que se va a filtrar.
 * @param {Object} stateCheck - Un objeto que contiene valores booleanos para verificar propiedades de 'data'.
 * @param {string} filterValue - El valor de filtro para buscar en los datos.
 * @param {Array<string>} [excludedFields] - Arreglo de nombres de campos que se deben excluir del filtro.
 * @returns {Array<Object>} - Un nuevo arreglo de objetos que cumple con los criterios de filtrado.
 */
export const filterData = (
  data,
  stateCheck,
  filterValue,
  excludedFields = [],
) =>
  data.filter(
    (item) =>
      (!Object.values(stateCheck).some((value) => value) ||
        Object.keys(stateCheck).some(
          (property) => stateCheck[property] && item.result.includes(property),
        )) &&
      (filterValue === '' ||
        Object.keys(item).some(
          (key) =>
            !excludedFields.includes(key) &&
            item[key]
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase()),
        )),
  );
