import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { reactValidatorOptions } from '../../../../../helpers/simpleReactValidator';
import { regexEmail } from '../../../../../helpers/customRegex.hepers';

import InputForm from '../../../../../components/input/input.component';
import ButtonSecundary from '../../../../../components/button/buttonSecundary.component';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  modalEmail: {
    color: '#D8D8D8',
    padding: '15px 0px 20px 0px',
  },
  widthMax: {
    width: '100%',
  },
}));

const ModalMail = React.memo(({ mailTo, codigounico, sendSupportToMail }) => {
  const classes = useStyles();

  const simpleValidator = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  );

  const [, forceUpdate] = useState();

  const [loadingSendSupportPayroll, setLoadingSendSupportPayroll] = useState(
    false,
  );
  const [email, setEmail] = useState(mailTo);

  const inactiveLoadingSendSupportPayroll = () => {
    setLoadingSendSupportPayroll(false);
  };

  /**
   * Actualiza el email
   * @param {*} e evento
   */
  const handleMail = (e) => {
    const email = e.target.value.replace(regexEmail, '');
    setEmail(email);
  };

  /**
   * Envia la petición para enviar el correo con el soporte de nómina
   * o muestra los mensajes de error
   * @param {*} e evento
   */
  const SendMails = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoadingSendSupportPayroll(true);
      simpleValidator.current.hideMessages();
      sendSupportToMail(email, codigounico, inactiveLoadingSendSupportPayroll);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(Math.random());
    }
  };

  return (
    <form onSubmit={(e) => SendMails(e)} className={classes.widthMax}>
      <div>
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.modalEmail}
          >
            Enviaremos el documento soporte de nómina al siguiente correo
            electrónico, puedes modificarlo si lo requieres.
          </Grid>
          <Grid item lg={12} md={12} sm={6} xs={12}>
            <InputForm
              id={'txtEmail'}
              label={'Correo electrónico *'}
              type={'text'}
              className={classes.letter}
              value={email}
              maxLength={50}
              noMargin={true}
              onChange={(e) => handleMail(e)}
              disabled={loadingSendSupportPayroll}
              validator={simpleValidator.current}
              validateOptions={'required|correo|min:10|max:50'}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={6} className={classes.alignCenter}>
          <ButtonSecundary
            text={'Enviar'}
            loading={loadingSendSupportPayroll}
            type={'submit'}
            margin={true}
          />
        </Grid>
      </div>
    </form>
  );
});

export default ModalMail;
