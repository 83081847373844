import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import { blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
    },
    input: {
        display: 'none',
    },
    buttonSuccess: {
        backgroundColor: theme.palette.thirdColor,
        color: theme.palette.primaryColor,
        '&:hover': {
            backgroundColor: blue[800],
        },
        textTransform: 'none',
        fontSize: 12,
        minWidth: 130
    },
}));

export default function ButtonUpload(props) {
    const classes = useStyles();
    const { text, onChange } = props;

    return (
        <div className={classes.root}>
            <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={onChange}
            />
            <label htmlFor="contained-button-file">
                <Button
                    variant="contained"
                    component="span"
                    className={classes.buttonSuccess}>
                    <PublishIcon></PublishIcon>
                    {text}
                </Button>
            </label>
        </div>
    );
}
