import {
  SAVE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  LOAD_CATEGORY,
  GET_CATEGORY_ARTICLE,
  CLEAN_DATA_CATEGORY,
  CLEAN_STATUS,
  GET_CATEGORY,
  LOADING_GET_CATEGORIES
} from "../actions/types";
import isEmpty from "../utils/isEmpty";


const initialState = {
  loadedCategory: null,
  editCategoryStatus: false,
  loadingGetCategories: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CATEGORY:
      return {
        ...state,
        saveCategoryResponse: action.payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        deleteCategoryResponse: action.payload,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        updateCategoryResponse: action.payload,
      };
    case LOAD_CATEGORY:
      return {
        ...state,
        loadedCategory: action.payload,
        editCategoryStatus: !isEmpty(action.payload),
      }

    case GET_CATEGORY_ARTICLE:
      return {
        ...state,
        getCategories: action.payload
      };

    case CLEAN_DATA_CATEGORY:
      return {
        ...state,
        cleanDataCategory: action.payload,
        editCategoryStatus: action.payload1
      }
    case CLEAN_STATUS:
      return {
        ...state,
        cleanStatus: action.payload
      }
    case GET_CATEGORY:
      return {
        ...state,
        categoryListResponse: action.payload,
        countCategories: action.payload1
      }
    case LOADING_GET_CATEGORIES:
      return {
        ...state,
        loadingGetCategories: action.payload
      }
    default:
      return state;
  }
}
