/**
 * * Valida si la extensión de un archivo está en la lista de extensiones permitidas.
 *
 * @param {File} file - El objeto de archivo a validar.
 * @param {Array} allowedExtensions - Un array de extensiones permitidas (por ejemplo, ['xls', 'xlsx', 'xlsm', 'csv']).
 * @returns {boolean} - True si la extensión es válida, false en caso contrario.
 */
export const validateExtension = (file, allowedExtensions) => {
  const fileName = file.name.toLowerCase();
  const fileExtension = fileName.split('.').pop();

  return allowedExtensions.includes(fileExtension);
};
