export const countConcepts = (datos) => {
  let registeredCount = 0;
  let transmittedCount = 0;
  let mixedCount = 0;
  let errorCount = 0;

  datos.forEach((empleado) => {
    const concepts = empleado.concepts;
    const status = concepts.map((concept) => concept.status);

    if (status.every((s) => s === 'Registrado')) {
      registeredCount++;
    } else if (status.includes('Transmitido')) {
      transmittedCount++;
    } else if (status.includes('Registrado')) {
      mixedCount++;
    } else if (status.every((s) => s === 'Error')) {
      errorCount++;
    }
  });

  return {
    Registrado: registeredCount,
    Transmitido: transmittedCount,
    Mixto: mixedCount,
    Error: errorCount,
  };
};
