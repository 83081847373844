/**
 * Genera el nombre completo de un empleado eliminando
 * los espacios en blanco innecesarios
 * @param {*} primerApellido
 * @param {*} segundoApellido
 * @param {*} primerNombre
 * @param {*} otroNombre
 * @returns Nombre completo
 */
export const getFullName = (
  primerApellido,
  segundoApellido,
  primerNombre,
  otroNombre,
) => {
  return `${primerNombre ?? ''} ${otroNombre ?? ''} ${primerApellido ?? ''} ${
    segundoApellido ?? ''
  }`
    ?.replace(/\s{2,}/g, ' ')
    ?.trim();
};

/**
 * Retorna texto (por defecto el mes) dependiendo de la fecha que se le pase como paramentro
 * opcionalmente recibe un segundo parametro que indica si se concatena el año
 * y por defecto esta en false
 * @param {date} date Fecha a describir
 * @param  {boolean} getMonthAndYear Opcional - concatena año - default:false
 */
export const getStringFromDate = function (date, getMonthAndYear = false) {
  const monthList = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  if (getMonthAndYear) {
    return `${monthList[date?.getMonth()]} ${date?.getFullYear()}`;
  }
  return `${monthList[date?.getMonth()]}`;
};

export const getCustomFormatDate = (date, separator = '/') => {
  const monthList = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  return `${monthList[date?.getMonth()]}${separator}${date?.getFullYear()}`;
};

/**
 * Retorna Mes, Año y/o di, segun se seleccione, en un objeto
 * destructurado de tipo { date: dateParams, year: XXXX, month: XX, day:XX}
 * Ejm: getDestructuredDate({ date: new Date(), day: false }) -> retorna mes y año
 * mes retorno : 1-12
 * @param {date} date Fecha a describir
 * @param  {boolean} anio Opcional - concatena año - default:true
 * @param  {boolean} month Opcional - concatena año - default:true
 * @param  {boolean} anio day - concatena año - default:true
 */
export const getDestructuredDate = function ({
  date,
  anio = true,
  month = true,
  day = true,
}) {
  let returnObj = { date, year: null, month: null, day: null };

  if (anio) returnObj.year = Number(date.getFullYear());
  if (month) returnObj.month = Number(date.getMonth()) + 1;
  if (day) returnObj.day = Number(date.getDay());

  return removeEmptyOrNull(returnObj);
};
/**
 * Retorna el mismo objeto sin keys con valor nulo en sus propiedades
 * @param {date} obj Objeto a limpiar
 */
export const removeEmptyOrNull = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
      (!obj[k] && obj[k] !== 0 && delete obj[k]),
  );

  return obj;
};
/**
 * Retorna id random alfa-numerico (ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789),
 * se le pasa un parametro opcional
 * que indica el tamaño de el id generado, por defecto es 12
 * @param {inf} lengthID Tamaño del id generado
 */
export const makeRandomId = (lengthID = 12) => {
  let ID = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (var i = 0; i < lengthID; i++) {
    ID += characters.charAt(Math.floor(Math.random() * 36));
  }
  return ID;
};
/**
 * unformat value since $XXX.XXX.XXX to number float
 * @param {text} value Valor a quitar formato tipo: $XXX.XXX.XXX
 */
// unformat value since $XXX.XXX.XXX to number float
export const unformatterValue = (value) => {
  if (value) {
    value = value.replace('$', '');
    value = value.replace(/(,)/g, '');
    value = parseFloat(value);
    return value;
  }
  return value;
};

/**
 * Función para colocar en mayuscula la primera letra de cada propiedad de un objeto
 * @param {*} obj objeto a modificar
 */
export const capitalizeProperty = (arr) => {
  const newArray = arr.map((obj) =>
    Object.keys(obj).reduce((count, key) => {
      count[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
      return count;
    }, {}),
  );
  return newArray;
};
